import {
    USER_LOADING,
    USER_SUCCESS,
    USER_FAILED,
    USER_EMAIL_UPDATING_SUCCESS,
    USER_UPDATING_SUCCESS,
} from '../actions/actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    user: null,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_EMAIL_UPDATING_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        case USER_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case USER_SUCCESS:
        case USER_UPDATING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload,
            };
        case USER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
