import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Button, TextInput, RadioGroup } from '../..';

function InviteUserForm(props) {
    const { t } = useTranslation();

    const defaultValues = _.reduce(
        props.data,
        (result, value, key) => {
            result[key] = value;
            return result;
        },
        {}
    );

    const { register, handleSubmit, control } = useForm({
        defaultValues: defaultValues,
    });

    const renderFields = () => {
        return (
            <React.Fragment>
                <Grid item sm={6} xs={12}>
                    <TextInput
                        label={t('forms:input.first_name')}
                        name="first_name"
                        required
                        error={props.errors && props.errors['first_name']}
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextInput
                        label={t('forms:input.last_name')}
                        name="last_name"
                        required
                        error={props.errors && props.errors['last_name']}
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label={t('forms:label.email_address')}
                        name={'email'}
                        email
                        required
                        error={props.errors && props.errors['email']}
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RadioGroup
                        label={t('forms:label.user_level')}
                        options={[
                            {
                                value: 'admin',
                                label: t('forms:label.user_admin'),
                            },
                            {
                                value: 'secretariat',
                                label: t('forms:label.user_secretariat'),
                            },
                            {
                                value: 'gate',
                                label: t('forms:label.user_gate'),
                            },
                        ]}
                        name="level"
                        error={props.errors && props.errors['method']}
                        required
                        control={control}
                        ref={register({ required: true })}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    return (
        <form onSubmit={handleSubmit(props.action)} style={{ width: '100%' }}>
            <Grid container spacing={3}>
                {renderFields()}
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color={'secondary'}
                        isLoading={props.isLoading}
                        type={'submit'}
                    >
                        {t('common:button.save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

InviteUserForm.propTypes = {
    data: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
};

export { InviteUserForm };
