/**
 * The dropdownalert is implemented as a service so that it can be used outside of components.
 */
import { showAlert } from 'components/AlertContainer/actions';
import { ALERT_TYPE } from '../enums/alert';
import StoreUtil from './StoreUtil';

export default class AlertService {
    /**
     * Call this function when you want to show an alert with the type error
     *
     * @param title The title of the alert
     * @param description The description of the alert
     */
    static showError({ title, description }) {
        StoreUtil.getStore().dispatch(
            showAlert({
                type: ALERT_TYPE.ERROR,
                title,
                description,
            })
        );
    }

    /**
     * Call this function when you want to show an alert with the type success
     *
     * @param title The title of the alert
     * @param description The description of the alert
     */
    static showSuccess({ title, description }) {
        StoreUtil.getStore().dispatch(
            showAlert({
                type: ALERT_TYPE.SUCCESS,
                title,
                description,
            })
        );
    }

    /**
     * Call this function when you want to show an alert with the type info
     *
     * @param title The title of the alert
     * @param description The description of the alert
     */
    static showInfo({ title, description }) {
        StoreUtil.getStore().dispatch(
            showAlert({
                type: ALERT_TYPE.INFO,
                title,
                description,
            })
        );
    }
}
