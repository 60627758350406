import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

/* eslint-disable no-undef */
class AutoScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            const anchor = document.querySelector("#back-to-top-anchor");

            if (anchor) {
                anchor.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }

    render() {
        return <React.Fragment />;
    }
}

AutoScrollToTop.propTypes = {
    location: PropTypes.object,
};
/* eslint-enable no-undef */

const AutoScrollToTopHistory = withRouter(AutoScrollToTop);

export { AutoScrollToTopHistory as AutoScrollToTop };
