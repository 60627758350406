import {
    CLUB_ADD_LOADING,
    CLUB_ADD_SUCCESS,
    CLUB_ADD_FAILED,
    CLUB_LOADING,
    CLUB_LOADING_SUCCESS,
    CLUB_LOADING_FAILED,
    CLUB_EDIT_LOADING,
    CLUB_EDIT_SUCCESS,
    CLUB_EDIT_FAILED,
    CLUB_RESET,
} from "./actionTypes";

const INITIAL_STATE = {
    isLoading: false,
    isAdding: false,
    isEditing: false,
    formErrors: null,
    newClub: null,
    club: null,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUB_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case CLUB_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                club: action.payload,
            };
        case CLUB_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case CLUB_EDIT_LOADING:
            return {
                ...state,
                isEditing: true,
            };
        case CLUB_EDIT_SUCCESS:
            return {
                ...state,
                // isEditing: false,  // keep loading for redirect
                newClub: action.payload,
            };
        case CLUB_EDIT_FAILED:
            return {
                ...state,
                isEditing: false,
                formErrors: action.payload,
            };

        case CLUB_ADD_LOADING:
            return {
                ...state,
                isAdding: true,
            };
        case CLUB_ADD_SUCCESS:
            return {
                ...state,
                // isAdding: false,  // keep loading for redirect
                newClub: action.payload,
            };
        case CLUB_ADD_FAILED:
            return {
                ...state,
                isAdding: false,
                formErrors: action.payload,
            };

        case CLUB_RESET:
            return {
                ...state,
                ...INITIAL_STATE,
            };

        default:
            return state;
    }
};
