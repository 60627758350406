import { applyDiscountToOrder as apiApplyDiscountToOrder } from 'services/OrderService';

import {
    ADMIN_DISCOUNT_DIALOG_APPLY_LOADING,
    ADMIN_DISCOUNT_DIALOG_APPLY_SUCCESS,
    ADMIN_DISCOUNT_DIALOG_APPLY_FAILED,
} from './actionTypes';

export const applyDiscountToOrder = ({ id, amount }) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_DISCOUNT_DIALOG_APPLY_LOADING,
            payload: amount,
        });

        apiApplyDiscountToOrder(id, amount)
            .then((result) => {
                dispatch({
                    type: ADMIN_DISCOUNT_DIALOG_APPLY_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_DISCOUNT_DIALOG_APPLY_FAILED,
                    payload: error,
                });
            });
    };
};
