import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { IconBorder } from 'components';
import {
    Button,
    Breadcrumbs,
    Card,
    CardMedia,
    CardContent,
    Grid,
    Hidden,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    InfoOutlined as InfoIcon,
    PlaceOutlined as PlaceIcon,
    ScheduleOutlined as ScheduleIcon,
    ArrowBackOutlined as ArrowBackIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import {
    hexToRGB,
    getObjectFromCache,
    randomEventImage,
    getEventDays,
    stripSeconds,
} from 'utils';

const useStyles = (theme) => ({
    header: {
        borderRadius: '20px',
        color: theme.colors.white,
        marginLeft: '-16px',
        marginRight: '-16px',

        [theme.breakpoints.up('sm')]: {
            marginLeft: '0px',
            marginRight: '0px',
        },

        [theme.breakpoints.up('1344')]: {
            marginLeft: '-56px',
            marginRight: '-56px',
        },
    },
    headerLeft: {
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            maxWidth: '100%',
            width: '100%',
        },
    },
    headerRight: {
        alignSelf: 'flex-end',

        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            maxWidth: '100%',
            width: '100%',
        },
    },
    content: {
        padding: '0',
    },
    relative: {
        position: 'relative',
    },
    title: {
        color: theme.colors.white,
        fontSize: '2.5rem',
        lineHeight: 1,
        margin: '16px 0',
    },
    stat: {
        alignItems: 'center',
        color: hexToRGB(theme.colors.white, 0.7),
        display: 'flex',
        marginTop: '5px',

        '& > svg': {
            marginRight: '8px',
            verticalAlign: 'middle',
        },
    },
    statContent: {
        marginLeft: theme.spacing(1),
    },
    media: {
        backgroundColor: theme.colors.springWood,
        position: 'relative',
        padding: '24px 16px 0px',

        [theme.breakpoints.up('sm')]: {
            padding: '40px 32px 32px',
        },

        [theme.breakpoints.up('md')]: {
            padding: '144px 56px 40px',
        },
    },
    mediaHasBackButton: {
        padding: '80px 16px 0px',

        [theme.breakpoints.up('sm')]: {
            padding: '96px 32px 32px',
        },

        [theme.breakpoints.up('md')]: {
            padding: '200px 56px 40px',
        },
    },
    meta: {
        borderRadius: '20px',
    },
    metaContent: {
        padding: '24px',

        '& > p:first-child': {
            marginTop: 0,
        },
    },
    labelContainer: {
        display: 'inline-block',
        marginLeft: '16px',

        [theme.breakpoints.down('xs')]: {
            display: 'block',
            marginLeft: 0,
            marginTop: '24px',
        },
    },
    label: {
        marginTop: '24px',
    },
    back: {
        color: theme.colors.white,
        fontSize: '.875rem',
        position: 'absolute',
        top: '16px',
        left: '0px',

        [theme.breakpoints.up('sm')]: {
            top: '32px',
            left: '16px',
        },

        [theme.breakpoints.up('md')]: {
            top: '32px',
            left: '48px',
        },

        '& svg': {
            height: '.875rem',
            marginRight: '15px',
            width: '.875rem',
        },
    },
    shimmer: {
        backgroundColor: 'rgba(51, 37, 21, 0.31)',
        position: 'relative',
    },
});

class CardEventHeader extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            clubName: null,
            default_header_image: randomEventImage()['@2x'],
        };

        // event handlers
        this.handleDaysClick = this.handleDaysClick.bind(this);
        this.handleSubscribeClick = this.handleSubscribeClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        // get the club object
        if (this.props.club !== prevProps.club) {
            getObjectFromCache({
                url: this.props.club,
                success: (result) => {
                    this.setState({
                        clubName: result['name'],
                    });
                },
                error: () => {
                    // do something TODO
                },
            });
        }
    }

    handleDaysClick(event) {
        event.preventDefault();
        event.stopPropagation();

        /* eslint-disable no-undef */
        document.getElementById('days').scrollIntoView({ behavior: 'smooth' });
        /* eslint-enable no-undef */
    }

    handleSubscribeClick(event) {
        event.preventDefault();
        event.stopPropagation();

        /* eslint-disable no-undef */
        document
            .getElementById('courses')
            .scrollIntoView({ behavior: 'smooth' });
        /* eslint-enable no-undef */
    }

    renderDate() {
        const { days } = this.props;
        const eventDays = getEventDays(days);

        if (eventDays && eventDays.days.length > 0) {
            const dayObj = eventDays.days[0];
            const count = eventDays.days.length - 1;

            return (
                <React.Fragment>
                    <div className={'date'}>
                        <strong className={'month'}>
                            {dayObj.day.format('MMM')}
                        </strong>
                        <strong className={'day'}>
                            {dayObj.day.format('DD')}
                        </strong>
                        <span className={'year'}>
                            {dayObj.day.format('YYYY')}
                        </span>
                    </div>
                    <div className={'meta'}>
                        <strong className={'text'}>
                            {dayObj.day.format('dddd')}
                        </strong>
                        <time className={'time'}>
                            {stripSeconds(dayObj.time_from)} -{' '}
                            {stripSeconds(dayObj.time_to)}
                        </time>
                    </div>
                    {eventDays.days.length > 1 && (
                        <small>
                            <Link onClick={this.handleDaysClick}>
                                <Trans
                                    i18nKey="text.more_days"
                                    count={count}
                                    ns="common"
                                >
                                    + {{ count }} more day
                                </Trans>
                            </Link>
                        </small>
                    )}
                </React.Fragment>
            );
        }
    }

    renderDays() {
        const { actionUrl, days, classes, t, isLoading } = this.props;

        if (days && days.length > 0) {
            if (isLoading) {
                return (
                    <Grid
                        item
                        lg={3}
                        md={4}
                        sm={5}
                        xs={12}
                        className={classes.headerRight}
                    >
                        <Card className={classes.meta}>
                            <CardContent className={classes.metaContent}>
                                <p>
                                    <strong>
                                        {t('common:text.date_and_time')}
                                    </strong>
                                </p>
                                <p>
                                    <Skeleton
                                        variant="rect"
                                        width={'80%'}
                                        height={19}
                                    />
                                    <Skeleton
                                        style={{ marginTop: '5px' }}
                                        variant="rect"
                                        width={'60%'}
                                        height={16}
                                    />
                                </p>
                                <Skeleton
                                    variant="rect"
                                    width={'100%'}
                                    height={52}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                );
            }

            return (
                <Grid
                    item
                    lg={3}
                    md={4}
                    sm={5}
                    xs={12}
                    className={classes.headerRight}
                >
                    <Card className={classes.meta}>
                        <CardContent className={classes.metaContent}>
                            <p>
                                <strong>
                                    {t('common:text.date_and_time')}
                                </strong>
                            </p>
                            <div className={'date-container'}>
                                {this.renderDate()}
                            </div>
                            <Button
                                disableElevation
                                fullWidth
                                variant="contained"
                                color="secondary"
                                href={actionUrl}
                                onClick={this.handleSubscribeClick}
                            >
                                {t('common:text.subscribe_now')}
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            );
        }
    }

    renderContent() {
        const { days, classes } = this.props;

        if (days && days.length > 0) {
            return (
                <Grid
                    item
                    lg={9}
                    md={8}
                    sm={7}
                    xs={12}
                    className={classes.headerLeft}
                >
                    {this.renderInnerContent()}
                </Grid>
            );
        }

        return (
            <Grid item xs={12}>
                {this.renderInnerContent()}
            </Grid>
        );
    }

    renderInnerContent() {
        const { name, classes, isLoading, t } = this.props;

        if (isLoading) {
            return (
                <React.Fragment>
                    <Grid item>
                        <Hidden xsDown>
                            <Skeleton
                                className={classes.shimmer}
                                variant="rect"
                                width={'50%'}
                                height={27}
                            />
                        </Hidden>
                        <Hidden smUp>
                            <Skeleton
                                className={classes.shimmer}
                                variant="rect"
                                width={'75%'}
                                height={27}
                            />
                        </Hidden>
                    </Grid>
                    <Grid item>
                        <div className={classes.title}>
                            <Hidden xsDown>
                                <Skeleton
                                    className={classes.shimmer}
                                    variant="rect"
                                    width={'70%'}
                                    height={40}
                                />
                            </Hidden>
                            <Hidden smUp>
                                <Skeleton
                                    className={classes.shimmer}
                                    variant="rect"
                                    width={'100%'}
                                    height={40}
                                />
                            </Hidden>
                        </div>
                    </Grid>
                    <Grid item className={classes.stat}>
                        <Hidden xsDown>
                            <Skeleton
                                className={classes.shimmer}
                                variant="rect"
                                width={'25%'}
                                height={24}
                            />
                        </Hidden>
                        <Hidden smUp>
                            <Skeleton
                                className={classes.shimmer}
                                variant="rect"
                                width={'50%'}
                                height={24}
                            />
                        </Hidden>
                    </Grid>
                    <Grid item className={classes.stat}>
                        <Hidden xsDown>
                            <Skeleton
                                className={classes.shimmer}
                                variant="rect"
                                width={'25%'}
                                height={24}
                            />
                        </Hidden>
                        <Hidden smUp>
                            <Skeleton
                                className={classes.shimmer}
                                variant="rect"
                                width={'50%'}
                                height={24}
                            />
                        </Hidden>
                    </Grid>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Grid item>
                    <Breadcrumbs separator="\" className={'breadcrumbs'}>
                        <Link to="/">{t('common:text.menu.home')}</Link>
                        <Link to="/events">{t('common:text.menu.events')}</Link>
                        <Link to={'/events/' + this.props.eventId}>
                            {this.state.clubName}
                        </Link>
                        <Link to={'/events/' + this.props.eventId}>{name}</Link>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <Typography variant="h1" className={classes.title}>
                        {name}
                    </Typography>
                </Grid>
                {this.renderIcons()}
                {this.renderClosed()}
            </React.Fragment>
        );
    }

    renderClubName() {
        const { club, classes } = this.props;
        const clubName = this.state.clubName;

        if (!club) {
            return;
        }

        if (clubName) {
            return (
                <Grid item className={classes.stat}>
                    <div>
                        <IconBorder>
                            <PlaceIcon fontSize="small" />
                        </IconBorder>
                    </div>
                    <div className={classes.statContent}>{clubName}</div>
                </Grid>
            );
        }

        return (
            <Skeleton
                variant="rect"
                width={'40%'}
                className={classes.shimmer}
                height="17"
            />
        );
    }

    renderSubscriptionDates() {
        const { subscriptionsFrom, subscriptionsTo, classes } = this.props;

        if (subscriptionsTo || subscriptionsFrom) {
            return (
                <Grid item className={classes.stat}>
                    <div>
                        <IconBorder>
                            <ScheduleIcon fontSize="small" />
                        </IconBorder>
                    </div>
                    <div className={classes.statContent}>
                        <Trans i18nKey="subscribe_from_to" ns="common">
                            Subscribe from{' '}
                            <strong>{{ subscriptionsFrom }}</strong> to{' '}
                            <strong>{{ subscriptionsTo }}</strong>
                        </Trans>
                    </div>
                </Grid>
            );
        }
    }

    renderInfo() {
        const { info, classes } = this.props;

        if (info) {
            return (
                <Grid item className={classes.stat}>
                    <div>
                        <IconBorder>
                            <InfoIcon fontSize="small" />
                        </IconBorder>
                    </div>
                    <div className={classes.statContent}>{info}</div>
                </Grid>
            );
        }
    }

    renderTime() {
        const { time, classes } = this.props;

        if (time) {
            return (
                <Grid item className={classes.stat}>
                    <div>
                        <IconBorder>
                            <ScheduleIcon fontSize="small" />
                        </IconBorder>
                    </div>
                    <div className={classes.statContent}>{time}</div>
                </Grid>
            );
        }
    }

    renderIcons() {
        return (
            <React.Fragment>
                {this.renderClubName()}
                {this.renderSubscriptionDates()}
                {this.renderInfo()}
                {this.renderTime()}
            </React.Fragment>
        );
    }

    renderClosed() {
        const { closed, classes, t } = this.props;

        if (closed) {
            return (
                <Grid item className={classes.label}>
                    {closed ? (
                        <span className="label red">
                            {t('common:labels.subscriptions_closed')}
                        </span>
                    ) : (
                        <span className="label green">
                            {t('common:labels.subscriptions_open')}
                        </span>
                    )}
                </Grid>
            );
        }
    }

    renderBack() {
        const { classes, showBackButton, t, backAction } = this.props;

        if (showBackButton) {
            return (
                <IconButton className={classes.back} onClick={backAction}>
                    <ArrowBackIcon />
                    {t('common:text.back')}
                </IconButton>
            );
        }
    }

    renderLoading() {
        const { classes } = this.props;

        return (
            <Card elevation={0} className={classes.header}>
                <CardMedia className={classes.media}>
                    <div className="card-overlay dark" />

                    <CardContent
                        className={[classes.content, classes.relative]}
                    >
                        <Grid container alignItems="center" spacing={4}>
                            {this.renderContent()}
                            {this.renderDays()}
                        </Grid>
                    </CardContent>
                </CardMedia>
            </Card>
        );
    }

    render() {
        const { classes, isLoading, showBackButton } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return (
            <Card elevation={0} className={classes.header}>
                <CardMedia
                    image={this.state.default_header_image}
                    className={classNames(
                        classes.media,
                        showBackButton && classes.mediaHasBackButton
                    )}
                >
                    <div className="card-overlay dark" />

                    {this.renderBack()}

                    <CardContent
                        className={[classes.content, classes.relative]}
                    >
                        <Grid container alignItems="center" spacing={4}>
                            {this.renderContent()}
                            {this.renderDays()}
                        </Grid>
                    </CardContent>
                </CardMedia>
            </Card>
        );
    }
}

CardEventHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    club: PropTypes.string.isRequired,
    subscriptionsTo: PropTypes.object,
    subscriptionsFrom: PropTypes.object,
    days: PropTypes.object,
    showBackButton: PropTypes.bool,
    closed: PropTypes.bool,
    time: PropTypes.string,
    info: PropTypes.string,
    actionUrl: PropTypes.string,
    isLoading: PropTypes.bool,
    backAction: PropTypes.func,
    eventId: PropTypes.number,
    t: PropTypes.func.isRequired,
};

const CardEventHeaderTranslated = withTranslation()(CardEventHeader);
const CardEventHeaderStyled = withStyles(useStyles)(CardEventHeaderTranslated);
const CardEventHeaderRouter = withRouter(CardEventHeaderStyled);

export { CardEventHeaderRouter as CardEventHeader };
