import {
    LOGOUT_PAGE_LOADING,
    LOGOUT_PAGE_LOADING_FAILED,
    LOGOUT_PAGE_LOADING_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    isLoggingOut: false,
    isLoggedOut: false,
    error: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGOUT_PAGE_LOADING:
            return {
                ...state,
                isLoggingOut: true,
                isLoggedOut: false,
                error: false,
            };
        case LOGOUT_PAGE_LOADING_SUCCESS:
            return {
                ...state,
                isLoggingOut: false,
                isLoggedOut: true,
            };
        case LOGOUT_PAGE_LOADING_FAILED:
            return {
                ...state,
                isLoggingOut: false,
                isLoggedOut: false,
                error: true,
            };

        default:
            return state;
    }
};
