import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { Button as MuiButton, DialogContentText } from '@material-ui/core';
import { Button } from '../..';
import { Dialog } from '..';

const useStyles = (theme) => ({
    primary: {
        backgroundColor: theme.colors.potPourri,
        color: theme.colors.permanentGeraniumLake,
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    secondary: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    contentText: {
        color: theme.colors.mikado,
    },
});

class DialogDelete extends Component {
    renderActions() {
        const { classes, onClose, isDeleting, t, action } = this.props;

        return (
            <React.Fragment>
                <Button
                    disabled={isDeleting}
                    isLoading={isDeleting}
                    variant="contained"
                    className={classes.primary}
                    disableElevation
                    onClick={action}
                >
                    {t('common:button.yes_sure')}
                </Button>
                <MuiButton
                    variant="text"
                    onClick={onClose}
                    className={classes.secondary}
                >
                    {t('common:button.cancel')}
                </MuiButton>
            </React.Fragment>
        );
    }

    render() {
        const { classes, onClose, open, t, title, description } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={title ? title : t('common:title.are_you_sure')}
                slidedirection={'down'}
                actions={this.renderActions()}
                isDeleteDialog
            >
                <DialogContentText
                    classes={{
                        root: classes.contentText,
                    }}
                >
                    {description ? description : t('common:text.are_you_sure')}
                </DialogContentText>
            </Dialog>
        );
    }
}

DialogDelete.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    action: PropTypes.func.isRequired,
    t: PropTypes.object.isRequired,
};

DialogDelete.defaultProps = {
    open: false,
    title: null,
    description: null,
};

const DialogDeleteStyled = withStyles(useStyles)(DialogDelete);
const DialogDeleteTranslated = withTranslation()(DialogDeleteStyled);

export { DialogDeleteTranslated as DialogDelete };
