import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import className from 'classnames';

import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Button } from 'components';

const useStyles = (theme) => ({
    loader: {
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            width: '100% !important',
        },
    },
});

class CardAction extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.props.action) {
            this.props.action();
        }
    }

    renderLoading() {
        return (
            <Card className="club-card" elevation={0}>
                <CardContent>
                    <div className="clubId">
                        <Skeleton variant="rect" height={20} width={50} />
                    </div>
                    <div className="name">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="email">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="website">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="actions" />
                </CardContent>
            </Card>
        );
    }

    renderContent() {
        const {
            title,
            description,
            actionText,
            buttonIsLoading,
            isLoading,
            classes,
        } = this.props;

        return (
            <CardContent>
                <div className="content">
                    <strong>{title}</strong>
                    <small>{description}</small>
                </div>
                <div className={className('actions', isLoading && 'isLoading')}>
                    {isLoading ? (
                        <Skeleton
                            variant={'rect'}
                            height={60}
                            width={100}
                            className={classes.loader}
                        />
                    ) : (
                        <Button
                            disabled={buttonIsLoading}
                            isLoading={buttonIsLoading}
                            component={'span'}
                            color={'secondary'}
                            onClick={this.handleAction}
                        >
                            {actionText}
                        </Button>
                    )}
                </div>
            </CardContent>
        );
    }

    renderCard() {
        return (
            <Card className="action-card" elevation={0}>
                <CardActionArea onClick={this.handleAction}>
                    {this.renderContent()}
                </CardActionArea>
            </Card>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return <React.Fragment>{this.renderCard()}</React.Fragment>;
    }
}

CardAction.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    actionText: PropTypes.string.isRequiredIf((props) => !props.isLoading),
    action: PropTypes.func.isRequiredIf((props) => !props.isLoading),
    buttonIsLoading: PropTypes.bool,
    isLoading: PropTypes.bool,
};

const CardActionTranslated = withTranslation()(CardAction);
const CardActionStyled = withStyles(useStyles)(CardActionTranslated);
const CardActionRouter = withRouter(CardActionStyled);

export { CardActionRouter as CardAction };
