import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Animated } from 'react-animated-css';
import { InView } from 'react-intersection-observer';

import {
    Container,
    Collapse,
    Fade,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';

import { Button } from '..';

const useStyles = (theme) => ({
    list: {
        margin: '32px 0',
    },
    imageContainer: {
        display: 'none',
        flex: 1,
        paddingLeft: theme.spacing(4) + 'px !important',
        position: 'relative',
        textAlign: 'right',

        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },

        '& > div > div': {
            position: 'absolute',
            right: 0,
            // top: "50%",
            // transform: "translateY(-50%)",
        },
    },
    contentContainer: {
        flex: 1,
    },
    img: {
        maxWidth: '90%',
        maxHeight: '550px',
    },
});

class USPImageBlock extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            open: 0, // first item (index) is open on default
        };

        // event handlers
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event, index) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            open: index,
        });
    }

    render() {
        const { classes } = this.props;

        return (
            <Container>
                <InView>
                    {({ inView, ref }) => {
                        return (
                            <Grid container spacing={3} ref={ref}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={classes.contentContainer}
                                >
                                    <Animated
                                        animationIn="fadeIn"
                                        animationOut="fadeOut"
                                        isVisible={inView}
                                    >
                                        <Typography variant={'h2'}>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: this.props.title,
                                                }}
                                            />
                                        </Typography>
                                        <div className={classes.list}>
                                            <ol className={'usp-numbered-list'}>
                                                {this.props.items.map(
                                                    (obj, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className={classNames(
                                                                    this.state
                                                                        .open ===
                                                                        index &&
                                                                        'open'
                                                                )}
                                                            >
                                                                <Link
                                                                    onClick={(
                                                                        event
                                                                    ) =>
                                                                        this.handleClick(
                                                                            event,
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <strong>
                                                                        {
                                                                            obj.title
                                                                        }
                                                                    </strong>
                                                                    <IconButton
                                                                        className={
                                                                            'toggle'
                                                                        }
                                                                        onClick={(
                                                                            event
                                                                        ) =>
                                                                            this.handleClick(
                                                                                event,
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <KeyboardArrowDownIcon />
                                                                    </IconButton>
                                                                </Link>
                                                                <Collapse
                                                                    in={
                                                                        this
                                                                            .state
                                                                            .open ===
                                                                        index
                                                                    }
                                                                >
                                                                    <p>
                                                                        {
                                                                            obj.description
                                                                        }
                                                                    </p>
                                                                    {obj.cta && (
                                                                        <Button
                                                                            variant={
                                                                                'text'
                                                                            }
                                                                            onClick={
                                                                                obj
                                                                                    .cta
                                                                                    .onClick
                                                                            }
                                                                        >
                                                                            {
                                                                                obj
                                                                                    .cta
                                                                                    .text
                                                                            }
                                                                        </Button>
                                                                    )}

                                                                    <img
                                                                        src={
                                                                            obj
                                                                                .image
                                                                                .src
                                                                        }
                                                                        alt={
                                                                            obj
                                                                                .image
                                                                                .alt
                                                                        }
                                                                        className={
                                                                            classes.img
                                                                        }
                                                                    />
                                                                </Collapse>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ol>
                                        </div>
                                        {this.props.ctaText && (
                                            <Button
                                                color={'secondary'}
                                                onClick={this.props.ctaAction}
                                            >
                                                {this.props.ctaText}
                                            </Button>
                                        )}
                                    </Animated>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    className={classes.imageContainer}
                                >
                                    {this.props.items.map((obj, index) => {
                                        return (
                                            <Animated
                                                animationIn="fadeInRight"
                                                animationOut="fadeOut"
                                                isVisible={inView}
                                            >
                                                <Fade
                                                    in={
                                                        this.state.open ===
                                                        index
                                                    }
                                                >
                                                    <div>
                                                        <img
                                                            src={obj.image.src}
                                                            alt={obj.image.alt}
                                                            className={
                                                                classes.img
                                                            }
                                                        />
                                                    </div>
                                                </Fade>
                                            </Animated>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        );
                    }}
                </InView>
            </Container>
        );
    }
}

USPImageBlock.propTypes = {
    items: PropTypes.exact({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        cta: PropTypes.exact({
            text: PropTypes.string,
            onClick: PropTypes.func,
        }),
        image: PropTypes.exact({
            src: PropTypes.string.isRequired,
            alt: PropTypes.string.isRequired,
        }),
    }).isRequired,
    title: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
    ctaAction: PropTypes.func,
    classes: PropTypes.object.isRequired,
};

const USPImageBlockStyled = withStyles(useStyles)(USPImageBlock);

export { USPImageBlockStyled as USPImageBlock };
