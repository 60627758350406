import React, { Component, Suspense, Spinner } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { getUrl, urls } from 'urls';

import { Container } from '@material-ui/core';

import { TemplateMarketing } from '../TemplateMarketing';

class DisclaimerPage extends Component {
    render() {
        const { t } = this.props;

        return (
            <Suspense fallback={<Spinner />}>
                <TemplateMarketing>
                    <Helmet>
                        <title>
                            {t('legal:title.main.disclaimer')} | Equento
                        </title>
                        <link
                            rel="canonical"
                            href={getUrl(urls.DISCLAIMER, {}, true)}
                        />
                    </Helmet>
                    <Container>
                        <h1>{t('legal:title.main.disclaimer')}</h1>

                        <p>
                            <strong>{t('disclaimer:title.application')}</strong>
                        </p>
                        <p>
                            By browsing this website and in any way interacting
                            with it, you automatically agree to the following
                            conditions. And you agree to abide to them.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>{t('disclaimer:title.contact')}</strong>
                        </p>
                        <p>
                            Selentic
                            <br />
                            Ingo Berben
                            <br />
                            <a href="mailto:info@equento.be">info@equnto.be</a>
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>
                                {t('disclaimer:title.intellectual')}
                            </strong>
                        </p>
                        <p>
                            The services delivered by this website and the
                            supplied information are protected by different
                            intellectual property rights. We are the copyright
                            owner of the concept, structure, layout and design
                            of this website. Full or partial reproduction,
                            distribution, publication, adaptation, translation
                            or usage for commercial purposes is explicitly
                            forbidden. Browsing this website give you no
                            permissions for this. Exceptions on this are only
                            given by prior written consent.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>
                                {t(
                                    'disclaimer:title.intellectual_third_parties'
                                )}
                            </strong>
                        </p>
                        <p>
                            We sometimes use YouTube or other services as
                            content sources. Given the agreements of these
                            services state that the content should not violate
                            intellectual properties, we assume that that is the
                            case. In case we, unknowingly, violated your
                            intellectual property, contact us as soon as
                            possible that we can credit you or remove the
                            content.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>{t('disclaimer:title.limitation')}</strong>
                        </p>
                        <p>
                            We strive for complete and reliable information on
                            our website. We cannot be held accountable for
                            incorrect information on the website, contacting us
                            for corrections is always possible.
                        </p>
                        <p>
                            We cannot be held accountable for a malfunction on
                            the website and any potential direct or indirect
                            damage this causes you. We hold the right to limit
                            or block the access to the website in cases of force
                            majeure. A link to a third party website does not
                            hold a implicit approval of the content of that
                            website. When transferring your personal
                            information, we use all available options to make
                            that happen safely, but it is always at your own
                            risk.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>{t('disclaimer:title.law')}</strong>
                        </p>
                        <p>Only the Belgian Law is applicable.</p>

                        <div className="spacer" />

                        <p>
                            <strong>{t('disclaimer:title.access')}</strong>
                        </p>
                        <p>
                            This website is accessible by recent computers and
                            mobile device with a recent browser. The website
                            holds no guarantees for compatibility and cannot be
                            held accountable for be unavailable.
                        </p>
                        <p>
                            It is forbidden to force access to the website
                            that’s not publicly visible. It is also forbidden to
                            modify the website, add or remove items in any way
                            possible.
                        </p>
                    </Container>
                </TemplateMarketing>
            </Suspense>
        );
    }
}

const DisclaimerPageTranslated = withTranslation()(DisclaimerPage);
export { DisclaimerPageTranslated as DisclaimerPage };
