export const ADMIN_ORDER_LOADING = 'admin_order_loading';
export const ADMIN_ORDER_SUCCESS = 'admin_order_success';
export const ADMIN_ORDER_FAILED = 'admin_order_failed';

export const ADMIN_ORDER_PAYMENTS_LOADING = 'admin_order_payments_loading';
export const ADMIN_ORDER_PAYMENTS_SUCCESS = 'admin_order_payments_success';
export const ADMIN_ORDER_PAYMENTS_FAILED = 'admin_order_payments_failed';

export const ADMIN_ORDER_PAY_LOADING = 'admin_order_pay_loading';
export const ADMIN_ORDER_PAY_SUCCESS = 'admin_order_pay_success';
export const ADMIN_ORDER_PAY_FAILED = 'admin_order_pay_failed';
