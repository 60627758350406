import React from 'react';

import './styles.scss';

import PropTypes from 'prop-types';

class TemplateMarketing extends React.Component {
    render() {
        return this.props.children;
        // return (
        //     <Grid container display="flex">
        //         <Grid item xs={12} md={12}>
        //             {this.props.children}
        //         </Grid>
        //     </Grid>
        // );
    }
}

TemplateMarketing.propTypes = {
    children: PropTypes.object.isRequired,
};

export { TemplateMarketing };
