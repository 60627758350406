import moment from 'moment';
import {
    PROFILE_LOADING,
    PROFILE_SUCCESS,
    PROFILE_FAILED,
    PROFILE_UPDATING_LOADING,
    PROFILE_UPDATING_SUCCESS,
    PROFILE_UPDATING_FAILED,
} from './actionTypes';

import {
    getProfileThroughUser as apiGetProfile,
    updateProfile as apiUpdateProfile,
} from 'services/ProfileService';

export const getProfileThroughUser = (userId) => {
    return (dispatch) => {
        dispatch({ type: PROFILE_LOADING });

        apiGetProfile(userId)
            .then((result) => {
                dispatch({
                    type: PROFILE_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: PROFILE_FAILED,
                    payload: error,
                });
            });
    };
};

export const updateProfile = (profileId, data) => {
    // convert stuff
    if (data['date_of_birth']) {
        data['date_of_birth'] = moment(
            data['date_of_birth'],
            'DD-MM-YYYY'
        ).format('YYYY-MM-DD');
    }

    return (dispatch) => {
        dispatch({ type: PROFILE_UPDATING_LOADING });

        apiUpdateProfile(profileId, data)
            .then((result) => {
                dispatch({
                    type: PROFILE_UPDATING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: PROFILE_UPDATING_FAILED,
                    payload: error,
                });
            });
    };
};
