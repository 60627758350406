import React from 'react';
import { Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment from 'moment';
import { urls, getUrl } from 'urls';
import PropTypes from 'prop-types';

import { CardActionArea, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Chip } from 'components';
import { renderAmount } from '../../../utils';

const useStyles = (theme) => ({
    amount: {
        textAlign: 'right',
    },
    lines: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        borderBottomColor: theme.colors.bone,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',

        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
    },
    labels: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(3),
        textAlign: 'left',

        [theme.breakpoints.up('sm')]: {
            paddingBottom: 0,
            paddingTop: 0,
            textAlign: 'right',
        },
    },
    link: {
        color: theme.colors.mikado + ' !important',

        '&:hover': {
            textDecoration: 'none',
        },
    },
});

function OrderRow(props) {
    const classes = makeStyles(useStyles)();

    if (props.isLoading) {
        return (
            <Grid
                container
                display={'flex'}
                alignItems={'center'}
                className={classNames(classes.lines)}
            >
                <Grid item sm={1} xs={10}>
                    <Skeleton width={'96%'} height={24} />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Skeleton width={'96%'} height={24} />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Skeleton width={'96%'} height={24} />
                </Grid>
                <Grid item sm={3} xs={12} className={classes.labels}>
                    <Skeleton width={'96%'} height={32} />
                </Grid>
                <Grid item sm={2} className={classes.amount}>
                    <Skeleton width={'96%'} height={24} />
                </Grid>
            </Grid>
        );
    }

    // not loading
    const count = props.obj.amount_subscriptions;

    function getStyle(status) {
        if (status === 'payment-pending') {
            return 'warning';
        } else if (
            status === 'canceled' ||
            status === 'not-paid' ||
            status === 'partially-paid'
        ) {
            return 'error';
        } else if (status === 'paid') {
            return 'success';
        }

        return 'default';
    }

    return (
        <CardActionArea
            href={getUrl(urls.ADMIN_ORDER_DETAIL, { orderId: props.obj.id })}
            color={'inherit'}
            className={classes.link}
        >
            <Grid
                container
                display={'flex'}
                alignItems={'center'}
                className={classNames(classes.lines)}
            >
                <Grid item sm={1} xs={10}>
                    <Typography variant={'body1'}>
                        <small>#{props.obj.id}</small>
                    </Typography>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Typography variant={'body1'}>
                        <small>
                            {moment(props.obj.created_at).format('LL')}
                        </small>
                    </Typography>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Typography variant={'body1'}>
                        <Trans
                            i18nKey="text.amount_subscription"
                            count={count}
                            ns="common"
                        >
                            {{ count }} subscriptions
                        </Trans>
                    </Typography>
                </Grid>
                <Grid item sm={3} xs={12} className={classes.labels}>
                    <Chip
                        label={props.obj.status_description}
                        variant="default"
                        type={getStyle(props.obj.status)}
                    />
                </Grid>
                <Grid item sm={2} className={classes.amount}>
                    <Typography variant={'body1'}>
                        <strong>{renderAmount(props.obj.total_amount)}</strong>
                    </Typography>
                </Grid>
            </Grid>
        </CardActionArea>
    );
}

OrderRow.propTypes = {
    // props
    isLoading: PropTypes.bool,
    obj: PropTypes.object,
    // event handlers
    // others
};

export { OrderRow };
