import React, { Component } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { withStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import classNames from 'classnames';
import EventService from 'lib/EventService';
import { getItem } from 'services/StorageService';
import { EVENT } from 'enums';

import PropTypes from 'prop-types';

const useStyles = (theme) => ({
    container: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        width: '100%',

        [theme.breakpoints.up('1280')]: {
            width: '100%',
            maxWidth: '1400px',
        },

        '& h1': {
            margin: 0,
        },
    },
    outerContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        // justifyContent: 'center',
    },
    header: {
        backgroundColor: theme.colors.white,
        borderBottomColor: 'rgba(0, 0, 0, .12)',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        display: 'flex',
        justifyContent: 'center',
    },
    noBottomPadding: {
        paddingBottom: 0,
    },
    drawerOpen: {
        // paddingLeft: theme.spacing(3),
        //
        // [theme.breakpoints.up('sm')]: {
        //     paddingLeft: theme.spacing(3),
        // },

        [theme.breakpoints.up('md')]: {
            paddingLeft: '250px',
        },
    },
    drawerClosed: {
        // paddingLeft: theme.spacing(3),

        [theme.breakpoints.up('md')]: {
            paddingLeft: '120px',
        },
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',

        transition: theme.transitions.create(['padding'], {
            duration: theme.transitions.duration.standard,
        }),
    },
    gridWrapper: {
        maxWidth: '1400px',
        width: '100%',
    },
});

class TemplateAdmin extends Component {
    constructor(props) {
        super(props);

        let value = getItem('adminDrawerOpen') === 'true';

        this.state = {
            drawerOpen: value,
            id: _uniqueId('template'),
        };
    }

    componentDidMount() {
        EventService.subscribe(
            EVENT.ADMIN_DRAWER_UPDATED,
            this.state.id,
            this.handleDrawerUpdate.bind(this)
        );
    }

    componentWillUnmount() {
        EventService.unsubscribe(EVENT.ADMIN_DRAWER_UPDATED, this.state.id);
    }

    handleDrawerUpdate({ drawerOpen }) {
        this.setState({
            drawerOpen: drawerOpen,
        });
    }

    render() {
        const { header, filter, removeBottomPadding, classes } = this.props;

        return (
            <Container
                maxWidth={false}
                className={classNames(
                    classes.wrapper,
                    (header || filter) && classes.header,
                    this.state.drawerOpen === true
                        ? classes.drawerOpen
                        : classes.drawerClosed
                )}
            >
                <Grid
                    container
                    spacing={2}
                    display="flex"
                    justify="center"
                    alignItems="center"
                    className={classes.gridWrapper}
                >
                    <Grid item xs={12} className={classes.outerContainer}>
                        <div
                            className={classNames(
                                classes.container,
                                removeBottomPadding
                                    ? classes.noBottomPadding
                                    : null
                            )}
                        >
                            {this.props.children}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

TemplateAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    header: PropTypes.bool,
    filter: PropTypes.bool,
    removeBottomPadding: PropTypes.bool,
};

const TemplateAdminStyled = withStyles(useStyles)(TemplateAdmin);

export { TemplateAdminStyled as TemplateAdmin };
