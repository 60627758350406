import { listPublicFutureEvents } from 'services/EventService';

import {
    EVENT_PAGE_LOADING,
    EVENT_PAGE_LOADING_SUCCESS,
    EVENT_PAGE_LOADING_FAILED,
} from './actionTypes';

export const listFutureEvents = (page = 1) => {
    return (dispatch) => {
        dispatch({ type: EVENT_PAGE_LOADING });

        listPublicFutureEvents({ page: page })
            .then((result) => {
                dispatch({
                    type: EVENT_PAGE_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((data) => {
                dispatch({
                    type: EVENT_PAGE_LOADING_FAILED,
                    payload: data,
                });
            });
    };
};
