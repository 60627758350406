import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const useStyles = (theme) => ({
    success: {
        backgroundColor: theme.colors.mediumLaurel,
        borderRadius: '50%',
        display: 'inline-block',
        height: '10px',
        marginLeft: '8px',
        width: '10px',
    },
    error: {
        backgroundColor: theme.colors.permanentGeraniumLake,
        borderRadius: '50%',
        display: 'inline-block',
        height: '10px',
        marginLeft: '8px',
        width: '10px',
    },
});

class Bullet extends Component {
    render() {
        const { type, classes } = this.props;

        return (
            <span
                className={classNames(
                    type === 'success' && classes.success,
                    type === 'error' && classes.error
                )}
            />
        );
    }
}

Bullet.propTypes = {
    type: PropTypes.oneOf(['error', 'success']),
    classes: PropTypes.object,
};

Bullet.defaultProps = {
    type: 'error',
};

const BulletStyled = withStyles(useStyles)(Bullet);
export { BulletStyled as Bullet };
