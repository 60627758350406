//
// Base urls should include the trailing slash '/'
//
let config = {
    // url: 'https://api.equento.be/',
    baseSiteUrl: 'https://www.equento.be/',
    url: 'https://selentic.herokuapp.com/',
    // baseSiteUrl: "https://equento-website.herokuapp.com/",
};

/* eslint-disable no-undef */
if (process.env.NODE_ENV !== 'production') {
    config = {
        // url: "http://127.0.0.1:8002/",
        // url: 'http://192.168.0.199:8002/',
        // url: 'http://192.168.1.6:8002/',
        url: 'http://10.216.7.125:8002/',
        // url: 'http://192.168.1.6:8000/',
        baseSiteUrl: 'http://localhost:3000/',
    };
}
/* eslint-enable no-undef */

export default config;
