import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';
import { getUrl, urls } from 'urls';
import Faq from 'react-faq-component';

import {
    CircularProgress,
    Container,
    Grid,
    Typography,
} from '@material-ui/core';

import { TemplateMarketing } from '..';

const useStyles = (theme) => ({
    header: {
        marginTop: theme.spacing(3),

        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(5),
        },

        '& h1, & h2': {
            lineHeight: 1,
            marginBottom: theme.spacing(2),

            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(10),
            },
        },

        '& p': {
            marginBottom: theme.spacing(4),
        },
    },
});

const faqStyles = {
    bgColor: 'transparent',
    rowContentPaddingTop: '16px',
    rowContentPaddingBottom: '24px',
};

class HelpPage extends Component {
    constructor(props) {
        super(props);

        // data
        this.state = {
            data: [
                {
                    title: 'Cat #1',
                    rows: [
                        {
                            title: 'Lore ipsum dolor site amet #1',
                            content:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat, ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus. In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae. Fusce sed commodo purus, at tempus turpis.',
                        },
                        {
                            title: 'Lore ipsum dolor site amet #2',
                            content:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed tempor sem. Aenean vel turpis feugiat, ultricies metus at, consequat velit. Curabitur est nibh, varius in tellus nec, mattis pulvinar metus. In maximus cursus lorem, nec laoreet velit eleifend vel. Ut aliquet mauris tortor, sed egestas libero interdum vitae. Fusce sed commodo purus, at tempus turpis.',
                        },
                    ],
                },
            ],
        };
    }

    render() {
        const { t, classes } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <TemplateMarketing>
                    <Helmet>
                        <title>Help | Equento</title>
                        <link
                            rel="canonical"
                            href={getUrl(urls.HELP, {}, true)}
                        />
                    </Helmet>
                    <Container>
                        <Grid container spacing={3} className={classes.header}>
                            <Typography variant={'h1'} className={'page-title'}>
                                {t('common:title.help')}
                            </Typography>
                            {this.state.data.map((obj) => (
                                <Faq data={obj} styles={faqStyles} />
                            ))}
                        </Grid>
                    </Container>
                </TemplateMarketing>
            </Suspense>
        );
    }
}

const HelpPageTranslated = withTranslation()(HelpPage);
const HelpPageRouter = withRouter(HelpPageTranslated);
const HelpPageStyled = withStyles(useStyles)(HelpPageRouter);

export { HelpPageStyled as HelpPage };
