import {
    acceptAuthorization as apiAcceptAuthorization,
    declineAuthorization as apiDeclineAuthorization,
} from 'services/AuthorizationsService';

import {
    AUTHORIZATION_ACCEPT_LOADING,
    AUTHORIZATION_ACCEPT_SUCCESS,
    AUTHORIZATION_ACCEPT_FAILED,
    AUTHORIZATION_DECLINE_LOADING,
    AUTHORIZATION_DECLINE_SUCCESS,
    AUTHORIZATION_DECLINE_FAILED,
} from './actionTypes';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

export const acceptAuthorization = ({ hash, key }) => {
    return (dispatch) => {
        dispatch({
            type: AUTHORIZATION_ACCEPT_LOADING,
            meta: { key: key },
        });

        apiAcceptAuthorization({ permission_hash: hash })
            .then((result) => {
                dispatch({
                    type: AUTHORIZATION_ACCEPT_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });

                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.authorization.accept.description'
                    ),
                });
            })
            .catch((error) => {
                dispatch({
                    type: AUTHORIZATION_ACCEPT_FAILED,
                    payload: error,
                    meta: { key: key },
                });

                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });
            });
    };
};

export const declineAuthorization = ({ hash, key }) => {
    return (dispatch) => {
        dispatch({
            type: AUTHORIZATION_DECLINE_LOADING,
            meta: { key: key },
        });

        apiDeclineAuthorization({ permission_hash: hash })
            .then((result) => {
                dispatch({
                    type: AUTHORIZATION_DECLINE_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });

                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.authorization.decline.description'
                    ),
                });
            })
            .catch((error) => {
                dispatch({
                    type: AUTHORIZATION_DECLINE_FAILED,
                    payload: error,
                    meta: { key: key },
                });

                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });
            });
    };
};
