export const SHOPPING_CART_LOADING = 'shopping_cart_loading';
export const SHOPPING_CART_LOADING_SUCCESS = 'shopping_cart_loading_success';
export const SHOPPING_CART_LOADING_FAILED = 'shopping_cart_loading_failed';

export const ADMIN_SHOPPING_CART_LOADING = 'admin_shopping_cart_loading';
export const ADMIN_SHOPPING_CART_LOADING_SUCCESS =
    'admin_shopping_cart_loading_success';
export const ADMIN_SHOPPING_CART_LOADING_FAILED =
    'admin_shopping_cart_loading_failed';

export const SHOPPING_CART_PAY_LOADING = 'shopping_cart_pay_loading';
export const SHOPPING_CART_PAY_SUCCESS = 'shopping_cart_pay_success';
export const SHOPPING_CART_PAY_FAILED = 'shopping_cart_pay_failed';

export const ADMIN_SHOPPING_CART_PAY_LOADING =
    'admin_shopping_cart_pay_loading';
export const ADMIN_SHOPPING_CART_PAY_SUCCESS =
    'admin_shopping_cart_pay_success';
export const ADMIN_SHOPPING_CART_PAY_FAILED = 'admin_shopping_cart_pay_failed';

export const ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_LOADING =
    'admin_shopping_cart_remove_discount_loading';
export const ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_SUCCESS =
    'admin_shopping_cart_remove_discount_success';
export const ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_FAILED =
    'admin_shopping_cart_remove_discount_failed';
