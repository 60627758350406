import {
    AUTHORIZATION_ACCEPT_LOADING,
    AUTHORIZATION_ACCEPT_SUCCESS,
    AUTHORIZATION_ACCEPT_FAILED,
    AUTHORIZATION_DECLINE_LOADING,
    AUTHORIZATION_DECLINE_SUCCESS,
    AUTHORIZATION_DECLINE_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isDeclining: false,
    isDeclined: false,
    errorDecline: null,

    isAccepting: false,
    isAccepted: false,
    errorAccept: null,
};

export default function (name = '') {
    return (state = INITIAL_STATE, action) => {
        if (!action.meta || action.meta.key !== name) {
            return state;
        }

        switch (action.type) {
            case AUTHORIZATION_DECLINE_LOADING:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isDeclining: true,
                };
            case AUTHORIZATION_DECLINE_SUCCESS:
                return {
                    ...state,
                    isDeclining: false,
                    isDeclined: true,
                };
            case AUTHORIZATION_DECLINE_FAILED:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isDeclining: false,
                    errorDecline: action.payload,
                };

            case AUTHORIZATION_ACCEPT_LOADING:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isAccepting: true,
                };
            case AUTHORIZATION_ACCEPT_SUCCESS:
                return {
                    ...state,
                    isAccepting: false,
                    isAccepted: true,
                };
            case AUTHORIZATION_ACCEPT_FAILED:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isAccepting: false,
                    errorAccept: action.payload,
                };

            default:
                return state;
        }
    };
}
