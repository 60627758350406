import { ALERT_SHOW, ALERT_HIDE } from "./actionTypes";

export const showAlert = ({ type, title, description }) => {
    return {
        type: ALERT_SHOW,
        payload: { type, title, description },
    };
};

export const hideAlert = ({ key }) => {
    return {
        type: ALERT_HIDE,
        payload: { key },
    };
};
