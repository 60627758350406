import React, { Component, Suspense, Spinner } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUrl, urls } from 'urls';
import { Helmet } from 'react-helmet';

import {
    Warning as WarningIcon,
    CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';

import { TemplateLogin } from '..';
import { EmptyMessage, Toast } from 'components';
import { activateUser, activateEmail } from './actions';

const useStyles = (theme) => ({
    green: {
        color: theme.colors.mediumLaurel,
    },
});

class AccountPage extends Component {
    constructor(props) {
        super(props);

        // set state
        this.state = {
            pageType: this.props.match.params.pageType,
            subpageType: this.props.match.params.subpageType,
        };
    }

    componentDidMount() {
        switch (this.state.pageType) {
            case 'activate':
                if (this.state.subpageType === 'email') {
                    this.props.activateEmail({
                        uid: this.props.match.params.uid,
                        token: this.props.match.params.token,
                    });
                } else {
                    this.props.activateUser({
                        uid: this.props.match.params.uid,
                        token: this.props.match.params.token,
                    });
                }
                break;
            default:
                break;
        }
    }

    renderEmailActivate() {
        const { t, emailActivateError } = this.props;

        return (
            <Suspense fallback={<Spinner />}>
                <Helmet>
                    <title>{t('common:title.email.activate')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateLogin
                    title={t('common:title.email.activate')}
                    description={t('common:text.email.activate_description')}
                >
                    <Toast
                        message={t('common:error.general.description')}
                        open={Boolean(emailActivateError)}
                    />
                    {emailActivateError ? (
                        <EmptyMessage
                            title={t('common:title.email.activating_error')}
                            description={t(
                                'common:text.email.activating_error_description'
                            )}
                            icon={<WarningIcon />}
                            textSize={'small'}
                            variant={'error'}
                        />
                    ) : (
                        <EmptyMessage
                            title={t('common:title.email.activating')}
                            description={t(
                                'common:text.email.activating_description'
                            )}
                            showLoader
                            textSize={'small'}
                        />
                    )}
                </TemplateLogin>
            </Suspense>
        );
    }

    renderEmailActivated() {
        const { t, classes } = this.props;

        return (
            <Suspense fallback={<Spinner />}>
                <Helmet>
                    <title>{t('common:title.email.activated')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateLogin
                    title={t('common:title.email.activated')}
                    description={t('common:text.email.activated_description')}
                >
                    {
                        <EmptyMessage
                            title={t('common:title.email.activated_end')}
                            description={t(
                                'common:text.email.activated_description_end'
                            )}
                            icon={
                                <CheckCircleIcon
                                    fontSize={'large'}
                                    className={classes.green}
                                />
                            }
                            textSize={'small'}
                        />
                    }
                </TemplateLogin>
            </Suspense>
        );
    }

    renderAccountActivate() {
        const { t, accountActivateError } = this.props;

        return (
            <Suspense fallback={<Spinner />}>
                <Helmet>
                    <title>
                        {t('common:title.account.activate')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateLogin
                    title={t('common:title.account.activate')}
                    description={t('common:text.account.activate_description')}
                >
                    <Toast
                        message={t('common:error.general.description')}
                        open={Boolean(accountActivateError)}
                    />
                    {accountActivateError ? (
                        <EmptyMessage
                            title={t('common:title.account.activating_error')}
                            description={t(
                                'common:text.account.activating_error_description'
                            )}
                            icon={<WarningIcon />}
                            textSize={'small'}
                            variant={'error'}
                        />
                    ) : (
                        <EmptyMessage
                            title={t('common:title.account.activating')}
                            description={t(
                                'common:text.account.activating_description'
                            )}
                            showLoader
                            textSize={'small'}
                        />
                    )}
                </TemplateLogin>
            </Suspense>
        );
    }

    renderAccountActivated() {
        const { t, classes } = this.props;

        return (
            <Suspense fallback={<Spinner />}>
                <Helmet>
                    <title>
                        {t('common:title.account.activated')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateLogin
                    title={t('common:title.account.activated')}
                    description={t('common:text.account.activated_description')}
                >
                    {
                        <EmptyMessage
                            title={t('common:title.account.activated_end')}
                            description={t(
                                'common:text.account.activated_description_end'
                            )}
                            icon={
                                <CheckCircleIcon
                                    fontSize={'large'}
                                    className={classes.green}
                                />
                            }
                            textSize={'small'}
                            actionText={t('common:button.login')}
                            action={() =>
                                this.props.history.push(getUrl(urls.LOGIN))
                            }
                        />
                    }
                </TemplateLogin>
            </Suspense>
        );
    }

    render() {
        const { isAccountActivated, isEmailActivated } = this.props;

        switch (this.state.pageType) {
            case 'activate':
                if (this.state.subpageType === 'email') {
                    if (isEmailActivated) {
                        return this.renderEmailActivated();
                    } else {
                        return this.renderEmailActivate();
                    }
                } else if (isAccountActivated) {
                    return this.renderAccountActivated();
                } else {
                    return this.renderAccountActivate();
                }
            default:
                break;
        }

        return;
    }
}

const mapStateToProps = (state) => {
    const {
        isAccountActvating,
        accountActivateError,
        isAccountActivated,

        isEmailActvating,
        emailActivateError,
        isEmailActivated,
    } = state.pageAccount;

    return {
        isAccountActvating,
        accountActivateError,
        isAccountActivated,

        isEmailActvating,
        emailActivateError,
        isEmailActivated,
    };
};

const AccountPageTranslated = withTranslation()(AccountPage);
const AccountPageStyled = withStyles(useStyles)(AccountPageTranslated);
const AccountPageRouter = withRouter(AccountPageStyled);
const AccountPageState = connect(mapStateToProps, {
    activateUser,
    activateEmail,
})(AccountPageRouter);

export { AccountPageState as AccountPage };
