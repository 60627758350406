import _ from 'lodash';
import {
    AUTHORIZATIONS_LOADING,
    AUTHORIZATIONS_LOADING_SUCCESS,
    AUTHORIZATIONS_LOADING_FAILED,
} from '../actions/actionTypes.js';
import { getAuthorizationsKey } from '../utils';

const INITIAL_STATE = {
    isLoading: false,
    authorizations: null,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AUTHORIZATIONS_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };

        case AUTHORIZATIONS_LOADING_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                authorizations: _.reduce(
                    action.payload.results,
                    function (result, value, key) {
                        result[
                            getAuthorizationsKey(
                                value.content_object.id,
                                value.content_object_type
                            )
                        ] = value;
                        return result;
                    },
                    {}
                ),
            };

        case AUTHORIZATIONS_LOADING_FAILED:
            return {
                ...state,
                ...INITIAL_STATE,
                error: action.payload,
            };

        default:
            return state;
    }
};
