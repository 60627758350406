import React from 'react';
import { Translation } from 'react-i18next';
import {
    DashboardOutlined as DashboardIcon,
    // TimelineOutlined as TimelineIcon,
    LocalMallOutlined as OrdersIcon,
    EventNoteOutlined as EventNodeIcon,
    HomeWorkOutlined as HomeWorkIcon,
} from '@material-ui/icons';
import { getUrl, urls } from 'urls';

export const routes = [
    {
        path: getUrl(urls.ADMIN_DASHBOARD),
        name: (
            <Translation>
                {(t) => t('common:title.admin.dashboard')}
            </Translation>
        ),
        // component: AdminDashboardPage,
        icon: <DashboardIcon />,
    },
    {
        path: getUrl(urls.ADMIN_CLUBS),
        name: <Translation>{(t) => t('common:title.admin.clubs')}</Translation>,
        // component: AdminClubsPage,
        icon: <HomeWorkIcon />,
    },
    {
        path: getUrl(urls.ADMIN_EVENTS),
        name: (
            <Translation>{(t) => t('common:title.admin.events')}</Translation>
        ),
        // component: AdminEventsPage,
        icon: <EventNodeIcon />,
    },
    // {
    //     path: getUrl(urls.ADMIN_STATISTICS),
    //     name: props.t('common:title.admin.statistics'),
    //     component: AdminStatisticsPage,
    //     icon: <TimelineIcon />,
    // },
    {
        path: getUrl(urls.ADMIN_ORDERS),
        name: (
            <Translation>{(t) => t('common:title.admin.orders')}</Translation>
        ),
        // component: AdminOrdersPage,
        icon: <OrdersIcon />,
    },
];
