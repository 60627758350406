import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Grid, Hidden, Toolbar } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import { MobileMenu, ShoppingCart, UserMenu } from 'components';
import { topbarPublicMenuRoutes } from '../routes';
import { getUrl, urls } from 'urls';

import logoSvg from 'assets/images/equento-logo.svg';

const useStyles = (theme) => ({
    actions: {
        textAlign: 'right',

        [theme.breakpoints.down('sm')]: {
            '& svg': {
                // fontSize: '1.25rem',
                marginLeft: theme.spacing(0.5),
            },

            '& button[type="button"]': {
                margin: 0,
                padding: '8px',
            },
        },
    },
    logo: {
        display: 'inline-block',
        // height: "30px",
        // paddingLeft: 0,

        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(0.5),
            // height: "25px",

            '& img': {
                height: '25px',
            },
        },

        [theme.breakpoints.down('sm')]: {
            '& img': {
                width: '120px',
            },
        },
    },
});

class ToolbarPublic extends Component {
    render() {
        const { classes, isLoggedIn } = this.props;

        return (
            <Toolbar disableGutters>
                <Container maxWidth={'lg'}>
                    <Grid
                        container
                        spacing={2}
                        display={'flex'}
                        justify={'space-between'}
                        alignItems={'center'}
                    >
                        <Grid item xs={3}>
                            <NavLink to="/" className={classes.logo}>
                                <img
                                    src={logoSvg}
                                    alt="Equento"
                                    className="logo"
                                />
                            </NavLink>
                        </Grid>
                        <Grid item xs={9} className={classes.actions}>
                            <Hidden smDown>
                                <nav className="main">
                                    <ul>
                                        {topbarPublicMenuRoutes.map(
                                            (route, i) =>
                                                !route.hidden &&
                                                ((!route.hideWhenAutenticated &&
                                                    isLoggedIn) ||
                                                    !isLoggedIn) ? (
                                                    <li key={i}>
                                                        <NavLink
                                                            exact
                                                            key={i}
                                                            to={route.path}
                                                            activeClassName="selected"
                                                        >
                                                            <Button
                                                                disableElevation
                                                                variant={
                                                                    route.path ===
                                                                    getUrl(
                                                                        urls.LOGIN
                                                                    )
                                                                        ? 'contained'
                                                                        : 'text'
                                                                }
                                                                color={
                                                                    route.path ===
                                                                    getUrl(
                                                                        urls.LOGIN
                                                                    )
                                                                        ? 'secondary'
                                                                        : 'default'
                                                                }
                                                            >
                                                                {route.name}
                                                            </Button>
                                                        </NavLink>
                                                    </li>
                                                ) : null
                                        )}

                                        {isLoggedIn && (
                                            <li>
                                                <UserMenu />
                                            </li>
                                        )}
                                        <li>
                                            <ShoppingCart />
                                        </li>
                                    </ul>
                                </nav>
                            </Hidden>
                            <Hidden mdUp>
                                <ShoppingCart />
                                <MobileMenu {...this.props} />
                            </Hidden>
                        </Grid>
                    </Grid>
                </Container>
            </Toolbar>
        );
    }
}

ToolbarPublic.propTypes = {
    // props
    isLoggedIn: PropTypes.bool,
    classes: PropTypes.object,
    // handlers
};

const ToolbarPublicStyled = withStyles(useStyles)(ToolbarPublic);

export { ToolbarPublicStyled as ToolbarPublic };
