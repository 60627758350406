import {
    DIALOG_SUBSCRIPTION_LOADING,
    DIALOG_SUBSCRIPTION_SUCCESS,
    DIALOG_SUBSCRIPTION_FAILED,

    DIALOG_SUBSCRIPTION_LOCK_LOADING,
    DIALOG_SUBSCRIPTION_LOCK_SUCCESS,
    DIALOG_SUBSCRIPTION_LOCK_FAILED,

    DIALOG_SUBSCRIPTION_LOCK_DELETE_LOADING,
    DIALOG_SUBSCRIPTION_LOCK_DELETE_SUCCESS,
    DIALOG_SUBSCRIPTION_LOCK_DELETE_FAILED,

    ADMIN_DIALOG_BASKET_SUBSCRIPTION_LOADING,
    ADMIN_DIALOG_BASKET_SUBSCRIPTION_SUCCESS,
    ADMIN_DIALOG_BASKET_SUBSCRIPTION_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    errors: null,
    newSubscription: null,

    isLoadingLock: false,
    isLocked: false,
    lockError: false,
    lock: null,
    isDeletingLock: false,
};

export default function (name = '') {
    return (state = INITIAL_STATE, action) => {
        if (!action.meta || action.meta.key !== name) {
            return state;
        }

        switch (action.type) {
            case DIALOG_SUBSCRIPTION_LOCK_LOADING:
                return {
                    ...state,
                    isLoadingLock: true,
                };
            case DIALOG_SUBSCRIPTION_LOCK_SUCCESS:
                return {
                    ...state,
                    isLoadingLock: false,
                    isLocked: true,
                    lock: action.payload,
                };
            case DIALOG_SUBSCRIPTION_LOCK_FAILED:
                return {
                    ...state,
                    isLoadingLock: false,
                    lockError: action.payload,
                };

            case DIALOG_SUBSCRIPTION_LOCK_DELETE_LOADING:
                return {
                    ...state,
                    isDeletingLock: true,
                };
            case DIALOG_SUBSCRIPTION_LOCK_DELETE_SUCCESS:
                return {
                    ...state,
                    isDeletingLock: false,
                    isLocked: false,
                    lock: null,
                };
            case DIALOG_SUBSCRIPTION_LOCK_DELETE_FAILED:
                return {
                    ...state,
                    isDeletingLock: false,
                    lockError: action.payload,
                };

            case ADMIN_DIALOG_BASKET_SUBSCRIPTION_LOADING:
            case DIALOG_SUBSCRIPTION_LOADING:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isLoading: true,
                };
            case ADMIN_DIALOG_BASKET_SUBSCRIPTION_SUCCESS:
            case DIALOG_SUBSCRIPTION_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    newSubscription: action.payload,
                };
            case ADMIN_DIALOG_BASKET_SUBSCRIPTION_FAILED:
            case DIALOG_SUBSCRIPTION_FAILED:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isLoading: false,
                    errors: action.payload,
                };

            default:
                return state;
        }
    };
}
