import {
    EVENT_LOADING,
    EVENT_LOADING_SUCCESS,
    EVENT_LOADING_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    event: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EVENT_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case EVENT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                event: action.payload,
            };
        case EVENT_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
