import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Avatar,
    Container,
    Grid,
    Hidden,
    IconButton,
    Toolbar,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { NavLink } from 'react-router-dom';
import { ArrowBackOutlined as ArrowBackIcon } from '@material-ui/icons';
import EventService from 'lib/EventService';
import { getItem } from 'services/StorageService';
import { AdminMobileMenu, ShoppingCart } from 'components';
import { EVENT } from 'enums';

import logoSvg from 'assets/images/equento-logo.svg';

const useStyles = (theme) => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'center',

        transition: theme.transitions.create(['padding'], {
            duration: theme.transitions.duration.standard,
        }),
    },
    adminDrawerOpen: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '220px',
        },
    },
    adminDrawerClosed: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: '90px',
        },
    },
    adminbarContent: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',

        transition: theme.transitions.create(['padding'], {
            duration: theme.transitions.duration.standard,
        }),

        [theme.breakpoints.up('1280')]: {
            maxWidth: '1400px',
        },
    },
    adminWrapper: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '1400px',
    },
    titleAdmin: {
        display: 'flex',
        alignItems: 'center',

        '& button': {
            marginRight: theme.spacing(2),
            marginLeft: -theme.spacing(1.5),
        },

        '& span': {
            fontWeight: '800',
            fontFamily: 'Nunito',
            fontSize: '1.25rem',
        },
    },
    adminActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        '&> *': {
            marginLeft: theme.spacing(1),
        },

        [theme.breakpoints.down('sm')]: {
            '& svg': {
                fontSize: '1.25rem',
            },

            '& button[type="button"]': {
                margin: 0,
                padding: 0,
            },
        },
    },
    actions: {
        textAlign: 'right',

        [theme.breakpoints.down('sm')]: {
            '& svg': {
                // fontSize: '1.25rem',
                marginLeft: theme.spacing(0.5),
            },

            '& button[type="button"]': {
                margin: 0,
                padding: '8px',
            },
        },
    },
    logo: {
        display: 'inline-block',
        height: '30px',

        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(0.5),
            height: '25px',

            '& img': {
                height: '25px',
            },
        },

        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            // padding: '24px',

            '& img': {
                width: '120px',
            },
        },
    },
});

class ToolbarAdmin extends Component {
    constructor(props) {
        super(props);

        this.setDrawerOpen = this.setDrawerOpen.bind(this);

        this.state = {
            adminDrawerOpen: getItem('adminDrawerOpen') === true,
        };
    }

    componentDidMount() {
        EventService.subscribe(
            EVENT.ADMIN_DRAWER_UPDATED,
            'toolbaradmin',
            this.setDrawerOpen
        );
    }

    componentWillUnmount() {
        EventService.unsubscribe(EVENT.ADMIN_DRAWER_UPDATED, 'toolbaradmin');
    }

    setDrawerOpen({ drawerOpen }) {
        this.setState({
            adminDrawerOpen: drawerOpen,
        });
    }

    render() {
        const { classes, adminBackAction, adminTitle } = this.props;

        const renderAdminAction = () => (
            <React.Fragment>
                {adminBackAction && (
                    <IconButton onClick={adminBackAction}>
                        <ArrowBackIcon />
                    </IconButton>
                )}
                {adminTitle && <span>{adminTitle}</span>}
            </React.Fragment>
        );

        return (
            <Toolbar disableGutters>
                <Container maxWidth={false} className={classes.wrapper}>
                    <Grid
                        spacing={2}
                        container
                        display="flex"
                        justify="center"
                        alignItems="center"
                        className={classNames(
                            classes.wrapper,
                            this.state.adminDrawerOpen
                                ? classes.adminDrawerOpen
                                : classes.adminDrawerClosed
                        )}
                    >
                        <Hidden smDown>
                            <Grid item xs={12} className={classes.adminWrapper}>
                                <div className={classes.adminbarContent}>
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.titleAdmin}
                                    >
                                        {renderAdminAction()}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.adminActions}
                                    >
                                        <ShoppingCart admin />
                                        <Avatar variant={'rounded'}>
                                            {this.props.user?.first_name[0]}
                                            {this.props.user?.last_name[0]}
                                        </Avatar>
                                    </Grid>
                                </div>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp>
                            <Grid item xs={3}>
                                <NavLink to="/" className={classes.logo}>
                                    <img
                                        src={logoSvg}
                                        alt="Equento"
                                        className="logo"
                                    />
                                </NavLink>
                            </Grid>
                            <Grid item xs={9} className={classes.actions}>
                                <ShoppingCart admin />
                                <AdminMobileMenu {...this.props} />
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </Toolbar>
        );
    }
}

ToolbarAdmin.propTypes = {
    // props
    classes: PropTypes.object,
    adminBackAction: PropTypes.func,
    adminTitle: PropTypes.string,
    user: PropTypes.object,
    // handlers
};

const mapStateToProps = (state) => {
    const { user } = state.user;

    return {
        user,
    };
};

const ToolbarAdminStyled = withStyles(useStyles)(ToolbarAdmin);
const ToolbarAdminState = connect(mapStateToProps)(ToolbarAdminStyled);

export { ToolbarAdminState as ToolbarAdmin };
