import React from "react";
import classNames from "classnames";
// import 'date-fns';
import { FormControl, InputLabel } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Controller } from "react-hook-form";

import PropTypes from "prop-types";

function DateTimePicker(props) {
    const renderChildren = () => {
        if (props.children) {
            return <div className="children">{props.children}</div>;
        }
    };

    const renderError = () => {
        const { error } = props;

        if (error) {
            return <small className="error">{error}</small>;
        }
    };

    return (
        <FormControl
            className={classNames(
                "form-control",
                props.removeSpacing && "no-spacing"
            )}
        >
            <InputLabel shrink required={props.required}>
                {props.label}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                    control={props.control}
                    as={
                        <KeyboardDateTimePicker
                            required={props.required}
                            format="dd/MM/yyyy HH:mm"
                            ampm={false}
                            KeyboardButtonProps={{
                                "aria-label": "choose date",
                            }}
                        />
                    }
                    value={props.watch(props.name)}
                    defaultValue={null}
                    onChange={(date) => {
                        props.setValue(props.name, date);
                        return { value: date }; //important to update the controller value after change else state is updated and the controller will not render
                    }}
                    name={props.name}
                    initialFocusedDate={null}
                    rules={{ required: props.required }}
                />
            </MuiPickersUtilsProvider>
            {renderChildren()}
            {renderError()}
        </FormControl>
    );
}

DateTimePicker.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    innerRef: PropTypes.func.isRequired,
    required: PropTypes.bool,
    children: PropTypes.object,
    error: PropTypes.object,
    defaultValue: PropTypes.object,
    control: PropTypes.object.isRequired,
    removeSpacing: PropTypes.bool,
    watch: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
};

// use ref forwarding for react-hook-form to work with class based components
const DateTimePickerStyled = React.forwardRef((props, ref) => (
    <DateTimePicker innerRef={ref} {...props} />
));

export { DateTimePickerStyled as DateTimePicker };
