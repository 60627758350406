import {
    GLOBAL_LOADING_START,
    GLOBAL_LOADING_END,
} from '../actions/actionTypes';

const INITIAL_STATE = {
    isLoading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GLOBAL_LOADING_START:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };

        case GLOBAL_LOADING_END:
            return {
                ...state,
                ...INITIAL_STATE,
            };

        default:
            return state;
    }
};
