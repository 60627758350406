import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { getUrl, urls } from 'urls';
import PropTypes from 'prop-types';
import { Animated } from 'react-animated-css';
import { InView } from 'react-intersection-observer';

import {
    CircularProgress,
    Container,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    EditOutlined as EditIcon,
    PaymentOutlined as PaymentIcon,
    FaceOutlined as FaceIcon,
    WarningOutlined as WarningIcon,
    CheckCircleOutlined as CheckCircleIcon,
} from '@material-ui/icons';

import { TemplateMarketing } from '..';
import {
    Button,
    CardEvent,
    EmptyMessage,
    USPList,
    USPImageBlock,
} from 'components';

import { listEvents } from './actions';

import ImgHeader from 'assets/images/image-with-qrcode-en.svg';
import ImgSubscriptionAdd from 'assets/images/subscription-add-en.svg';
import ImgTickets from 'assets/images/tickets.svg';
import ImgTicket from 'assets/images/ticket.svg';
import ImgEvents from 'assets/images/events.svg';
import ImgPaymentMethods from 'assets/images/payment-methods.svg';
import ImgOrganiser from 'assets/images/organiser.svg';

const useStyles = (theme) => ({
    header: {
        alignItems: 'center',
        marginTop: theme.spacing(3),

        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(5),
        },

        '& h1, & h2': {
            lineHeight: 1,
            marginBottom: theme.spacing(2),

            [theme.breakpoints.up('sm')]: {
                marginTop: theme.spacing(0),
            },
        },

        '& h1': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '40px',
            },
        },

        '& p': {
            marginBottom: theme.spacing(4),
        },
    },
    headerImage: {
        marginTop: theme.spacing(6),
        textAlign: 'center',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            marginTop: theme.spacing(0),
            textAlign: 'right',
            width: '90%',
        },

        '& img': {
            display: 'inline-block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '80%',
        },
    },
    content: {
        padding: '96px 0',

        '& h2': {
            marginBottom: theme.spacing(1),
        },
    },
    // ticketContainer: {
    //     backgroundImage: 'url(' + ImgTicket + ')',
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize: '40%',
    // },
    center: {
        textAlign: 'center',
    },
    contentSmall: {
        padding: '48px 0',
        textAlign: 'center',

        '& h2': {
            marginBottom: '8px',
            marginTop: '24px',
        },

        '& button': {
            marginTop: '32px',
        },
    },
    eventsContainer: {
        margin: '32px 0',
    },
    uspList: {
        margin: '40px 0',
    },
    textRight: {
        textAlign: 'right',
    },
    ticketContainer: {
        '& img': {
            display: 'block',
            margin: 'auto',
            textAlign: 'center',
        },
    },
});

class HomePage extends Component {
    constructor(props) {
        super(props);

        // data
        this.state = {
            uspList: [
                {
                    icon: PaymentIcon,
                    title: props.t('common:usp.title.safe_online_payment'),
                    description: props.t(
                        'common:usp.description.safe_online_payment'
                    ),
                },
                {
                    icon: EditIcon,
                    title: props.t('common:usp.title.fast_subscribing'),
                    description: props.t(
                        'common:usp.description.fast_subscribing'
                    ),
                },
                {
                    icon: FaceIcon,
                    title: props.t('common:usp.title.for_riders'),
                    description: props.t('common:usp.description.for_riders'),
                },
            ],
            subscribeUSPList: [
                {
                    title: props.t('common:usp.title.find_event'),
                    description: props.t('common:usp.description.find_event'),
                    image: {
                        src: ImgEvents,
                        alt: props.t('common:img.alt.home.event'),
                    },
                },
                {
                    title: props.t('common:usp.title.subscribe_yourself'),
                    description: props.t(
                        'common:usp.description.subscribe_yourself'
                    ),
                    image: {
                        src: ImgSubscriptionAdd,
                        alt: props.t('common:img.alt.home.subscription'),
                    },
                },
                {
                    title: props.t('common:usp.title.pay_order'),
                    description: props.t('common:usp.description.pay_order'),
                    image: {
                        src: ImgPaymentMethods,
                        alt: props.t('common:img.alt.home.payment_methods'),
                    },
                },
                {
                    title: props.t('common:usp.title.receive_tickets'),
                    description: props.t(
                        'common:usp.description.receive_tickets'
                    ),
                    image: {
                        src: ImgTicket,
                        alt: props.t('common:img.alt.home.ticket'),
                    },
                },
            ],
        };
    }

    componentDidMount() {
        this.props.listEvents();
    }

    renderEventItems() {
        const { events, classes, isLoading, error, t } = this.props;

        if (error) {
            return (
                <EmptyMessage
                    icon={<WarningIcon />}
                    title={t('common:error.general.title')}
                    description={t('common:error.general.description_long')}
                    variant={'error'}
                    textSize={'small'}
                />
            );
        }

        if (isLoading) {
            return (
                <Grid container spacing={3}>
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        className={classes.eventsContainer}
                    >
                        <CardEvent isLoading />
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        className={classes.eventsContainer}
                    >
                        <CardEvent isLoading />
                    </Grid>
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        className={classes.eventsContainer}
                    >
                        <CardEvent isLoading />
                    </Grid>
                </Grid>
            );
        }

        if (events.length === 0) {
            return (
                <Grid item xs={12} classNames={classes.eventsContainer}>
                    <small>{t('common:text.event.no_upcoming_events')}</small>
                </Grid>
            );
        }

        return (
            <Grid
                container
                spacing={3}
                alignItems={'center'}
                justify={'center'}
            >
                {events.map((event, index) => {
                    return (
                        <Grid
                            key={index}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            className={classes.eventsContainer}
                        >
                            <CardEvent
                                id={event.id}
                                name={event.name}
                                club={event.club}
                                image={event.header_image}
                                days={event.days}
                                closed={event.subscriptions_closed}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    renderEvents() {
        const { classes, t } = this.props;

        return (
            <div className={classNames(classes.content, classes.center)}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant={'h2'}>
                                {t('common:title.home.events_upcoming')}
                            </Typography>
                            <Typography>
                                {t('common:description.home.events_upcoming')}
                            </Typography>
                        </Grid>

                        {this.renderEventItems()}

                        <Grid item xs={12}>
                            <Button
                                color={'secondary'}
                                onClick={() =>
                                    this.props.history.push(getUrl(urls.EVENTS))
                                }
                            >
                                {t('common:button.view_events')}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }

    render() {
        const { t, classes } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <TemplateMarketing>
                    <Helmet>
                        <title>
                            The online subscription system for the equine world
                            | Equento
                        </title>
                        <link
                            rel="canonical"
                            href={getUrl(urls.HOME, {}, true)}
                        />
                    </Helmet>
                    <Container>
                        <Grid container spacing={3} className={classes.header}>
                            <InView>
                                {({ inView, ref, entry }) => {
                                    return (
                                        <Grid ref={ref} item xs={12} sm={6}>
                                            <Animated
                                                animationIn="fadeInDown"
                                                animationOut="fadeOut"
                                                isVisible={inView}
                                            >
                                                <Typography variant={'h1'}>
                                                    {t(
                                                        'common:title.home.main'
                                                    )}
                                                </Typography>
                                                <Typography>
                                                    {t(
                                                        'common:description.home.main'
                                                    )}
                                                </Typography>
                                                <Button
                                                    color={'secondary'}
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            getUrl(urls.EVENTS)
                                                        )
                                                    }
                                                >
                                                    {t(
                                                        'common:button.view_events'
                                                    )}
                                                </Button>
                                            </Animated>
                                        </Grid>
                                    );
                                }}
                            </InView>
                            <InView>
                                {({ inView, ref, entry }) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={6}
                                            className={classes.headerImage}
                                            ref={ref}
                                        >
                                            <Animated
                                                animationIn="fadeInRight"
                                                animationOut="fadeOut"
                                                isVisible={inView}
                                            >
                                                <img
                                                    src={ImgHeader}
                                                    alt={t(
                                                        'common:img.alt.home.header'
                                                    )}
                                                />
                                            </Animated>
                                        </Grid>
                                    );
                                }}
                            </InView>
                        </Grid>
                    </Container>

                    <USPList items={this.state.uspList} />

                    <div className={classNames(classes.header, 'content-blue')}>
                        <Container>
                            <Grid
                                container
                                spacing={3}
                                alignItems={'center'}
                                className={classes.ticketContainer}
                            >
                                <InView>
                                    {({ inView, ref }) => {
                                        return (
                                            <React.Fragment>
                                                <Grid
                                                    ref={ref}
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                >
                                                    <Animated
                                                        animationIn="fadeInLeft"
                                                        animationOut="fadeOut"
                                                        isVisible={inView}
                                                    >
                                                        <img
                                                            src={ImgTickets}
                                                            alt={t(
                                                                'common:img.alt.home.tickets'
                                                            )}
                                                            width="90%"
                                                        />
                                                    </Animated>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <Animated
                                                        animationIn="fadeIn"
                                                        animationOut="fadeOut"
                                                        isVisible={inView}
                                                    >
                                                        <Typography
                                                            variant={'h2'}
                                                        >
                                                            <span
                                                                dangerouslySetInnerHTML={{
                                                                    __html: t(
                                                                        'common:title.home.administration',
                                                                        {
                                                                            interpolation: {
                                                                                escapeValue: false,
                                                                            },
                                                                        }
                                                                    ),
                                                                }}
                                                            />
                                                        </Typography>
                                                        <Typography>
                                                            {t(
                                                                'common:description.home.administration'
                                                            )}
                                                        </Typography>
                                                        <Button
                                                            variant={'text'}
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    getUrl(
                                                                        urls.EVENTS
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            {t(
                                                                'common:button.view_events'
                                                            )}
                                                        </Button>
                                                    </Animated>
                                                </Grid>
                                            </React.Fragment>
                                        );
                                    }}
                                </InView>
                            </Grid>
                        </Container>
                    </div>

                    <div className={classes.content}>
                        <USPImageBlock
                            title={t('common:title.home.subscribing', {
                                interpolation: { escapeValue: false },
                            })}
                            items={this.state.subscribeUSPList}
                            ctaText={t('common:button.view_events')}
                            ctaAction={() =>
                                this.props.history.push(getUrl(urls.EVENTS))
                            }
                        />
                    </div>

                    <div
                        className={classNames(classes.header, 'content-green')}
                    >
                        <Container>
                            <InView>
                                {({ inView, ref }) => {
                                    return (
                                        <Grid
                                            ref={ref}
                                            container
                                            spacing={3}
                                            alignItems={'center'}
                                        >
                                            <Grid item xs={12} sm={6}>
                                                <Animated
                                                    animationIn="fadeInLeft"
                                                    animationOut="fadeOut"
                                                    isVisible={inView}
                                                >
                                                    <img
                                                        src={ImgOrganiser}
                                                        alt={t(
                                                            'common:img.alt.home.organiser'
                                                        )}
                                                        width={'90%'}
                                                    />
                                                </Animated>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Animated
                                                    animationIn="fadeIn"
                                                    animationOut="fadeOut"
                                                    isVisible={inView}
                                                >
                                                    <Typography variant={'h2'}>
                                                        <span
                                                            dangerouslySetInnerHTML={{
                                                                __html: t(
                                                                    'common:title.home.organisers',
                                                                    {
                                                                        interpolation: {
                                                                            escapeValue: false,
                                                                        },
                                                                    }
                                                                ),
                                                            }}
                                                        />
                                                    </Typography>
                                                    <ul
                                                        className={classNames(
                                                            'check',
                                                            classes.uspList
                                                        )}
                                                    >
                                                        <li>
                                                            <CheckCircleIcon />
                                                            {t(
                                                                'common:description.home.organisers_administation'
                                                            )}
                                                        </li>
                                                        <li>
                                                            <CheckCircleIcon />
                                                            {t(
                                                                'common:description.home.organisers_pay_online'
                                                            )}
                                                        </li>
                                                        <li>
                                                            <CheckCircleIcon />
                                                            {t(
                                                                'common:description.home.organisers_secretariat'
                                                            )}
                                                        </li>
                                                    </ul>
                                                    <Button
                                                        color={'secondary'}
                                                        onClick={() =>
                                                            this.props.history.push(
                                                                getUrl(
                                                                    urls.ORGANISERS
                                                                )
                                                            )
                                                        }
                                                    >
                                                        {t(
                                                            'common:button.view_advantages'
                                                        )}
                                                    </Button>
                                                </Animated>
                                            </Grid>
                                        </Grid>
                                    );
                                }}
                            </InView>
                        </Container>
                    </div>

                    {this.renderEvents()}
                </TemplateMarketing>
            </Suspense>
        );
    }
}

HomePage.propTypes = {
    // props
    isLoading: PropTypes.bool,
    events: PropTypes.object,
    error: PropTypes.string,
    history: PropTypes.object,
    classes: PropTypes.object,
    // handlers
    t: PropTypes.func.isRequired,
    listEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { isLoading, error, events } = state.pageHome;

    return {
        isLoading,
        error,
        events,
    };
};

const HomePageTranslated = withTranslation()(HomePage);
const HomePageRouter = withRouter(HomePageTranslated);
const HomePageStyled = withStyles(useStyles)(HomePageRouter);
const HomePageState = connect(mapStateToProps, {
    listEvents,
})(HomePageStyled);

export { HomePageState as HomePage };
