import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import { Dialog } from 'components';

import { addUserToClub, changeUserFromClub } from './actions';
import { InviteUserForm } from './form';

const useStyles = (theme) => ({
    appbar: {
        backgroundColor: theme.colors.white,
        color: theme.colors.mikado,
        position: 'fixed',

        '& .MuiPaper-root.MuiAppBar-root': {
            backgroundColor: theme.colors.white,
            color: theme.colors.mikado,
        },
    },
    dialog: {
        backgroundColor: theme.colors.springWood,
        color: theme.colors.mikado,
    },
    middle: {
        alignItems: 'center',
        minHeight: '100%',
        padding: '64px 0',
    },
    alert: {
        marginTop: '32px',
    },
});

class DialogInviteUser extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {};

        // handlers
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.newRequest !== this.props.newRequest &&
            this.props.newRequest
        ) {
            this.handleClose(this.props.newRequest);
        }
    }

    componentWillUnmount() {
        // when unmounting, make sure it's closed
        // this.handleClose();
    }

    handleSubmit(data) {
        if (this.props.invitation) {
            // edit
            this.props.changeUserFromClub({
                id: this.props.club.id,
                ...data,
            });
        } else {
            // add
            this.props.addUserToClub({
                id: this.props.club.id,
                ...data,
            });
        }
    }

    handleClose(obj) {
        const { onClose } = this.props;

        if (onClose) {
            onClose(obj);
        }
    }

    render() {
        const { t, open, email, invitation } = this.props;

        if (email && !invitation) {
            return this.renderLoading();
        }

        return (
            <Dialog
                open={open}
                onClose={() => this.handleClose()}
                title={t('common:title.add_user')}
                smallFooter
                showLine
                noFooter
            >
                <Grid container>
                    <InviteUserForm
                        action={this.handleSubmit}
                        isLoading={this.props.isLoading}
                        errors={this.props.errors}
                        data={invitation}
                    />
                </Grid>
            </Dialog>
        );
    }
}

DialogInviteUser.propTypes = {
    // props
    open: PropTypes.bool,
    email: PropTypes.string,
    invitation: PropTypes.object,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
    club: PropTypes.object.isRequired,
    newRequest: PropTypes.object,
    // handlers
    addUserToClub: PropTypes.func.isRequired,
    changeUserFromClub: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

DialogInviteUser.defaultProps = {
    open: false,
};

const mapStateToProps = (state) => {
    const {
        // add
        isAddingUser,
        errorAddingUser,
        newRequest,
        // change
        isChangingUser,
        isChanged,
        errorChangingUser,
    } = state.dialogInviteUser;

    return {
        isLoading: isAddingUser || isChangingUser,
        errors: Object.assign({}, errorAddingUser, errorChangingUser),
        isAddingUser,
        errorAddingUser,
        newRequest,
        isChangingUser,
        isChanged,
        errorChangingUser,
    };
};

const DialogInviteUserTranslated = withTranslation()(DialogInviteUser);
const DialogInviteUserStyled = withStyles(useStyles)(
    DialogInviteUserTranslated
);
const DialogInviteUserState = connect(mapStateToProps, {
    addUserToClub,
    changeUserFromClub,
})(DialogInviteUserStyled);

export { DialogInviteUserState as DialogInviteUser };
