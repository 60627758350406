import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import TranslationUtil from 'lib/TranslationUtil';

function LanguageSelector() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [language, setLanguage] = React.useState(
        TranslationUtil.getTranslations().language
    );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeLanguage = (lang) => {
        setLanguage(lang);
        TranslationUtil.getTranslations().changeLanguage(lang);
        handleClose();
    };

    return (
        <React.Fragment>
            <Button
                aria-controls="language-menu"
                aria-haspopup="true"
                style={{ boxShadow: 'none' }}
                color="secondary"
                variant="contained"
                onClick={handleClick}
            >
                {language === 'nl' ? 'Nederlands' : 'English'}
                <KeyboardArrowDownIcon />
            </Button>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    selected={language === 'nl'}
                    onClick={() => handleChangeLanguage('nl')}
                >
                    Nederlands
                </MenuItem>
                <MenuItem
                    selected={language === 'en'}
                    onClick={() => handleChangeLanguage('en')}
                >
                    English
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

export { LanguageSelector };
