import {
    ADMIN_COMBINATION_CONFIRM_LOADING,
    ADMIN_COMBINATION_CONFIRM_SUCCESS,
    ADMIN_COMBINATION_CONFIRM_FAILED,
    ADMIN_COMBINATION_UNCONFIRM_LOADING,
    ADMIN_COMBINATION_UNCONFIRM_SUCCESS,
    ADMIN_COMBINATION_UNCONFIRM_FAILED,
    ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_LOADING,
    ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_SUCCESS,
    ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_FAILED,
    ADMIN_SUBSCRIPTION_MARK_ENTERED_LOADING,
    ADMIN_SUBSCRIPTION_MARK_ENTERED_SUCCESS,
    ADMIN_SUBSCRIPTION_MARK_ENTERED_FAILED,
    SUBSCRIPTION_SET_TO_STATE,
} from './actionTypes';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';
import { showGlobalLoader, hideGlobalLoader } from 'actions/global';

import {
    markEnteredSubscriptionAdmin as apiMarkEnteredSubscriptionAdmin,
    markNotEnteredSubscriptionAdmin as apiMarkNotEnteredSubscriptionAdmin,
} from 'services/SubscriptionService';

import {
    confirmCombinationAdmin as apiConfirmCombinationAdmin,
    unconfirmCombinationAdmin as apiUnconfirmCombinationAdmin,
} from 'services/CombinationService';

export const setSubscriptionToSate = ({ subscription, key }) => {
    return (dispatch) => {
        dispatch({
            type: SUBSCRIPTION_SET_TO_STATE,
            payload: { subscription },
            meta: { key },
        });
    };
};

export const confirmCombinationAdmin = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_COMBINATION_CONFIRM_LOADING,
            payload: {
                id: id,
            },
            meta: { key: key },
        });

        dispatch(showGlobalLoader());

        apiConfirmCombinationAdmin(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_COMBINATION_CONFIRM_SUCCESS,
                    payload: {
                        id: id,
                        result: result,
                    },
                    meta: { key: key },
                });

                dispatch(hideGlobalLoader());
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_COMBINATION_CONFIRM_FAILED,
                    payload: {
                        id: id,
                        error: error,
                    },
                    meta: { key: key },
                });

                dispatch(hideGlobalLoader());
            });
    };
};

export const unconfirmCombinationAdmin = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_COMBINATION_UNCONFIRM_LOADING,
            payload: { id: id },
            meta: { key: key },
        });

        dispatch(showGlobalLoader());

        apiUnconfirmCombinationAdmin(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_COMBINATION_UNCONFIRM_SUCCESS,
                    payload: {
                        id: id,
                        result: result,
                    },
                    meta: { key: key },
                });

                dispatch(hideGlobalLoader());
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_COMBINATION_UNCONFIRM_FAILED,
                    payload: {
                        id: id,
                        error: error,
                    },
                    meta: { key: key },
                });

                dispatch(hideGlobalLoader());
            });
    };
};

export const markEnteredSubscriptionAdmin = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_SUBSCRIPTION_MARK_ENTERED_LOADING,
            payload: {
                id: id,
            },
            meta: { key: key },
        });

        dispatch(showGlobalLoader());

        apiMarkEnteredSubscriptionAdmin({ id })
            .then((result) => {
                dispatch({
                    type: ADMIN_SUBSCRIPTION_MARK_ENTERED_SUCCESS,
                    payload: {
                        id: id,
                        result: result,
                    },
                    meta: { key: key },
                });

                dispatch(hideGlobalLoader());
                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.description'
                    ),
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_SUBSCRIPTION_MARK_ENTERED_FAILED,
                    payload: {
                        id: id,
                        error: error,
                    },
                    meta: { key: key },
                });

                dispatch(hideGlobalLoader());
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });
            });
    };
};

export const markNotEnteredSubscriptionAdmin = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_LOADING,
            payload: {
                id: id,
            },
            meta: { key: key },
        });

        dispatch(showGlobalLoader());

        apiMarkNotEnteredSubscriptionAdmin({ id })
            .then((result) => {
                dispatch({
                    type: ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_SUCCESS,
                    payload: {
                        id: id,
                        result: result,
                    },
                    meta: { key: key },
                });

                dispatch(hideGlobalLoader());
                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.description'
                    ),
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_FAILED,
                    payload: {
                        id: id,
                        error: error,
                    },
                    meta: { key: key },
                });

                dispatch(hideGlobalLoader());
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });
            });
    };
};
