import { getPublicEvent } from 'services/EventService';

import {
    EVENT_DETAIL_PAGE_LOADING,
    EVENT_DETAIL_PAGE_LOADING_SUCCESS,
    EVENT_DETAIL_PAGE_LOADING_FAILED,
} from './actionTypes';

export const getEvent = (id) => {
    return (dispatch) => {
        dispatch({ type: EVENT_DETAIL_PAGE_LOADING });

        getPublicEvent(id)
            .then((result) => {
                dispatch({
                    type: EVENT_DETAIL_PAGE_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((data) => {
                dispatch({
                    type: EVENT_DETAIL_PAGE_LOADING_FAILED,
                    payload: data,
                });
            });
    };
};
