import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
    Button as MuiButton,
    DialogContentText,
    Grid,
    Typography,
} from '@material-ui/core';
import { EmptyMessage, Dialog } from 'components';
import { getCountryImage } from 'utils';

const useStyles = (theme) => ({
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    contentText: {
        color: theme.colors.mikado,
        marginTop: '15px',
        textAlign: 'center',
    },
    action: {
        textAlign: 'right',
    },
    countryImage: {
        borderRadius: '5px',
        width: '32px',
    },
    item: {
        borderBottomColor: theme.colors.mercury,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: 'left',

        '&:last-child': {
            borderBottomWidth: 0,
            marginBottom: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
    },
    actions: {
        textAlign: 'right',

        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
});

class DialogBasketSubscriptions extends Component {
    constructor(props) {
        super(props);

        // events
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(subscription) {
        this.props.onSelect(subscription);
        this.props.onClose();
    }

    renderContent() {
        const { isLoading, subscriptions, classes, t } = this.props;

        if (isLoading) {
            return (
                <EmptyMessage
                    title={t('common:title.loading_subscriptions')}
                    description={t('common:description.loading_subscriptions')}
                    showLoader
                />
            );
        }

        if (Object.keys(subscriptions).length === 0) {
            return (
                <Typography variant={'body1'}>
                    {t('common:error.general.no_subscriptions')}
                </Typography>
            );
        }

        const renderedSubscriptions = [];

        return (
            <Grid container alignItems={'center'} justifyContent={'flex-start'}>
                {_.map(subscriptions, (obj, index) => {
                    return (
                        <React.Fragment>
                            {_.map(obj.subscriptions, (subscription) => {
                                return (
                                    <React.Fragment>
                                        {_.map(
                                            subscription.combinations,
                                            (combination) => {
                                                const countryImage = getCountryImage(
                                                    combination?.country
                                                );

                                                // see if already rendered
                                                const key = `${combination.first_name}_${combination.last_name}_${combination.horse_name}`.toLowerCase();

                                                if (
                                                    _.indexOf(
                                                        renderedSubscriptions,
                                                        key
                                                    ) >= 0
                                                ) {
                                                    return <React.Fragment />;
                                                }

                                                renderedSubscriptions.push(key);

                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        key={index}
                                                        className={classes.item}
                                                    >
                                                        <Grid
                                                            item
                                                            sm={2}
                                                            xs={12}
                                                        >
                                                            <img
                                                                src={
                                                                    countryImage
                                                                }
                                                                alt="Belgium"
                                                                aria-label="Flag of Belgium"
                                                                className={
                                                                    classes.countryImage
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sm={6}
                                                            xs={12}
                                                        >
                                                            <strong>
                                                                {`${combination.first_name} ${combination.last_name}`}
                                                            </strong>
                                                            <br />
                                                            <small>
                                                                {
                                                                    combination.horse_name
                                                                }
                                                            </small>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sm={4}
                                                            xs={12}
                                                            className={
                                                                classes.actions
                                                            }
                                                        >
                                                            <MuiButton
                                                                variant={'text'}
                                                                onClick={() =>
                                                                    this.handleSelect(
                                                                        subscription
                                                                    )
                                                                }
                                                            >
                                                                {t(
                                                                    'common:link.select'
                                                                )}
                                                            </MuiButton>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            }
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </Grid>
        );
    }

    render() {
        const { classes, onClose, open, t } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={t('common:title.select_subscription')}
                showLine
            >
                <Grid container>
                    <Grid item xs={12}>
                        <DialogContentText
                            classes={{ root: classes.contentText }}
                        >
                            {this.renderContent()}
                        </DialogContentText>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

DialogBasketSubscriptions.defaultProps = {
    classes: {},
    admin: false,
};

DialogBasketSubscriptions.propTypes = {
    // props
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    admin: PropTypes.bool,
    subscriptions: PropTypes.object,
    // handlers
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

DialogBasketSubscriptions.defaultProps = {
    open: false,
};

const mapStateToProps = (state) => {
    const { isLoading, subscriptions, refundSubscriptions } = state.shoppingCart;

    return {
        isLoading,
        subscriptions: { ...subscriptions, ...refundSubscriptions },
    };
};

const DialogBasketSubscriptionsStyled = withStyles(useStyles)(
    DialogBasketSubscriptions
);
const DialogBasketSubscriptionsTranslated = withTranslation()(
    DialogBasketSubscriptionsStyled
);
const DialogBasketSubscriptionsState = connect(
    mapStateToProps,
    {}
)(DialogBasketSubscriptionsTranslated);

export { DialogBasketSubscriptionsState as DialogBasketSubscriptions };
