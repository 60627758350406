import format from 'string-format';
import fetchApi from './api';

const endPoints = {
    list: '/v1/profiles/',
    detail: '/v1/profiles/{id}/',
};

export const getProfile = (profileId) => fetchApi({
    url: format(endPoints.detail, { id: profileId }),
    method: 'GET',
});

export const getProfileThroughUser = (userId) => fetchApi({
    url: endPoints.list,
    data: {
        'user': userId,
    },
    method: 'GET',
});

export const updateProfile = (profileId, data) => fetchApi({
    url: format(endPoints.detail, { id: profileId }),
    data: data,
    method: 'PATCH',
});
