import React, { Component } from 'react';
import _uniqueId from 'lodash/uniqueId';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { urls, getUrl } from 'urls';
import { EVENT } from 'enums';
// import AlertService from 'lib/AlertService';
import { AdminContext, getObjectFromCache, buildStartList } from '../../utils';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import EventService from 'lib/EventService';

import { Button, Grid, Typography, Hidden } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    CancelOutlined as CancelOutlinedIcon,
    EuroSymbolOutlined as EuroIcon,
    CancelOutlined as CancelIcon,
    CheckCircleOutlined as CheckCircleIcon,
    RadioButtonUncheckedOutlined as RadioButtonIcon,
    ScheduleOutlined as ScheduleIcon,
    ChevronRightOutlined as ChevronRightIcon,
    DoubleArrowOutlined as DoubleArrowOutlinedIcon,
} from '@material-ui/icons';
import {
    AdminHeader,
    Bullet,
    CardSubscriptionAdmin,
    EmptyMessage,
    MetaStat,
    PopupMenuAdminCourse,
    TemplateAdmin,
    Toast,
} from 'components';

import {
    getCourse,
    getEvent,
    resetChanged,
    resetCreated,
    downloadStartlist,
} from './actions';

const useStyles = (theme) => ({
    border: {
        borderBottomColor: theme.colors.bone,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        marginBottom: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    offset: {
        marginTop: theme.spacing(2),
    },
});

class AdminCoursePage extends Component {
    static contextType = AdminContext; // eslint-disable-line react/sort-comp

    constructor(props) {
        super(props);

        // state
        this.state = {
            eventId: this.props.match.params.eventId,
            courseId: this.props.match.params.courseId,
            dialogDeleteOpen: false,
            dialogCourseOpen: false,
            club: null,
            id: _uniqueId('template'),
        };

        // event handlers
        this.renderDescription = this.renderDescription.bind(this);
        this.renderHeaderDescription = this.renderHeaderDescription.bind(this);
        this.reloadCourse = this.reloadCourse.bind(this);
    }

    componentDidMount() {
        const { t } = this.props;

        this.context.setAdminTitle(t('common:title.course_detail'));
        this.context.setAdminBackAction(() =>
            this.props.history.push(
                getUrl(urls.ADMIN_EVENT_DETAIL, { eventId: this.state.eventId })
            )
        );

        EventService.subscribe(
            EVENT.SUBSCRIPTION_DELETED,
            this.state.id,
            this.reloadCourse
        );
        EventService.subscribe(
            EVENT.SUBSCRIPTION_CHANGED,
            this.state.id,
            this.reloadCourse
        );

        this.reloadCourse();
    }

    componentWillUnmount() {
        EventService.unsubscribe(EVENT.SUBSCRIPTION_DELETED, this.state.id);
        EventService.unsubscribe(EVENT.SUBSCRIPTION_CHANGED, this.state.id);
    }

    componentDidUpdate(prevProps) {
        // load club
        if (
            prevProps.event !== this.props.event &&
            this.props.event &&
            !this.state.club
        ) {
            getObjectFromCache({
                url: this.props.event.club,
                success: (result) => {
                    this.setState({
                        club: result,
                    });
                },
                error: () => {
                    // do something TODO
                },
            });
        }

        // after delete, return TODO
        // if (
        //     prevProps.isDeletingCourse !== this.props.isDeletingCourse &&
        //     prevProps.isDeletingCourse &&
        //     !this.props.isDeletingCourse
        // ) {
        //     // redirect back to event
        //     this.props.history.push(
        //         getUrl(urls.ADMIN_EVENT_DETAIL, {
        //             eventId: this.state.eventId,
        //         })
        //     );
        // }
    }

    reloadCourse() {
        // load objects
        this.props.getCourse(this.state.courseId);
        this.props.getEvent(this.state.eventId);
    }

    renderHeaderDescription() {
        const { isLoading, event } = this.props;

        if (isLoading) {
            return;
        }

        return (
            <small>
                {event.name} -{' '}
                {this.state.club ? (
                    this.state.club.name
                ) : (
                    <Skeleton
                        height={25}
                        width={100}
                        style={{ display: 'inline-block' }}
                    />
                )}
            </small>
        );
    }

    renderDescription() {
        const { t, isLoading, course } = this.props;

        return (
            <React.Fragment>
                {isLoading || course.description ? (
                    <Typography variant="h2" className="title-h2">
                        {t('common:title.description')}
                    </Typography>
                ) : (
                    ''
                )}

                {isLoading ? (
                    <React.Fragment>
                        <Skeleton variant="rect" width={'100%'} height={21} />
                        <Skeleton
                            variant="rect"
                            width={'100%'}
                            height={21}
                            style={{ marginTop: '5px' }}
                        />
                        <Skeleton
                            variant="rect"
                            width={'80%'}
                            height={21}
                            style={{ marginTop: '5px' }}
                        />
                    </React.Fragment>
                ) : (
                    <Typography
                        variant="body1"
                        style={{ 'white-space': 'pre-line' }}
                    >
                        {course.description}
                    </Typography>
                )}
            </React.Fragment>
        );
    }

    renderList() {
        const { t, classes, isLoading } = this.props;

        return (
            <React.Fragment>
                {this.renderDescription()}

                <Typography
                    variant={'h2'}
                    className={classNames(classes.offset, 'title-h2')}
                >
                    {t('common:title.startlist')}
                </Typography>

                {isLoading
                    ? [...new Array(10)].map((key) => (
                          <CardSubscriptionAdmin
                              key={key}
                              isLoading={isLoading}
                          />
                      ))
                    : this.renderSubscriptions()}
            </React.Fragment>
        );
    }

    renderSubscriptions() {
        const { course, isLoading } = this.props;

        if (isLoading) {
            return;
        }

        const subscriptions = buildStartList({
            subscriptions: course.subscriptions,
            max: course.subscriptions_allowed,
        });

        return (
            <React.Fragment>
                {Object.keys(subscriptions).map((key, index) => {
                    const obj = subscriptions[key];

                    if (!obj) {
                        return (
                            <CardSubscriptionAdmin
                                course={course}
                                startNumber={key}
                                editable={'subscribe' in course.actions}
                                empty
                                key={index}
                            />
                        );
                    } else if (obj.editable) {
                        return (
                            <CardSubscriptionAdmin
                                course={course}
                                startNumber={obj.start_number}
                                subscription={obj}
                                reserved={obj.reserved}
                                editable={'subscribe' in course.actions}
                                empty={obj.reserved}
                                key={index}
                            />
                        );
                    } else if (obj.reserved) {
                        return (
                            <CardSubscriptionAdmin
                                course={course}
                                startNumber={obj.start_number}
                                reserved={obj.reserved}
                                editable={'subscribe' in course.actions}
                                empty={obj.reserved}
                                key={index}
                            />
                        );
                    } else {
                        return (
                            <CardSubscriptionAdmin
                                course={course}
                                startNumber={key}
                                subscription={obj}
                                empty={!obj}
                                editable={'subscribe' in course.actions}
                                key={index}
                            />
                        );
                    }
                })}
            </React.Fragment>
        );
    }

    renderHeader() {
        const { course, isLoading, t } = this.props;

        if (isLoading) {
            return (
                <AdminHeader
                    withTitle
                    withDescription
                    withBreadcrumbs
                    isLoading
                    variant={'small'}
                    removeMargin
                />
            );
        }

        return (
            <AdminHeader
                breadcrumbs={[
                    {
                        title: t('common:text.menu.events'),
                        url: urls.ADMIN_EVENTS,
                    },
                    {
                        title: this.props.event.name,
                        url: getUrl(urls.ADMIN_EVENT_DETAIL, {
                            eventId: this.state.eventId,
                        }),
                    },
                    {
                        title: this.props.course.name,
                        url: getUrl(urls.ADMIN_EVENT_COURSES, {
                            eventId: this.state.eventId,
                        }),
                    },
                ]}
                title={`${course.position}. ${course.name} - ${course.height}cm`}
                descriptionContainer={this.renderHeaderDescription}
                variant={'small'}
                removeMargin
                actionContainer={this.renderActions()}
            />
        );
    }

    renderActions() {
        const { course, event, isLoading } = this.props;

        return (
            <PopupMenuAdminCourse
                course={course}
                event={event}
                isLoading={isLoading}
                isViewedOnDetailPage
            />
        );
    }

    renderNotFound() {
        const { t } = this.props;

        return (
            <EmptyMessage
                icon={<CancelOutlinedIcon />}
                title={t('common:title.not_found')}
                description={t('common:description.event.not_found')}
            />
        );
    }

    renderStatistics() {
        const { t, isLoading, course, classes } = this.props;

        return (
            <React.Fragment>
                <Typography
                    variant={'h2'}
                    className={classNames(classes.offset, 'title-h2')}
                >
                    {t('common:title.details')}
                </Typography>

                <div className="card legend">
                    <div className="card-content">
                        <Bullet />{' '}
                        <span className="text">
                            {t('common:text.not_paid')}
                        </span>
                    </div>
                </div>

                <Typography
                    variant={'h3'}
                    className={classNames(classes.offset, 'title-h3')}
                >
                    {t('common:title.course')}
                </Typography>
                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<ScheduleIcon />}
                    value={course?.start_hour || t('common:text.unknown')}
                    text={t('forms:label.start_hour')}
                />
                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<DoubleArrowOutlinedIcon />}
                    value={course?.type_description || t('common:text.unknown')}
                    text={t('forms:label.course_type')}
                />

                <Typography
                    variant={'h3'}
                    className={classNames(classes.offset, 'title-h3')}
                >
                    {t('common:title.combinations')}
                </Typography>
                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<DoubleArrowOutlinedIcon />}
                    value={
                        course &&
                        course.statistics &&
                        course.statistics.subscriptions.all.total
                    }
                    text={t('forms:label.total_combinations')}
                />
                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<DoubleArrowOutlinedIcon />}
                    value={
                        course &&
                        course.statistics &&
                        course.statistics.subscriptions.all.out_of_competition
                    }
                    text={t('forms:label.out_of_competition_in_text')}
                />

                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<CheckCircleIcon />}
                    value={
                        course &&
                        course.statistics &&
                        course.statistics.subscriptions.all.confirmed
                    }
                    text={t('forms:label.amount_confirmed')}
                />
                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<CancelIcon />}
                    value={
                        course &&
                        course.statistics &&
                        course.statistics.subscriptions.all.not_paid
                    }
                    text={t('forms:label.not_paid_in_text')}
                />

                <Typography
                    variant={'h3'}
                    className={classNames(classes.offset, 'title-h3')}
                >
                    {t('common:title.places')}
                </Typography>
                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<RadioButtonIcon />}
                    value={
                        course &&
                        course.statistics &&
                        course.statistics.places.free
                    }
                    text={t('forms:label.places_free')}
                />
                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<CancelIcon />}
                    value={
                        course &&
                        course.statistics &&
                        course.statistics.places.reserved
                    }
                    text={t('forms:label.reserved_in_text')}
                />

                <Typography
                    variant={'h3'}
                    className={classNames(classes.offset, 'title-h3')}
                >
                    {t('common:title.price')}
                </Typography>
                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<EuroIcon />}
                    value={`€ ${course && course.submission_price}`}
                    text={t('forms:label.submission_price')}
                />
                <MetaStat
                    isLoading={isLoading || !course}
                    icon={<EuroIcon />}
                    value={`€ ${course && course.submission_price_locally}`}
                    text={t('forms:label.locally_in_text')}
                />
            </React.Fragment>
        );
    }

    render() {
        const {
            t,
            isCreated,
            isChanged,
            error,
            isLoading,
            course,
        } = this.props;

        if (!isLoading && error) {
            return this.renderNotFound();
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {course
                            ? course.position +
                              '. ' +
                              course.name +
                              ' - ' +
                              course?.height +
                              'cm'
                            : t('common:title.course_detail')}{' '}
                        | {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>
                <Toast
                    message={t('common:success.general.created_subscription')}
                    open={isCreated}
                    type="success"
                    onClose={this.props.resetCreated}
                />
                <Toast
                    message={t('common:success.general.changed_subscription')}
                    open={isChanged}
                    type="success"
                    onClose={this.props.resetChanged}
                />

                <TemplateAdmin header>{this.renderHeader()}</TemplateAdmin>

                <TemplateAdmin>
                    <Grid container spacing={3} display="flex">
                        <Grid item sm={8} xs={12}>
                            <Hidden smUp>
                                <Button
                                    fullWidth
                                    variant={'outlined'}
                                    endIcon={<ChevronRightIcon />}
                                    href={'#statistics'}
                                >
                                    {t('common:button.to_statistics')}
                                </Button>
                            </Hidden>
                            {this.renderList()}
                        </Grid>
                        <Grid item sm={4} xs={12} id={'statistics'}>
                            {this.renderStatistics()}
                        </Grid>
                    </Grid>
                </TemplateAdmin>
            </React.Fragment>
        );
    }
}

AdminCoursePage.propTypes = {
    // props
    course: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    event: PropTypes.object,
    isLoading: PropTypes.bool,
    classes: PropTypes.object,
    isCreated: PropTypes.bool,
    isChanged: PropTypes.bool,
    isExportingStartlist: PropTypes.bool,
    error: PropTypes.string,
    startlist: PropTypes.object,
    exportStartlistError: PropTypes.object,
    // handlers
    getCourse: PropTypes.func.isRequired,
    getEvent: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    resetCreated: PropTypes.func.isRequired,
    resetChanged: PropTypes.func.isRequired,
    downloadStartlist: PropTypes.func.isRequired,
    reloadCourse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        course,
        error,
        isLoadingEvent,
        event,
        errorEvent,
        isCreated,
        isChanged,
        isExportingStartlist,
        startlist,
        exportStartlistError,
    } = state.pageAdminCourse;

    return {
        isLoading: isLoading || isLoadingEvent,
        course,
        error,
        isLoadingEvent,
        event,
        errorEvent,
        isCreated,
        isChanged,
        isExportingStartlist,
        startlist,
        exportStartlistError,
    };
};

const AdminCoursePageTranslated = withTranslation()(AdminCoursePage);
const AdminCoursePageStyled = withStyles(useStyles)(AdminCoursePageTranslated);
const AdminCoursePageState = connect(mapStateToProps, {
    getCourse,
    getEvent,
    resetCreated,
    resetChanged,
    downloadStartlist,
})(AdminCoursePageStyled);
const AdminCoursePageRouter = withRouter(AdminCoursePageState);
export { AdminCoursePageRouter as AdminCoursePage };
