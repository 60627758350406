import {
    REGISTER_PAGE_LOADING,
    REGISTER_PAGE_SUCCESS,
    // REGISTER_PAGE_FAILED,
    REGISTER_USER_LOADING,
    REGISTER_USER_FAILED,
    REGISTER_USER_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    formErrors: null,
    isRegistering: false,
    isLoading: true,
    error: null,
    newUser: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTER_USER_LOADING:
            return {
                ...state,
                isRegistering: true,
                formErrors: null,
            };
        case REGISTER_USER_FAILED:
            return {
                ...state,
                isRegistering: false,
                formErrors: action.payload,
            };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                isRegistering: false,
                newUser: action.payload,
            };
        case REGISTER_PAGE_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case REGISTER_PAGE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
};
