import {
    addAdminEvent,
    editAdminEvent,
    getAdminEvent,
} from 'services/EventService';
import { listAdminClubs } from 'services/ClubService';

import {
    EVENT_ADD_LOADING,
    EVENT_ADD_SUCCESS,
    EVENT_ADD_FAILED,
    EVENT_LOADING,
    EVENT_LOADING_SUCCESS,
    EVENT_LOADING_FAILED,
    EVENT_EDIT_LOADING,
    EVENT_EDIT_SUCCESS,
    EVENT_EDIT_FAILED,
    EVENT_CLUBS_LOADING,
    EVENT_CLUBS_SUCCESS,
    EVENT_CLUBS_FAILED,
    EVENT_RESET,
} from './actionTypes';

export const getEvent = (id) => {
    return (dispatch) => {
        dispatch({ type: EVENT_LOADING });

        getAdminEvent(id)
            .then((result) => {
                dispatch({
                    type: EVENT_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: EVENT_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const addEvent = (data) => {
    return (dispatch) => {
        dispatch({ type: EVENT_ADD_LOADING });

        addAdminEvent(data)
            .then((result) => {
                dispatch({
                    type: EVENT_ADD_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: EVENT_ADD_FAILED,
                    payload: error,
                });
            });
    };
};

export const editEvent = (data) => {
    return (dispatch) => {
        dispatch({ type: EVENT_EDIT_LOADING });

        global.log('>>>>> EDIT EVENT', data);

        editAdminEvent(data.id, data)
            .then((result) => {
                dispatch({
                    type: EVENT_EDIT_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: EVENT_EDIT_FAILED,
                    payload: error,
                });
            });
    };
};

export const listClubs = () => {
    return (dispatch) => {
        dispatch({ type: EVENT_CLUBS_LOADING });

        listAdminClubs()
            .then((result) => {
                dispatch({
                    type: EVENT_CLUBS_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: EVENT_CLUBS_FAILED,
                    payload: error,
                });
            });
    };
};

export const resetEvent = () => {
    return (dispatch) => {
        dispatch({ type: EVENT_RESET });
    };
};
