import {
    ADMIN_COURSE_LOADING,
    ADMIN_COURSE_SUCCESS,
    ADMIN_COURSE_FAILED,
    ADMIN_COURSE_EVENT_LOADING,
    ADMIN_COURSE_EVENT_SUCCESS,
    ADMIN_COURSE_EVENT_FAILED,
    ADMIN_COURSE_ACTIONS_LOADING,
    ADMIN_COURSE_ACTIONS_SUCCESS,
    ADMIN_COURSE_ACTIONS_FAILED,
    ADMIN_COURSE_RESET_CHANGED,
    ADMIN_COURSE_RESET_CREATED,
    ADMIN_COURSE_EXPORT_START_LIST_LOADING,
    ADMIN_COURSE_EXPORT_START_LIST_SUCCESS,
    ADMIN_COURSE_EXPORT_START_LIST_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    course: null,
    error: null,

    isLoadingEvent: false,
    event: null,
    errorEvent: null,

    isLoadingActions: false,
    actions: null,
    errorActions: null,

    isCreated: false,
    isChanged: false,

    isExportingStartlist: false,
    exportStartlistError: null,
    startlist: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_COURSE_LOADING:
            return {
                ...state,
                isLoading: true,
                isOutdated: false,
            };
        case ADMIN_COURSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                course: action.payload,
            };
        case ADMIN_COURSE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case ADMIN_COURSE_EVENT_LOADING:
            return {
                ...state,
                isLoadingEvent: true,
            };
        case ADMIN_COURSE_EVENT_SUCCESS:
            return {
                ...state,
                isLoadingEvent: false,
                event: action.payload,
            };
        case ADMIN_COURSE_EVENT_FAILED:
            return {
                ...state,
                isLoadingEvent: false,
                errorEvent: action.payload,
            };

        case ADMIN_COURSE_ACTIONS_LOADING:
            return {
                ...state,
                isLoadingActions: true,
            };
        case ADMIN_COURSE_ACTIONS_SUCCESS:
            return {
                ...state,
                isLoadingActions: false,
                actions: action.payload.actions.USER,
            };
        case ADMIN_COURSE_ACTIONS_FAILED:
            return {
                ...state,
                isLoadingActions: false,
                errorActions: action.payload,
            };

        case ADMIN_COURSE_RESET_CHANGED:
            return {
                ...state,
                isChanged: false,
            };
        case ADMIN_COURSE_RESET_CREATED:
            return {
                ...state,
                isCreated: false,
            };

        case ADMIN_COURSE_EXPORT_START_LIST_LOADING:
            return {
                ...state,
                isExportingStartlist: true,
                exportStartlistError: null,
                startlist: null,
            };

        case ADMIN_COURSE_EXPORT_START_LIST_SUCCESS:
            return {
                ...state,
                isExportingStartlist: false,
                startlist: action.payload,
            };

        case ADMIN_COURSE_EXPORT_START_LIST_FAILED:
            return {
                ...state,
                isExportingStartlist: false,
                exportStartlistError: action.payload,
            };

        default:
            return state;
    }
};
