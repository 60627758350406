import React, { Component, Suspense, Spinner } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { Container } from '@material-ui/core';

import { TemplateMarketing } from '../TemplateMarketing';
import { getUrl } from 'urls';

class PrivacyPage extends Component {
    render() {
        const { t } = this.props;

        return (
            <Suspense fallback={<Spinner />}>
                <Helmet>
                    <title>{t('legal:title.main.privacy')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateMarketing>
                    <Container>
                        <h1>{t('legal:title.main.privacy')}</h1>

                        <p>
                            We believe privacy is of the utmost importance and
                            want to transparently inform you about how we
                            collect and handle your information. This privacy
                            policy is conform the General Data Protection
                            Regulation (GDPR).
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>
                                How do we collect your information and why?
                            </strong>
                        </p>
                        <p>
                            <em>Statistics</em>
                        </p>
                        <ul>
                            <li>
                                <strong>Google Analytics</strong>
                                <br />
                                When you browse our website, we collect some
                                information like the browser you’re using, your
                                language, the page you’re on, how you interact,
                                your (general) location, etc. We use this data
                                to improve the website and give you a better
                                experience. This data is anonymized. You can
                                find more information in Googles Privacy Policy,{' '}
                                <a
                                    href="https://www.google.com/analytics/terms/en.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://www.google.com/analytics/terms/nl.html
                                </a>
                                .
                            </li>
                            <li>
                                <strong>Facebook Pixel</strong>
                                <br />
                                We use the Facebook Pixel to track which pages
                                you visited. With this data we can show you
                                specific ads on Facebook. At no point in time do
                                we have any information on who you are or what
                                your Facebook account is. Facebooks privacy
                                policy can be found at{' '}
                                <a
                                    href="https://www.facebook.com/privacy/explanation"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://www.facebook.com/privacy/explanation
                                </a>
                                .
                            </li>
                            <li>
                                <strong>Hotjar</strong>
                                <br />
                                We use the tool Hotjar to keep track how you
                                interact with our website. Your scroll behavior
                                or clicks on certain elements on a page. The
                                data that is collected is completely anonymous.
                                The privacy policy of Hotjar can be found at{' '}
                                <a
                                    href="https://www.hotjar.com/legal/policies/privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://www.hotjar.com/legal/policies/privacy
                                </a>
                                .
                            </li>
                        </ul>
                        <p>
                            <em>Personal information</em>
                        </p>
                        <ul>
                            <li>
                                <strong>Required information</strong>
                                To use our services, we require certain personal
                                information. Some possiblities include first
                                name, family name, email address, birthdate,
                                phone number and street address. In certain case
                                we also need company details for invoicing.
                            </li>
                            <li>
                                <strong>Sentry</strong>
                                We use Sentry to track errors on our website and
                                applications. When an error occurs, this is
                                automatically submitted to Sentry and we are
                                notified of it. This way we can fix the issue
                                fast and efficiently. Sentry’s privacy policy
                                can be found at{' '}
                                <a
                                    href="https://sentry.io/privacy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://sentry.io/privacy/
                                </a>
                                .
                            </li>
                        </ul>
                        <p>
                            <em>Other information</em>
                        </p>
                        <ul>
                            <li>
                                <strong>Pictures</strong>
                                <br />
                                The pictures you or we upload, are uploaded to
                                Amazon Simple Cloud Storage Service (S3). The
                                privacy policy of Amazon can be found at{' '}
                                <a
                                    href="https://aws.amazon.com/compliance/data-privacy-faq/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://aws.amazon.com/compliance/data-privacy-faq/
                                </a>
                                .
                            </li>
                            <li>
                                <strong>Feedback widget</strong>
                                <br />
                                We use UserReport to capture your feedback on
                                our website. The data you’ve entered like your
                                first name, last name, email address and your
                                message will be kept at their servers. We use
                                this data to improve our website, product and /
                                or services. The privacy policy of UserReport
                                can be found at{' '}
                                <a
                                    href="https://privacy.userreport.com/en-GB/for-users/privacy-policy/hort-version/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://privacy.userreport.com/en-GB/for-users/privacy-policy/hort-version/
                                </a>
                                .
                            </li>
                            <li>
                                <strong>Getsitecontrol</strong>
                                <br />
                                We use the tools of Getsitecontrol to ask your
                                feedback or to give you the possibility to share
                                certain pages on social media or email. The
                                privacy policy of Getsitecontrol can be found at{' '}
                                <a
                                    href="https://getsitecontrol.com/privacy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://getsitecontrol.com/privacy/
                                </a>
                                .
                            </li>
                            <li>
                                <strong>YouTube</strong>
                                <br />
                                We use YouTube to show videos on this website.
                                You can review the terms of YouTube on{' '}
                                <a
                                    href="https://www.youtube.com/t/terms"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://www.youtube.com/t/terms
                                </a>
                                .
                            </li>
                        </ul>

                        <div className="spacer" />

                        <p>
                            <strong>With whom do we share your data?</strong>
                        </p>
                        <p>
                            Like stated above, we do share your data with some
                            third parties. They are responsible to process your
                            data correctly and to keep it secure. The transfer
                            of your data will always done via a secure
                            connection. We only share your data with third
                            parties that are in line with de new GDPR
                            guidelines.
                        </p>
                        <p>
                            In addition to these partners, we also manage your
                            data, but we never share it with third parties,
                            unless in the following cases:
                        </p>
                        <ul>
                            <li>
                                We are required by law, the government, a
                                lawyer, ... to share certain data.
                            </li>
                            <li>
                                Our professional advisers (lawyers, accountants,
                                etc.) who are located in Belgium and the
                                Netherlands need certain information for their
                                activities.
                            </li>
                            <li>
                                When we notice that illegal activities take
                                place.
                            </li>
                            <li>
                                When we have to defend our rights or with a
                                financial risk.
                            </li>
                            <li>
                                When logging errors from our system to help you.
                            </li>
                        </ul>
                        <p>
                            Partners with whom we exchange data comply with the
                            GDPR guidelines.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>How do we protect your data?</strong>
                        </p>
                        <p>
                            We ensure that your data is always sent over a
                            secure connection to our servers. These servers are
                            in their turn secured and managed by our supplier
                            who works in accordance with GDPR guidelines. We
                            have different systems that ensure that you can
                            never get or see data from other people. Our systems
                            are in accordance with European guidelines.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>How long do we keep your data?</strong>
                        </p>
                        <p>
                            We keep your data as long as it is necessary to
                            offer our services and products to you and others
                            and to comply with our legal obligations.
                        </p>
                        <p>
                            If you no longer want us to keep your personal
                            information, you can contact us so that we can
                            delete your data and, if applicable, cancel your
                            account. Even if you have your data deleted, we can
                            still keep some data for a period to prevent fraud,
                            for our accounting, to respect the law, to assist
                            with investigations by a government agency or to
                            solve problems. . This data is only used in these
                            cases.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>
                                Marketing choices related to your personal
                                information
                            </strong>
                        </p>
                        <p>
                            If you have given your consent (eg by subscribing to
                            a newsletter or by creating an account) we can send
                            you emails. These are emails that are required for
                            our services to work correctly, as well as marketing
                            emails. For the latter you always have the option to
                            unsubscribe so that you no longer receive these
                            emails.
                        </p>
                        <p>
                            You also have the right to refuse our cookies, but
                            then we can’t ensure that our services work
                            correctly. For this we refer to our Cookie Policy.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>Information about children</strong>
                        </p>

                        <p>
                            Our websites are not intended for children under the
                            age of 16. If you are younger than 16, we ask you
                            not to use our website and / or not to give us
                            personal information. You can always ask your
                            parents or legal guardian to use our services in
                            your place. If you are between 16 and 18 years old,
                            you may surf on our website, but you need
                            supervision from a parent or legal guardian to
                            become a registered user. It is the responsibility
                            of parents or legal guardian to monitor the use of
                            our website by their child.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>
                                Information that you make public or give to
                                others
                            </strong>
                        </p>
                        <p>
                            When you place public information on our website, we
                            are not responsible for the way other people use it.
                            Always think carefully before you share information
                            with others.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>How can you reach us?</strong>
                        </p>
                        <p>
                            Equento
                            <br />
                            Ingo Berben
                            <br />
                            <a href="mailto:info@equento.be">info@equento.be</a>
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>Which rights do you have?</strong>
                        </p>
                        <p>
                            <em>Right of inspection and correction</em>
                        </p>
                        <p>
                            You have the right to view all personal data that
                            this website collects from you. Any incorrect or
                            incomplete data can be changed or removed.
                        </p>
                        <p>
                            <em>Right to be forgotten</em>
                        </p>
                        <p>
                            You always have the right to have your data removed
                            from our databases.
                        </p>
                        <p>
                            <em>Right to file a complaint</em>
                        </p>
                        <p>
                            If you believe that we have processed your data
                            unlawfully, you have the right to file a complaint
                            with the Privacy Commission.
                        </p>
                        <p>
                            <em>Right to withdraw permission</em>
                        </p>
                        <p>
                            Every user or visitor whose personal data is
                            collected has the right to object to the processing
                            of that data.
                        </p>
                        <p>
                            <em>Right to restriction of processing</em>
                        </p>
                        <p>
                            You have the right to (temporarily) stop processing
                            your personal data.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>Updates</strong>
                        </p>
                        <p>
                            We have the right to change this privacy statement
                            if necessary. That is why it is important to
                            regularly view this page. The privacy policy was
                            last changed on May 19, 2018.
                        </p>
                    </Container>
                </TemplateMarketing>
            </Suspense>
        );
    }
}

const PrivacyPageTranslated = withTranslation()(PrivacyPage);
export { PrivacyPageTranslated as PrivacyPage };
