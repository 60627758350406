import {
    CLUB_DELETE_LOADING,
    CLUB_DELETE_SUCCESS,
    CLUB_DELETE_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isDeleting: false,
    isDeleted: false,
    error: null,
};

export default function (name = '') {
    return (state = INITIAL_STATE, action) => {
        if (!action.meta || action.meta.key !== name) {
            return state;
        }

        switch (action.type) {
            case CLUB_DELETE_LOADING:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isDeleting: true,
                };
            case CLUB_DELETE_SUCCESS:
                return {
                    ...state,
                    isDeleting: false,
                    isDeleted: true,
                };
            case CLUB_DELETE_FAILED:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isDeleting: false,
                    error: action.payload,
                };

            default:
                return state;
        }
    };
}
