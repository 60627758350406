import { listAdminOrders } from 'services/OrderService';

import {
    ADMIN_ORDERS_LOADING,
    ADMIN_ORDERS_LOADING_SUCCESS,
    ADMIN_ORDERS_LOADING_FAILED,
} from './actionTypes';

export const listOrders = ({ page = 1, payment_status = undefined }) => {
    let status = payment_status;
    if (status === 'all') {
        status = undefined;
    }

    return (dispatch) => {
        dispatch({ type: ADMIN_ORDERS_LOADING });

        listAdminOrders({ page, status })
            .then((result) => {
                dispatch({
                    type: ADMIN_ORDERS_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_ORDERS_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};
