export const ADMIN_COURSE_ADD_LOADING = 'admin_course_add_loading';
export const ADMIN_COURSE_ADD_SUCCESS = 'admin_course_add_success';
export const ADMIN_COURSE_ADD_FAILED = 'admin_course_add_failed';

export const ADMIN_COURSE_LOADING = 'admin_course_loading';
export const ADMIN_COURSE_LOADING_SUCCESS = 'admin_course_loading_success';
export const ADMIN_COURSE_LOADING_FAILED = 'admin_course_loading_failed';

export const ADMIN_COURSE_EDIT_LOADING = 'admin_course_edit_loading';
export const ADMIN_COURSE_EDIT_SUCCESS = 'admin_course_edit_success';
export const ADMIN_COURSE_EDIT_FAILED = 'admin_course_edit_failed';

export const ADMIN_COURSE_TYPES_LOADING = 'admin_course_types_loading';
export const ADMIN_COURSE_TYPES_SUCCESS = 'admin_course_types_success';
export const ADMIN_COURSE_TYPES_FAILED = 'admin_course_types_failed';

export const ADMIN_COURSE_RESET = 'admin_course_reset';
