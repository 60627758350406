export const ADMIN_TICKET_LOADING = 'admin_ticket_loading';
export const ADMIN_TICKET_SUCCESS = 'admin_ticket_success';
export const ADMIN_TICKET_FAILED = 'admin_ticket_failed';

export const ADMIN_TICKET_ORDERS_LOADING = 'admin_ticket_orders_loading';
export const ADMIN_TICKET_ORDERS_SUCCESS = 'admin_ticket_orders_success';
export const ADMIN_TICKET_ORDERS_FAILED = 'admin_ticket_orders_failed';

export const ADMIN_TICKET_EVENT_LOADING = 'admin_ticket_event_loading';
export const ADMIN_TICKET_EVENT_SUCCESS = 'admin_ticket_event_success';
export const ADMIN_TICKET_EVENT_FAILED = 'admin_ticket_event_failed';

export const ADMIN_TICKET_COMBINATION_CONFIRM_LOADING =
    'admin_ticket_combination_confirm_loading';
export const ADMIN_TICKET_COMBINATION_CONFIRM_SUCCESS =
    'admin_ticket_combination_confirm_success';
export const ADMIN_TICKET_COMBINATION_CONFIRM_FAILED =
    'admin_ticket_combination_confirm_failed';

export const ADMIN_TICKET_COMBINATION_UNCONFIRM_LOADING =
    'admin_ticket_combination_unconfirm_loading';
export const ADMIN_TICKET_COMBINATION_UNCONFIRM_SUCCESS =
    'admin_ticket_combination_unconfirm_success';
export const ADMIN_TICKET_COMBINATION_UNCONFIRM_FAILED =
    'admin_ticket_combination_unconfirm_failed';
