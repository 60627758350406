import React, { Component } from 'react';
import { Button as MuiButton, CircularProgress } from '@material-ui/core';

import PropTypes from 'prop-types';

import './styles.scss';

class Button extends Component {
    render() {
        const {
            disabled,
            className,
            isLoading,
            onClick,
            fullWidth,
            color,
            variant,
            type,
            component,
            size,
        } = this.props;

        return (
            <MuiButton
                variant={variant || 'contained'}
                disabled={disabled || isLoading}
                disableElevation
                className={className}
                type={type}
                component={component}
                onClick={onClick}
                fullWidth={fullWidth}
                color={color}
                size={size}
            >
                {!isLoading && this.props.children}
                {isLoading && <CircularProgress size={28} />}
            </MuiButton>
        );
    }
}

Button.propTypes = {
    children: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    color: PropTypes.string,
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    type: PropTypes.oneOf(['submit', 'button']),
    component: PropTypes.string,
};

Button.defaultProps = {
    disabled: false,
    isLoading: false,
    component: 'button',
    size: 'medium',
    type: 'button',
    color: 'default',
};

export { Button };
