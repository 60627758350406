import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUrl, urls } from 'urls';
import { AdminContext } from '../../utils';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import {
    AdminHeader,
    Button,
    CardEventAdmin,
    Toast,
    CardList,
    TemplateAdmin,
} from 'components';
import {
    listEvents,
    listEventListActions,
    resetChanged,
    resetCreated,
    resetDeleted,
} from './actions';
import { FilterForm } from './form';

class AdminEventsPage extends Component {
    static contextType = AdminContext; // eslint-disable-line react/sort-comp

    constructor(props) {
        super(props);

        // load events and actions
        this.props.listEvents({ page: 1 });
        this.props.listEventListActions();

        // event handlers
        this.handlePage = this.handlePage.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.reloadEvents = this.reloadEvents.bind(this);
    }

    componentDidMount() {
        const { t } = this.props;

        this.context.setAdminTitle(t('common:title.jumpings'));
        this.context.setAdminBackAction(null);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isCreated !== this.props.isCreated &&
            this.props.isCreated
        ) {
            this.reloadEvents();
        }

        if (
            prevProps.isDeleted !== this.props.isDeleted &&
            this.props.isDeleted
        ) {
            this.reloadEvents();
        }

        if (
            prevProps.isChanged !== this.props.isChanged &&
            this.props.isChanged
        ) {
            this.reloadEvents();
        }
    }

    reloadEvents() {
        const { currentPage } = this.props;

        this.handlePage(null, currentPage);
    }

    handlePage(object, page) {
        this.props.listEvents({ page: page });
    }

    handleStatusChange(event, value) {
        this.props.listEvents({ page: 1, status: value.props.value });
    }

    renderActions() {
        const { t, isLoadingActions, errorActions, actions } = this.props;

        if (isLoadingActions) {
            return <CircularProgress size={20} />;
        }

        if (errorActions) {
            return <Toast message={t('common:error.general.description')} />;
        }

        if (actions && actions['create']) {
            // if the user can create stuff
            return (
                <Button
                    color={'secondary'}
                    onClick={() =>
                        this.props.history.push(getUrl(urls.ADMIN_EVENT_ADD))
                    }
                >
                    {t('common:title.admin.event_add')}
                </Button>
            );
        }

        return;
    }

    render() {
        const {
            t,
            isCreated,
            isDeleted,
            isChanged,
            isLoading,
            events,
            totalPages,
            currentPage,
            error,
        } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <Helmet>
                    <title>
                        {t('common:title.admin.events')} |{' '}
                        {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>
                <Toast
                    message={t('common:success.general.created_event')}
                    open={isCreated}
                    type="success"
                    onClose={this.props.resetCreated}
                />
                <Toast
                    message={t('common:success.general.changed_event')}
                    open={isChanged}
                    type="success"
                    onClose={this.props.resetChanged}
                />
                <Toast
                    message={t('common:success.general.deleted_event')}
                    open={isDeleted}
                    type="success"
                    onClose={this.props.resetDeleted}
                />

                <TemplateAdmin header>
                    <AdminHeader
                        title={t('common:title.admin.events')}
                        actionContainer={this.renderActions()}
                        breadcrumbs={[
                            {
                                title: t('common:text.menu.events'),
                                url: getUrl(urls.ADMIN_EVENTS),
                            },
                        ]}
                        removeMargin
                    />
                </TemplateAdmin>

                <TemplateAdmin filter>
                    <FilterForm onStatusChange={this.handleStatusChange} />
                </TemplateAdmin>

                <TemplateAdmin>
                    <CardList
                        isLoading={isLoading}
                        objects={events}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        error={error}
                        card={CardEventAdmin}
                        handlePage={this.handlePage}
                        useCardAsLoaders
                    />
                </TemplateAdmin>
            </Suspense>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoading,
        events,
        error,
        isChanged,
        isCreated,
        isDeleted,
        currentPage,
        totalPages,

        isLoadingActions,
        errorActions,
        actions,
    } = state.pageAdminEvents;

    return {
        isLoading: isLoading || isLoadingActions,
        events,
        error,
        isChanged,
        isCreated,
        isDeleted,
        currentPage,
        totalPages,

        isLoadingActions,
        errorActions,
        actions,
    };
};

AdminEventsPage.propTypes = {
    isCreated: PropTypes.bool,
    isDeleted: PropTypes.bool,
    isChanged: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingActions: PropTypes.bool,
    events: PropTypes.object,
    error: PropTypes.object,
    errorActions: PropTypes.object,
    actions: PropTypes.array,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    resetCreated: PropTypes.func.isRequired,
    resetChanged: PropTypes.func.isRequired,
    resetDeleted: PropTypes.func.isRequired,
    listEvents: PropTypes.func.isRequired,
    listEventListActions: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.object,
};

const AdminEventsPageTranslated = withTranslation()(AdminEventsPage);
const AdminEventsPageState = connect(mapStateToProps, {
    listEvents,
    listEventListActions,
    resetChanged,
    resetCreated,
    resetDeleted,
})(AdminEventsPageTranslated);
const AdminEventsPageRouter = withRouter(AdminEventsPageState);
export { AdminEventsPageRouter as AdminEventsPage };
