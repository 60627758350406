import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';

import {
    Chip as MuiChip,
} from '@material-ui/core';

const useStyles = (theme) => ({
    warning: {
        backgroundColor: theme.colors.bone,
        color: theme.colors.cigar,
        // fontWeight: 'bold',
    },
    success: {
        backgroundColor: theme.colors.parisWhite,
        color: theme.colors.heavyMetal,
        // fontWeight: 'bold',
    },
    error: {
        backgroundColor: theme.colors.potPourri,
        color: theme.colors.permanentGeraniumLake,
        // fontWeight: 'bold',
    }
});

class Chip extends Component {
    render() {
        const { classes } = this.props;

        return (
            <MuiChip
                {...this.props}

                classes={{
                    root: classes[this.props.type]
                }}
            />
        );
    }
}

const ChipStyled = withStyles(useStyles)(Chip);

export { ChipStyled as Chip };
