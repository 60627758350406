import { getAuthToken } from 'services/StorageService';

import {
    AUTHENTICATION_CHECKING,
    AUTHENTICATION_CHECKING_SUCCESS,
    AUTHENTICATION_CHECKING_FAILED,
} from './actionTypes';

export const checkAuthentication = () => {
    return (dispatch) => {
        dispatch({ type: AUTHENTICATION_CHECKING });

        const token = getAuthToken();

        if (token) {
            dispatch({
                type: AUTHENTICATION_CHECKING_SUCCESS,
                payload: { auth_token: token },
            });
        } else {
            dispatch({
                type: AUTHENTICATION_CHECKING_FAILED,
            });
        }
    };
};

export const checkAuthenticationSync = () => {
    return getAuthToken();
};
