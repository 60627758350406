import {
    DIALOG_SUBSCRIPTION_LOADING,
    DIALOG_SUBSCRIPTION_SUCCESS,
    DIALOG_SUBSCRIPTION_FAILED,
    DIALOG_SUBSCRIPTION_LOCK_LOADING,
    DIALOG_SUBSCRIPTION_LOCK_SUCCESS,
    DIALOG_SUBSCRIPTION_LOCK_FAILED,
    DIALOG_SUBSCRIPTION_LOCK_DELETE_LOADING,
    DIALOG_SUBSCRIPTION_LOCK_DELETE_SUCCESS,
    DIALOG_SUBSCRIPTION_LOCK_DELETE_FAILED,
    ADMIN_DIALOG_BASKET_SUBSCRIPTION_LOADING,
    ADMIN_DIALOG_BASKET_SUBSCRIPTION_SUCCESS,
    ADMIN_DIALOG_BASKET_SUBSCRIPTION_FAILED,
} from './actionTypes';
import {
    addSubscriptionBasket as apiAddBasketSubscription,
    editSubscriptionBasket as apiEditBasketSubscription,
    addSubscriptionBasketAdmin as apiAddBasketSubscriptionAdmin,
    editSubscriptionBasketAdmin as apiEditBasketSubscriptionAdmin,
} from 'services/BasketService';
import { editSubscriptionAdmin as apiEditSubscriptionAdmin } from 'services/SubscriptionService';
import {
    addSubscriptionLock as apiAddSubscriptionLock,
    deleteSubscriptionLock as apiDeleteSubscriptionLock,
} from 'services/SubscriptionLockService';
import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

export const addBasketSubscription = ({
    course,
    start_number,
    out_of_competition,
    combinations,
    key,
}) => {
    return (dispatch) => {
        dispatch({
            type: DIALOG_SUBSCRIPTION_LOADING,
            meta: { key: key },
        });

        apiAddBasketSubscription({
            course,
            start_number,
            out_of_competition,
            combinations,
        })
            .then((result) => {
                dispatch({
                    type: DIALOG_SUBSCRIPTION_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: DIALOG_SUBSCRIPTION_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const addBasketSubscriptionAdmin = ({
    course,
    start_number,
    out_of_competition,
    combinations,
    key,
}) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_DIALOG_BASKET_SUBSCRIPTION_LOADING,
            meta: { key: key },
        });

        apiAddBasketSubscriptionAdmin({
            course,
            start_number,
            out_of_competition,
            combinations,
        })
            .then((result) => {
                dispatch({
                    type: ADMIN_DIALOG_BASKET_SUBSCRIPTION_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_DIALOG_BASKET_SUBSCRIPTION_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const editBasketSubscription = ({
    id,
    course,
    start_number,
    out_of_competition,
    combinations,
    key,
}) => {
    return (dispatch) => {
        dispatch({
            type: DIALOG_SUBSCRIPTION_LOADING,
            meta: { key: key },
        });

        apiEditBasketSubscription({
            id,
            course,
            start_number,
            out_of_competition,
            combinations,
        })
            .then((result) => {
                dispatch({
                    type: DIALOG_SUBSCRIPTION_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: DIALOG_SUBSCRIPTION_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const editBasketSubscriptionAdmin = ({
    id,
    course,
    start_number,
    out_of_competition,
    combinations,
    key,
}) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_DIALOG_BASKET_SUBSCRIPTION_LOADING,
            meta: { key: key },
        });

        apiEditBasketSubscriptionAdmin({
            id,
            course,
            start_number,
            out_of_competition,
            combinations,
        })
            .then((result) => {
                dispatch({
                    type: ADMIN_DIALOG_BASKET_SUBSCRIPTION_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_DIALOG_BASKET_SUBSCRIPTION_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const editSubscriptionAdmin = ({
    id,
    course,
    start_number,
    out_of_competition,
    combinations,
    key,
}) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_DIALOG_BASKET_SUBSCRIPTION_LOADING,
            meta: { key: key },
        });

        apiEditSubscriptionAdmin({
            id,
            course,
            start_number,
            out_of_competition,
            combinations,
        })
            .then((result) => {
                dispatch({
                    type: ADMIN_DIALOG_BASKET_SUBSCRIPTION_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_DIALOG_BASKET_SUBSCRIPTION_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const addSubscriptionLock = ({ course, start_number, key }) => {
    return (dispatch) => {
        dispatch({
            type: DIALOG_SUBSCRIPTION_LOCK_LOADING,
            meta: { key: key },
        });

        apiAddSubscriptionLock({ course, start_number })
            .then((result) => {
                dispatch({
                    type: DIALOG_SUBSCRIPTION_LOCK_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                // AlertService.showError({
                //     description: TranslationUtil.getTranslations().t(
                //         'common:error.general.description'
                //     ),
                // });

                dispatch({
                    type: DIALOG_SUBSCRIPTION_LOCK_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const deleteSubscriptionLock = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: DIALOG_SUBSCRIPTION_LOCK_DELETE_LOADING,
            meta: { key: key },
        });

        apiDeleteSubscriptionLock({ id })
            .then((result) => {
                dispatch({
                    type: DIALOG_SUBSCRIPTION_LOCK_DELETE_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                // AlertService.showError({
                //     description: TranslationUtil.getTranslations().t(
                //         'common:error.general.description'
                //     ),
                // });

                dispatch({
                    type: DIALOG_SUBSCRIPTION_LOCK_DELETE_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};
