import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import moment from "moment";

import PropTypes from "prop-types";

import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Chip } from "..";

const useStyles = (theme) => ({
    item: {
        borderBottomColor: theme.colors.mercury,
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
});

class PaymentItem extends Component {
    getStyle(status) {
        if (status === "pending" || status === "open") {
            return "warning";
        } else if (status === "canceled" || status === "failed") {
            return "error";
        } else if (status === "paid") {
            return "success";
        }

        return "default";
    }

    renderLabel() {
        const { payment } = this.props;

        return (
            <Chip
                label={payment.status_description}
                variant="default"
                type={this.getStyle(payment.status)}
            />
        );
    }

    renderDate() {
        const { payment } = this.props;

        let date;
        switch (payment.status) {
            case "open":
            case "pending":
                date = payment.created_at;
                break;
            case "authorized":
                date = payment.completed_at;
                break;
            case "paid":
                date = payment.paid_at;
                break;
            case "expired":
                date = payment.expired_at;
                break;
            case "failed":
                date = payment.failed_at;
                break;
            case "cancelled":
                date = payment.cancelled_at;
                break;
            default:
                date = undefined;
        }

        if (date) {
            return moment(date).format("LLL");
        }

        return date;
    }

    render() {
        const { t, payment, classes, isLoading } = this.props;

        if (isLoading) {
            return (
                <Grid container>
                    <Grid item sm={2} xs={12} className={classes.item}>
                        <Skeleton height={18} width={40} />
                    </Grid>
                    <Grid item sm={4} xs={12} className={classes.item}>
                        <Skeleton height={24} width={"50%"} />
                    </Grid>
                    <Grid item sm={3} xs={12} className={classes.item}>
                        <Skeleton height={24} width={"50%"} />
                    </Grid>
                    <Grid item sm={3} xs={12} className={classes.item}>
                        <Skeleton height={18} width={40} />
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid container>
                <Grid item sm={2} xs={12} className={classes.item}>
                    <small>#{payment.id}</small>
                </Grid>
                <Grid item sm={4} xs={12} className={classes.item}>
                    {payment.image && (
                        <img
                            src={payment.image.size1x}
                            alt={payment.payment_method}
                        />
                    )}
                    {payment.payment_method
                        ? payment.payment_method_description
                        : t("common:text.payment.no_method")}
                </Grid>
                <Grid item sm={3} xs={12} className={classes.item}>
                    {this.renderLabel()}
                </Grid>
                <Grid item sm={3} xs={12} className={classes.item}>
                    <small>
                        {this.renderDate() || t("common:text.payment.no_date")}
                    </small>
                </Grid>
            </Grid>
        );
    }
}

PaymentItem.propTypes = {
    // props
    payment: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    classes: PropTypes.object,
    // handlers
    t: PropTypes.func.isRequired,
};

const PaymentItemStyled = withStyles(useStyles)(PaymentItem);
const PaymentItemTranslated = withTranslation()(PaymentItemStyled);

export { PaymentItemTranslated as PaymentItem };
