import React, { Component } from "react";
import { withStyles } from '@material-ui/styles';

import PropTypes from 'prop-types';

const useStyles = (theme) => ({
    divider: {
        fontFamily: 'Nunito',
        fontSize: '16px',
        fontWeight: 'bold',
        color: theme.colors.mikado,
        marginBottom: theme.spacing(1)
    }
});

class TextDivider extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.divider}>
                {this.props.text}
            </div>
        );
    }
}

TextDivider.propTypes = {
    text: PropTypes.string.isRequired,
};

const TextDividerStyled = withStyles(useStyles)(TextDivider);

export { TextDividerStyled as TextDivider };
