import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import { Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    Dialog,
    DialogQR,
    Button,
    TicketSubscription,
    DownloadFile,
} from 'components';
import { getObjectFromCache } from 'utils';
import { downloadTicket } from './actions';

const useStyles = (theme) => ({
    backDropOverlay: {
        zIndex: 1301,
        color: theme.colors.white,
        flexDirection: 'column',
    },
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    dialog: {
        minWidth: '550px',
        [theme.breakpoints.down('md')]: {
            minWidth: '320px',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '300px',
        },
    },
    contentText: {
        color: theme.colors.mikado,
    },
    dividerText: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#e5e5e5',
        display: 'block',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        paddingBottom: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(6),

        '&:first-child': {
            paddingTop: theme.spacing(0),
        },
    },
});

class DialogTicket extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            courses: {},
            dialogQROpen: false,
            isDownloadingTicket: false,
        };

        // event handlers
        this.handleDialogQRClose = this.handleDialogQRClose.bind(this);
        this.handleViewQRCode = this.handleViewQRCode.bind(this);
    }

    componentDidMount() {
        if (
            this.props.ticket &&
            (!this.state.courses ||
                Object.keys(this.state.courses).length === 0)
        ) {
            Object.entries(this.props.ticket.subscriptions).map(
                ([key, value]) =>
                    getObjectFromCache({
                        url: value.course,
                        success: (result) => {
                            const courses = this.state.courses;
                            courses[value.course] = result;
                            this.setState({
                                courses: courses,
                            });
                        },
                        error: () => {
                            // do something TODO
                        },
                    })
            );
        }
    }

    handleDialogQRClose() {
        this.setState({
            dialogQROpen: false,
        });
    }

    handleViewQRCode() {
        this.setState({
            dialogQROpen: true,
        });
    }

    renderDialogQR() {
        return (
            <DialogQR
                open={this.state.dialogQROpen}
                onClose={this.handleDialogQRClose}
                ticket={this.props.ticket}
            />
        );
    }

    renderFileDownload() {
        return (
            <DownloadFile
                onStart={() => this.setState({ isDownloadingTicket: true })}
                onFinish={() => this.setState({ isDownloadingTicket: false })}
                onError={() => {
                    AlertService.showError({
                        description: TranslationUtil.getTranslations().t(
                            'common:error.download_ticket'
                        ),
                    });
                }}
                onSuccess={() => {
                    AlertService.showSuccess({
                        description: TranslationUtil.getTranslations().t(
                            'common:success.download_ticket'
                        ),
                    });
                }}
                action={async () =>
                    this.props.downloadTicket(this.props.ticket.id)
                }
                start={this.state.isDownloadingTicket}
            />
        );
    }

    render() {
        const { classes, onClose, open, t, ticket, event } = this.props;
        const count = ticket.subscriptions.length;

        const subscriptions = _.reduce(
            ticket.subscriptions,
            (result, value) => {
                if (!result[value.course]) {
                    result[value.course] = [];
                }
                result[value.course].push(value);
                return result;
            },
            {}
        );
        const subtitle = t('common:text.amount_subscription', { count: count });

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={`${t('common:title.ticket')} #${ticket.id}`}
                subtitle={subtitle}
                showLine
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            color={'secondary'}
                            onClick={() =>
                                this.setState({ isDownloadingTicket: true })
                            }
                            isLoading={this.state.isDownloadingTicket}
                        >
                            {t('common:button.download_ticket')}
                        </Button>
                        {this.renderFileDownload()}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            color={'secondary'}
                            onClick={this.handleViewQRCode}
                        >
                            {t('common:button.view_qr')}
                        </Button>
                        {this.renderDialogQR()}
                    </Grid>
                    <Grid item xs={12}>
                        <div className="dashed-line" />
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <strong>
                            <small>{t('common:title.created_at')}</small>
                        </strong>
                        <br />
                        {moment(ticket.created_at).format('LL')}
                    </Grid>
                    <Grid item sm={6} xs={6}>
                        <strong>
                            <small>{t('common:title.event')}</small>
                        </strong>
                        <br />
                        {event && event.name}
                    </Grid>
                    <Grid item xs={12}>
                        <div className="dashed-line" />
                    </Grid>
                    <Grid item xs={12}>
                        {Object.entries(subscriptions).map(([key, value]) => (
                            <React.Fragment>
                                <Typography
                                    variant={'h3'}
                                    className={classes.dividerText}
                                >
                                    {this.state.courses[key] ? (
                                        this.state.courses[key].position +
                                        '. ' +
                                        this.state.courses[key].name +
                                        ' - ' +
                                        this.state.courses[key].height +
                                        ' cm'
                                    ) : (
                                        <Skeleton height={20} width={200} />
                                    )}
                                </Typography>

                                {Object.entries(value).map(([key, obj]) => (
                                    <TicketSubscription
                                        key={key}
                                        event={event}
                                        subscription={obj}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

DialogTicket.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    ticket: PropTypes.object.isRequired,
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    downloadTicket: PropTypes.func.isRequired,
    t: PropTypes.object.isRequired,
};

DialogTicket.defaultProps = {
    open: false,
};

const DialogTicketState = connect(null, {
    downloadTicket,
})(DialogTicket);
const DialogTicketStyled = withStyles(useStyles)(DialogTicketState);
const DialogTicketTranslated = withTranslation()(DialogTicketStyled);

export { DialogTicketTranslated as DialogTicket };
