import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { dynamicInjectReducer } from 'reducers/InjectReducer';
import { connect } from 'react-redux';

import { urls, getUrl } from 'urls';
import PropTypes from 'prop-types';
import Reducer from './reducer';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import { getIdFromUrl } from 'utils';

import { deleteCourse, downloadStartList } from './actions';

import {
    DialogCourse,
    DialogDelete,
    DownloadFile,
    PopupMenu,
    PopupMenuItem,
} from 'components';

class PopupMenuAdminCourse extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            eventId: this.props.match.params.eventId,
            courseId: this.props.match.params.courseId,
            dialogDeleteOpen: false,
            dialogCourseOpen: false,
            isDownloading: false,
        };

        // event handlers
        this.handleReplaceCourse = this.handleReplaceCourse.bind(this);
        this.handleChangeCourse = this.handleChangeCourse.bind(this);
        this.handleDeleteCourse = this.handleDeleteCourse.bind(this);
        this.handleDialogDeleteOpen = this.handleDialogDeleteOpen.bind(this);
        this.handleDialogDeleteClose = this.handleDialogDeleteClose.bind(this);
        this.handleDialogCourseSelect = this.handleDialogCourseSelect.bind(
            this
        );
        this.handleViewCourse = this.handleViewCourse.bind(this);
        this.handleDialogCourseClose = this.handleDialogCourseClose.bind(this);
    }

    componentDidUpdate(prevProps) {
        // deleting
        if (prevProps.isDeleted !== this.props.isDeleted) {
            if (this.props.isDeleted) {
                this.handleDialogDeleteClose();
            }
        }
    }

    handleDeleteCourse() {
        const { course } = this.props;

        if (course) {
            this.props.deleteCourse({ id: course.id });
        }
    }

    handleDialogCourseSelect(course) {
        this.props.history.push(
            getUrl(urls.ADMIN_EVENT_COURSE_START_LIST, {
                eventId: getIdFromUrl(this.props.course.event),
                courseId: course.id,
            })
        );
    }

    handleReplaceCourse() {
        this.setState({
            dialogCourseOpen: true,
        });
    }

    handleDialogDeleteOpen(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.setState({
            dialogDeleteOpen: true,
        });
    }

    handleDialogCourseClose() {
        this.setState({
            dialogCourseOpen: false,
        });
    }

    handleDialogDeleteClose() {
        this.setState({
            dialogDeleteOpen: false,
        });
    }

    handleViewCourse(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const { history, course } = this.props;

        history.push(
            getUrl(urls.ADMIN_EVENT_COURSE_START_LIST, {
                eventId: this.props.event.id,
                courseId: course.id,
            })
        );
    }

    handleChangeCourse(event) {
        event.preventDefault();
        event.stopPropagation();

        const { history, course } = this.props;

        history.push(
            getUrl(urls.ADMIN_EVENT_COURSE_CHANGE, {
                eventId: getIdFromUrl(this.props.course.event),
                courseId: course.id,
            }),
            { goBackToDetail: this.props.isViewedOnDetailPage }
        );
    }

    renderDialogCourse() {
        const { isLoading, course } = this.props;

        if (isLoading || !course) {
            return;
        }

        return (
            <DialogCourse
                open={this.state.dialogCourseOpen}
                onClose={this.handleDialogCourseClose}
                onSelect={this.handleDialogCourseSelect}
                course={course}
                admin
            />
        );
    }

    renderDialogDelete() {
        const { isLoading, course } = this.props;

        if (isLoading || !course) {
            return;
        }

        return (
            <DialogDelete
                open={this.state.dialogDeleteOpen}
                onClose={this.handleDialogDeleteClose}
                action={this.handleDeleteCourse}
                isDeletingCourse={this.props.isDeletingCourse}
            />
        );
    }

    renderFileDownload() {
        return (
            <DownloadFile
                onStart={() => this.setState({ isDownloading: true })}
                onFinish={() => this.setState({ isDownloading: false })}
                onError={() => {
                    AlertService.showError({
                        description: TranslationUtil.getTranslations().t(
                            'common:error.download_startlist'
                        ),
                    });
                }}
                onSuccess={() => {
                    AlertService.showSuccess({
                        description: TranslationUtil.getTranslations().t(
                            'common:success.download_startlist'
                        ),
                    });
                }}
                action={async () =>
                    this.props.downloadStartlist({ id: this.props.course.id })
                }
                start={this.state.isDownloading}
            />
        );
    }

    render() {
        const { t, course, isViewedOnDetailPage } = this.props;

        return (
            <React.Fragment>
                <PopupMenu>
                    {isViewedOnDetailPage && (
                        <PopupMenuItem
                            action={this.handleReplaceCourse}
                            label={t('common:text.replace_course')}
                        />
                    )}
                    {course?.actions && course?.actions['change'] && (
                        <PopupMenuItem
                            action={this.handleChangeCourse}
                            label={t('common:text.edit_course')}
                        />
                    )}
                    <PopupMenuItem
                        action={() => this.setState({ isDownloading: true })}
                        isLoading={this.state.isDownloading}
                        label={t('common:text.download_startlist')}
                    />
                    {course?.actions && course?.actions['delete'] && (
                        <PopupMenuItem
                            action={this.handleDialogDeleteOpen}
                            label={t('common:text.remove_course')}
                            type={'destroy'}
                        />
                    )}
                </PopupMenu>

                {this.renderDialogDelete()}
                {this.renderDialogCourse()}
                {this.renderFileDownload()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.reducerKey || !state[ownProps.reducerKey]) {
        return {};
    }

    const { isDeleting, isDeleted, error } = state[ownProps.reducerKey];

    return {
        isDeletingCourse: isDeleting,
        isDeleted,
        error,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        deleteCourse: ({ id }) =>
            dispatch(deleteCourse({ id, key: ownProps.reducerKey })),
        downloadStartlist: ({ id }) =>
            dispatch(downloadStartList({ id, key: ownProps.reducerKey })),
    };
};

PopupMenuAdminCourse.defaultProps = {
    isViewedOnDetailPage: false,
};

PopupMenuAdminCourse.propTypes = {
    isDeleted: PropTypes.bool,
    isLoading: PropTypes.bool,
    isViewedOnDetailPage: PropTypes.bool,
    isDeletingCourse: PropTypes.bool,
    event: PropTypes.object.isRequiredIf((props) => !props.isLoading),
    course: PropTypes.object.isRequiredIf((props) => !props.isLoading),
    deleteCourse: PropTypes.func.isRequired,
    downloadStartlist: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

const PopupMenuAdminCourseTranslated = withTranslation()(PopupMenuAdminCourse);
const PopupMenuAdminCourseRouter = withRouter(PopupMenuAdminCourseTranslated);
const PopupMenuAdminCourseState = connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupMenuAdminCourseRouter);
const PopupMenuAdminCourseReduced = dynamicInjectReducer({
    key: `popupMenuAdminCourse`,
    reducer: Reducer,
})(PopupMenuAdminCourseState);

export { PopupMenuAdminCourseReduced as PopupMenuAdminCourse };
