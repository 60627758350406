import React from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CardContent, Fade, Grid, MenuItem } from '@material-ui/core';
import { Checkbox, Select } from 'components';

const useStyles = makeStyles((theme) => ({
    content: {
        width: '100%',
    },
}));

function ActionForm(props) {
    const { control, setValue, register, getValues } = useForm({
        defaultValues: {
            action: 'selected',
            selectAll: false,
        },
    });
    const { t } = useTranslation();
    const classes = useStyles();

    if (props.reset) {
        setValue('selectAll', false);
        props.onResetted();
    }

    return (
        <CardContent className={classes.content}>
            <Grid container display={'flex'} alignItems={'center'}>
                <Grid item sm={1} xs={2}>
                    <Checkbox
                        name="selectAll"
                        removeSpacing
                        ref={register}
                        checked={getValues('selectAll')}
                        onChange={(event) => {
                            props.onSelectAll(event.target.checked);
                        }}
                    />
                </Grid>
                <Grid item sm={11} xs={10}>
                    <Fade
                        in={getValues('selectAll') || props.showGlobalActions}
                    >
                        <div>
                            <Select
                                name="action"
                                control={control}
                                setValue={setValue}
                                onChange={props.onAction}
                                ref={register}
                                removeSpacing
                            >
                                <MenuItem value={'selected'} disabled>
                                    {t('forms:select.with_selected')}
                                </MenuItem>
                                <MenuItem value={'confirm'}>
                                    {t('forms:select.confirm')}
                                </MenuItem>
                                <MenuItem value={'unconfirm'}>
                                    {t('forms:select.unconfirm')}
                                </MenuItem>
                            </Select>
                        </div>
                    </Fade>
                </Grid>
            </Grid>
        </CardContent>
    );
}

ActionForm.propTypes = {
    onSelectAll: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    reset: PropTypes.bool,
    onResetted: PropTypes.func.isRequired,
};

export { ActionForm };
