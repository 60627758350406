import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dynamicInjectReducer } from 'reducers/InjectReducer';
import Reducer from './reducer';
import fileDownload from 'js-file-download';

import { downloadFile, resetDownloaded } from './actions';

class DownloadFile extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            fileDownloaded: false,
        };

        // event handlers
        this._downloadFile = this._downloadFile.bind(this);
    }

    componentDidUpdate(prevProps) {
        // download file
        if (
            prevProps.isDownloaded !== this.props.isDownloaded &&
            this.props.isDownloaded === true
        ) {
            this._downloadFile();
        }

        // start download
        if (this.props.start !== prevProps.start && this.props.start === true) {
            if (!this.props.isDownloading && this.props.action) {
                this.props.downloadFile({
                    action: this.props.action,
                    onStart: this.props.onStart,
                    onFinish: this.props.onFinish,
                    onSuccess: this.props.onSuccess,
                    onError: this.props.onError,
                });
            }
        }
    }

    _downloadFile() {
        fileDownload(
            this.props.file.data,
            this.props.file.filename.replaceAll('"', '')
        );

        this.props.resetDownloaded();
    }

    render() {
        return <React.Fragment />;
    }
}

DownloadFile.propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    onStart: PropTypes.func,
    onFinish: PropTypes.func,
    action: PropTypes.func.isRequired,
    file: PropTypes.object,
    downloadFile: PropTypes.func.isRequired,
    resetDownloaded: PropTypes.func.isRequired,
    isDownloaded: PropTypes.bool,
    isDownloading: PropTypes.bool,
    start: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.reducerKey || !state[ownProps.reducerKey]) {
        return {};
    }

    const { isDownloaded, isDownloading, error, file } = state[
        ownProps.reducerKey
    ];

    return {
        isDownloaded,
        isDownloading,
        error,
        file,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        downloadFile: ({ action, onStart, onFinish, onSuccess, onError }) =>
            dispatch(
                downloadFile({
                    action,
                    onStart,
                    onFinish,
                    onSuccess,
                    onError,
                    key: ownProps.reducerKey,
                })
            ),
        resetDownloaded: () =>
            dispatch(resetDownloaded({ key: ownProps.reducerKey })),
    };
};

const DownloadFileState = connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadFile);
const DownloadFileReduced = dynamicInjectReducer({
    key: `componentDownloadFile`,
    reducer: Reducer,
})(DownloadFileState);

export { DownloadFileReduced as DownloadFile };
