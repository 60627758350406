import {
    TICKETS_PAGE_LOADING,
    TICKETS_PAGE_LOADING_SUCCESS,
    TICKETS_PAGE_LOADING_FAILED,
} from './actionTypes';

import { listTickets as apiListTickets } from 'services/TicketService';

export const listTickets = (page) => {
    return (dispatch) => {
        dispatch({ type: TICKETS_PAGE_LOADING });

        apiListTickets(page)
            .then((result) => {
                dispatch({
                    type: TICKETS_PAGE_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: TICKETS_PAGE_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};
