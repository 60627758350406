import {
    DIALOG_COURSE_LOADING,
    DIALOG_COURSE_SUCCESS,
    DIALOG_COURSE_FAILED,
} from './actionTypes';

import { listAdminCourses, listPublicCourses } from 'services/CourseService';

export const listCourses = ({ eventId, admin = false }) => {
    return (dispatch) => {
        dispatch({
            type: DIALOG_COURSE_LOADING,
        });

        let func = listAdminCourses;
        if (!admin) {
            func = listPublicCourses;
        }

        func({ eventId })
            .then((result) => {
                dispatch({
                    type: DIALOG_COURSE_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: DIALOG_COURSE_FAILED,
                    payload: error,
                });
            });
    };
};
