import React, { Component } from 'react';
import CookieConsent from 'react-cookie-consent';
// import { BrowserRouter as Router } from 'react-router-dom';
import { Router, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AlertContainer } from './components/AlertContainer';
import EventService from 'lib/EventService';
import { EVENT } from 'enums';

import history from './services/history';
import Routes from './routes';
import {
    checkAuthenticationSync,
    checkAuthentication,
} from './actions/authentication';
import { getUser } from './actions/user';
import { getAuthorizations } from './actions/authorizations';
import { AdminContext } from './utils';
// import { MessengerChat } from 'react-messenger-chat-plugin';

class App extends Component {
    constructor(props) {
        super(props);

        // get logged in status fast -> check token in browser
        const result = checkAuthenticationSync();
        this.state = {
            isLoggedIn: result !== null,
            adminTitle: null,
            adminBackAction: null,
            setAdminTitle: this.setAdminTitle.bind(this),
            setAdminBackAction: this.setAdminBackAction.bind(this),
        };

        // store it in the store
        this.props.checkAuthentication();

        // events
        this.reloadAuthorizations = this.reloadAuthorizations.bind(this);
    }

    componentDidMount() {
        EventService.subscribe(
            EVENT.CLUB_CHANGED,
            'app',
            this.reloadAuthorizations
        );
        EventService.subscribe(
            EVENT.COURSE_CHANGED,
            'app',
            this.reloadAuthorizations
        );
    }

    componentDidUpdate(prevProps) {
        // if the user is logged in, get profile and user data
        if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
            if (this.props.isLoggedIn) {
                this.props.getUser();
            }

            this.setState({
                isLoggedIn: this.props.isLoggedIn,
            });
        }

        if (!prevProps.user && this.props.user) {
            this.reloadAuthorizations();
        }
    }

    componentWillUnmount() {
        EventService.unsubscribe(EVENT.CLUB_CHANGED, 'app');
        EventService.unsubscribe(EVENT.COURSE_CHANGED, 'app');
    }

    reloadAuthorizations() {
        this.props.getAuthorizations({ email: this.props.user.email });
    }

    setAdminTitle(title) {
        this.setState({
            adminTitle: title,
        });
    }

    setAdminBackAction(action) {
        this.setState({
            adminBackAction: action,
        });
    }

    // renderMessengerChat() {
    //     return (
    //         <MessengerChat
    //             pageId="1151465361695940"
    //             language="nl_BE"
    //             themeColor="#332515"
    //         />
    //     );
    // }

    render() {
        const { t, isLoading } = this.props;

        return (
            <AdminContext.Provider value={this.state}>
                <AlertContainer />
                <Router history={history}>
                    <Routes
                        isLoggedIn={this.state.isLoggedIn}
                        isLoading={isLoading}
                        adminTitle={this.state.adminTitle}
                        adminBackAction={this.state.adminBackAction}
                    />
                    <CookieConsent
                        location={'bottom'}
                        buttonText={t('cookie:button.primary')}
                        style={{ padding: '16px', zIndex: '1201' }}
                        disableButtonStyles
                        sameSite={'lax'}
                        cookieName={'cookie-consent'}
                        buttonClasses={
                            'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-disableElevation'
                        }
                    >
                        {t('cookie:description.text')}{' '}
                        <Link to="/cookie">
                            {t('cookie:button.more_information')}
                        </Link>
                    </CookieConsent>
                </Router>
            </AdminContext.Provider>
        );
    }
}

App.propTypes = {
    // props
    isLoggedIn: PropTypes.bool,
    isLoading: PropTypes.bool,
    user: PropTypes.object,
    // handlers
    checkAuthentication: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    getAuthorizations: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { isLoggedIn } = state.authentication;
    const { isLoading } = state.global;
    const { user } = state.user;

    return {
        isLoggedIn,
        isLoading,
        user,
    };
};

const AppState = connect(mapStateToProps, {
    checkAuthentication,
    getUser,
    getAuthorizations,
})(App);
const AppTranslated = withTranslation()(AppState);

export { AppTranslated as App };
