import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { getUrl, urls } from 'urls';
import { withStyles } from '@material-ui/core/styles';

import { Container, Grid, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { CardEvent, EmptyMessage, Toast } from 'components';
import { TemplateMarketing } from '../TemplateMarketing';
import history from 'services/history';

import { listFutureEvents } from './actions';
import { scrollIntoView } from '../../utils';

const useStyles = (theme) => ({
    flex: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(2),
    },
});

class EventsPage extends Component {
    componentDidMount() {
        // check if there's a page
        const query = new URLSearchParams(window.location.search);
        let page = query.get('page');

        if (!page || page <= 0) {
            page = 1;
        }
        this.props.listFutureEvents(page);

        this.renderEvents = this.renderEvents.bind(this);
        this.handlePage = this.handlePage.bind(this);
    }

    handlePage(object, page) {
        // load next page
        this.props.listFutureEvents(page);

        scrollIntoView('#top-events');
        history.push(window.location.pathname + '?page=' + page);
    }

    renderPagination() {
        const { classes, total_pages, current_page } = this.props;

        if (total_pages <= 0) {
            return;
        }

        return (
            <Grid item xs={12}>
                <div className={classes.flex}>
                    <Pagination
                        count={total_pages}
                        shape="rounded"
                        page={current_page}
                        onChange={this.handlePage}
                    />
                </div>
            </Grid>
        );
    }

    renderEvents() {
        const { isLoading, error, t, events } = this.props;

        if (isLoading) {
            return (
                <Grid
                    container
                    spacing={3}
                    display="flex"
                    justify="space-between"
                >
                    {[...new Array(6)].map((index) => (
                        <Grid item md={4} sm={6} xs={12} key={index}>
                            <CardEvent isLoading />
                        </Grid>
                    ))}
                </Grid>
            );
        }

        if (error) {
            return (
                <React.Fragment>
                    <Toast
                        message={t('common:error.general.description')}
                        open
                    />
                    <EmptyMessage
                        title={t('common:text.no_events.title')}
                        description={t('common:text.no_events.description')}
                    />
                </React.Fragment>
            );
        }

        if (events.length === 0) {
            return (
                <React.Fragment>
                    <EmptyMessage
                        title={t('common:text.no_events.title')}
                        description={t('common:text.no_events.description')}
                    />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div id="top-events" />
                <Grid
                    container
                    spacing={3}
                    display="flex"
                    justify="space-between"
                >
                    {events.map((event, i) => (
                        <Grid item md={4} sm={6} xs={12} key={i}>
                            <CardEvent
                                id={event.id}
                                name={event.name}
                                club={event.club}
                                image={event.header_image}
                                days={event.days}
                                closed={event.subscriptions_closed}
                            />
                        </Grid>
                    ))}

                    {this.renderPagination()}
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const { t } = this.props;

        return (
            <TemplateMarketing>
                <Helmet>
                    <title>{t('common:title.jumpings')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(urls.EVENTS, {}, true)}
                    />
                </Helmet>
                <Container maxWidth={'lg'}>
                    <Typography variant={'h1'} className={'page-title'}>
                        {t('common:title.jumpings')}
                    </Typography>

                    {this.renderEvents()}
                </Container>
            </TemplateMarketing>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoading,
        error,
        events,
        total_pages,
        total_events,
        current_page,
    } = state.pagePublicEvents;

    return {
        isLoading,
        error,
        events,
        total_pages,
        total_events,
        current_page,
    };
};

const PublicEventsPageState = connect(mapStateToProps, {
    listFutureEvents,
})(EventsPage);

const PublicEventsPageTranslated = withTranslation()(PublicEventsPageState);
const PublicEventsPageStyles = withStyles(useStyles)(
    PublicEventsPageTranslated
);

export { PublicEventsPageStyles as PublicEventsPage };
