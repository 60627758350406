import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Breadcrumbs, Grid, Typography, IconButton } from '@material-ui/core';
import { ArrowBackOutlined as ArrowBackIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

const useStyles = (theme) => ({
    actions: {
        marginTop: theme.spacing(3),
        textAlign: 'right',

        [theme.breakpoints.up('sm')]: {
            marginTop: '5px',
        },

        [theme.breakpoints.down('xs')]: {
            '& .MuiButton-root': {
                width: '100%',
            },
        },
    },
    container: {
        marginBottom: theme.spacing(4),
    },
    noMargin: {
        marginBottom: 0,
    },
    breadcrumbs: {
        fontSize: '.875rem',
        lineHeight: '125%',
        marginBottom: theme.spacing(3),
    },
    inactive: {
        color: theme.colors.warmGrey + ' !important',
        fontWeight: 'normal !important',
    },
    active: {
        fontSize: '.875rem',
    },
    description: {
        color: theme.colors.warmGrey,
        marginTop: theme.spacing(1),
    },
    offset: {
        // marginTop: theme.spacing(2),
        margin: '0 !important',
    },
});

class AdminHeader extends Component {
    renderBreadcrumbs() {
        const { breadcrumbs, classes, t } = this.props;
        const length = breadcrumbs ? breadcrumbs.length : 0;

        if (length === 0) {
            return;
        }

        return (
            <Grid item xs={12}>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    className={classes.breadcrumbs}
                >
                    <Link to="/admin" className={classes.inactive}>
                        {t('common:text.menu.home')}
                    </Link>
                    {breadcrumbs &&
                        breadcrumbs.map((obj, index) => {
                            if (index === length - 1) {
                                return (
                                    <Typography
                                        color="textPrimary"
                                        className={classes.active}
                                        key={index}
                                    >
                                        {obj.title}
                                    </Typography>
                                );
                            } else {
                                return (
                                    <Link
                                        to={obj.url}
                                        key={index}
                                        className={classes.inactive}
                                    >
                                        {obj.title}
                                    </Link>
                                );
                            }
                        })}
                </Breadcrumbs>
            </Grid>
        );
    }

    renderLoading() {
        const {
            isLoading,
            withBreadcrumbs,
            withTitle,
            withDescription,
        } = this.props;

        if (!isLoading) {
            return;
        }

        return (
            <React.Fragment>
                {withBreadcrumbs && (
                    <Grid item xs={12}>
                        <Skeleton height={25} width={100} />
                    </Grid>
                )}

                <Grid item sm={8} xs={12}>
                    {withTitle && <Skeleton height={70} width={200} />}
                    {withDescription && <Skeleton height={20} width={100} />}
                </Grid>
                <Grid item sm={4} xs={12} />
            </React.Fragment>
        );
    }

    renderContent() {
        const {
            title,
            descriptionContainer,
            actionContainer,
            classes,
            variant,
            isLoading,
            backAction,
        } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return (
            <React.Fragment>
                {this.renderBreadcrumbs()}

                {backAction && (
                    <Grid item sm={1}>
                        <IconButton>
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                )}

                <Grid item sm={backAction ? 7 : 8} xs={12}>
                    <Typography
                        className={classes.offset}
                        variant={variant === 'small' ? 'h2' : 'h1'}
                    >
                        {title}
                    </Typography>
                    {descriptionContainer && (
                        <div className={classes.description}>
                            {descriptionContainer()}
                        </div>
                    )}
                </Grid>

                <Grid item sm={4} xs={12} className={classes.actions}>
                    {actionContainer}
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const { removeMargin, classes } = this.props;

        return (
            <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                className={classNames(
                    classes.container,
                    removeMargin && classes.noMargin
                )}
            >
                {this.renderContent()}
            </Grid>
        );
    }
}

AdminHeader.propTypes = {
    // props
    title: PropTypes.string.isRequired,
    descriptionContainer: PropTypes.func,
    actionContainer: PropTypes.object,
    variant: PropTypes.oneOf(['small', 'default']),
    backAction: PropTypes.func,
    breadcrumbs: PropTypes.array,
    withBreadcrumbs: PropTypes.bool,
    withTitle: PropTypes.bool,
    withDescription: PropTypes.bool,
    isLoading: PropTypes.bool,
    removeMargin: PropTypes.bool,
    classes: PropTypes.object,
    // handlers
    t: PropTypes.func.isRequired,
};

const AdminHeaderStyled = withStyles(useStyles)(AdminHeader);
const AdminHeaderTranslated = withTranslation()(AdminHeaderStyled);

export { AdminHeaderTranslated as AdminHeader };
