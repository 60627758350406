import {
    FILE_DOWNLOAD_LOADING,
    FILE_DOWNLOAD_SUCCESS,
    FILE_DOWNLOAD_FAILED,
    FILE_DOWNLOAD_RESET,
} from './actionTypes';

const INITIAL_STATE = {
    isDownloading: false,
    isDownloaded: false,
    error: null,
    file: null,
};

export default function (name = '') {
    return (state = INITIAL_STATE, action) => {
        if (!action.meta || action.meta.key !== name) {
            return state;
        }

        switch (action.type) {
            // csv
            case FILE_DOWNLOAD_LOADING:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isDownloading: true,
                };
            case FILE_DOWNLOAD_SUCCESS:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isDownloaded: true,
                    file: action.payload,
                };
            case FILE_DOWNLOAD_FAILED:
                return {
                    ...state,
                    ...INITIAL_STATE,
                    error: true,
                };
            case FILE_DOWNLOAD_RESET:
                return {
                    ...state,
                    ...INITIAL_STATE,
                };

            default:
                return state;
        }
    };
}
