import {
    AUTHORIZATIONS_LOADING,
    AUTHORIZATIONS_LOADING_SUCCESS,
    AUTHORIZATIONS_LOADING_FAILED,
} from './actionTypes';

import { getAuthorizations as apiGetAuthorizations } from '../services/AuthorizationsService';

export const getAuthorizations = ({ email }) => {
    return (dispatch) => {
        dispatch({ type: AUTHORIZATIONS_LOADING });

        apiGetAuthorizations({ email })
            .then((result) => {
                dispatch({
                    type: AUTHORIZATIONS_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: AUTHORIZATIONS_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};
