import React, { Component, Suspense } from 'react';
import { SnackbarProvider } from 'notistack';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import CssBaseline from '@material-ui/core/CssBaseline';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import { App } from './App';
import { I18nextProvider } from 'react-i18next';

import { Provider } from 'react-redux';
import StoreUtil from './lib/StoreUtil';
import TranslationUtil from './lib/TranslationUtil';
import reducers from './reducers';

// load fonts
const fontConfig = {
    google: {
        families: ['Nunito:800,800i', 'Lato:300,300i,400,400i,700,700i'],
    },
};

// override default styles
const breakpoints = createBreakpoints({});
const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#23190e',
            main: '#332515',
            dark: '#5b5043',
            contrastText: '#fff',
        },
        secondary: {
            light: '#a59366',
            main: '#ecd393',
            dark: '#efdba8',
            contrastText: '#332515',
        },
        background: {
            default: '#f9f6f1',
        },
        text: {
            primary: '#332515',
        },
        error: {
            main: '#DA3030',
        },
    },
    colors: {
        armadillo: '#423f34',
        black: '#000000',
        bone: '#e7d7c5',
        chalky: '#ecd393',
        cigar: '#7b5933',
        firebrick: '#b42a1e',
        heavyMetal: '#2d392c',
        mediumLaurel: '#6c8a6b',
        mercury: '#e8e7e3',
        mikado: '#332515',
        mischka: '#cdd4da',
        norway: '#a4b8a3',
        parisWhite: '#c0cec0',
        permanentGeraniumLake: '#da3030',
        potPourri: '#fae6e6',
        springWood: '#f9f6f1',
        warmGrey: '#968f86',
        white: '#ffffff',
    },
    typography: {
        fontSize: 16,
        fontWeight: '400',
        fontFamily: [
            'Lato',
            '-apple-system',
            'Roboto',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        h1: {
            color: '#332515',
            fontFamily: [
                'Nunito',
                'Lato',
                '"Helvetica Neue"',
                'sans-serif',
            ].join(','),
            fontWeight: 800,
            fontSize: '2.50rem',
            lineHeight: '1.15',

            [breakpoints.up('sm')]: {
                fontSize: '3.75rem',
            },
        },
        h2: {
            color: '#332515',
            fontFamily: [
                'Nunito',
                'Lato',
                '"Helvetica Neue"',
                'sans-serif',
            ].join(','),
            fontWeight: 800,
            fontSize: '1.75rem',
            lineHeight: '1.15',

            [breakpoints.up('sm')]: {
                fontSize: '2.25rem',
            },
        },
        h3: {
            color: '#332515',
            fontFamily: [
                'Nunito',
                'Lato',
                '"Helvetica Neue"',
                'sans-serif',
            ].join(','),
            fontWeight: 800,
            fontSize: '1.25rem',
            lineHeight: '1.15',

            [breakpoints.up('sm')]: {
                fontSize: '1.75rem',
            },
        },
        h4: {
            color: '#332515',
            fontFamily: [
                'Nunito',
                'Lato',
                '"Helvetica Neue"',
                'sans-serif',
            ].join(','),
            fontWeight: 800,
            fontSize: '1.5rem',
            lineHeight: '1.15',
        },
        h5: {
            color: '#332515',
            fontFamily: [
                'Nunito',
                'Lato',
                '"Helvetica Neue"',
                'sans-serif',
            ].join(','),
            fontWeight: 800,
            fontSize: '1.25rem',
            lineHeight: '1.15',
        },
    },
    overrides: {
        MuiAvatar: {
            rounded: {
                borderRadius: 5,
            },
        },
        MuiBackdrop: {
            root: {
                // backdropFilter: 'blur(3px)',
                '&~ .MuiDialog-container': {
                    backdropFilter: 'blur(3px)',
                },
            },
        },
        MuiButton: {
            root: {
                borderRadius: '5px',
                fontWeight: 'bold',
                textTransform: 'none',
                padding: '12px 20px',
            },
        },
        MuiCardHeader: {
            root: {
                padding: '24px 24px 16px',

                [breakpoints.up('sm')]: {
                    padding: '32px 32px 16px',
                },
            },
        },
        MuiCardContent: {
            root: {
                padding: '0px 24px',

                [breakpoints.up('sm')]: {
                    padding: '0 32px',
                },

                '&:first-child': {
                    paddingTop: '24px',
                },
            },
        },
        MuiCardActions: {
            root: {
                padding: '0 32px',
            },
        },
        MuiContainer: {
            root: {
                [breakpoints.up('sm')]: {
                    width: 'calc(100% - 48px)',
                },
            },

            disableGutters: {
                [breakpoints.up('sm')]: {
                    width: '100%',
                },
            },
        },
        MuiDialog: {
            paper: {
                [breakpoints.down('sm')]: {
                    margin: '32px 0',
                },
            },
        },
        MuiDialogActions: {
            root: {
                padding: '8px 32px 24px',
                justifyContent: 'flex-start',
                flexDirection: 'column',

                [breakpoints.down('sm')]: {
                    padding: '16px 24px 16px',
                },

                '& button': {
                    marginTop: '4px',
                },
            },
        },
        MuiDialogContent: {
            root: {
                padding: '0 32px',

                [breakpoints.down('sm')]: {
                    padding: '0 24px',
                },
            },
        },
        MuiDialogTitle: {
            root: {
                padding: '32px 32px 8px',

                [breakpoints.down('sm')]: {
                    padding: '24px 24px 8px',
                },

                '& h2': {
                    fontFamily: 'Nunito',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginRight: '32px',
                    marginTop: '4px',
                },

                '& .closeButton': {
                    position: 'absolute',
                    right: '24px',
                    top: '24px',
                    color: '#968f86',

                    [breakpoints.down('sm')]: {
                        right: '16px',
                        top: '16px',
                    },
                },
            },
        },
        MuiFab: {
            root: {
                borderRadius: '5px',
            },
        },
        MuiFormControl: {
            root: {
                marginBottom: '0',
                width: '100%',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '0.875rem',
                fontWeight: 'bold',
                color: '#423f34',
                marginBottom: '0.5rem',
                '& + .MuiInput-formControl': {
                    marginTop: '1.5rem',
                },
                '&.MuiInputLabel-shrink': {
                    transform: 'scale(1)',
                },
            },
            asterisk: {
                color: '#da3030',
            },
        },
        MuiIconButton: {
            root: {
                borderRadius: '5px',
            },
        },
        MuiInput: {
            root: {
                background: '#eae9e7',
                borderWidth: 1,
                borderColor: '#b1b1b1',
                borderRadius: '5px',
                overflow: 'draft',
            },
            underline: {
                '&::before': {
                    borderBottomColor: 'transparent',
                },
            },
            input: {
                padding: '12px 15px',
                width: '100%',
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: '1rem',
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 20,
            },

            elevation1: {
                boxShadow: '0 0 10px rgba(0, 0, 0, .1)',
            },
        },
        MuiStepIcon: {
            root: {
                '&$active': {
                    color: '#ecd393',

                    '& text': {
                        fill: '#332515',
                        fontWeight: 'bold',
                    },
                },
            },
        },
        MuiTouchRipple: {
            child: {
                borderRadius: '5px',
            },
        },
        MuiTypography: {
            body1: {
                fontSize: '1rem',
            },
        },
    },
});

// setup global logging
/* eslint-disable no-undef */
const log = (...msgs) => {
    if (process.env.NODE_ENV === 'development') {
        console.log(...msgs);
    }
};
global.log = log;
/* eslint-enable no-undef */

class Root extends Component {
    constructor(props) {
        super(props);

        StoreUtil.initialize(reducers);
    }

    componentDidMount() {
        TranslationUtil.initialize();
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={StoreUtil.getStore()}>
                    <I18nextProvider i18n={TranslationUtil.getTranslations()}>
                        <Suspense fallback={'loading'}>
                            <WebfontLoader config={fontConfig}>
                                <SnackbarProvider>
                                    <App />
                                </SnackbarProvider>
                            </WebfontLoader>
                        </Suspense>
                    </I18nextProvider>
                </Provider>
            </ThemeProvider>
        );
    }
}

export default Root;
