import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';

import { DialogContent, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dialog } from '..';
import { PaymentMethodForm } from './form';

const useStyles = (theme) => ({
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    contentText: {
        color: theme.colors.mikado,
        padding: 0,
        textAlign: 'left',
    },
    alert: {
        width: '100%',
    },
});

class DialogPaymentMethod extends Component {
    render() {
        const { classes, onClose, open, t } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={t('common:title.payment_method')}
                subtitle={t('common:text.payment_method')}
                showLine
            >
                <Grid container>
                    {this.props.errors && (
                        <Alert severity={'error'} className={classes.alert}>
                            {this.props.errors['non_field_errors'] ||
                                t('common:error.general.title')}
                        </Alert>
                    )}
                    <Grid item xs={12}>
                        <DialogContent classes={{ root: classes.contentText }}>
                            <PaymentMethodForm
                                onSubmit={this.props.action}
                                errors={this.props.errors}
                                onClose={onClose}
                                isLoading={this.props.isLoading}
                            />
                        </DialogContent>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

DialogPaymentMethod.defaultProps = {
    isLoading: false,
    classes: {},
};

DialogPaymentMethod.propTypes = {
    // props
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    action: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    isLoading: PropTypes.bool,
    // functions
    t: PropTypes.func.isRequired,
};

DialogPaymentMethod.defaultProps = {
    open: false,
};

const DialogPaymentMethodStyled = withStyles(useStyles)(DialogPaymentMethod);
const DialogPaymentMethodTranslated = withTranslation()(
    DialogPaymentMethodStyled
);

export { DialogPaymentMethodTranslated as DialogPaymentMethod };
