import {
    ADMIN_ORDER_LOADING,
    ADMIN_ORDER_SUCCESS,
    ADMIN_ORDER_FAILED,
    ADMIN_ORDER_PAYMENTS_LOADING,
    ADMIN_ORDER_PAYMENTS_SUCCESS,
    ADMIN_ORDER_PAYMENTS_FAILED,
    ADMIN_ORDER_PAY_LOADING,
    ADMIN_ORDER_PAY_SUCCESS,
    ADMIN_ORDER_PAY_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    order: null,
    error: null,
    // payments
    isLoadingPayments: false,
    payments: [],
    total_payments: 0,
    totalPaymentsPages: 0,
    currentPaymentPage: 1,
    errorPayments: null,
    // pay
    isProcessingPayment: false,
    payError: null,
    payRedirect: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_ORDER_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ADMIN_ORDER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                order: action.payload,
            };
        case ADMIN_ORDER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case ADMIN_ORDER_PAYMENTS_LOADING:
            return {
                ...state,
                isLoadingPayments: true,
            };
        case ADMIN_ORDER_PAYMENTS_SUCCESS:
            return {
                ...state,
                isLoadingPayments: false,
                total_payments: action.payload.count,
                totalPaymentsPages: action.payload.total_pages,
                payments: action.payload.results,
                currentPaymentPage: action.payload.current_page,
            };
        case ADMIN_ORDER_PAYMENTS_FAILED:
            return {
                ...state,
                isLoadingPayments: false,
                errorPayments: action.payload,
            };

        case ADMIN_ORDER_PAY_LOADING:
            return {
                ...state,
                payRedirect: null,
                isProcessingPayment: true,
            };
        case ADMIN_ORDER_PAY_SUCCESS:
            return {
                ...state,
                isProcessingPayment: false,
                payRedirect: action.payload,
            };
        case ADMIN_ORDER_PAY_FAILED:
            return {
                ...state,
                isProcessingPayment: false,
                payError: action.payload,
            };

        default:
            return state;
    }
};
