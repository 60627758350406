import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { getUrl, urls } from 'urls';
import PropTypes from 'prop-types';

import {
    Container,
    Fab,
    LinearProgress,
    useScrollTrigger,
} from '@material-ui/core';
import { KeyboardArrowUp as KeyboardArrowUpIcon } from '@material-ui/icons';
import { TopNavigation } from './navigation';
import { Pages } from './pages';
import { AutoScrollToTop, ScrollToTop } from './components';

import { Footer } from 'components';

const useStyles = makeStyles((theme) => ({
    offset: {
        minHeight: theme.spacing(11),

        [theme.breakpoints.up('sm')]: {
            minHeight: theme.spacing(18),
        },
    },
    offsetAdmin: {
        minHeight: theme.spacing(11),

        [theme.breakpoints.up('sm')]: {
            minHeight: theme.spacing(12),
        },
    },
}));

/* eslint-disable no-undef */
export default function Routes(props) {
    const classes = useStyles();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 1,
    });
    const isAdmin = window.location.pathname.startsWith(getUrl(urls.ADMIN));

    return (
        <React.Fragment>
            {props.isLoading && (
                <LinearProgress
                    color={'primary'}
                    style={{
                        zIndex: 1300,
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                    }}
                />
            )}

            <AutoScrollToTop />
            <TopNavigation trigger={trigger} {...props} />

            <div id="back-to-top-anchor" />

            <Container
                maxWidth={false}
                className="main-container"
                disableGutters
            >
                <div
                    className={classNames(
                        isAdmin ? classes.offsetAdmin : classes.offset,
                        'page-offset'
                    )}
                />
                <Pages isLoggedIn={props.isLoggedIn} {...props} />
            </Container>

            <Container maxWidth={false} disableGutters>
                <Footer />
            </Container>

            <ScrollToTop {...props}>
                <Fab
                    color="primary"
                    size="small"
                    aria-label="Scroll back to the top"
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollToTop>
        </React.Fragment>
    );
}

Routes.propTypes = {
    isLoggedIn: PropTypes.bool,
    isLoading: PropTypes.bool,
};
/* eslint-enable no-undef */
