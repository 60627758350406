import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { getUrl, urls } from 'urls';
import { Helmet } from 'react-helmet';
import { Animated } from 'react-animated-css';
import { InView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import {
    CircularProgress,
    Container,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    LocalActivityOutlined as LocalActivityIcon,
    PaymentOutlined as PaymentIcon,
    DashboardOutlined as DashboardIcon,
    PolicyOutlined as SupportIcon,
    DevicesOutlined as DevicesIcon,
    BackupOutlined as BackupIcon,
    CloudDoneOutlined as CloudDoneIcon,
    HelpOutline as HelpIcon,
    ListOutlined as ListIcon,
    CodeOutlined as CodeIcon,
    EuroSymbolOutlined as EuroSymbolIcon,
} from '@material-ui/icons';

import { TemplateMarketing } from '..';
import { Button, USPList, USPImageBlock } from 'components';

import IconBancontact from 'assets/images/icons/payment/bancontact.svg';
import IconING from 'assets/images/icons/payment/ing.svg';
import IconVisa from 'assets/images/icons/payment/visa.svg';
import IconMastercard from 'assets/images/icons/payment/mastercard.svg';
import IconBelfius from 'assets/images/icons/payment/belfius.svg';
import IconKBC from 'assets/images/icons/payment/kbc.svg';
import IconIDeal from 'assets/images/icons/payment/ideal.svg';
import IconQuoteStart from 'assets/images/quote-start.svg';
import IconQuoteEnd from 'assets/images/quote-end.svg';
import ImgAdmin from 'assets/images/admin-en.svg';
import ImgFree from 'assets/images/free.svg';
import ImgCreateAccountClub from 'assets/images/create-account-club-en.svg';
import ImgAddEventAdmin from 'assets/images/admin-add-event-en.svg';
import ImgEventDetailAdmin from 'assets/images/admin-event-detail-en.svg';

const useStyles = (theme) => ({
    header: {
        marginTop: theme.spacing(3),

        '& h1, & h2': {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
        },

        '& p': {
            marginBottom: theme.spacing(4),
        },
    },
    content: {
        padding: '96px 0',
    },
    contentSmall: {
        padding: '48px 0',

        '& h2': {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(3),
        },

        '& button': {
            marginTop: '32px',
        },
    },
    contentColumn: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(12),

        '& h3': {
            marginTop: 0,
            marginBottom: theme.spacing(1),
        },
    },
    center: {
        textAlign: 'center',
    },
    functionalities: {
        '& p': {
            marginBottom: theme.spacing(8),
        },
    },
    quote: {
        '& h2': {
            position: 'relative',
            zIndex: 2,

            '& span': {
                position: 'relative',
                zIndex: 2,
            },
        },

        '& img': {
            position: 'absolute',
            zIndex: 1,

            '&.start': {
                top: '-16px',
                left: '-24px',
            },

            '&.end': {
                top: '-16px',
                right: '-24px',
            },
        },
    },
});

class OrganisersPage extends Component {
    constructor(props) {
        super(props);

        // data
        this.state = {
            uspList: [
                {
                    icon: LocalActivityIcon,
                    title: props.t('common:usp.title.easy'),
                    description: props.t('common:usp.description.easy'),
                },
                {
                    icon: PaymentIcon,
                    title: props.t('common:usp.title.fast_payment'),
                    description: props.t('common:usp.description.fast_payment'),
                },
                {
                    icon: DashboardIcon,
                    title: props.t('common:usp.title.admin'),
                    description: props.t('common:usp.description.admin'),
                },
            ],
            uspFunctionalities: [
                {
                    icon: DevicesIcon,
                    title: props.t('common:usp.title.subscribe_online'),
                    description: props.t(
                        'common:usp.description.subscribe_online'
                    ),
                },
                {
                    icon: BackupIcon,
                    title: props.t('common:usp.title.backup'),
                    description: props.t('common:usp.description.backup'),
                },
                {
                    icon: CloudDoneIcon,
                    title: props.t('common:usp.title.always_available'),
                    description: props.t(
                        'common:usp.description.always_available'
                    ),
                },
                {
                    icon: DashboardIcon,
                    title: props.t('common:usp.title.secretariat'),
                    description: props.t('common:usp.description.secretariat'),
                },
                {
                    //     icon: RefreshIcon,
                    //     title: props.t('common:usp.title.resubscribe'),
                    //     description: props.t('common:usp.description.resubscribe'),
                    // }, {
                    icon: HelpIcon,
                    title: props.t('common:usp.title.online_helpdesk'),
                    description: props.t(
                        'common:usp.description.online_helpdesk'
                    ),
                },
                {
                    icon: ListIcon,
                    title: props.t('common:usp.title.start_list'),
                    description: props.t('common:usp.description.start_list'),
                },
                {
                    icon: CodeIcon,
                    title: props.t('common:usp.title.platform'),
                    description: props.t('common:usp.description.platform'),
                },
                {
                    icon: EuroSymbolIcon,
                    title: props.t('common:usp.title.free_promotion'),
                    description: props.t(
                        'common:usp.description.free_promotion'
                    ),
                },
            ],
            subscribeUSPList: [
                {
                    title: props.t('common:usp.title.create_account'),
                    description: props.t(
                        'common:usp.description.create_account'
                    ),
                    cta: {
                        text: props.t('common:usp.cta.create_account'),
                        onClick: () => {
                            this.props.history.push(getUrl(urls.ADMIN));
                        },
                    },
                    image: {
                        src: ImgCreateAccountClub,
                        alt: props.t(
                            'common:img.alt.organisers.create_account_club'
                        ),
                    },
                },
                {
                    title: props.t('common:usp.title.create_event'),
                    description: props.t('common:usp.description.create_event'),
                    cta: {
                        text: props.t('common:usp.cta.create_event'),
                        onClick: () => {
                            this.props.history.push(
                                getUrl(urls.ADMIN_EVENT_ADD)
                            );
                        },
                    },
                    image: {
                        src: ImgAddEventAdmin,
                        alt: props.t(
                            'common:img.alt.organisers.create_event_admin'
                        ),
                    },
                },
                {
                    title: props.t('common:usp.title.manage_event'),
                    description: props.t('common:usp.description.manage_event'),
                    cta: {
                        text: props.t('common:usp.cta.manage_event'),
                        onClick: () => {
                            this.props.history.push(getUrl(urls.ADMIN));
                        },
                    },
                    image: {
                        src: ImgEventDetailAdmin,
                        alt: props.t(
                            'common:img.alt.organisers.event_detail_admin'
                        ),
                    },
                },
            ],
        };

        // event handlers
        this.handleViewPricing = this.handleViewPricing.bind(this);
    }

    handleViewPricing(event) {
        event.preventDefault();
        event.stopPropagation();

        this.props.history.push(getUrl(urls.PRICING));
    }

    render() {
        const { t, classes } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <Helmet>
                    <title>{t('common:text.menu.organisers')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>
                <TemplateMarketing>
                    <Container>
                        <InView>
                            {({ inView, ref }) => {
                                return (
                                    <Grid
                                        ref={ref}
                                        container
                                        spacing={3}
                                        alignItems={'center'}
                                        justify={'center'}
                                        className={classNames(
                                            classes.header,
                                            classes.center
                                        )}
                                    >
                                        <Grid item sm={9} xs={12}>
                                            <Animated
                                                animationIn="fadeInDown"
                                                animationOut="fadeOut"
                                                isVisible={inView}
                                            >
                                                <Typography
                                                    ref={ref}
                                                    variant={'h1'}
                                                >
                                                    {t(
                                                        'common:title.organisers.main'
                                                    )}
                                                </Typography>
                                                <Typography>
                                                    {t(
                                                        'common:description.organisers.main'
                                                    )}
                                                </Typography>
                                                <Button
                                                    color={'secondary'}
                                                    onClick={() => {
                                                        this.props.history.push(
                                                            getUrl(
                                                                urls.ADMIN_EVENT_ADD
                                                            )
                                                        );
                                                    }}
                                                >
                                                    {t(
                                                        'common:button.create_event'
                                                    )}
                                                </Button>
                                            </Animated>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Animated
                                                animationIn="fadeIn"
                                                animationOut="fadeOut"
                                                isVisible={inView}
                                            >
                                                <div ref={ref}>
                                                    <img
                                                        src={ImgAdmin}
                                                        alt={t(
                                                            'common:img.alt.organisers.admin'
                                                        )}
                                                        width={'100%'}
                                                    />
                                                </div>
                                            </Animated>
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        </InView>
                    </Container>

                    <USPList items={this.state.uspList} />

                    <div
                        className={classNames(
                            classes.contentSmall,
                            'content-blue'
                        )}
                    >
                        <Container>
                            <InView>
                                {({ inView, ref }) => {
                                    return (
                                        <Animated
                                            animationIn="fadeIn"
                                            animationOut="fadeOut"
                                            isVisible={inView}
                                        >
                                            <Grid
                                                ref={ref}
                                                container
                                                spacing={2}
                                                justify={'center'}
                                            >
                                                <Grid item sm={3} xs={12}>
                                                    <Typography variant={'h2'}>
                                                        {t(
                                                            'common:title.organisers.tickets'
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item sm={6} xs={12}>
                                                    <Typography>
                                                        {t(
                                                            'common:description.organisers.tickets'
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Animated>
                                    );
                                }}
                            </InView>
                        </Container>
                    </div>

                    <div className="spacer" />
                    <div className="spacer" />

                    <div className={classNames(classes.header, classes.center)}>
                        <Container>
                            <Grid
                                container
                                spacing={3}
                                alignItems={'center'}
                                justify={'center'}
                            >
                                <Grid item xs={12} sm={7}>
                                    <Typography variant={'h2'}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    'common:title.organisers.easy_online_payment',
                                                    {
                                                        interpolation: {
                                                            escapeValue: false,
                                                        },
                                                    }
                                                ),
                                            }}
                                        />
                                    </Typography>
                                    <Typography>
                                        {t(
                                            'common:description.organisers.easy_online_payment'
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <InView>
                                        {({ inView, ref }) => {
                                            return (
                                                <Animated
                                                    animationIn="fadeInUp"
                                                    animationOut="fadeOut"
                                                    isVisible={inView}
                                                >
                                                    <ul
                                                        ref={ref}
                                                        className={
                                                            'payment-methods'
                                                        }
                                                    >
                                                        <li>
                                                            <span>
                                                                <img
                                                                    src={
                                                                        IconBancontact
                                                                    }
                                                                    alt="Bancontact"
                                                                />
                                                                <strong>
                                                                    Bancontact
                                                                </strong>
                                                            </span>
                                                            <small>
                                                                {t(
                                                                    'common:payment_methods.bancontact'
                                                                )}
                                                            </small>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <img
                                                                    src={
                                                                        IconBelfius
                                                                    }
                                                                    alt="Belfius"
                                                                />
                                                                <strong>
                                                                    Belfius
                                                                </strong>
                                                            </span>
                                                            <small>
                                                                {t(
                                                                    'common:payment_methods.belfius'
                                                                )}
                                                            </small>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <img
                                                                    src={
                                                                        IconING
                                                                    }
                                                                    alt="ING"
                                                                />
                                                                <strong>
                                                                    ING
                                                                </strong>
                                                            </span>
                                                            <small>
                                                                {t(
                                                                    'common:payment_methods.ing'
                                                                )}
                                                            </small>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <img
                                                                    src={
                                                                        IconMastercard
                                                                    }
                                                                    alt="MasterCard"
                                                                />
                                                                <strong>
                                                                    MasterCard
                                                                </strong>
                                                            </span>
                                                            <small>
                                                                {t(
                                                                    'common:payment_methods.mastercard'
                                                                )}
                                                            </small>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <img
                                                                    src={
                                                                        IconKBC
                                                                    }
                                                                    alt="KBC/CBC"
                                                                />
                                                                <strong>
                                                                    KBC/CBC
                                                                </strong>
                                                            </span>
                                                            <small>
                                                                {t(
                                                                    'common:payment_methods.kbc'
                                                                )}
                                                            </small>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <img
                                                                    src={
                                                                        IconVisa
                                                                    }
                                                                    alt="VISA"
                                                                />
                                                                <strong>
                                                                    VISA
                                                                </strong>
                                                            </span>
                                                            <small>
                                                                {t(
                                                                    'common:payment_methods.visa'
                                                                )}
                                                            </small>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                <img
                                                                    src={
                                                                        IconIDeal
                                                                    }
                                                                    alt="iDeal"
                                                                />
                                                                <strong>
                                                                    iDeal
                                                                </strong>
                                                            </span>
                                                            <small>
                                                                {t(
                                                                    'common:payment_methods.ideal'
                                                                )}
                                                            </small>
                                                        </li>
                                                    </ul>
                                                </Animated>
                                            );
                                        }}
                                    </InView>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>

                    <div
                        className={classNames(
                            classes.contentSmall,
                            classes.contentColumn
                        )}
                    >
                        <Container>
                            <Grid container spacing={3} justify={'center'}>
                                <Grid item sm={3} xs={12}>
                                    <Typography variant={'h3'}>
                                        {t(
                                            'common:title.organisers.transaction_costs'
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <Typography>
                                        {t(
                                            'common:description.organisers.transaction_costs'
                                        )}
                                    </Typography>
                                    <Button
                                        variant={'text'}
                                        onClick={this.handleViewPricing}
                                    >
                                        {t('common:button.view_prices')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>

                    <div
                        className={classNames(
                            'content-green',
                            classes.center,
                            classes.quote
                        )}
                    >
                        <Container>
                            <Grid
                                container
                                spacing={3}
                                alignItems={'center'}
                                justify={'center'}
                            >
                                <Grid item xs={12} sm={8}>
                                    <Typography variant={'h2'}>
                                        <img
                                            src={IconQuoteStart}
                                            alt=""
                                            className={'start'}
                                        />
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    'common:title.organisers.quote',
                                                    {
                                                        interpolation: {
                                                            escapeValue: false,
                                                        },
                                                    }
                                                ),
                                            }}
                                        />
                                        <img
                                            src={IconQuoteEnd}
                                            alt=""
                                            className={'end'}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>

                    <div
                        className={classNames(
                            classes.content,
                            classes.header,
                            classes.center,
                            classes.functionalities
                        )}
                    >
                        <Container>
                            <Grid container spacing={3} justify={'center'}>
                                <Grid item sm={8} xs={12}>
                                    <Typography variant={'h2'}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    'common:title.organisers.functionalities',
                                                    {
                                                        interpolation: {
                                                            escapeValue: false,
                                                        },
                                                    }
                                                ),
                                            }}
                                        />
                                    </Typography>
                                    <Typography>
                                        {t(
                                            'common:description.organisers.functionalities'
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                        <USPList
                            items={this.state.uspFunctionalities}
                            disableMargin
                        />
                    </div>

                    <div
                        className={classNames(
                            'content-yellow',
                            classes.content,
                            classes.header
                        )}
                    >
                        <Container>
                            <Grid
                                container
                                spacing={3}
                                alignItems={'center'}
                                justify={'center'}
                            >
                                <Grid item xs={12} sm={6}>
                                    <Typography variant={'h2'}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: t(
                                                    'common:title.organisers.free',
                                                    {
                                                        interpolation: {
                                                            escapeValue: false,
                                                        },
                                                    }
                                                ),
                                            }}
                                        />
                                    </Typography>
                                    <Typography>
                                        {t(
                                            'common:description.organisers.free'
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <img
                                        src={ImgFree}
                                        alt={t(
                                            'common:img.alt.organisers.free'
                                        )}
                                        width={'60%'}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                    </div>

                    <div className={classes.content}>
                        <USPImageBlock
                            title={t(
                                'common:title.organisers.three_simple_steps',
                                { interpolation: { escapeValue: false } }
                            )}
                            items={this.state.subscribeUSPList}
                        />
                    </div>

                    <div
                        className={classNames(
                            classes.contentSmall,
                            classes.center
                        )}
                    >
                        <Container>
                            <SupportIcon fontSize={'large'} />

                            <Typography variant={'h2'}>
                                {t('common:title.home.help')}
                            </Typography>
                            <Typography>
                                {t('common:description.home.help')}
                            </Typography>

                            <Button variant={'text'}>
                                {t('common:button.help')}
                            </Button>
                        </Container>
                    </div>
                </TemplateMarketing>
            </Suspense>
        );
    }
}

OrganisersPage.propTypes = {
    // props
    classes: PropTypes.object.isRequired,
    // event handlers
    t: PropTypes.func.isRequired,
    history: PropTypes.func.isRequired,
};

const OrganisersPageTranslated = withTranslation()(OrganisersPage);
const OrganisersPageStyled = withStyles(useStyles)(OrganisersPageTranslated);
const OrganisersPageRouter = withRouter(OrganisersPageStyled);
export { OrganisersPageRouter as OrganisersPage };
