import {
    PROFILE_LOADING,
    PROFILE_SUCCESS,
    PROFILE_FAILED,
} from '../actions/actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    profile: null,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PROFILE_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case PROFILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                profile: action.payload,
            };
        case PROFILE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
