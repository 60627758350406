import React, { Component } from "react";
import { withTranslation, Trans } from "react-i18next";
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';

import {
    Card,
    CardHeader,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Typography,
} from '@material-ui/core';
import {
    Skeleton,
} from '@material-ui/lab';
import { DialogProfile, Toast } from '..';
import {
    getUser,
    updateUser,
    updateEmail,
    updatePassword,
} from '../../actions/user';
import {
    getProfileThroughUser,
    updateProfile,
} from '../../actions/profile';

const useStyles = (theme) => ({
    title: {
        marginBottom: '32px',
    },
    subtitle: {
        marginBottom: '24px',
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
    },
    list: {
        fontSize: '1rem',
        marginBottom: '40px'
    },
    listText: {
        fontSize: '1rem',
    },
    listItem: {
        paddingLeft: '32px',
        paddingRight: '32px',

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        }
    },
    card: {
        borderRadius: '5px',
        padding: 0,
        marginBottom: '40px',

        '& .MuiList-padding': {
            padding: 0,
        }
    },
    cardHeader: {
        padding: '32px 32px 16px 32px',
    },
    label: {
        width: '25%',

        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: '12px 0 0',
            width: '100%',
        }
    },
    value: {
        width: '65%',

        [theme.breakpoints.down('sm')]: {
            margin: 0,
            padding: '0 0 8px',
            width: '100%',
        }
    },
    action: {
        [theme.breakpoints.down('sm')]: {
            padding: '0 0 12px',
            width: '100%',
        }
    }
});

class Profile extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            dialogOpen: false,
            data: null,
            action: null,
        };

        // load the user and profile
        this.props.getUser();

        // event handlers
        this.handleChangeDialog = this.handleChangeDialog.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeGender = this.handleChangeGender.bind(this);
        this.handleChangeDateOfBirth = this.handleChangeDateOfBirth.bind(this);
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            if (!this.props.profile) {
                this.props.getProfileThroughUser(this.props.user.id);
            }
        }

        if (prevProps.isUpdating !== this.props.isUpdating && !this.props.isUpdating) {
            if (this.props.isUpdated) {
                this.handleDialogClose();
            }
        }
    }

    convertDate(date) {
        if (!date) {
            return;
        }

        const convertedDate = moment(date, "YYYY-DD-MM");

        if (convertedDate.isValid()) {
            return convertedDate.format('LL');
        }

        return date;
    }

    handleChangeDialog({ data, action }) {
        this.setState({
            dialogOpen: true,
            data: data,
            action: action,
        });
    }

    handleDialogClose() {
        this.setState({
            dialogOpen: false,
            data: null,
            action: null,
        });
    }

    handleChangeEmail() {
        const { t } = this.props;

        this.handleChangeDialog({
            data: {
                email: {
                    label: t('forms:label.email_address'),
                    required: true,
                    value: this.props.user.email,
                    type: 'email',
                },
                password: {
                    label: t('forms:label.password'),
                    required: true,
                    value: undefined,
                    type: 'password',
                }
            },
            action: (data) => {
                this.props.updateEmail({
                    email: data.email,
                    password: data.password
                });
            }
        });
    }

    handleChangePassword() {
        const { t } = this.props;

        this.handleChangeDialog({
            data: {
                current_password: {
                    label: t('forms:label.current_password'),
                    required: true,
                    type: 'password',
                },
                new_password: {
                    label: t('forms:label.new_password'),
                    required: true,
                    type: 'password',
                },
                re_new_password: {
                    label: t('forms:label.re_new_password'),
                    required: true,
                    type: 'password',
                }
            },
            action: (data) => {
                this.props.updatePassword(data);
            }
        });
    }

    handleChangeName() {
        const { t } = this.props;

        this.handleChangeDialog({
            data: {
                first_name: {
                    label: t('forms:label.first_name'),
                    required: true,
                    value: this.props.user.first_name,
                    type: 'text',
                },
                last_name: {
                    label: t('forms:label.last_name'),
                    required: true,
                    value: this.props.user.last_name,
                    type: 'text',
                }
            },
            action: (data) => {
                this.props.updateUser(this.props.user.id, data);
            }
        });
    }

    handleChangeGender() {
        const { t } = this.props;

        this.handleChangeDialog({
            data: {
                gender: {
                    label: t('forms:label.gender'),
                    required: true,
                    value: this.props.profile.gender,
                    type: 'select',
                    options: (
                        [
                            <MenuItem key={'male'} value="male">{t('forms:gender.male')}</MenuItem>,
                            <MenuItem key={'female'} value="female">{t('forms:gender.female')}</MenuItem>,
                            <MenuItem key={'x'} value="x">{t('forms:gender.neutral')}</MenuItem>
                        ]
                    )
                }
            },
            action: (data) => {
                this.props.updateProfile(this.props.profile.id, data);
            }
        });
    }

    handleChangeDateOfBirth() {
        const { t } = this.props;

        let convertedDate = this.props.profile.date_of_birth;
        if (convertedDate) {
            convertedDate = moment(convertedDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
        }

        this.handleChangeDialog({
            data: {
                date_of_birth: {
                    label: t('forms:label.date_of_birth'),
                    required: true,
                    value: convertedDate,
                    type: 'date',
                }
            },
            action: (data) => {
                this.props.updateProfile(this.props.profile.id, data);
            }
        });
    }

    handleChangeCountry() {
        const { t } = this.props;

        this.handleChangeDialog({
            data: {
                country: {
                    label: t('forms:label.country'),
                    required: true,
                    value: this.props.profile.country.toUpperCase(),
                    type: 'country',
                }
            },
            action: (data) => {
                this.props.updateProfile(this.props.profile.id, data);
            }
        });
    }

    renderValue(isLoading, obj, key, showLoader = true) {
        if (isLoading) {
            if (showLoader) {
                return (
                    <Skeleton
                        height={22}
                        width={'40%'}
                        style={{ display: 'inline-block' }}
                    />
                );
            }
        }

        if (!obj) {
            return;
        }

        return obj[key];
    }

    render() {
        const { t, isLoading, classes } = this.props;

        const email = this.props.user && this.props.user.change_email;

        return (
            <React.Fragment>
                <Toast
                    message={t('common:text.is_updated')}
                    open={this.props.isUpdatedUser || this.props.isUpdatedProfile || this.props.isUpdatedPassword}
                    type={'success'}
                />

                <Typography variant="h2" className={classes.title}>
                    {t('common:title.profile')}
                </Typography>

                <Card className={classes.card}>
                    <CardHeader
                        title={t('common:title.login_information')}
                        subheader={t('common:subtitle.login_information')}
                        className={classes.cardHeader}
                    />
                    <List>
                        <ListItem
                            divider
                            button
                            className={classes.listItem}
                            onClick={this.handleChangeEmail}
                        >
                            <ListItemText className={classNames(classes.listText, classes.label)}>
                                <strong>{t('forms:label.email_address')}</strong>
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.value)}>
                                {this.renderValue(isLoading, this.props.user, 'email')}
                                {
                                    this.props.user && this.props.user.change_email_requested && (
                                        <small>
                                            <br/>
                                            <Trans i18nKey="text.change_email_requested_info" ns="common">
                                                A change of your email address to <strong>{{ email }}</strong> is requested. Please check this email address to confirm the change.
                                            </Trans>
                                        </small>
                                    )
                                }
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.action)}>
                                { !isLoading && <Link>{t('common:button.change')}</Link> }
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            divider
                            button
                            className={classes.listItem}
                            onClick={this.handleChangePassword}
                        >
                            <ListItemText className={classNames(classes.listText, classes.label)}>
                                <strong>{t('forms:label.password')}</strong>
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.value)}>
                                &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.action)}>
                                { !isLoading && <Link>{t('common:button.change')}</Link> }
                            </ListItemText>
                        </ListItem>
                    </List>
                </Card>

                <Card className={classes.card}>
                    <CardHeader
                        title={t('common:title.personal')}
                        subheader={t('common:subtitle.personal')}
                        className={classes.cardHeader}
                    />
                    <List>
                        <ListItem
                            divider
                            button
                            className={classes.listItem}
                            onClick={this.handleChangeName}
                        >
                            <ListItemText className={classNames(classes.listText, classes.label)}>
                                <strong>{t('forms:label.name')}</strong>
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.value)}>
                                {this.renderValue(isLoading, this.props.user, 'first_name')} {this.renderValue(isLoading, this.props.user, 'last_name', false)}
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.action)}>
                                { !isLoading && <Link>{t('common:button.change')}</Link> }
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            divider
                            button
                            className={classes.listItem}
                            onClick={this.handleChangeGender}
                        >
                            <ListItemText className={classNames(classes.listText, classes.label)}>
                                <strong>{t('forms:label.gender')}</strong>
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.value)}>
                                {this.renderValue(isLoading, this.props.profile, 'gender_description')}
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.action)}>
                                { !isLoading && <Link>{t('common:button.change')}</Link> }
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            divider
                            button
                            className={classes.listItem}
                            onClick={this.handleChangeDateOfBirth}
                        >
                            <ListItemText className={classNames(classes.listText, classes.label)}>
                                <strong>{t('forms:label.date_of_birth')}</strong>
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.value)}>
                                {this.convertDate(this.renderValue(isLoading, this.props.profile, 'date_of_birth'))}
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.action)}>
                                { !isLoading && <Link>{t('common:button.change')}</Link> }
                            </ListItemText>
                        </ListItem>
                        <ListItem
                            divider
                            button
                            className={classes.listItem}
                            onClick={this.handleChangeCountry}
                        >
                            <ListItemText className={classNames(classes.listText, classes.label)}>
                                <strong>{t('forms:label.country')}</strong>
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.value)}>
                                {this.renderValue(isLoading, this.props.profile, 'country_description')}
                            </ListItemText>
                            <ListItemText className={classNames(classes.listText, classes.action)}>
                                { !isLoading && <Link>{t('common:button.change')}</Link> }
                            </ListItemText>
                        </ListItem>
                    </List>
                </Card>

                <DialogProfile
                    open={this.state.dialogOpen}
                    onClose={this.handleDialogClose}
                    data={this.state.data}
                    action={this.state.action}
                    isLoading={this.props.isUpdating}
                    errors={this.props.formErrors}
                />

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoadingUser,
        errorUser,
        isUpdatingUser,
        isUpdatedUser,
        user,
        isLoadingProfile,
        isUpdatingProfile,
        isUpdatedProfile,
        errorProfile,
        profile,
        isUpdatingPassword,
        isUpdatedPassword,
        errorPassword,
    } = state.componentProfile;

    return {
        isLoading: isLoadingUser || isLoadingProfile,
        isLoadingUser,
        isLoadingProfile,
        isUpdatedProfile,
        isUpdating: isUpdatingUser || isUpdatingProfile || isUpdatingPassword,
        isUpdatedUser,
        isUpdatingUser,
        isUpdated: isUpdatedUser || isUpdatedProfile || isUpdatedPassword,
        isUpdatedPassword,
        isUpdatingPassword,
        errorUser,
        errorProfile,
        errorPassword,
        formErrors: Object.assign({}, errorUser, errorProfile, errorPassword),
        user,
        profile,
    };
};

const ProfileState = connect(
    mapStateToProps,
    {
        getUser,
        getProfileThroughUser,
        updateUser,
        updateEmail,
        updateProfile,
        updatePassword,
    }
)(Profile);
const ProfileTranslated = withTranslation()(ProfileState);
const ProfileStyled = withStyles(useStyles)(ProfileTranslated);

export { ProfileStyled as Profile };
