import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';

import { ListItemIcon, MenuItem } from '@material-ui/core';

const useStyles = (theme) => ({
    destroy: {
        color: '#da3030',
    },
});

class PopupMenuItem extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handleAction = this.handleAction.bind(this);
    }

    handleAction(event) {
        const { action } = this.props;

        return action(event);
    }

    renderContent() {
        const { icon, label, type, classes, isLoading } = this.props;

        if (isLoading) {
            return <CircularProgress size={20} />;
        }

        const Icon = icon;

        return (
            <React.Fragment>
                {icon && (
                    <ListItemIcon
                        className={
                            type && type === 'destroy' && classes.destroy
                        }
                    >
                        <Icon />
                    </ListItemIcon>
                )}
                {label}
            </React.Fragment>
        );
    }

    render() {
        const { type, classes } = this.props;

        return (
            <MenuItem
                onClick={this.handleAction}
                className={type && type === 'destroy' && classes.destroy}
            >
                {this.renderContent()}
            </MenuItem>
        );
    }
}

PopupMenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['destroy', undefined]),
    action: PropTypes.func.isRequired,
    icon: PropTypes.object,
    isLoading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
};

const PopupMenuItemStyled = withStyles(useStyles)(PopupMenuItem);
export { PopupMenuItemStyled as PopupMenuItem };
