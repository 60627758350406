import {
    USER_LOADING,
    USER_SUCCESS,
    USER_FAILED,

    USER_UPDATING_LOADING,
    USER_UPDATING_SUCCESS,
    USER_UPDATING_FAILED,

    USER_EMAIL_UPDATING_LOADING,
    USER_EMAIL_UPDATING_SUCCESS,
    USER_EMAIL_UPDATING_FAILED,

    USER_PASSWORD_UPDATING_LOADING,
    USER_PASSWORD_UPDATING_SUCCESS,
    USER_PASSWORD_UPDATING_FAILED,

    PROFILE_LOADING,
    PROFILE_SUCCESS,
    PROFILE_FAILED,

    PROFILE_UPDATING_LOADING,
    PROFILE_UPDATING_SUCCESS,
    PROFILE_UPDATING_FAILED,
} from '../../actions/actionTypes';

const INITIAL_STATE = {
    isLoadingUser: false,
    errorUser: null,
    user: null,
    isUpdatingUser: false,
    isUpdatedUser: false,

    errorPassword: null,
    isUpdatingPassword: false,
    isUpdatedPassword: false,

    isLoadingProfile: false,
    errorProfile: null,
    profile: null,
    isUpdatingProfile: false,
    isUpdatedProfile: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoadingUser: true,
            };
        case USER_SUCCESS:
            return {
                ...state,
                isLoadingUser: false,
                user: action.payload,
            };
        case USER_FAILED:
            return {
                ...state,
                isLoadingUser: false,
                errorUser: action.payload,
            };

        case USER_UPDATING_LOADING:
            return {
                ...state,
                errorUser: null,
                isUpdatingUser: true,
                isUpdatedUser: false,
                // reset
                isUpdatedProfile: false,
                isUpdatedPassword: false,
            };
        case USER_UPDATING_SUCCESS:
            return {
                ...state,
                isUpdatingUser: false,
                isUpdatedUser: true,
                user: action.payload,
            };
        case USER_UPDATING_FAILED:
            return {
                ...state,
                errorUser: action.payload,
                isUpdatingUser: false,
                isUpdatedUser: false,
            };

        case USER_PASSWORD_UPDATING_LOADING:
            return {
                ...state,
                errorPassword: null,
                isUpdatingPassword: true,
                isUpdatedPassword: false,
                // reset
                isUpdatedProfile: false,
                isUpdatedUser: false,
            };
        case USER_PASSWORD_UPDATING_SUCCESS:
            return {
                ...state,
                isUpdatingPassword: false,
                isUpdatedPassword: true,
            };
        case USER_PASSWORD_UPDATING_FAILED:
            return {
                ...state,
                isUpdatingPassword: false,
                isUpdatedPassword: false,
                errorPassword: action.payload,
            };

        case USER_EMAIL_UPDATING_LOADING:
            return {
                ...state,
                errorUser: null,
                isUpdatingUser: true,
                isUpdatedUser: false,
                // reset
                isUpdatedProfile: false,
                isUpdatedPassword: false,
            };
        case USER_EMAIL_UPDATING_SUCCESS:
            return {
                ...state,
                isUpdatingUser: false,
                isUpdatedUser: true,
                user: action.payload,
            };
        case USER_EMAIL_UPDATING_FAILED:
            return {
                ...state,
                errorUser: action.payload,
                isUpdatingUser: false,
                isUpdatedUser: false,
            };

        case PROFILE_LOADING:
            return {
                ...state,
                isLoadingProfile: true,
            };
        case PROFILE_SUCCESS:
            return {
                ...state,
                isLoadingProfile: false,
                profile: action.payload.results[0],
            };
        case PROFILE_FAILED:
            return {
                ...state,
                isLoadingProfile: false,
                errorProfile: action.payload,
            };

        case PROFILE_UPDATING_LOADING:
            return {
                ...state,
                errorProfile: null,
                isUpdatingProfile: true,
                isUpdatedProfile: false,
                // reset
                isUpdatedUser: false,
                isUpdatedPassword: false,
            };
        case PROFILE_UPDATING_SUCCESS:
            return {
                ...state,
                isUpdatingProfile: false,
                isUpdatedProfile: true,
                profile: action.payload,
            };
        case PROFILE_UPDATING_FAILED:
            return {
                ...state,
                errorProfile: action.payload,
                isUpdatingProfile: false,
                isUpdatedProfile: false,
            };

        default:
            return state;
    }
};
