import {
    ADMIN_COURSE_ADD_LOADING,
    ADMIN_COURSE_ADD_SUCCESS,
    ADMIN_COURSE_ADD_FAILED,
    ADMIN_COURSE_LOADING,
    ADMIN_COURSE_LOADING_SUCCESS,
    ADMIN_COURSE_LOADING_FAILED,
    ADMIN_COURSE_EDIT_LOADING,
    ADMIN_COURSE_EDIT_SUCCESS,
    ADMIN_COURSE_EDIT_FAILED,
    ADMIN_COURSE_TYPES_LOADING,
    ADMIN_COURSE_TYPES_SUCCESS,
    ADMIN_COURSE_TYPES_FAILED,
    ADMIN_COURSE_RESET,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    isAdding: false,
    isEditing: false,
    formErrors: null,
    newCourse: null,
    course: null,
    error: null,
    errorTypes: null,
    isLoadingTypes: false,
    types: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_COURSE_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ADMIN_COURSE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                course: action.payload,
            };
        case ADMIN_COURSE_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case ADMIN_COURSE_EDIT_LOADING:
            return {
                ...state,
                isEditing: true,
            };
        case ADMIN_COURSE_EDIT_SUCCESS:
            return {
                ...state,
                // isEditing: false,  // keep loading for redirect
                newCourse: action.payload,
            };
        case ADMIN_COURSE_EDIT_FAILED:
            return {
                ...state,
                isEditing: false,
                formErrors: action.payload,
            };

        case ADMIN_COURSE_ADD_LOADING:
            return {
                ...state,
                isAdding: true,
            };
        case ADMIN_COURSE_ADD_SUCCESS:
            return {
                ...state,
                // isAdding: false,  // keep loading for redirect
                newCourse: action.payload,
            };
        case ADMIN_COURSE_ADD_FAILED:
            return {
                ...state,
                isAdding: false,
                formErrors: action.payload,
            };

        case ADMIN_COURSE_TYPES_LOADING:
            return {
                ...state,
                isLoadingTypes: true,
            };
        case ADMIN_COURSE_TYPES_SUCCESS:
            return {
                ...state,
                isLoadingTypes: false,
                types: action.payload,
            };
        case ADMIN_COURSE_TYPES_FAILED:
            return {
                ...state,
                isLoadingTypes: false,
                errorTypes: action.payload,
            };

        case ADMIN_COURSE_RESET:
            return {
                ...state,
                ...INITIAL_STATE,
            };

        default:
            return state;
    }
};
