import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import history from 'services/history';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { listOrders } from './actions';

import { CardOrder, EmptyMessage } from '..';
import { scrollIntoView } from '../../utils';

const useStyles = (theme) => ({
    title: {
        marginBottom: '32px',
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
    },
});

class Orders extends Component {
    constructor(props) {
        super(props);

        // check if there's a page
        const query = new URLSearchParams(window.location.search.slice(1));
        let page = query.get('page');
        let order = query.get('order');

        if (!page || page <= 0) {
            page = 1;
        }

        // save to state
        this.state = {
            openOrderId: order,
        };

        // load the orders
        this.props.listOrders(page);

        // event handlers
        this.handlePage = this.handlePage.bind(this);
    }

    handlePage(object, page) {
        // load next page
        this.props.listOrders(page);

        scrollIntoView('#top-orders');
        history.push(window.location.pathname + '?page=' + page);
    }

    renderEmpty() {
        const { t } = this.props;

        return (
            <EmptyMessage
                title={t('common:title.no_orders')}
                description={t('common:text.no_orders')}
            />
        );
    }

    renderLoader() {
        const { t, classes } = this.props;

        return (
            <React.Fragment>
                <div id="top-orders" />
                <Typography variant="h2" className={classes.title}>
                    {t('common:title.orders')}
                </Typography>

                {[...new Array(10)].map((key) => (
                    <CardOrder key={key} isLoading />
                ))}
            </React.Fragment>
        );
    }

    renderPagination() {
        const { classes, totalPages, currentPage } = this.props;

        if (totalPages <= 0) {
            return;
        }

        return (
            <Grid item xs={12}>
                <div className={classes.flex}>
                    <Pagination
                        count={totalPages}
                        shape="rounded"
                        page={currentPage}
                        onChange={this.handlePage}
                    />
                </div>
            </Grid>
        );
    }

    render() {
        const { t, orders, isLoading, classes } = this.props;

        if (isLoading) {
            return this.renderLoader();
        }

        if (!orders || orders.length === 0) {
            return this.renderEmpty();
        }

        return (
            <React.Fragment>
                <div id="top-orders" />
                <Typography variant="h2" className={classes.title}>
                    {t('common:title.orders')}
                </Typography>

                {orders.map((obj, i) => {
                    return (
                        <CardOrder
                            order={obj}
                            key={i}
                            openDialog={`${obj.id}` === this.state.openOrderId}
                        />
                    );
                })}

                {this.renderPagination()}
            </React.Fragment>
        );
    }
}

Orders.propTypes = {
    // props
    classes: PropTypes.object.isRequired,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    totalOrders: PropTypes.number,
    isLoading: PropTypes.bool,
    orders: PropTypes.array,
    // handlers
    listOrders: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        error,
        orders,
        totalOrders,
        totalPages,
        currentPage,
    } = state.componentOrders;

    return {
        isLoading,
        error,
        orders,
        totalOrders,
        totalPages,
        currentPage,
    };
};

const OrdersState = connect(mapStateToProps, {
    listOrders,
})(Orders);
const OrdersTranslated = withTranslation()(OrdersState);
const OrdersStyled = withStyles(useStyles)(OrdersTranslated);

export { OrdersStyled as Orders };
