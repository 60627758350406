import _ from 'lodash';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Snackbar, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ALERT_TYPE } from '../../enums/alert';
import { hideAlert } from './actions';

const useStyles = (theme) => ({
    alert: {
        boxShadow: '0 0 10px rgba(0, 0, 0, .1)',
    },
});

// function TransitionLeft(props) {
//     return <Slide {...props} direction="left" />;
// }

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}

function TransitionRight(props) {
    return <Slide {...props} direction="right" />;
}

class AlertContainer extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose({ key }) {
        this.props.hideAlert({ key });
    }

    render() {
        const { alerts, classes } = this.props;

        return _.map(alerts, (value) => {
            return (
                <Snackbar
                    open
                    key={value.key}
                    onClose={() => this.handleClose({ key: value.key })}
                    TransitionComponent={
                        value.type === 'info' ? TransitionRight : TransitionDown
                    }
                    anchorOrigin={
                        value.type === 'info'
                            ? { horizontal: 'left', vertical: 'bottom' }
                            : { horizontal: 'center', vertical: 'top' }
                    }
                    autoHideDuration={
                        value.type === ALERT_TYPE.ERROR ? null : 5000
                    }
                >
                    <Alert
                        severity={value.type}
                        onClose={this.handleClose}
                        className={classes.alert}
                    >
                        {value.description}
                    </Alert>
                </Snackbar>
            );
        });
    }
}

AlertContainer.propTypes = {
    // props
    alerts: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    // handlers
    hideAlert: PropTypes.func.isRequired,
};

// AlertContainer.defaultProps = {
//     type: ALERT_TYPE.ERORR,
// };

const mapStateToProps = (state) => {
    const { alerts } = state.componentAlert;

    return {
        alerts,
    };
};

const AlertContainerState = connect(mapStateToProps, {
    hideAlert,
})(AlertContainer);
const AlertContainerStyled = withStyles(useStyles)(AlertContainerState);

export { AlertContainerStyled as AlertContainer };
