import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';

import { Grid } from '@material-ui/core';
import { Button, RadioGroup } from 'components';

import iconBancontact from 'assets/images/icons/payment/bancontact.svg';
import iconING from 'assets/images/icons/payment/ing.svg';
import iconVISA from 'assets/images/icons/payment/visa.svg';
import iconMastercard from 'assets/images/icons/payment/mastercard.svg';
import iconBelfius from 'assets/images/icons/payment/belfius.svg';
import iconKBC from 'assets/images/icons/payment/kbc.svg';
import iconiDeal from 'assets/images/icons/payment/ideal.svg';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: '24px',
    },
    cancel: {
        marginTop: '10px',
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '16px',
    },
}));

function PaymentMethodForm(props) {
    const { t } = useTranslation();
    const classes = useStyles();

    const { handleSubmit, register, control } = useForm();

    function getPaymentIcons() {
        return (
            <React.Fragment>
                <ul className={'payment-icons'}>
                    <li>
                        <img src={iconBancontact} alt="Bancontact" />
                    </li>
                    <li>
                        <img src={iconING} alt="ING" />
                    </li>
                    <li>
                        <img src={iconBelfius} alt="Belfius" />
                    </li>
                    <li>
                        <img src={iconKBC} alt="KBC" />
                    </li>
                    <li>
                        <img src={iconiDeal} alt="iDeal" />
                    </li>
                    <li>
                        <img src={iconVISA} alt="VISA" />
                    </li>
                    <li>
                        <img src={iconMastercard} alt="MasterCard" />
                    </li>
                </ul>
            </React.Fragment>
        );
    }

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Grid
                container
                spacing={3}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <Grid item xs={12}>
                    <RadioGroup
                        options={[
                            {
                                value: 'online',
                                label: t('forms:label.online'),
                                help_text: getPaymentIcons(),
                            },
                            { value: 'cash', label: t('forms:label.cash') },
                            { value: 'user', label: t('forms:label.user') },
                            {
                                value: 'not_paid',
                                label: t('forms:label.mark_as_not_paid'),
                            },
                        ]}
                        name="method"
                        error={props.errors && props.errors['method']}
                        required
                        control={control}
                        label={t('forms:label.payment_method')}
                        ref={register({ required: true })}
                    />
                </Grid>

                <Grid item xs={12} className={classes.actions}>
                    <Button
                        color={'secondary'}
                        isLoading={props.isLoading}
                        type={'submit'}
                    >
                        {t('forms:button.to_payment')}
                    </Button>
                    <Button
                        variant={'text'}
                        className={classes.cancel}
                        onClick={props.onClose}
                    >
                        {t('forms:button.cancel')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

PaymentMethodForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    errors: PropTypes.object,
    isLoading: PropTypes.bool,
};

export { PaymentMethodForm };
