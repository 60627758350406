import {
    EVENTS_LOADING,
    EVENTS_LOADING_SUCCESS,
    EVENTS_LOADING_FAILED,
    EVENT_RESET_CHANGED,
    EVENT_RESET_CREATED,
    EVENT_RESET_DELETED,
    EVENTS_ACTIONS_LOADING,
    EVENTS_ACTIONS_SUCCESS,
    EVENTS_ACTIONS_FAILED,
} from './actionTypes';

import {
    EVENT_ADD_SUCCESS,
    EVENT_EDIT_SUCCESS,
} from '../AdminEventAddEdit/actionTypes';

import { ADMIN_EVENT_DELETE_SUCCESS } from 'components/Popups/PopupMenuAdminEvent/actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    events: [],
    totalEvents: 0,
    totalPages: 0,
    currentPage: 1,
    isCreated: false,
    isChanged: false,
    isDeleted: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EVENT_RESET_CHANGED:
            return {
                ...state,
                isChanged: false,
            };
        case EVENT_RESET_CREATED:
            return {
                ...state,
                isCreated: false,
            };
        case EVENT_RESET_DELETED:
            return {
                ...state,
                isDeleted: false,
            };

        case EVENT_ADD_SUCCESS:
            return {
                ...state,
                isCreated: true,
            };
        case EVENT_EDIT_SUCCESS:
            return {
                ...state,
                isChanged: true,
            };
        case ADMIN_EVENT_DELETE_SUCCESS:
            return {
                ...state,
                isDeleted: true,
            };

        case EVENTS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case EVENTS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                totalEvents: action.payload.count,
                totalPages: action.payload.total_pages,
                events: action.payload.results,
                currentPage: action.payload.current_page,
            };
        case EVENTS_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case EVENTS_ACTIONS_LOADING:
            return {
                ...state,
                isLoadingActions: true,
            };
        case EVENTS_ACTIONS_SUCCESS:
            return {
                ...state,
                isLoadingActions: false,
                actions: action.payload.actions.USER,
            };
        case EVENTS_ACTIONS_FAILED:
            return {
                ...state,
                isLoadingActions: false,
                errorActions: action.payload,
            };

        default:
            return state;
    }
};
