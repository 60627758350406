import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { Redirect, withRouter } from 'react-router-dom';
import { urls, getUrl } from 'urls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import EventService from 'lib/EventService';
import { EVENT } from 'enums';

import { AdminHeader, Toast, EmptyMessage, TemplateAdmin } from 'components';
import { scrollIntoView, AdminContext } from '../../utils';

import {
    getCourse,
    addCourse,
    editCourse,
    listCourseTypes,
    resetCourse,
} from './actions';
import { CourseForm } from './form';

class AdminCourseAddEditPage extends Component {
    static contextType = AdminContext; // eslint-disable-line react/sort-comp

    constructor(props) {
        super(props);

        const { courseId, eventId } = this.props.match.params;
        let goBackToDetail = false;

        if (this.props.location?.state) {
            goBackToDetail = this.props.location.state.goBackToDetail;
        }

        // set state
        this.state = {
            redirect: null,
            eventId: eventId,
            courseId: courseId,
            goBackToDetail: goBackToDetail,
        };

        // load course
        if (courseId) {
            this.props.getCourse(courseId);
        }

        // load course types
        this.props.listCourseTypes();

        // event handlers
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { t } = this.props;
        const { courseId, goBackToDetail } = this.state;

        this.context.setAdminTitle(
            courseId
                ? t('common:title.admin.course_edit')
                : t('common:title.admin.course_add')
        );
        this.context.setAdminBackAction(() => {
            if (goBackToDetail === true) {
                this.props.history.push(
                    getUrl(urls.ADMIN_EVENT_COURSE_START_LIST, {
                        eventId: this.state.eventId,
                        courseId: this.state.courseId,
                    })
                );
            } else {
                this.props.history.push(
                    getUrl(urls.ADMIN_EVENT_DETAIL, {
                        eventId: this.state.eventId,
                    })
                );
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.newCourse !== this.props.newCourse &&
            this.props.newCourse
        ) {
            // success
            this.setState({
                redirect: true,
            });

            EventService.trigger(EVENT.COURSE_CHANGED);
        }

        if (
            prevProps.formErrors !== this.props.formErrors &&
            this.props.formErrors
        ) {
            scrollIntoView('#top-form');
        }
    }

    componentWillUnmount() {
        this.props.resetCourse();
    }

    handleSubmit(data) {
        // visibility
        if (typeof data['status'] === 'boolean') {
            data['status'] = data['status'] ? 'published' : 'draft';
        }

        // start hour
        let startHour = data['start_hour'];
        if (startHour) {
            if (startHour instanceof Date) {
                startHour.setSeconds(0);
                startHour = startHour.toLocaleTimeString();
            } else {
                const splitted = startHour.split(' ');
                startHour = splitted[1];
            }
            data['start_hour'] = startHour;
        }

        // add event
        data['event'] = this.state.eventId;

        if (this.props.course) {
            this.props.editCourse({ ...data, id: this.props.course?.id });
        } else {
            this.props.addCourse(data);
        }
    }

    renderContent() {
        const { t, error, formErrors, history } = this.props;
        const { redirect, eventId, courseId } = this.state;

        return (
            <React.Fragment>
                <TemplateAdmin header>
                    {(Boolean(error) || Boolean(formErrors)) && (
                        <Toast
                            message={t('common:error.general.description')}
                            open
                        />
                    )}
                    {redirect && (
                        <React.Fragment>
                            <Redirect
                                to={getUrl(urls.ADMIN_EVENT_DETAIL, {
                                    eventId: eventId,
                                })}
                            />
                            <Toast
                                message={t(
                                    'common:success.general.description'
                                )}
                                open
                            />
                        </React.Fragment>
                    )}
                    <AdminHeader
                        title={
                            courseId
                                ? t('common:title.admin.course_edit')
                                : t('common:title.admin.course_add')
                        }
                        breadcrumbs={[
                            {
                                title: t('common:text.menu.events'),
                                url: urls.ADMIN_EVENTS,
                            },
                            {
                                title: t('common:text.menu.event'),
                                url: getUrl(urls.ADMIN_EVENT_DETAIL, {
                                    eventId: eventId,
                                }),
                            },
                            {
                                title: courseId
                                    ? t('common:title.admin.course_edit')
                                    : t('common:title.admin.course_add'),
                            },
                        ]}
                        removeMargin
                    />
                </TemplateAdmin>

                <TemplateAdmin>
                    <div id="top-form" />

                    <CourseForm
                        onSubmit={this.handleSubmit}
                        onCancel={() =>
                            history.push(
                                getUrl(urls.ADMIN_EVENT_DETAIL, {
                                    eventId: eventId,
                                })
                            )
                        }
                        errors={this.props.formErrors}
                        isLoading={this.props.isAdding || this.props.isEditing}
                        course={this.props.course}
                        types={this.props.types}
                    />
                </TemplateAdmin>
            </React.Fragment>
        );
    }

    renderLoader() {
        const { t } = this.props;

        return (
            <EmptyMessage
                title={t('common:title.load_course')}
                description={t('common:description.load_course')}
                showLoader
            />
        );
    }

    render() {
        const { isLoading, t } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <Helmet>
                    <title>
                        {this.state.courseId
                            ? t('common:title.admin.course_edit')
                            : t('common:title.admin.course_add')}{' '}
                        | {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>
                {isLoading ? this.renderLoader() : this.renderContent()}
            </Suspense>
        );
    }
}

AdminCourseAddEditPage.propTypes = {
    // props
    course: PropTypes.object,
    formErrors: PropTypes.object,
    error: PropTypes.string,
    history: PropTypes.object,
    isAdding: PropTypes.bool,
    isEditing: PropTypes.bool,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    newCourse: PropTypes.object,
    types: PropTypes.object,
    goBackToDetail: PropTypes.bool,
    location: PropTypes.object,
    // handlers
    addCourse: PropTypes.func.isRequired,
    editCourse: PropTypes.func.isRequired,
    getCourse: PropTypes.func.isRequired,
    listCourseTypes: PropTypes.func.isRequired,
    resetCourse: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isAdding,
        isEditing,
        isLoading,
        newCourse,
        course,
        formErrors,
        error,
        types,
    } = state.pageAdminCourseAddEdit;

    return {
        isAdding,
        isEditing,
        isLoading,
        newCourse,
        course,
        formErrors,
        error,
        types,
    };
};

const AdminCourseAddEditPageTranslated = withTranslation()(
    AdminCourseAddEditPage
);
const AdminCourseAddEditPageState = connect(mapStateToProps, {
    addCourse,
    getCourse,
    editCourse,
    listCourseTypes,
    resetCourse,
})(AdminCourseAddEditPageTranslated);
const AdminCourseAddEditPageRouter = withRouter(AdminCourseAddEditPageState);
export { AdminCourseAddEditPageRouter as AdminCourseAddEditPage };
