/* eslint-disable no-undef */
export const clear = () => {
    return localStorage.clear();
};

export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
};

export const setRefreshToken = (refreshToken) => {
    return localStorage.setItem('refresh_token', refreshToken);
};

export const getAuthToken = () => {
    return localStorage.getItem('auth_token');
};

export const setAuthToken = (authToken) => {
    return localStorage.setItem('auth_token', authToken);
};

export const setItem = (key, value) => {
    return localStorage.setItem(key, value);
};

export const getItem = (key, defaultValue = undefined) => {
    const value = localStorage.getItem(key);

    if (value === undefined) {
        localStorage.setItem(key, defaultValue);
        return defaultValue;
    }

    return value;
};

export const removeItem = (key) => {
    return localStorage.removeItem(key);
};

export const localStorageAvailable = () => {
    try {
        localStorage.setItem('storage', 1);
        return true;
    } catch (err) {
        return false;
    }
};
/* eslint-enable no-undef */
