import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getUrl, urls } from 'urls';
import { Helmet } from 'react-helmet';

import { TemplateMarketing } from '../TemplateMarketing';
import { EmptyMessage } from 'components';

// actions
import { logoutUser } from './actions';

class LogoutPage extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handleRedirect = this.handleRedirect.bind(this);
    }

    componentDidMount() {
        this.props.logoutUser();
    }

    handleRedirect(event) {
        event.preventDefault();
        event.stopPropagation();

        this.props.history.push(getUrl(urls.HOME));
    }

    render() {
        const { t, isLoggedOut } = this.props;

        return (
            <TemplateMarketing>
                {isLoggedOut ? (
                    <React.Fragment>
                        <Helmet>
                            <title>
                                {t('common:title.logged_out')} | Equento
                            </title>
                            <link
                                rel="canonical"
                                href={getUrl(
                                    window.location.pathname,
                                    {},
                                    true
                                )}
                            />
                        </Helmet>
                        <EmptyMessage
                            title={t('common:title.logged_out')}
                            description={t('common:description.logged_out')}
                            actionText={t('common:button.homepage')}
                            action={this.handleRedirect}
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Helmet>
                            <title>
                                {t('common:title.logging_out')} | Equento
                            </title>
                            <link
                                rel="canonical"
                                href={getUrl(
                                    window.location.pathname,
                                    {},
                                    true
                                )}
                            />
                        </Helmet>
                        <EmptyMessage
                            showLoader
                            title={t('common:title.logging_out')}
                            description={t('common:description.logging_out')}
                        />
                    </React.Fragment>
                )}
            </TemplateMarketing>
        );
    }
}

const mapStateToProps = (state) => {
    const { isLoggingOut, isLoggedOut } = state.pageLogout;
    const { isLoggedIn } = state.authentication;

    return {
        isLoggingOut,
        isLoggedOut,
        isLoggedIn,
    };
};

const LogoutPageState = connect(mapStateToProps, {
    logoutUser,
})(LogoutPage);

const LogoutPageTranslated = withTranslation()(LogoutPageState);
const LogoutPageRouter = withRouter(LogoutPageTranslated);

export { LogoutPageRouter as LogoutPage };
