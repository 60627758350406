import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import { Grid } from '@material-ui/core';
import { Dialog, DownloadFile } from 'components';
import { ExportForm } from './form';
import { exportEvent, exportCourse } from './actions';

class DialogExport extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            isExporting: false,
        };

        // event handler
        this.exportFile = this.exportFile.bind(this);
        this.startExport = this.startExport.bind(this);
    }

    startExport(data) {
        this.setState({
            isExporting: true,
            ...data,
        });
    }

    exportFile() {
        const { exportClass } = this.props;

        if (exportClass === 'course') {
            return this.props.exportCourse({
                id: this.props.course?.id,
                ...this.state,
            });
        } else {
            return this.props.exportEvent({
                id: this.props.event?.id,
                ...this.state,
            });
        }
    }

    renderFileDownload() {
        const { exportClass } = this.props;

        return (
            <DownloadFile
                onStart={() => this.setState({ isExporting: true })}
                onFinish={() => this.setState({ isExporting: false })}
                onError={() => {
                    AlertService.showError({
                        description: TranslationUtil.getTranslations().t(
                            exportClass === 'course'
                                ? 'common:error.export_course'
                                : 'common:error.export_event'
                        ),
                    });
                }}
                onSuccess={() => {
                    AlertService.showSuccess({
                        description: TranslationUtil.getTranslations().t(
                            exportClass === 'course'
                                ? 'common:success.export_course'
                                : 'common:success.export_event'
                        ),
                    });
                }}
                action={async () => this.exportFile()}
                start={this.state.isExporting}
            />
        );
    }

    render() {
        const { onClose, open, t, exportClass } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={
                    exportClass === 'course'
                        ? t('common:title.export_dialog_course')
                        : t('common:title.export_dialog_event')
                }
                subtitle={
                    exportClass === 'course'
                        ? t('common:subtitle.export_dialog_course')
                        : t('common:subtitle.export_dialog_event')
                }
                showLine
                noFooter
            >
                <Grid container>
                    <ExportForm
                        action={this.startExport}
                        defaultType={this.props.defaultType}
                        showType={this.props.showType}
                        isLoading={this.state.isExporting}
                    />
                </Grid>

                {this.renderFileDownload()}
            </Dialog>
        );
    }
}

DialogExport.propTypes = {
    // props
    open: PropTypes.bool,
    defaultType: PropTypes.oneOf(['csv', 'excel', 'receipts', 'start-list']),
    showType: PropTypes.bool,
    isExporting: PropTypes.bool,
    formErrors: PropTypes.object,
    event: PropTypes.object.isRequiredIf(
        (props) => props.exportClass === 'event'
    ),
    course: PropTypes.object.isRequiredIf(
        (props) => props.exportClass === 'course'
    ),
    exportClass: PropTypes.oneOf(['course', 'event']).isRequired,
    // handlers
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    exportEvent: PropTypes.func.isRequired,
    exportCourse: PropTypes.func.isRequired,
};

DialogExport.defaultProps = {
    open: false,
    showType: true,
};

const DialogExportTranslated = withTranslation()(DialogExport);
const DialogExportState = connect(null, {
    exportEvent,
    exportCourse,
})(DialogExportTranslated);

export { DialogExportState as DialogExport };
