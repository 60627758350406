import format from 'string-format';
import fetchApi from './api';

const publicEndPoints = {
    list: '/v1/order-payments/',
    detail: '/v1/order-payments/{id}/',
};

const adminEndPoints = {
    list: '/v1/admin/order-payments/',
    detail: '/v1/admin/order-payments/{id}/',
};

export const listOrderPayments = (page, orderId) => fetchApi({
    url: publicEndPoints.list,
    data: {
        page: page,
        order: orderId,
    },
    method: 'GET',
});

export const listAdminOrderPayments = (page, orderId) => fetchApi({
    url: adminEndPoints.list,
    data: {
        page: page,
        order: orderId,
    },
    method: 'GET',
});

export const getOrderPayment = (id) => fetchApi({
    url: format(publicEndPoints.detail, { id }),
    method: 'GET',
});

export const getAdminOrderPayment = (id) => fetchApi({
    url: format(adminEndPoints.detail, { id }),
    method: 'GET',
});
