import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import { Alert, Skeleton } from '@material-ui/lab';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    IconButton,
    TableContainer,
    Table,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { DialogInviteUser, DialogDelete, IntroText } from 'components';
import {
    EditOutlined as EditIcon,
    DeleteOutlined as DeleteIcon,
    RefreshOutlined as RefreshIcon,
} from '@material-ui/icons';

import {
    listClubUsers,
    deleteUserFromClub,
    resendUserInvitation,
} from './actions';

const useStyles = (theme) => ({
    userAvatar: {
        paddingLeft: '0',
        paddingRight: 8,
        verticalAlign: 'middle',
        width: '40px',

        [theme.breakpoints.down('xs')]: {
            border: 0,
            display: 'block',
            paddingBottom: 0,
            textAlign: 'center',
            width: 'auto',
        },
    },
    userAvatarImage: {
        borderRadius: '5px',
        height: '40px',
        margin: '0 auto',
        width: '40px',
    },
    userName: {
        paddingLeft: 8,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        verticalAlign: 'middle',
        width: '40%',

        [theme.breakpoints.down('xs')]: {
            border: 0,
            display: 'block',
            textAlign: 'center',
            width: 'auto',
        },
    },
    userRole: {
        textAlign: 'left',
        verticalAlign: 'middle',

        [theme.breakpoints.down('xs')]: {
            border: 0,
            display: 'block',
            paddingBottom: '4px',
            paddingTop: '4px',
            textAlign: 'center',
        },
    },
    userActions: {
        paddingRight: '0',
        textAlign: 'right',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',

        [theme.breakpoints.down('xs')]: {
            display: 'block',
            textAlign: 'center',
        },
    },
    userRow: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    userTable: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    loader: {
        [theme.breakpoints.down('xs')]: {
            margin: '0 auto',
        },
    },
});

class AdminClubUsers extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            dialogInviteUserOpen: false,
            dialogDeleteOpen: false,
            deleteEmail: null,
            inviteObj: null,
            snack: null,
        };

        // event handlers
        this.handleButtonAddUser = this.handleButtonAddUser.bind(this);
        this.handleDialogInviteUserClose = this.handleDialogInviteUserClose.bind(
            this
        );
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDialogDeleteClose = this.handleDialogDeleteClose.bind(this);
        this.handleDeleteInvitation = this.handleDeleteInvitation.bind(this);
        this.handleResend = this.handleResend.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.reload = this.reload.bind(this);
    }

    componentDidMount() {
        // load stuff
        this.reload();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isDeletedUser !== this.props.isDeletedUser &&
            this.props.isDeletedUser
        ) {
            this.handleDialogDeleteClose();

            AlertService.showSuccess({
                description: TranslationUtil.getTranslations().t(
                    'common:success.user.deleted'
                ),
            });

            this.reload();
        }

        if (prevProps.isResent !== this.props.isResent && this.props.isResent) {
            this.props.closeSnackbar(this.state.snack);

            AlertService.showSuccess({
                description: TranslationUtil.getTranslations().t(
                    'common:success.user.invitation_resent'
                ),
            });
        }

        if (
            prevProps.resendError !== this.props.resendError &&
            this.props.resendError
        ) {
            this.props.closeSnackbar(this.state.snack);
        }
    }

    reload() {
        if (this.props.club) {
            this.props.listClubUsers({ clubId: this.props.club?.id });
        }
    }

    handleResend(email) {
        const snack = this.props.enqueueSnackbar(
            this.props.t('common:text.user.resending_invitation')
        );

        this.setState({
            snack: snack,
        });

        this.props.resendUserInvitation({
            id: this.props.club.id,
            email: email,
        });
    }

    handleChange(obj) {
        this.setState({
            dialogInviteUserOpen: true,
            inviteObj: obj,
        });
    }

    handleButtonAddUser() {
        this.setState({
            dialogInviteUserOpen: true,
        });
    }

    handleDialogInviteUserClose(invitation) {
        this.setState({
            dialogInviteUserOpen: false,
            inviteObj: null,
        });

        if (invitation) {
            AlertService.showSuccess({
                description: TranslationUtil.getTranslations().t(
                    'common:success.user.added_invitation'
                ),
            });

            // TODO: just add the newly added user
            this.reload();
        }
    }

    handleDelete(email) {
        this.setState({
            dialogDeleteOpen: true,
            deleteEmail: email,
        });
    }

    handleDialogDeleteClose() {
        this.setState({
            dialogDeleteOpen: false,
            deleteEmail: null,
        });
    }

    handleDeleteInvitation() {
        if (this.state.deleteEmail) {
            this.props.deleteUserFromClub({
                id: this.props.club.id,
                email: this.state.deleteEmail,
            });
        }
    }

    renderLoading() {
        const { classes } = this.props;

        return _.times(2, () => (
            <TableContainer>
                <Table className={classes.userTable}>
                    <TableRow className={classes.userRow}>
                        <TableCell className={classes.userAvatar}>
                            <Skeleton
                                width={40}
                                height={40}
                                variant={'rect'}
                                className={classes.userAvatarImage}
                            />
                        </TableCell>
                        <TableCell className={classes.userName}>
                            <Skeleton
                                variant={'text'}
                                width={'90%'}
                                className={classes.loader}
                            />
                            <Skeleton
                                variant={'text'}
                                width={'70%'}
                                className={classes.loader}
                            />
                        </TableCell>
                        <TableCell className={classes.userRole}>
                            <Skeleton
                                variant={'text'}
                                className={classes.loader}
                            />
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        ));
    }

    renderEmpty() {
        const { t } = this.props;

        return (
            <TableContainer>
                <Table>
                    <TableRow>
                        <TableCell>
                            <em>{t('common:description.club.no_users')}</em>
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        );
    }

    renderError() {
        const { t } = this.props;

        return (
            <Alert severity={'error'} color={'error'}>
                {t('common:error.general.description')}
            </Alert>
        );
    }

    renderContent() {
        const { classes, isLoading, userCount, error, users, t } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        if (userCount === 0) {
            return this.renderEmpty();
        }

        if (error) {
            return this.renderError();
        }

        return _.map(users, (obj, index) => (
            <TableContainer>
                <Table className={classes.userTable}>
                    <TableRow className={classes.userRow}>
                        <TableCell className={classes.userAvatar}>
                            {obj.image_url ? (
                                <img
                                    src={obj.image_url}
                                    className={classes.userAvatarImage}
                                />
                            ) : (
                                <Avatar
                                    className={classes.userAvatarImage}
                                    variant={'rounded'}
                                >
                                    AU
                                </Avatar>
                            )}
                        </TableCell>
                        <TableCell className={classes.userName}>
                            <strong>
                                {obj.first_name} {obj.last_name}
                            </strong>
                            <br />
                            <small>{obj.email}</small>
                        </TableCell>
                        <TableCell className={classes.userRole}>
                            <span>{obj.level_display}</span>
                            {!obj.approved_at && !obj.declined_at && (
                                <React.Fragment>
                                    <br />
                                    <small>
                                        ({t('common:text.user_invited')})
                                    </small>
                                </React.Fragment>
                            )}
                        </TableCell>
                        <TableCell className={classes.userActions}>
                            {obj.actions.resend && (
                                <IconButton
                                    color="primary"
                                    title={t('common:text.invitation.resend')}
                                    onClick={() => this.handleResend(obj.email)}
                                >
                                    <RefreshIcon fontSize="small" />
                                </IconButton>
                            )}
                            {obj.actions.change && (
                                <IconButton
                                    color="primary"
                                    title={
                                        obj.approved_at
                                            ? t('common:text.user.change')
                                            : t('common:text.invitation.change')
                                    }
                                    onClick={() => this.handleChange(obj)}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )}
                            {obj.actions.delete && (
                                <IconButton
                                    style={{ color: '#da3030' }}
                                    title={
                                        obj.approved_at || obj.declined_at
                                            ? t('common:text.user.delete')
                                            : t('common:text.invitation.delete')
                                    }
                                    onClick={() => this.handleDelete(obj.email)}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            )}
                        </TableCell>
                    </TableRow>
                </Table>
            </TableContainer>
        ));
    }

    renderDialogInviteUser() {
        return (
            <DialogInviteUser
                open={this.state.dialogInviteUserOpen}
                onClose={this.handleDialogInviteUserClose}
                invitation={this.state.inviteObj}
                club={this.props.club}
            />
        );
    }

    renderDialogDelete() {
        return (
            <DialogDelete
                open={this.state.dialogDeleteOpen}
                onClose={this.handleDialogDeleteClose}
                action={this.handleDeleteInvitation}
                isDeleting={this.props.isDeletingUser}
            />
        );
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div id="top-page" />

                <Grid
                    container
                    spacing={3}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                >
                    <Grid item md={4} xs={12}>
                        <IntroText
                            title={t('common:title.users')}
                            description={t('common:description.club.users')}
                            buttonText={t('common:button.add_user')}
                            onButtonPress={this.handleButtonAddUser}
                        />
                    </Grid>

                    <Grid item md={8} xs={12}>
                        <Card>
                            <CardContent>{this.renderContent()}</CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {this.renderDialogInviteUser()}
                {this.renderDialogDelete()}
            </React.Fragment>
        );
    }
}

AdminClubUsers.propTypes = {
    // props
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    users: PropTypes.array,
    userCount: PropTypes.number,
    club: PropTypes.object.isRequired,
    isAddingUser: PropTypes.bool,
    errorAddingUser: PropTypes.object,
    isDeletingUser: PropTypes.bool,
    isDeletedUser: PropTypes.bool,
    errorDeletingUser: PropTypes.object,
    isResending: PropTypes.bool,
    isResent: PropTypes.bool,
    resendError: PropTypes.object,
    // handlers
    listClubUsers: PropTypes.func.isRequired,
    addUserToClub: PropTypes.func.isRequired,
    deleteUserFromClub: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    resendUserInvitation: PropTypes.func.isRequired,
    // other
    classes: PropTypes.object.isRequired,
    t: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        error,
        users,
        userCount,

        isDeletingUser,
        isDeletedUser,
        errorDeletingUser,

        isResending,
        isResent,
        resendError,
    } = state.componentAdminClubUsers;

    return {
        isLoading,
        error,
        users,
        userCount,

        isDeletedUser,
        isDeletingUser,
        errorDeletingUser,

        isResending,
        isResent,
        resendError,
    };
};

const AdminClubUsersState = connect(mapStateToProps, {
    listClubUsers,
    deleteUserFromClub,
    resendUserInvitation,
})(AdminClubUsers);
const AdminClubUsersTranslated = withTranslation()(AdminClubUsersState);
const AdminClubUsersStyled = withStyles(useStyles)(AdminClubUsersTranslated);
const AdminClubUsersRouter = withRouter(AdminClubUsersStyled);
const AdminClubUsersSnackbar = withSnackbar(AdminClubUsersRouter);

export { AdminClubUsersSnackbar as AdminClubUsers };
