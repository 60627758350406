import { REGISTER_USER_SUCCESS } from 'pages/Register/actionTypes';
import {
    SHOPPING_CART_LOADING,
    SHOPPING_CART_LOADING_SUCCESS,
    SHOPPING_CART_LOADING_FAILED,
    ADMIN_SHOPPING_CART_LOADING,
    ADMIN_SHOPPING_CART_LOADING_SUCCESS,
    ADMIN_SHOPPING_CART_LOADING_FAILED,
    SHOPPING_CART_PAY_LOADING,
    SHOPPING_CART_PAY_SUCCESS,
    SHOPPING_CART_PAY_FAILED,
    ADMIN_SHOPPING_CART_PAY_LOADING,
    ADMIN_SHOPPING_CART_PAY_SUCCESS,
    ADMIN_SHOPPING_CART_PAY_FAILED,
    ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_LOADING,
    ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_SUCCESS,
    ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_FAILED,
} from './actionTypes';
import { groupSubscriptionsByCourse } from 'utils';

const INITIAL_STATE = {
    isLoading: false,
    amountSubscriptions: 0,
    amountRefundSubscriptions: 0,
    error: null,
    subscriptions: {},
    refundSubscriptions: {},
    isProcessingPayment: false,
    paymentError: null,
    paymentRedirect: null,
    newRegisteredUser: null,
    isRemovingDiscount: false,
    isDiscountRemoved: false,
    removeDiscountError: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_SHOPPING_CART_LOADING:
        case SHOPPING_CART_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case ADMIN_SHOPPING_CART_LOADING_SUCCESS:
        case SHOPPING_CART_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                basket: action.payload,
                amountSubscriptions: action.payload.subscriptions
                    ? action.payload.subscriptions.length
                    : 0,
                amountRefundSubscriptions: action.payload.refund_subscriptions
                    ? action.payload.refund_subscriptions.length
                    : 0,
                // order subscriptions by course
                subscriptions: groupSubscriptionsByCourse(
                    action.payload.subscriptions
                ),
                refundSubscriptions: groupSubscriptionsByCourse(
                    action.payload.refund_subscriptions
                ),
            };
        case ADMIN_SHOPPING_CART_LOADING_FAILED:
        case SHOPPING_CART_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case ADMIN_SHOPPING_CART_PAY_LOADING:
        case SHOPPING_CART_PAY_LOADING:
            return {
                ...state,
                isProcessingPayment: true,
            };
        case ADMIN_SHOPPING_CART_PAY_SUCCESS:
        case SHOPPING_CART_PAY_SUCCESS:
            return {
                ...state,
                isProcessingPayment: false,
                paymentRedirect: action.payload,
            };
        case ADMIN_SHOPPING_CART_PAY_FAILED:
        case SHOPPING_CART_PAY_FAILED:
            return {
                ...state,
                isProcessingPayment: false,
                paymentError: action.payload,
            };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                newRegisteredUser: action.payload,
            };

        case ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_LOADING:
            return {
                ...state,
                isRemovingDiscount: true,
                isDiscountRemoved: false,
                removeDiscountError: null,
            };
        case ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_SUCCESS:
            return {
                ...state,
                isRemovingDiscount: false,
                isDiscountRemoved: true,
            };
        case ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_FAILED:
            return {
                ...state,
                isRemovingDiscount: false,
                removeDiscountError: action.payload,
            };

        default:
            return state;
    }
};
