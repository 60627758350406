import {
    ADMIN_ORDERS_LOADING,
    ADMIN_ORDERS_LOADING_SUCCESS,
    ADMIN_ORDERS_LOADING_FAILED,
} from "./actionTypes";

const INITIAL_STATE = {
    // orders
    isLoading: true,
    orders: [],
    total_orders: 0,
    totalPages: 0,
    currentPage: 1,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_ORDERS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ADMIN_ORDERS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                total_orders: action.payload.count,
                totalPages: action.payload.total_pages,
                orders: action.payload.results,
                currentPage: action.payload.current_page,
            };
        case ADMIN_ORDERS_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
