import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { urls, getUrl } from 'urls';

import { CircularProgress, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { listCourses, listCourseListActions } from './actions';

import {
    AdminHeader,
    Button,
    CardCourseAdmin,
    EmptyMessage,
    Toast,
} from 'components';
import { scrollIntoView } from 'utils';

const useStyles = (theme) => ({
    flex: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
    },
});

class AdminEventCourses extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handleNewCourse = this.handleNewCourse.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.renderActions = this.renderActions.bind(this);
    }

    componentDidMount() {
        // load stuff
        if (!this.props.isLoadingEvent && this.props.event) {
            this.props.listCourses(this.props.event.id);
            this.props.listCourseListActions(this.props.event.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event !== this.props.event && this.props.event) {
            this.props.listCourses(this.props.event.id);
            this.props.listCourseListActions(this.props.event.id);
        }

        // if (
        //     prevProps.isCreated !== this.props.isCreated &&
        //     this.props.isCreated
        // ) {
        //     this.props.listCourses(this.props.event.id);
        // }
        //
        // if (
        //     prevProps.isDeleted !== this.props.isDeleted &&
        //     this.props.isDeleted
        // ) {
        //     this.props.listCourses(this.props.event.id);
        // }
        //
        // if (
        //     prevProps.isChanged !== this.props.isChanged &&
        //     this.props.isChanged
        // ) {
        //     this.props.listCourses(this.props.event.id);
        // }
    }

    handleNewCourse() {
        this.props.history.push(
            getUrl(urls.ADMIN_EVENT_COURSE_ADD, {
                eventId: this.props.event.id,
            })
        );
    }

    handlePage(object, page) {
        // load next page
        this.props.listCourses(this.props.event.id, page);

        scrollIntoView('#top-page');
        this.props.history.push(window.location.pathname + '?page=' + page); // eslint-disable-line no-undef
    }

    renderEmpty() {
        const { t } = this.props;

        return (
            <EmptyMessage
                title={t('common:title.no_courses')}
                description={t('common:text.no_courses.description')}
                actionText={t('common:text.new_course')}
                action={this.handleNewCourse}
                actionAsButton
            />
        );
    }

    renderActions() {
        const { t, isLoadingActions, errorActions, actions } = this.props;

        if (isLoadingActions) {
            return <CircularProgress size={20} />;
        }

        if (errorActions) {
            return (
                <Toast message={t('common:error.general.description')} open />
            );
        }

        if (actions && actions['create']) {
            // if the user can create stuff
            return (
                <Button
                    color={'secondary'}
                    onClick={() =>
                        this.props.history.push(
                            getUrl(urls.ADMIN_EVENT_COURSE_ADD, {
                                eventId: this.props.event.id,
                            })
                        )
                    }
                >
                    {t('common:title.admin.course_add')}
                </Button>
            );
        }

        return;
    }

    renderLoader() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div id="top-page" />
                <AdminHeader
                    variant={'small'}
                    title={t('common:title.courses')}
                />

                {[...new Array(10)].map((key) => (
                    <CardCourseAdmin key={key} isLoading />
                ))}
            </React.Fragment>
        );
    }

    renderPagination() {
        const { classes, totalPages, currentPage } = this.props;

        if (totalPages <= 0) {
            return;
        }

        return (
            <Grid item xs={12}>
                <div className={classes.flex}>
                    <Pagination
                        count={totalPages}
                        shape="rounded"
                        page={currentPage}
                        onChange={this.handlePage}
                    />
                </div>
            </Grid>
        );
    }

    render() {
        const { t, courses, isLoading, event } = this.props;

        if (isLoading) {
            return this.renderLoader();
        }

        if (!courses || courses.length === 0) {
            return this.renderEmpty();
        }

        return (
            <React.Fragment>
                <div id="top-page" />

                <AdminHeader
                    variant={'small'}
                    title={t('common:title.courses')}
                    actionContainer={this.renderActions()}
                />

                {courses.map((obj, i) => {
                    return (
                        <CardCourseAdmin course={obj} event={event} key={i} />
                    );
                })}

                {this.renderPagination()}
            </React.Fragment>
        );
    }
}

AdminEventCourses.propTypes = {
    // props
    event: PropTypes.object.isRequiredIf((props) => !props.isLoadingEvent),
    isLoadingEvent: PropTypes.bool,
    isCreated: PropTypes.bool,
    isDeleted: PropTypes.bool,
    isChanged: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingActions: PropTypes.bool,
    errorActions: PropTypes.array,
    actions: PropTypes.object,
    history: PropTypes.object,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    courses: PropTypes.object,
    classes: PropTypes.object.isRequired,
    // handlers
    listCourses: PropTypes.func.isRequired,
    listCourseListActions: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        error,
        courses,

        isLoadingActions,
        actions,
        errorActions,
    } = state.componentAdminEventCourses;

    return {
        isLoading,
        error,
        courses,

        isLoadingActions,
        actions,
        errorActions,
    };
};

const AdminEventCoursesState = connect(mapStateToProps, {
    listCourses,
    listCourseListActions,
})(AdminEventCourses);
const AdminEventCoursesTranslated = withTranslation()(AdminEventCoursesState);
const AdminEventCoursesStyled = withStyles(useStyles)(
    AdminEventCoursesTranslated
);
const AdminEventCoursesRouter = withRouter(AdminEventCoursesStyled);

export { AdminEventCoursesRouter as AdminEventCourses };
