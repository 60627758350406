import {
    ADMIN_CLUB_USERS_LOADING,
    ADMIN_CLUB_USERS_SUCCESS,
    ADMIN_CLUB_USERS_FAILED,
    ADMIN_CLUB_DELETE_INVITATION_LOADING,
    ADMIN_CLUB_DELETE_INVITATION_SUCCESS,
    ADMIN_CLUB_DELETE_INVITATION_FAILED,
    ADMIN_CLUB_RESEND_INVITATION_LOADING,
    ADMIN_CLUB_RESEND_INVITATION_SUCCESS,
    ADMIN_CLUB_RESEND_INVITATION_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    users: [],
    userCount: 0,
    // delete
    isDeletingUser: false,
    isDeletedUser: false,
    errorDeletingUser: null,
    // resend
    isResending: false,
    isResent: false,
    resendError: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_CLUB_USERS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ADMIN_CLUB_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                users: action.payload.results,
                userCount: action.payload.count,
            };
        case ADMIN_CLUB_USERS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload || true,
            };

        case ADMIN_CLUB_DELETE_INVITATION_LOADING:
            return {
                ...state,
                isDeletingUser: true,
                isDeletedUser: false,
                errorDeletingUser: null,
            };
        case ADMIN_CLUB_DELETE_INVITATION_SUCCESS:
            return {
                ...state,
                isDeletingUser: false,
                isDeletedUser: true,
            };
        case ADMIN_CLUB_DELETE_INVITATION_FAILED:
            return {
                ...state,
                isDeletingUser: false,
                errorDeletingUser: action.payload,
            };

        case ADMIN_CLUB_RESEND_INVITATION_LOADING:
            return {
                ...state,
                isResending: true,
                isResent: false,
                resendError: null,
            };
        case ADMIN_CLUB_RESEND_INVITATION_SUCCESS:
            return {
                ...state,
                isResending: false,
                isResent: true,
            };
        case ADMIN_CLUB_RESEND_INVITATION_FAILED:
            return {
                ...state,
                isResending: false,
                resendError: action.payload,
            };

        default:
            return state;
    }
};
