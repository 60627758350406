import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { urls, getUrl } from 'urls';
import { Skeleton } from '@material-ui/lab';
import { Chip, PopupMenuAdminEvent } from 'components';
import { getObjectFromCache } from 'utils';

const useStyles = (theme) => ({});

class CardEventAdmin extends Component {
    constructor(props) {
        super(props);

        // set state
        this.state = {
            dialogDeleteOpen: false,
            club_name: null,
        };

        // event handlers
        this.handleViewEvent = this.handleViewEvent.bind(this);
    }

    componentDidMount() {
        if (this.props.obj && !this.state.club_name) {
            getObjectFromCache({
                url: this.props.obj.club,
                success: (result) => {
                    this.setState({
                        club_name: result['name'],
                    });
                },
                error: () => {
                    // do something TODO
                },
            });
        }
    }

    handleViewEvent() {
        const { history, obj } = this.props;

        history.push(getUrl(urls.ADMIN_EVENT_DETAIL, { eventId: obj.id }));
    }

    renderActions() {
        return (
            <PopupMenuAdminEvent
                event={this.props.obj}
                isLoading={this.props.isLoadingActions || this.props.isLoading}
            />
        );
    }

    renderLoading() {
        return (
            <Card className="event-admin-card" elevation={0}>
                <CardContent>
                    <div className="eventId">
                        <Skeleton variant="rect" height={20} width={50} />
                    </div>
                    <div className="name">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="clubname">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="status">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="actions" />
                </CardContent>
            </Card>
        );
    }

    renderCard() {
        const { obj, t } = this.props;

        return (
            <Card className="event-admin-card" elevation={0}>
                <CardActionArea onClick={this.handleViewEvent}>
                    <CardContent>
                        <div className="eventId">#{obj.id}</div>
                        <div className="name">
                            {this.state.club_name ? (
                                <small>{this.state.club_name}</small>
                            ) : (
                                <Skeleton
                                    variant="rect"
                                    width={100}
                                    height="17"
                                />
                            )}
                            <strong>{obj.name}</strong>
                        </div>
                        <div className="clubname">
                            <small>{t('common:title.start_date')}</small>
                            {obj.days[0].date_start_at}{' '}
                            {obj.days[0].time_start_at}
                        </div>
                        <div className="status">
                            <Chip label={obj.status} />
                        </div>
                        <div className="actions">{this.renderActions()}</div>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return <React.Fragment>{this.renderCard()}</React.Fragment>;
    }
}

CardEventAdmin.propTypes = {
    isDeleted: PropTypes.bool,
    isDeleting: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingActions: PropTypes.bool,
    isCopyingEvent: PropTypes.bool,
    isEventCopied: PropTypes.bool,
    errorCopying: PropTypes.object,
    errorActions: PropTypes.object,
    obj: PropTypes.object,
    deleteEvent: PropTypes.func.isRequired,
    copyEvent: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object,
    t: PropTypes.func.isRequired,
};

const CardEventAdminTranslated = withTranslation()(CardEventAdmin);
const CardEventAdminStyled = withStyles(useStyles)(CardEventAdminTranslated);
const CardEventAdminRouter = withRouter(CardEventAdminStyled);

export { CardEventAdminRouter as CardEventAdmin };
