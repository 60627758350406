import {
    DIALOG_INVITE_USER_INVITATION_ADD_LOADING,
    DIALOG_INVITE_USER_INVITATION_ADD_SUCCESS,
    DIALOG_INVITE_USER_INVITATION_ADD_FAILED,
    DIALOG_INVITE_USER_INVITATION_CHANGE_LOADING,
    DIALOG_INVITE_USER_INVITATION_CHANGE_SUCCESS,
    DIALOG_INVITE_USER_INVITATION_CHANGE_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    // add
    isAddingUser: false,
    errorAddingUser: null,
    newRequest: null,
    // change
    isChangingUser: false,
    isChanged: null,
    errorChangingUser: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DIALOG_INVITE_USER_INVITATION_ADD_LOADING:
            return {
                ...INITIAL_STATE,
                isAddingUser: true,
                errorAddingUser: null,
            };
        case DIALOG_INVITE_USER_INVITATION_ADD_SUCCESS:
            return {
                ...state,
                isAddingUser: false,
                errorAddingUser: null,
                newRequest: action.payload,
            };
        case DIALOG_INVITE_USER_INVITATION_ADD_FAILED:
            return {
                ...state,
                isAddingUser: false,
                newRequest: null,
                errorAddingUser: action.payload,
            };

        case DIALOG_INVITE_USER_INVITATION_CHANGE_LOADING:
            return {
                ...INITIAL_STATE,
                isChangingUser: true,
            };
        case DIALOG_INVITE_USER_INVITATION_CHANGE_SUCCESS:
            return {
                ...state,
                isChangingUser: false,
                isChanged: true,
                newRequest: action.payload,
            };
        case DIALOG_INVITE_USER_INVITATION_CHANGE_FAILED:
            return {
                ...state,
                isChangingUser: false,
                errorChangingUser: action.payload,
            };

        default:
            return state;
    }
};
