import { getAdminEvent } from 'services/EventService';

import {
    EVENT_LOADING,
    EVENT_LOADING_SUCCESS,
    EVENT_LOADING_FAILED,
} from './actionTypes';

export const getEvent = (id) => {
    return (dispatch) => {
        dispatch({ type: EVENT_LOADING });

        getAdminEvent(id)
            .then((result) => {
                dispatch({
                    type: EVENT_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: EVENT_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};
