import {
    addAdminClub,
    editAdminClub,
    getAdminClub,
} from 'services/ClubService';

import {
    CLUB_ADD_LOADING,
    CLUB_ADD_SUCCESS,
    CLUB_ADD_FAILED,
    CLUB_LOADING,
    CLUB_LOADING_SUCCESS,
    CLUB_LOADING_FAILED,
    CLUB_EDIT_LOADING,
    CLUB_EDIT_SUCCESS,
    CLUB_EDIT_FAILED,
    CLUB_RESET,
} from './actionTypes';

const convertData = (data) => {
    if (data.vat_number) {
        data.vat_number = data.vat_number
            .replaceAll(' ', '')
            .replaceAll('.', '');
    }

    return data;
};

export const getClub = (id) => {
    return (dispatch) => {
        dispatch({ type: CLUB_LOADING });

        getAdminClub(id)
            .then((result) => {
                dispatch({
                    type: CLUB_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CLUB_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const addClub = (data) => {
    return (dispatch) => {
        dispatch({ type: CLUB_ADD_LOADING });

        addAdminClub(convertData(data))
            .then((result) => {
                dispatch({
                    type: CLUB_ADD_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CLUB_ADD_FAILED,
                    payload: error,
                });
            });
    };
};

export const editClub = (data) => {
    return (dispatch) => {
        dispatch({ type: CLUB_EDIT_LOADING });

        delete data['payment_information'];

        editAdminClub(data.id, convertData(data))
            .then((result) => {
                dispatch({
                    type: CLUB_EDIT_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CLUB_EDIT_FAILED,
                    payload: error,
                });
            });
    };
};

export const resetClub = () => {
    return (dispatch) => {
        dispatch({ type: CLUB_RESET });
    };
};
