import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { CardList, CardCourseAdmin } from '..';

import {
    listCourses,
} from './actions';


class AdminCoursesList extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handlePage = this.handlePage.bind(this);
    }

    componentDidMount() {
        this.props.listCourses();
    }

    handlePage(object, page) {
        this.props.listCourses(page);
    }

    render() {
        const {
            isLoading, courses, total_pages,
            current_page, error,
        } = this.props;

        return (
            <CardList
                isLoading={isLoading}
                objects={courses}
                totalPages={total_pages}
                currentPage={current_page}
                error={error}
                card={CardCourseAdmin}
                handlePage={this.handlePage}
                useCardAsLoaders
            />
        );
    }
}

AdminCoursesList.propTypes = {
    event: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        events,
        error,
        isChanged,
        isCreated,
        isDeleted,
        current_page,
    } = state.componentAdminCourseList;

    return {
        isLoading,
        events,
        error,
        isChanged,
        isCreated,
        isDeleted,
        current_page,
    };
};

const AdminCoursesListState = connect(
    mapStateToProps,
    {
        listCourses,
    }
)(AdminCoursesList);

export { AdminCoursesListState as AdminCoursesList };
