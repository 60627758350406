import format from 'string-format';
import fetchApi from './api';

const adminEndPoints = {
    addSubscriptionLock: '/v1/locks/subscriptions/',
    editSubscriptionLock: '/v1/locks/subscriptions/{id}/',
    deleteSubscriptionLock: '/v1/locks/subscriptions/{id}/',
};

export const addSubscriptionLock = ({ course, start_number }) => fetchApi({
    url: adminEndPoints.addSubscriptionLock,
    data: {
        course,
        start_number,
    },
    method: 'POST',
});

export const editSubscriptionLock = ({ id, course, start_number, subscription }) => fetchApi({
    url: format(adminEndPoints.editSubscriptionLock, { id }),
    data: {
        course,
        start_number,
        subscription,
    },
    method: 'PATCH',
});

export const deleteSubscriptionLock = ({ id }) => fetchApi({
    url: format(adminEndPoints.deleteSubscriptionLock, { id }),
    data: {},
    method: 'DELETE',
});
