import { payOrder as apiPayOrder } from 'services/BasketService';
import { getOrder as apiGetOrder } from 'services/OrderService';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import {
    ORDER_PAY_LOADING,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAILED,
    ORDER_LOADING,
    ORDER_SUCCESS,
    ORDER_FAILED,
    ORDER_RESET_DIALOG,
} from './actionTypes';

export const resetDialog = ({ key }) => {
    return {
        type: ORDER_RESET_DIALOG,
        meta: { key: key },
    };
};

export const payOrder = ({ id, amount, key }) => {
    return (dispatch) => {
        dispatch({
            type: ORDER_PAY_LOADING,
            meta: { key: key },
        });

        apiPayOrder(id, amount)
            .then((result) => {
                dispatch({
                    type: ORDER_PAY_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ORDER_PAY_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const getOrder = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: ORDER_LOADING,
            meta: { key: key },
        });

        apiGetOrder(id)
            .then((result) => {
                dispatch({
                    type: ORDER_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ORDER_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};
