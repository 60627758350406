import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
    CircularProgress,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    Button,
} from '..';

const useStyles = (theme) => ({
    center: {
        textAlign: 'center',
        margin: '80px 0',
        width: '80%',
    },
    loader: {
        marginBottom: theme.spacing(3),
    },
    link: {
        display: 'block',
        marginTop: '40px',
    },
    small: {
        fontSize: '1.25rem',
    },
    icon: {
        marginBottom: '15px',
    },
    text: {
        display: 'block',
        margin: '5px auto 0',
        width: '80%',

        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    error: {
        color: theme.colors.permanentGeraniumLake,

        '& h2': {
            color: theme.colors.permanentGeraniumLake,
        }
    }
});


class EmptyMessage extends Component {
    renderIcon() {
        const { showLoader, icon, classes } = this.props;

        if (icon) {
            return (
                <div className={classes.icon}>
                    {icon}
                </div>
            );
        }

        if (showLoader) {
            return <CircularProgress className={classes.loader} />;
        }
    }

    renderAction() {
        const { action, actionText, actionAsButton, t, classes } = this.props;

        if (action) {
            return (
                <div className={classes.link}>
                    {
                        actionAsButton
                            ? (
                                <Button
                                    color={'secondary'}
                                    onClick={action}
                                >
                                    {
                                        actionText
                                            ? actionText
                                            : t('common:error.general.default_actionText')
                                    }
                                </Button>
                            )
                            : (
                                <Link
                                    onClick={action}
                                >
                                    {
                                        actionText
                                            ? actionText
                                            : t('common:error.general.default_actionText')}
                                </Link>
                            )
                    }
                </div>
            );
        }
    }

    render() {
        const { title, description, classes, textSize, variant } = this.props;

        let typoClass = classes.normal;
        if (textSize === 'small') {
            typoClass = classes.small;
        }

        let variantClass = classes.default;
        if (variant === 'error') {
            variantClass = classes.error;
        }

        return (
            <Grid container className={classes.container} display="flex" alignItems="center" justify="center">
                <Grid item className={classNames(classes.center, variantClass)}>
                    {this.renderIcon()}

                    <Typography variant="h2" className={typoClass}>
                        {title}
                    </Typography>
                    <small className={classes.text}>{description}</small>

                    {this.renderAction()}
                </Grid>
            </Grid>
        );
    }
}

EmptyMessage.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    showLoader: PropTypes.bool,
    actionText: PropTypes.string,
    action: PropTypes.func,
    actionAsButton: PropTypes.bool,
    icon: PropTypes.object,
    textSize: PropTypes.oneOf(['small', 'normal']),
    variant: PropTypes.oneOf(['error', 'default'])
};

const EmptyMessageStyled = withStyles(useStyles)(EmptyMessage);
const EmptyMessageTranslated = withTranslation()(EmptyMessageStyled);

export { EmptyMessageTranslated as EmptyMessage };
