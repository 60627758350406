import { deleteAdminClub } from 'services/ClubService';

import {
    CLUB_DELETE_LOADING,
    CLUB_DELETE_SUCCESS,
    CLUB_DELETE_FAILED,
} from './actionTypes';

export const deleteClub = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: CLUB_DELETE_LOADING,
            meta: { key: key },
        });

        deleteAdminClub({ id })
            .then((result) => {
                dispatch({
                    type: CLUB_DELETE_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                dispatch({
                    type: CLUB_DELETE_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};
