import StandardError from 'standard-error';

function ApiError(code, msg, response_obj) {
    StandardError.call(
        this,
        msg,
        {
            code,
            response_obj,
            response: null
        }
    );
}

ApiError.prototype = Object.create(StandardError.prototype, {
    constructor: {
        value: ApiError,
        configurable: true,
        writable: true,
    }
});
ApiError.prototype.name = 'ApiError';

export { ApiError as default };
