import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { getUrl, urls } from 'urls';
import { routes } from '../../pages/Admin/routes';

import { Link, NavLink } from 'react-router-dom';

import {
    Button,
    Divider,
    IconButton,
    MenuList,
    MenuItem,
    SwipeableDrawer,
} from '@material-ui/core';

import { MenuOutlined as MenuIcon } from '@material-ui/icons';

import { UserMenu } from '..';

import logoSvg from 'assets/images/equento-logo.svg';

const useStyles = (theme) => ({
    logo: {
        padding: '32px',

        '& img': {
            width: '120px',
        },

        [theme.breakpoints.down('sm')]: {
            padding: '24px',
        },
    },
    list: {
        '& .MuiButton-label': {
            justifyContent: 'flex-start',
        },

        '& button[type="button"]': {
            width: '100%',
        },

        '& a': {
            display: 'block',
            width: '100%',

            '& button:hover': {
                backgroundColor: 'transparent',
            },
        },
    },
    button: {
        width: '100%',

        '& .MuiButton-label': {
            justifyContent: 'center',
        },
    },
    mobileMenu: {
        maxWidth: '280px',
        minWidth: '280px',
        width: '280px',
    },
    usermenu: {
        flexDirection: 'column',
    },
});

class AdminMobileMenu extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            open: false,
        };

        // event handlers
        this.handleClose = this.handleClose.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
    }

    handleClose() {
        this.setState({
            open: false,
        });
    }

    handleOpen() {
        this.setState({
            open: true,
        });
    }

    render() {
        const { classes, isLoggedIn } = this.props;

        return (
            <React.Fragment>
                <IconButton onClick={this.handleOpen}>
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                    open={this.state.open}
                    onClose={this.handleClose}
                    classes={{
                        paper: classNames(classes.mobileMenu),
                    }}
                >
                    <Link to="/" className={classes.logo}>
                        <img src={logoSvg} alt="Equento" className="logo" />
                    </Link>
                    <Divider />
                    <MenuList className={classes.list}>
                        {routes.map((route, i) =>
                            !route.hidden &&
                            ((!route.hideWhenAutenticated && isLoggedIn) ||
                                !isLoggedIn) ? (
                                <MenuItem key={i}>
                                    <NavLink
                                        exact
                                        key={i}
                                        to={route.path}
                                        onClick={this.handleClose}
                                        activeClassName="selected"
                                        className={
                                            route.path === getUrl(urls.LOGIN)
                                                ? classes.button
                                                : null
                                        }
                                    >
                                        <Button
                                            fullWidth
                                            disableRipple
                                            disableElevation
                                            disableFocusRipple
                                            startIcon={route.icon}
                                            variant={
                                                route.path ===
                                                getUrl(urls.LOGIN)
                                                    ? 'contained'
                                                    : 'text'
                                            }
                                            color={
                                                route.path ===
                                                getUrl(urls.LOGIN)
                                                    ? 'secondary'
                                                    : 'default'
                                            }
                                        >
                                            {route.name}
                                        </Button>
                                    </NavLink>
                                </MenuItem>
                            ) : null
                        )}

                        {isLoggedIn && (
                            <React.Fragment>
                                <Divider />
                                <MenuItem className={classes.usermenu}>
                                    <UserMenu mobile />
                                </MenuItem>
                            </React.Fragment>
                        )}
                    </MenuList>
                </SwipeableDrawer>
            </React.Fragment>
        );
    }
}

const AdminMobileMenuStyled = withStyles(useStyles)(AdminMobileMenu);
const AdminMobileMenuTranslated = withTranslation()(AdminMobileMenuStyled);

export { AdminMobileMenuTranslated as AdminMobileMenu };
