import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation, Trans } from 'react-i18next';
import { getUrl } from 'urls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { TemplateLogin } from '../TemplateLogin';
import { MailOutline as MailOutlineIcon } from '@material-ui/icons';

// actions
import { registerUser } from './actions';
import { RegisterForm } from './form';

class RegisterPage extends Component {
    renderGeneralFormErrors() {
        const { formErrors } = this.props;

        if (formErrors && formErrors['non_field_errors']) {
            return (
                <div className="form-error">
                    {formErrors['non_field_errors']}
                </div>
            );
        }
    }

    renderRegisterForm() {
        const { isRegistering, formErrors } = this.props;

        return (
            <React.Fragment>
                {this.renderGeneralFormErrors()}

                <RegisterForm
                    onSubmit={this.props.registerUser}
                    isLoading={isRegistering}
                    errors={formErrors}
                />
            </React.Fragment>
        );
    }

    renderLoader() {
        const { t, newUser } = this.props;

        return (
            <div className="text-center">
                <MailOutlineIcon fontSize={'large'} />

                <br />
                <br />

                <strong>{t('common:text.registered.title')}</strong>
                <br />
                <small>{t('common:text.registered.description')}</small>
                <br />
                <br />
                <br />
                <span>
                    <Trans i18nKey="text.registered.email" ns="common">
                        An email has been sent to{' '}
                        <strong>{{ email: newUser.email }}</strong>.
                    </Trans>
                </span>
                <br />
                <small>{t('common:text.registered.spam')}</small>
            </div>
        );
    }

    render() {
        const { t, newUser } = this.props;

        return (
            <TemplateLogin
                title={t('common:title.welcome')}
                description={t('common:text.fill_in_fields_account')}
            >
                <Helmet>
                    <title>{t('common:title.register')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>

                {newUser ? this.renderLoader() : this.renderRegisterForm()}
            </TemplateLogin>
        );
    }
}

RegisterPage.propTypes = {
    // props
    formErrors: PropTypes.object,
    isRegistering: PropTypes.bool,
    newUser: PropTypes.object,
    // handlers
    registerUser: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        isRegistering,
        formErrors,
        newUser,
    } = state.pageRegister;
    const { isLoggedIn } = state.authentication;

    return {
        isLoading,
        isRegistering,
        formErrors,
        isLoggedIn,
        newUser,
    };
};

const RegisterPageState = connect(mapStateToProps, {
    registerUser,
})(RegisterPage);

const RegisterPageTranslated = withTranslation()(RegisterPageState);

export { RegisterPageTranslated as RegisterPage };
