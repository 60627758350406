import AccountPageReducer from '../pages/Account/reducer';
import AdminClubUsersReducer from 'components/AdminClubUsers/reducer';
import AdminClubsPageReducer from '../pages/AdminClubs/reducer';
import AdminCoursesListReducer from 'components/AdminCoursesList/reducer';
import AdminCoursePageReducer from '../pages/AdminCourse/reducer';
import AdminCourseAddEditReducer from '../pages/AdminCourseAddEdit/reducer';
import AdminEventPageReducer from '../pages/AdminEvent/reducer';
import AdminEventsPageReducer from '../pages/AdminEvents/reducer';
import AdminOrderPageReducer from '../pages/AdminOrder/reducer';
import AdminOrdersPageReducer from '../pages/AdminOrders/reducer';
import AdminEventStatisticsReducer from 'components/AdminEventStatistics/reducer';
import AdminEventCoursesReducer from 'components/AdminEventCourses/reducer';
import AdminEventTicketsReducer from 'components/AdminEventTickets/reducer';
import AdminClubAddEditReducer from '../pages/AdminClubAddEdit/reducer';
import AdminEventAddEditReducer from '../pages/AdminEventAddEdit/reducer';
import AdminTicketPageReducer from '../pages/AdminTicket/reducer';
import AlertReducer from 'components/AlertContainer/reducer';
import AuthenticationReducer from './AuthenticationReducer';
import AuthorizationsReducer from './AuthorizationsReducer';
import DialogCourseReducer from 'components/Dialogs/DialogCourse/reducer';
import DialogInviteUserReducer from 'components/Dialogs/DialogInviteUser/reducer';
import DialogDiscountReducer from 'components/Dialogs/DialogDiscount/reducer';
import ForgotPasswordPageReducer from '../pages/ForgotPassword/reducer';
import HomePageReducer from '../pages/Home/reducer';
import LoginPageReducer from '../pages/Login/reducer';
import LogoutPageReducer from '../pages/Logout/reducer';
import NotificationsReducer from 'components/Notifications/reducer';
import OrderComponentReducer from 'components/Orders/reducer';
import ProfileComponentReducer from 'components/Profile/reducer';
import PublicCourseDetailPageReducer from '../pages/CourseDetail/reducer';
import PublicEventsPageReducer from '../pages/Events/reducer';
import PublicEventDetailPageReducer from '../pages/EventDetail/reducer';
import ProfileReducer from './ProfileReducer';
import RegisterPageReducer from '../pages/Register/reducer';
import ResetPasswordPageReducer from '../pages/ResetPassword/reducer';
import ShoppingCartReducer from 'components/ShoppingCart/reducer';
import TicketComponentReducer from 'components/Tickets/reducer';
import UserReducer from './UserReducer';
import GlobalReducer from './GlobalReducer';

export const staticReducers = {
    // pages
    pageAccount: AccountPageReducer,
    pageAdminClubs: AdminClubsPageReducer,
    pageAdminCourse: AdminCoursePageReducer,
    pageAdminEvent: AdminEventPageReducer,
    pageAdminEvents: AdminEventsPageReducer,
    pageAdminClubAddEdit: AdminClubAddEditReducer,
    pageAdminCourseAddEdit: AdminCourseAddEditReducer,
    pageAdminEventAddEdit: AdminEventAddEditReducer,
    pageAdminOrder: AdminOrderPageReducer,
    pageAdminOrders: AdminOrdersPageReducer,
    pageAdminTicket: AdminTicketPageReducer,
    pageForgotPassword: ForgotPasswordPageReducer,
    pageHome: HomePageReducer,
    pageLogin: LoginPageReducer,
    pageLogout: LogoutPageReducer,
    pagePublicCourseDetail: PublicCourseDetailPageReducer,
    pagePublicEvents: PublicEventsPageReducer,
    pagePublicEventDetail: PublicEventDetailPageReducer,
    pageRegister: RegisterPageReducer,
    pageResetPassword: ResetPasswordPageReducer,

    // components
    componentAlert: AlertReducer,
    componentAdminClubUsers: AdminClubUsersReducer,
    componentAdminCourseList: AdminCoursesListReducer,
    componentAdminEventStatistics: AdminEventStatisticsReducer,
    componentAdminEventCourses: AdminEventCoursesReducer,
    componentAdminEventTickets: AdminEventTicketsReducer,
    componentNotifications: NotificationsReducer,
    componentOrders: OrderComponentReducer,
    componentProfile: ProfileComponentReducer,
    componentTickets: TicketComponentReducer,

    // dialogs
    dialogCourse: DialogCourseReducer,
    dialogDiscount: DialogDiscountReducer,
    dialogInviteUser: DialogInviteUserReducer,
    shoppingCart: ShoppingCartReducer,

    // login
    authentication: AuthenticationReducer,

    // authorizations
    authorizations: AuthorizationsReducer,

    // user
    user: UserReducer,

    // profile
    profile: ProfileReducer,

    // global
    global: GlobalReducer,
};
