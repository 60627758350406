import {
    ORDER_PAY_LOADING,
    ORDER_PAY_SUCCESS,
    ORDER_PAY_FAILED,
    ORDER_LOADING,
    ORDER_SUCCESS,
    ORDER_FAILED,
    ORDER_RESET_DIALOG,
} from './actionTypes';

const INITIAL_STATE = {
    isProcessingPayment: false,
    paymentError: null,
    paymentRedirect: null,
    order: null,
    isLoadingOrder: false,
    orderError: null,
};

export default function (name = '') {
    return (state = INITIAL_STATE, action) => {
        if (!action.meta || action.meta.key !== name) {
            return state;
        }

        switch (action.type) {
            case ORDER_RESET_DIALOG:
                return {
                    ...state,
                    ...INITIAL_STATE,
                };
            case ORDER_PAY_LOADING:
                return {
                    ...state,
                    isProcessingPayment: true,
                };
            case ORDER_PAY_SUCCESS:
                return {
                    ...state,
                    isProcessingPayment: false,
                    paymentRedirect: action.payload,
                };
            case ORDER_PAY_FAILED:
                return {
                    ...state,
                    isProcessingPayment: false,
                    paymentError: action.payload,
                };

            case ORDER_LOADING:
                return {
                    ...state,
                    isLoadingOrder: true,
                };
            case ORDER_SUCCESS:
                return {
                    ...state,
                    isLoadingOrder: false,
                    order: action.payload,
                };
            case ORDER_FAILED:
                return {
                    ...state,
                    isLoadingOrder: false,
                    orderError: action.payload,
                };

            default:
                return state;
        }
    };
}
