import {
    CLUBS_LOADING,
    CLUBS_LOADING_SUCCESS,
    CLUBS_LOADING_FAILED,
    CLUB_RESET_CHANGED,
    CLUB_RESET_CREATED,
    CLUB_RESET_DELETED,
    CLUBS_ACTION_LOADING,
    CLUBS_ACTION_SUCCESS,
    CLUBS_ACTION_FAILED,
} from './actionTypes';

import {
    CLUB_ADD_SUCCESS,
    CLUB_EDIT_SUCCESS,
} from 'pages/AdminClubAddEdit/actionTypes';

import { CLUB_DELETE_SUCCESS } from 'components/Cards/CardClub/actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    isLoadingActions: true,
    error: null,
    errorActions: null,
    actions: {},
    clubs: [],
    totalClubs: 0,
    totalPages: 0,
    currentPage: 1,
    isCreated: false,
    isChanged: false,
    isDeleted: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLUBS_ACTION_LOADING:
            return {
                ...state,
                isLoadingActions: true,
            };
        case CLUBS_ACTION_SUCCESS:
            return {
                ...state,
                isLoadingActions: false,
                actions: action.payload.actions.USER,
            };
        case CLUBS_ACTION_FAILED:
            return {
                ...state,
                isLoadingActions: false,
                errorActions: action.payload,
            };

        case CLUB_RESET_CHANGED:
            return {
                ...state,
                isChanged: false,
            };
        case CLUB_RESET_CREATED:
            return {
                ...state,
                isCreated: false,
            };
        case CLUB_RESET_DELETED:
            return {
                ...state,
                isDeleted: false,
            };

        case CLUB_ADD_SUCCESS:
            return {
                ...state,
                isCreated: true,
            };
        case CLUB_EDIT_SUCCESS:
            return {
                ...state,
                isChanged: true,
            };
        case CLUB_DELETE_SUCCESS:
            return {
                ...state,
                isDeleted: true,
            };

        case CLUBS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case CLUBS_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                totalClubs: action.payload.count,
                totalPages: action.payload.total_pages,
                clubs: action.payload.results,
                currentPage: action.payload.current_page,
            };
        case CLUBS_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
