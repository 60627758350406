import { deleteAdminCourse, exportStartlist } from 'services/CourseService';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import {
    ADMIN_COURSE_DELETE_LOADING,
    ADMIN_COURSE_DELETE_SUCCESS,
    ADMIN_COURSE_DELETE_FAILED,
} from './actionTypes';

export const deleteCourse = ({ id, key }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_DELETE_LOADING });

        deleteAdminCourse(id)
            .then((result) => {
                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.deleted_course'
                    ),
                });

                dispatch({
                    type: ADMIN_COURSE_DELETE_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_COURSE_DELETE_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const downloadStartList = ({ id, key }) => {
    return (dispatch) => {
        return exportStartlist({ id });
    };
};
