import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { CardSubscription } from 'components';

class CardSubscriptionAdmin extends Component {
    render() {
        return <CardSubscription {...this.props} admin />;
    }
}

const CardSubscriptionAdminTranslated = withTranslation()(
    CardSubscriptionAdmin
);

export { CardSubscriptionAdminTranslated as CardSubscriptionAdmin };
