import {
    ADMIN_COURSES_LOADING,
    ADMIN_COURSES_LOADING_SUCCESS,
    ADMIN_COURSES_LOADING_FAILED,
} from './actionTypes';

import { listAdminCourses } from 'services/CourseService';

export const listCourses = ({ eventId, page }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSES_LOADING });

        listAdminCourses({ event: eventId, page })
            .then((result) => {
                dispatch({
                    type: ADMIN_COURSES_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_COURSES_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};
