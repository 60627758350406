import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Alert } from '@material-ui/lab';

import { TemplateLogin } from '../TemplateLogin';
import { getUrl } from 'urls';
import PropTypes from 'prop-types';

import { ForgotPasswordForm } from './forms';

// actions
import { resetPassword } from './actions';

const useStyles = (theme) => ({
    alert: {
        marginBottom: theme.spacing(2),
    }
});


class ForgotPasswordPage extends Component {
    renderGeneralFormErrors() {
        const { errors } = this.props;

        if (errors && errors['non_field_errors']) {
            return (
                <div className="form-error">{errors['non_field_errors']}</div>
            );
        }
    }

    renderSuccessMessage() {
        const { emailSent, t, classes } = this.props;

        if (emailSent) {
            return (
                <Alert severity={'success'} className={classes.alert}>
                    {t('common:success.general.email_sent')}
                </Alert>
            );
        }
    }

    renderForgotPasswordForm() {
        const { isLoading, t } = this.props;

        return (
            <React.Fragment>
                {this.renderGeneralFormErrors()}
                {this.renderSuccessMessage()}

                <p>{t('common:description.intro_forgot_password')}</p>

                <ForgotPasswordForm
                    onSubmit={this.props.resetPassword}
                    isLoading={isLoading}
                />
            </React.Fragment>
        );
    }

    render() {
        const { t, location } = this.props;

        return (
            <TemplateLogin
                title={t('common:title.forgot_password')}
                description={t('common:description.forgot_password')}
            >
                <Helmet>
                    <title>
                        {t('common:title.forgot_password_short')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(location.pathname, {}, true)}
                    />
                </Helmet>
                {this.renderForgotPasswordForm()}
            </TemplateLogin>
        );
    }
}

ForgotPasswordPage.propTypes = {
    // props
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
    emailSent: PropTypes.bool,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    // events
    resetPassword: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { isLoading, emailSent, errors } = state.pageForgotPassword;

    return {
        errors,
        isLoading,
        emailSent,
    };
};

const ForgotPasswordPageState = connect(mapStateToProps, {
    resetPassword,
})(ForgotPasswordPage);

const ForgotPasswordPageTranslated = withTranslation()(ForgotPasswordPageState);
const ForgotPasswordPageRouter = withRouter(ForgotPasswordPageTranslated);
const ForgotPasswordPageStyled = withStyles(useStyles)(ForgotPasswordPageRouter);

export { ForgotPasswordPageStyled as ForgotPasswordPage };
