import {
    ADMIN_COURSES_PAGE_LOADING,
    ADMIN_COURSES_PAGE_LOADING_SUCCESS,
    ADMIN_COURSES_PAGE_LOADING_FAILED,
    ADMIN_COURSES_ACTIONS_LOADING,
    ADMIN_COURSES_ACTIONS_LOADING_SUCCESS,
    ADMIN_COURSES_ACTIONS_LOADING_FAILED,
    ADMIN_COURSES_RESET_CREATED,
    ADMIN_COURSES_RESET_CHANGED,
    ADMIN_COURSES_RESET_DELETED,
} from './actionTypes';
import {
    ADMIN_COURSE_ADD_SUCCESS,
    ADMIN_COURSE_EDIT_SUCCESS,
} from '../../pages/AdminCourseAddEdit/actionTypes';
import { ADMIN_COURSE_DELETE_SUCCESS } from '../Popups/PopupMenuAdminCourse/actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    courses: [],
    totalCourses: 0,
    totalPages: 0,
    currentPage: 1,

    isLoadingActions: false,
    actions: [],
    errorActions: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_COURSES_PAGE_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ADMIN_COURSES_PAGE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                courses: action.payload.results,
                totalCourses: action.payload.count,
                totalPages: action.payload.total_pages,
                currentPage: action.payload.current_page,
            };
        case ADMIN_COURSES_PAGE_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case ADMIN_COURSES_ACTIONS_LOADING:
            return {
                ...state,
                isLoadingActions: true,
            };
        case ADMIN_COURSES_ACTIONS_LOADING_SUCCESS:
            return {
                ...state,
                isLoadingActions: false,
                actions: action.payload,
            };
        case ADMIN_COURSES_ACTIONS_LOADING_FAILED:
            return {
                ...state,
                isLoadingActions: false,
                errorActions: action.payload,
            };

        case ADMIN_COURSES_RESET_CHANGED:
            return {
                ...state,
                isChanged: false,
            };
        case ADMIN_COURSES_RESET_CREATED:
            return {
                ...state,
                isCreated: false,
            };
        case ADMIN_COURSES_RESET_DELETED:
            return {
                ...state,
                isDeleted: false,
            };

        case ADMIN_COURSE_ADD_SUCCESS:
            return {
                ...state,
                isCreated: true,
            };
        case ADMIN_COURSE_EDIT_SUCCESS:
            return {
                ...state,
                isChanged: true,
            };
        case ADMIN_COURSE_DELETE_SUCCESS:
            return {
                ...state,
                isDeleted: true,
            };

        default:
            return state;
    }
};
