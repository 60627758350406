import {
    listAdminEvents,
    listAdminEventListActions,
} from 'services/EventService';

import {
    EVENTS_LOADING,
    EVENTS_LOADING_SUCCESS,
    EVENTS_LOADING_FAILED,
    EVENT_RESET_CHANGED,
    EVENT_RESET_CREATED,
    EVENT_RESET_DELETED,
    EVENTS_ACTIONS_LOADING,
    EVENTS_ACTIONS_SUCCESS,
    EVENTS_ACTIONS_FAILED,
} from './actionTypes';

export const listEvents = ({ page = 1, status = undefined }) => {
    let newStatus = status;

    if (newStatus === 'all') {
        newStatus = undefined;
    }

    return (dispatch) => {
        dispatch({ type: EVENTS_LOADING });

        listAdminEvents({ page, status: newStatus })
            .then((result) => {
                dispatch({
                    type: EVENTS_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: EVENTS_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const resetCreated = () => {
    return (dispatch) => {
        dispatch({ type: EVENT_RESET_CREATED });
    };
};

export const resetChanged = () => {
    return (dispatch) => {
        dispatch({ type: EVENT_RESET_CHANGED });
    };
};

export const resetDeleted = () => {
    return (dispatch) => {
        dispatch({ type: EVENT_RESET_DELETED });
    };
};

export const listEventListActions = () => {
    return (dispatch) => {
        dispatch({ type: EVENTS_ACTIONS_LOADING });

        listAdminEventListActions()
            .then((result) => {
                dispatch({
                    type: EVENTS_ACTIONS_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: EVENTS_ACTIONS_FAILED,
                    payload: error,
                });
            });
    };
};
