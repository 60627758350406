import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { dynamicInjectReducer } from '../../../reducers/InjectReducer';
import Reducer from './reducer';
import PropTypes from 'prop-types';
import EventService from 'lib/EventService';
import { EVENT } from 'enums';

import {
    AppBar,
    Card,
    CardContent,
    Container,
    Dialog,
    Grid,
    IconButton,
    Slide,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Close as CloseIcon } from '@material-ui/icons';
import { DialogCourse, DialogStartNumber, DialogBasketSubscriptions } from '..';
import { Toast, Button } from '../..';
import { zeroPad } from 'utils';

import {
    addBasketSubscription,
    addBasketSubscriptionAdmin,
    editBasketSubscription,
    editBasketSubscriptionAdmin,
    editSubscriptionAdmin,
    addSubscriptionLock,
    deleteSubscriptionLock,
} from './actions';
import SubscriptionForm from './form';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme) => ({
    appbar: {
        backgroundColor: theme.colors.white,
        color: theme.colors.mikado,
        position: 'fixed',

        '& .MuiPaper-root.MuiAppBar-root': {
            backgroundColor: theme.colors.white,
            color: theme.colors.mikado,
        },
    },
    dialog: {
        backgroundColor: theme.colors.springWood,
        color: theme.colors.mikado,
    },
    middle: {
        alignItems: 'center',
        minHeight: '100%',
        padding: '48px 0',
    },
    alert: {
        marginTop: '32px',
    },
    textRight: {
        textAlign: 'right',

        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
        },
    },
});

class DialogSubscription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showNewRider: false,
            showNewHorse: false,
            dialogCourseOpen: false,
            dialogStartNumberOpen: false,
            dialogBasketSubscriptionsOpen: false,
            course: props.course,
            startNumber: props.startNumber,
            autoFillSubscription: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNewHorse = this.handleNewHorse.bind(this);
        this.handleNewRider = this.handleNewRider.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeCourse = this.handleChangeCourse.bind(this);
        this.handleChangeStartNumber = this.handleChangeStartNumber.bind(this);
        this.handleDialogCourseClose = this.handleDialogCourseClose.bind(this);
        this.handleDialogCourseSelect = this.handleDialogCourseSelect.bind(
            this
        );
        this.handleDialogStartNumberClose = this.handleDialogStartNumberClose.bind(
            this
        );
        this.handleDialogStartNumberSelect = this.handleDialogStartNumberSelect.bind(
            this
        );
        this.handleDialogBasketSubscriptionsClose = this.handleDialogBasketSubscriptionsClose.bind(
            this
        );
        this.handleDialogBasketSubscriptionsSelect = this.handleDialogBasketSubscriptionsSelect.bind(
            this
        );
        this.handleDialogBasketSubscriptionsOpen = this.handleDialogBasketSubscriptionsOpen.bind(
            this
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.newSubscription !== this.props.newSubscription) {
            this.handleClose();
        }

        if (prevProps.course !== this.props.course) {
            this.setState({
                course: this.props.course,
            });
        }

        if (!prevProps.startNumber && this.props.startNumber) {
            this.setState({
                startNumber: this.props.startNumber,
            });
        }

        if (prevProps.open !== this.props.open && this.props.open) {
            this.props.addSubscriptionLock({
                course: this.props.course.id,
                startNumber: this.props.startNumber,
            });
        }
    }

    componentWillUnmount() {
        // when unmounting, make sure it's closed
        // this.handleClose();
    }

    handleNewRider() {
        this.setState({
            showNewRider: true,
        });
    }

    handleNewHorse() {
        this.setState({
            showNewHorse: true,
        });
    }

    handleDialogCourseSelect(course) {
        this.setState({
            course: course,
            startNumber: '--',
        });
    }

    handleDialogCourseClose() {
        this.setState({
            dialogCourseOpen: false,
        });
    }

    handleDialogStartNumberSelect(startNumber) {
        this.setState({
            startNumber: startNumber,
        });

        // update reserve start number
        this.props.addSubscriptionLock({
            course: this.state.course.id,
            startNumber: this.state.startNumber,
        });
    }

    handleDialogStartNumberClose() {
        this.setState({
            dialogStartNumberOpen: false,
        });
    }

    handleChangeCourse(event) {
        event.preventDefault();

        this.setState({
            dialogCourseOpen: true,
        });
    }

    handleChangeStartNumber(event) {
        event.preventDefault();

        this.setState({
            dialogStartNumberOpen: true,
        });
    }

    handleDialogBasketSubscriptionsOpen() {
        this.setState({
            dialogBasketSubscriptionsOpen: true,
        });
    }

    handleDialogBasketSubscriptionsClose() {
        this.setState({
            dialogBasketSubscriptionsOpen: false,
        });
    }

    handleDialogBasketSubscriptionsSelect(subscription) {
        this.setState({
            autoFillSubscription: subscription,
        });
    }

    handleSubmit(data) {
        const { subscription, admin, basketSubscriptions } = this.props;
        const combination = {
            first_name: data.first_name,
            last_name: data.last_name,
            horse_name: data.horse_name,
            pony: data.pony,
            country: data.country,
        };

        if (subscription?.combinations.length > 0) {
            combination.id = subscription.combinations[0].id;
        }

        const params = {
            course: this.state.course.id,
            start_number: this.state.startNumber,
            out_of_competition: data.out_of_competition,
            combinations: [combination],
        };

        if (!subscription) {
            if (admin) {
                this.props.addBasketSubscriptionAdmin(params);
            } else {
                this.props.addBasketSubscription(params);
            }
        } else if (admin) {
            // check if the subscription is in a basket
            if (
                basketSubscriptions &&
                _.find(basketSubscriptions, ['id', subscription.id])
            ) {
                // in a basket
                this.props.editBasketSubscriptionAdmin({
                    id: subscription.id,
                    ...params,
                });
            } else {
                // not in a basket
                this.props.editSubscriptionAdmin({
                    id: subscription.id,
                    ...params,
                });
            }
        } else {
            this.props.editBasketSubscription({
                id: subscription.id,
                ...params,
            });
        }
    }

    handleClose() {
        const { onClose, newSubscription, isDeletingLock } = this.props;

        // release start number
        if (this.props.lock && !isDeletingLock) {
            this.props.deleteSubscriptionLock({
                id: this.props.lock.id,
            });
        }

        if (onClose) {
            onClose(newSubscription);

            EventService.trigger(EVENT.SUBSCRIPTION_CHANGED);
        }
    }

    renderError() {
        const { errors, classes } = this.props;

        if (errors) {
            // scroll into view
            const anchor = document.querySelector('#card-header'); // eslint-disable-line no-undef

            if (anchor) {
                anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }

            if (errors.message || errors.non_field_errors) {
                return (
                    <Alert className={classes.alert} severity="error">
                        {errors.message || errors.non_field_errors}
                    </Alert>
                );
            }
        }
    }

    renderTopBar() {
        const { classes, t } = this.props;

        return (
            <AppBar className={classes.appbar}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={this.handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    {false && (
                        <Typography variant="h6" className={classes.title}>
                            {t('common:text.subscription_add')}
                        </Typography>
                    )}
                </Toolbar>
            </AppBar>
        );
    }

    renderAutoFill() {
        const { classes, t } = this.props;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    justify={'center'}
                    alignItems={'center'}
                >
                    <Grid item xs={12} lg={8}>
                        <Typography variant={'body1'}>
                            <strong>
                                {t('common:title.autofill_subscription')}
                            </strong>
                        </Typography>
                        <Typography variant={'body1'}>
                            {t('common:description.autofill_subscription')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4} className={classes.textRight}>
                        <Button
                            type={'text'}
                            onClick={this.handleDialogBasketSubscriptionsOpen}
                        >
                            {t('common:button.choose_subscription')}
                        </Button>
                    </Grid>
                </Grid>
                <div className="dashed-line" />
            </React.Fragment>
        );
    }

    renderContent() {
        const { classes, t, isLoading, subscription, errors } = this.props;
        const { course, startNumber } = this.state;
        // const { show_new_horse, show_new_rider } = this.state;

        return (
            <Container maxWidth={'lg'} className={classes.middle}>
                <Grid container justify="center" className={classes.middle}>
                    <Grid item xs={12} lg={8}>
                        <Card elevation={0} className="card subscription">
                            <CardContent>
                                <header id="card-header">
                                    <Typography variant={'h3'}>
                                        {subscription
                                            ? t(
                                                  'common:title.edit_subscription'
                                              )
                                            : t(
                                                  'common:title.add_subscription'
                                              )}
                                    </Typography>
                                    <p>
                                        {subscription
                                            ? t(
                                                  'common:text.fill_in_fields_subscription_edit'
                                              )
                                            : t(
                                                  'common:text.fill_in_fields_subscription'
                                              )}
                                    </p>

                                    {this.renderError()}
                                </header>

                                <div className="dashed-line" />

                                {errors && errors['combinations'] && (
                                    <Grid item md={12} xs={12}>
                                        <Alert
                                            severity="error"
                                            style={{ marginBottom: 32 }}
                                        >
                                            {errors['combinations'][0]}
                                        </Alert>
                                    </Grid>
                                )}

                                <Grid container spacing={3}>
                                    <Grid item sm={6} xs={12}>
                                        <div className="introStat">
                                            <strong className="label">
                                                {t('common:title.course')}
                                            </strong>
                                            <span className="value">
                                                {course.position}. {course.name}
                                            </span>
                                            <small>{course.height} cm</small>

                                            <Link
                                                onClick={
                                                    this.handleChangeCourse
                                                }
                                            >
                                                {t('common:link.change_course')}
                                            </Link>
                                        </div>
                                    </Grid>
                                    <Grid item sm={6} xs={12}>
                                        <div className="introStat">
                                            <strong className="label">
                                                {t('common:title.start_number')}
                                            </strong>
                                            <span className="value">
                                                {zeroPad(startNumber, 2)}.
                                            </span>
                                            <small />

                                            <Link
                                                onClick={
                                                    this.handleChangeStartNumber
                                                }
                                            >
                                                {t(
                                                    'common:link.change_start_number'
                                                )}
                                            </Link>
                                        </div>
                                    </Grid>
                                </Grid>

                                <div className="dashed-line" />

                                {this.renderAutoFill()}

                                <SubscriptionForm
                                    handleNewRider={this.handleNewRider}
                                    handleNewHorse={this.handleNewHorse}
                                    showNewRider
                                    showNewHorse
                                    onSubmit={this.handleSubmit}
                                    isLoading={isLoading}
                                    subscription={
                                        this.state.autoFillSubscription ||
                                        subscription
                                    }
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    renderDialogCourse() {
        const { course } = this.state;

        return (
            <DialogCourse
                open={this.state.dialogCourseOpen}
                onClose={this.handleDialogCourseClose}
                onSelect={this.handleDialogCourseSelect}
                course={course}
                admin={this.props.admin}
            />
        );
    }

    renderDialogStartNumber() {
        const { course } = this.state;

        return (
            <DialogStartNumber
                open={this.state.dialogStartNumberOpen}
                onClose={this.handleDialogStartNumberClose}
                onSelect={this.handleDialogStartNumberSelect}
                course={course}
            />
        );
    }

    renderDialogAutoFill() {
        return (
            <DialogBasketSubscriptions
                open={this.state.dialogBasketSubscriptionsOpen}
                onClose={this.handleDialogBasketSubscriptionsClose}
                onSelect={this.handleDialogBasketSubscriptionsSelect}
                admin
            />
        );
    }

    render() {
        const { classes, t, errors, open } = this.props;

        return (
            <Dialog
                fullScreen
                open={open}
                onClose={this.handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    classes: {
                        root: classes.dialog,
                    },
                }}
            >
                <Toast
                    message={
                        Boolean(errors) && errors['start_number']
                            ? errors['start_number']
                            : t('common:error.general.description')
                    }
                    open={Boolean(errors)}
                />
                {this.renderTopBar()}
                {this.renderContent()}
                {this.renderDialogCourse()}
                {this.renderDialogStartNumber()}
                {this.renderDialogAutoFill()}
            </Dialog>
        );
    }
}

DialogSubscription.propTypes = {
    // props
    admin: PropTypes.bool,
    basketSubscriptions: PropTypes.object,
    classes: PropTypes.object,
    course: PropTypes.object.isRequired,
    errors: PropTypes.object,
    isDeletingLock: PropTypes.bool,
    isLoading: PropTypes.bool,
    lock: PropTypes.bool,
    newSubscription: PropTypes.object,
    open: PropTypes.bool,
    startNumber: PropTypes.string.isRequired,
    subscription: PropTypes.object,
    // handlers
    onClose: PropTypes.func.isRequired,
    addSubscriptionLock: PropTypes.func.isRequired,
    addBasketSubscriptionAdmin: PropTypes.func.isRequired,
    addBasketSubscription: PropTypes.func.isRequired,
    editBasketSubscriptionAdmin: PropTypes.func.isRequired,
    editBasketSubscription: PropTypes.func.isRequired,
    editSubscriptionAdmin: PropTypes.func.isRequired,
    deleteSubscriptionLock: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

DialogSubscription.defaultProps = {
    open: false,
    admin: false,
};

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.reducerKey || !state[ownProps.reducerKey]) {
        return {};
    }

    const {
        isLoading,
        errors,
        newSubscription,

        isLoadingLock,
        isDeletingLock,
        isLocked,
        lockError,
        lock,
    } = state[ownProps.reducerKey];
    const { subscriptions } = state.shoppingCart;

    return {
        isLoading: isLoading || isLoadingLock,
        errors,
        newSubscription,
        basketSubscriptions: subscriptions,
        isLoadingLock,
        isDeletingLock,
        isLocked,
        lockError,
        lock,
        key: ownProps.reducerKey,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        addBasketSubscription: ({ ...args }) =>
            dispatch(
                addBasketSubscription({ ...args, key: ownProps.reducerKey })
            ),
        addBasketSubscriptionAdmin: ({ ...args }) =>
            dispatch(
                addBasketSubscriptionAdmin({
                    ...args,
                    key: ownProps.reducerKey,
                })
            ),
        editBasketSubscription: ({ ...args }) =>
            dispatch(
                editBasketSubscription({ ...args, key: ownProps.reducerKey })
            ),
        editBasketSubscriptionAdmin: ({ ...args }) =>
            dispatch(
                editBasketSubscriptionAdmin({
                    ...args,
                    key: ownProps.reducerKey,
                })
            ),
        editSubscriptionAdmin: ({ ...args }) =>
            dispatch(
                editSubscriptionAdmin({ ...args, key: ownProps.reducerKey })
            ),
        addSubscriptionLock: ({ ...args }) =>
            dispatch(
                addSubscriptionLock({ ...args, key: ownProps.reducerKey })
            ),
        deleteSubscriptionLock: ({ ...args }) =>
            dispatch(
                deleteSubscriptionLock({ ...args, key: ownProps.reducerKey })
            ),
    };
};

const DialogSubscriptionTranslated = withTranslation()(DialogSubscription);
const DialogSubscriptionStyled = withStyles(useStyles)(
    DialogSubscriptionTranslated
);
const DialogSubscriptionState = connect(
    mapStateToProps,
    mapDispatchToProps
)(DialogSubscriptionStyled);
const DialogSubscriptionReduced = dynamicInjectReducer({
    key: `dialogSubscription`,
    reducer: Reducer,
})(DialogSubscriptionState);

export { DialogSubscriptionReduced as DialogSubscription };
