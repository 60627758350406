import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Grid, MenuItem } from '@material-ui/core';

import { Select } from 'components';

const useStyles = makeStyles((theme) => ({
    noMargin: {
        marginBottom: '0 !important',
    },
}));

function FilterForm(props) {
    const { control, setValue, register } = useForm({
        defaultValues: { status: 'all' },
    });
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <form>
            <Grid container>
                <Grid item xs={12}>
                    <Select
                        label={t('forms:label.payment_status')}
                        name="status"
                        control={control}
                        setValue={setValue}
                        className={classes.noMargin}
                        onChange={props.onPaymentStatusChange}
                        ref={register}
                    >
                        <MenuItem value={'all'}>
                            {t('forms:select.status.order.all')}
                        </MenuItem>
                        <MenuItem value={'canceled'}>
                            {t('forms:select.status.order.canceled')}
                        </MenuItem>
                        <MenuItem value={'draft'}>
                            {t('forms:select.status.order.draft')}
                        </MenuItem>
                        <MenuItem value={'paid'}>
                            {t('forms:select.status.order.paid')}
                        </MenuItem>
                        <MenuItem value={'partially-paid'}>
                            {t('forms:select.status.order.partially-paid')}
                        </MenuItem>
                        <MenuItem value={'not-paid'}>
                            {t('forms:select.status.order.not-paid')}
                        </MenuItem>
                        <MenuItem value={'payment-pending'}>
                            {t('forms:select.status.order.payment-pending')}
                        </MenuItem>
                        <MenuItem value={'payment-local'}>
                            {t('forms:select.status.order.payment-local')}
                        </MenuItem>
                        <MenuItem value={'payment-failed'}>
                            {t('forms:select.status.order.payment-failed')}
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </form>
    );
}

FilterForm.propTypes = {
    onPaymentStatusChange: PropTypes.func.isRequired,
};

export { FilterForm };
