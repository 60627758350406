import React from 'react';
import _ from 'lodash';
import fetchApi from './services/api';
import moment from 'moment';

const object_cache = {};
const notifier_cache = {};

export function randomEventImage() {
    const index = Math.ceil(Math.random() * Math.floor(8));

    return {
        '@1x': require(`./assets/images/bg-default-${index}.png`),
        '@2x': require(`./assets/images/bg-default-${index}@2x.png`),
        '@3x': require(`./assets/images/bg-default-${index}@3x.png`),
    };
}

export function renderAmount(amount) {
    if (amount === undefined) {
        return amount;
    }

    // if amount is negative, place minus sign correctly
    const obj = parseFloat(amount);

    if (obj < 0) {
        return <span>-&nbsp;&euro;&nbsp;{obj.toFixed(2).substring(1)}</span>;
    }

    return <span>&euro;&nbsp;{obj.toFixed(2)}</span>;
}

export function zeroPad(num, places) {
    return String(num).padStart(places, '0');
}

export function scrollIntoView(element) {
    const anchor = document.querySelector(element);

    if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
}

export function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16);
    var g = parseInt(hex.slice(3, 5), 16);
    var b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
}

export function getObjectFromCache({ url, success, error }) {
    const result = object_cache[url];

    if (!result) {
        object_cache[url] = 'waiting'; // set temp status

        // call the api
        fetchApi({
            url: url,
            method: 'GET',
        })
            .then((result) => {
                object_cache[url] = result;
                success(result);

                // let all watchers know
                for (let key in notifier_cache[url]) {
                    notifier_cache[url][key]['success'](result);
                }

                // delete it
                delete notifier_cache[url];
            })
            .catch((result) => {
                error(result);

                // let all watchers know
                for (let key in notifier_cache[url]) {
                    notifier_cache[url][key]['error'](result);
                }

                // delete it
                delete notifier_cache[url];
            });
    } else if (result === 'waiting') {
        // waiting for result
        if (!notifier_cache[url]) {
            notifier_cache[url] = [];
        }
        // add to watch list
        notifier_cache[url].push({
            success: success,
            error: error,
        });
    } else {
        success(object_cache[url]);
    }
}

export function getEventDays(days) {
    if (!days || days.length <= 0) {
        return;
    }

    // if just one day
    if (days.length === 1) {
        const dateStart = days[0].date_start_at;
        const dateEnd = days[0].date_end_at;
        const timeStart = days[0].time_start_at;
        const timeEnd = days[0].time_end_at;

        if (dateStart === dateEnd) {
            // Maandag 4 september 2020 12:00 - 13:00
            const dateObj = moment(dateStart, 'YYYY/MM/DD');
            return {
                days: [
                    {
                        day: dateObj,
                        time_from: timeStart,
                        time_to: timeEnd,
                    },
                ],
                text: `${dateObj.format('dddd LL')} ${timeStart.slice(
                    0,
                    -3
                )} - ${timeEnd.slice(0, -3)}`,
            };
        } else {
            // 4 september 2020 12:00 - 5 september 2020 13:00
            const dateObjStart = moment(dateStart, 'YYYY/MM/DD');
            const dateObjEnd = moment(dateEnd, 'YYYY/MM/DD');

            let returnedDays = [];
            let counter = 0;

            for (
                var m = dateObjStart;
                m.isBefore(dateObjEnd) || m.isSame(dateObjEnd);
                m.add(1, 'days')
            ) {
                returnedDays.push({
                    day: moment(m),
                    time_from: counter === 0 ? timeStart.slice(0, -3) : '00:00',
                    time_to: '24:00',
                });
                counter += 1;
            }

            // update last day
            returnedDays[days.length]['time_to'] = timeEnd.slice(0, -3);

            return {
                days: returnedDays,
                text: `${dateObjStart.format(
                    'LL'
                )} ${timeStart} - ${dateObjEnd.format('LL')} ${timeEnd}`,
            };
        }
    }

    // if multiple days, return pe 1 & 2 march 2020
    if (days.length > 1) {
        const dateStart = days[0].date_start_at;
        const dateEnd = days[days.length - 1].date_end_at;
        const timeStart = days[0].time_start_at;
        const timeEnd = days[days.length - 1].time_end_at;
        const dateObjStart = moment(dateStart, 'YYYY/MM/DD');
        const dateObjEnd = moment(dateEnd, 'YYYY/MM/DD');
        let returnedDays = [];

        for (var l = 0; l < days.length; l += 1) {
            let tempDateStart = days[l].date_start_at;
            let tempDateEnd = days[l].date_end_at;

            if (tempDateStart === tempDateEnd) {
                // Maandag 4 september 2020 12:00 - 13:00
                const dateObj = moment(tempDateStart, 'YYYY/MM/DD');
                returnedDays.push({
                    day: dateObj,
                    time_from: days[l].time_start_at.slice(0, -3),
                    time_to: days[l].time_end_at.slice(0, -3),
                });
            } else {
                // 4 september 2020 12:00 - 5 september 2020 13:00
                const dateObjStart = moment(tempDateStart, 'YYYY/MM/DD');
                const dateObjEnd = moment(tempDateEnd, 'YYYY/MM/DD');

                let tempDays = [];
                let counter = 0;

                for (
                    var p = dateObjStart;
                    p.isBefore(dateObjEnd);
                    p.add(1, 'days')
                ) {
                    tempDays.push({
                        day: p,
                        time_from:
                            counter === 0
                                ? days[l].time_start_at.slice(0, -3)
                                : '00:00',
                        time_to: '24:00',
                    });
                }

                // update last day
                tempDays[days.length - 1]['time_to'] = days[
                    l
                ].time_end_at.slice(0, -3);

                returnedDays.push(tempDays);
            }
        }

        return {
            days: returnedDays,
            text: `${dateObjStart.format(
                'LL'
            )} ${timeStart} - ${dateObjEnd.format('LL')} ${timeEnd}`,
        };
    }
}

export function groupSubscriptionsByCourse(subscriptions) {
    //
    // Group subscriptions by the course and calculate the amount.
    //
    return _.reduce(
        subscriptions,
        (result, value) => {
            if (!result[value.course]) {
                result[value.course] = {
                    total_amount: 0,
                    subscriptions: [],
                    event: null,
                    club: null,
                    course_object: null,
                    event_object: null,
                };
            }
            result[value.course]['total_amount'] += Number(value.total_amount);
            result[value.course]['subscriptions'].push(value);
            result[value.course]['event'] = value.event;
            result[value.course]['club'] = value.club;
            result[value.course]['course_object'] = value.course_object;
            result[value.course]['event_object'] = value.event_object;
            return result;
        },
        {}
    );
}

export function getIdFromUrl(url) {
    //
    // Retrieve the ID from the URL.
    // https://api.equento.be/admin/events/12/ will return 12.
    //
    if (!url) {
        return;
    }

    let splitted = url.split('/');
    // filter out empty strings
    splitted = splitted.filter(Boolean);

    // return last item, it's the id
    return splitted[splitted.length - 1];
}

export function buildStartList({ subscriptions, max }) {
    // build subscriptions
    const newSubscriptions = [];

    for (let i = 1; i <= max; i++) {
        newSubscriptions[i] = null;
    }

    // fill in existing subscriptions
    if (subscriptions) {
        subscriptions.map((obj) => {
            return (newSubscriptions[obj.start_number] = obj);
        });
    }

    return newSubscriptions;
}

export function stripSeconds(timeObj) {
    if (typeof timeObj === 'string') {
        const splitted = timeObj.split(':');
        if (splitted.length >= 3) {
            // remove last element => seconds
            splitted.splice(-1, 1);
        }

        return splitted.join(':');
    }

    return timeObj;
}

export const AdminContext = React.createContext({
    adminTitle: null,
    adminBackAction: null,
    setAdminTitle: () => {},
    setAdminBackAction: () => {},
});

export function getCountryImage(countryCode) {
    let countryImage = null;

    try {
        countryImage = require(`./assets/images/icons/country/${countryCode.toLowerCase()}.svg`);
    } catch {
        countryImage = require(`./assets/images/icons/country/default.svg`);
    }

    return countryImage;
}

export function getAuthorizationsKey(id, contentType) {
    return contentType + '-' + id;
}
