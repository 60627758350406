export const ADMIN_COMBINATION_CONFIRM_LOADING =
    'admin_combination_confirm_loading';
export const ADMIN_COMBINATION_CONFIRM_SUCCESS =
    'admin_combination_confirm_success';
export const ADMIN_COMBINATION_CONFIRM_FAILED =
    'admin_combination_confirm_failed';

export const ADMIN_COMBINATION_UNCONFIRM_LOADING =
    'admin_combination_unconfirm_loading';
export const ADMIN_COMBINATION_UNCONFIRM_SUCCESS =
    'admin_combination_unconfirm_success';
export const ADMIN_COMBINATION_UNCONFIRM_FAILED =
    'admin_combination_unconfirm_failed';

export const ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_LOADING =
    'admin_subscription_mark_not_entered_loading';
export const ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_SUCCESS =
    'admin_subscription_mark_not_entered_success';
export const ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_FAILED =
    'admin_subscription_mark_not_entered_failed';

export const ADMIN_SUBSCRIPTION_MARK_ENTERED_LOADING =
    'admin_subscription_mark_entered_loading';
export const ADMIN_SUBSCRIPTION_MARK_ENTERED_SUCCESS =
    'admin_subscription_mark_entered_success';
export const ADMIN_SUBSCRIPTION_MARK_ENTERED_FAILED =
    'admin_subscription_mark_entered_failed';

export const SUBSCRIPTION_SET_TO_STATE = 'admin_subscription_set_to_state';
