import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import {
    Container,
    Collapse,
    Grid,
    IconButton,
    Hidden,
} from '@material-ui/core';
import {
    Close as CloseIcon,
    Facebook as FacebookIcon,
    Instagram as InstagramIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { LanguageSelector } from '../LanguageSelector';

import './styles.scss';

import PropTypes from 'prop-types';

import iconBancontact from 'assets/images/icons/payment/bancontact.svg';
import iconING from 'assets/images/icons/payment/ing.svg';
import iconVISA from 'assets/images/icons/payment/visa.svg';
import iconMastercard from 'assets/images/icons/payment/mastercard.svg';
import iconBelfius from 'assets/images/icons/payment/belfius.svg';
import iconKBC from 'assets/images/icons/payment/kbc.svg';
import iconiDeal from 'assets/images/icons/payment/ideal.svg';
// import icon_sepa from 'assets/images/icons/payment/sepa.svg';
import logo from 'assets/images/equento-logo.svg';

const useStyles = (theme) => ({
    center: {
        textAlign: 'center',
    },
    menu: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
});

class Footer extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            productShown: false,
            socialShown: false,
            legalShown: false,
        };

        // event handlers
        this.handleProductClick = this.handleProductClick.bind(this);
        this.handleSocialClick = this.handleSocialClick.bind(this);
        this.handleLegalClick = this.handleLegalClick.bind(this);
    }

    handleProductClick(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            productShown: !this.state.productShown,
        });
    }

    handleSocialClick(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            socialShown: !this.state.socialShown,
        });
    }

    handleLegalClick(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            legalShown: !this.state.legalShown,
        });
    }

    render() {
        const { t, small, classes } = this.props;

        if (small) {
            return (
                <div className="footer small">
                    <small>
                        &copy; 2020. {t('common:text.all-rights-reserved')}
                    </small>
                </div>
            );
        }

        return (
            <div className="footer">
                <Container maxWidth={'lg'}>
                    <Grid container spacing={2} display="flex" justify="center">
                        <Grid item xs={12} className={classes.center}>
                            <small>{t('common:text.safe_payment')}</small>
                            <ul className={'payment-icons'}>
                                <li>
                                    <img
                                        src={iconBancontact}
                                        alt="Bancontact"
                                    />
                                </li>
                                <li>
                                    <img src={iconING} alt="ING" />
                                </li>
                                <li>
                                    <img src={iconBelfius} alt="Belfius" />
                                </li>
                                <li>
                                    <img src={iconKBC} alt="KBC" />
                                </li>
                                <li>
                                    <img src={iconiDeal} alt="iDeal" />
                                </li>
                                <li>
                                    <img src={iconVISA} alt="VISA" />
                                </li>
                                <li>
                                    <img
                                        src={iconMastercard}
                                        alt="MasterCard"
                                    />
                                </li>
                            </ul>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <img src={logo} alt="Equento" />

                            <p className={'description'}>
                                {t('common:text.equento_description')}
                            </p>

                            <Hidden mdDown>
                                <small>
                                    &copy; 2020.{' '}
                                    {t('common:text.all-rights-reserved')}{' '}
                                    <a
                                        href="https://www.selentic.net"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Selentic WebDesign
                                    </a>
                                </small>
                            </Hidden>
                        </Grid>
                        <Grid item lg={6} xs={12} className={classes.menu}>
                            <Grid item lg={3} xs={12}>
                                <nav
                                    className={classNames(
                                        this.state.productShown && 'open'
                                    )}
                                >
                                    <Link onClick={this.handleProductClick}>
                                        <strong>
                                            {t('common:text.menu.product')}
                                        </strong>

                                        <IconButton size={'small'}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Link>
                                    <Collapse in={this.state.productShown}>
                                        <ul>
                                            <li>
                                                <Link to="/events">
                                                    {t(
                                                        'common:text.menu.events'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/organisers">
                                                    {t(
                                                        'common:text.menu.organisers'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/help">
                                                    {t('common:text.menu.help')}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/login">
                                                    {t(
                                                        'common:text.menu.login'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/register">
                                                    {t(
                                                        'common:text.menu.register'
                                                    )}
                                                </Link>
                                            </li>
                                        </ul>
                                    </Collapse>
                                </nav>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <nav
                                    className={classNames(
                                        this.state.socialShown && 'open'
                                    )}
                                >
                                    <Link onClick={this.handleSocialClick}>
                                        <strong>
                                            {t('common:text.menu.follow_us')}
                                        </strong>

                                        <IconButton size={'small'}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Link>
                                    <Collapse in={this.state.socialShown}>
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://facebook.com/equento.be"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <FacebookIcon /> Facebook
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://instagram.com/equento.be"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <InstagramIcon /> Instagram
                                                </a>
                                            </li>
                                        </ul>
                                    </Collapse>
                                </nav>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <nav
                                    className={classNames(
                                        this.state.legalShown && 'open'
                                    )}
                                >
                                    <Link onClick={this.handleLegalClick}>
                                        <strong>
                                            {t('common:text.menu.legal')}
                                        </strong>

                                        <IconButton size={'small'}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Link>
                                    <Collapse in={this.state.legalShown}>
                                        <ul>
                                            <li>
                                                <Link to="/privacy">
                                                    {t(
                                                        'common:text.menu.privacy_policy'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/cookie">
                                                    {t(
                                                        'common:text.menu.cookie_policy'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/disclaimer">
                                                    {t(
                                                        'common:text.menu.disclaimer'
                                                    )}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/terms">
                                                    {t(
                                                        'common:text.menu.terms'
                                                    )}
                                                </Link>
                                            </li>
                                        </ul>
                                    </Collapse>
                                </nav>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} xs={12}>
                            <LanguageSelector />
                        </Grid>
                        <Hidden lgUp>
                            <Grid item xs={12}>
                                <small>
                                    &copy; 2020.{' '}
                                    {t('common:text.all-rights-reserved')}{' '}
                                    <a
                                        href="https://www.selentic.net"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Selentic WebDesign
                                    </a>
                                </small>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </div>
        );
    }
}

Footer.propTypes = {
    small: PropTypes.bool,
};

const FooterTranslated = withTranslation()(Footer);
const FooterStyled = withStyles(useStyles)(FooterTranslated);
export { FooterStyled as Footer };
