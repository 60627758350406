import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';

import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dialog } from 'components';
import { ProfileForm } from './form';

const useStyles = (theme) => ({
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    contentText: {
        color: theme.colors.mikado,
        marginTop: '15px',
        textAlign: 'center',
    },
    alert: {
        marginBottom: theme.spacing(3),
        width: '100%',
    },
});

class DialogProfile extends Component {
    render() {
        const { onClose, open, t, classes } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={t('common:title.change_profile')}
                smallFooter
                showLine
                noFooter
            >
                <Grid container>
                    {this.props.errors &&
                        Object.keys(this.props.errors).length > 0 && (
                            <Alert severity={'error'} className={classes.alert}>
                                {this.props.errors['non_field_errors'] ||
                                    t('common:error.general.title')}
                            </Alert>
                        )}

                    <ProfileForm
                        data={this.props.data}
                        action={this.props.action}
                        isLoading={this.props.isLoading}
                        errors={this.props.errors}
                    />
                </Grid>
            </Dialog>
        );
    }
}

DialogProfile.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
    t: PropTypes.func.isRequired,
};

DialogProfile.defaultProps = {
    open: false,
};

const DialogProfileStyled = withStyles(useStyles)(DialogProfile);
const DialogProfileTranslated = withTranslation()(DialogProfileStyled);

export { DialogProfileTranslated as DialogProfile };
