import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Select, Checkbox } from 'components';
import { MenuItem, Grid } from '@material-ui/core';

function ExportForm(props) {
    const { t } = useTranslation();

    const defaultValues = _.reduce(
        props.data,
        (result, value, key) => {
            result[key] = value['value'];
            return result;
        },
        {}
    );

    const { register, handleSubmit, control, setValue } = useForm({
        defaultValues: defaultValues,
    });

    if (props.defaultType) {
        // register the existence of the field
        register('fileType');
        // set the variable
        setValue('fileType', props.defaultType);
    }

    return (
        <form onSubmit={handleSubmit(props.action)} style={{ width: '100%' }}>
            <Grid container spacing={3}>
                {props.showType && (
                    <Grid item xs={12}>
                        <Select
                            label={t('forms:label.file_type')}
                            name="fileType"
                            required
                            control={control}
                            setValue={setValue}
                            // onChange={props.onStatusChange}
                            ref={register}
                        >
                            <MenuItem value={'csv'}>
                                {t('forms:select.file_type.csv')}
                            </MenuItem>
                            <MenuItem value={'excel'}>
                                {t('forms:select.file_type.excel')}
                            </MenuItem>
                        </Select>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Checkbox
                        label={t('forms:label.show_unpaid')}
                        name="showUnpaid"
                        ref={register()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color={'secondary'}
                        isLoading={props.isLoading}
                        type={'submit'}
                    >
                        {t('forms:button.export')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

ExportForm.propTypes = {
    data: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
    showType: PropTypes.bool,
    defaultType: PropTypes.oneOf(['csv', 'excel', 'receipts', 'start-list']),
};

export { ExportForm };
