import {
    ORDERS_PAGE_LOADING,
    ORDERS_PAGE_LOADING_SUCCESS,
    ORDERS_PAGE_LOADING_FAILED,
} from "./actionTypes";

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    orders: [],
    totalOrders: 0,
    totalPages: 0,
    currentPage: 1,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ORDERS_PAGE_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case ORDERS_PAGE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                orders: action.payload.results,
                totalOrders: action.payload.count,
                totalPages: action.payload.total_pages,
                currentPage: action.payload.current_page,
            };
        case ORDERS_PAGE_LOADING_FAILED:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
