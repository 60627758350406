import format from "string-format";
import fetchApi from "./api";
import apiConfig from "./api/config";

const publicEndPoints = {
    addSubscription: "/v1/basket/subscriptions/",
    editSubscription: "/v1/basket/subscriptions/{id}/",
    deleteSubscription: "/v1/basket/subscriptions/{id}/",
    listSubscriptions: "/v1/basket/subscriptions/",
    currentBasket: "/v1/orders/current/",
    payOrder: "/v1/orders/{id}/pay/",
};

const adminEndPoints = {
    addSubscription: "/v1/admin/basket/subscriptions/",
    editSubscription: "/v1/admin/basket/subscriptions/{id}/",
    deleteSubscription: "/v1/admin/basket/subscriptions/{id}/",
    currentBasket: "/v1/admin/orders/current/",
    payOrder: "/v1/admin/orders/{id}/pay/",
};

export const currentBasket = () =>
    fetchApi({
        url: publicEndPoints.currentBasket,
        method: "GET",
    });

export const currentAdminBasket = () =>
    fetchApi({
        url: adminEndPoints.currentBasket,
        method: "GET",
    });

export const listSubscriptions = () =>
    fetchApi({
        url: publicEndPoints.listSubscriptions,
        method: "GET",
    });

export const addSubscriptionBasket = ({
    course,
    start_number,
    out_of_competition,
    combinations,
}) =>
    fetchApi({
        url: publicEndPoints.addSubscription,
        data: {
            course,
            start_number,
            out_of_competition,
            combinations,
        },
        method: "POST",
    });

export const addSubscriptionBasketAdmin = ({
    course,
    start_number,
    out_of_competition,
    combinations,
}) =>
    fetchApi({
        url: adminEndPoints.addSubscription,
        data: {
            course,
            start_number,
            out_of_competition,
            combinations,
        },
        method: "POST",
    });

export const editSubscriptionBasket = ({
    id,
    course,
    start_number,
    out_of_competition,
    combinations,
}) =>
    fetchApi({
        url: format(publicEndPoints.editSubscription, { id }),
        data: {
            course,
            start_number,
            out_of_competition,
            combinations,
        },
        method: "PATCH",
    });

export const editSubscriptionBasketAdmin = ({
    id,
    course,
    start_number,
    out_of_competition,
    combinations,
}) =>
    fetchApi({
        url: format(adminEndPoints.editSubscription, { id }),
        data: {
            course,
            start_number,
            out_of_competition,
            combinations,
        },
        method: "PATCH",
    });

export const deleteSubscriptionBasket = (id) =>
    fetchApi({
        url: format(publicEndPoints.deleteSubscription, { id }),
        data: {},
        method: "DELETE",
    });

export const deleteSubscriptionBasketAdmin = (id) =>
    fetchApi({
        url: format(adminEndPoints.deleteSubscription, { id }),
        data: {},
        method: "DELETE",
    });

export const payOrder = (id, amount, email) =>
    fetchApi({
        url: format(publicEndPoints.payOrder, { id }),
        data: {
            redirect_url: `${apiConfig.baseSiteUrl}user/orders?order=${id}`,
            email,
            amount,
        },
        method: "POST",
    });

export const payAdminOrder = (id, method, amount) =>
    fetchApi({
        url: format(adminEndPoints.payOrder, { id }),
        data: {
            redirect_url: `${apiConfig.baseSiteUrl}admin/orders/${id}`,
            method,
            amount,
        },
        method: "POST",
    });
