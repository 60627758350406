import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import {
    Avatar,
    Card,
    CardActionArea,
    CardContent,
    MenuItem,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { DialogTicket, DialogQR, PopupMenu } from 'components';
import { getObjectFromCache } from 'utils';

const useStyles = (theme) => ({
    name: {
        display: 'flex',
        alignItems: 'center',

        '& .MuiAvatar-root': {
            marginRight: '10px',
        },
    },
});

class CardTicket extends Component {
    constructor(props) {
        super(props);

        // set state
        this.state = {
            dialogOpen: props.openDialog,
            dialogQROpen: props.openDialogQR,
            event_name: null,
            event: null,
        };

        // event handlers
        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
        this.handleDialogQROpen = this.handleDialogQROpen.bind(this);
        this.handleDialogQRClose = this.handleDialogQRClose.bind(this);
    }

    componentDidMount() {
        if (this.props.ticket) {
            if (!this.state.event_name) {
                getObjectFromCache({
                    url: this.props.ticket.event,
                    success: (result) => {
                        this.setState({
                            event_name: result['name'],
                            event: result,
                        });
                    },
                    error: () => {
                        // do something TODO
                    },
                });
            }
        }
    }

    getStyle(status) {
        if (status === 'payment-pending') {
            return 'warning';
        } else if (
            status === 'canceled' ||
            status === 'not-paid' ||
            status === 'partially-paid'
        ) {
            return 'error';
        } else if (status === 'paid') {
            return 'success';
        }

        return 'default';
    }

    handleDialogOpen(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            dialogOpen: true,
        });
    }

    handleDialogClose() {
        this.setState({
            dialogOpen: false,
        });

        const query = new URLSearchParams(this.props.location.search.slice(1)); // eslint-disable-line no-undef
        if (query.get('ticket')) {
            query.delete('ticket');

            if (query.toString() !== '') {
                this.props.history.push(
                    this.props.location.pathname + '?' + query.toString()
                );
            }
        }
    }

    handleDialogQROpen(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            dialogQROpen: true,
        });
    }

    handleDialogQRClose() {
        this.setState({
            dialogQROpen: false,
        });
    }

    renderLoading() {
        return (
            <Card className="ticket-card" elevation={0}>
                <CardContent>
                    <div className="orderId">
                        <Skeleton variant="rect" height={20} width={50} />
                    </div>
                    <div className="event">
                        <Skeleton variant="rect" height={20} width={100} />
                    </div>
                    <div className="subscriptions">
                        <Skeleton variant="rect" height={20} width={100} />
                    </div>
                    <div className="creationDate">
                        <Skeleton variant="rect" height={20} width={100} />
                    </div>
                    <div className="actions" />
                </CardContent>
            </Card>
        );
    }

    renderEvent() {
        const { admin, classes, ticket } = this.props;

        if (admin) {
            return ticket && ticket.user ? (
                <div className={classes.name}>
                    <Avatar variant={'rounded'}>
                        {ticket.user.first_name[0]}
                        {ticket.user.last_name[0]}
                    </Avatar>
                    <strong>
                        {ticket.user.first_name} {ticket.user.last_name}
                    </strong>
                </div>
            ) : (
                <Skeleton height={22} width={'80%'} />
            );
        }

        return this.state.event_name ? (
            <strong>{this.state.event_name}</strong>
        ) : (
            <Skeleton height={22} width={'80%'} />
        );
    }

    renderCard() {
        const { ticket, t, onClick } = this.props;
        const count = ticket.subscriptions.length;

        return (
            <Card className="ticket-card" elevation={0}>
                <CardActionArea
                    onClick={onClick ? onClick : this.handleDialogOpen}
                >
                    <CardContent>
                        <div className="orderId">#{ticket.id}</div>
                        <div className="event">{this.renderEvent()}</div>
                        <div className="subscriptions">
                            <Trans
                                i18nKey="text.amount_subscription"
                                count={count}
                                ns="common"
                            >
                                {{ count }} subscriptions
                            </Trans>
                        </div>
                        <div className="creationDate">
                            <small>
                                {moment(ticket.created_at).format('LL')}
                            </small>
                        </div>
                        <div className="actions">
                            {!this.props.admin && (
                                <PopupMenu>
                                    <MenuItem onClick={this.handleDialogOpen}>
                                        {t('common:text.view_ticket')}
                                    </MenuItem>
                                    {
                                        // <MenuItem onClick={this.handleDialogQROpen}>{t('common:text.view_qrcode')}</MenuItem>
                                    }
                                </PopupMenu>
                            )}
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }

    renderDialog() {
        const { ticket } = this.props;

        return (
            <DialogTicket
                ticket={ticket}
                event={this.state.event}
                open={this.state.dialogOpen}
                onClose={this.handleDialogClose}
            />
        );
    }

    renderDialogQR() {
        const { ticket } = this.props;

        return (
            <DialogQR
                ticket={ticket}
                open={this.state.dialogQROpen}
                onClose={this.handleDialogQRClose}
            />
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return (
            <React.Fragment>
                {this.renderCard()}
                {this.renderDialog()}
                {this.renderDialogQR()}
            </React.Fragment>
        );
    }
}

CardTicket.propTypes = {
    classes: PropTypes.object.isRequired,
    ticket: PropTypes.object.isRequiredIf((props) => !props.isLoading),
    isLoading: PropTypes.bool,
    openDialog: PropTypes.bool,
    openDialogQR: PropTypes.bool,
    admin: PropTypes.bool,
    actionUrl: PropTypes.string,
    onClick: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.object,
};

const CardTicketTranslated = withTranslation()(CardTicket);
const CardTicketStyled = withStyles(useStyles)(CardTicketTranslated);
const CardTicketRouter = withRouter(CardTicketStyled);

export { CardTicketRouter as CardTicket };
