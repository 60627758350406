import React from 'react';
import classNames from 'classnames';
import {
    FormControl,
    InputLabel,
} from '@material-ui/core';
import {
    Autocomplete,
} from '@material-ui/lab';
import {
    TextInput,
} from '..';
import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';

import { countries } from './data';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

function CountrySelect (props) {
    return (
        <FormControl className={classNames('form-control', props.removeSpacing && 'no-spacing')}>
            <InputLabel shrink required={props.required}>
                {props.label}
            </InputLabel>

            <Controller
                render={defaultProps => (
                    <Autocomplete
                        {...defaultProps}
                        options={countries}
                        onChange={(_, data) => { defaultProps.onChange(data.code);}}
                        autoHighlight
                        getOptionLabel={(option) => {
                            if (typeof (option) === 'string') {
                                const filtered = countries.filter((value, index) => {
                                    return value.code === option;
                                });

                                if (filtered.length > 0) {
                                    return filtered[0].label;
                                }
                            }
                            return option.label;
                        }}
                        getOptionSelected={(option, value) => {
                            if (option && value) {
                                if (typeof (value) === 'string') {
                                    return option.code.toUpperCase() === value.toUpperCase();
                                } else {
                                    return option.code.toUpperCase() === value.code.toUpperCase();
                                }
                            }

                            return false;
                        }}
                        renderOption={(option) => (
                            <React.Fragment>
                                <span>{countryToFlag(option.code)}</span>
                                {option.label}
                            </React.Fragment>
                        )}
                        renderInput={(params) => {
                            return (<TextInput
                                params={params}
                                {...props}
                                ref={props.ref}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'disabled', // disable autocomplete and autofill
                                }}
                            />);
                        }}
                    />
                )}
                onChange={([, data]) => props.setValue(props.name, data.code)}
                name={props.name}
                defaultValue={'BE'}
                control={props.control}
            />
        </FormControl>
    );
}

CountrySelect.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    innerRef: PropTypes.func.isRequired,
    required: PropTypes.bool,
    children: PropTypes.any.isRequired,
    error: PropTypes.any,
    removeSpacing: PropTypes.bool,
};

// use ref forwarding for react-hook-form to work with class based components
// const CountrySelectStyled = (
//     React.forwardRef((props, ref) => <CountrySelect innerRef={ref} {...props} />)
// );

export { CountrySelect };
