import {
    NOTIFICATIONS_PAGE_LOADING,
    NOTIFICATIONS_PAGE_LOADING_SUCCESS,
    NOTIFICATIONS_PAGE_LOADING_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    authorizations: [],
    totalAuthorizations: 0,
    totalPages: 0,
    currentPage: 1,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NOTIFICATIONS_PAGE_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case NOTIFICATIONS_PAGE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                authorizations: action.payload.results,
                totalAuthorizations: action.payload.count,
                totalPages: action.payload.total_pages,
                currentPage: action.payload.current_page,
            };
        case NOTIFICATIONS_PAGE_LOADING_FAILED:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
