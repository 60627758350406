import React, { Component } from 'react';
import classNames from 'classnames';
import {
    FormControl,
    FormControlLabel,
    Checkbox as MuiCheckbox,
} from '@material-ui/core';

import PropTypes from 'prop-types';

class Checkbox extends Component {
    renderError() {
        const { error } = this.props;

        if (error) {
            return <small className="error">{error}</small>;
        }
    }

    render() {
        const {
            checked,
            onChange,
            name,
            innerRef,
            label,
            removeSpacing,
        } = this.props;

        return (
            <FormControl
                className={classNames(
                    'form-control',
                    removeSpacing && 'no-spacing'
                )}
            >
                <FormControlLabel
                    control={
                        <MuiCheckbox
                            defaultChecked={checked}
                            // checked={checked}
                            onChange={onChange}
                            name={name}
                            inputRef={innerRef}
                        />
                    }
                    label={label}
                />
                {this.renderError()}
            </FormControl>
        );
    }
}

Checkbox.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    innerRef: PropTypes.func.isRequired,
    required: PropTypes.bool,
    error: PropTypes.object,
    removeSpacing: PropTypes.bool,
};

// use ref forwarding for react-hook-form to work with class based components
const CheckboxStyled = React.forwardRef((props, ref) => (
    <Checkbox innerRef={ref} {...props} />
));

export { CheckboxStyled as Checkbox };
