import {
    ADMIN_CLUB_USERS_LOADING,
    ADMIN_CLUB_USERS_SUCCESS,
    ADMIN_CLUB_USERS_FAILED,
    ADMIN_CLUB_ADD_USER_LOADING,
    ADMIN_CLUB_ADD_USER_SUCCESS,
    ADMIN_CLUB_ADD_USER_FAILED,
    ADMIN_CLUB_DELETE_INVITATION_LOADING,
    ADMIN_CLUB_DELETE_INVITATION_SUCCESS,
    ADMIN_CLUB_DELETE_INVITATION_FAILED,
    ADMIN_CLUB_RESEND_INVITATION_LOADING,
    ADMIN_CLUB_RESEND_INVITATION_SUCCESS,
    ADMIN_CLUB_RESEND_INVITATION_FAILED,
} from './actionTypes';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import {
    listClubUsers as apiListClubUsers,
    deleteUserFromClub as apiDeleteUserFromClub,
    resendUserInvitation as apiResendUserInvitation,
} from 'services/ClubService';

export const listClubUsers = ({ clubId }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_CLUB_USERS_LOADING });

        apiListClubUsers(clubId)
            .then((result) => {
                dispatch({
                    type: ADMIN_CLUB_USERS_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_CLUB_USERS_FAILED,
                    payload: error,
                });
            });
    };
};

export const deleteUserFromClub = ({ id, email }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_CLUB_DELETE_INVITATION_LOADING });

        apiDeleteUserFromClub(id, { email })
            .then((result) => {
                dispatch({
                    type: ADMIN_CLUB_DELETE_INVITATION_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_CLUB_DELETE_INVITATION_FAILED,
                    payload: error,
                });
            });
    };
};

export const resendUserInvitation = ({ id, email }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_CLUB_RESEND_INVITATION_LOADING });

        apiResendUserInvitation(id, { email })
            .then((result) => {
                dispatch({
                    type: ADMIN_CLUB_RESEND_INVITATION_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                if (error && error.message) {
                    AlertService.showError({
                        description: error.message,
                    });
                } else {
                    AlertService.showError({
                        description: TranslationUtil.getTranslations().t(
                            'common:error.general.description'
                        ),
                    });
                }

                dispatch({
                    type: ADMIN_CLUB_RESEND_INVITATION_FAILED,
                    payload: error,
                });
            });
    };
};
