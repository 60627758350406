import { listAdminClubs, listAdminClubListActions } from 'services/ClubService';

import {
    CLUBS_LOADING,
    CLUBS_LOADING_SUCCESS,
    CLUBS_LOADING_FAILED,
    CLUB_RESET_CHANGED,
    CLUB_RESET_CREATED,
    CLUB_RESET_DELETED,
    CLUBS_ACTION_LOADING,
    CLUBS_ACTION_SUCCESS,
    CLUBS_ACTION_FAILED,
} from './actionTypes';

export const listClubs = (page) => {
    return (dispatch) => {
        dispatch({ type: CLUBS_LOADING });

        listAdminClubs(page)
            .then((result) => {
                dispatch({
                    type: CLUBS_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CLUBS_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const resetCreated = () => {
    return (dispatch) => {
        dispatch({ type: CLUB_RESET_CREATED });
    };
};

export const resetChanged = () => {
    return (dispatch) => {
        dispatch({ type: CLUB_RESET_CHANGED });
    };
};

export const resetDeleted = () => {
    return (dispatch) => {
        dispatch({ type: CLUB_RESET_DELETED });
    };
};

export const listClubListActions = (id) => {
    return (dispatch) => {
        dispatch({ type: CLUBS_ACTION_LOADING });

        listAdminClubListActions(id)
            .then((result) => {
                dispatch({
                    type: CLUBS_ACTION_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CLUBS_ACTION_FAILED,
                    payload: error,
                });
            });
    };
};
