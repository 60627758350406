import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getUrl, urls } from 'urls';
import { Button, TextInput } from '../..';

function LoginForm(props) {
    const { t } = useTranslation();

    const defaultValues = _.reduce(
        props.data,
        (result, value, key) => {
            result[key] = value['value'];
            return result;
        },
        {}
    );

    // global.log('>>> default values ', defaultValues)

    const { register, handleSubmit } = useForm({
        defaultValues: defaultValues,
    });

    return (
        <form onSubmit={handleSubmit(props.action)} style={{ width: '100%' }}>
            <TextInput
                label={t('forms:label.email_address')}
                name="email"
                required
                email
                ref={register({ required: true })}
            />
            <TextInput
                label={t('forms:label.password')}
                name="password"
                password
                required
                ref={register({ required: true })}
            >
                <a href={getUrl(urls.FORGOT_PASSWORD)}>
                    {t('common:link.forgot_password')}
                </a>
            </TextInput>
            <Button
                fullWidth
                color={'secondary'}
                isLoading={props.isLoading}
                type={'submit'}
            >
                {t('common:button.login')}
            </Button>
        </form>
    );
}

LoginForm.propTypes = {
    data: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
};

export { LoginForm };
