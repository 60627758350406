import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import { ALERT_TYPE } from "../../enums/alert";

import { Snackbar, Slide } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = (theme) => ({
    alert: {
        boxShadow: "0 0 10px rgba(0, 0, 0, .1)",
    },
});

// function TransitionLeft(props) {
//     return <Slide {...props} direction="left" />;
// }

function TransitionDown(props) {
    return <Slide {...props} direction="down" />;
}

class Toast extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: props.open,
        };

        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.setState({
                open: this.props.open,
            });
        }
    }

    handleClose() {
        const { onClose } = this.props;

        this.setState({
            open: false,
        });

        if (onClose) {
            onClose();
        }
    }

    render() {
        const { message, type, classes } = this.props;

        return (
            <Snackbar
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={TransitionDown}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                autoHideDuration={type === ALERT_TYPE.ERROR ? null : 5000}
            >
                <Alert
                    severity={type}
                    onClose={this.handleClose}
                    className={classes.alert}
                >
                    {message}
                </Alert>
            </Snackbar>
        );
    }
}

Toast.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool,
    type: PropTypes.oneOf([ALERT_TYPE.ERROR, ALERT_TYPE.SUCCESS]),
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
};

Toast.defaultProps = {
    type: ALERT_TYPE.ERROR,
};

const ToastStyled = withStyles(useStyles)(Toast);

export { ToastStyled as Toast };
