import React, { Component } from 'react';
import classNames from 'classnames';
import {
    RadioGroup as MuiRadioGroup,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio as MuiRadio,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';

class RadioGroup extends Component {
    renderError() {
        const { error } = this.props;

        if (error) {
            return <small className="error">{error}</small>;
        }
    }

    renderOptions() {
        const { options } = this.props;

        return options.map((obj, index) => {
            return (
                <React.Fragment key={index}>
                    <FormControlLabel
                        key={index}
                        checked={obj.checked}
                        value={obj.value}
                        label={obj.label}
                        control={<MuiRadio />}
                    />
                    {obj.help_text}
                </React.Fragment>
            );
        });
    }

    render() {
        const {
            onChange,
            name,
            innerRef,
            label,
            required,
            removeSpacing,
        } = this.props;

        return (
            <FormControl
                className={classNames(
                    'form-control',
                    removeSpacing && 'no-spacing'
                )}
            >
                <FormLabel component="legend" required={this.props.required}>
                    {label}
                </FormLabel>
                <Controller
                    as={
                        <MuiRadioGroup
                            aria-label="radio-group"
                            onChange={onChange}
                            inputRef={innerRef}
                            label={label}
                            required={required}
                        >
                            {this.renderOptions()}
                        </MuiRadioGroup>
                    }
                    name={name}
                    control={this.props.control}
                    defaultValue={''}
                />
                {this.renderError()}
            </FormControl>
        );
    }
}

RadioGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    innerRef: PropTypes.func.isRequired,
    required: PropTypes.bool,
    error: PropTypes.object,
    options: PropTypes.array.isRequired,
    control: PropTypes.object.isRequired,
    removeSpacing: PropTypes.bool,
};

// use ref forwarding for react-hook-form to work with class based components
const RadioGroupStyled = React.forwardRef((props, ref) => (
    <RadioGroup innerRef={ref} {...props} />
));

export { RadioGroupStyled as RadioGroup };
