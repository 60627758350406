export const SUBSCRIPTION_BASKET_DELETE_LOADING = 'subscription_basket_delete_loading';
export const SUBSCRIPTION_BASKET_DELETE_SUCCESS = 'subscription_basket_delete_success';
export const SUBSCRIPTION_BASKET_DELETE_FAILED = 'subscription_basket_delete_failed';

export const ADMIN_SUBSCRIPTION_BASKET_DELETE_LOADING = 'admin_subscription_basket_delete_loading';
export const ADMIN_SUBSCRIPTION_BASKET_DELETE_SUCCESS = 'admin_subscription_basket_delete_success';
export const ADMIN_SUBSCRIPTION_BASKET_DELETE_FAILED = 'admin_subscription_basket_delete_failed';

export const ADMIN_SUBSCRIPTION_DELETE_LOADING = 'admin_subscription_delete_loading';
export const ADMIN_SUBSCRIPTION_DELETE_SUCCESS = 'admin_subscription_delete_success';
export const ADMIN_SUBSCRIPTION_DELETE_FAILED = 'admin_subscription_delete_failed';
