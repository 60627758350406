import React from 'react';
import { Redirect, Switch, withRouter } from 'react-router-dom';
import { getUrl, urls } from 'urls';
import { topbarPublicMenuRoutes } from './routes';
import {
    AccountPage,
    AdminPage,
    CookiePage,
    CourseDetailPage,
    DisclaimerPage,
    EventDashboardPage,
    ForgotPasswordPage,
    HelpPage,
    LogoutPage,
    NotFoundPage,
    PrivacyPage,
    PricingPage,
    PublicEventDetailPage,
    RegisterPage,
    ResetPasswordPage,
    TermsPage,
    UserPage,
    // TicketQRPage,
} from '../pages';

// <Route
//     isPrivate
//     authenticated={isLoggedIn}
//     path={getUrl(urls.TICKET_DETAIL_QR_CODE)}
//     component={TicketQRPage}
// />

import Route from './Route';

const Pages = withRouter(({ history, isLoggedIn }) => {
    return (
        <Switch location={history.location}>
            <Route
                exact
                path={getUrl(urls.DISCLAIMER)}
                component={DisclaimerPage}
            />
            <Route exact path={getUrl(urls.COOKIE)} component={CookiePage} />
            <Route exact path={getUrl(urls.PRIVACY)} component={PrivacyPage} />
            <Route exact path={getUrl(urls.TERMS)} component={TermsPage} />

            <Route exact path={getUrl(urls.HELP)} component={HelpPage} />

            <Route exact path={getUrl(urls.LOGOUT)} component={LogoutPage} />
            <Route
                exact
                path={getUrl(urls.FORGOT_PASSWORD)}
                component={ForgotPasswordPage}
            />
            <Route
                exact
                path={getUrl(urls.EVENT_COURSE_START_LIST)}
                component={CourseDetailPage}
            />
            <Route
                exact
                path={getUrl(urls.EVENT_DETAIL)}
                component={PublicEventDetailPage}
            />
            <Route
                isPrivate
                authenticated={isLoggedIn}
                path={getUrl(urls.USER_SUBPAGE)}
                component={UserPage}
            />
            <Route
                exact
                path={getUrl(urls.DASHBOARD_EVENT)}
                component={EventDashboardPage}
            />

            <Route path={getUrl(urls.REGISTER)} component={RegisterPage} />
            <Route path={getUrl(urls.PRICING)} component={PricingPage} />

            <Route
                exact
                path={getUrl(urls.ACCOUNT_PAGE_CONFIRM)}
                component={AccountPage}
            />
            <Route
                exact
                path={getUrl(urls.RESET_PASSWORD_CONFIRM)}
                component={ResetPasswordPage}
            />
            <Route
                exact
                isPrivate
                authenticated={isLoggedIn}
                path={getUrl(urls.ACCOUNT_SUBPAGE_CONFIRM)}
                component={AccountPage}
            />

            <Redirect
                exact
                from={getUrl(urls.ADMIN)}
                to={getUrl(urls.ADMIN_DASHBOARD)}
            />

            <Route
                isPrivate
                isAdmin
                authenticated={isLoggedIn}
                path={getUrl(urls.ADMIN_SUBPAGE)}
                component={AdminPage}
            />

            {topbarPublicMenuRoutes.map((route, i) => (
                <Route
                    key={i}
                    isPrivate={route.isPrivate}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                />
            ))}

            <Route component={NotFoundPage} />
        </Switch>
    );
});

export { Pages };
