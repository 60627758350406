import {
    ACCOUNT_ACTIVATE_LOADING,
    ACCOUNT_ACTIVATE_SUCCESS,
    ACCOUNT_ACTIVATE_FAILED,

    EMAIL_ACTIVATE_LOADING,
    EMAIL_ACTIVATE_SUCCESS,
    EMAIL_ACTIVATE_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    // account
    isAccountActivating: false,
    isAccountActivated: false,
    accountActivateError: null,
    // email
    isEmailActivating: false,
    isEmailActivated: false,
    emailActivateError: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACCOUNT_ACTIVATE_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isAccountActivating: true,
            };
        case ACCOUNT_ACTIVATE_SUCCESS:
            return {
                ...state,
                isAccountActivating: false,
                isAccountActivated: true,
            };
        case ACCOUNT_ACTIVATE_FAILED:
            return {
                ...state,
                isAccountActivating: false,
                accountActivateError: action.payload,
            };

        case EMAIL_ACTIVATE_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isEmailActivating: true,
            };
        case EMAIL_ACTIVATE_SUCCESS:
            return {
                ...state,
                isEmailActivating: false,
                isEmailActivated: true,
            };
        case EMAIL_ACTIVATE_FAILED:
            return {
                ...state,
                isEmailActivating: false,
                emailActivateError: action.payload,
            };

        default:
            return state;
    }
};
