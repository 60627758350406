import format from 'string-format';
import fetchApi from './api';

const endPoints = {
    update: '/v1/users/{id}/',
    updateEmail: '/v1/users/update_email/',
    updatePassword: '/v1/auth/users/set_password/',
    get: '/v1/auth/users/me/',
};

export const getUser = () => fetchApi({
    url: endPoints.get,
    method: 'GET'
});

export const updateUser = (userId, first_name, last_name) => fetchApi({
    url: format(endPoints.update, { id: userId }),
    data: {
        first_name: first_name,
        last_name: last_name,
    },
    method: 'PATCH',
});

export const updateEmail = (email, password) => fetchApi({
    url: endPoints.updateEmail,
    data: {
        email: email,
        password: password,
    },
    method: 'PUT'
});

export const updatePassword = (current_password, new_password, re_new_password) => fetchApi({
    url: endPoints.updatePassword,
    data: {
        current_password: current_password,
        new_password: new_password,
        re_new_password: re_new_password,
    },
    method: 'POST'
});
