import {
    ADMIN_ADMINISTRATION_STATISTICS_LOADING,
    ADMIN_ADMINISTRATION_STATISTICS_SUCCESS,
    ADMIN_ADMINISTRATION_STATISTICS_FAILED,
} from './actionTypes';

import { getEventStatistics as apiGetEventStatistics } from 'services/EventService';

export const getEventStatistics = ({ id }) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_ADMINISTRATION_STATISTICS_LOADING,
        });

        apiGetEventStatistics(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_ADMINISTRATION_STATISTICS_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_ADMINISTRATION_STATISTICS_FAILED,
                    payload: error,
                });
            });
    };
};
