import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { DialogDelete } from '..';
import { dynamicInjectReducer } from '../../../reducers/InjectReducer';
import Reducer from './reducer';
import EventService from 'lib/EventService';
import { EVENT } from 'enums';

import {
    deleteSubscriptionBasketAdmin,
    deleteSubscriptionBasket,
    deleteSubscriptionAdmin,
} from './actions';

class DialogSubscriptionDelete extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handleClose = this.handleClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.inOpenBasket = this.inOpenBasket.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDeleted !== this.props.isDeleted) {
            if (this.props.isDeleted) {
                this.handleClose();
            }
        }
    }

    inOpenBasket(subscription) {
        //
        // Check if the subscription is in the current open basket.
        //

        const { shoppingBasketSubscriptions } = this.props;

        // is grouped by course_id
        return _.find(shoppingBasketSubscriptions, (obj) => {
            global.log(obj); // eslint-disable-line no-undef
        });
    }

    handleClose() {
        if (this.props.onClose) {
            this.props.onClose(this.props.isDeleted);

            if (this.props.isDeleted) {
                EventService.trigger(EVENT.SUBSCRIPTION_DELETED);
            }
        }
    }

    handleDelete() {
        const { subscription, admin } = this.props;

        if (subscription) {
            // check if the subscription is in the current basket
            if (this.inOpenBasket(subscription)) {
                if (admin) {
                    this.props.deleteSubscriptionBasketAdmin({
                        id: subscription.id,
                    });
                } else {
                    this.props.deleteSubscriptionBasket({
                        id: subscription.id,
                    });
                }
            } else {
                this.props.deleteSubscriptionAdmin({ id: subscription.id });
            }
        }
    }

    render() {
        return (
            <DialogDelete
                open={this.props.open}
                onClose={this.handleClose}
                action={this.handleDelete}
                isDeleting={this.props.isDeleting}
                admin={this.props.admin}
            />
        );
    }
}

DialogSubscriptionDelete.propTypes = {
    // props
    open: PropTypes.bool,
    isDeleting: PropTypes.bool,
    isDeleted: PropTypes.bool,
    admin: PropTypes.bool,
    error: PropTypes.string,
    subscription: PropTypes.object.isRequired,
    key: PropTypes.string.isRequired,
    shoppingBasketSubscriptions: PropTypes.object,
    // event handlers
    onClose: PropTypes.func.isRequired,
    deleteSubscriptionBasketAdmin: PropTypes.func.isRequired,
    deleteSubscriptionBasket: PropTypes.func.isRequired,
    deleteSubscriptionAdmin: PropTypes.func.isRequired,
    // other
    classes: PropTypes.object.isRequired,
    t: PropTypes.object.isRequired,
};

DialogSubscriptionDelete.defaultProps = {
    open: false,
    admin: false,
};

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.reducerKey || !state[ownProps.reducerKey]) {
        return {};
    }
    const { subscriptions } = state.shoppingCart;

    const {
        isDeleting,
        isDeleted,
        error,
    } = state[ownProps.reducerKey];

    return {
        isDeleting,
        isDeleted,
        error,
        key: ownProps.reducerKey,
        shoppingBasketSubscriptions: subscriptions,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        deleteSubscriptionBasketAdmin: ({ ...args }) =>
            dispatch(
                deleteSubscriptionBasketAdmin({
                    ...args,
                    key: ownProps.reducerKey,
                })
            ),
        deleteSubscriptionBasket: ({ ...args }) =>
            dispatch(
                deleteSubscriptionBasket({
                    ...args,
                    key: ownProps.reducerKey,
                })
            ),
        deleteSubscriptionAdmin: ({ ...args }) =>
            dispatch(
                deleteSubscriptionAdmin({
                    ...args,
                    key: ownProps.reducerKey,
                })
            ),
    };
};

const DialogSubscriptionDeleteTranslated = withTranslation()(DialogSubscriptionDelete);
const DialogSubscriptionDeleteState = connect(
    mapStateToProps,
    mapDispatchToProps
)(DialogSubscriptionDeleteTranslated);
const DialogSubscriptionDeleteReduced = dynamicInjectReducer({
    key: `dialogSubscriptionDelete`,
    reducer: Reducer,
})(DialogSubscriptionDeleteState);

export { DialogSubscriptionDeleteReduced as DialogSubscriptionDelete };
