import React, { Component, Suspense, Spinner } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { Container } from '@material-ui/core';

import { TemplateMarketing } from '../TemplateMarketing';
import { getUrl } from 'urls';

class NotFoundPage extends Component {
    render() {
        const { t } = this.props;

        return (
            <Suspense fallback={<Spinner />}>
                <Helmet>
                    <title>{t('common:text.not_found')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateMarketing>
                    <Container>
                        <h1>{t('common:text.not_found')}</h1>

                        <p>{t('common:description.not_found')}</p>
                    </Container>
                </TemplateMarketing>
            </Suspense>
        );
    }
}

const NotFoundPageTranslated = withTranslation()(NotFoundPage);
export { NotFoundPageTranslated as NotFoundPage };
