export const EVENTS_LOADING = 'events_loading';
export const EVENTS_LOADING_SUCCESS = 'events_loading_success';
export const EVENTS_LOADING_FAILED = 'events_loading_failed';

export const EVENT_RESET_CHANGED = 'event_reset_changed';
export const EVENT_RESET_CREATED = 'event_reset_created';
export const EVENT_RESET_DELETED = 'event_reset_deleted';

export const EVENTS_ACTIONS_LOADING = 'events_actions_loading';
export const EVENTS_ACTIONS_SUCCESS = 'events_actions_success';
export const EVENTS_ACTIONS_FAILED = 'events_actions_failed';
