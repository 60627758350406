import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { IconBorder } from '..';

const useStyles = (theme) => ({
    meta: {
        marginBottom: theme.spacing(2),

        '& > div': {
            marginRight: theme.spacing(2),
            width: theme.spacing(7),
        },

        '& p': {
            lineHeight: 1.45,
            marginBottom: 0,
            marginTop: theme.spacing(0.25),
            width: '100%',

            '& strong': {
                display: 'block',
                fontWeight: 'bold',
            },

            '& small': {
                color: theme.colors.warmGrey,
                display: 'block',
                marginBottom: 0,
                marginTop: 0,
            },
        },
    },
    dates: {
        '& small': {
            display: 'block',
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(0.5),
        },
    },
});

class MetaStat extends Component {
    render() {
        const { classes, isLoading, id, icon, value, text } = this.props;

        return (
            <Box
                id={id}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-start'}
                className={classNames(classes.meta, classes.dates)}
            >
                <div>
                    <IconBorder dark variant={'medium'}>
                        {icon}
                    </IconBorder>
                </div>
                <p>
                    {isLoading ? (
                        <Skeleton width={'100%'} height={22} />
                    ) : (
                        <strong>{value}</strong>
                    )}
                    {isLoading ? (
                        <Skeleton width={'100%'} height={22} />
                    ) : (
                        <small>{text}</small>
                    )}
                </p>
            </Box>
        );
    }
}

MetaStat.propTypes = {
    isLoading: PropTypes.bool,
    id: PropTypes.string,
    icon: PropTypes.object.isRequired,
    value: PropTypes.string.isRequiredIf((props) => !props.isLoading),
    text: PropTypes.string,
};

const MetaStatStyled = withStyles(useStyles)(MetaStat);

export { MetaStatStyled as MetaStat };
