import React, { Component, Suspense, Spinner } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { Container } from '@material-ui/core';

import { TemplateMarketing } from '../TemplateMarketing';
import { getUrl } from 'urls';

class CookiePage extends Component {
    render() {
        const { t } = this.props;

        return (
            <Suspense fallback={<Spinner />}>
                <Helmet>
                    <title>{t('legal:title.main.cookie')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateMarketing>
                    <Container>
                        <h1>{t('legal:title.main.cookie')}</h1>

                        <p>
                            Hi! Welcome on the website of Equento. You’re
                            probably here because you’d like to know more about
                            how we use cookies on our website. We’ll try to
                            explain it.
                        </p>
                        <p>
                            We use cookies to simplify your browsing experience
                            and to modify the content of the website to your
                            needs and preferences. You can reject the cookies,
                            but some parts of the website will work suboptimal.
                            When you visit the website without rejecting the
                            cookies in your browser and you click continue, you
                            accept our cookie usage.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>What is a cookie?</strong>
                        </p>
                        <p>
                            Sadly enough, it’s not something you can eat. A
                            cookie is a small text file that the server of a
                            website will save in the browser of your computer or
                            mobile device when you visit the website.This file
                            contains information to allow the website to work
                            correctly.
                        </p>
                        <p>
                            We never save sensitive information in the cookie
                            that can directly identify you. Your IP address,
                            which could be used to identify you, is anonymized.
                        </p>
                        <p>
                            More information about cookies can be found on{' '}
                            <a
                                href="https://cookiesandyou.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://cookiesandyou.com/
                            </a>
                            .
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>What kind of cookies are there?</strong>
                        </p>
                        <p>We can categorize cookies in the following:</p>
                        <ul>
                            <li>
                                Permanent cookies: these cookies are saved on
                                your computer even when you close your browser.
                                This way we can verify whether your logged in or
                                you dismissed a certain popup or which language
                                you selected.
                            </li>
                            <li>
                                Tracking or analytical cookies: these cookies
                                save how you interacted with our website, which
                                pages you visited, your browser language, your
                                browser, … We use these results to enhance our
                                website.
                            </li>
                            <li>
                                Temporary cookies: these are cookies that
                                disappear from your device when you close your
                                browser.
                            </li>
                            <li>
                                Marketing cookies: these cookies follow your
                                visits across different website and build a user
                                profile. This profile is used to personalise
                                your experience e.g. advertisements, newsletters
                                that match what your interested in.
                            </li>
                            <li>
                                Advertising cookies: these cookies track your
                                purchase of a product. They track which link you
                                clicked on and through which website you came.
                                This allows the affiliated website to get a
                                commission.
                            </li>
                        </ul>

                        <div className="spacer" />

                        <p>
                            <strong>Who sets the cookies?</strong>
                        </p>
                        <p>
                            Cookies can be created by the server of the website
                            you visit or by the websites partners. The server of
                            a website can only read cookies that they wrote. It
                            has no access to any other information on your
                            computer or mobile device.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>
                                Potential partner that can save cookies:
                            </strong>
                        </p>
                        <ul>
                            <li>
                                Google Analytics, YouTube, Google Adwords,
                                Google Maps, Google Remarketing and other Google
                                products:{' '}
                                <a
                                    href="https://www.google.be/intl/nl/policies/technologies/cookies/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://www.google.be/intl/nl/policies/technologies/cookies/
                                </a>
                            </li>
                            <li>
                                Facebook pixel:{' '}
                                <a
                                    href="https://www.facebook.com/policies/cookies/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://www.facebook.com/policies/cookies/
                                </a>
                            </li>
                            <li>
                                Getsitecontrol:{' '}
                                <a
                                    href="https://getsitecontrol.com/privacy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://getsitecontrol.com/privacy/
                                </a>
                            </li>
                            <li>
                                Lightwidget:{' '}
                                <a
                                    href="https://lightwidget.com/privacy"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://lightwidget.com/privacy
                                </a>
                            </li>
                            <li>
                                Hotjar:{' '}
                                <a
                                    href="https://www.hotjar.com/legal/policies/cookie-information"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://www.hotjar.com/legal/policies/cookie-information
                                </a>
                            </li>
                            <li>
                                UserReport:{' '}
                                <a
                                    href="https://privacy.userreport.com/en-GB/for-users/privacy-policy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://privacy.userreport.com/en-GB/for-users/privacy-policy/
                                </a>
                            </li>
                            <li>
                                YouTube:{' '}
                                <a
                                    href="https://www.youtube.com/t/terms"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://www.youtube.com/t/terms
                                </a>
                            </li>
                        </ul>

                        <div className="spacer" />

                        <p>
                            <strong>Why are cookies needed?</strong>
                        </p>

                        <p>
                            Cookies generally allow the interaction between the
                            user and the website to be easier, faster and help
                            the user to navigate between different parts of a
                            website. Cookies can also be used to make the
                            content or publicity of a website more relevant to a
                            user and even change to the personal needs.
                        </p>

                        <div className="spacer" />

                        <p>
                            <strong>How can you reject cookies?</strong>
                        </p>
                        <p>
                            You can reject cookies, but we can’t guarantee that
                            the website will work correctly. Check out these
                            links for the most common browser to turn off your
                            cookies
                        </p>

                        <ul>
                            <li>
                                <a
                                    href="https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Internet Explorer
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Microsoft Edge
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    FireFox
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://support.google.com/chrome/answer/95647?hl=en"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Chrome
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://support.apple.com/en-us/HT201265"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Safari
                                </a>
                            </li>
                        </ul>

                        <div className="spacer" />

                        <p>
                            <strong>Updates</strong>
                        </p>

                        <p>
                            We reserve the right to update the cookie policy.
                            This was last modified on May 20, 2018.
                        </p>
                    </Container>
                </TemplateMarketing>
            </Suspense>
        );
    }
}

const CookiePageTranslated = withTranslation()(CookiePage);
export { CookiePageTranslated as CookiePage };
