import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { ALERT_SHOW, ALERT_HIDE } from "./actionTypes";

const INITIAL_STATE = {
    alerts: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case ALERT_SHOW:
        return {
            ...state,
            alerts: [...state.alerts, { ...action.payload, key: uuidv4() }],
        };
    case ALERT_HIDE:
        const array = _.remove(state.alerts, (n) => {
            return n.key === action.payload?.key;
        });

        return {
            ...state,
            alerts: array,
        };

    default:
        return state;
    }
};
