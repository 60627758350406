import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import classnames from "classnames";
import PropTypes from "prop-types";

import {
    Collapse,
    Card,
    CardActionArea,
    CardContent,
    Divider,
    IconButton,
    Typography,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";

const useStyles = (theme) => ({
    flex: {
        display: "flex",
        justifyContent: "center",
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingBottom: theme.spacing(3),

        "& h2": {
            fontSize: "1.25rem",
            width: "40%",

            "& small": {
                display: "block",
                color: theme.colors.warmGrey,
                fontSize: "1rem",
                fontWeight: "normal",
                marginTop: theme.spacing(0.5),
            },
        },
    },
    content: {
        paddingTop: theme.spacing(3),
        // display: 'flex',
        // alignItems: 'flex-start',
        // justifyContent: 'space-between',
        // flexDirection: 'row',
    },
});

class CollapsablePanel extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            expanded: false,
        };

        // event handlers
        this.handleExpandClick = this.handleExpandClick.bind(this);
    }

    handleExpandClick(event) {
        event.stopPropagation();
        event.preventDefault();

        this.setState({
            expanded: !this.state.expanded,
        });
    }

    render() {
        const { classes, label, sublabel } = this.props;

        return (
            <Card>
                <CardActionArea onClick={this.handleExpandClick}>
                    <CardContent className={classes.header}>
                        <Typography variant={"h2"}>
                            {label} <small>{sublabel}</small>
                        </Typography>
                        {this.props.headerComponent}
                        <div>
                            <IconButton
                                className={classnames(
                                    classes.expand,
                                    this.state.expanded && classes.expandOpen
                                )}
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </div>
                    </CardContent>
                </CardActionArea>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <Divider />
                    <CardContent className={classes.content}>
                        {this.props.children}
                    </CardContent>
                </Collapse>
            </Card>
        );
    }
}

CollapsablePanel.propTypes = {
    label: PropTypes.string.isRequired,
    sublabel: PropTypes.string,
    classes: PropTypes.object,
    children: PropTypes.object,
    headerComponent: PropTypes.object,
};

const CollapsablePanelStyled = withStyles(useStyles)(CollapsablePanel);

export { CollapsablePanelStyled as CollapsablePanel };
