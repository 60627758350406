import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import Helmet from 'react-helmet';
import { getUrl } from 'urls';

import {
    Grid,
    Typography,
    TableContainer,
    TableRow,
    Table,
    TableCell,
    TableBody,
    Paper,
} from '@material-ui/core';

import { AdminHeader, CollapsablePanel, Stat } from '..';
import { renderAmount } from '../../utils';
import { getEventStatistics } from './actions';

const useStyles = (theme) => ({
    flex: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
    },
    topstats: {
        marginBottom: theme.spacing(4),
        textAlign: 'center',
    },
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(5),

        '& h3': {
            fontSize: '0.75rem',
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
            textTransform: 'uppercase',
            color: theme.colors.armadillo,
        },

        '&:last-child': {
            marginBottom: 0,
        },
    },
    column: {
        // display: 'flex',
        width: '48%',

        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    innerRow: {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-between',

        '& div[class*="Stat-alternative"]': {
            marginRight: theme.spacing(2),
            // minWidth: '30%',
            minWidth: '160px',
        },
    },
    stat: {
        '& strong, & small': {
            display: 'block',
        },

        '& strong': {
            fontSize: '1rem',
        },

        '& small': {
            color: theme.colors.warmGrey,
            fontSize: '.75rem',
            marginTop: theme.spacing(0.5),
        },
    },
    title: {
        marginBottom: theme.spacing(2),
    },
});

class AdminEventStatistics extends Component {
    componentDidMount() {
        if (this.props.event) {
            this.props.getEventStatistics({ id: this.props.event.id });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event !== this.props.event) {
            if (
                this.props.event &&
                (!this.props.statistics || this.props.statistics.length === 0)
            ) {
                if (!this.props.isLoading) {
                    this.props.getEventStatistics({ id: this.props.event.id });
                }
            }
        }
    }

    render() {
        const { t, classes, event, statistics, isLoading } = this.props;

        if (isLoading) {
            return <React.Fragment />;
        }

        return (
            <React.Fragment>
                <div id="top-page" />

                <Helmet>
                    <title>
                        {t('common:title.statistics')} |{' '}
                        {isLoading ? event?.name : 'Event'} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>

                <AdminHeader
                    variant={'small'}
                    title={t('common:title.statistics')}
                />

                <Grid container spacing={2} className={classes.topstats}>
                    <Grid item xs={12} sm={3}>
                        <Stat
                            label={'Tickets'}
                            value={statistics?.tickets?.all}
                            footNote={' '}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Stat
                            label={'Orders'}
                            value={statistics?.orders?.all.count}
                            footNote={`${statistics?.orders?.refunded.count} refunded`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Stat
                            label={'Total subscriptions'}
                            value={statistics?.subscriptions?.all}
                            footNote={`${statistics?.subscriptions?.out_of_competition} out of competition`}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Stat
                            label={'Amount received'}
                            value={renderAmount(statistics?.payment?.paid)}
                            footNote={
                                renderToStaticMarkup(
                                    renderAmount(
                                        statistics?.payment?.not_paid +
                                            statistics?.payment
                                                ?.partially_paid +
                                            statistics?.payment
                                                ?.payment_pending +
                                            statistics?.payment
                                                ?.payment_failed +
                                            statistics?.payment?.draft
                                    )
                                ) + ' not paid'
                            }
                        />
                    </Grid>
                </Grid>

                <CollapsablePanel label={'Orders'}>
                    <div className={classes.row}>
                        <div className={classes.column}>
                            <Typography variant={'h3'}>Order totals</Typography>
                            <div className={classes.innerRow}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Funded'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders
                                                            ?.funded.count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.funded.amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Refunded'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders
                                                            ?.refunded.count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.refunded
                                                                .amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Total'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders?.all
                                                            .count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.all.amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <div className={classes.column}>
                            <Typography variant={'h3'}>Payments</Typography>
                            <div className={classes.innerRow}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Paid'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders?.paid
                                                            .count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.paid.amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Canceled'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders
                                                            ?.canceled.count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.canceled
                                                                .amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Draft'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders
                                                            ?.draft.count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.draft.amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Partially paid'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders
                                                            ?.partially_paid
                                                            .count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.partially_paid
                                                                .amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Not paid'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders
                                                            ?.not_paid.count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.not_paid
                                                                .amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Payment pending'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders
                                                            ?.payment_pending
                                                            .count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.payment_pending
                                                                .amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component={'th'}>
                                                    {'Payment failed'}
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        statistics?.orders
                                                            ?.payment_failed
                                                            .count
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <small>
                                                        &euro;&nbsp;
                                                        {
                                                            statistics?.orders
                                                                ?.payment_failed
                                                                .amount
                                                        }
                                                    </small>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </CollapsablePanel>

                <div className={'spacer'} />
                <div className={'spacer'} />

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={'h3'} className={classes.title}>
                            Courses
                        </Typography>
                    </Grid>
                </Grid>

                {statistics?.courses?.map((obj, index) => (
                    <React.Fragment>
                        <CollapsablePanel
                            label={`${obj.position}. ${obj.name}`}
                            sublabel={`${obj.height} cm`}
                            headerComponent={
                                <React.Fragment>
                                    <div className={classes.stat}>
                                        <strong>
                                            {obj.subscriptions?.all}
                                        </strong>
                                        <small>Total combinations</small>
                                    </div>
                                    <div className={classes.stat}>
                                        <strong>
                                            {renderAmount(obj.payment?.paid)}
                                        </strong>
                                        <small>
                                            {renderAmount(
                                                obj?.payment?.not_paid +
                                                    obj?.payment
                                                        ?.partially_paid +
                                                    obj?.payment
                                                        ?.payment_pending +
                                                    obj?.payment
                                                        ?.payment_failed +
                                                    obj?.payment?.draft
                                            )}{' '}
                                            {'not paid'}
                                        </small>
                                    </div>
                                </React.Fragment>
                            }
                        >
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <Typography variant={'h3'}>
                                        Combinations
                                    </Typography>
                                    <div className={classes.innerRow}>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'In competition'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj
                                                                    .subscriptions
                                                                    ?.in_competition
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {
                                                                'Out of competition'
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj
                                                                    .subscriptions
                                                                    ?.out_of_competition
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Refunded'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj
                                                                    .subscriptions
                                                                    ?.refunded
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Total'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj
                                                                    .subscriptions
                                                                    ?.all
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                                <div className={classes.column}>
                                    <Typography variant={'h3'}>
                                        Order totals
                                    </Typography>
                                    <div className={classes.innerRow}>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Funded'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders
                                                                    ?.funded
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.funded
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Refunded'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders
                                                                    ?.refunded
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.refunded
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Total'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders?.all
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.all
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.row}>
                                <div className={classes.column}>
                                    <Typography variant={'h3'}>
                                        Payments
                                    </Typography>
                                    <div className={classes.innerRow}>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Paid'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders?.paid
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.paid
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Canceled'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders
                                                                    ?.canceled
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.canceled
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Draft'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders
                                                                    ?.draft
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.draft
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Partially paid'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders
                                                                    ?.partially_paid
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.partially_paid
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Not paid'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders
                                                                    ?.not_paid
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.not_paid
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Payment pending'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders
                                                                    ?.payment_pending
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.payment_pending
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            component={'th'}
                                                        >
                                                            {'Payment failed'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                obj.orders
                                                                    ?.payment_failed
                                                                    .count
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            <small>
                                                                &euro;&nbsp;
                                                                {
                                                                    obj.orders
                                                                        ?.payment_failed
                                                                        .amount
                                                                }
                                                            </small>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                </div>
                            </div>
                        </CollapsablePanel>

                        <div className={'spacer'} />
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    }
}

AdminEventStatistics.propTypes = {
    // props
    event: PropTypes.object.isRequiredIf((props) => !props.isLoadingEvent),
    isLoadingEvent: PropTypes.bool,
    isLoading: PropTypes.bool,
    statistics: PropTypes.array,
    error: PropTypes.string,
    // functions
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    getEventStatistics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        statistics,
        error,
    } = state.componentAdminEventStatistics;

    return {
        isLoading,
        statistics,
        error,
    };
};

const AdminEventStatisticsState = connect(mapStateToProps, {
    getEventStatistics,
})(AdminEventStatistics);
const AdminEventStatisticsTranslated = withTranslation()(
    AdminEventStatisticsState
);
const AdminEventStatisticsStyled = withStyles(useStyles)(
    AdminEventStatisticsTranslated
);
const AdminEventStatisticsRouter = withRouter(AdminEventStatisticsStyled);

export { AdminEventStatisticsRouter as AdminEventStatistics };
