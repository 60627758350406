import format from 'string-format';
import fetchApi from './api';
import apiConfig from './api/config';

const publicEndPoints = {
    list: '/v1/orders/',
    detail: '/v1/orders/{id}/',
};

const adminEndPoints = {
    list: '/v1/admin/orders/',
    detail: '/v1/admin/orders/{id}/',
    pay: '/v1/admin/orders/{id}/pay/',
};

export const listOrders = (page) =>
    fetchApi({
        url: publicEndPoints.list,
        data: {
            page: page,
            ordering: '-created_at',
        },
        method: 'GET',
    });

export const listAdminOrders = ({ page, status, ticketId = undefined }) =>
    fetchApi({
        url: adminEndPoints.list,
        data: {
            page: page,
            status: status,
            ordering: '-created_at',
            ticket: ticketId,
        },
        method: 'GET',
    });

export const getOrder = (id) =>
    fetchApi({
        url: format(publicEndPoints.detail, { id }),
        method: 'GET',
    });

export const getAdminOrder = (id) =>
    fetchApi({
        url: format(adminEndPoints.detail, { id }),
        method: 'GET',
    });

export const payAdminOrder = (id, method, amount) =>
    fetchApi({
        url: format(adminEndPoints.pay, { id }),
        data: {
            redirect_url: `${apiConfig.baseSiteUrl}admin/orders/${id}`,
            method,
            amount,
        },
        method: 'POST',
    });

export const applyDiscountToOrder = (id, amount) =>
    fetchApi({
        url: format(adminEndPoints.detail, { id }),
        data: {
            discount_amount: amount,
        },
        method: 'PATCH',
    });

export const removeDiscountFromOrder = (id) =>
    fetchApi({
        url: format(adminEndPoints.detail, { id }),
        data: {
            discount_amount: 0,
        },
        method: 'PATCH',
    });
