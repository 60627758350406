import format from 'string-format';
import fetchApi from './api';

const adminEndPoints = {
    list: '/v1/admin/clubs/',
    detail: '/v1/admin/clubs/{id}/',
    add: '/v1/admin/clubs/',
    edit: '/v1/admin/clubs/{id}/',
    delete: '/v1/admin/clubs/{id}/',
    users: '/v1/admin/clubs/{id}/users/',
    addUser: '/v1/admin/clubs/{id}/users/add/',
    changeUser: '/v1/admin/clubs/{id}/users/change/',
    deleteUser: '/v1/admin/clubs/{id}/users/delete/',
    resendInvitation: '/v1/admin/clubs/{id}/resend_invitation/',
};

export const listAdminClubs = (page = 1) =>
    fetchApi({
        url: adminEndPoints.list,
        data: {
            page: page,
        },
        method: 'GET',
    });

export const listClubUsers = (id) =>
    fetchApi({
        url: format(adminEndPoints.users, { id }),
        method: 'GET',
    });

export const addUserToClub = (id, data) =>
    fetchApi({
        url: format(adminEndPoints.addUser, { id }),
        data: data,
        method: 'POST',
    });

export const deleteUserFromClub = (id, data) =>
    fetchApi({
        url: format(adminEndPoints.deleteUser, { id }),
        data: data,
        method: 'DELETE',
    });

export const changeUserFromClub = (id, data) =>
    fetchApi({
        url: format(adminEndPoints.changeUser, { id }),
        data: data,
        method: 'PUT',
    });

export const resendUserInvitation = (id, data) =>
    fetchApi({
        url: format(adminEndPoints.resendInvitation, { id }),
        data: data,
        method: 'POST',
    });

export const listAdminClubListActions = (id) =>
    fetchApi({
        url: adminEndPoints.list,
        method: 'OPTIONS',
    });

export const getAdminClub = (id) =>
    fetchApi({
        url: format(adminEndPoints.detail, { id }),
        method: 'GET',
    });

export const addAdminClub = (data) =>
    fetchApi({
        url: adminEndPoints.add,
        data: data,
        method: 'POST',
    });

export const editAdminClub = (id, data) =>
    fetchApi({
        url: format(adminEndPoints.edit, { id }),
        data: data,
        method: 'PATCH',
    });

export const deleteAdminClub = (id) =>
    fetchApi({
        url: format(adminEndPoints.delete, { id }),
        method: 'DELETE',
    });
