import {
    SUBSCRIPTION_BASKET_DELETE_LOADING,
    SUBSCRIPTION_BASKET_DELETE_SUCCESS,
    SUBSCRIPTION_BASKET_DELETE_FAILED,

    ADMIN_SUBSCRIPTION_BASKET_DELETE_LOADING,
    ADMIN_SUBSCRIPTION_BASKET_DELETE_SUCCESS,
    ADMIN_SUBSCRIPTION_BASKET_DELETE_FAILED,

    ADMIN_SUBSCRIPTION_DELETE_LOADING,
    ADMIN_SUBSCRIPTION_DELETE_SUCCESS,
    ADMIN_SUBSCRIPTION_DELETE_FAILED,
} from './actionTypes';

import {
    deleteSubscriptionBasket as apiDeleteSubscriptionBasket,
    deleteSubscriptionBasketAdmin as apiDeleteSubscriptionBasketAdmin,
} from 'services/BasketService';

import {
    deleteSubscriptionAdmin as apiDeleteSubscriptionAdmin,
} from 'services/SubscriptionService';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

export const deleteSubscriptionBasket = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: SUBSCRIPTION_BASKET_DELETE_LOADING,
            payload: { id: id },
            meta: { key: key },
        });

        apiDeleteSubscriptionBasket(id)
            .then(() => {
                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.deleted_subscription'
                    ),
                });

                dispatch({
                    type: SUBSCRIPTION_BASKET_DELETE_SUCCESS,
                    payload: { id: id },
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.deleted_subscription'
                    ),
                });

                dispatch({
                    type: SUBSCRIPTION_BASKET_DELETE_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const deleteSubscriptionBasketAdmin = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_SUBSCRIPTION_BASKET_DELETE_LOADING,
            payload: { id: id },
            meta: { key: key },
        });

        apiDeleteSubscriptionBasketAdmin(id)
            .then(() => {
                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.deleted_subscription'
                    ),
                });

                dispatch({
                    type: ADMIN_SUBSCRIPTION_BASKET_DELETE_SUCCESS,
                    payload: { id: id },
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.deleted_subscription'
                    ),
                });

                dispatch({
                    type: ADMIN_SUBSCRIPTION_BASKET_DELETE_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const deleteSubscriptionAdmin = ({ id, key }) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_SUBSCRIPTION_DELETE_LOADING,
            payload: { id: id },
            meta: { key: key },
        });

        apiDeleteSubscriptionAdmin(id)
            .then(() => {
                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.deleted_subscription'
                    ),
                });

                dispatch({
                    type: ADMIN_SUBSCRIPTION_DELETE_SUCCESS,
                    payload: { id: id },
                    meta: { key: key },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.deleted_subscription'
                    ),
                });

                dispatch({
                    type: ADMIN_SUBSCRIPTION_DELETE_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};
