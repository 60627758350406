import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AdminHeader, CardAction, DialogExport } from 'components';

const useStyles = (theme) => ({
    flex: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
    },
});

class AdminEventAdministration extends Component {
    constructor(props) {
        super(props);

        // state
        this.initialState = {
            dialogExportOpen: false,
            isExportingCSV: false,
            isExportingExcel: false,
            isDownloadingReceipts: false,
            isDownloadingStartlists: false,
            isExportingStartLists: false,
            isExportingReceipts: false,
        };

        this.state = {
            ...this.initialState,
        };
    }

    renderDialogExport() {
        return (
            <DialogExport
                open={this.state.dialogExportOpen}
                defaultType={
                    (this.state.isExportingStartLists && 'start-list') ||
                    undefined ||
                    (this.state.isExportingReceipts && 'receipts') ||
                    undefined
                }
                showType={
                    !this.state.isExportingStartLists &&
                    !this.state.isExportingReceipts
                }
                exportClass={'event'}
                event={this.props.event}
                onClose={() =>
                    this.setState({
                        ...this.initialState,
                    })
                }
            />
        );
    }

    renderCardExport() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <CardAction
                    title={t('common:title.export_dialog_event')}
                    description={t('common:subtitle.export_dialog_event')}
                    actionText={t('common:button.export')}
                    isLoading={this.props.isLoadingEvent}
                    action={() => this.setState({ dialogExportOpen: true })}
                    buttonIsLoading={this.state.isExportingEvent}
                />
            </React.Fragment>
        );
    }

    renderCardReceipts() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <CardAction
                    title={t('common:title.receipts')}
                    description={t('common:description.receipts')}
                    actionText={t('common:button.download')}
                    isLoading={this.props.isLoadingEvent}
                    action={() =>
                        this.setState({
                            dialogExportOpen: true,
                            isExportingReceipts: true,
                        })
                    }
                    buttonIsLoading={this.state.isExportingEvent}
                />
            </React.Fragment>
        );
    }

    renderCardStartlists() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <CardAction
                    title={t('common:title.start_lists')}
                    description={t('common:description.start_lists')}
                    actionText={t('common:button.download')}
                    isLoading={this.props.isLoadingEvent}
                    action={() =>
                        this.setState({
                            dialogExportOpen: true,
                            isExportingStartLists: true,
                        })
                    }
                    buttonIsLoading={this.state.isExportingEvent}
                />
            </React.Fragment>
        );
    }

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div id="top-page" />

                <AdminHeader
                    variant={'small'}
                    title={t('common:title.administration')}
                />

                {this.renderCardExport()}
                {this.renderCardReceipts()}
                {this.renderCardStartlists()}

                {this.renderDialogExport()}
            </React.Fragment>
        );
    }
}

AdminEventAdministration.propTypes = {
    // prop
    event: PropTypes.object.isRequiredIf((props) => !props.isLoadingEvent),
    isLoadingEvent: PropTypes.bool,
    // handlers
    t: PropTypes.func.isRequired,
};

const AdminEventAdministrationState = connect(
    null,
    {}
)(AdminEventAdministration);
const AdminEventAdministrationTranslated = withTranslation()(
    AdminEventAdministrationState
);
const AdminEventAdministrationStyled = withStyles(useStyles)(
    AdminEventAdministrationTranslated
);
const AdminEventAdministrationRouter = withRouter(
    AdminEventAdministrationStyled
);

export { AdminEventAdministrationRouter as AdminEventAdministration };
