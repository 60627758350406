import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import './styles.scss';

class TertiaryButton extends Component {
    render() {
        return (
            <Button
                variant="contained"
                href={this.props.href}
                onClick={this.props.onClick}
                disableElevation
                className={'button-tertiary'}
            >
                {this.props.children}
            </Button>
        );
    }
}

TertiaryButton.propTypes = {
    children: PropTypes.string.isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
};

export { TertiaryButton };
