import format from 'string-format';
import fetchApi from './api';

const endPoints = {
    list: '/v1/courses/',
    detail: '/v1/courses/{id}/',
};

const adminEndPoints = {
    list: '/v1/admin/courses/',
    add: '/v1/admin/courses/',
    detail: '/v1/admin/courses/{id}/',
    edit: '/v1/admin/courses/{id}/',
    delete: '/v1/admin/courses/{id}/',
    types: '/v1/admin/courses/types/',
    listActions: '/v1/admin/courses/actions/',
    export: '/v1/admin/courses/{id}/export/',
};

export const listPublicCourses = ({ page = 1, eventId = undefined }) =>
    fetchApi({
        url: endPoints.list,
        data: {
            page: page,
            event: eventId,
        },
        method: 'GET',
        useAccessToken: false,
    });

export const getPublicCourse = (id) =>
    fetchApi({
        url: format(endPoints.detail, { id }),
        method: 'GET',
        useAccessToken: false,
    });

export const listAdminCourses = ({ eventId, page = 1 }) =>
    fetchApi({
        url: adminEndPoints.list,
        data: {
            page: page,
            event: eventId,
        },
        method: 'GET',
    });

export const getAdminCourse = (id) =>
    fetchApi({
        url: format(adminEndPoints.detail, { id }),
        method: 'GET',
    });

export const addAdminCourse = (data) =>
    fetchApi({
        url: adminEndPoints.add,
        data: data,
        method: 'POST',
    });

export const editAdminCourse = (id, data) =>
    fetchApi({
        url: format(adminEndPoints.edit, { id }),
        data: data,
        method: 'PATCH',
    });

export const deleteAdminCourse = (id) =>
    fetchApi({
        url: format(adminEndPoints.delete, { id }),
        method: 'DELETE',
    });

export const listAdminCourseTypes = () =>
    fetchApi({
        url: adminEndPoints.types,
        method: 'GET',
    });

export const listAdminCourseListActions = (eventId) =>
    fetchApi({
        url: adminEndPoints.listActions,
        data: {
            event: eventId,
        },
        method: 'GET',
    });

export const exportStartlist = ({ id }) =>
    fetchApi({
        url: format(adminEndPoints.export, { id }),
        data: {
            type: 'start-list',
            show_unpaid: true,
        },
        method: 'POST',
        responseType: 'arraybuffer',
        exposeHeaders: true,
    });

export const exportCourse = ({ id, fileType, showUnpaid }) =>
    fetchApi({
        url: format(adminEndPoints.export, { id }),
        data: { type: fileType, show_unpaid: showUnpaid },
        method: 'POST',
        responseType: 'arraybuffer',
        exposeHeaders: true,
    });
