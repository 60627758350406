import format from 'string-format';
import fetchApi from './api';

const adminEndPoints = {
    editSubscription: '/v1/admin/subscriptions/{id}/',
    deleteSubscription: '/v1/admin/subscriptions/{id}/',
    markedEnteredSubscription: '/v1/admin/subscriptions/{id}/mark_entered/',
    markedNotEnteredSubscription:
        '/v1/admin/subscriptions/{id}/mark_not_entered/',
};

export const editSubscriptionAdmin = ({
    id,
    course,
    start_number,
    out_of_competition,
    combinations,
}) =>
    fetchApi({
        url: format(adminEndPoints.editSubscription, { id }),
        data: {
            course,
            start_number,
            out_of_competition,
            combinations,
        },
        method: 'PATCH',
    });

export const deleteSubscriptionAdmin = (id) =>
    fetchApi({
        url: format(adminEndPoints.deleteSubscription, { id }),
        data: {},
        method: 'DELETE',
    });

export const markEnteredSubscriptionAdmin = ({ id }) =>
    fetchApi({
        url: format(adminEndPoints.markedEnteredSubscription, { id }),
        method: 'POST',
    });

export const markNotEnteredSubscriptionAdmin = ({ id }) =>
    fetchApi({
        url: format(adminEndPoints.markedNotEnteredSubscription, { id }),
        method: 'POST',
    });
