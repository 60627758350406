import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Animated } from 'react-animated-css';
import { InView } from 'react-intersection-observer';

import { Container, Grid, Typography } from '@material-ui/core';

import { IconBorder } from '..';

class USPList extends Component {
    render() {
        return (
            <Container>
                <Grid
                    container
                    spacing={3}
                    className={classNames(
                        'usp-list-container',
                        this.props.disableMargin && 'disable-margin'
                    )}
                >
                    {this.props.items.map((obj) => {
                        const Icon = obj.icon;

                        return (
                            <InView>
                                {({ inView, ref }) => {
                                    return (
                                        <Grid ref={ref} item xs={12} sm={4}>
                                            <Animated
                                                animationIn="fadeInUp"
                                                animationOut="fadeOut"
                                                isVisible={inView}
                                            >
                                                <div
                                                    className={'usp-list-item'}
                                                >
                                                    <IconBorder
                                                        dark
                                                        variant={'large'}
                                                    >
                                                        <Icon />
                                                    </IconBorder>
                                                    <Typography variant={'h3'}>
                                                        {obj.title}
                                                    </Typography>
                                                    <Typography>
                                                        {obj.description}
                                                    </Typography>
                                                </div>
                                            </Animated>
                                        </Grid>
                                    );
                                }}
                            </InView>
                        );
                    })}
                </Grid>
            </Container>
        );
    }
}

USPList.propTypes = {
    items: PropTypes.exact({
        icon: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
    disableMargin: PropTypes.bool,
};

export { USPList };
