import React from "react";
import classNames from "classnames";
import {
    FormControl,
    Select as MuiSelect,
    InputLabel,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

import PropTypes from "prop-types";

/* eslint-disable no-unused-expressions */
function Select(props) {
    return (
        <FormControl
            className={classNames(
                "form-control",
                props.className,
                props.removeSpacing && "no-spacing"
            )}
        >
            {props.label && (
                <InputLabel shrink required={props.required}>
                    {props.label}
                </InputLabel>
            )}
            <Controller
                render={({ onChange, ref, value }) => (
                    <MuiSelect
                        required={props.required}
                        onChange={(e, v) => {
                            onChange(e);
                            props.onChange && props.onChange(e, v);
                        }}
                        inputRef={ref}
                        value={value}
                    >
                        {props.children}
                    </MuiSelect>
                )}
                rules={{ required: props.required }}
                name={props.name}
                control={props.control}
                defaultValue=""
            />
            {props.error && <small className="error">{props.error}</small>}
        </FormControl>
    );
}
/* eslint-enable no-unused-expressions */

Select.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    innerRef: PropTypes.func.isRequired,
    required: PropTypes.bool,
    control: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    error: PropTypes.object,
    className: PropTypes.object,
    onChange: PropTypes.func,
    removeSpacing: PropTypes.bool,
};

// use ref forwarding for react-hook-form to work with class based components
const SelectStyled = React.forwardRef((props, ref) => (
    <Select innerRef={ref} {...props} />
));

export { SelectStyled as Select };
