import _ from 'lodash';
import {
    ADMIN_TICKET_LOADING,
    ADMIN_TICKET_SUCCESS,
    ADMIN_TICKET_FAILED,
    ADMIN_TICKET_EVENT_LOADING,
    ADMIN_TICKET_EVENT_SUCCESS,
    ADMIN_TICKET_EVENT_FAILED,
    ADMIN_TICKET_ORDERS_LOADING,
    ADMIN_TICKET_ORDERS_SUCCESS,
    ADMIN_TICKET_ORDERS_FAILED,
    ADMIN_TICKET_COMBINATION_CONFIRM_LOADING,
    ADMIN_TICKET_COMBINATION_CONFIRM_SUCCESS,
    ADMIN_TICKET_COMBINATION_CONFIRM_FAILED,
    ADMIN_TICKET_COMBINATION_UNCONFIRM_LOADING,
    ADMIN_TICKET_COMBINATION_UNCONFIRM_SUCCESS,
    ADMIN_TICKET_COMBINATION_UNCONFIRM_FAILED,
} from './actionTypes';
import { getIdFromUrl } from '../../utils';

const INITIAL_STATE = {
    isLoading: true,
    ticket: null,
    subscriptions: {},
    error: null,
    // event
    isLoadingEvent: false,
    event: null,
    errorEvent: null,
    // orders
    isLoadingOrders: false,
    orders: [],
    errorOrders: null,
    totalOrders: 0,
    totalPagesOrders: 0,
    currentPageOrders: 1,
    // actions
    confirmCombinations: {},
    unconfirmCombinations: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_TICKET_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ADMIN_TICKET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                ticket: action.payload,
                subscriptions: _.reduce(
                    action.payload.subscriptions,
                    (result, value, key) => {
                        result[value.id] = value;
                        return result;
                    },
                    {}
                ),
            };
        case ADMIN_TICKET_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case ADMIN_TICKET_ORDERS_LOADING:
            return {
                ...state,
                isLoadingOrders: true,
            };
        case ADMIN_TICKET_ORDERS_SUCCESS:
            return {
                ...state,
                isLoadingOrders: false,
                orders: action.payload.results,
                totalOrders: action.payload.count,
                totalPagesOrders: action.payload.total_pages,
                currentPageOrders: action.payload.current_page,
            };
        case ADMIN_TICKET_ORDERS_FAILED:
            return {
                ...state,
                isLoadingOrders: false,
                errorOrders: action.payload,
            };

        case ADMIN_TICKET_EVENT_LOADING:
            return {
                ...state,
                isLoadingEvent: true,
            };
        case ADMIN_TICKET_EVENT_SUCCESS:
            return {
                ...state,
                isLoadingEvent: false,
                event: action.payload,
            };
        case ADMIN_TICKET_EVENT_FAILED:
            return {
                ...state,
                isLoadingEvent: false,
                errorEvent: action.payload,
            };

        case ADMIN_TICKET_COMBINATION_CONFIRM_LOADING: {
            let confirmCombinations = state.confirmCombinations;
            confirmCombinations[action.payload.id] = {
                isLoading: true,
                isSuccess: false,
                error: undefined,
            };

            return {
                ...state,
                confirmCombinations: Object.assign({}, confirmCombinations),
            };
        }
        case ADMIN_TICKET_COMBINATION_CONFIRM_SUCCESS: {
            let confirmCombinations = state.confirmCombinations;
            confirmCombinations[action.payload.id] = {
                isLoading: false,
                isSuccess: true,
                error: undefined,
            };

            // update subscriptions
            let subscriptions = state.subscriptions;
            const subscriptionId = getIdFromUrl(
                action.payload.result.subscription
            );
            let combinations = subscriptions[subscriptionId];
            combinations[0] = action.payload.result;
            // TODO multiple combinations
            subscriptions[subscriptionId].combinations = combinations;

            return {
                ...state,
                confirmCombinations: Object.assign({}, confirmCombinations),
                subscriptions: Object.assign({}, subscriptions),
            };
        }
        case ADMIN_TICKET_COMBINATION_CONFIRM_FAILED: {
            let confirmCombinations = state.confirmCombinations;
            confirmCombinations[action.payload.id] = {
                isLoading: false,
                isSuccess: false,
                error: action.payload.error.message,
            };

            return {
                ...state,
                confirmCombinations: Object.assign({}, confirmCombinations),
            };
        }

        case ADMIN_TICKET_COMBINATION_UNCONFIRM_LOADING: {
            let unconfirmCombinations = state.unconfirmCombinations;
            unconfirmCombinations[action.payload.id] = {
                isLoading: true,
                isSuccess: false,
                error: undefined,
            };

            return {
                ...state,
                unconfirmCombinations: Object.assign({}, unconfirmCombinations),
            };
        }
        case ADMIN_TICKET_COMBINATION_UNCONFIRM_SUCCESS: {
            let unconfirmCombinations = state.unconfirmCombinations;
            unconfirmCombinations[action.payload.id] = {
                isLoading: false,
                isSuccess: true,
                error: undefined,
            };

            // update subscriptions
            let subscriptions = state.subscriptions;
            const subscriptionId = getIdFromUrl(
                action.payload.result.subscription
            );
            let combinations = subscriptions[subscriptionId];
            combinations[0] = action.payload.result;
            // TODO multiple combinations
            subscriptions[subscriptionId].combinations = combinations;

            return {
                ...state,
                unconfirmCombinations: Object.assign({}, unconfirmCombinations),
                subscriptions: Object.assign({}, subscriptions),
            };
        }
        case ADMIN_TICKET_COMBINATION_UNCONFIRM_FAILED: {
            let unconfirmCombinations = state.unconfirmCombinations;
            unconfirmCombinations[action.payload.id] = {
                isLoading: false,
                isSuccess: false,
                error: action.payload.error.message,
            };

            return {
                ...state,
                unconfirmCombinations: Object.assign({}, unconfirmCombinations),
            };
        }

        default:
            return state;
    }
};
