import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Checkbox, CountrySelect, Select, TextInput } from '../../Forms';

function SubscriptionForm(props) {
    const [subscription, setSubscription] = useState(props.subscription);
    const [isAddingSubscription] = useState(!props.subscription);
    const defaultValues = {
        first_name:
            props.subscription?.combinations.length > 0
                ? props.subscription.combinations[0].first_name
                : '',
        last_name:
            props.subscription?.combinations.length > 0
                ? props.subscription.combinations[0].last_name
                : '',
        horse_name:
            props.subscription?.combinations.length > 0
                ? props.subscription.combinations[0].horse_name
                : '',
        pony:
            props.subscription?.combinations.length > 0
                ? props.subscription.combinations[0].pony
                : false,
        out_of_competition: props.subscription
            ? props.subscription.out_of_competition
            : false,
        country:
            props.subscription &&
            props.subscription.combinations.length > 0 &&
            props.subscription.combinations[0].country,
    };

    const { register, handleSubmit, control, setValue, reset } = useForm({
        defaultValues,
    });
    const { t } = useTranslation();

    if (subscription !== props.subscription) {
        reset({ ...defaultValues });
        setSubscription(props.subscription);
    }

    const renderRider = () => {
        if (props.showNewRider) {
            return (
                <React.Fragment>
                    <Grid item sm={6} xs={12}>
                        <TextInput
                            label={t('forms:input.first_name')}
                            name="first_name"
                            required
                            error={props.errors && props.errors['first_name']}
                            ref={register({ required: true })}
                        />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <TextInput
                            label={t('forms:input.last_name')}
                            name="last_name"
                            required
                            error={props.errors && props.errors['last_name']}
                            ref={register({ required: true })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CountrySelect
                            label={t('forms:select.country')}
                            name="country"
                            required
                            control={control}
                            setValue={setValue}
                            error={props.errors && props.errors['country']}
                            ref={register({ required: true })}
                        />
                    </Grid>
                </React.Fragment>
            );
        }

        return (
            <Grid item xs={12}>
                <Select
                    label={t('forms:select.rider')}
                    name="rider"
                    required
                    error={props.errors && props.errors['rider']}
                    ref={register({ required: true })}
                >
                    <Link onClick={props.handleNewRider}>
                        Een nieuwe ruiter toevoegen?
                    </Link>
                </Select>
            </Grid>
        );
    };

    const renderHorse = () => {
        if (props.showNewHorse) {
            return (
                <Grid item xs={12}>
                    <TextInput
                        label={t('forms:input.horse_name')}
                        name="horse_name"
                        required
                        error={props.errors && props.errors['horse_name']}
                        ref={register({ required: true })}
                    />
                </Grid>
            );
        }

        return (
            <Grid item xs={12}>
                <Select
                    label={t('forms:select.horse')}
                    name="horse"
                    required
                    error={props.errors && props.errors['horse']}
                    ref={register({ required: true })}
                >
                    <Link onClick={props.handleNewHorse}>
                        Een nieuw paard toevoegen?
                    </Link>
                </Select>
            </Grid>
        );
    };

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} className="card-content">
            <Grid container spacing={3}>
                {renderRider()}
                {renderHorse()}

                <Grid item xs={12}>
                    <Checkbox
                        name="pony"
                        label={t('forms:checkbox.pony')}
                        checked={props.subscription?.combinations[0].pony}
                        error={props.errors && props.errors['pony']}
                        ref={register()}
                    />
                    <Checkbox
                        name="out_of_competition"
                        label={t('forms:checkbox.out_of_competition')}
                        error={
                            props.errors && props.errors['out_of_competition']
                        }
                        checked={props.subscription?.out_of_competition}
                        ref={register()}
                    />
                </Grid>
            </Grid>

            <div className="dashed-line" />

            <footer>
                <Button
                    disabled={props.isLoading}
                    fullWidth
                    type="submit"
                    disableElevation
                    variant="contained"
                    color="secondary"
                >
                    {!props.isLoading &&
                        (!isAddingSubscription
                            ? t('forms:button.edit')
                            : t('forms:button.add'))}
                    {props.isLoading && <CircularProgress size={28} />}
                </Button>
            </footer>
        </form>
    );
}

SubscriptionForm.propTypes = {
    handleNewHorse: PropTypes.func.isRequired,
    handleNewRider: PropTypes.func.isRequired,
    showNewHorse: PropTypes.bool,
    showNewRider: PropTypes.bool,
    isLoading: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    subscription: PropTypes.object,
    errors: PropTypes.array,
};

export default SubscriptionForm;
