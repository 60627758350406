import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import {
    Card,
    CardContent,
} from '@material-ui/core';
import {
    Skeleton,
} from '@material-ui/lab';

const useStyles = (theme) => ({
    label: {
        color: theme.colors.warmGrey,
        marginBottom: theme.spacing(1),
    },
    value: {
        display: 'block',
        fontSize: theme.spacing(4),
        fontWeight: 'bold',
    },
    alternative: {
        backgroundColor: theme.colors.springWood,
        boxShadow: 'none',

        '& .MuiCardContent-root:first-child': {
            padding: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        }
    }
});


class Stat extends Component {
    render() {
        const {
            classes, label, value, isLoading,
            footNote, variant,
        } = this.props;

        if (isLoading) {
            const LoadingContent = () => (
                <div>
                    <small><Skeleton height={19} width={'50%'} /></small>
                    <Skeleton height={22} width={'100%'} />
                </div>
            );

            if (variant === 'remove-background') {
                return <LoadingContent />;
            }

            return (
                <Card className={variant === 'alternative' && classes.alternative}>
                    <CardContent>
                        <LoadingContent />
                    </CardContent>
                </Card>
            );
        }

        const Content = () => (
            <div>
                <small className={classes.label}>{label}</small>
                <span className={classes.value}>{value}</span>
                { footNote && <small className={classes.footNote} dangerouslySetInnerHTML={
                    { __html: footNote }
                }/> }
            </div>
        );

        if (variant === 'remove-background') {
            return <Content />;
        }

        return (
            <Card className={variant === 'alternative' && classes.alternative}>
                <CardContent>
                    <Content />
                </CardContent>
            </Card>
        );
    }
}

Stat.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string.isRequiredIf(props => !props.isLoading),
    value: PropTypes.any.isRequiredIf(props => !props.isLoading),
    footNote: PropTypes.string,
    isLoading: PropTypes.bool,
    variant: PropTypes.oneOf(['default', 'remove-background', 'alternative']),
};

const StatStyles = withStyles(useStyles)(Stat);

export { StatStyles as Stat };
