export const ADMIN_COURSES_PAGE_LOADING = 'admin_courses_page_loading';
export const ADMIN_COURSES_PAGE_LOADING_SUCCESS = 'admin_courses_page_loading_success';
export const ADMIN_COURSES_PAGE_LOADING_FAILED = 'admin_courses_page_loading_failed';

export const ADMIN_COURSES_ACTIONS_LOADING = 'admin_courses_actions_loading';
export const ADMIN_COURSES_ACTIONS_LOADING_SUCCESS = 'admin_courses_actions_loading_success';
export const ADMIN_COURSES_ACTIONS_LOADING_FAILED = 'admin_courses_actions_loading_failed';

export const ADMIN_COURSES_RESET_CHANGED = 'admin_courses_reset_changed';
export const ADMIN_COURSES_RESET_CREATED = 'admin_courses_reset_created';
export const ADMIN_COURSES_RESET_DELETED = 'admin_courses_reset_deleted';
