import {
    DIALOG_INVITE_USER_INVITATION_ADD_LOADING,
    DIALOG_INVITE_USER_INVITATION_ADD_SUCCESS,
    DIALOG_INVITE_USER_INVITATION_ADD_FAILED,
    DIALOG_INVITE_USER_INVITATION_CHANGE_LOADING,
    DIALOG_INVITE_USER_INVITATION_CHANGE_SUCCESS,
    DIALOG_INVITE_USER_INVITATION_CHANGE_FAILED,
} from './actionTypes';
import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import {
    addUserToClub as apiAddUserToClub,
    changeUserFromClub as apiChangeUserFromClub,
} from 'services/ClubService';

export const addUserToClub = ({ id, ...data }) => {
    return (dispatch) => {
        dispatch({ type: DIALOG_INVITE_USER_INVITATION_ADD_LOADING });

        apiAddUserToClub(id, data)
            .then((result) => {
                dispatch({
                    type: DIALOG_INVITE_USER_INVITATION_ADD_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: DIALOG_INVITE_USER_INVITATION_ADD_FAILED,
                    payload: error,
                });
            });
    };
};

export const changeUserFromClub = ({ id, ...data }) => {
    return (dispatch) => {
        dispatch({ type: DIALOG_INVITE_USER_INVITATION_CHANGE_LOADING });

        apiChangeUserFromClub(id, data)
            .then((result) => {
                dispatch({
                    type: DIALOG_INVITE_USER_INVITATION_CHANGE_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: DIALOG_INVITE_USER_INVITATION_CHANGE_FAILED,
                    payload: error,
                });
            });
    };
};
