import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Backdrop,
    CircularProgress,
    DialogContentText,
    Grid,
} from '@material-ui/core';
import { Dialog } from '..';
import { Button, Chip, ShoppingCartItem, Toast } from '../..';
import { payOrder, getOrder, resetDialog } from './actions';
import { dynamicInjectReducer } from '../../../reducers/InjectReducer';
import Reducer from './reducer';
import { groupSubscriptionsByCourse } from 'utils';

const useStyles = (theme) => ({
    backDropOverlay: {
        zIndex: 1301,
        color: theme.colors.white,
        flexDirection: 'column',
    },
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    contentText: {
        color: theme.colors.mikado,
    },
    total: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
    },
    actionContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    amount: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
        textAlign: 'right',
    },
    paymentTotal: {
        textAlign: 'right',
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    paymentMethod: {
        alignItems: 'center',
        display: 'flex',
        paddingBottom: '8px',
        paddingTop: '8px',

        '& img': {
            marginRight: '12px',
            verticalAlign: 'middle',
            width: '32px',
        },

        '& small': {
            display: 'block',
        },
    },
});

class DialogOrder extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handlePayOrder = this.handlePayOrder.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.open && this.props.open) {
            // load the details of the order
            this.props.getOrder({
                id: this.props.order.id,
            });
        }
    }

    componentDidUnmount() {
        this.props.resetDialog();
    }

    getStyle(status) {
        if (status === 'payment-pending') {
            return 'warning';
        } else if (
            status === 'canceled' ||
            status === 'not-paid' ||
            status === 'partially-paid'
        ) {
            return 'error';
        } else if (status === 'paid') {
            return 'success';
        }

        return 'default';
    }

    handlePayOrder() {
        const { order } = this.props;

        // pay the order
        this.props.payOrder({
            id: order.id,
            amount: order.total_amount,
        });
    }

    renderLoader() {
        const { paymentRedirect, classes, t } = this.props;

        if (!paymentRedirect) {
            return;
        }

        window.location = paymentRedirect.payment_url; // eslint-disable-line

        return (
            <Backdrop
                className={classes.backDropOverlay}
                open={Boolean(paymentRedirect)}
            >
                <CircularProgress color="inherit" />
                <div className={classes.loader}>
                    {t('common:text.to_payment')}
                </div>
            </Backdrop>
        );
    }

    renderPayButton() {
        const { order, t, isProcessingPayment } = this.props;

        if (order.status === 'draft' || order.status === 'partially-paid') {
            return (
                <Button
                    disabled={isProcessingPayment}
                    isLoading={isProcessingPayment}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={this.handlePayOrder}
                >
                    {t('common:button.pay_now')}
                </Button>
            );
        } else if (
            order.status === 'not-paid' ||
            order.status === 'payment-failed'
        ) {
            return (
                <Button
                    disabled={isProcessingPayment}
                    isLoading={isProcessingPayment}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={this.handlePayOrder}
                >
                    {t('common:button.retry_payment')}
                </Button>
            );
        }
    }

    renderToast() {
        const { paymentError, t } = this.props;

        return (
            <Toast
                message={
                    paymentError &&
                    (paymentError.non_field_errors ||
                        t('common:error.general.description'))
                }
                open={Boolean(paymentError)}
            />
        );
    }

    renderPayments() {
        const { order, classes } = this.props;

        return (
            <React.Fragment>
                {order.payments?.map((payment) => {
                    return (
                        <React.Fragment>
                            <Grid item xs={6} className={classes.paymentMethod}>
                                <div>
                                    {payment.image && (
                                        <img
                                            src={payment.image.size2x}
                                            alt={
                                                payment.payment_method_description
                                            }
                                        />
                                    )}
                                </div>
                                <div>
                                    <strong>
                                        {payment.payment_method_description}
                                    </strong>
                                    <small>
                                        {moment(payment.paid_at).format('LLL')}
                                    </small>
                                </div>
                            </Grid>
                            <Grid item xs={6} className={classes.paymentTotal}>
                                &euro;&nbsp;{payment.total_amount}
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }

    renderTotalLeft() {
        const { order, t, classes } = this.props;

        if (order.payments?.length > 0) {
            return (
                <React.Fragment>
                    <Grid item xs={6} className={classes.total}>
                        {t('common:title.total_left')}
                    </Grid>
                    <Grid item xs={6} className={classes.amount}>
                        &euro;&nbsp;{order.total_amount_left}
                    </Grid>
                </React.Fragment>
            );
        }
    }

    renderSubscriptions() {
        const { order, isLoadingOrder } = this.props;

        if (isLoadingOrder) {
            return (
                <Grid item xs={12}>
                    <ShoppingCartItem isLoading hideActions />
                </Grid>
            );
        }

        const subscriptions = groupSubscriptionsByCourse(order.subscriptions);
        const refundSubscriptions = groupSubscriptionsByCourse(
            order.refund_subscriptions
        );

        return (
            <Grid item xs={12}>
                {Object.entries(subscriptions).map(([key, value]) => (
                    <ShoppingCartItem
                        key={key}
                        course={value.course_object}
                        event={value.event_object}
                        subscriptions={value.subscriptions}
                        amount={value.total_amount}
                        hideActions
                    />
                ))}
                {Object.entries(refundSubscriptions).map(([key, value]) => (
                    <ShoppingCartItem
                        key={key}
                        course={value.course_object}
                        event={value.event_object}
                        subscriptions={value.subscriptions}
                        amount={value.total_amount}
                        hideActions
                    />
                ))}
            </Grid>
        );
    }

    render() {
        const { classes, onClose, open, t, order } = this.props;
        const count =
            order.amount_subscriptions + order.amount_refund_subscriptions;

        return (
            <React.Fragment>
                {this.renderLoader()}
                <Dialog
                    open={open}
                    onClose={onClose}
                    title={`${t('common:title.order')} #${order.id}`}
                >
                    {this.renderToast()}
                    <Grid container>
                        <Grid item xs={12}>
                            <DialogContentText
                                classes={{ root: classes.contentText }}
                            >
                                <small>
                                    <Trans
                                        i18nKey="text.amount_subscription"
                                        count={count}
                                        ns="common"
                                    >
                                        {{ count }} subscriptions
                                    </Trans>
                                </small>
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="dashed-line" />
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <strong>
                                <small>{t('common:title.status')}</small>
                            </strong>
                            <br />
                            <Chip
                                label={order.status_description}
                                variant="default"
                                type={this.getStyle(order.status)}
                            />
                        </Grid>
                        <Grid item sm={6} xs={6}>
                            <strong>
                                <small>{t('common:title.created_at')}</small>
                            </strong>
                            <br />
                            {moment(order.created_at).format('LL')}
                        </Grid>
                        <Grid item xs={12}>
                            <div className="dashed-line" />
                        </Grid>
                        {this.renderSubscriptions()}
                        <Grid item xs={12}>
                            <div className="dashed-line" />
                        </Grid>
                        <Grid item xs={6} className={classes.total}>
                            {t('common:title.total')}
                        </Grid>
                        <Grid item xs={6} className={classes.amount}>
                            &euro;&nbsp;{order.total_amount}
                        </Grid>
                        {this.renderPayments()}
                        {this.renderTotalLeft()}
                    </Grid>
                </Dialog>
            </React.Fragment>
        );
    }
}

DialogOrder.propTypes = {
    // props
    open: PropTypes.bool,
    order: PropTypes.object,
    paymentRedirect: PropTypes.string,
    isProcessingPayment: PropTypes.bool,
    isLoadingOrder: PropTypes.bool,
    paymentError: PropTypes.object,
    key: PropTypes.string.isRequired,
    // event handlers
    onClose: PropTypes.func.isRequired,
    getOrder: PropTypes.func.isRequired,
    payOrder: PropTypes.func.isRequired,
    resetDialog: PropTypes.func.isRequired,
    // other
    classes: PropTypes.object.isRequired,
    t: PropTypes.object.isRequired,
};

DialogOrder.defaultProps = {
    open: false,
};

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.reducerKey || !state[ownProps.reducerKey]) {
        return {};
    }

    const {
        isProcessingPayment,
        paymentError,
        paymentRedirect,
        isLoadingOrder,
        order,
        orderError,
    } = state[ownProps.reducerKey];

    return {
        isProcessingPayment,
        paymentError,
        paymentRedirect,
        isLoadingOrder,
        order: order || ownProps.order,
        orderError,
        key: ownProps.reducerKey,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        payOrder: ({ id, amount }) =>
            dispatch(
                payOrder({
                    id,
                    amount,
                    key: ownProps.reducerKey,
                })
            ),
        getOrder: ({ id }) =>
            dispatch(
                getOrder({
                    id,
                    key: ownProps.reducerKey,
                })
            ),
        resetDialog: () =>
            dispatch(
                resetDialog({
                    key: ownProps.reducerKey,
                })
            ),
    };
};

const DialogOrderStyled = withStyles(useStyles)(DialogOrder);
const DialogOrderTranslated = withTranslation()(DialogOrderStyled);
const DialogOrderState = connect(
    mapStateToProps,
    mapDispatchToProps
)(DialogOrderTranslated);
const DialogOrderReduced = dynamicInjectReducer({
    key: `dialogOrder`,
    reducer: Reducer,
})(DialogOrderState);

export { DialogOrderReduced as DialogOrder };
