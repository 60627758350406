import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    FormControl,
    TextField,
    InputAdornment,
    IconButton,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import PropTypes from 'prop-types';

const useStyles = (theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    helpText: {
        color: theme.colors.warmGrey,
        marginTop: '4px',
    },
});

class TextInput extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            passwordVisible: false,
        };

        // event handlers
        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(
            this
        );
    }

    togglePasswordVisibility() {
        this.setState({
            passwordVisible: !this.state.passwordVisible,
        });
    }

    renderChildren() {
        if (this.props.children) {
            return <div className="children">{this.props.children}</div>;
        }
    }

    renderEndIcon() {
        const { password } = this.props;

        let icon = <VisibilityIcon fontSize="small" />;
        if (this.state.passwordVisible) {
            icon = <VisibilityOffIcon fontSize="small" />;
        }

        if (password) {
            return {
                InputProps: {
                    endAdornment: (
                        <InputAdornment>
                            <IconButton onClick={this.togglePasswordVisibility}>
                                {icon}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            };
        }
    }

    renderError() {
        const { error } = this.props;

        if (error) {
            return <small className="error">{error}</small>;
        }
    }

    renderHelpText() {
        const { helpText, classes } = this.props;

        if (helpText) {
            return <small className={classes.helpText}>{helpText}</small>;
        }
    }

    renderContent() {
        const { password, email, number } = this.props;

        let type = 'input';
        if (password) {
            if (this.state.passwordVisible) {
                type = 'input';
            } else {
                type = 'password';
            }
        } else if (email) {
            type = 'email';
        } else if (number) {
            type = 'number';
        }

        return (
            <FormControl
                className={!this.props.removeForm ? 'form-control' : ''}
            >
                <TextField
                    {...this.props.params}
                    InputLabelProps={{ shrink: true }}
                    label={this.props.label}
                    required={this.props.required}
                    name={this.props.name}
                    type={type}
                    multiline={this.props.multiline}
                    placeholder={this.props.placeholder}
                    inputRef={this.props.innerRef}
                    inputProps={{
                        min: this.props.min,
                        ...this.props.inputProps,
                    }}
                    {...this.renderEndIcon()}
                    error={Boolean(this.props.error)}
                />
                {this.renderHelpText()}
                {this.renderError()}
                {this.renderChildren()}
            </FormControl>
        );
    }

    render() {
        const { mask, maskPlaceholder } = this.props;

        if (mask) {
            return (
                <InputMask
                    mask={mask}
                    maskPlaceholder={maskPlaceholder}
                    value={this.props.value}
                >
                    {() => this.renderContent()}
                </InputMask>
            );
        }

        return this.renderContent();
    }
}

TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    innerRef: PropTypes.func.isRequired,
    value: PropTypes.object,
    password: PropTypes.bool,
    multiline: PropTypes.bool,
    email: PropTypes.bool,
    number: PropTypes.bool,
    required: PropTypes.bool,
    children: PropTypes.object,
    error: PropTypes.object,
    inputProps: PropTypes.object,
    params: PropTypes.object,
    classes: PropTypes.object,
    placeholder: PropTypes.string,
    removeForm: PropTypes.bool,
    helpText: PropTypes.string,
    min: PropTypes.number,
    removeSpacing: PropTypes.bool,
    mask: PropTypes.string,
    maskPlaceholder: PropTypes.string,
};

// use ref forwarding for react-hook-form to work with class based components
const TextInputStyled = withStyles(useStyles)(
    React.forwardRef((props, ref) => <TextInput innerRef={ref} {...props} />)
);

export { TextInputStyled as TextInput };
