import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TabPanel extends Component {
    render() {
        const { children, value, index, ...other } = this.props;

        // alert('value: ' + value + ' index: ' + index)

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && children}
            </div>
        );
    }
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired,
};

export { TabPanel };
