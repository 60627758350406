export const DIALOG_INVITE_USER_INVITATION_ADD_LOADING =
    'dialog_invite_user_invitation_add_loading';
export const DIALOG_INVITE_USER_INVITATION_ADD_SUCCESS =
    'dialog_invite_user_invitation_add_success';
export const DIALOG_INVITE_USER_INVITATION_ADD_FAILED =
    'dialog_invite_user_invitation_add_failed';

export const DIALOG_INVITE_USER_INVITATION_CHANGE_LOADING =
    'dialog_invite_user_invitation_change_loading';
export const DIALOG_INVITE_USER_INVITATION_CHANGE_SUCCESS =
    'dialog_invite_user_invitation_change_success';
export const DIALOG_INVITE_USER_INVITATION_CHANGE_FAILED =
    'dialog_invite_user_invitation_change_failed';
