import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { DialogContentText, Grid } from '@material-ui/core';
import { Dialog } from '..';

const useStyles = (theme) => ({
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    contentText: {
        color: theme.colors.mikado,
        marginTop: '15px',
        textAlign: 'center',
    },
});

class DialogQR extends Component {
    render() {
        const { classes, onClose, open, t, ticket } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={t('common:title.qr_code')}
                showLine
            >
                <Grid container>
                    <Grid item xs={12}>
                        <DialogContentText
                            classes={{ root: classes.contentText }}
                        >
                            <QRCode
                                value={`https://www.equento.be/user/tickets/?unique=${ticket.unique_id}`}
                                renderAs={'svg'}
                            />
                        </DialogContentText>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

DialogQR.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    ticket: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

DialogQR.defaultProps = {
    open: false,
};

const DialogQRStyled = withStyles(useStyles)(DialogQR);
const DialogQRTranslated = withTranslation()(DialogQRStyled);

export { DialogQRTranslated as DialogQR };
