import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { getUrl, urls } from 'urls';
import { Helmet } from 'react-helmet';

import { Alert } from '@material-ui/lab';
import { CheckCircle as CheckCircleIcon } from '@material-ui/icons';

import { TemplateLogin } from '../TemplateLogin';
import { EmptyMessage } from 'components';
import { ResetPasswordForm } from './forms';

import PropTypes from 'prop-types';

// actions
import { resetPasswordConfirm } from './actions';

const useStyles = (theme) => ({
    green: {
        color: theme.colors.mediumLaurel,
    },
});


class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);

        // params for state
        this.state = {
            uid: this.props.match.params.uid,
            token: this.props.match.params.token,
        };

        // event handlers
        this.handleNewPassword = this.handleNewPassword.bind(this);
    }

    handleNewPassword(data) {
        this.props.resetPasswordConfirm({
            uid: this.state.uid,
            token: this.state.token,
            ...data,
        });
    }

    renderGeneralFormErrors() {
        const { errors, t } = this.props;

        if (errors) {
            if (Object.keys(errors).indexOf('token') >= 0) {
                return (
                    <Alert severity={'error'}>
                        {t('common:error.invalid')}
                    </Alert>
                );
            }

            return (
                <Alert severity={'error'}>
                    {errors['non_field_errors'] ||
                        t('common:error.general.description')}
                </Alert>
            );
        }
    }

    renderForgotPasswordForm() {
        const { isLoading, t } = this.props;

        return (
            <React.Fragment>
                {this.renderGeneralFormErrors()}

                <p>{t('common:description.intro_reset_password')}</p>

                <ResetPasswordForm
                    onSubmit={this.handleNewPassword}
                    isLoading={isLoading}
                    formErrors={this.props.errors}
                />
            </React.Fragment>
        );
    }

    renderSuccessMessage() {
        const { t, classes } = this.props;

        return (
            <EmptyMessage
                title={t('common:title.reset_password_confirmed')}
                description={t('common:description.reset_password_confirmed')}
                icon={
                    <CheckCircleIcon
                        fontSize={'large'}
                        className={classes.green}
                    />
                }
                textSize={'small'}
                actionText={t('common:button.login')}
                action={() => this.props.history.push(getUrl(urls.LOGIN))}
            />
        );
    }

    render() {
        const { isSuccess, t, location } = this.props;

        return (
            <TemplateLogin
                title={t('common:title.reset_password')}
                description={t('common:description.reset_password')}
            >
                <Helmet>
                    <title>
                        {t('common:title.reset_password_short')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(location.pathname, {}, true)}
                    />
                </Helmet>
                {isSuccess
                    ? this.renderSuccessMessage()
                    : this.renderForgotPasswordForm()}
            </TemplateLogin>
        );
    }
}

ResetPasswordPage.propTypes = {
    // props
    match: PropTypes.object.isRequired,
    errors: PropTypes.object,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    // events
    resetPasswordConfirm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { isLoading, errors, isSuccess } = state.pageResetPassword;

    return {
        errors,
        isLoading,
        isSuccess,
    };
};

const ResetPasswordPageState = connect(mapStateToProps, {
    resetPasswordConfirm,
})(ResetPasswordPage);

const ResetPasswordPageTranslated = withTranslation()(ResetPasswordPageState);
const ResetPasswordPageRouter = withRouter(ResetPasswordPageTranslated);
const ResetPasswordPageStyled = withStyles(useStyles)(ResetPasswordPageRouter);

export { ResetPasswordPageStyled as ResetPasswordPage };
