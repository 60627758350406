import axios from 'axios';
import { serialize } from 'object-to-formdata';
import i18n from 'i18next';

import apiConfig from './config';
import ApiError from './exceptions';
import StoreUtil from 'lib/StoreUtil';
import AlertService from 'lib/AlertService';

// import Cookie from 'js-cookie';

// setup cookie stuff
axios.defaults.withCredentials = true;

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

// axios.interceptors.response.use((response) => {
//     const sessionCookie = Cookie.get();
//     global.log('Cookie', sessionCookie);
//     return response;
// });

// cookieJar.getCookies('/', (err, cookies) => {
//     alert(err);
//     global.log(cookies);
// });

// import {
//     userOffline,
//     resetToLogin,
//     resetToPremium,
//     resetToUltimate,
//     resetToError,
// } from '../../actions/globalActions';
// import { showAlert, hideAlert } from '../../actions/appActions';

// const showAlertMessage = (e) => {
//     if (e.code !== 400) {
//         if (!store.getState().app.alertShown) {
//             global.log(e.response);
//             global.log(e);
//             global.log(e.code);
//             if (e.response && e.response.detail) {
//                 // premium alert
//                 Alert.alert(
//                     'Oops',
//                     e.response.detail,
//                     [
//                         {
//                             text: 'OK',
//                             style: 'cancel',
//                             onPress: () => store.dispatch(hideAlert())
//                         },
//                     ]
//                 );
//             } else {
//                 Alert.alert(
//                     'Oops',
//                     'Er liep iets fout. Probeer het opnieuw.',
//                     [
//                         {
//                             text: 'OK',
//                             style: 'cancel',
//                             onPress: () => store.dispatch(hideAlert())
//                         },
//                     ]
//                 );
//             }
//
//             store.dispatch(showAlert());
//         }
//     }
//
//     return;
// };

const apiError = (error) => {
    global.log('>>> fetch error ');
    global.log(error.name);
    global.log(error);
    global.log(error.message);
    // global.log(error.response.data)

    if (error.name === 'Error' && error.message === 'Network Error') {
        // user is offline, call offline screen with globel dispatcher function
        // store.dispatch(userOffline());
        global.log('>> you are offline');
        return Promise.reject('Offline');
    }

    if (error.name === 'ApiError') {
        global.log('>> api error');
        global.log(error.code);
        global.log(error.response);
        global.log(error.response_obj);
        global.log('<< / api error');

        if (error.code === 401) {
            // store.dispatch(resetToLogin());
            global.log('>> not logged in');
            return;
        }

        if (error.code === 500) {
            // show it only once
            global.log('>> SERVER ERROR <<');
            // store.dispatch(showAlert());
            // store.dispatch(resetToError());
            AlertService.showError({
                title: 'Woops!',
                description: 'Er ging iets mis.',
            });
            global.log('>> server error');
            throw error;
            // return;
        }

        // collect response
        if (error.response_obj) {
            return error.response_obj.json().then((result) => {
                global.log(result);
                error.response = result;

                if (Object.keys(result).indexOf('code') > -1) {
                    // if premium, show message
                    if (result.code === 'PREMIUM_REQUIRED') {
                        // store.dispatch(resetToPremium());
                    }

                    // if ultimate, show message
                    if (result.code === 'ULTIMATE_REQUIRED') {
                        // store.dispatch(resetToUltimate());
                    }
                } else {
                    // showAlertMessage(error);
                    global.log('>> show alert message ', error);
                }
                throw error;
            });
        }

        global.log('>>>> no response object');
        // show error when not 400
        // showAlertMessage(error);

        global.log('>>>> throwing!!!!');
        throw error;
    }

    // if (error.response && error.response.json) {
    //     error.response.json().then((json) => {
    //         if (json) return json;
    //         return error;
    //     });
    // }
    if (error.response) {
        return Promise.reject(error.response.data);
    }

    return Promise.reject('Unknown');
};

const apiSuccess = (result, exposeHeaders) => {
    global.log('>> API result');
    global.log(result);

    switch (result.status) {
        case 200:
            if (exposeHeaders) {
                return result;
            } else {
                return result.data;
            }
        case 201: // created
            if (exposeHeaders) {
                return result;
            } else {
                return result.data;
            }
        case 204:
            if (exposeHeaders) {
                return result;
            } else {
                return result.data;
            }
        case 400: // bad request
            throw new ApiError(400, 'Bad request', result);
        case 401: // Unauthorized, ongeldige token
            throw new ApiError(401, 'Unauthorized', result);
        case 403: // forbidden
            throw new ApiError(403, 'Forbidden', result);
        case 404: // not found
            throw new ApiError(404, 'Not found');
        case 405: // method not allowed
            throw new ApiError(405, 'Method not allowed');
        case 500: // server error
            throw new ApiError(500, 'Server error');
        case 502: // server error
            throw new ApiError(500, 'Bad gateway');
        case 504: // gateway timeout
            throw new ApiError(504, 'Timeout');
        default:
            global.log('>> also returning');
            return result;
    }
};

const fetchApi = ({
    url,
    data = {},
    method = 'GET',
    headers = {},
    useAccessToken = true,
    hasFiles = false,
    responseType = 'json',
    exposeHeaders = false,
}) => {
    const accessToken = StoreUtil.getStore().getState().authentication.token;
    // const accessToken = '';
    let getParams = '';
    let payload = '';

    if (method === 'GET') {
        getParams = data;
    } else {
        // convert payload to FormData
        global.log('>>> converting to formdata ', data);
        if (hasFiles) {
            payload = serialize(data, {
                indices: true,
            });
        } else {
            payload = data;
        }
    }

    // headers
    const apiHeaders = {
        ...headers,
        Accept: 'application/json',
        'Accept-Language': i18n.language,
    };
    if (hasFiles) {
        apiHeaders['Content-Type'] = 'multipart/form-data';
    } else {
        apiHeaders['Content-Type'] = 'application/json';
    }

    if (accessToken && useAccessToken) {
        apiHeaders.Authorization = `Token ${accessToken}`;
    }

    // if (wait) {
    //     return async() => {
    //         await fetch(callingUrl + getParams, params)
    //             .then(result => apiSuccess(result))
    //             .catch(error => apiError(error));
    //         }
    // }

    global.log('>>> data', payload, data);
    global.log('>>> params ', getParams);
    global.log('>>> method ', method);
    global.log('>>> headers ', apiHeaders);

    return (
        axios({
            method: method,
            baseURL: apiConfig.url,
            url: url,
            params: getParams,
            data: payload,
            headers: apiHeaders,
            responseType: responseType ? responseType : 'json',
        })
            // .then((response) => response.json())
            .then((result) => apiSuccess(result, exposeHeaders))
            .catch((error) => apiError(error, exposeHeaders))
    );
    // global.log('>> netinfo');
    // return NetInfo.isConnected.fetch().then(result => {
    //     if (!result) {
    //         store.dispatch(userOffline());
    //         throw new Exception('User offline');
    //     } else {
    //         return fetch(callingUrl + getParams, params)
    //             // .then((response) => response.json())
    //             .then((result) => apiSuccess(result))
    //             .catch((error) => apiError(error));
    //     }
    // });
};

export { fetchApi as default };
