import _ from 'lodash';
import {
    COURSE_DETAIL_PAGE_LOADING,
    COURSE_DETAIL_PAGE_LOADING_SUCCESS,
    COURSE_DETAIL_PAGE_LOADING_FAILED,
} from './actionTypes';
import { DIALOG_SUBSCRIPTION_SUCCESS } from 'components/Dialogs/DialogSubscription/actionTypes';
import { SUBSCRIPTION_BASKET_DELETE_SUCCESS } from 'components/Dialogs/DialogSubscriptionDelete/actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    course: null,
    isDeleted: false,
    isCreated: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DIALOG_SUBSCRIPTION_SUCCESS:
            if (state.course) {
                let course = JSON.parse(JSON.stringify(state.course)); // deep copy
                course.subscriptions.push(action.payload);

                return {
                    ...state,
                    course: course,
                    isCreated: true,
                };
            }

            return {
                ...state,
            };
        case SUBSCRIPTION_BASKET_DELETE_SUCCESS:
            if (action.payload && action.payload.id) {
                let course = JSON.parse(JSON.stringify(state.course)); // deep copy
                let objects = _.remove(course.subscriptions, (obj) => {
                    return action.payload.id !== obj.id;
                });
                course.subscriptions = objects;

                return {
                    ...state,
                    course: course,
                    isDeleted: true,
                };
            }

            return {
                ...state,
            };
        case COURSE_DETAIL_PAGE_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case COURSE_DETAIL_PAGE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                course: action.payload,
            };
        case COURSE_DETAIL_PAGE_LOADING_FAILED:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
