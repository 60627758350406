import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { getUrl, urls } from 'urls';

import { Avatar, Container, Grid, Tabs, Tab } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    Orders,
    Profile,
    TabPanel,
    Toast,
    Tickets,
    Notifications,
} from 'components';

import { getUser } from 'actions/user';
import { getProfileThroughUser } from 'actions/profile';

const useStyles = (theme) => ({
    tabs: {
        margin: '40px 0 0',
    },
    border: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: theme.colors.bone,
        marginBottom: '40px',
    },
    tab: {
        textTransform: 'none',
        fontWeight: 'bold',
    },
    container: {
        alignItems: 'center',
        display: 'flex',
    },
    content: {
        marginLeft: '15px',
    },
});

class UserPage extends Component {
    constructor(props) {
        super(props);

        // render correct subpage
        let subpage = props.match.params.subpage;
        let selectedTab = getUrl(urls.USER_ORDERS);
        if (subpage) {
            selectedTab = getUrl(urls.USER_SUBPAGE, { subpage: subpage });
        }

        // state
        this.state = {
            selectedTab: selectedTab,
            routes: [
                {
                    path: getUrl(urls.USER_PROFILE),
                    name: props.t('common:text.menu.profile'),
                    component: Profile,
                },
                {
                    path: getUrl(urls.USER_ORDERS),
                    name: props.t('common:text.menu.orders'),
                    component: Orders,
                },
                {
                    path: getUrl(urls.USER_TICKETS),
                    name: props.t('common:text.menu.tickets'),
                    component: Tickets,
                },
                {
                    path: getUrl(urls.USER_NOTIFICATIONS),
                    name: props.t('common:text.menu.notifications'),
                    component: Notifications,
                },
            ],
        };

        // event handlers
        this.handleChange = this.handleChange.bind(this);

        // load the user and profile
        this.props.getUser();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.match.params.subpage !== this.props.match.params.subpage
        ) {
            this.setState({
                selectedTab: getUrl(urls.USER_SUBPAGE, {
                    subpage: this.props.match.params.subpage,
                }),
            });
        }

        if (prevProps.user !== this.props.user && this.props.user) {
            if (!this.props.profile) {
                this.props.getProfileThroughUser(this.props.user.id);
            }
        }
    }

    getProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    handleChange(event, value) {
        this.setState({
            selectedTab: value,
        });
        this.props.history.push(value);
    }

    renderUser() {
        const { user, isLoadingUser, classes, errorUser, t } = this.props;

        if (isLoadingUser) {
            return (
                <React.Fragment>
                    <Skeleton height={30} width={200} />
                    <Skeleton height={22} width={160} />
                </React.Fragment>
            );
        }

        if (errorUser || !user) {
            return <Toast message={t('common:error.user_not_loaded')} open />;
        }

        return (
            <div className={classes.container}>
                <Avatar>
                    {user.first_name[0].toUpperCase()}
                    {user.last_name[0].toUpperCase()}
                </Avatar>
                <div className={classes.content}>
                    <strong>
                        {user.first_name} {user.last_name}
                    </strong>
                    <br />
                    <small>
                        {user.email}{' '}
                        {user.change_email_requested &&
                            `(${t('common:text.change_email_requested')})`}
                    </small>
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        const { selectedTab } = this.state;

        return (
            <React.Fragment>
                <div className={classes.border}>
                    <Container>
                        <Grid container display="flex">
                            <Grid item xs={12}>
                                {this.renderUser()}
                            </Grid>
                            <Grid item xs={12}>
                                <Tabs
                                    value={selectedTab}
                                    onChange={this.handleChange}
                                    variant="scrollable"
                                    scrollButton="auto"
                                    classes={{
                                        root: classes.tabs,
                                    }}
                                >
                                    {this.state.routes.map((obj, index) => (
                                        <Tab
                                            classes={{ root: classes.tab }}
                                            value={obj.path}
                                            label={obj.name}
                                            {...this.getProps(index)}
                                        />
                                    ))}
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Container>
                    {this.state.routes.map((obj, index) => {
                        const Component = obj.component;
                        return (
                            <TabPanel value={selectedTab} index={obj.path}>
                                <Component />
                            </TabPanel>
                        );
                    })}
                </Container>
            </React.Fragment>
        );
    }
}

UserPage.propTypes = {
    // props
    user: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    isLoadingUser: PropTypes.bool,
    errorUser: PropTypes.object,
    // handlers
    getUser: PropTypes.func.isRequired,
    getProfileThroughUser: PropTypes.func.isRequired,
    // other
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state.user;
    const { profile } = state.profile;

    return {
        user,
        profile,
        errorUser: state.user.error,
        errorProfile: state.profile.error,
        isLoadingUser: state.user.isLoading,
        isLoadingProfile: state.profile.isLoading,
    };
};

const UserPageTranslated = withTranslation()(UserPage);
const UserPageRouter = withRouter(UserPageTranslated);
const UserPageStyled = withStyles(useStyles)(UserPageRouter);
const UserPageState = connect(mapStateToProps, {
    getUser,
    getProfileThroughUser,
})(UserPageStyled);

export { UserPageState as UserPage };
