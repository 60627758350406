import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import { urls, getUrl } from 'urls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { Skeleton } from '@material-ui/lab';
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';
import {
    AdminEventCourses,
    AdminEventTickets,
    AdminEventAdministration,
    AdminEventStatistics,
    AdminHeader,
    EmptyMessage,
    TabPanel,
    Tabs,
    TemplateAdmin,
    PopupMenuAdminEvent,
} from 'components';
import {
    getObjectFromCache,
    getEventDays,
    AdminContext,
    getAuthorizationsKey,
} from '../../utils';

import { getEvent } from './actions';

const useStyles = (theme) => ({
    container: {
        alignItems: 'center',
        display: 'flex',
    },
    content: {
        marginLeft: '15px',
    },
    border: {
        borderBottomColor: theme.colors.bone,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        marginBottom: theme.spacing(5),
    },
});

class AdminEventPage extends Component {
    static contextType = AdminContext; // eslint-disable-line react/sort-comp

    constructor(props) {
        super(props);

        // render correct subpage
        const { location } = this.props;
        const query = new URLSearchParams(location.search.slice(1)); // eslint-disable-line no-undef
        let subpage = query.get('s');

        let selectedTab = 'courses';
        if (subpage) {
            selectedTab = subpage;
        }

        // state
        this.state = {
            selectedTab: selectedTab,
            eventId: this.props.match.params.eventId,
            clubName: null,
            club: null,
            routes: [
                {
                    path: 'courses',
                    name: props.t('common:text.menu.courses'),
                    component: AdminEventCourses,
                },
                {
                    path: 'tickets',
                    name: props.t('common:text.menu.tickets'),
                    component: AdminEventTickets,
                },
                {
                    path: 'statistics',
                    name: props.t('common:text.menu.statistics'),
                    component: AdminEventStatistics,
                },
                {
                    path: 'admin',
                    name: props.t('common:text.menu.admin'),
                    component: AdminEventAdministration,
                },
            ],
        };

        // event handlers
        this.handleChange = this.handleChange.bind(this);
        this.renderDescription = this.renderDescription.bind(this);

        // load event
        this.props.getEvent(this.state.eventId);
    }

    componentDidMount() {
        const { t } = this.props;

        this.context.setAdminTitle(t('common:title.event_detail'));
        this.context.setAdminBackAction(() =>
            this.props.history.push(urls.ADMIN_EVENTS)
        );
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.match.params.subpage !== this.props.match.params.subpage
        ) {
            this.setState({
                selectedTab: this.props.match.params.subpage,
            });
        }

        if (prevProps.event !== this.props.event && !this.state.clubName) {
            getObjectFromCache({
                url: this.props.event?.club,
                success: (result) => {
                    this.setState({
                        clubName: result['name'],
                        club: result,
                    });
                },
                error: () => {
                    // do something TODO
                },
            });
        }
    }

    handleChange(event, value) {
        // set in state
        this.setState({
            selectedTab: value,
        });
        // set in url
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: '?s=' + value,
        });
    }

    getTabsForUser() {
        // return the tabs that should be visible for the logged in user
        const { user, authorizations, event } = this.props;
        const { club } = this.state;

        if (!user || !authorizations) {
            return;
        }

        const keyClub = getAuthorizationsKey(club?.id, 'club');
        const keyEvent = getAuthorizationsKey(event?.id, 'event');
        let level = null;
        let routes = null;

        // console.log('>>>>>>>>>>>>', authorizations, keyClub, keyEvent);

        if (Object.keys(authorizations).indexOf(keyEvent) >= 0) {
            level = authorizations[keyEvent].level;
        } else if (Object.keys(authorizations).indexOf(keyClub) >= 0) {
            level = authorizations[keyClub].level;
        }

        if (level === 'gate') {
            const validPaths = ['courses'];
            routes = _.reduce(
                this.state.routes,
                function (result, value, key) {
                    if (validPaths.indexOf(value.path) >= 0) {
                        result.push(value);
                    }
                    return result;
                },
                []
            );
        } else if (level === 'secretariat') {
            const validPaths = ['courses', 'tickets', 'statistics', 'admin'];
            routes = _.reduce(
                this.state.routes,
                function (result, value, key) {
                    if (validPaths.indexOf(value.path) >= 0) {
                        result.push(value);
                    }
                    return result;
                },
                []
            );
        } else if (level === 'admin') {
            const validPaths = ['courses', 'tickets', 'statistics', 'admin'];
            routes = _.reduce(
                this.state.routes,
                function (result, value, key) {
                    if (validPaths.indexOf(value.path) >= 0) {
                        result.push(value);
                    }
                    return result;
                },
                []
            );
        }

        if (routes) {
            return routes.map((obj, index) => ({
                value: obj.path,
                label: obj.name,
            }));
        }

        return;
    }

    renderDescription() {
        const { event } = this.props;

        return (
            <small>
                {this.state.clubName ? (
                    this.state.clubName
                ) : (
                    <Skeleton
                        height={25}
                        width={100}
                        style={{ display: 'inline-block' }}
                    />
                )}{' '}
                &ndash; {getEventDays(event?.days)['text']}
            </small>
        );
    }

    renderHeader() {
        const { event, isLoading, t } = this.props;

        if (isLoading) {
            return (
                <AdminHeader
                    isLoading
                    withTitle
                    withDescription
                    withBreadcrumbs
                    variant={'small'}
                    removeMargin
                />
            );
        }

        // backAction={() => this.history.push(getUrl(urls.ADMIN_EVENT_DETAIL, { eventId: this.state.eventId }))}

        return (
            <AdminHeader
                title={event?.name}
                descriptionContainer={this.renderDescription}
                variant={'small'}
                breadcrumbs={[
                    {
                        title: t('common:text.menu.events'),
                        url: urls.ADMIN_EVENTS,
                    },
                    {
                        title: this.props.event?.name,
                        url: getUrl(urls.ADMIN_EVENT_DETAIL, {
                            eventId: this.state.eventId,
                        }),
                    },
                ]}
                removeMargin
                actionContainer={this.renderActions()}
            />
        );
    }

    renderActions() {
        const { event, isLoading } = this.props;

        return (
            <PopupMenuAdminEvent
                event={event}
                isLoading={isLoading}
                isViewedOnDetailPage
            />
        );
    }

    renderNotFound() {
        const { t } = this.props;

        return (
            <EmptyMessage
                icon={<CancelOutlinedIcon />}
                title={t('common:title.not_found')}
                description={t('common:description.event.not_found')}
            />
        );
    }

    renderTabs() {
        const { classes } = this.props;
        const { selectedTab } = this.state;
        const tabs = this.getTabsForUser();

        if (!tabs || tabs.length === 0) {
            return <div style={{ height: '24px' }} />;
        }

        return (
            <Tabs
                selectedTab={selectedTab}
                onTabChange={this.handleChange}
                variant="scrollable"
                scrollButton="auto"
                classes={{
                    root: classes.tabs,
                }}
                tabs={tabs}
            />
        );
    }

    render() {
        const { isLoading, error, event, t } = this.props;
        const { selectedTab } = this.state;

        if (!isLoading && error) {
            return this.renderNotFound();
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {event ? event?.name : t('common:title.event_detail')} |{' '}
                        {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(this.props.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateAdmin header removeBottomPadding>
                    {this.renderHeader()}
                    {this.renderTabs()}
                </TemplateAdmin>

                <TemplateAdmin>
                    {this.state.routes.map((obj, index) => {
                        const Component = obj.component;
                        return (
                            <TabPanel value={selectedTab} index={obj.path}>
                                <Component
                                    event={this.props.event}
                                    isLoadingEvent={this.props.isLoading}
                                />
                            </TabPanel>
                        );
                    })}
                </TemplateAdmin>
            </React.Fragment>
        );
    }
}

AdminEventPage.propTypes = {
    // props
    authorizations: PropTypes.object,
    event: PropTypes.object,
    isLoading: PropTypes.bool,
    window: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    classes: PropTypes.object,
    error: PropTypes.array,
    user: PropTypes.object.isRequired,
    // handlers
    t: PropTypes.func.isRequired,
    getEvent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { isLoading, event, error } = state.pageAdminEvent;
    const { user } = state.user;
    const { authorizations } = state.authorizations;

    return {
        isLoading,
        event,
        error,
        user,
        authorizations,
    };
};

const AdminEventPageTranslated = withTranslation()(AdminEventPage);
const AdminEventPageStyled = withStyles(useStyles)(AdminEventPageTranslated);
const AdminEventPageState = connect(mapStateToProps, {
    getEvent,
})(AdminEventPageStyled);
const AdminEventPageRouter = withRouter(AdminEventPageState);
export { AdminEventPageRouter as AdminEventPage };
