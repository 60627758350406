import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Grid, CircularProgress } from '@material-ui/core';
import { TextInput } from 'components';

import PropTypes from 'prop-types';

function ResetPasswordForm(props) {
    const { register, handleSubmit, watch, errors } = useForm();
    const { t } = useTranslation();
    const password = useRef({});
    password.current = watch('new_password', '');

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <form onSubmit={handleSubmit(props.onSubmit)}>
                    <TextInput
                        label={t('forms:label.new_password')}
                        name="new_password"
                        required
                        password
                        error={
                            errors && errors.new_password
                                ? errors.new_password.message
                                : props.formErrors &&
                                  props.formErrors['new_password']
                        }
                        ref={register({
                            required: true,
                            minLength: {
                                value: 8,
                                message: t('common:error.password_min_length'),
                            },
                        })}
                    />
                    <TextInput
                        label={t('forms:label.re_new_password')}
                        name="re_new_password"
                        required
                        password
                        error={
                            errors && errors.re_new_password
                                ? errors.re_new_password.message
                                : ''
                        }
                        ref={register({
                            required: true,
                            validate: (value) =>
                                value === password.current ||
                                t('common:error.password_not_matching'),
                        })}
                    />
                    <div>
                        <Button
                            disabled={props.isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            disableElevation
                            color="secondary"
                        >
                            {!props.isLoading &&
                                t('common:button.set_password')}
                            {props.isLoading && <CircularProgress size={28} />}
                        </Button>
                    </div>
                </form>
            </Grid>
        </Grid>
    );
}

ResetPasswordForm.propTypes = {
    // props
    isLoading: PropTypes.bool,
    formErrors: PropTypes.object,
    // events
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export { ResetPasswordForm };
