import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getUrl, urls } from 'urls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

// import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import { TemplateLogin } from '../TemplateLogin';
import { TertiaryButton, EmptyMessage } from 'components';

// actions
import { loginUser } from './actions';
import { LoginForm } from './forms';

class LoginPage extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
    }

    handleForgotPassword(event) {
        event.preventDefault();
        event.stopPropagation();

        this.props.history.push({
            pathname: getUrl(urls.FORGOT_PASSWORD),
        });
    }

    renderGeneralFormErrors() {
        const { formErrors } = this.props;

        if (formErrors && formErrors['non_field_errors']) {
            return (
                <div className="form-error">
                    {formErrors['non_field_errors']}
                </div>
            );
        }
    }

    renderLoginForm() {
        const { isLoggingIn, isLoggedIn, t } = this.props;
        const params = new URLSearchParams(window.location.search); // eslint-disable-line no-undef
        // get the new url
        const next = params.get('next');
        // remove it
        params.delete('next');
        // build new search
        const newSearch = decodeURI(params.toString());

        if (isLoggedIn) {
            // sanity check
            const currentUrl = window.location.pathname; // eslint-disable-line no-undef
            let newUrl = '/';
            // if this is not the login page -> return to the same page
            if (!currentUrl.startsWith(getUrl(urls.LOGIN))) {
                newUrl = currentUrl;
            }

            if (next) {
                this.props.history.push({
                    pathname: next,
                    search: newSearch ? '?' + newSearch : '',
                });
            } else {
                this.props.history.push({
                    pathname: newUrl,
                    search: newSearch ? '?' + newSearch : '',
                });
            }

            return (
                <React.Fragment>
                    <EmptyMessage
                        title={t('common:title.loggedin')}
                        description={t('common:description.wait')}
                        size={'small'}
                        showLoader
                    />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this.renderGeneralFormErrors()}

                <LoginForm
                    onSubmit={this.props.loginUser}
                    onForgotPassword={this.handleForgotPassword}
                    isLoading={isLoggingIn}
                />

                <div className="dashed-line" />

                <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item xs={12} className={'text-center'}>
                        <Typography variant={'h3'}>
                            {t('common:title.login_no_account')}
                        </Typography>
                        <p>{t('common:description.login_no_account')}</p>
                        <TertiaryButton href="/register">
                            {t('common:title.login_create_account')}
                        </TertiaryButton>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const { t } = this.props;

        return (
            <TemplateLogin
                title={t('common:title.login')}
                description={t('common:description.login')}
            >
                <Helmet>
                    <title>{t('common:title.login')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>

                {this.renderLoginForm()}
            </TemplateLogin>
        );
    }
}

const mapStateToProps = (state) => {
    const { isLoggingIn, formErrors } = state.pageLogin;
    const { isLoggedIn } = state.authentication;

    return {
        isLoggingIn,
        formErrors,
        isLoggedIn,
    };
};

LoginPage.propTypes = {
    // props
    formErrors: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    isLoggingIn: PropTypes.bool,
    // event handlers
    t: PropTypes.func.isRequired,
    history: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
};

const LoginPageState = connect(mapStateToProps, {
    loginUser,
})(LoginPage);

const LoginPageTranslated = withTranslation()(LoginPageState);
const LoginPageRouter = withRouter(LoginPageTranslated);

export { LoginPageRouter as LoginPage };
