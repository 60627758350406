import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import {
    Avatar,
    Card,
    CardActionArea,
    CardContent,
    MenuItem,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Chip, DialogOrder, PopupMenu } from 'components';
import { renderAmount } from 'utils';

const useStyles = (theme) => ({});

class CardOrder extends Component {
    constructor(props) {
        super(props);

        // set state
        this.state = {
            dialogOpen: props.openDialog,
        };

        // event handlers
        this.handleDialogOpen = this.handleDialogOpen.bind(this);
        this.handleDialogClose = this.handleDialogClose.bind(this);
    }

    getStyle(status) {
        if (status === 'payment-pending') {
            return 'warning';
        } else if (
            status === 'canceled' ||
            status === 'not-paid' ||
            status === 'partially-paid'
        ) {
            return 'error';
        } else if (status === 'paid') {
            return 'success';
        }

        return 'default';
    }

    handleDialogOpen(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            dialogOpen: true,
        });
    }

    handleDialogClose() {
        this.setState({
            dialogOpen: false,
        });

        const { location } = this.props;
        const query = new URLSearchParams(location.search.slice(1)); // eslint-disable-line no-undef

        if (query.get('order')) {
            query.delete('order');

            if (query.toString() !== '') {
                this.props.history.push(
                    location.pathname + '?' + query.toString()
                );
            }
        }
    }

    renderLoading() {
        const { showUser } = this.props;

        return (
            <Card className="order-card" elevation={0}>
                <CardContent>
                    <div className="orderId">
                        <Skeleton variant="rect" height={20} width={50} />
                    </div>
                    {showUser && (
                        <div className="user">
                            <Skeleton variant="rect" height={20} width={100} />
                        </div>
                    )}
                    <div className="creationDate">
                        <Skeleton variant="rect" height={20} width={100} />
                    </div>
                    <div className="subscriptions">
                        <Skeleton variant="rect" height={20} width={100} />
                    </div>
                    <div className="status">
                        <Skeleton variant="rect" height={20} width={100} />
                    </div>
                    <div className="total">
                        <Skeleton variant="rect" height={20} width={50} />
                    </div>
                    <div className="actions" />
                </CardContent>
            </Card>
        );
    }

    renderUser() {
        const { showUser, order, t } = this.props;

        if (!showUser) {
            return;
        }

        if (!order.user) {
            return (
                <div className="user">
                    <Avatar variant={'rounded'}>
                        {t('common:text.anonymous_user_avatar')}
                    </Avatar>
                    <strong>
                        <em>{t('common:text.anonymous_user')}</em>
                    </strong>
                </div>
            );
        }

        return (
            <div className="user">
                <Avatar variant={'rounded'}>
                    {order.user.first_name[0]}
                    {order.user.last_name[0]}
                </Avatar>
                <strong>
                    {order.user.first_name} {order.user.last_name}
                </strong>
            </div>
        );
    }

    renderCard() {
        const { order, t, onClick } = this.props;
        const count =
            order.amount_subscriptions + order.amount_refund_subscriptions;

        return (
            <Card className="order-card" elevation={0}>
                <CardActionArea
                    onClick={onClick ? onClick : this.handleDialogOpen}
                >
                    <CardContent>
                        <div className="orderId">#{order.id}</div>
                        {this.renderUser()}
                        <div className="creationDate">
                            {moment(order.created_at).format('LL')}
                        </div>
                        <div className="subscriptions">
                            <Trans
                                i18nKey="text.amount_subscription"
                                count={count}
                                ns="common"
                            >
                                {{ count }} subscriptions
                            </Trans>
                        </div>
                        <div className="status">
                            <Chip
                                label={order.status_description}
                                variant="default"
                                type={this.getStyle(order.status)}
                            />
                        </div>
                        <div className="total">
                            <strong>{renderAmount(order.total_amount)}</strong>
                        </div>
                        <div className="actions">
                            <PopupMenu>
                                <MenuItem
                                    onClick={
                                        onClick
                                            ? onClick
                                            : this.handleDialogOpen
                                    }
                                >
                                    {t('common:text.view_order')}
                                </MenuItem>
                            </PopupMenu>
                        </div>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }

    renderDialog() {
        const { order, admin } = this.props;

        if (admin) {
            return;
        }

        return (
            <DialogOrder
                order={order}
                open={this.state.dialogOpen}
                onClose={this.handleDialogClose}
            />
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return (
            <React.Fragment>
                {this.renderCard()}
                {this.renderDialog()}
            </React.Fragment>
        );
    }
}

CardOrder.propTypes = {
    // props
    classes: PropTypes.object.isRequired,
    showUser: PropTypes.bool,
    order: PropTypes.object,
    isLoading: PropTypes.bool,
    openDialog: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    admin: PropTypes.bool,
    // handlers
    onClick: PropTypes.func,
    t: PropTypes.func.isRequired,
};

const CardOrderTranslated = withTranslation()(CardOrder);
const CardOrderStyled = withStyles(useStyles)(CardOrderTranslated);
const CardOrderRouter = withRouter(CardOrderStyled);

export { CardOrderRouter as CardOrder };
