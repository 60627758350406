import { getPublicCourse } from 'services/CourseService';

import {
    COURSE_DETAIL_PAGE_LOADING,
    COURSE_DETAIL_PAGE_LOADING_SUCCESS,
    COURSE_DETAIL_PAGE_LOADING_FAILED,
    COURSE_DETAIL_RESET_CREATED,
    COURSE_DETAIL_RESET_DELETED,
} from './actionTypes';

export const getCourse = (id) => {
    return (dispatch) => {
        dispatch({ type: COURSE_DETAIL_PAGE_LOADING });

        getPublicCourse(id)
            .then((result) => {
                dispatch({
                    type: COURSE_DETAIL_PAGE_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((data) => {
                dispatch({
                    type: COURSE_DETAIL_PAGE_LOADING_FAILED,
                    payload: data,
                });
            });
    };
};

export const resetCreated = () => {
    return (dispatch) => {
        dispatch({ type: COURSE_DETAIL_RESET_CREATED });
    };
};

export const resetDeleted = () => {
    return (dispatch) => {
        dispatch({ type: COURSE_DETAIL_RESET_DELETED });
    };
};
