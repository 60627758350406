import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

// import AlertService from 'lib/AlertService';
// import TranslationUtil from 'lib/TranslationUtil';

import { Grid } from '@material-ui/core';
import { Dialog } from 'components';
import { DiscountForm } from './form';
import { applyDiscountToOrder } from './actions';

class DialogDiscount extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handleApplyDiscount = this.handleApplyDiscount.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDiscountAdded !== this.props.isDiscountAdded) {
            if (this.props.isDiscountAdded) {
                this.handleClose(this.props.amount);
            }
        }
    }

    handleClose(amount) {
        this.props.onClose(amount);
    }

    handleApplyDiscount(data) {
        this.props.applyDiscountToOrder({
            id: this.props.order?.id,
            amount: data.discount,
        });
    }

    render() {
        const { open, t } = this.props;

        return (
            <Dialog
                open={open}
                onClose={this.handleClose}
                title={t('common:title.discount_dialog')}
                subtitle={t('common:subtitle.discount_dialog')}
                showLine
                noFooter
            >
                <Grid container>
                    <DiscountForm
                        action={this.handleApplyDiscount}
                        isLoading={this.props.isLoading}
                        errors={this.props.error}
                    />
                </Grid>
            </Dialog>
        );
    }
}

DialogDiscount.propTypes = {
    // props
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    isDiscountAdded: PropTypes.bool,
    error: PropTypes.object,
    order: PropTypes.object.isRequired,
    amount: PropTypes.number,
    // handlers
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    applyDiscountToOrder: PropTypes.func.isRequired,
};

DialogDiscount.defaultProps = {
    open: false,
};

const mapStateToProps = (state) => {
    const { isLoading, isDiscountAdded, error, amount } = state.dialogDiscount;

    return {
        isLoading,
        isDiscountAdded,
        error,
        amount,
    };
};

const DialogDiscountTranslated = withTranslation()(DialogDiscount);
const DialogDiscountState = connect(mapStateToProps, {
    applyDiscountToOrder,
})(DialogDiscountTranslated);

export { DialogDiscountState as DialogDiscount };
