import React from 'react';
import 'date-fns';
import { FormControl, InputLabel } from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';
import { Schedule as ScheduleIcon } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { Controller } from 'react-hook-form';

import PropTypes from 'prop-types';

function TimePicker(props) {
    const renderChildren = () => {
        if (props.children) {
            return <div className="children">{props.children}</div>;
        }
    };

    const renderError = () => {
        const { error } = props;

        if (error) {
            return <small className="error">{error}</small>;
        }
    };

    return (
        <FormControl className="form-control">
            <InputLabel shrink required={props.required}>
                {props.label}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                    control={props.control}
                    as={
                        <KeyboardTimePicker
                            required={props.required}
                            format="HH:mm"
                            ampm={false}
                            keyboardIcon={<ScheduleIcon />}
                            KeyboardButtonProps={{
                                'aria-label': 'choose time',
                            }}
                        />
                    }
                    value={props.watch(props.name)}
                    onChange={(date) => {
                        props.setValue(props.name, date);
                        return { value: date }; //important to update the controller value after change else state is updated and the controller will not render
                    }}
                    name={props.name}
                    initialFocusedTime={null}
                    rules={{ required: props.required }}
                />
            </MuiPickersUtilsProvider>
            {renderChildren()}
            {renderError()}
        </FormControl>
    );
}

TimePicker.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    innerRef: PropTypes.func.isRequired,
    required: PropTypes.bool,
    children: PropTypes.object,
    error: PropTypes.object,
    defaultValue: PropTypes.object,
    control: PropTypes.object.isRequired,
    removeSpacing: PropTypes.bool,
    watch: PropTypes.func,
    setValue: PropTypes.func,
};

// use ref forwarding for react-hook-form to work with class based components
const TimePickerStyled = React.forwardRef((props, ref) => (
    <TimePicker innerRef={ref} {...props} />
));

export { TimePickerStyled as TimePicker };
