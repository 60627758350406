import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import { Link, useHistory } from 'react-router-dom';
import { getUrl, urls } from 'urls';

import {
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    MenuItem,
    Typography,
} from '@material-ui/core';
import {
    AddCircle as AddCircleIcon,
    Close as CloseIcon,
} from '@material-ui/icons';
import {
    Button,
    Checkbox,
    DateTimePicker,
    IntroText,
    Select,
    TextInput,
} from 'components';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingBottom: '32px',
        paddingTop: '32px',

        '&:last-child': {
            paddingBottom: '32px',
        },
    },
    cancel: {
        marginLeft: '15px',
    },
    divider: {
        marginLeft: '-32px',
        marginRight: '-32px',
        marginTop: '16px',
    },
    link: {
        alignItems: 'center',
        display: 'flex',

        '& svg': {
            marginRight: '10px',
        },
    },
}));

function EventForm(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const event = props.event;
    const [activeDays, setActiveDays] = React.useState([1]);
    let defaultValues = {};

    // load event
    if (event) {
        // convert club to id
        if (event.club.indexOf('/') > 0) {
            const splittedClubUrl = event.club.split('/');
            if (splittedClubUrl && splittedClubUrl.length > 0) {
                event.club = splittedClubUrl[splittedClubUrl.length - 2];
            }
        }

        // visibility
        if (event.status === 'published') {
            event.status = true;
        } else if (event.status === 'draft') {
            event.status = false;
        }

        // convert days
        if (event.days && event.days.length > 0) {
            event.days = _.reduce(
                event.days,
                (result, value) => {
                    if (value && Object.keys(value).indexOf('date_start') < 0) {
                        result.push({
                            date_start: `${value.date_start_at} ${value.time_start_at}`,
                            date_end: `${value.date_end_at} ${value.time_end_at}`,
                        });
                    } else {
                        result.push(value);
                    }
                    return result;
                },
                []
            );
        }
        // render default values
        // event.status = true;
        defaultValues = event;
    }

    const {
        handleSubmit,
        register,
        unregister,
        control,
        setValue,
        getValues,
        watch,
    } = useForm({
        defaultValues: defaultValues,
    });

    const renderNextDay = (event) => {
        event.stopPropagation();
        event.preventDefault();

        activeDays.push(activeDays[activeDays.length - 1] + 1);
        setActiveDays([...activeDays]);
    };

    const removeDay = (event, day) => {
        event.stopPropagation();
        event.preventDefault();

        let oldDay = activeDays.splice(activeDays.indexOf(day), 1);
        // unregister it
        unregister(`days[${oldDay - 1}].date_start`);
        unregister(`days[${oldDay - 1}].date_end`);
        // todo reset days numbers
        setActiveDays([...activeDays]);
    };

    const cancel = () => {
        history.push(getUrl(urls.ADMIN_EVENTS));
    };

    function renderDay(day) {
        return (
            <React.Fragment>
                <Grid item xs={6} style={{ alignSelf: 'center' }}>
                    <Typography variant={'h4'}>
                        {t('forms:title.day')} {day}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={6}
                    style={{ justifySelf: 'flex-end', textAlign: 'right' }}
                >
                    {day > 1 && (
                        <IconButton onClick={(event) => removeDay(event, day)}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </Grid>

                <Grid item md={6} xs={12}>
                    <DateTimePicker
                        label={t('forms:label.date_start')}
                        name={`days[${day - 1}].date_start`}
                        required
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        error={
                            props.errors &&
                            props.errors['days'] &&
                            (props.errors['days'][day - 1]['date_start_at'] ||
                                props.errors['days'][day - 1]['time_start_at'])
                        }
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <DateTimePicker
                        label={t('forms:label.date_end')}
                        name={`days[${day - 1}].date_end`}
                        required
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        error={
                            props.errors &&
                            props.errors['days'] &&
                            (props.errors['days'][day - 1]['date_end_at'] ||
                                props.errors['days'][day - 1]['time_end_at'])
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <Divider className={classes.divider} />
                </Grid>
            </React.Fragment>
        );
    }

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Grid
                container
                spacing={3}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <Grid item md={6} xs={12}>
                    <IntroText
                        title={t('common:title.details')}
                        description={t('common:description.event.details')}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent
                            classes={{
                                root: classes.content,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Select
                                        label={t('forms:label.club')}
                                        name="club"
                                        required
                                        control={control}
                                        setValue={setValue}
                                        error={
                                            props.errors && props.errors['club']
                                        }
                                        ref={register({ required: true })}
                                    >
                                        {props.clubs.map((obj, index) => (
                                            <MenuItem
                                                key={index}
                                                value={obj.id}
                                            >
                                                {obj.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput
                                        label={t('forms:label.title')}
                                        name="name"
                                        required
                                        error={
                                            props.errors && props.errors['name']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput
                                        label={t('forms:label.description')}
                                        name="description"
                                        multiline
                                        required
                                        error={
                                            props.errors &&
                                            props.errors['description']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Checkbox
                                        label={t('forms:label.status_event')}
                                        name="status"
                                        error={
                                            props.errors &&
                                            props.errors['status']
                                        }
                                        ref={register('status')}
                                        checked={getValues('status')}
                                        onChange={(event) => {
                                            setValue(
                                                'status',
                                                event.target.checked
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item md={6} xs={12}>
                    <IntroText
                        title={t('common:title.dates_event')}
                        description={t('common:description.event.dates_event')}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent
                            classes={{
                                root: classes.content,
                            }}
                        >
                            <Grid container spacing={3}>
                                {activeDays.map((obj, index) => renderDay(obj))}

                                <Grid item xs={12}>
                                    <Link
                                        onClick={renderNextDay}
                                        className={classes.link}
                                    >
                                        <AddCircleIcon />
                                        {t('forms:link.add_day')}
                                    </Link>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item md={6} xs={12}>
                    <IntroText
                        title={t('common:title.dates_subscription')}
                        description={t(
                            'common:description.event.dates_subscription'
                        )}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent
                            classes={{
                                root: classes.content,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <DateTimePicker
                                        label={t('forms:label.date_start')}
                                        name="subscriptions_open_at"
                                        required
                                        setValue={setValue}
                                        watch={watch}
                                        control={control}
                                        error={
                                            props.errors &&
                                            props.errors['date_start']
                                        }
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <DateTimePicker
                                        label={t('forms:label.date_end')}
                                        name="subscriptions_closed_at"
                                        required
                                        setValue={setValue}
                                        watch={watch}
                                        control={control}
                                        error={
                                            props.errors &&
                                            props.errors['date_end']
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                {!event && (
                    <Grid item xs={12}>
                        <small>{t('common:text.event.courses_later')}</small>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Button
                        color={'secondary'}
                        type={'submit'}
                        isLoading={props.isLoading}
                    >
                        {event
                            ? t('forms:button.edit_event')
                            : t('forms:button.add_event')}
                    </Button>
                    <Button
                        variant={'text'}
                        className={classes.cancel}
                        onClick={cancel}
                    >
                        {t('forms:button.cancel')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

EventForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    event: PropTypes.object,
    clubs: PropTypes.array,
    errors: PropTypes.array,
    eventCreated: PropTypes.bool,
};

export { EventForm };
