import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment from 'moment';
import { urls, getUrl } from 'urls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import EventService from 'lib/EventService';
import { EVENT } from 'enums';

import {
    Avatar,
    Backdrop,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';
import {
    AdminHeader,
    Button,
    CardList,
    Chip,
    DialogPaymentMethod,
    EmptyMessage,
    PaymentItem,
    ShoppingCartItem,
    TemplateAdmin,
    TicketItem,
} from 'components';
import {
    groupSubscriptionsByCourse,
    renderAmount,
    AdminContext,
} from '../../utils';

import { getOrder, listOrderPayments, payAdminOrder } from './actions';

const useStyles = (theme) => ({
    border: {
        borderBottomColor: theme.colors.bone,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        marginBottom: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    avatar: {
        height: theme.spacing(8),
        width: theme.spacing(8),
        marginBottom: theme.spacing(3),
    },
    userContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        paddingTop: theme.spacing(3),
    },
    subscriptionsContainer: {
        paddingTop: theme.spacing(3),
    },
    userDetails: {
        width: '100%',

        '& strong': {
            display: 'block',
        },
    },
    tableContainer: {
        width: '100%',

        '& td': {
            '&:first-child': {
                paddingLeft: theme.spacing(4),
            },

            '&:last-child': {
                paddingRight: theme.spacing(4),
            },
        },
    },
    skeleton: {
        display: 'inline-block',
        transform: 'none',
        marginBottom: '12px',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    skeletonAvatar: {
        marginBottom: '32px',
    },
    subscription: {
        borderBottomColor: theme.colors.mercury,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        padding: '16px 0',
    },
    first: {
        paddingLeft: theme.spacing(4),
    },
    last: {
        paddingRight: theme.spacing(4),
    },
    amount: {
        textAlign: 'right',
    },
    name: {
        '& strong': {
            display: 'block',
        },
    },
    footer: {
        backgroundColor: theme.colors.mercury,
        marginTop: theme.spacing(4),

        '& strong': {
            display: 'block',
            padding: '32px 0',
        },
    },
    textRight: {
        textAlign: 'right',
    },
    spacer: {
        marginTop: theme.spacing(3),
    },
    backDrop: {
        zIndex: 1400,
        color: theme.colors.white,
        flexDirection: 'column',
    },
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
});

class AdminOrderPage extends Component {
    static contextType = AdminContext; // eslint-disable-line react/sort-comp

    constructor(props) {
        super(props);

        // state
        this.state = {
            orderId: this.props.match.params.orderId,
            openPaymentMethodDialog: false,
        };

        // event handlers
        this.handlePaymentDialogClose = this.handlePaymentDialogClose.bind(
            this
        );
        this.handlePaymentDialogOpen = this.handlePaymentDialogOpen.bind(this);
        this.handlePayAdminOrder = this.handlePayAdminOrder.bind(this);
        this.reloadOrder = this.reloadOrder.bind(this);
    }

    componentDidMount() {
        const { t } = this.props;

        this.context.setAdminTitle(t('common:title.order_detail'));
        this.context.setAdminBackAction(() =>
            this.props.history.push(getUrl(urls.ADMIN_ORDERS))
        );

        EventService.subscribe(
            EVENT.SUBSCRIPTION_DELETED,
            'adminorder',
            this.reloadOrder
        );
        EventService.subscribe(
            EVENT.SUBSCRIPTION_CHANGED,
            'adminorder',
            this.reloadOrder
        );
        this.reloadOrder();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.paymentRedirect !== this.props.paymentRedirect) {
            if (this.props.paymentRedirect) {
                this.handlePaymentDialogClose();
            }
        }
    }

    componentWillUnmount() {
        EventService.unsubscribe(EVENT.SUBSCRIPTION_DELETED, 'adminorder');
        EventService.unsubscribe(EVENT.SUBSCRIPTION_CHANGED, 'adminorder');
    }

    getStyle(status) {
        if (status === 'payment-pending') {
            return 'warning';
        } else if (
            status === 'canceled' ||
            status === 'not-paid' ||
            status === 'partially-paid'
        ) {
            return 'error';
        } else if (status === 'paid') {
            return 'success';
        }

        return 'default';
    }

    reloadOrder() {
        // load objects
        this.props.getOrder(this.state.orderId);
        this.props.listOrderPayments(1, this.state.orderId);
    }

    handlePaymentDialogOpen() {
        this.setState({
            openPaymentMethodDialog: true,
        });
    }

    handlePaymentDialogClose() {
        this.setState({
            openPaymentMethodDialog: false,
        });
    }

    handlePayAdminOrder(data) {
        const { isLoggedIn, order } = this.props;

        if (!isLoggedIn) {
            // do someting TODO
            alert('not logged in'); // eslint-disable-line no-alert,no-undef
            return;
        }

        this.props.payAdminOrder({
            id: order.id,
            amount: order.total_amount,
            ...data,
        });
    }

    renderLoader() {
        const { payRedirect, classes, t } = this.props;

        if (!payRedirect) {
            return;
        }

        window.location = payRedirect.payment_url; // eslint-disable-line no-undef

        return (
            <Backdrop className={classes.backDrop} open>
                <CircularProgress color="inherit" />
                <div className={classes.loader}>
                    {t('common:text.to_payment')}
                </div>
            </Backdrop>
        );
    }

    renderActions() {
        const { isLoading, order, t, payError } = this.props;

        if (isLoading) {
            return <CircularProgress size={40} />;
        }

        if (order?.status !== 'paid' && order?.status !== 'pending') {
            return (
                <React.Fragment>
                    <DialogPaymentMethod
                        open={this.state.openPaymentMethodDialog}
                        action={this.handlePayAdminOrder}
                        onClose={this.handlePaymentDialogClose}
                        errors={payError}
                        isLoading={this.props.isProcessingPayment}
                    />
                    <Button
                        color={'secondary'}
                        onClick={this.handlePaymentDialogOpen}
                    >
                        {t('common:button.to_payment')}
                    </Button>
                </React.Fragment>
            );
        }
    }

    renderHeader() {
        const { order, isLoading, t } = this.props;

        if (isLoading) {
            return (
                <AdminHeader
                    withTitle
                    withDescription
                    withBreadcrumbs
                    isLoading
                    variant={'small'}
                />
            );
        }

        return (
            <AdminHeader
                breadcrumbs={[
                    {
                        title: t('common:text.menu.orders'),
                        url: urls.ADMIN_ORDERS,
                    },
                    {
                        title: `${t('common:text.menu.order')} #${order?.id}`,
                        url: getUrl(urls.ADMIN_ORDER_DETAIL, {
                            orderId: this.state.orderId,
                        }),
                    },
                ]}
                title={`${t('common:text.menu.order')} #${order?.id}`}
                descriptionContainer={() => (
                    <Chip
                        label={order?.status_description}
                        variant="default"
                        type={this.getStyle(order?.status)}
                    />
                )}
                actionContainer={this.renderActions()}
                variant={'small'}
            />
        );
    }

    renderNotFound() {
        const { t } = this.props;

        return (
            <EmptyMessage
                icon={<CancelOutlinedIcon />}
                title={t('common:title.not_found')}
                description={t('common:description.order.not_found')}
            />
        );
    }

    renderUser() {
        const { order, isLoading, classes, t } = this.props;

        if (isLoading) {
            return (
                <div className={classes.userContainer}>
                    <Skeleton
                        height={64}
                        width={64}
                        className={classNames(
                            classes.skeleton,
                            classes.skeletonAvatar
                        )}
                    />

                    <div className={classes.userDetails}>
                        <Skeleton
                            height={24}
                            width={'80%'}
                            className={classes.skeleton}
                        />
                        <Skeleton
                            height={18}
                            width={'60%'}
                            className={classes.skeleton}
                        />
                    </div>
                </div>
            );
        }

        if (!order?.user) {
            // anonymous user
            return (
                <div className={classes.userContainer}>
                    <Avatar variant={'rounded'} className={classes.avatar}>
                        {t('common:text.anonymous_user_avatar')}
                    </Avatar>

                    <div className={classes.userDetails}>
                        <em>{t('common:text.anonymous_user')}</em>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.userContainer}>
                <Avatar variant={'rounded'} className={classes.avatar}>
                    {order?.user?.first_name[0]}
                    {order?.user?.last_name[0]}
                </Avatar>

                <div className={classes.userDetails}>
                    <strong>
                        {order?.user?.first_name} {order?.user?.last_name}
                    </strong>
                    <small>{order?.user?.email}</small>
                </div>
            </div>
        );
    }

    renderSubscriptions() {
        const { isLoading, order, classes, t } = this.props;

        if (isLoading) {
            return (
                <React.Fragment>
                    <CardContent>
                        <div className={classes.subscriptionsContainer}>
                            {[...new Array(3)].map((key) => (
                                <ShoppingCartItem
                                    key={key}
                                    isLoading
                                    hideActions
                                    admin
                                />
                            ))}
                        </div>
                    </CardContent>
                    <CardActions className={classes.footer}>
                        <Grid item sm={6} xs={12}>
                            <strong>
                                <Skeleton height={24} width={'50%'} />
                            </strong>
                        </Grid>
                        <Grid item sm={6} xs={12} className={classes.textRight}>
                            <strong>
                                <Skeleton
                                    height={24}
                                    width={'20%'}
                                    style={{ display: 'inline-block' }}
                                />
                            </strong>
                        </Grid>
                    </CardActions>
                </React.Fragment>
            );
        }

        const subscriptions = groupSubscriptionsByCourse(order?.subscriptions);
        const refundSubscriptions = groupSubscriptionsByCourse(
            order?.refund_subscriptions
        );

        return (
            <React.Fragment>
                <CardContent>
                    <div className={classes.subscriptionsContainer}>
                        {Object.entries(subscriptions).map(([key, value]) => (
                            <ShoppingCartItem
                                key={key}
                                course={value.course_object}
                                event={value.event_object}
                                subscriptions={value.subscriptions}
                                amount={value.total_amount}
                                admin
                            />
                        ))}
                        {Object.entries(refundSubscriptions).map(
                            ([key, value]) => (
                                <ShoppingCartItem
                                    key={key}
                                    course={value.course_object}
                                    event={value.event_object}
                                    subscriptions={value.subscriptions}
                                    amount={0 - value.total_amount}
                                    admin
                                />
                            )
                        )}
                    </div>
                </CardContent>
                <CardActions className={classes.footer}>
                    <Grid item sm={6} xs={12}>
                        <strong>{t('common:text.total')}</strong>
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.textRight}>
                        <strong>{renderAmount(order?.total_amount)}</strong>
                    </Grid>
                </CardActions>
            </React.Fragment>
        );
    }

    renderExpiredSubscriptions() {
        const { t, order, classes } = this.props;

        if (!order) {
            return;
        }

        const subscriptions = groupSubscriptionsByCourse(
            order.expired_subscriptions
        );
        let totalAmount = 0;

        if (Object.keys(subscriptions).length === 0) {
            return;
        }

        return (
            <Card className={classes.spacer}>
                <CardHeader
                    title={t('common:title.expired_subscriptions')}
                    subheader={t(
                        'common:description.order.expired_subscriptions'
                    )}
                />
                <CardContent>
                    <div className={classes.subscriptionsContainer}>
                        {Object.entries(subscriptions).map(([key, value]) => {
                            totalAmount += value.total_amount;

                            return (
                                <ShoppingCartItem
                                    key={key}
                                    course={value.course_object}
                                    event={value.event_object}
                                    subscriptions={value.subscriptions}
                                    amount={value.total_amount}
                                    admin
                                />
                            );
                        })}
                    </div>
                </CardContent>
                <CardActions className={classes.footer}>
                    <Grid item sm={6} xs={12}>
                        <strong>{t('common:text.total')}</strong>
                    </Grid>
                    <Grid item sm={6} xs={12} className={classes.textRight}>
                        <strong>{renderAmount(totalAmount)}</strong>
                    </Grid>
                </CardActions>
            </Card>
        );
    }

    renderDetails() {
        const { order, isLoading, classes, t } = this.props;

        return (
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <strong>{t('forms:label.id')}</strong>
                            </TableCell>
                            <TableCell align="right">
                                {isLoading ? (
                                    <Skeleton height={24} width={'100%'} />
                                ) : (
                                    `#${order?.id}`
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>{t('forms:label.created')}</strong>
                            </TableCell>
                            <TableCell align="right">
                                {isLoading ? (
                                    <Skeleton height={24} width={'100%'} />
                                ) : (
                                    moment(order?.created_at).format('LLL')
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>{t('forms:label.confirmed')}</strong>
                            </TableCell>
                            <TableCell align="right">
                                {isLoading ? (
                                    <Skeleton height={24} width={'100%'} />
                                ) : (
                                    moment(order?.confirmed_at).format('LLL')
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <strong>{t('forms:label.status')}</strong>
                            </TableCell>
                            <TableCell align="right">
                                {isLoading ? (
                                    <Skeleton height={24} width={'100%'} />
                                ) : (
                                    <Chip
                                        label={order?.status_description}
                                        variant="default"
                                        type={this.getStyle(order?.status)}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    renderPayments() {
        const {
            isLoadingPayments,
            payments,
            totalPaymentsPages,
            errorPayments,
            currentPaymentPage,
            t,
        } = this.props;

        if (Object.keys(payments).length === 0) {
            return (
                <CardContent>
                    <small>
                        {t('common:description.payment.no_payments_found')}
                    </small>
                </CardContent>
            );
        }

        return (
            <CardContent>
                <CardList
                    isLoading={isLoadingPayments}
                    objects={payments}
                    totalPages={totalPaymentsPages}
                    currentPage={currentPaymentPage}
                    error={errorPayments}
                    card={(props) => (
                        <PaymentItem
                            isLoading={this.props.isLoading}
                            payment={props.obj && props.obj.payment}
                        />
                    )}
                    handlePage={this.handlePaymentPage}
                    useCardAsLoaders
                    removeSpacing
                />
            </CardContent>
        );
    }

    renderTickets() {
        const { isLoading, order, t } = this.props;

        if (!order) {
            return (
                <CardContent>
                    <TicketItem isLoading />
                </CardContent>
            );
        }

        if (Object.keys(order.tickets).length === 0) {
            return (
                <CardContent>
                    <small>
                        {t('common:description.ticket.no_tickets_found')}
                    </small>
                </CardContent>
            );
        }

        return (
            <CardContent>
                {order?.tickets?.map((obj, index) => (
                    <TicketItem
                        key={index}
                        isLoading={isLoading}
                        ticket={obj}
                    />
                ))}
            </CardContent>
        );
    }

    render() {
        const { error, isLoading, classes, t } = this.props;

        if (!isLoading && error) {
            return this.renderNotFound();
        }

        // <Toast
        //     message={t('common:success.general.created_subscription')}
        //     open={isCreated}
        //     type="success"
        //     onClose={this.props.resetCreated}
        // />

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {t('common:title.order_detail')} |{' '}
                        {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>

                {this.renderLoader()}

                <TemplateAdmin header>{this.renderHeader()}</TemplateAdmin>

                <TemplateAdmin>
                    <Grid container spacing={3}>
                        <Grid item md={8} xs={12}>
                            <Card>
                                <CardHeader
                                    title={t('common:title.subscriptions')}
                                    subheader={t(
                                        'common:description.order.subscriptions'
                                    )}
                                />
                                {this.renderSubscriptions()}
                            </Card>

                            {this.renderExpiredSubscriptions()}

                            <Card className={classes.spacer}>
                                <CardHeader
                                    title={t('common:title.payments')}
                                    subheader={t(
                                        'common:description.order.payments'
                                    )}
                                />
                                {this.renderPayments()}
                            </Card>

                            <Card className={classes.spacer}>
                                <CardHeader
                                    title={t('common:title.tickets')}
                                    subheader={t(
                                        'common:description.order.tickets'
                                    )}
                                />
                                {this.renderTickets()}
                            </Card>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <Card>
                                <CardHeader
                                    title={t('common:title.customer')}
                                    subheader={t(
                                        'common:description.order.customer'
                                    )}
                                />
                                <CardContent>{this.renderUser()}</CardContent>
                            </Card>

                            <Card className={classes.spacer}>
                                <CardHeader
                                    title={t('common:title.details')}
                                    subheader={t(
                                        'common:description.order.details'
                                    )}
                                />
                                {this.renderDetails()}
                            </Card>
                        </Grid>
                    </Grid>
                </TemplateAdmin>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoading,
        order,
        error,
        isLoadingPayments,
        isProcessingPayment,
        errorPayments,
        currentPaymentPage,
        payments,
        totalPaymentsPages,
        payError,
        payRedirect,
    } = state.pageAdminOrder;
    const { isLoggedIn } = state.authentication;

    return {
        isLoading,
        order,
        error,
        isLoadingPayments,
        isProcessingPayment,
        errorPayments,
        currentPaymentPage,
        payments,
        totalPaymentsPages,
        payError,
        payRedirect,
        isLoggedIn,
    };
};

AdminOrderPage.propTypes = {
    // props
    isLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isLoadingPayments: PropTypes.bool,
    isProcessingPayment: PropTypes.bool,
    totalPaymentsPages: PropTypes.number,
    currentPaymentPage: PropTypes.number,
    classes: PropTypes.object,
    error: PropTypes.string,
    payError: PropTypes.string,
    errorPayments: PropTypes.string,
    payments: PropTypes.object,
    order: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    paymentRedirect: PropTypes.string,
    payRedirect: PropTypes.string,
    // handlers
    t: PropTypes.func.isRequired,
    getOrder: PropTypes.func.isRequired,
    listOrderPayments: PropTypes.func.isRequired,
    payAdminOrder: PropTypes.func.isRequired,
};

const AdminOrderPageTranslated = withTranslation()(AdminOrderPage);
const AdminOrderPageStyled = withStyles(useStyles)(AdminOrderPageTranslated);
const AdminOrderPageState = connect(mapStateToProps, {
    getOrder,
    listOrderPayments,
    payAdminOrder,
})(AdminOrderPageStyled);
const AdminOrderPageRouter = withRouter(AdminOrderPageState);
export { AdminOrderPageRouter as AdminOrderPage };
