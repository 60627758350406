import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import Route from '../../routes/Route';
import { getUrl, urls } from 'urls';

import { Transition, TransitionGroup } from 'react-transition-group';

import {
    AdminDashboardPage,
    AdminClubsPage,
    AdminClubAddEditPage,
    AdminCoursePage,
    AdminCourseAddEditPage,
    AdminEventsPage,
    AdminEventPage,
    AdminEventAddEditPage,
    AdminOrderPage,
    AdminOrdersPage,
    AdminTicketPage,
} from '..';

import { play, exit } from '../../animations';

export const AdminPages = withRouter(({ history, isLoggedIn, path }) => {
    function paths() {
        switch (path) {
            case urls.ADMIN_DASHBOARD:
                return (
                    <Switch location={history.location}>
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_DASHBOARD)}
                            component={AdminDashboardPage}
                        />
                    </Switch>
                );

            case urls.ADMIN_CLUBS:
                return (
                    <Switch location={history.location}>
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_CLUBS)}
                            component={AdminClubsPage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_CLUB_ADD)}
                            component={AdminClubAddEditPage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_CLUB_CHANGE)}
                            component={AdminClubAddEditPage}
                        />
                    </Switch>
                );

            case urls.ADMIN_EVENTS:
                return (
                    <Switch location={history.location}>
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_EVENTS)}
                            component={AdminEventsPage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_EVENT_ADD)}
                            component={AdminEventAddEditPage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_EVENT_CHANGE)}
                            component={AdminEventAddEditPage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_EVENT_DETAIL)}
                            component={AdminEventPage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_EVENT_COURSE_ADD)}
                            component={AdminCourseAddEditPage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_EVENT_COURSE_START_LIST)}
                            component={AdminCoursePage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_EVENT_COURSE_CHANGE)}
                            component={AdminCourseAddEditPage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_EVENT_TICKET_DETAIL)}
                            component={AdminTicketPage}
                        />
                    </Switch>
                );

            case urls.ADMIN_ORDERS:
                return (
                    <Switch location={history.location}>
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_ORDERS)}
                            component={AdminOrdersPage}
                        />
                        <Route
                            isPrivate
                            isAdmin
                            exact
                            authenticated={isLoggedIn}
                            path={getUrl(urls.ADMIN_ORDER_DETAIL)}
                            component={AdminOrderPage}
                        />
                    </Switch>
                );

            default:
                return;
        }
    }

    return (
        <TransitionGroup>
            <Transition
                key={history.location.key}
                appear
                onEnter={(node, appears) =>
                    play(history.location.pathname, node, appears)
                }
                onExit={(node, appears) => exit(node, appears)}
                timeout={{ enter: 750, exit: 250 }}
            >
                {paths()}
            </Transition>
        </TransitionGroup>
    );
});
