import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import {
    CircularProgress,
    Container,
    Grid,
    Hidden,
    Typography,
} from '@material-ui/core';

import { TemplateMarketing } from '..';
import { Button } from 'components';
import { getUrl } from 'urls';

import IconBancontact from 'assets/images/icons/payment/bancontact.svg';
import IconING from 'assets/images/icons/payment/ing.svg';
import IconVISA from 'assets/images/icons/payment/visa.svg';
import IconMasterCard from 'assets/images/icons/payment/mastercard.svg';
import IconBelfius from 'assets/images/icons/payment/belfius.svg';
import IconKBC from 'assets/images/icons/payment/kbc.svg';
import IconIDeal from 'assets/images/icons/payment/ideal.svg';

const useStyles = (theme) => ({
    header: {
        marginTop: theme.spacing(3),

        '& h1, & h2': {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
        },

        '& p': {
            marginBottom: theme.spacing(4),
        },
    },
    content: {
        padding: '96px 0 128px',
    },
    contentSmall: {
        padding: '48px 0',

        '& h2': {
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(3),
        },

        '& button': {
            marginTop: theme.spacing(3),
        },
    },
    center: {
        textAlign: 'center',
    },
    paymentMethod: {
        padding: '24px 0 16px',
        borderBottomColor: theme.colors.bone,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',

        '& img': {
            marginRight: theme.spacing(1),
            verticalAlign: 'middle',
        },

        '& svg': {
            color: theme.colors.warmGrey,
            margin: '0 8px',
            verticalAlign: 'middle',
        },
    },
    tableHeader: {
        padding: '16px 0',
        borderBottomColor: theme.colors.bone,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
    },
    right: {
        textAlign: 'right',

        [theme.breakpoints.down('sm')]: {
            marginTop: '-12px',
            textAlign: 'left',
        },
    },
    cost: {
        marginRight: theme.spacing(2),

        '& span': {
            margin: '0 4px',
        },
    },
});

class PricingPage extends Component {
    constructor(props) {
        super(props);

        // data
        this.state = {
            methods: [
                {
                    icon: IconBancontact,
                    name: 'Bancontact',
                    items: [
                        {
                            description: 'Most used payment method in Belgium.',
                            cost: {
                                fixed: '€ 0,78',
                                variable: '1,00%',
                            },
                        },
                    ],
                },
                {
                    icon: IconMasterCard,
                    name: 'Mastercard',
                    items: [
                        {
                            description:
                                'Populair creditcard, used in more than 200 countries.',
                            cost: {
                                fixed: '€ 0,50',
                                variable: '5,60%',
                            },
                        },
                    ],
                },
                {
                    icon: IconVISA,
                    name: 'VISA',
                    items: [
                        {
                            description:
                                'Populair creditcard, more then 2,5 billion cards worldwide.',
                            cost: {
                                fixed: '€ 0,50',
                                variable: '5,60%',
                            },
                        },
                    ],
                },
                {
                    icon: IconIDeal,
                    name: 'iDeal',
                    items: [
                        {
                            description:
                                'Most used payment method in the Nederlands.',
                            cost: {
                                fixed: '€ 0,58',
                                variable: '1,00%',
                            },
                        },
                    ],
                },
                {
                    icon: IconBelfius,
                    name: 'Belfius',
                    items: [
                        {
                            description:
                                'The payment method for more than 1 million Belfius-clients.',
                            cost: {
                                fixed: '€ 0,50',
                                variable: '1,80%',
                            },
                        },
                    ],
                },
                {
                    icon: IconKBC,
                    name: 'KBC / CBC',
                    items: [
                        {
                            description:
                                'The payment method for the biggest Belgian bank.',
                            cost: {
                                fixed: '€ 0,50',
                                variable: '1,80%',
                            },
                        },
                    ],
                },
                {
                    icon: IconING,
                    name: 'ING',
                    items: [
                        {
                            description:
                                'Used by more than 2.5 million ING customers.',
                            cost: {
                                fixed: '€ 0,50',
                                variable: '1,80%',
                            },
                        },
                    ],
                },
            ],
        };
    }

    render() {
        const { t, classes } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <Helmet>
                    <title>{t('common:text.menu.pricing')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateMarketing>
                    <Container>
                        <Grid
                            container
                            spacing={3}
                            alignItems={'center'}
                            justify={'center'}
                            className={classNames(
                                classes.header,
                                classes.center
                            )}
                        >
                            <Grid item sm={8} xs={12}>
                                <Typography variant={'h1'}>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('common:title.main', {
                                                interpolation: {
                                                    escapeValue: false,
                                                },
                                            }),
                                        }}
                                    />
                                </Typography>
                                <Typography>
                                    {t('common:description.pricing.main')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>

                    <div className={classNames(classes.content)}>
                        <Container>
                            <Hidden smDown>
                                <Grid
                                    container
                                    spacing={3}
                                    justify={'center'}
                                    alignItems={'center'}
                                    className={classes.tableHeader}
                                >
                                    <Grid item sm={4} xs={12}>
                                        <Typography variant={'h4'}>
                                            {t('common:title.payment_method')}
                                        </Typography>
                                    </Grid>
                                    <Grid item sm={5} xs={12}>
                                        <Typography variant={'h4'}>
                                            {t('common:title.description')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={3}
                                        xs={12}
                                        className={classes.right}
                                    >
                                        <Typography variant={'h4'}>
                                            {t('common:title.transaction')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            {this.state.methods.map((obj, index) => {
                                return (
                                    <Grid
                                        container
                                        spacing={3}
                                        justify={'center'}
                                        alignItems={'center'}
                                        key={index}
                                        className={classes.paymentMethod}
                                    >
                                        {obj.items.map((method, counter) => {
                                            var Icon = obj.icon;

                                            return (
                                                <React.Fragment>
                                                    <Grid item sm={4} xs={12}>
                                                        {counter === 0 && (
                                                            <React.Fragment>
                                                                <img
                                                                    src={Icon}
                                                                    alt={
                                                                        obj.name
                                                                    }
                                                                    width={32}
                                                                />
                                                                <strong>
                                                                    {obj.name}
                                                                </strong>
                                                            </React.Fragment>
                                                        )}
                                                    </Grid>
                                                    <Grid item sm={5} xs={12}>
                                                        {method.description}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        sm={3}
                                                        xs={12}
                                                        className={
                                                            classes.right
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                classes.cost
                                                            }
                                                        >
                                                            <strong>
                                                                {
                                                                    method.cost
                                                                        .fixed
                                                                }
                                                            </strong>
                                                            {method.cost
                                                                .variable && (
                                                                <React.Fragment>
                                                                    <span>
                                                                        +
                                                                    </span>
                                                                    <strong>
                                                                        {
                                                                            method
                                                                                .cost
                                                                                .variable
                                                                        }
                                                                    </strong>
                                                                </React.Fragment>
                                                            )}
                                                        </span>
                                                    </Grid>
                                                </React.Fragment>
                                            );
                                        })}
                                    </Grid>
                                );
                            })}
                        </Container>
                    </div>

                    <div
                        className={classNames(
                            classes.contentSmall,
                            classes.center,
                            'content-blue'
                        )}
                    >
                        <Container>
                            <Typography variant={'h2'}>
                                {t('common:title.ready')}
                            </Typography>
                            <Typography>
                                {t('common:description.pricing.ready')}
                            </Typography>

                            <Button variant={'text'}>
                                {t('common:button.start')}
                            </Button>
                        </Container>
                    </div>
                </TemplateMarketing>
            </Suspense>
        );
    }
}

const PricingPageTranslated = withTranslation()(PricingPage);
const PricingPageStyled = withStyles(useStyles)(PricingPageTranslated);
export { PricingPageStyled as PricingPage };
