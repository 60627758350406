import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';

import { TemplateAdmin } from 'components';

class AdminStatisticsPage extends Component {
    render() {
        const { t } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <TemplateAdmin>
                    <h1>{t('common:title.admin.statistics')}</h1>

                    {[...new Array(40)]
                        .map(
                            () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
                        )
                        .join('\n')}
                </TemplateAdmin>
            </Suspense>
        );
    }
}

const AdminStatisticsPageTranslated = withTranslation()(AdminStatisticsPage);
export { AdminStatisticsPageTranslated as AdminStatisticsPage };
