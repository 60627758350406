import {
    TICKETS_PAGE_LOADING,
    TICKETS_PAGE_LOADING_SUCCESS,
    TICKETS_PAGE_LOADING_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    tickets: [],
    total_tickets: 0,
    total_pages: 0,
    current_page: 1,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TICKETS_PAGE_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case TICKETS_PAGE_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tickets: action.payload.results,
                total_tickets: action.payload.count,
                total_pages: action.payload.total_pages,
                current_page: action.payload.current_page,
            };
        case TICKETS_PAGE_LOADING_FAILED:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
