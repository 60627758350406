import {
    addAdminCourse,
    editAdminCourse,
    getAdminCourse,
    listAdminCourseTypes,
} from 'services/CourseService';
import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import {
    ADMIN_COURSE_ADD_LOADING,
    ADMIN_COURSE_ADD_SUCCESS,
    ADMIN_COURSE_ADD_FAILED,
    ADMIN_COURSE_LOADING,
    ADMIN_COURSE_LOADING_SUCCESS,
    ADMIN_COURSE_LOADING_FAILED,
    ADMIN_COURSE_EDIT_LOADING,
    ADMIN_COURSE_EDIT_SUCCESS,
    ADMIN_COURSE_EDIT_FAILED,
    ADMIN_COURSE_TYPES_LOADING,
    ADMIN_COURSE_TYPES_SUCCESS,
    ADMIN_COURSE_TYPES_FAILED,
    ADMIN_COURSE_RESET,
} from './actionTypes';

export const getCourse = (id) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_LOADING });

        getAdminCourse(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_COURSE_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_COURSE_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const addCourse = (data) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_ADD_LOADING });

        addAdminCourse(data)
            .then((result) => {
                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.created_course'
                    ),
                });

                dispatch({
                    type: ADMIN_COURSE_ADD_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_COURSE_ADD_FAILED,
                    payload: error,
                });
            });
    };
};

export const editCourse = (data) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_EDIT_LOADING });

        editAdminCourse(data.id, data)
            .then((result) => {
                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.changed_course'
                    ),
                });

                dispatch({
                    type: ADMIN_COURSE_EDIT_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                AlertService.showError({
                    title: TranslationUtil.getTranslations().t(
                        'common:error.general.title'
                    ),
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_COURSE_EDIT_FAILED,
                    payload: error,
                });
            });
    };
};

export const listCourseTypes = () => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_TYPES_LOADING });

        listAdminCourseTypes()
            .then((result) => {
                dispatch({
                    type: ADMIN_COURSE_TYPES_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_COURSE_TYPES_FAILED,
                    payload: error,
                });
            });
    };
};

export const resetCourse = () => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_RESET });
    };
};
