import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getUrl, urls } from 'urls';
import PropTypes from 'prop-types';

// import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid, MenuItem } from '@material-ui/core';

import {
    Checkbox,
    CountrySelect,
    DatePicker,
    Select,
    TextInput,
} from 'components';

function RegisterForm(props) {
    const { register, handleSubmit, control, setValue, watch } = useForm();
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                    <TextInput
                        label={t('forms:label.first_name')}
                        name="first_name"
                        required
                        error={props.errors && props.errors['first_name']}
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <TextInput
                        label={t('forms:label.last_name')}
                        name="last_name"
                        required
                        error={props.errors && props.errors['last_name']}
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label={t('forms:label.email_address')}
                        name="email"
                        email
                        required
                        error={props.errors && props.errors['email']}
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label={t('forms:label.password')}
                        name="password"
                        password
                        required
                        error={props.errors && props.errors['password']}
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Select
                        label={t('forms:label.gender')}
                        name="gender"
                        required
                        setValue={setValue}
                        control={control}
                        error={props.errors && props.errors['gender']}
                        ref={(ref) => {
                            if (!ref) return;
                            register({
                                required: true,
                                name: 'gender',
                                value: ref.value,
                            });
                        }}
                    >
                        <MenuItem value="male">
                            {t('forms:gender.male')}
                        </MenuItem>
                        <MenuItem value="female">
                            {t('forms:gender.female')}
                        </MenuItem>
                        <MenuItem value="x">
                            {t('forms:gender.neutral')}
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <DatePicker
                        label={t('forms:label.date_of_birth')}
                        name="date_of_birth"
                        required
                        setValue={setValue}
                        watch={watch}
                        control={control}
                        error={props.errors && props.errors['date_of_birth']}
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CountrySelect
                        label={t('forms:label.country')}
                        name="country"
                        required
                        control={control}
                        setValue={setValue}
                        ref={register({ required: true })}
                        error={props.errors && props.errors['country']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox
                        error={props.errors && props.errors['agreed_to_terms']}
                        label={
                            <Trans i18nKey="agreed_to_terms" ns="forms">
                                I agree to the{' '}
                                <Link to={getUrl(urls.TERMS)} target="_blank">
                                    terms and conditions
                                </Link>
                                .
                            </Trans>
                        }
                        name="agreed_to_terms"
                        required
                        ref={register()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={props.isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        disableElevation
                        color="secondary"
                    >
                        {!props.isLoggingIn && t('forms:button.register')}
                        {props.isLoggingIn && <CircularProgress size={28} />}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

RegisterForm.propTypes = {
    // props
    errors: PropTypes.object,
    isLoading: PropTypes.bool,
    isLoggingIn: PropTypes.bool,
    // handlers
    onSubmit: PropTypes.func.isRequired,
};

export { RegisterForm };
