import {
    ORDERS_PAGE_LOADING,
    ORDERS_PAGE_LOADING_SUCCESS,
    ORDERS_PAGE_LOADING_FAILED,
} from './actionTypes';

import { listOrders as apiListOrders } from 'services/OrderService';

export const listOrders = (page) => {
    return (dispatch) => {
        dispatch({ type: ORDERS_PAGE_LOADING });

        apiListOrders(page)
            .then((result) => {
                dispatch({
                    type: ORDERS_PAGE_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ORDERS_PAGE_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};
