import React from "react";
import PropTypes from "prop-types";
import { ReactReduxContext } from "react-redux";

import { v4 as uuidv4 } from "uuid";
import { injectReducer, removeReducer } from "./utils";

export const InjectReducer = (reducer) => (WrappedComponent) => {
    class InjectReducer extends React.Component {
        static contextType = ReactReduxContext;

        componentWillMount() {
            injectReducer(this.context.store, reducer);
        }

        componentWillUnmount() {
            removeReducer(this.context.store, reducer);
        }

        render() {
            return React.createElement(WrappedComponent, this.props);
        }
    }

    InjectReducer.contextTypes = {
        store: PropTypes.shape({
            replaceReducer: PropTypes.func.isRequired,
        }),
    };
    return InjectReducer;
};

export const dynamicInjectReducer =
    ({ key, reducer }) =>
    (WrappedComponent) => {
        class dynamicInjectReducer extends React.Component {
            static contextType = ReactReduxContext;

            constructor(props) {
                super(props);

                const id = uuidv4();

                // random id for reducer
                this.state = {
                    reducerKey: `${key}-${id}`,
                };
            }

            componentDidMount() {
                injectReducer(this.context.store, {
                    [this.state.reducerKey]: reducer(this.state.reducerKey),
                });
            }

            componentWillUnmount() {
                removeReducer(this.context.store, {
                    [this.state.reducerKey]: reducer(this.state.reducerKey),
                });
            }

            render() {
                // extend props with new keys of reducers
                const props = Object.assign({}, this.props, {
                    reducerKey: this.state.reducerKey,
                });
                return React.createElement(WrappedComponent, props);
            }
        }

        dynamicInjectReducer.contextTypes = {
            store: PropTypes.shape({
                replaceReducer: PropTypes.func.isRequired,
            }),
        };

        return dynamicInjectReducer;
    };
