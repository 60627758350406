import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { urls, getUrl } from 'urls';
import { Helmet } from 'react-helmet';

import {
    Container,
    CircularProgress,
    Grid,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { hexToRGB, buildStartList } from '../../utils';
import { TemplateMarketing } from '../TemplateMarketing';
import { Toast, EmptyMessage } from 'components';
import { getCourse, resetDeleted, resetCreated } from './actions';

const useStyles = (theme) => ({
    media: {
        backgroundColor: theme.colors.springWood,
        position: 'relative',
        padding: '144px 56px 40px',
    },
    header: {
        borderRadius: '20px',
        color: theme.colors.white,
        marginLeft: '-56px',
        marginRight: '-56px',
    },
    content: {
        padding: '0',
    },
    relative: {
        position: 'relative',
    },
    title: {
        color: theme.colors.white,
        fontSize: '2.5rem',
        lineHeight: 1.25,
        margin: '8px 0',
    },
    stat: {
        color: hexToRGB(theme.colors.white, 0.7),
        marginTop: '5px',

        '& > svg': {
            marginRight: '8px',
            verticalAlign: 'middle',
        },
        '& span.label': {
            marginLeft: '15px',
        },
    },
    meta: {
        borderRadius: '20px',
    },
    metaContent: {
        padding: '24px',

        '& > p:first-child': {
            marginTop: 0,
        },
    },
    label: {
        marginTop: '24px',
    },
});

class EventDashboardPage extends Component {
    constructor(props) {
        super(props);

        const eventId = this.props.match.params.eventId;

        // save to state
        this.state = {
            eventId: eventId,
        };

        // event handlers
        this.reloadCourse = this.reloadCourse.bind(this);
    }

    reloadCourse() {
        // load course
        this.props.getCourse(this.state.course_id);
    }

    render() {
        const {
            t,
            isLoading,
            error,
            course,
            isCreated,
            isDeleted,
        } = this.props;

        if (error) {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>{t('common:error.general.title')} Equento</title>
                        <link
                            rel="canonical"
                            href={getUrl(window.location.pathname, {}, true)}
                        />
                    </Helmet>
                    <Toast
                        message={t('common:error.general.description')}
                        open
                    />
                    <EmptyMessage
                        title={t('common:error.general.title')}
                        description={t('common:error.general.description_long')}
                        action={this.reloadCourse}
                    />
                </React.Fragment>
            );
        }

        return (
            <Container>
                <Suspense fallback={<CircularProgress />}>
                    <Helmet>
                        <title>
                            {course
                                ? course.name
                                : t('common:title.course_detail')}{' '}
                            | Equento
                        </title>
                        <link
                            rel="canonical"
                            href={getUrl(window.location.pathname, {}, true)}
                        />
                    </Helmet>
                    <TemplateMarketing>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h2" className="title-h2">
                                    {t('common:title.startlist')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </TemplateMarketing>
                </Suspense>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoading,
        error,
        course,
        isCreated,
        isDeleted,
    } = state.pagePublicCourseDetail;

    return {
        isLoading,
        error,
        course,
        isCreated,
        isDeleted,
    };
};

const EventDashboardPageState = connect(mapStateToProps, {
    getCourse,
    resetCreated,
    resetDeleted,
})(EventDashboardPage);

const EventDashboardPageTranslated = withTranslation()(EventDashboardPageState);
const EventDashboardPageStyled = withStyles(useStyles)(
    EventDashboardPageTranslated
);
export { EventDashboardPageStyled as EventDashboardPage };
