import {
    FILE_DOWNLOAD_LOADING,
    FILE_DOWNLOAD_SUCCESS,
    FILE_DOWNLOAD_FAILED,
    FILE_DOWNLOAD_RESET,
} from './actionTypes';

export const resetDownloaded = ({ key }) => {
    return (dispatch) => {
        dispatch({
            type: FILE_DOWNLOAD_RESET,
            meta: { key: key },
        });
    };
};

export const downloadFile = ({
    action,
    onStart,
    onFinish,
    onSuccess,
    onError,
    key,
}) => {
    return (dispatch) => {
        dispatch({ type: FILE_DOWNLOAD_LOADING });

        if (onStart) {
            onStart();
        }

        action()
            .then((result) => {
                const [, filename] = result.headers[
                    'content-disposition'
                ].split('filename=');

                dispatch({
                    type: FILE_DOWNLOAD_SUCCESS,
                    payload: {
                        data: result.data,
                        filename: filename,
                    },
                    meta: { key: key },
                });

                if (onSuccess) {
                    onSuccess();
                }

                if (onFinish) {
                    onFinish();
                }
            })
            .catch((error) => {
                dispatch({
                    type: FILE_DOWNLOAD_FAILED,
                    payload: error,
                    meta: { key: key },
                });

                if (onError) {
                    onError();
                }

                if (onFinish) {
                    onFinish();
                }
            });
    };
};
