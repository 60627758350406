export const ADMIN_COURSE_LOADING = 'admin_course_loading';
export const ADMIN_COURSE_SUCCESS = 'admin_course_success';
export const ADMIN_COURSE_FAILED = 'admin_course_failed';

export const ADMIN_COURSE_EVENT_LOADING = 'admin_course_event_loading';
export const ADMIN_COURSE_EVENT_SUCCESS = 'admin_course_event_success';
export const ADMIN_COURSE_EVENT_FAILED = 'admin_course_event_failed';

export const ADMIN_COURSE_ACTIONS_LOADING = 'admin_course_actions_loading';
export const ADMIN_COURSE_ACTIONS_SUCCESS = 'admin_course_actions_success';
export const ADMIN_COURSE_ACTIONS_FAILED = 'admin_course_actions_failed';

export const ADMIN_COURSE_RESET_CHANGED = 'admin_course_reset_changed';
export const ADMIN_COURSE_RESET_CREATED = 'admin_course_reset_created';

export const ADMIN_COURSE_EXPORT_START_LIST_LOADING =
    'admin_couse_export_start_list_loading';
export const ADMIN_COURSE_EXPORT_START_LIST_SUCCESS =
    'admin_couse_export_start_list_success';
export const ADMIN_COURSE_EXPORT_START_LIST_FAILED =
    'admin_couse_export_start_list_failed';
