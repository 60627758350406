import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { dynamicInjectReducer } from 'reducers/InjectReducer';

import { AvatarGroup } from '@material-ui/lab';
import {
    Avatar,
    Card,
    CardActionArea,
    CardContent,
    Fade,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Button } from 'components';
import { acceptAuthorization, declineAuthorization } from './actions';
import Reducer from './reducer';

const useStyles = (theme) => ({});

class CardAuthorization extends Component {
    constructor(props) {
        super(props);

        // set state
        this.state = {};

        // event handlers
        this.handleDecline = this.handleDecline.bind(this);
        this.handleAccept = this.handleAccept.bind(this);
    }

    componentDidUpdate(prevProps) {}

    handleDecline(obj) {
        this.props.declineAuthorization({ hash: obj?.permission_hash });
    }

    handleAccept(obj) {
        this.props.acceptAuthorization({ hash: obj?.permission_hash });
    }

    renderLoading() {
        return (
            <Card className="default-card" elevation={0}>
                <CardContent>
                    <div className="card-w-100 card-sm-w-20">
                        <Skeleton variant="circle" height={36} width={36} />
                    </div>
                    <div className="card-w-100 card-sm-w-40">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="card-w-100 card-sm-w-20">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="card-w-100 card-md-w-20 card-buttons" />
                </CardContent>
            </Card>
        );
    }

    renderContent() {
        const { t, obj } = this.props;

        return (
            <CardContent>
                <div className="card-w-100 card-sm-w-10">
                    <AvatarGroup max={2}>
                        <Avatar
                            alt={obj.first_name + ' ' + obj.last_name}
                            src={obj.image_url}
                        />
                        <Avatar
                            alt={obj.content_object.title}
                            src={obj.content_object.image_url}
                        />
                    </AvatarGroup>
                </div>
                <div className="card-w-100 card-sm-w-40">
                    <Trans i18nKey="text.authorization" ns="common">
                        <strong>{{ name: obj.content_object?.name }}</strong>{' '}
                        wants to work together
                    </Trans>
                </div>
                <div className="card-w-100 card-sm-w-20">
                    <strong>{t('common:label.role')}:</strong>{' '}
                    {obj.level_display}
                </div>
                <div className="card-w-100 card-md-w-30 card-buttons">
                    {this.renderActions()}
                </div>
            </CardContent>
        );
    }

    renderActions() {
        const { isAccepted, isDeclined, obj, t } = this.props;

        if (isAccepted) {
            return (
                <Button variant="outlined" disabled>
                    {t('common:button.accepted')}
                </Button>
            );
        }

        if (isDeclined) {
            return (
                <Button variant="outlined" disabled>
                    {t('common:button.declined')}
                </Button>
            );
        }

        return (
            <React.Fragment>
                <Button
                    color={'secondary'}
                    onClick={() => this.handleAccept(obj)}
                    isLoading={this.props.isAccepting}
                >
                    {t('common:button.accept')}
                </Button>
                <Button
                    color={'danger'}
                    onClick={() => this.handleDecline(obj)}
                    isLoading={this.props.isDeclining}
                >
                    {t('common:button.decline')}
                </Button>
            </React.Fragment>
        );
    }

    renderCard() {
        const { isAccepted, isDeclined } = this.props;
        let hasDefaultAction = false;

        return (
            <Fade in={!isAccepted && !isDeclined} unmountOnExit>
                <Card className="default-card" elevation={0}>
                    {hasDefaultAction ? (
                        <CardActionArea onClick={this.handleChangeClub}>
                            {this.renderContent()}
                        </CardActionArea>
                    ) : (
                        this.renderContent()
                    )}
                </Card>
            </Fade>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return <React.Fragment>{this.renderCard()}</React.Fragment>;
    }
}

CardAuthorization.propTypes = {
    // props
    obj: PropTypes.object,
    isAccepting: PropTypes.bool,
    isAccepted: PropTypes.bool,
    errorAccept: PropTypes.object,
    isDeclining: PropTypes.bool,
    isDeclined: PropTypes.bool,
    isLoading: PropTypes.bool,
    errorDecline: PropTypes.object,
    // handlers
    declineAuthorization: PropTypes.func.isRequired,
    acceptAuthorization: PropTypes.func.isRequired,
    // other
    t: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.reducerKey || !state[ownProps.reducerKey]) {
        return {};
    }

    const {
        isAccepting,
        isAccepted,
        errorAccept,

        isDeclining,
        isDeclined,
        errorDecline,
    } = state[ownProps.reducerKey];

    return {
        isAccepting,
        isAccepted,
        errorAccept,

        isDeclining,
        isDeclined,
        errorDecline,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        acceptAuthorization: ({ hash }) =>
            dispatch(acceptAuthorization({ hash, key: ownProps.reducerKey })),
        declineAuthorization: ({ hash }) =>
            dispatch(declineAuthorization({ hash, key: ownProps.reducerKey })),
    };
};

const CardAuthorizationTranslated = withTranslation()(CardAuthorization);
const CardAuthorizationStyled = withStyles(useStyles)(
    CardAuthorizationTranslated
);
// const CardAuthorizationRouter = withRouter(CardAuthorizationStyled);
const CardAuthorizationState = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardAuthorizationStyled);
const CardAuthorizationReduced = dynamicInjectReducer({
    key: `componentCardAuthorization`,
    reducer: Reducer,
})(CardAuthorizationState);

export { CardAuthorizationReduced as CardAuthorization };
