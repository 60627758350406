import { resetPasswordConfirm as apiResetPasswordConfirm } from 'services/AuthenticationService';

import {
    RESET_PASSWORD_CONFIRM_LOADING,
    RESET_PASSWORD_CONFIRM_SUCCESS,
    RESET_PASSWORD_CONFIRM_FAILED,
} from './actionTypes';

export const resetPasswordConfirm = ({ uid, token, new_password }) => {
    return (dispatch) => {
        dispatch({ type: RESET_PASSWORD_CONFIRM_LOADING });

        apiResetPasswordConfirm(uid, token, new_password)
            .then((result) => {
                dispatch({
                    type: RESET_PASSWORD_CONFIRM_SUCCESS,
                    payload: result,
                });
            })
            .catch((data) => {
                dispatch({
                    type: RESET_PASSWORD_CONFIRM_FAILED,
                    payload: data,
                });
            });
    };
};
