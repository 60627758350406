export const ADMIN_CLUB_USERS_LOADING = 'admin_club_users_loading';
export const ADMIN_CLUB_USERS_SUCCESS = 'admin_club_users_success';
export const ADMIN_CLUB_USERS_FAILED = 'admin_club_users_failed';

export const ADMIN_CLUB_DELETE_INVITATION_LOADING =
    'admin_club_delete_invitation_loading';
export const ADMIN_CLUB_DELETE_INVITATION_SUCCESS =
    'admin_club_delete_invitation_success';
export const ADMIN_CLUB_DELETE_INVITATION_FAILED =
    'admin_club_delete_invitation_failed';

export const ADMIN_CLUB_RESEND_INVITATION_LOADING =
    'admin_club_resend_invitation_loading';
export const ADMIN_CLUB_RESEND_INVITATION_SUCCESS =
    'admin_club_resend_invitation_success';
export const ADMIN_CLUB_RESEND_INVITATION_FAILED =
    'admin_club_resend_invitation_failed';
