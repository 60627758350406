import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { urls, getUrl } from 'urls';

import { CardOrder } from 'components';

class CardOrderAdmin extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        event.stopPropagation();

        this.props.history.push({
            pathname: getUrl(urls.ADMIN_ORDER_DETAIL, {
                orderId: this.props.obj.id,
            }),
            search: '',
        });
    }

    render() {
        return (
            <CardOrder
                order={this.props.obj}
                {...this.props}
                onClick={this.handleClick}
                showUser
                admin
            />
        );
    }
}

CardOrderAdmin.propTypes = {
    // props
    obj: PropTypes.object,
    history: PropTypes.object,
};

const CardOrderAdminRouter = withRouter(CardOrderAdmin);

export { CardOrderAdminRouter as CardOrderAdmin };
