import {
    ADMIN_COURSES_PAGE_LOADING,
    ADMIN_COURSES_PAGE_LOADING_SUCCESS,
    ADMIN_COURSES_PAGE_LOADING_FAILED,
    ADMIN_COURSES_ACTIONS_LOADING,
    ADMIN_COURSES_ACTIONS_LOADING_SUCCESS,
    ADMIN_COURSES_ACTIONS_LOADING_FAILED,
    ADMIN_COURSES_RESET_CHANGED,
    ADMIN_COURSES_RESET_CREATED,
    ADMIN_COURSES_RESET_DELETED,
} from './actionTypes';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import {
    listAdminCourses,
    listAdminCourseListActions,
} from 'services/CourseService';

export const listCourses = (eventId, page = 1) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSES_PAGE_LOADING });

        listAdminCourses({ eventId, page })
            .then((result) => {
                dispatch({
                    type: ADMIN_COURSES_PAGE_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_COURSES_PAGE_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const listCourseListActions = (eventId) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSES_ACTIONS_LOADING });

        listAdminCourseListActions(eventId)
            .then((result) => {
                dispatch({
                    type: ADMIN_COURSES_ACTIONS_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_COURSES_ACTIONS_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const resetCreated = () => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSES_RESET_CREATED });
    };
};

export const resetChanged = () => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSES_RESET_CHANGED });
    };
};

export const resetDeleted = () => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSES_RESET_DELETED });
    };
};
