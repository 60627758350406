import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Button } from 'components';

const useStyles = (theme) => ({
    button: {
        marginTop: theme.spacing(3),
    },
});

class IntroText extends Component {
    renderButton() {
        if (!this.props.onButtonPress) {
            return;
        }

        return (
            <Button
                color={'secondary'}
                type={'submit'}
                onClick={this.props.onButtonPress}
                className={this.props.classes.button}
            >
                {this.props.buttonText}
            </Button>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Typography variant={'h2'} gutterBottom>
                    {this.props.title}
                </Typography>
                <Typography variant={'body1'} gutterBottom>
                    {this.props.description}
                </Typography>
                {this.renderButton()}
            </React.Fragment>
        );
    }
}

IntroText.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequiredIf((props) => props.onButtonPress),
    onButtonPress: PropTypes.func,
    classes: PropTypes.object.isRequired,
};

const IntroTextStyled = withStyles(useStyles)(IntroText);

export { IntroTextStyled as IntroText };
