import {
    GLOBAL_LOADING_START,
    GLOBAL_LOADING_END,
} from './actionTypes';

export const showGlobalLoader = () => {
    return {
        type: GLOBAL_LOADING_START
    };
};

export const hideGlobalLoader = () => {
    return {
        type: GLOBAL_LOADING_END
    };
};
