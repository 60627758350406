import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';

import { Card, CardContent, Divider, Grid, MenuItem } from '@material-ui/core';
import {
    Button,
    Checkbox,
    Select,
    TextInput,
    TimePicker,
    IntroText,
} from 'components';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: '24px',
    },
    cancel: {
        marginLeft: '15px',
    },
    divider: {
        marginLeft: '-32px',
        marginRight: '-32px',
        marginTop: '16px',
    },
}));

function CourseForm(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const course = props.course;
    let defaultValues = {};

    if (course) {
        // visibility
        if (course.status === 'published') {
            course.status = true;
        } else if (course.status === 'draft') {
            course.status = false;
        }

        // if (course.start_hour.length === 8) {
        //     course.start_hour = course.start_hour.substring(0, course.start_hour.length - 3);
        // }
        if (typeof course?.start_hour === 'string') {
            const date = new Date();
            const splitted = course.start_hour.split(':');
            date.setHours(splitted[0]);
            date.setMinutes(splitted[1]);
            course.start_hour = date;
        }

        // render default values
        defaultValues = course;
    }

    const { handleSubmit, register, control, setValue, watch } = useForm({
        defaultValues: defaultValues,
    });

    const cancel = () => {
        if (props.onCancel) {
            props.onCancel();
        } else {
            history.goBack();
        }
    };

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Grid
                container
                spacing={3}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                <Grid item md={6} xs={12}>
                    <IntroText
                        title={t('common:title.details')}
                        description={t('common:description.course.details')}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent
                            classes={{
                                root: classes.content,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextInput
                                        label={t('forms:label.name')}
                                        name="name"
                                        required
                                        error={
                                            props.errors && props.errors['name']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextInput
                                        label={t('forms:label.position')}
                                        name="position"
                                        number
                                        required
                                        error={
                                            props.errors &&
                                            props.errors['position']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextInput
                                        label={t('forms:label.height')}
                                        name="height"
                                        number
                                        required
                                        helpText={t('forms:help_text.height')}
                                        error={
                                            props.errors &&
                                            props.errors['height']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput
                                        label={t('forms:label.description')}
                                        name="description"
                                        multiline
                                        error={
                                            props.errors &&
                                            props.errors['description']
                                        }
                                        ref={register({})}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput
                                        label={t(
                                            'forms:label.subscriptions_allowed'
                                        )}
                                        name="subscriptions_allowed"
                                        required
                                        number
                                        min={0}
                                        error={
                                            props.errors &&
                                            props.errors[
                                                'subscriptions_allowed'
                                            ]
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Checkbox
                                        label={t('forms:label.status_course')}
                                        name="status"
                                        error={
                                            props.errors &&
                                            props.errors['status']
                                        }
                                        ref={register()}
                                        checked={defaultValues.status}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Checkbox
                                        label={t('forms:label.closed_course')}
                                        name="closed"
                                        error={
                                            props.errors &&
                                            props.errors['closed']
                                        }
                                        ref={register()}
                                        checked={defaultValues.closed}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item md={6} xs={12}>
                    <IntroText
                        title={t('common:title.type')}
                        description={t('common:description.course.type')}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent
                            classes={{
                                root: classes.content,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Select
                                        label={t('forms:label.type')}
                                        name="type"
                                        required
                                        control={control}
                                        setValue={setValue}
                                        error={
                                            props.errors && props.errors['type']
                                        }
                                        ref={register({ required: true })}
                                    >
                                        {props.types &&
                                            props.types.map((obj, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={obj.slug}
                                                >
                                                    {obj.label}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </Grid>

                                <Grid item xs={12}>
                                    <Divider className={classes.divider} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Checkbox
                                        label={t('forms:label.pony_course')}
                                        name="pony_course"
                                        error={
                                            props.errors &&
                                            props.errors['pony_course']
                                        }
                                        ref={register()}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item md={6} xs={12}>
                    <IntroText
                        title={t('common:title.timing')}
                        description={t('common:description.course.timing')}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent
                            classes={{
                                root: classes.content,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TimePicker
                                        label={t('forms:label.start_hour')}
                                        name={'start_hour'}
                                        setValue={setValue}
                                        watch={watch}
                                        control={control}
                                        error={
                                            props.errors &&
                                            props.errors['start_hour']
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item md={6} xs={12}>
                    <IntroText
                        title={t('common:title.pricing')}
                        description={t('common:description.course.pricing')}
                    />
                </Grid>

                <Grid item md={6} xs={12}>
                    <Card>
                        <CardContent
                            classes={{
                                root: classes.content,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextInput
                                        label={t(
                                            'forms:label.submission_price'
                                        )}
                                        name="submission_price"
                                        required
                                        number
                                        min={0}
                                        error={
                                            props.errors &&
                                            props.errors['submission_price']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        label={t(
                                            'forms:label.submission_price_locally'
                                        )}
                                        name="submission_price_locally"
                                        required
                                        number
                                        min={0}
                                        error={
                                            props.errors &&
                                            props.errors[
                                                'submission_price_locally'
                                            ]
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        color={'secondary'}
                        type={'submit'}
                        isLoading={props.isLoading}
                    >
                        {course
                            ? t('forms:button.edit_course')
                            : t('forms:button.add_course')}
                    </Button>
                    <Button
                        variant={'text'}
                        className={classes.cancel}
                        onClick={cancel}
                    >
                        {t('forms:button.cancel')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

CourseForm.propTypes = {
    errors: PropTypes.object,
    course: PropTypes.object,
    isLoading: PropTypes.bool,
    types: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export { CourseForm };
