import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { getUrl, urls } from 'urls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import EventService from 'lib/EventService';
import { EVENT } from 'enums';

import {
    AdminHeader,
    Toast,
    EmptyMessage,
    TemplateAdmin,
    Tabs,
    TabPanel,
    AdminClubUsers,
} from 'components';
import { scrollIntoView, AdminContext } from '../../utils';

import { getClub, addClub, editClub, resetClub } from './actions';
import { ClubForm } from './form';

class AdminClubAddEditPage extends Component {
    static contextType = AdminContext; // eslint-disable-line

    constructor(props) {
        super(props);

        const clubId = this.props.match.params.clubId;
        // alert(clubId)

        // render correct subpage
        const { location } = this.props;
        const query = new URLSearchParams(location.search.slice(1)); // eslint-disable-line no-undef
        let subpage = query.get('tab');

        let selectedTab = 'details';
        if (subpage) {
            selectedTab = subpage;
        }

        // set state
        this.state = {
            redirect: null,
            clubId: clubId,
            selectedTab: selectedTab,
            routes: [
                {
                    path: 'details',
                    name: props.t('common:text.menu.details'),
                    component: () => (
                        <ClubForm
                            onSubmit={this.handleSubmit}
                            errors={this.props.formErrors}
                            isLoading={
                                this.props.isAdding || this.props.isEditing
                            }
                            club={this.props.club}
                        />
                    ),
                },
                {
                    path: 'users',
                    name: props.t('common:text.menu.users'),
                    // component: AdminEventTickets,
                    component: AdminClubUsers,
                },
            ],
        };

        // reset
        this.props.resetClub();

        // load club
        if (clubId) {
            this.props.getClub(clubId);
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const { t } = this.props;
        const { clubId } = this.state;

        this.context.setAdminTitle(
            clubId
                ? t('common:title.admin.club_edit')
                : t('common:title.admin.club_add')
        );
        this.context.setAdminBackAction(() =>
            this.props.history.push(getUrl(urls.ADMIN_CLUBS))
        );
    }

    componentWillUnmount() {
        // reset
        // this.props.resetClub();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.newClub !== this.props.newClub && this.props.newClub) {
            // success
            this.setState({
                redirect: true,
            });

            EventService.trigger(EVENT.CLUB_CHANGED);
        }

        if (
            prevProps.formErrors !== this.props.formErrors &&
            this.props.formErrors
        ) {
            scrollIntoView('#top-form');
        }
    }

    handleSubmit(data) {
        if (this.props.club) {
            data.id = this.props.club.id;
            this.props.editClub(data);
        } else {
            this.props.addClub(data);
        }
    }

    handleChange(event, value) {
        // set in state
        this.setState({
            selectedTab: value,
        });
        // set in url
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: '?tab=' + value,
        });
    }

    renderContent() {
        const { t, error } = this.props;
        const { redirect, clubId } = this.state;

        return (
            <React.Fragment>
                {error && (
                    <Toast
                        message={t('common:error.general.description')}
                        open
                    />
                )}
                {redirect && (
                    <React.Fragment>
                        <Redirect to={getUrl(urls.ADMIN_CLUBS)} />
                        <Toast
                            message={t('common:success.general.description')}
                            type={'success'}
                            open
                        />
                    </React.Fragment>
                )}
                <TemplateAdmin header removeBottomPadding={clubId}>
                    <AdminHeader
                        title={
                            clubId
                                ? this.props.club?.name
                                : t('common:title.admin.club_add')
                        }
                        breadcrumbs={[
                            {
                                title: t('common:text.menu.clubs'),
                                url: getUrl(urls.ADMIN_CLUBS),
                            },
                            {
                                title: clubId
                                    ? this.props.club?.name
                                    : t('common:title.admin.club_add'),
                            },
                        ]}
                        removeMargin
                    />

                    {clubId && (
                        <Tabs
                            selectedTab={this.state.selectedTab}
                            onTabChange={this.handleChange}
                            tabs={this.state.routes.map((obj, index) => ({
                                value: obj.path,
                                label: obj.name,
                            }))}
                        />
                    )}
                </TemplateAdmin>
                <TemplateAdmin>
                    <div id="top-form" />

                    {!clubId ? (
                        <ClubForm
                            onSubmit={this.handleSubmit}
                            errors={this.props.formErrors}
                            isLoading={
                                this.props.isAdding || this.props.isEditing
                            }
                            club={this.props.club}
                        />
                    ) : (
                        this.state.routes.map((obj, index) => {
                            const Component = obj.component;
                            return (
                                <TabPanel
                                    value={this.state.selectedTab}
                                    index={obj.path}
                                >
                                    <Component club={this.props.club} />
                                </TabPanel>
                            );
                        })
                    )}
                </TemplateAdmin>
            </React.Fragment>
        );
    }

    renderLoader() {
        const { t } = this.props;

        return (
            <EmptyMessage
                title={t('common:title.load_club')}
                description={t('common:description.club.load_club')}
                showLoader
            />
        );
    }

    render() {
        const { isLoading, t, history } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <Helmet>
                    <title>
                        {this.state.clubId
                            ? t('common:title.admin.club_edit')
                            : t('common:title.admin.club_add')}{' '}
                        | {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(history.location.pathname, {}, true)}
                    />
                </Helmet>
                {isLoading ? this.renderLoader() : this.renderContent()}
            </Suspense>
        );
    }
}

AdminClubAddEditPage.propTypes = {
    // props
    match: PropTypes.object,
    history: PropTypes.object,
    newClub: PropTypes.object,
    formErrors: PropTypes.object,
    club: PropTypes.object,
    isAdding: PropTypes.bool,
    error: PropTypes.string,
    isEditing: PropTypes.bool,
    isLoading: PropTypes.bool,
    // handlers
    t: PropTypes.func.isRequired,
    resetClub: PropTypes.func.isRequired,
    getClub: PropTypes.func.isRequired,
    addClub: PropTypes.func.isRequired,
    editClub: PropTypes.func.isRequired,
    location: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isAdding,
        isEditing,
        isLoading,
        newClub,
        club,
        formErrors,
        error,
    } = state.pageAdminClubAddEdit;

    return {
        isAdding,
        isEditing,
        isLoading,
        newClub,
        club,
        formErrors,
        error,
    };
};

const AdminClubAddEditPageTranslated = withTranslation()(AdminClubAddEditPage);
const AdminClubAddEditPageState = connect(mapStateToProps, {
    addClub,
    getClub,
    editClub,
    resetClub,
})(AdminClubAddEditPageTranslated);
const AdminClubAddEditPageRouter = withRouter(AdminClubAddEditPageState);
export { AdminClubAddEditPageRouter as AdminClubAddEditPage };
