export const urls = {
    // public
    ACCOUNT_PAGE_CONFIRM: '/account/:pageType/:uid/:token',
    ACCOUNT_SUBPAGE_CONFIRM: '/account/:pageType/:subpageType/:uid/:token',
    COOKIE: '/cookie',
    DISCLAIMER: '/disclaimer',
    DASHBOARD_EVENT: '/dashboard/:eventId/',
    EVENTS: '/events',
    EVENT_COURSE_START_LIST: '/events/:eventId/course/:courseId/start-list',
    EVENT_DETAIL: '/events/:eventId/',
    FORGOT_PASSWORD: '/forgot-password',
    HELP: '/help',
    HOME: '/',
    LOGIN: '/login',
    LOGOUT: '/user/logout',
    ORGANISERS: '/organisers',
    PRIVACY: '/privacy',
    PRICING: '/pricing',
    REGISTER: '/register',
    RESET_PASSWORD_CONFIRM: '/password/reset/confirm/:uid/:token',
    TERMS: '/terms',
    TICKET_DETAIL_QR_CODE: '/ticket/:id',
    USER_ORDERS: '/user/orders',
    USER_PROFILE: '/user/profile',
    USER_NOTIFICATIONS: '/user/notifications',
    USER_SUBPAGE: '/user/:subpage',
    USER_TICKETS: '/user/tickets',
    // admin
    ADMIN: '/admin',
    ADMIN_CLUBS: '/admin/clubs',
    ADMIN_CLUB_ADD: '/admin/clubs/add',
    ADMIN_CLUB_CHANGE: '/admin/clubs/change/:clubId',
    ADMIN_DASHBOARD: '/admin/dashboard',
    ADMIN_EVENTS: '/admin/events',
    ADMIN_EVENT_ADD: '/admin/events/add',
    ADMIN_EVENT_CHANGE: '/admin/events/change/:eventId',
    ADMIN_EVENT_COURSES: '/admin/events/:eventId/courses',
    ADMIN_EVENT_COURSE_ADD: '/admin/events/:eventId/courses/add',
    ADMIN_EVENT_COURSE_CHANGE:
        '/admin/events/:eventId/courses/change/:courseId',
    ADMIN_EVENT_COURSE_START_LIST:
        '/admin/events/:eventId/course/:courseId/start-list',
    ADMIN_EVENT_DETAIL: '/admin/events/:eventId',
    ADMIN_EVENT_TICKET_DETAIL: '/admin/events/:eventId/tickets/:ticketId',
    ADMIN_ORDERS: '/admin/orders',
    ADMIN_ORDER_DETAIL: '/admin/orders/:orderId',
    ADMIN_STATISTICS: '/admin/statistics',
    ADMIN_SUBPAGE: '/admin/:subpage',
};

export const getUrl = (urlName, params, fullUrl = false) => {
    if (Object.values(urls).indexOf(urlName) < 0) {
        return fullUrl ? `https://www.equento.be/${urlName}` : urlName;
    }

    if (!params || Object.keys(params).length === 0) {
        return fullUrl ? `https://www.equento.be/${urlName}` : urlName;
    }

    const segments = urlName.split('/');

    const value = segments
        .map((segment) => {
            const offset = segment.indexOf(':') + 1;

            if (!offset) {
                return segment;
            }

            const key = segment.slice(offset);

            return params[key];
        })
        .join('/');

    return fullUrl ? `https://www.equento.be/${value}` : value;
};
