import {
    LOGIN_PAGE_LOGIN_LOADING,
    LOGIN_PAGE_LOGIN_LOADING_FAILED,
    LOGIN_PAGE_LOGIN_LOADING_SUCCESS,

    LOGIN_FORM_LOADING,
    // LOGIN_FORM_LOADING_FAILED,
    LOGIN_FORM_LOADING_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    formErrors: null,
    isLoggingIn: false,
    isLoadingForm: true,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_PAGE_LOGIN_LOADING:
            return {
                ...state,
                isLoggingIn: false,
                formErrors: null,
            };
        case LOGIN_PAGE_LOGIN_LOADING_FAILED:
            return {
                ...state,
                isLoggingIn: false,
                formErrors: action.payload,
            };
        case LOGIN_PAGE_LOGIN_LOADING_SUCCESS:
            return {
                ...state,
                isLoggingIn: false,
            };

        case LOGIN_FORM_LOADING:
            return {
                ...state,
                isLoadingForm: true,
            };
        case LOGIN_FORM_LOADING_SUCCESS:
            return {
                ...state,
            };

        default:
            return state;
    }
};
