// authentication
export const AUTHENTICATION_CHECKING = 'authentication_checking';
export const AUTHENTICATION_CHECKING_FAILED = 'authentication_checking_failed';
export const AUTHENTICATION_CHECKING_SUCCESS =
    'authentication_checking_success';

// authorizations
export const AUTHORIZATIONS_LOADING = 'authorizations_loading';
export const AUTHORIZATIONS_LOADING_SUCCESS = 'authorizations_loading_success';
export const AUTHORIZATIONS_LOADING_FAILED = 'authorizations_loading_failed';

// profile
export const PROFILE_LOADING = 'profile_loading';
export const PROFILE_SUCCESS = 'profile_success';
export const PROFILE_FAILED = 'profile_failed';

export const PROFILE_UPDATING_LOADING = 'profile_updating_loading';
export const PROFILE_UPDATING_SUCCESS = 'profile_updating_success';
export const PROFILE_UPDATING_FAILED = 'profile_updating_failed';

// user
export const USER_UPDATING_LOADING = 'user_updating_loading';
export const USER_UPDATING_SUCCESS = 'user_updating_success';
export const USER_UPDATING_FAILED = 'user_updating_failed';

export const USER_LOADING = 'user_loading';
export const USER_SUCCESS = 'user_success';
export const USER_FAILED = 'user_failed';

export const USER_EMAIL_UPDATING_LOADING = 'user_email_updating_loading';
export const USER_EMAIL_UPDATING_SUCCESS = 'user_email_updating_success';
export const USER_EMAIL_UPDATING_FAILED = 'user_email_updating_failed';

export const USER_PASSWORD_UPDATING_LOADING = 'user_password_updating_loading';
export const USER_PASSWORD_UPDATING_SUCCESS = 'user_password_updating_success';
export const USER_PASSWORD_UPDATING_FAILED = 'user_password_updating_failed';

// GLOBAL
export const GLOBAL_LOADING_START = 'global_loading_start';
export const GLOBAL_LOADING_END = 'global_loading_end';
