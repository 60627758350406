import {
    ADMIN_TICKETS_PAGE_LOADING,
    ADMIN_TICKETS_PAGE_LOADING_SUCCESS,
    ADMIN_TICKETS_PAGE_LOADING_FAILED,
    ADMIN_TICKETS_ACTIONS_LOADING,
    ADMIN_TICKETS_ACTIONS_LOADING_SUCCESS,
    ADMIN_TICKETS_ACTIONS_LOADING_FAILED,
} from './actionTypes';

import {
    listAdminTickets,
    listAdminTicketListActions,
} from 'services/TicketService';

export const listTickets = ({ eventId, page = 1 }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_TICKETS_PAGE_LOADING });

        listAdminTickets(eventId, page)
            .then((result) => {
                dispatch({
                    type: ADMIN_TICKETS_PAGE_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_TICKETS_PAGE_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const listTicketListActions = (eventId) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_TICKETS_ACTIONS_LOADING });

        listAdminTicketListActions(eventId)
            .then((result) => {
                dispatch({
                    type: ADMIN_TICKETS_ACTIONS_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_TICKETS_ACTIONS_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};
