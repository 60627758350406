import { getAdminCourse, exportStartlist } from 'services/CourseService';
import { getAdminEvent } from 'services/EventService';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import {
    ADMIN_COURSE_LOADING,
    ADMIN_COURSE_SUCCESS,
    ADMIN_COURSE_FAILED,
    ADMIN_COURSE_EVENT_LOADING,
    ADMIN_COURSE_EVENT_SUCCESS,
    ADMIN_COURSE_EVENT_FAILED,
    ADMIN_COURSE_RESET_CHANGED,
    ADMIN_COURSE_RESET_CREATED,
    ADMIN_COURSE_EXPORT_START_LIST_LOADING,
    ADMIN_COURSE_EXPORT_START_LIST_SUCCESS,
    ADMIN_COURSE_EXPORT_START_LIST_FAILED,
} from './actionTypes';

export const getCourse = (id) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_LOADING });

        getAdminCourse(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_COURSE_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_COURSE_FAILED,
                    payload: error,
                });
            });
    };
};

export const getEvent = (id) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_EVENT_LOADING });

        getAdminEvent(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_COURSE_EVENT_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_COURSE_EVENT_FAILED,
                    payload: error,
                });
            });
    };
};

export const resetCreated = () => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_RESET_CREATED });
    };
};

export const resetChanged = () => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_RESET_CHANGED });
    };
};

export const downloadStartlist = ({ id }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_COURSE_EXPORT_START_LIST_LOADING });

        exportStartlist({ id })
            .then((result) => {
                const [, filename] = result.headers[
                    'content-disposition'
                ].split('filename=');

                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.exported_start_list'
                    ),
                });

                dispatch({
                    type: ADMIN_COURSE_EXPORT_START_LIST_SUCCESS,
                    payload: {
                        data: result.data,
                        filename: filename,
                    },
                });
            })
            .catch((error) => {
                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.exported_start_list'
                    ),
                });

                dispatch({
                    type: ADMIN_COURSE_EXPORT_START_LIST_FAILED,
                    payload: error,
                });
            });
    };
};
