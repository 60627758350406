import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Button, CountrySelect, DatePicker, Select, TextInput } from '../..';

function ProfileForm(props) {
    const { t } = useTranslation();

    const defaultValues = _.reduce(
        props.data,
        (result, value, key) => {
            result[key] = value['value'];
            return result;
        },
        {}
    );

    // global.log('>>> default values ', defaultValues)

    const { register, handleSubmit, control, watch, setValue } = useForm({
        defaultValues: defaultValues,
    });

    const renderField = (data, key) => {
        const obj = data[key];

        switch (obj.type) {
            case 'country':
                return (
                    <CountrySelect
                        label={obj.label}
                        name={key}
                        required={obj.required}
                        control={control}
                        setValue={setValue}
                        ref={register({ required: obj.required })}
                    />
                );
            case 'select':
                return (
                    <Select
                        label={obj.label}
                        name={key}
                        control={control}
                        required={obj.required}
                        error={props.errors && props.errors[key]}
                        ref={(ref) => {
                            if (!ref) return;
                            register({
                                required: obj.required,
                                name: key,
                                value: ref.value,
                            });
                        }}
                    >
                        {obj.options}
                    </Select>
                );
            case 'date':
                return (
                    <DatePicker
                        label={obj.label}
                        name={key}
                        control={control}
                        required={obj.required}
                        error={props.errors && props.errors[key]}
                        // ref={register({ required: obj.required })}
                        defaultValue={obj.value}
                        watch={watch}
                        setValue={setValue}
                    />
                );
            case 'text':
                return (
                    <TextInput
                        label={obj.label}
                        name={key}
                        error={props.errors && props.errors[key]}
                        required={obj.required}
                        ref={register({ required: obj.required })}
                    />
                );
            case 'email':
                return (
                    <TextInput
                        label={obj.label}
                        name={key}
                        email
                        error={props.errors && props.errors[key]}
                        required={obj.required}
                        ref={register({ required: obj.required })}
                    />
                );
            case 'password':
                return (
                    <TextInput
                        label={obj.label}
                        name={key}
                        password
                        error={props.errors && props.errors[key]}
                        required={obj.required}
                        ref={register({ required: obj.required })}
                    />
                );
            default:
                return;
        }
    };

    const renderFields = () => {
        const data = props.data;

        if (data) {
            return Object.keys(data).map((key, index) => (
                <Grid item xs={12} key={index}>
                    {renderField(data, key)}
                </Grid>
            ));
        }
    };

    return (
        <form onSubmit={handleSubmit(props.action)} style={{ width: '100%' }}>
            <Grid container spacing={3}>
                {renderFields()}
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color={'secondary'}
                        isLoading={props.isLoading}
                        type={'submit'}
                    >
                        {t('common:button.save')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

ProfileForm.propTypes = {
    data: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
};

export { ProfileForm };
