export const EVENT_ADD_LOADING = 'event_add_loading';
export const EVENT_ADD_SUCCESS = 'event_add_success';
export const EVENT_ADD_FAILED = 'event_add_failed';

export const EVENT_LOADING = 'event_loading';
export const EVENT_LOADING_SUCCESS = 'event_loading_success';
export const EVENT_LOADING_FAILED = 'event_loading_failed';

export const EVENT_EDIT_LOADING = 'event_edit_loading';
export const EVENT_EDIT_SUCCESS = 'event_edit_success';
export const EVENT_EDIT_FAILED = 'event_edit_failed';

export const EVENT_CLUBS_LOADING = 'event_clubs_loading';
export const EVENT_CLUBS_SUCCESS = 'event_clubs_success';
export const EVENT_CLUBS_FAILED = 'event_clubs_failed';

export const EVENT_RESET = 'event_reset';
