import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import history from 'services/history';

import { Grid, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { listTickets } from './actions';

import { CardTicket, EmptyMessage } from '..';
import { scrollIntoView } from '../../utils';

const useStyles = (theme) => ({
    title: {
        marginBottom: '32px',
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
    },
});

class Tickets extends Component {
    constructor(props) {
        super(props);

        // check if there's a page
        const query = new URLSearchParams(window.location.search.slice(1));
        let page = query.get('page');
        let ticket = query.get('ticket');

        if (!page || page <= 0) {
            page = 1;
        }

        // save to state
        this.state = {
            openTicketId: ticket,
        };

        // load the orders
        this.props.listTickets(page);

        // event handlers
        this.handlePage = this.handlePage.bind(this);
    }

    handlePage(object, page) {
        // load next page
        this.props.listTickets(page);

        scrollIntoView('#top-tickets');
        history.push(window.location.pathname + '?page=' + page);
    }

    renderEmpty() {
        const { t } = this.props;

        return (
            <EmptyMessage
                title={t('common:title.no_tickets')}
                description={t('common:text.no_tickets')}
            />
        );
    }

    renderLoader() {
        const { t, classes } = this.props;

        return (
            <React.Fragment>
                <div id="top-tickets" />
                <Typography variant="h2" className={classes.title}>
                    {t('common:title.tickets')}
                </Typography>

                {[...new Array(10)].map((key) => (
                    <CardTicket key={key} isLoading />
                ))}
            </React.Fragment>
        );
    }

    renderPagination() {
        const { classes, total_pages, current_page } = this.props;

        if (total_pages <= 0) {
            return;
        }

        return (
            <Grid item xs={12}>
                <div className={classes.flex}>
                    <Pagination
                        count={total_pages}
                        shape="rounded"
                        page={current_page}
                        onChange={this.handlePage}
                    />
                </div>
            </Grid>
        );
    }

    render() {
        const { t, tickets, isLoading, classes } = this.props;

        if (isLoading) {
            return this.renderLoader();
        }

        if (!tickets || tickets.length === 0) {
            return this.renderEmpty();
        }

        return (
            <React.Fragment>
                <div id="top-tickets" />
                <Typography variant="h2" className={classes.title}>
                    {t('common:title.tickets')}
                </Typography>

                {tickets.map((obj, i) => {
                    return (
                        <CardTicket
                            ticket={obj}
                            key={i}
                            openDialog={`${obj.id}` === this.state.openTicketId}
                        />
                    );
                })}

                {this.renderPagination()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { isLoading, error, tickets } = state.componentTickets;

    return {
        isLoading,
        error,
        tickets,
    };
};

const TicketsState = connect(mapStateToProps, {
    listTickets,
})(Tickets);
const TicketsTranslated = withTranslation()(TicketsState);
const TicketsStyled = withStyles(useStyles)(TicketsTranslated);

export { TicketsStyled as Tickets };
