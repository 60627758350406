import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, TextInput } from 'components';
import { Grid } from '@material-ui/core';

function DiscountForm(props) {
    const { t } = useTranslation();

    const defaultValues = _.reduce(
        props.data,
        (result, value, key) => {
            result[key] = value['value'];
            return result;
        },
        {}
    );

    const { register, handleSubmit } = useForm({
        defaultValues: defaultValues,
    });

    return (
        <form onSubmit={handleSubmit(props.action)} style={{ width: '100%' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextInput
                        label={t('forms:input.discount')}
                        name="discount"
                        required
                        number
                        error={props.errors && props.errors['discount_amount']}
                        ref={register({ required: true })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        fullWidth
                        color={'secondary'}
                        isLoading={props.isLoading}
                        type={'submit'}
                    >
                        {t('forms:button.apply_discount')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

DiscountForm.propTypes = {
    data: PropTypes.object.isRequired,
    action: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
};

export { DiscountForm };
