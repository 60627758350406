import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import Root from "./Root";

import * as serviceWorker from "./serviceWorker";

import "./styles/index.scss";

// init Sentry in production
/* eslint-disable no-undef */
if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://aad46c33b6ad4162812d9174c03e75f2@o26826.ingest.sentry.io/5607669",
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}
/* eslint-enable no-undef */

// render it
/* eslint-disable no-undef */
ReactDOM.render(
    <React.StrictMode>
        <Root />
    </React.StrictMode>,
    document.getElementById("root")
);
/* eslint-enable no-undef */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
