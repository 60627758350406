import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
// import moment from 'moment';

import PropTypes from 'prop-types';
import { urls, getUrl } from 'urls';
import { getIdFromUrl } from '../../utils';

import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = (theme) => ({
    item: {
        borderBottomColor: theme.colors.mercury,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    link: {
        color: theme.colors.mikado + ' !important',
        display: 'block',
        fontWeight: 'normal !important',

        '&:hover': {
            textDecoration: 'none !important',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
});

class TicketItem extends Component {
    // renderDate() {
    //     const { payment } = this.props;
    //
    //     switch (payment.status) {
    //         case 'open':
    //         case 'pending':
    //             return moment(payment.created_at).format('LLL');
    //         case 'authorized':
    //             return moment(payment.completed_at).format('LLL');
    //         case 'paid':
    //             return moment(payment.paid_at).format('LLL')
    //         case 'expired':
    //             return moment(payment.expired_at).format('LLL');
    //         case 'failed':
    //             return moment(payment.failed_at).format('LLL');
    //         case 'cancelled':
    //             return moment(payment.cancelled_at).format('LLL');
    //
    //         default:
    //             return;
    //     }
    // }

    render() {
        const { t, ticket, classes, isLoading } = this.props;

        if (isLoading) {
            return (
                <Grid container>
                    <Grid item sm={2} xs={12} className={classes.item}>
                        <Skeleton height={18} width={40} />
                    </Grid>
                    <Grid item sm={4} xs={12} className={classes.item}>
                        <Skeleton height={24} width={'50%'} />
                    </Grid>
                    <Grid item sm={3} xs={12} className={classes.item}>
                        <Skeleton height={24} width={'50%'} />
                    </Grid>
                    <Grid item sm={3} xs={12} className={classes.item}>
                        <Skeleton height={24} width={'50%'} />
                    </Grid>
                </Grid>
            );
        }

        const count = ticket.subscriptions.length;

        return (
            <Link
                to={getUrl(urls.ADMIN_EVENT_TICKET_DETAIL, {
                    ticketId: ticket.id,
                    eventId: getIdFromUrl(ticket.event),
                })}
                className={classes.link}
            >
                <Grid container>
                    <Grid item sm={2} xs={12} className={classes.item}>
                        <small>#{ticket.id}</small>
                    </Grid>
                    <Grid item sm={4} xs={12} className={classes.item}>
                        {ticket.user ? (
                            ticket.user?.first_name +
                            ' ' +
                            ticket.user?.last_name
                        ) : (
                            <small>{t('common:text.anonymous_user')}</small>
                        )}
                    </Grid>
                    <Grid item sm={3} xs={12} className={classes.item}>
                        <small>
                            <Trans
                                i18nKey="common:text.amount_subscription"
                                count={count}
                                ns="common"
                            >
                                {{ count }} subscriptions
                            </Trans>
                        </small>
                    </Grid>
                    <Grid item sm={3} xs={12} className={classes.item}>
                        <small>
                            {ticket.scanned_on
                                ? ticket.scanned_on
                                : t('common:text.not_scanned')}
                        </small>
                    </Grid>
                </Grid>
            </Link>
        );
    }
}

TicketItem.propTypes = {
    ticket: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    classes: PropTypes.object,
};

const TicketItemStyled = withStyles(useStyles)(TicketItem);
const TicketItemTranslated = withTranslation()(TicketItemStyled);

export { TicketItemTranslated as TicketItem };
