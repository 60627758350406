import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";

import cookieEN from "../translations/en/cookie.json";
import commonEN from "../translations/en/common.json";
import disclaimerEN from "../translations/en/disclaimer.json";
import formsEN from "../translations/en/forms.json";
import legalEN from "../translations/en/legal.json";

import cookieNL from "../translations/nl/cookie.json";
import commonNL from "../translations/nl/common.json";
import disclaimerNL from "../translations/nl/disclaimer.json";
import formsNL from "../translations/nl/forms.json";
import legalNL from "../translations/nl/legal.json";

const resources = {
    en: {
        common: commonEN,
        cookie: cookieEN,
        disclaimer: disclaimerEN,
        forms: formsEN,
        legal: legalEN,
    },
    nl: {
        common: commonNL,
        cookie: cookieNL,
        disclaimer: disclaimerNL,
        forms: formsNL,
        legal: legalNL,
    },
};

export default class TranslationUtil {
    static initialize(reducers) {
        // load translations
        i18n.use(LanguageDetector)
            .use(initReactI18next)
            .use(i18nextPlugin)
            .init({
                interpolation: { escapeValue: false }, // React already does escaping
                fallbackLng: "en",
                resources: resources,
            });
    }

    static getTranslations() {
        return i18n;
    }
}
