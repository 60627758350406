import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Button as MuiButton, Grid } from '@material-ui/core';
import { Checkbox } from '../..';
import { Dialog } from '..';
import { buildStartList, getCountryImage } from '../../../utils';

const useStyles = (theme) => ({
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    contentText: {
        padding: '0 8px',
        color: theme.colors.mikado,
        textAlign: 'center',
    },
    row: {
        borderBottomColor: theme.colors.mercury,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: 'left',

        '&:last-child': {
            borderBottomWidth: 0,
            marginBottom: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
    },
    action: {
        textAlign: 'right',
    },
    hide: {
        display: 'none',
    },
    subscription: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',

        '& img': {
            borderRadius: '5px',
            marginRight: theme.spacing(2),
            width: '40px',

            [theme.breakpoints.up('sm')]: {
                width: '50px',
            },
        },
    },
    subscriptionContent: {
        '& small': {
            display: 'block',
        },
    },
    cellStartnumber: {
        maxWidth: '15%',
    },
});

class DialogStartNumber extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            showFull: false,
        };

        // event handler
        this.handleShowFull = this.handleShowFull.bind(this);
    }

    handleShowFull(event) {
        event.preventDefault();

        this.setState({
            showFull: !this.state.showFull,
        });
    }

    handleSelectStartNumber(startNumber) {
        if (this.props.onSelect) {
            this.props.onSelect(startNumber);
            this.props.onClose();
        }
    }

    renderContent() {
        const { course, classes, t } = this.props;

        const subscriptions = buildStartList({
            subscriptions: course.subscriptions,
            max: course.subscriptions_allowed,
        });

        const renderSubscription = (subscription) => {
            const countryImage = getCountryImage(
                subscription.combinations[0].country
            );

            return (
                <div className={classes.subscription}>
                    <img src={countryImage} alt="" />
                    <div className={classes.subscriptionContent}>
                        <strong>
                            {subscription.combinations[0].first_name}{' '}
                            {subscription.combinations[0].last_name}
                        </strong>
                        <small>{subscription.combinations[0].horse_name}</small>
                    </div>
                </div>
            );
        };

        return (
            <Grid container alignItems={'center'} justifyContent={'flex-start'}>
                {subscriptions.map((obj, index) => {
                    if (!obj) {
                        return (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                className={classes.row}
                            >
                                <Grid
                                    item
                                    sm={2}
                                    xs={12}
                                    className={classes.cellStartnumber}
                                >
                                    {index}.<br />
                                </Grid>
                                <Grid item sm={8} xs={12}>
                                    <small>{t('forms:label.free')}</small>
                                </Grid>
                                <Grid
                                    item
                                    sm={2}
                                    xs={12}
                                    className={classes.action}
                                >
                                    <MuiButton
                                        onClick={() =>
                                            this.handleSelectStartNumber(index)
                                        }
                                        variant={'text'}
                                    >
                                        {t('common:link.select')}
                                    </MuiButton>
                                </Grid>
                            </Grid>
                        );
                    } else if (obj.reserved) {
                        return (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                className={classNames(
                                    classes.row,
                                    !this.state.showFull && classes.hide
                                )}
                            >
                                <Grid
                                    item
                                    sm={2}
                                    xs={12}
                                    className={classes.cellStartnumber}
                                >
                                    {index}.<br />
                                </Grid>
                                <Grid item sm={10} xs={12}>
                                    <small>{t('forms:label.reserved')}</small>
                                </Grid>
                            </Grid>
                        );
                    } else {
                        return (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                className={classNames(
                                    classes.rowSubscription,
                                    classes.row,
                                    !this.state.showFull && classes.hide
                                )}
                            >
                                <Grid
                                    item
                                    sm={2}
                                    xs={12}
                                    className={classes.cellStartnumber}
                                >
                                    {index}.<br />
                                </Grid>
                                <Grid item sm={10} xs={12}>
                                    {this.props.startNumber ===
                                    obj.start_number ? (
                                        <small>
                                            {t(
                                                'common:text.current_start_number'
                                            )}
                                        </small>
                                    ) : (
                                        renderSubscription(obj)
                                    )}
                                </Grid>
                            </Grid>
                        );
                    }
                })}
            </Grid>
        );
    }

    renderCheckbox() {
        const { t } = this.props;

        return (
            <Checkbox
                checked={!this.state.showFull}
                name={'show-full'}
                label={t('forms:label.show_free')}
                onChange={this.handleShowFull}
            />
        );
    }

    render() {
        const { onClose, open, t } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={t('common:title.select_start_number')}
                showLine
            >
                <Grid container>
                    <Grid item xs={12}>
                        {this.renderCheckbox()}
                        {this.renderContent()}
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

DialogStartNumber.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    course: PropTypes.object.isRequired,
    startNumber: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

DialogStartNumber.defaultProps = {
    open: false,
};

const DialogStartNumberStyled = withStyles(useStyles)(DialogStartNumber);
const DialogStartNumberTranslated = withTranslation()(DialogStartNumberStyled);

export { DialogStartNumberTranslated as DialogStartNumber };
