import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import PropTypes from 'prop-types';

import { listAuthorizations } from './actions';

import { CardAuthorization } from 'components';
import { scrollIntoView } from 'utils';

const useStyles = (theme) => ({
    title: {
        marginBottom: '32px',
    },
    flex: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(5),
    },
});

class Notifications extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handlePage = this.handlePage.bind(this);
    }

    componentDidMount() {
        this.props.listAuthorizations();
    }

    handlePage(object, page) {
        // load next page
        this.props.listAuthorizations(page);

        scrollIntoView('#top-page');
        this.props.history.push(window.location.pathname + '?page=' + page); // eslint-disable-line no-undef
    }

    renderPagination() {
        const { classes, totalPages, currentPage } = this.props;

        if (totalPages <= 0) {
            return;
        }

        return (
            <Grid item xs={12}>
                <div className={classes.flex}>
                    <Pagination
                        count={totalPages}
                        shape="rounded"
                        page={currentPage}
                        onChange={this.handlePage}
                    />
                </div>
            </Grid>
        );
    }

    renderAuthorizations() {
        const { authorizations, t } = this.props;

        if (authorizations.length === 0) {
            return (
                <Typography variant="caption">
                    {t('common:text.no_authorizations.description')}
                </Typography>
            );
        }

        return (
            <React.Fragment>
                {authorizations.map((obj) => (
                    <CardAuthorization obj={obj} />
                ))}
                {this.renderPagination()}
            </React.Fragment>
        );
    }

    renderContent() {
        const { t, classes } = this.props;

        return (
            <React.Fragment>
                <Typography variant="h3" className={classes.title}>
                    {t('common:title.authorizations')}
                </Typography>
                {this.renderAuthorizations()}
            </React.Fragment>
        );
    }

    renderLoading() {
        return [...new Array(4)].map(() => <CardAuthorization isLoading />);
    }

    render() {
        const { t, classes, isLoading } = this.props;

        return (
            <React.Fragment>
                <Typography variant="h2" className={classes.title}>
                    {t('common:title.notifications')}
                </Typography>

                {isLoading ? this.renderLoading() : this.renderContent()}
            </React.Fragment>
        );
    }
}

Notifications.propTypes = {
    // props
    isLoading: PropTypes.bool,
    authorizations: PropTypes.object.isRequiredIf((props) => !props.isLoading),
    error: PropTypes.object,
    totalAuthorizations: PropTypes.number.isRequiredIf(
        (props) => !props.isLoading
    ),
    totalPages: PropTypes.number.isRequiredIf((props) => !props.isLoading),
    currentPage: PropTypes.number.isRequiredIf((props) => !props.isLoading),
    // handlers
    listAuthorizations: PropTypes.func.isRequired,
    // other
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        error,
        authorizations,
        totalAuthorizations,
        totalPages,
        currentPage,
    } = state.componentNotifications;

    return {
        isLoading,
        error,
        authorizations,
        totalAuthorizations,
        totalPages,
        currentPage,
    };
};

const NotificationsState = connect(mapStateToProps, {
    listAuthorizations,
})(Notifications);
const NotificationsTranslated = withTranslation()(NotificationsState);
const NotificationsStyled = withStyles(useStyles)(NotificationsTranslated);
const NotificationsRouter = withRouter(NotificationsStyled);

export { NotificationsRouter as Notifications };
