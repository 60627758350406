import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

export default function RouteWrapper({
    component: Component,
    isPrivate,
    authenticated,
    ...rest
}) {
    /**
    * Redirect user to SignIn page if he tries to access a private route
    * without authentication.
    */
    if (isPrivate && !authenticated) {
        return (<Redirect to={{
            pathname: "/login",
            search: "?next=" + rest.location.pathname + "&" + rest.location.search.slice(1)
        }} />);
    }

    /**
    * Redirect user to Main page if he tries to access a non private route
    * (SignIn or SignUp) after being authenticated.
    */
    if (!isPrivate && authenticated) {
        return <Redirect to="/" />;
    }

    /**
    * If not included on both previous cases, redirect user to the desired route.
    */
    return <Route {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    authenticated: PropTypes.bool,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
    authenticated: false,
};
