export const DIALOG_SUBSCRIPTION_LOADING = 'dialog_subscription_loading';
export const DIALOG_SUBSCRIPTION_SUCCESS = 'dialog_subscription_success';
export const DIALOG_SUBSCRIPTION_FAILED = 'dialog_subscription_failed';

export const DIALOG_SUBSCRIPTION_LOCK_LOADING = 'dialog_subscription_lock_loading';
export const DIALOG_SUBSCRIPTION_LOCK_SUCCESS = 'dialog_subscription_lock_success';
export const DIALOG_SUBSCRIPTION_LOCK_FAILED = 'dialog_subscription_lock_failed';

export const DIALOG_SUBSCRIPTION_LOCK_DELETE_LOADING = 'dialog_subscription_lock_delete_loading';
export const DIALOG_SUBSCRIPTION_LOCK_DELETE_SUCCESS = 'dialog_subscription_lock_delete_success';
export const DIALOG_SUBSCRIPTION_LOCK_DELETE_FAILED = 'dialog_subscription_lock_delete_failed';

export const ADMIN_DIALOG_BASKET_SUBSCRIPTION_LOADING = 'admin_dialog_subscription_loading';
export const ADMIN_DIALOG_BASKET_SUBSCRIPTION_SUCCESS = 'admin_dialog_subscription_success';
export const ADMIN_DIALOG_BASKET_SUBSCRIPTION_FAILED = 'admin_dialog_subscription_failed';
