import { loginWithEmailAndPassword } from 'services/AuthenticationService';
import { setAuthToken } from 'services/StorageService';

import {
    LOGIN_PAGE_LOGIN_LOADING,
    LOGIN_PAGE_LOGIN_LOADING_SUCCESS,
    LOGIN_PAGE_LOGIN_LOADING_FAILED,
} from './actionTypes';

export const loginUser = ({ email, password }) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_PAGE_LOGIN_LOADING });

        loginWithEmailAndPassword(email, password)
            .then((result) => {
                dispatch({
                    type: LOGIN_PAGE_LOGIN_LOADING_SUCCESS,
                    payload: result,
                });

                // save token to storage
                setAuthToken(result.auth_token);
            })
            .catch((data) => {
                dispatch({
                    type: LOGIN_PAGE_LOGIN_LOADING_FAILED,
                    payload: data,
                });
            });
    };
};
