import {
    ACCOUNT_ACTIVATE_LOADING,
    ACCOUNT_ACTIVATE_SUCCESS,
    ACCOUNT_ACTIVATE_FAILED,
    EMAIL_ACTIVATE_LOADING,
    EMAIL_ACTIVATE_SUCCESS,
    EMAIL_ACTIVATE_FAILED,
} from './actionTypes';

import {
    activateAccount,
    activateEmailAddress,
} from 'services/AuthenticationService';

export const activateUser = ({ uid, token }) => {
    return (dispatch) => {
        dispatch({ type: ACCOUNT_ACTIVATE_LOADING });

        activateAccount(uid, token)
            .then((result) => {
                dispatch({
                    type: ACCOUNT_ACTIVATE_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ACCOUNT_ACTIVATE_FAILED,
                    payload: error,
                });
            });
    };
};

export const activateEmail = ({ uid, token }) => {
    return (dispatch) => {
        dispatch({ type: EMAIL_ACTIVATE_LOADING });

        activateEmailAddress(uid, token)
            .then((result) => {
                dispatch({
                    type: EMAIL_ACTIVATE_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: EMAIL_ACTIVATE_FAILED,
                    payload: error,
                });
            });
    };
};
