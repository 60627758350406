import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
    Button as MuiButton,
    DialogContentText,
    Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dialog } from '..';
import { EmptyMessage } from '../..';
import { listCourses } from './actions';
import { getIdFromUrl } from '../../../utils';

const useStyles = (theme) => ({
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    contentText: {
        color: theme.colors.mikado,
        marginTop: '15px',
        textAlign: 'center',
    },
    course: {
        borderBottomColor: theme.colors.mercury,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        textAlign: 'left',

        '&:last-child': {
            borderBottomWidth: 0,
            marginBottom: theme.spacing(0),
            paddingBottom: theme.spacing(0),
        },
    },
    action: {
        textAlign: 'right',
    },
});

class DialogCourse extends Component {
    componentDidMount() {
        this.props.listCourses({
            eventId: getIdFromUrl(this.props.course.event),
            admin: this.props.admin,
        });
    }

    handleSelectCourse(course) {
        if (this.props.onSelect) {
            this.props.onSelect(course);
            this.props.onClose();
        }
    }

    renderActions(obj) {
        const { course, admin, t } = this.props;

        if (course.id === obj.id) {
            return <small>{t('common:text.current_course')}</small>;
        }

        if (
            !admin &&
            course.subscriptions_allowed ===
                course.statistics?.subscriptions.all.total
        ) {
            return <span className="label red">{t('forms:label.full')}</span>;
        }

        return (
            <MuiButton
                onClick={() => this.handleSelectCourse(obj)}
                variant={'text'}
            >
                {t('common:link.select')}
            </MuiButton>
        );
    }

    renderContent() {
        const { isLoading, error, courses, classes, t } = this.props;

        if (isLoading) {
            return (
                <EmptyMessage
                    title={t('common:title.loading_courses')}
                    description={t('common:description.loading_courses')}
                    showLoader
                />
            );
        }

        if (error) {
            return (
                <Alert severity={'error'}>
                    {t('common:error.general.title')}
                </Alert>
            );
        }

        return (
            <Grid container alignItems={'center'} justifyContent={'flex-start'}>
                {courses.map((obj, index) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            key={index}
                            className={classes.course}
                        >
                            <Grid item sm={8} xs={12}>
                                <strong>
                                    {obj.position}. {obj.name}
                                </strong>
                                <br />
                                <small>
                                    {obj.height} cm &ndash;{' '}
                                    {obj.type_description}
                                </small>
                            </Grid>
                            <Grid
                                item
                                sm={4}
                                xs={12}
                                className={classes.action}
                            >
                                {this.renderActions(obj)}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    render() {
        const { classes, onClose, open, t } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={t('common:title.select_course')}
                showLine
            >
                <Grid container>
                    <Grid item xs={12}>
                        <DialogContentText
                            classes={{ root: classes.contentText }}
                        >
                            {this.renderContent()}
                        </DialogContentText>
                    </Grid>
                </Grid>
            </Dialog>
        );
    }
}

DialogCourse.defaultProps = {
    classes: {},
    admin: false,
};

DialogCourse.propTypes = {
    // props
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    course: PropTypes.object,
    isLoading: PropTypes.bool,
    courses: PropTypes.object,
    error: PropTypes.string,
    admin: PropTypes.bool,
    // handlers
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    listCourses: PropTypes.func.isRequired,
};

DialogCourse.defaultProps = {
    open: false,
};

const mapStateToProps = (state) => {
    const { isLoading, courses, error } = state.dialogCourse;

    return {
        isLoading,
        courses,
        error,
    };
};

const DialogCourseStyled = withStyles(useStyles)(DialogCourse);
const DialogCourseTranslated = withTranslation()(DialogCourseStyled);
const DialogCourseState = connect(mapStateToProps, {
    listCourses,
})(DialogCourseTranslated);

export { DialogCourseState as DialogCourse };
