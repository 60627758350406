import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withTranslation, Trans } from 'react-i18next';
import EventService from 'lib/EventService';
import { EVENT } from 'enums';

import {
    Badge,
    Backdrop,
    // Button as MuiButton,
    CircularProgress,
    SwipeableDrawer,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    ShoppingCartOutlined as ShoppingCartIcon,
    CloseOutlined as CloseIcon,
    DeleteOutlined as DeleteIcon,
} from '@material-ui/icons';
import {
    Button,
    DialogLogin,
    DialogPaymentMethod,
    DialogDiscount,
    ShoppingCartItem,
    Toast,
    TextDivider,
} from 'components';

import {
    getCurrentBasket,
    getCurrentAdminBasket,
    payOrder,
    payAdminOrder,
    removeDiscount,
} from './actions';

import { renderAmount } from 'utils';

const useStyles = (theme) => ({
    header: {
        flex: 1,
        minWidth: '320px',

        '& div:first-child': {
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
        },

        '& h3': {
            fontSize: '1.5rem',
            fontWeight: '800',
            marginLeft: '8px',
        },

        '& small': {
            marginTop: '8px',
            width: '100%',
        },

        '& button': {
            position: 'absolute',
            top: '30px',
            right: '20px',
        },
    },
    footer: {
        flex: 1,
        marginBottom: '-16px',
    },
    inner: {
        padding: theme.spacing(4),
    },
    innerSmall: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    content: {
        flex: 2,
        overflowY: 'scroll',
        padding: '40px 32px',
        alignSelf: 'flex-start',
        flexBasis: '200%',
        width: '100%',
    },
    cart: {
        backgroundColor: theme.colors.springWood,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        overflow: 'draft',

        '@media (max-height: 600px)': {
            height: 'auto',
        },
    },
    discount_container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
    },
    discount_text: {
        fontSize: '0.875rem',
    },
    discount_amount: {
        fontSize: '0.875rem',
    },
    deleteButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(3) * -1,
    },
    total: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(3),
    },
    total_text: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
    },
    amount: {
        fontSize: '1.125rem',
        fontWeight: 'bold',
    },
    link: {
        marginTop: theme.spacing(2),
    },
    backDrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.colors.white,
        flexDirection: 'column',
    },
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: theme.spacing(4),
    },
    spacer: {
        display: 'block',
        height: theme.spacing(4),
    },
});

class ShoppingCart extends Component {
    constructor(props) {
        super(props);

        // set state
        this.state = {
            shoppingBasketOpen: false,
            openPaymentMethodDialog: false,
            dialogDiscountOpen: false,
            dialogLoginOpen: false,
        };

        this.toggleShoppingBasket = this.toggleShoppingBasket.bind(this);
        this.reloadBasket = this.reloadBasket.bind(this);
        this.handlePayOrder = this.handlePayOrder.bind(this);
        this.handlePayAdminOrder = this.handlePayAdminOrder.bind(this);
        this.handlePaymentDialogClose = this.handlePaymentDialogClose.bind(
            this
        );
        this.handleDialogDiscountOpen = this.handleDialogDiscountOpen.bind(
            this
        );
        this.handleDialogDiscountClose = this.handleDialogDiscountClose.bind(
            this
        );
        this.handleRemoveDiscount = this.handleRemoveDiscount.bind(this);
    }

    componentDidMount() {
        this.reloadBasket();

        EventService.subscribe(
            EVENT.SUBSCRIPTION_DELETED,
            'shoppingcart',
            this.reloadBasket
        );
        EventService.subscribe(
            EVENT.SUBSCRIPTION_CHANGED,
            'shoppingcart',
            this.reloadBasket
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.paymentRedirect !== this.props.paymentRedirect) {
            if (this.props.paymentRedirect) {
                this.handlePaymentDialogClose();
            }
        }

        if (prevProps.isProcessingPayment !== this.props.isProcessingPayment) {
            if (
                !this.props.isProcessingPayment &&
                this.state.openPaymentMethodDialog
            ) {
                this.setState({
                    openPaymentMethodDialog: false,
                    shoppingBasketOpen: false,
                });
                this.reloadBasket();
            }
        }

        if (prevProps.isDiscountRemoved !== this.props.isDiscountRemoved) {
            if (this.props.isDiscountRemoved) {
                this.reloadBasket();
            }
        }
    }

    componentWillUnmount() {
        EventService.subscribe(EVENT.SUBSCRIPTION_DELETED, 'shoppingcart');
        EventService.subscribe(EVENT.SUBSCRIPTION_CHANGED, 'shoppingcart');
    }

    toggleShoppingBasket(event) {
        if (!event) {
            return;
        }

        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        this.setState({
            shoppingBasketOpen: !this.state.shoppingBasketOpen,
        });
    }

    handleRemoveDiscount() {
        this.props.removeDiscount({ id: this.props.basket.id });
    }

    handleDialogDiscountOpen() {
        this.setState({
            dialogDiscountOpen: true,
        });
    }

    handleDialogDiscountClose(discount) {
        this.setState({
            dialogDiscountOpen: false,
        });

        if (discount !== undefined) {
            this.reloadBasket();
        }
    }

    handlePaymentDialogClose() {
        this.setState({
            openPaymentMethodDialog: false,
        });
    }

    handlePayOrder() {
        const { isLoggedIn, basket, admin } = this.props;

        if (!isLoggedIn) {
            // do someting TODO
            this.setState({
                dialogLoginOpen: true,
            });
            return;
        }

        if (admin) {
            // open payment method dialog
            this.setState({
                openPaymentMethodDialog: true,
            });
        } else {
            // pay the order
            this.props.payOrder({
                id: basket.id,
                amount: basket.total_amount,
                email: this.props.newRegisteredUser?.email,
            });
        }
    }

    handlePayAdminOrder(data) {
        const { isLoggedIn, basket } = this.props;

        if (!isLoggedIn) {
            // do someting TODO
            alert('You are not logged in.'); // eslint-disable-line
            return;
        }

        this.props.payAdminOrder({
            id: basket.id,
            amount: basket.total_amount,
            ...data,
        });
    }

    reloadBasket() {
        if (this.props.admin) {
            this.props.getCurrentAdminBasket();
        } else {
            this.props.getCurrentBasket();
        }
    }

    renderDiscountContent() {
        const { admin, classes, t, basket, isRemovingDiscount } = this.props;

        if (!admin) {
            return;
        }

        // eslint-disable-next-line radix
        if (basket.discount_amount && parseInt(basket.discount_amount) !== 0) {
            return (
                <div className={classes.discount_container}>
                    <div className={classes.discount_text}>
                        {t('common:text.discount')}
                    </div>
                    <div className={classes.discount_amount}>
                        &euro;&nbsp;{basket.discount_amount}
                        <IconButton
                            aria-label="delete"
                            className={classes.deleteButton}
                            onClick={this.handleRemoveDiscount}
                        >
                            {!isRemovingDiscount && <DeleteIcon />}
                            {isRemovingDiscount && (
                                <CircularProgress size={28} />
                            )}
                        </IconButton>
                    </div>
                </div>
            );
        }

        return (
            <Button
                variant={'link'}
                fullWidth
                onClick={this.handleDialogDiscountOpen}
            >
                {t('common:button.add_discount')}
            </Button>
        );
    }

    renderDiscount() {
        const { admin, classes } = this.props;

        if (!admin) {
            return;
        }

        return (
            <div className={classes.discount}>
                <div className="dashed-line variant-darker" />

                <div className={classes.innerSmall}>
                    {this.renderDiscountContent()}
                </div>
            </div>
        );
    }

    renderFooter() {
        const {
            classes,
            t,
            isLoading,
            basket,
            isProcessingPayment,
        } = this.props;

        if (isLoading) {
            return (
                <footer className={classes.footer}>
                    <div className="dashed-line variant-darker" />
                    <div className={classes.inner}>
                        <div className={classes.total}>
                            <div className={classes.total_text}>
                                {t('common:text.total')}
                            </div>
                            <div className={classes.amount}>
                                <Skeleton
                                    variant="rect"
                                    height={25}
                                    width={50}
                                />
                            </div>
                        </div>
                        <Skeleton variant="rect" height={52} width={'100%'} />
                        <Skeleton
                            variant="rect"
                            height={28}
                            width={'100%'}
                            className={classes.link}
                        />
                    </div>
                </footer>
            );
        }

        if (!basket) {
            return;
        }

        return (
            <footer className={classes.footer}>
                {this.renderDiscount()}
                <div className="dashed-line variant-darker" />
                <div className={classes.inner}>
                    <div className={classes.total}>
                        <div className={classes.total_text}>
                            {t('common:text.total')}
                        </div>
                        <div className={classes.amount}>
                            {renderAmount(basket.total_amount || '0.00')}
                        </div>
                    </div>
                    <Button
                        fullWidth
                        type={'submit'}
                        color={'secondary'}
                        onClick={this.handlePayOrder}
                        isLoading={isProcessingPayment}
                        disabled={
                            !basket ||
                            (this.props.amountSubscriptions === 0 &&
                                this.props.amountRefundSubscriptions === 0)
                        }
                    >
                        {t('common:button.pay_order')}
                    </Button>
                </div>
            </footer>
        );
    }

    renderContent() {
        const {
            classes,
            isLoading,
            basket,
            subscriptions,
            t,
            admin,
            amountSubscriptions,
            amountRefundSubscriptions,
            refundSubscriptions,
        } = this.props;

        if (isLoading) {
            return (
                <div className={classes.content}>
                    <ShoppingCartItem isLoading />
                </div>
            );
        }

        if (
            !basket ||
            (amountSubscriptions === 0 && amountRefundSubscriptions === 0)
        ) {
            return (
                <div className={classes.content}>
                    <em>{t('common:text.empty_cart')}</em>
                </div>
            );
        }

        return (
            <div className={classes.content}>
                {amountSubscriptions ? (
                    <TextDivider text={t('common:title.subscriptions')} />
                ) : null}
                {Object.entries(subscriptions).map(([key, value]) => (
                    <ShoppingCartItem
                        key={key}
                        course={value.course_object}
                        event={value.event_object}
                        subscriptions={value.subscriptions}
                        amount={value.total_amount}
                        admin={admin}
                    />
                ))}

                {amountSubscriptions ? (
                    <div className={classes.spacer} />
                ) : null}

                {amountRefundSubscriptions ? (
                    <TextDivider
                        text={t('common:title.refunded_subscriptions')}
                    />
                ) : null}
                {Object.entries(refundSubscriptions).map(([key, value]) => (
                    <ShoppingCartItem
                        key={key}
                        course={value.course_object}
                        event={value.event_object}
                        subscriptions={value.subscriptions}
                        amount={0 - value.total_amount}
                        admin={admin}
                    />
                ))}
            </div>
        );
    }

    renderLoader() {
        const { paymentRedirect, classes, t } = this.props;

        if (!paymentRedirect) {
            return;
        }

        window.location = paymentRedirect.payment_url; // eslint-disable-line no-undef

        return (
            <Backdrop
                className={classes.backDrop}
                open={Boolean(paymentRedirect)}
            >
                <CircularProgress color="inherit" />
                <div className={classes.loader}>
                    {t('common:text.to_payment')}
                </div>
            </Backdrop>
        );
    }

    renderDialogLogin() {
        return <DialogLogin open={this.state.dialogLoginOpen} />;
    }

    renderDialogDiscount() {
        return (
            <DialogDiscount
                open={this.state.dialogDiscountOpen}
                order={this.props.basket}
                onClose={this.handleDialogDiscountClose}
            />
        );
    }

    render() {
        const {
            totalAmountSubscriptions,
            error,
            isLoading,
            t,
            classes,
            paymentError,
        } = this.props;

        const count = totalAmountSubscriptions;

        return (
            <React.Fragment>
                <IconButton
                    fontSize="small"
                    onClick={this.toggleShoppingBasket}
                    aria-describedby="shopping-cart"
                >
                    <Badge
                        badgeContent={totalAmountSubscriptions}
                        color={'error'}
                    >
                        <ShoppingCartIcon />
                    </Badge>
                </IconButton>

                <DialogPaymentMethod
                    open={this.state.openPaymentMethodDialog}
                    action={this.handlePayAdminOrder}
                    onClose={this.handlePaymentDialogClose}
                    errors={paymentError}
                    isLoading={this.props.isProcessingPayment}
                />

                <SwipeableDrawer
                    id="shopping-cart"
                    anchor={'right'}
                    open={this.state.shoppingBasketOpen}
                    onClose={this.toggleShoppingBasket}
                >
                    {this.renderLoader()}

                    <Toast
                        message={
                            (paymentError && paymentError.non_field_errors) ||
                            t('common:error.general.description')
                        }
                        open={Boolean(paymentError)}
                    />
                    <Toast
                        message={t('common:error.general.description')}
                        open={Boolean(error)}
                    />

                    <div className={classes.cart}>
                        <header className={classes.header}>
                            <div className={classes.inner}>
                                <ShoppingCartIcon />
                                <Typography variant="h3">
                                    {t('common:title.shopping_cart')}
                                </Typography>
                                <small>
                                    {isLoading ? (
                                        <Skeleton
                                            variant="rect"
                                            height={19}
                                            width={'60%'}
                                        />
                                    ) : (
                                        <Trans
                                            i18nKey="text.amount_subscription"
                                            count={count}
                                            ns="common"
                                        >
                                            {{ count }} subscriptions
                                        </Trans>
                                    )}
                                </small>
                                <div className={classes.close}>
                                    <IconButton
                                        edge="start"
                                        aria-label="close"
                                        onClick={this.toggleShoppingBasket}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className="dashed-line variant-darker" />
                        </header>

                        {this.renderContent()}
                        {this.renderFooter()}
                        {this.renderDialogLogin()}
                        {this.renderDialogDiscount()}
                    </div>
                </SwipeableDrawer>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoading,
        error,
        basket,
        amountSubscriptions,
        amountRefundSubscriptions,
        basketOutdated,
        subscriptions,
        refundSubscriptions,
        isProcessingPayment,
        paymentError,
        paymentRedirect,
        newRegisteredUser,
        isRemovingDiscount,
        isDiscountRemoved,
        removeDiscountError,
    } = state.shoppingCart;
    const { isLoggedIn } = state.authentication;

    return {
        isLoading,
        error,
        basket,
        totalAmountSubscriptions:
            amountSubscriptions + amountRefundSubscriptions,
        amountSubscriptions,
        amountRefundSubscriptions,
        basketOutdated,
        subscriptions,
        refundSubscriptions,
        isLoggedIn,
        isProcessingPayment,
        paymentError,
        paymentRedirect,
        newRegisteredUser,
        isRemovingDiscount,
        isDiscountRemoved,
        removeDiscountError,
    };
};

ShoppingCart.propTypes = {
    // props
    open: PropTypes.bool,
    admin: PropTypes.bool,
    classes: PropTypes.object,
    isLoading: PropTypes.bool,
    isProcessingPayment: PropTypes.bool,
    paymentRedirect: PropTypes.string,
    paymentError: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    basket: PropTypes.object,
    refundSubscriptions: PropTypes.object,
    totalAmountSubscriptions: PropTypes.number,
    error: PropTypes.string,
    amountSubscriptions: PropTypes.number,
    amountRefundSubscriptions: PropTypes.number,
    subscriptions: PropTypes.object,
    newRegisteredUser: PropTypes.object,
    isDiscountRemoved: PropTypes.bool,
    isRemovingDiscount: PropTypes.bool,
    removeDiscountError: PropTypes.object,
    // handlers
    onClose: PropTypes.func,
    t: PropTypes.func.isRequired,
    payOrder: PropTypes.func.isRequired,
    payAdminOrder: PropTypes.func.isRequired,
    getCurrentBasket: PropTypes.func.isRequired,
    getCurrentAdminBasket: PropTypes.func.isRequired,
    removeDiscount: PropTypes.func.isRequired,
};

ShoppingCart.defaultProps = {
    open: false,
    admin: false,
};

const ShoppingCartState = connect(mapStateToProps, {
    getCurrentBasket,
    getCurrentAdminBasket,
    payOrder,
    payAdminOrder,
    removeDiscount,
})(ShoppingCart);
const ShoppingCartTranslated = withTranslation()(ShoppingCartState);
const ShoppingCartStyled = withStyles(useStyles)(ShoppingCartTranslated);

export { ShoppingCartStyled as ShoppingCart };
