import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Collapse, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { DialogSubscriptionDelete, DialogSubscription } from '../Dialogs';

import { renderAmount } from 'utils';

const useStyles = (theme) => ({
    item: {
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: '#e5e5e5',
        paddingBottom: '8px',
        paddingTop: '16px',
        width: '100%',

        '&:first-child': {
            paddingTop: 0,
        },

        '&:last-child': {
            paddingBottom: 0,
            borderBottomWidth: 0,
        },
    },
    itemRow: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
    },
    itemCombination: {
        backgroundColor: theme.colors.mercury,
        borderRadius: '10px',
        flexDirection: 'column',
        padding: '16px',
        alignItems: 'center',
        marginTop: '8px',
    },
    combination: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '8px',
        width: '100%',

        '& span': {
            marginBottom: '4px',
        },
    },
    rider: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
    },
    actions: {
        marginRight: '-16px',

        '& ul': {
            display: 'flex',
            listStyle: 'none',
            margin: 0,
            padding: 0,

            '& li': {
                margin: 0,
                padding: 0,
            },
        },
    },
    itemAmount: {
        fontWeight: 'bold',
        padding: '4px 16px 8px 0',
    },
    itemSubtotal: {
        fontWeight: 'bold',
        padding: '4px 0 8px 16px',
        textAlign: 'right',
    },
    itemContent: {
        padding: '4px 0 8px',
        flexBasis: '200%',

        '& strong': {
            display: 'block',
            fontWeight: 'bold',
        },

        '& small': {
            display: 'block',
            marginTop: '4px',
        },
    },
    itemLink: {
        fontSize: '0.875rem',
        marginTop: '24px',
    },
});

class ShoppingCartItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            detailsShown: false,
            dialogSubscriptionDelete: undefined,
            dialogSubscriptionOpen: undefined,
        };

        // event handlers
        this.handleDetails = this.handleDetails.bind(this);
        this.handleSubscriptionDeleteClose = this.handleSubscriptionDeleteClose.bind(this);
        this.handleSubscriptionDeleteOpen = this.handleSubscriptionDeleteOpen.bind(this);
        this.dialogSubscriptionChangeDelete = this.dialogSubscriptionChangeDelete.bind(this);
        this.handleSubscriptionOpen = this.handleSubscriptionOpen.bind(this);
    }

    handleDetails(event) {
        event.preventDefault();

        this.setState({
            detailsShown: !this.state.detailsShown,
        });
    }

    handleSubscriptionDeleteClose(isDeleted) {
        this.setState({
            dialogSubscriptionDelete: undefined,
        });
    }

    dialogSubscriptionChangeDelete(newSubscription) {
        this.setState({
            dialogSubscriptionOpen: undefined,
        });
    }

    handleSubscriptionDeleteOpen(obj) {
        this.setState({
            dialogSubscriptionDelete: obj,
        });
    }

    handleSubscriptionOpen(obj) {
        this.setState({
            dialogSubscriptionOpen: obj,
        });
    }

    renderDialogSubscription() {
        return (
            <DialogSubscription
                open={this.state.dialogSubscriptionOpen !== undefined}
                onClose={this.dialogSubscriptionChangeDelete}
                course={this.props.course}
                startNumber={this.state.dialogSubscriptionOpen?.start_number}
                subscription={this.state.dialogSubscriptionOpen}
                admin={this.props.admin}
            />
        );
    }

    renderDialogDelete() {
        return (
            <DialogSubscriptionDelete
                open={this.state.dialogSubscriptionDelete !== undefined}
                onClose={this.handleSubscriptionDeleteClose}
                subscription={this.state.dialogSubscriptionDelete}
                admin={this.props.admin}
            />
        );
    }

    renderCollapse() {
        const { subscriptions, classes, t, hideActions } = this.props;

        return (
            <Collapse in={this.state.detailsShown}>
                {subscriptions.map((obj, i) => (
                    <div
                        className={classNames(
                            classes.itemRow,
                            classes.itemCombination
                        )}
                        key={i}
                    >
                        <div className={classes.row}>
                            <span>
                                {obj.start_number}
                                {obj.out_of_competition &&
                                    t('common:text.out_of_competition_short')}
                            </span>
                            <span>&euro;&nbsp;{obj.total_amount}</span>
                        </div>
                        <div className={classes.combination}>
                            {obj.combinations && obj.combinations.length > 0 && (
                                <div className={classes.rider}>
                                    <strong>
                                        {obj.combinations[0].first_name}{' '}
                                        {obj.combinations[0].last_name}
                                    </strong>
                                    <small>
                                        {obj.combinations[0].horse_name}
                                    </small>
                                </div>
                            )}
                            {!hideActions && (
                                <div className={classes.actions}>
                                    <ul>
                                        <li>
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    this.handleSubscriptionOpen(obj)
                                                }
                                            >
                                                <EditIcon fontSize="small" />
                                            </IconButton>
                                        </li>
                                        <li>
                                            <IconButton
                                                color="primary"
                                                onClick={() =>
                                                    this.handleSubscriptionDeleteOpen(obj)
                                                }
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                {this.renderDialogSubscription()}
                {this.renderDialogDelete()}
            </Collapse>
        );
    }

    render() {
        const { subscriptions, amount, classes, t, isLoading } = this.props;

        if (isLoading) {
            return (
                <div className={classes.item}>
                    <div className={classes.itemRow}>
                        <div className={classes.itemAmount}>
                            <Skeleton variant="rect" height={19} width={40} />
                        </div>
                        <div className={classes.itemContent}>
                            <strong>
                                <Skeleton
                                    variant="rect"
                                    height={22}
                                    width={'80%'}
                                />
                            </strong>
                            <small>
                                <Skeleton
                                    variant="rect"
                                    height={19}
                                    width={'70%'}
                                />
                            </small>
                        </div>
                        <div className={classes.itemSubtotal}>
                            <Skeleton variant="rect" height={19} width={40} />
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.item}>
                <div className={classes.itemRow}>
                    <div className={classes.itemAmount}>
                        {subscriptions.length}&nbsp;x
                    </div>
                    <div className={classes.itemContent}>
                        <strong>
                            {this.props.course?.name}
                        </strong>
                        <small>
                            {this.props.event ? (
                                t('common:title.course') +
                                ' ' +
                                this.props.course?.position +
                                ' - ' +
                                this.props.course?.height +
                                'cm - ' +
                                this.props.event.name
                            ) : (
                                <Skeleton
                                    variant="rect"
                                    height={19}
                                    width={'70%'}
                                />
                            )}
                        </small>

                        <div className={classes.itemLink}>
                            <Link onClick={this.handleDetails}>
                                {this.state.detailsShown
                                    ? t('common:text.hide_details')
                                    : t('common:text.view_details')}
                            </Link>
                        </div>
                    </div>
                    {amount ? (
                        <div className={classes.itemSubtotal}>
                            {renderAmount(amount)}
                        </div>
                    ) : (
                        <div className={classes.itemSubtotal} />
                    )}
                </div>
                {this.renderCollapse()}
            </div>
        );
    }
}

ShoppingCartItem.propTypes = {
    course: PropTypes.string.isRequiredIf((props) => !props.isLoading),
    event: PropTypes.string.isRequiredIf((props) => !props.isLoading),
    subscriptions: PropTypes.array.isRequiredIf((props) => !props.isLoading),
    amount: PropTypes.number,
    hideActions: PropTypes.bool,
    isLoading: PropTypes.bool,
    classes: PropTypes.object,
    admin: PropTypes.bool,
    handleDeleteSubscription: PropTypes.func.isRequiredIf((props) => !props.isLoading),
    dialogSubscriptionChangeDelete: PropTypes.func.isRequiredIf((props) => !props.isLoading),
    isDeleting: PropTypes.bool,
    handleEditSubscription: PropTypes.func.isRequiredIf((props) => !props.isLoading),
    isEditing: PropTypes.bool,
    t: PropTypes.func.isRequired,
};

const ShoppingCartItemStyled = withStyles(useStyles)(ShoppingCartItem);
const ShoppingCartItemTranslated = withTranslation()(ShoppingCartItemStyled);

export { ShoppingCartItemTranslated as ShoppingCartItem };
