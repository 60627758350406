import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';

import {
    Dialog as MuiDialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    IconButton,
    Slide,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = (theme) => ({
    dialog: {
        minWidth: '440px',
        [theme.breakpoints.down('md')]: {
            minWidth: '320px',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '300px',
        },
    },
    dialogDelete: {
        borderTopWidth: '5px',
        borderTopStyle: 'solid',
        borderTopColor: theme.colors.permanentGeraniumLake,
    },
    content: {
        padding: '24px 32px 8px',
        overflow: 'hidden',

        [theme.breakpoints.down('md')]: {
            padding: '24px 24px 8px',
        },

        '& .payment-icons': {
            marginBottom: 0,
        },
    },
    contentNoFooter: {
        padding: '24px 32px 32px',

        [theme.breakpoints.down('md')]: {
            padding: '24px 24px 24px',
        },
    },
    smallActions: {
        paddingTop: 0,
    },
    actions: {
        flexDirection: 'row',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    title: {
        paddingBottom: theme.spacing(0),

        '& small': {
            display: 'block',
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(1),
            fontWeight: 'normal',
            fontSize: '0.875rem',
            fontFamily: 'Lato',
        },
    },
    // footer: {
    //     marginBottom: theme.spacing(4),
    // },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide direction={props.slidedirection && 'up'} ref={ref} {...props} />
    );
});

Transition.propTypes = {
    slidedirection: PropTypes.string,
};

class Dialog extends Component {
    handleClick(event) {
        event.stopPropagation();
        // event.preventDefault();
    }

    render() {
        const {
            classes,
            onClose,
            open,
            title,
            slidedirection,
            isDeleteDialog,
            smallFooter,
            noFooter,
            subtitle,
            showLine,
        } = this.props;

        let classDelete = null;
        if (isDeleteDialog) {
            classDelete = classes.dialogDelete;
        }

        let classActions = null;
        if (smallFooter) {
            classActions = classes.smallActions;
        }

        return (
            <MuiDialog
                open={open}
                onClose={onClose}
                onClick={this.handleClick}
                scroll={'body'}
                TransitionComponent={Transition}
                TransitionProps={{
                    slidedirection: slidedirection,
                }}
                classes={{
                    paper: classNames(classes.dialog, classDelete),
                }}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle
                    id="dialog-title"
                    classes={{
                        root: classes.title,
                    }}
                >
                    {title}

                    <IconButton
                        aria-label="close"
                        className={'closeButton'}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>

                    {subtitle && <small>{subtitle}</small>}

                    {showLine && <div className="dashed-line" />}
                </DialogTitle>
                <DialogContent
                    id="dialog-description"
                    classes={{
                        root: classNames(
                            classes.content,
                            noFooter && classes.contentNoFooter
                        ),
                    }}
                >
                    {this.props.children}
                </DialogContent>
                <div className={classes.footer}>
                    {this.props.actions && (
                        <DialogActions
                            className={classNames(
                                classes.actions,
                                classActions
                            )}
                        >
                            {this.props.actions}
                        </DialogActions>
                    )}
                </div>
            </MuiDialog>
        );
    }
}

Dialog.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    showLine: PropTypes.bool,
    actions: PropTypes.object,
    children: PropTypes.object.isRequired,
    slidedirection: PropTypes.oneOf(['up', 'down']),
    isDeleteDialog: PropTypes.bool,
    smallFooter: PropTypes.bool,
    noFooter: PropTypes.bool,
};

Dialog.defaultProps = {
    open: false,
    title: null,
    slidedirection: 'up',
    isDeleteDialog: false,
};

const DialogStyled = withStyles(useStyles)(Dialog);

export { DialogStyled as Dialog };
