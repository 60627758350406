import {
    // listSubscriptions,
    currentBasket,
    currentAdminBasket,
    payOrder as apiPayOrder,
    payAdminOrder as apiPayAdminOrder,
} from 'services/BasketService';

import { removeDiscountFromOrder } from 'services/OrderService';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import {
    SHOPPING_CART_LOADING,
    SHOPPING_CART_LOADING_SUCCESS,
    SHOPPING_CART_LOADING_FAILED,
    ADMIN_SHOPPING_CART_LOADING,
    ADMIN_SHOPPING_CART_LOADING_SUCCESS,
    ADMIN_SHOPPING_CART_LOADING_FAILED,
    SHOPPING_CART_PAY_LOADING,
    SHOPPING_CART_PAY_SUCCESS,
    SHOPPING_CART_PAY_FAILED,
    ADMIN_SHOPPING_CART_PAY_LOADING,
    ADMIN_SHOPPING_CART_PAY_SUCCESS,
    ADMIN_SHOPPING_CART_PAY_FAILED,
    ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_LOADING,
    ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_SUCCESS,
    ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_FAILED,
} from './actionTypes';

export const removeDiscount = ({ id }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_LOADING });

        removeDiscountFromOrder(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_SHOPPING_CART_REMOVE_DISCOUNT_FAILED,
                    payload: error,
                });
            });
    };
};

export const getCurrentBasket = () => {
    return (dispatch) => {
        dispatch({ type: SHOPPING_CART_LOADING });

        currentBasket()
            .then((result) => {
                dispatch({
                    type: SHOPPING_CART_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SHOPPING_CART_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const getCurrentAdminBasket = () => {
    return (dispatch) => {
        dispatch({ type: ADMIN_SHOPPING_CART_LOADING });

        currentAdminBasket()
            .then((result) => {
                dispatch({
                    type: ADMIN_SHOPPING_CART_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_SHOPPING_CART_LOADING_FAILED,
                    payload: error,
                });
            });
    };
};

export const payOrder = ({ id, amount, email }) => {
    return (dispatch) => {
        dispatch({ type: SHOPPING_CART_PAY_LOADING });

        apiPayOrder(id, amount, email)
            .then((result) => {
                dispatch({
                    type: SHOPPING_CART_PAY_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: SHOPPING_CART_PAY_FAILED,
                    payload: error,
                });

                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });
            });
    };
};

export const payAdminOrder = ({ id, method, amount }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_SHOPPING_CART_PAY_LOADING });

        apiPayAdminOrder(id, method, amount)
            .then((result) => {
                dispatch({
                    type: ADMIN_SHOPPING_CART_PAY_SUCCESS,
                    payload: result,
                });

                AlertService.showSuccess({
                    description: TranslationUtil.getTranslations().t(
                        'common:success.general.description'
                    ),
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_SHOPPING_CART_PAY_FAILED,
                    payload: error,
                });

                AlertService.showError({
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });
            });
    };
};
