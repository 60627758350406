import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { Helmet } from 'react-helmet';

import { TemplateAdmin } from 'components';
import { AdminContext } from '../../utils';
import { getUrl } from 'urls';

class AdminDashboardPage extends Component {
    static contextType = AdminContext;

    componentDidMount() {
        const { t } = this.props;

        this.context.setAdminTitle(t('common:title.admin.dashboard'));
        this.context.setAdminBackAction(null);
    }

    render() {
        const { t } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <Helmet>
                    <title>
                        {t('common:title.admin.dashboard')} |{' '}
                        {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)}
                    />
                </Helmet>
                <TemplateAdmin header>
                    <h1>{t('common:title.admin.dashboard')}</h1>
                </TemplateAdmin>

                <TemplateAdmin></TemplateAdmin>
            </Suspense>
        );
    }
}

const AdminDashboardPageTranslated = withTranslation()(AdminDashboardPage);
export { AdminDashboardPageTranslated as AdminDashboardPage };
