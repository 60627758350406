import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { urls, getUrl } from 'urls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { EVENT } from 'enums';
import EventService from 'lib/EventService';

import {
    Container,
    CircularProgress,
    Grid,
    Typography,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { hexToRGB, buildStartList } from '../../utils';
import { TemplateMarketing } from '../TemplateMarketing';
import {
    CardSubscription,
    CardEventHeader,
    Toast,
    EmptyMessage,
} from 'components';
import { getCourse, resetDeleted, resetCreated } from './actions';

const useStyles = (theme) => ({
    media: {
        backgroundColor: theme.colors.springWood,
        position: 'relative',
        padding: '144px 56px 40px',
    },
    header: {
        borderRadius: '20px',
        color: theme.colors.white,
        marginLeft: '-56px',
        marginRight: '-56px',
    },
    content: {
        padding: '0',
    },
    relative: {
        position: 'relative',
    },
    title: {
        color: theme.colors.white,
        fontSize: '2.5rem',
        lineHeight: 1.25,
        margin: '8px 0',
    },
    stat: {
        color: hexToRGB(theme.colors.white, 0.7),
        marginTop: '5px',

        '& > svg': {
            marginRight: '8px',
            verticalAlign: 'middle',
        },
        '& span.label': {
            marginLeft: '15px',
        },
    },
    meta: {
        borderRadius: '20px',
    },
    metaContent: {
        padding: '24px',

        '& > p:first-child': {
            marginTop: 0,
        },
    },
    label: {
        marginTop: '24px',
    },
});

class CourseDetailPage extends Component {
    constructor(props) {
        super(props);

        const eventId = this.props.match.params.eventId;
        const courseId = this.props.match.params.courseId;

        // save to state
        this.state = {
            eventId: eventId,
            courseId: courseId,
        };

        // event handlers
        this.reloadCourse = this.reloadCourse.bind(this);
    }

    componentDidMount() {
        EventService.subscribe(
            EVENT.SUBSCRIPTION_DELETED,
            'coursedetail',
            this.reloadCourse
        );
        EventService.subscribe(
            EVENT.SUBSCRIPTION_CHANGED,
            'coursedetail',
            this.reloadCourse
        );

        this.reloadCourse();
    }

    componentWillUnmount() {
        EventService.unsubscribe(EVENT.SUBSCRIPTION_DELETED, 'coursedetail');
        EventService.unsubscribe(EVENT.SUBSCRIPTION_CHANGED, 'coursedetail');
    }

    reloadCourse() {
        // load course
        this.props.getCourse(this.state.courseId);
    }

    renderDescription() {
        const { t, isLoading, course } = this.props;

        return (
            <React.Fragment>
                {isLoading || course.description ? (
                    <Typography variant="h2" className="title-h2">
                        {t('common:title.description')}
                    </Typography>
                ) : (
                    ''
                )}

                {isLoading ? (
                    <React.Fragment>
                        <Skeleton variant="rect" width={'100%'} height={21} />
                        <Skeleton
                            variant="rect"
                            width={'100%'}
                            height={21}
                            style={{ marginTop: '5px' }}
                        />
                        <Skeleton
                            variant="rect"
                            width={'80%'}
                            height={21}
                            style={{ marginTop: '5px' }}
                        />
                    </React.Fragment>
                ) : (
                    <Typography
                        variant="body1"
                        style={{ 'white-space': 'pre-line' }}
                    >
                        {course.description}
                    </Typography>
                )}
            </React.Fragment>
        );
    }

    renderSubscriptions() {
        const { course, isLoading } = this.props;

        if (isLoading) {
            return;
        }

        // build subscriptions
        const subscriptions = buildStartList({
            subscriptions: course.subscriptions,
            max: course.subscriptions_allowed,
        });

        return (
            <React.Fragment>
                {Object.keys(subscriptions).map((key, index) => {
                    const obj = subscriptions[key];

                    if (!obj) {
                        return (
                            <CardSubscription
                                key={index}
                                course={course}
                                startNumber={key}
                                editable={!course.subscriptions_closed}
                                empty
                            />
                        );
                    } else if (obj.editable) {
                        return (
                            <CardSubscription
                                key={index}
                                course={course}
                                startNumber={obj.start_number}
                                subscription={obj}
                                editable={
                                    !course.subscriptions_closed && obj.editable
                                }
                            />
                        );
                    } else if (obj.reserved) {
                        return (
                            <CardSubscription
                                key={index}
                                course={course}
                                startNumber={obj.start_number}
                                reserved
                                editable={
                                    !course.subscriptions_closed && obj.editable
                                }
                            />
                        );
                    } else {
                        return (
                            <CardSubscription
                                key={index}
                                course={course}
                                startNumber={key}
                                subscription={obj}
                                empty={!obj}
                                editable={
                                    !course.subscriptions_closed && obj.editable
                                }
                            />
                        );
                    }
                })}
            </React.Fragment>
        );
    }

    render() {
        const {
            t,
            isLoading,
            error,
            course,
            isCreated,
            isDeleted,
        } = this.props;

        if (error) {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>{t('common:error.general.title')} Equento</title>
                        <link
                            rel="canonical"
                            href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                        />
                    </Helmet>
                    <Toast
                        message={t('common:error.general.description')}
                        open
                    />
                    <EmptyMessage
                        title={t('common:error.general.title')}
                        description={t('common:error.general.description_long')}
                        action={this.reloadCourse}
                    />
                </React.Fragment>
            );
        }

        return (
            <Container>
                <Suspense fallback={<CircularProgress />}>
                    <Helmet>
                        <title>
                            {course
                                ? course.name
                                : t('common:title.course_detail')}{' '}
                            | Equento
                        </title>
                        <link
                            rel="canonical"
                            href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                        />
                    </Helmet>
                    <Toast
                        message={t(
                            'common:success.general.created_subscription'
                        )}
                        open={isCreated}
                        type="success"
                        onClose={this.props.resetCreated}
                    />
                    <Toast
                        message={t(
                            'common:success.general.deleted_subscription'
                        )}
                        open={isDeleted}
                        type="success"
                        onClose={this.props.resetDeleted}
                    />
                    <TemplateMarketing>
                        <CardEventHeader
                            name={
                                course
                                    ? course.position +
                                      '. ' +
                                      course.name +
                                      ' - ' +
                                      course.height +
                                      'cm'
                                    : ''
                            }
                            eventId={this.state.eventId}
                            showBackButton
                            backAction={
                                () =>
                                    /* eslint-disable no-undef */
                                    (document.location = getUrl(
                                        urls.EVENT_DETAIL,
                                        {
                                            eventId: this.state.eventId,
                                        }
                                    ))
                                /* eslint-enable no-undef */
                            }
                            info={
                                course
                                    ? course.height +
                                      'cm - ' +
                                      course.type_description
                                    : ''
                            }
                            time={
                                course && course.time
                                    ? course.time +
                                      t('common:text.time_unit_hours_short')
                                    : ''
                            }
                            isLoading={isLoading}
                            closed={
                                course ? course.subscriptions_closed : false
                            }
                        />

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {this.renderDescription()}

                                <Typography variant="h2" className="title-h2">
                                    {t('common:title.startlist')}
                                </Typography>

                                {isLoading
                                    ? [...new Array(10)].map((key, index) => (
                                          <CardSubscription
                                              key={index}
                                              isLoading={isLoading}
                                          />
                                      ))
                                    : this.renderSubscriptions()}
                            </Grid>
                        </Grid>
                    </TemplateMarketing>
                </Suspense>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoading,
        error,
        course,
        isCreated,
        isDeleted,
    } = state.pagePublicCourseDetail;

    return {
        isLoading,
        error,
        course,
        isCreated,
        isDeleted,
    };
};

CourseDetailPage.propTypes = {
    // props
    isLoading: PropTypes.bool,
    course: PropTypes.object,
    error: PropTypes.string,
    isCreated: PropTypes.bool,
    isDeleted: PropTypes.bool,
    // event handlers
    match: PropTypes.object.isRequired,
    getCourse: PropTypes.func.isRequired,
    resetCreated: PropTypes.func.isRequired,
    resetDeleted: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const CourseDetailPageState = connect(mapStateToProps, {
    getCourse,
    resetCreated,
    resetDeleted,
})(CourseDetailPage);

const CourseDetailPageTranslated = withTranslation()(CourseDetailPageState);
const CourseDetailPageStyled = withStyles(useStyles)(
    CourseDetailPageTranslated
);
export { CourseDetailPageStyled as CourseDetailPage };
