import fetchApi from './api';

const endPoints = {
    activate: '/v1/auth/users/activation/',
    activateEmail: '/v1/users/confirm_email/',
    login: '/v1/auth/token/login/',
    logout: '/v1/auth/token/logout/',
    me: '/v1/auth/me/',
    register: '/v1/auth/users/',
    resetPassword: '/v1/auth/users/reset_password/',
    resetPasswordConfirm: '/v1/auth/users/reset_password_confirm/',
};

export const loginWithEmailAndPassword = (email, password) => fetchApi({
    url: endPoints.login,
    data: { email, password },
    method: 'POST',
    useAccessToken: false
});

export const logoutUser = () => fetchApi({
    url: endPoints.logout,
    method: 'POST'
});

export const loadUser = () => fetchApi({
    url: endPoints.me,
    method: 'GET'
});

export const registerUser = ({
    first_name, last_name, email, password, agreed_to_terms,
    date_of_birth, gender, vat_number, company_name, address,
    country,
}) => fetchApi({
    url: endPoints.register,
    data: {
        first_name: first_name,
        last_name: last_name,
        email: email,
        password: password,
        agreed_to_terms: agreed_to_terms,
        date_of_birth: date_of_birth,
        gender: gender,
        vat_number: vat_number,
        company_name: company_name,
        address: address,
        country: country,
    },
    method: 'POST',
    useAccessToken: false,
});

export const activateAccount = (uid, token) => fetchApi({
    url: endPoints.activate,
    data: {
        uid: uid,
        token: token,
    },
    method: 'POST',
    useAccessToken: false,
});

export const activateEmailAddress = (uid, token) => fetchApi({
    url: endPoints.activateEmail,
    data: {
        uid: uid,
        token: token,
    },
    method: 'POST',
    useAccessToken: true,
});

export const resetPassword = (email) => fetchApi({
    url: endPoints.resetPassword,
    data: {
        email: email,
    },
    method: 'POST',
    useAccessToken: false,
});

export const resetPasswordConfirm = (uid, token, new_password) => fetchApi({
    url: endPoints.resetPasswordConfirm,
    data: {
        uid: uid,
        token: token,
        new_password: new_password,
    },
    method: 'POST',
    useAccessToken: false,
});
