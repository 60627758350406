import {
    DIALOG_COURSE_LOADING,
    DIALOG_COURSE_SUCCESS,
    DIALOG_COURSE_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    error: null,
    courses: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DIALOG_COURSE_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case DIALOG_COURSE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                courses: action.payload.results, // TODO multiple pages
            };
        case DIALOG_COURSE_FAILED:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
