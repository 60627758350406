import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    listTickets,
} from './actions';

import {
    AdminHeader,
    CardList,
    CardTicketAdmin,
    EmptyMessage,
} from '..';

const useStyles = (theme) => ({
});

class AdminEventTickets extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.handlePage = this.handlePage.bind(this);
    }

    componentDidMount() {
        if (!this.props.isLoadingEvent) {
            this.props.listTickets({
                eventId: this.props.event.id,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event !== this.props.event && this.props.event) {
            this.props.listTickets({
                eventId: this.props.event.id,
            });
        }
    }

    handlePage(object, page) {
        this.props.listTickets({
            eventId: this.props.event.id,
            page: page,
        });
    }

    renderEmpty() {
        const { t } = this.props;

        return (
            <EmptyMessage
                title={t('common:title.no_tickets')}
                description={t('common:text.no_tickets')}
            />
        );
    }

    renderLoader() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <div id="top-page" />
                <AdminHeader
                    variant={'small'}
                    title={t('common:title.tickets')}
                />

                {
                    [...new Array(10)]
                        .map(
                            (key) => (<CardTicketAdmin
                                key={key}
                                isLoading
                            />)
                        )
                }
            </React.Fragment>
        );
    }

    render() {
        const {
            t, tickets, isLoading, total_pages,
            current_page, error, isLoadingEvent,
            event,
        } = this.props;

        if (isLoading || isLoadingEvent) {
            return this.renderLoader();
        }

        if (!tickets || tickets.length === 0) {
            return this.renderEmpty();
        }

        return (
            <React.Fragment>
                <div id="top-page" />

                <AdminHeader
                    variant={'small'}
                    title={t('common:title.tickets')}
                />

                <CardList
                    isLoading={isLoading}
                    objects={tickets}
                    totalPages={total_pages}
                    currentPage={current_page}
                    error={error}
                    card={props => <CardTicketAdmin event={event} isLoading={isLoadingEvent} {...props} />}
                    handlePage={this.handlePage}
                    useCardAsLoaders
                />
            </React.Fragment>
        );
    }
}

AdminEventTickets.propTypes = {
    event: PropTypes.object.isRequired,
    isLoadingEvent: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        error,
        tickets,

        isLoadingActions,
        actions,
        errorActions,
    } = state.componentAdminEventTickets;

    return {
        isLoading,
        error,
        tickets,

        isLoadingActions,
        actions,
        errorActions,
    };
};

const AdminEventTicketsState = connect(
    mapStateToProps,
    {
        listTickets,
    }
)(AdminEventTickets);
const AdminEventTicketsTranslated = withTranslation()(AdminEventTicketsState);
const AdminEventTicketsStyled = withStyles(useStyles)(AdminEventTicketsTranslated);
const AdminEventTicketsRouter = withRouter(AdminEventTicketsStyled);

export { AdminEventTicketsRouter as AdminEventTickets };
