import React, { Component, Suspense, Spinner } from 'react';
import { withTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { Container } from '@material-ui/core';

import { TemplateMarketing } from '../TemplateMarketing';
import { getUrl } from 'urls';

import PropTypes from 'prop-types';

class TermsPage extends Component {
    render() {
        const { t } = this.props;

        return (
            <Suspense fallback={<Spinner />}>
                <Helmet>
                    <title>{t('legal:title.main.terms')} | Equento</title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>
                <TemplateMarketing>
                    <Container>
                        <h1>{t('legal:title.main.terms')}</h1>

                        <p>
                            Currently the terms and conditions are only
                            available in Dutch. Please contact us for the
                            transations on{' '}
                            <a href="mailto:info@equento.be">info@equento.be</a>
                            .
                        </p>

                        <div className="spacer" />
                        <h2>Algemeen</h2>
                        <p>
                            Deze algemene voorwaarden zijn van toepassing op de
                            Diensten (zoals gedefinieerd in artikel 1) die onder
                            het merk ‘Equento’ op de markt worden gebracht
                            (hierna ‘<strong>Algemene Voorwaarden</strong>’).
                            Deze Diensten worden geleverd door Selentic, Ingo
                            Berben, met maatschappelijke zetel te
                            Sint-Pietersstraat 139, 3630 Leut, met
                            ondernemingsnummer 0811.354.718 (hierna ‘
                            <strong>Equento</strong>’).
                        </p>

                        <div className="spacer" />
                        <h2>Artikel 1: Definities</h2>
                        <ul>
                            <li>
                                <strong>Diensten:</strong> het geheel van de
                                door Equento aangeboden diensten.
                            </li>
                            <li>
                                <strong>Inschrijving / Bestelling:</strong> de
                                verbintenis tussen een Gebruiker en een
                                Organisator waarbij de Gebruiker aangeeft te
                                willen deelnemen aan een wedstrijd.
                            </li>
                            <li>
                                <strong>Organisator:</strong> elke natuurlijke
                                persoon, rechtspersoon en / of iedereen die in
                                naam of voor rekening van een (rechts)persoon
                                die één of meerdere wedstrijden organiseert en
                                deze aanbiedt via de Website.
                            </li>
                            <li>
                                <strong>Wedstrijd(en):</strong> een evenement
                                dat aangeboden wordt door de Organisator
                                waarvoor een Gebruiker een Inschrijving kan
                                doen.
                            </li>
                            <li>
                                <strong>Gebruiker:</strong> elke natuurlijke
                                persoon, rechtspersoon en / of iedereen die in
                                naam of voor rekening van een (rechts)persoon
                                die één of meerdere inschrijvingen aankoopt, een
                                offerte aanvraagt en / of een bestelling plaatst
                                bij Equento.
                            </li>
                            <li>
                                <strong>Website:</strong> www.equento.be.
                            </li>
                            <li>
                                <strong>Partij(en):</strong> Equento en/of de
                                Gebruiker.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 2: Toepasbaarheid</h2>
                        <ul>
                            <li>
                                <strong>2.1</strong> De huidige Algemene
                                Voorwaarden zijn van toepassing op elke online
                                bestelling op de Website van Equento.
                                Voorafgaand aan het aanmaken van een account en
                                / of het plaatsen van een bestelling en / of het
                                plaatsen van een Wedstrijd op de Website moet de
                                Gebruiker en / of Organisator de laatste versie
                                van de Algemene Voorwaarden lezen en
                                uitdrukkelijk aanvaarden middels het aanvinken
                                van een checkbox. Daarbij gaat de Gebruiker en /
                                of de Organisator akkoord met de Algemene
                                Voorwaarden.
                            </li>
                            <li>
                                <strong>2.2</strong> Equento heeft steeds het
                                recht om deze Algemene Voorwaarden te wijzigen.
                                In geval van wijziging zal de laatste versie van
                                de voorwaarden opnieuw ter aanvaarding worden
                                voorgelegd aan de Gebruiker en / of Organisator
                                via de accountomgeving en / of bij het
                                bestelproces en / of bij het aanmaken van een
                                Wedstrijd op de Website. Een kopie van deze
                                Algemene Voorwaarden kan ten alle tijden worden
                                geraadpleegd en gedownload worden op de Website.
                            </li>
                            <li>
                                <strong>2.3</strong> De Wedstrijden die op de
                                Website worden aangeboden zijn zowel bestemd
                                voor (i) natuurlijke personen die uitsluitend
                                handelen in hun hoedanigheid van Gebruiker, als
                                voor (ii) rechtspersonen die handelen in hun
                                professionele hoedanigheid en vertegenwoordigd
                                worden door een natuurlijk persoon.
                            </li>
                            <li>
                                <strong>2.4</strong> De rechtsverhouding tussen
                                de Gebruiker en / of Organisator en Equento en
                                alles wat hiermee verband houdt, is uitsluitend
                                onderworpen aan volgende normen: (in
                                hiërarchisch dalende volgorde, het volgende bij
                                ontbreken of stilzwijgen van het vorige) (1) de
                                schriftelijke en ondertekende bijzondere
                                overeenkomst tussen Partijen; (2) de
                                elektronische orderbevestiging; (3) deze
                                Algemene Voorwaarden; (4) het Belgische recht,
                                met uitsluiting van andere rechtsnormen die
                                kunnen leiden tot toepassing van een ander
                                rechtsstelsel dan het Belgische. Equento wijst
                                alle andere normen en voorwaarden af, met de
                                enkele uitzondering van de voorwaarden die
                                Equento uitdrukkelijk schriftelijk met de
                                Gebruiker en / of Organisator overeen is
                                gekomen. Deze uitdrukkelijk schriftelijk
                                aanvaarde afwijkingen zijn slechts geldig voor
                                de overeenkomst/bestelling waarop zij betrekking
                                hebben en kunnen niet worden ingeroepen bij
                                eventuele andere, zelfs gelijkaardige
                                overeenkomsten. De Gebruiker en / of Organisator
                                aanvaardt dat deze Algemene Voorwaarden als
                                enige van toepassing zullen zijn, met
                                uitsluiting van zijn eigen algemene of
                                specifieke (aankoop)voorwaarden, ook als deze
                                zouden bepalen dat zij als enige van toepassing
                                zijn.
                            </li>
                            <li>
                                <strong>2.5</strong> Deze Algemene Voorwaarden
                                doen geen afbreuk aan de wettelijke rechten die
                                op dwingende wijze aan de Gebruiker en / of
                                Organisator worden verleend krachtens de
                                toepasselijke nationale wetgeving m.b.t.
                                consumentenbescherming.
                            </li>
                            <li>
                                <strong>2.6</strong> Indien Equento één of
                                meerdere van de rechten opgesomd in deze
                                Algemene Voorwaarden eventueel of zelfs
                                herhaaldelijk niet zou afdwingen of toepassen,
                                dan kan dit slechts beschouwd worden als het
                                dulden van een bepaalde toestand en leidt dit
                                niet tot rechtsverwerking. Dergelijk nalaten kan
                                nooit als een verzaking aan de bepaling(en)
                                worden gezien en zal nooit de geldigheid van
                                deze rechten aantasten.
                            </li>
                            <li>
                                <strong>2.7</strong> De eventuele nietigheid van
                                één van de bepalingen van deze Algemene
                                Voorwaarden of een deel van een bepaling doet
                                geen afbreuk aan de toepasselijkheid van de
                                overige bepalingen en/of de rest van de
                                bepaling. In geval van nietigheid van één van de
                                bepalingen zullen Equento en de Gebruiker en /
                                of Organisator, in de mate van het mogelijke en
                                volgens hun loyaliteit en overtuiging,
                                onderhandelen om de nietige bepaling te
                                vervangen door een equivalente bepaling die
                                beantwoordt aan de algemene geest van deze
                                Algemene Voorwaarden. Komen de Partijen niet tot
                                een akkoord, dan kan het bevoegde forum de
                                nietige bepaling matigen tot wat (wettelijk)
                                toegelaten is.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 3: Totstandkoming overeenkomst</h2>
                        <ul>
                            <li>
                                <strong>3.1</strong> Elk aanbod op de Website,
                                op sociale media, in nieuwsbrieven of een andere
                                vorm van publieke of private aankondigingen is
                                slechts informatief. Equento mag de aanbiedingen
                                op elke ogenblik aanpassen of intrekken. Ondanks
                                het feit dat de Website of andere publieke of
                                private aankondigingen met de grootst mogelijke
                                zorgvuldigheid worden samengesteld, is het toch
                                mogelijk dat de aangeboden informatie onvolledig
                                is, materiële fouten bevat of niet up-to-date
                                is. Equento is niet aansprakelijk voor fouten.
                            </li>
                            <li>
                                <strong>3.2</strong> Kennelijke vergissingen en
                                / of kennelijke fouten in het aanbod binden
                                Equento niet.
                            </li>
                            <li>
                                <strong>3.3</strong> De Inschrijvingen via
                                Equento zijn enkel bedoeld voor individueel
                                gebruik en zijn niet bestemd voor wederverkoop.
                            </li>
                            <li>
                                <strong>3.4</strong> De overeenkomst tussen
                                Equento en de Gebruiker en / of Organisator komt
                                slechts tot stand nadat deze een bestelling
                                heeft gedaan en / of een account heeft
                                aangemaakt en / of een Wedstrijd heeft
                                aangemaakt.
                            </li>
                            <li>
                                <strong>3.5</strong> Annulatie van een
                                bestelling is enkel mogelijk op aanvraag van de
                                Gebruiker via{' '}
                                <a href="mailto:info@equento.be">
                                    info@equento.be
                                </a>{' '}
                                of via een Organisator. Het is aan de
                                Organisator om eventuele terugbetalingen te
                                regelen.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 4: Wedstrijd</h2>
                        <ul>
                            <li>
                                <strong>4.1</strong> De Organisator is
                                verantwoordelijk voor de aangeboden informatie,
                                waaronder, maar niet gelimiteerd tot de prijzen,
                                bij een Wedstrijd. Equento is niet aansprakelijk
                                voor fouten.
                            </li>
                            <li>
                                <strong>4.2</strong> De Wedstrijd kan op elk
                                moment gewijzigd worden door de Organisator.
                                Eventuele schade, terugbetalingen of
                                bijbetalingen moeten door de Organisator
                                geregeld worden.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 5: Inschrijving</h2>
                        <ul>
                            <li>
                                <strong>5.1</strong> Wanneer een Gebruiker een
                                Inschrijving plaatst, moet deze persoonlijke
                                gegevens opgeven. Deze gegevens moeten
                                waarheidsgetrouw zijn en gegevens zijn van (i)
                                natuurlijke personen die uitsluitend handelen in
                                hun hoedanigheid van Gebruiker, of voor (ii)
                                rechtspersonen die handelen in hun professionele
                                hoedanigheid en vertegenwoordigd worden door een
                                natuurlijk persoon.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 6: Terugbetalingen</h2>
                        <ul>
                            <li>
                                <strong>6.1</strong> Aan een Inschrijving zijn
                                transactiekosten verbonden. Deze worden door
                                ofwel de Gebruiker of de Organisator betaald.
                                Bij een terugbetaling kunnen de transactiekosten
                                nooit teruggevorderd worden.
                            </li>
                            <li>
                                <strong>6.2</strong> Wanneer een Wedstrijd wordt
                                geannuleerd, moet de Organisator de
                                terugbetalingen regelen van de reeds voltooide
                                Inschrijvingen.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 7: Prijs en kosten</h2>
                        <ul>
                            <li>
                                <strong>7.1</strong> Alle prijzen worden in Euro
                                (€) uitgedrukt en zijn inclusief btw.
                            </li>
                            <li>
                                <strong>7.2</strong> Elke verhoging van het
                                btw-tarief en/of elke andere kost tussen het
                                plaatsen van de bestelling en de uitvoering
                                ervan is ten laste van de Gebruiker /
                                Organisator.
                            </li>
                            <li>
                                <strong>7.3</strong> Het totaal van een
                                Inschrijving wordt altijd weergeven met een
                                samenvatting van de totstandkoming van het
                                totaal alvorens de Gebruiker deze afrekent.
                            </li>
                            <li>
                                <strong>7.4</strong> De prijzen weergegeven bij
                                een Wedstrijd kunnen ten alle tijden gewijzigd
                                worden door de Organisatie. Het is de
                                verantwoordelijkheid van de Organisatie om
                                eventuele verschillen terug te betalen of te
                                laten bijbetalen.
                            </li>
                            <li>
                                <strong>7.5</strong> Equento behoudt zich het
                                recht om prijzen vermeld op de Website, in
                                catalogi, nieuwsbrieven of eender welk ander
                                communicatiemiddel te wijzigen. Kennelijke
                                fouten, manipulaties of vergissingen (zoals,
                                maar niet gelimiteerd tot, evidente
                                onjuistheden) kunnen ook na de totstandkoming
                                van een overeenkomst door Equento worden
                                rechtgezet.
                            </li>
                            <li>
                                <strong>7.6</strong> Voor de Gebruiker zijn er
                                eventueel transactiekosten verbonden aan een
                                Inschrijving via de Website. Dit wordt bepaald
                                door de Organisator.
                            </li>
                            <li>
                                <strong>7.7</strong> De Organisator betaalt
                                altijd transactiekosten op een Inschrijving.
                                Deze kosten dienen om de afhandeling van de
                                online betaling te doen. Deze transactiekosten
                                kunnen niet teruggevorderd worden.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 8: Facturatie en betaling</h2>
                        <ul>
                            <li>
                                <strong>8.1</strong> De Gebruiker moet een
                                Inschrijving altijd vooraf betalen. Daarbij
                                heeft de Gebruiker de keuze uit verschillende
                                betaalmethodes. Deze hangen af van de
                                beschikbaarheid, maar voorbeelden zijn: VISA,
                                MasterCard, Bancontact.
                            </li>
                            <li>
                                <strong>8.2</strong> De betaalmethoden die
                                aangeboden worden, staan duidelijk op de Website
                                aangegeven. Equento behoudt zich het recht deze
                                betaalmethoden aan te passen.
                            </li>
                            <li>
                                <strong>8.3</strong> Alle online betalingen
                                worden door externe professionele en
                                gespecialiseerde partners verwerkt. De betaling
                                is dan ook onderworpen aan de algemene
                                voorwaarden van de externe partner en deze
                                draagt de verantwoordelijkheid voor de correcte
                                verwerking van alle online betalingen.
                            </li>
                            <li>
                                <strong>8.4</strong> Wanneer er een probleem is
                                met de betaling, kan de Gebruiker of Organisator
                                contact opnemen met Equento. Deze zal als
                                tussenpartij fungeren om het probleem op te
                                lossen. Problemen die hieruit voortvloeien,
                                zoals, maar niet gelimiteerd tot, het niet
                                kunnen voltooien van een Inschrijving en
                                daardoor niet kunnen deelnemen aan een
                                Wedstrijd, is niet de verantwoordelijkheid van
                                Equento.
                            </li>
                            <li>
                                <strong>8.5</strong> De Gebruiker en / of de
                                Organisator kan een factuur krijgen van zijn
                                betalingen. Hiervoor mag je contact opnemen met
                                Equento.
                            </li>
                            <li>
                                <strong>8.6</strong> Uitbetalingen aan de
                                Organisatie
                            </li>
                            <li>
                                <strong>8.6.1</strong> Op verschillende momenten
                                wordt het totaal van de Inschrijvingen voor een
                                Wedstrijd overgemaakt aan de Organisatie.
                                Hiervan worden de transactiekosten afgehouden.
                                Dit gebeurd niet onmiddellijk na het voltooien
                                van een Inschrijving.
                            </li>
                            <li>
                                <strong>8.6.2</strong> De Organisatie krijgt
                                hiervan een factuur met een duidelijk overzicht
                                van de transactiekosten.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 9: Aanvaarding en klachten</h2>
                        <ul>
                            <li>
                                <strong>9.1</strong> Eventuele klachten over
                                diensten, producten of facturen dienen via
                                e-mail naar{' '}
                                <a href="mailto:info@equento.be">
                                    info@equento.be
                                </a>{' '}
                                gestuurd te worden zodra de klacht is
                                vastgesteld en in ieder geval binnen de zeven
                                (7) dagen nadat deze diensten zijn verleend, de
                                producten zijn geleverd, of de facturen zijn
                                verzonden. Zichtbare gebreken aan producten
                                dienen echter op het moment van levering te
                                worden vastgesteld en vemeld.
                            </li>
                            <li>
                                <strong>9.2</strong> Na het verstrijken van deze
                                termijn worden de geleverde diensten / producten
                                / facturen geacht te zijn goedgekeurd door de
                                Gebruiker / Organisator.
                            </li>
                            <li>
                                <strong>9.3</strong> Klachten ontheffen een
                                Gebruiker / Organisator niet van de
                                betalingsverplichting binnen de vastgestelde
                                termijn(en).
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 10: Verplichtingen Gebruiker</h2>
                        <ul>
                            <li>
                                <strong>10.1</strong> Alle informatie die de
                                Gebruiker aan Equento verstrekt, zoals, maar
                                niet gelimiteerd tot, de accountomgeving en/of
                                het bestelprocess, moet actueel en
                                waarheidsgetrouw zijn.
                            </li>
                            <li>
                                <strong>10.2</strong> Het wachtwoord van de
                                Gebruiker mag niet aan derden worden
                                doorgegeven. Het wachtwoord moet veilig en
                                afgeschermd van onbevoegden bewaard worden. De
                                Gebruiker stelt Equento onmiddellijk op de
                                hoogte van een eventueel verlies of doorgave van
                                deze informatie. Equento is niet
                                verantwoordelijk als hierdoor informatie is
                                gelekt, of andere problemen door ontstaan.
                            </li>
                            <li>
                                <strong>10.3</strong> De Gebruiker mag geen
                                teksten, afbeeldingen, foto’s, video’s of elke
                                andere vorm van publicaties gebruiken op de
                                Website als hij hierop geen licentie heeft.
                                Equento is hiervoor niet aansprakelijk.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 11: Verplichtingen Organisator</h2>
                        <ul>
                            <li>
                                <strong>11.1</strong> Alle informatie die de
                                Organisator aan Equento verstrekt, zoals, maar
                                niet gelimiteerd tot, de accountomgeving en/of
                                het bestelprocess, moet actueel en
                                waarheidsgetrouw zijn.
                            </li>
                            <li>
                                <strong>11.2</strong> Het wachtwoord van de
                                Organisator mag niet aan derden worden
                                doorgegeven. Het wachtwoord moet veilig en
                                afgeschermd van onbevoegden bewaard worden. De
                                Gebruiker stelt Equento onmiddellijk op de
                                hoogte van een eventueel verlies of doorgave van
                                deze informatie. Equento is niet
                                verantwoordelijk als hierdoor informatie is
                                gelekt, of andere problemen door ontstaan.
                            </li>
                            <li>
                                <strong>11.3</strong> Wanneer een Wedstrijd niet
                                kan doorgaan, is het de verantwoordelijkheid van
                                de Organisator om de terugbetalingen te regelen.
                                De Organisator mag het reeds overgemaakte bedrag
                                van de Inschrijvingen niet houden.
                                Transactiekosten zijn niet terugbetaalbaar.
                            </li>
                            <li>
                                <strong>11.4</strong> De Organisator mag geen
                                teksten, afbeeldingen, foto’s, video’s of elke
                                andere vorm van publicaties gebruiken op de
                                Website als hij hierop geen licentie heeft.
                                Equento is hiervoor niet aansprakelijk.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 12: Aansprakelijkheid</h2>
                        <ul>
                            <li>
                                <strong>12.1</strong> Equento is niet
                                aansprakelijk voor eventuele schade ten gevolge
                                van een downtime van de website of van één of
                                meerdere van haar partners (zoals, maar niet
                                gelimiteerd tot betalingspartners), onjuist
                                verstrekte informatie en/of verlies van het
                                wachtwoord. De Gebruiker en/of Organisator
                                vrijwaart Equento tegen eventuele aanspraken van
                                derden.
                            </li>
                            <li>
                                <strong>12.2</strong> De aansprakelijkheid van
                                Equento ten aanzien van de Gebruiker en/of
                                Organisator is beperkt tot de aansprakelijkheid
                                die verplicht is opgelegd door de wet en is
                                gelimiteerd tot het totaalbedrag van de
                                Inschrijving(en), exclusief de transactiekosten.
                            </li>
                            <li>
                                <strong>12.3</strong> De Gebruiker / Organisator
                                gaat ermee akkoord dat Equento, ondanks alle
                                voorzorgen die genomen zijn om de Website online
                                te houden, geen enkele garantie, van welke aard
                                dan ook geeft.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 13: Vertrouwelijkheid</h2>
                        <ul>
                            <li>
                                <strong>13.1</strong> De Gebruiker en/of
                                Organisator verplicht zich om geen
                                vertrouwelijke informatie te onthullen die werd
                                ontvangen van Equento.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 14: Overmacht</h2>
                        <ul>
                            <li>
                                <strong>14.1</strong> Equento is niet
                                aansprakelijk voor situaties van overmacht die
                                ertoe kunnen leiden dat Equento niet (tijdig)
                                aan haar verplichtingen kan voldoen. De
                                verplichtingen zullen opgeschort worden voor de
                                duur van de desbetreffende situatie.
                            </li>
                            <li>
                                <strong>14.2</strong> Equento probeert er alles
                                aan te doen om de Website online te houden.
                                Eventuele downtime en de impact hiervan, of de
                                kosten die hieraan verbonden zijn voor
                                bijvoorbeeld de Organisatie, is niet de
                                verantwoordelijkheid van Equento. Equento zal er
                                alles aan doen om de website bij downtime zo
                                snel mogelijk terug online te krijgen.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 15: Intellectuele eigendom</h2>
                        <ul>
                            <li>
                                <strong>15.1</strong> Alle onderdelen van de
                                Website, zoals, maar niet gelimiteerd tot,
                                logo’s, afbeeldingen, video’s, foto’s, teksten,
                                layout en het grafisch ontwerp van de
                                webpagina’s, e-mails en de app zijn beschermd
                                door auteursrechten en de exclusieve eigendom
                                van Equento en/of haar licentiegever. Elke vorm
                                van kopiëren, publicatie, namaak of andere vorm
                                van gebruik, onder welke vorm dan ook, is
                                verboden zonder voorafgaande toestemming van
                                Equento.
                            </li>
                            <li>
                                <strong>15.2</strong> De inhoud van de Website
                                mag gedeeld worden op sociale media, als dit de
                                bekendheid van Equento of Equento zelf op een
                                positieve manier naar voren brengt. Equento
                                behoudt zich het recht om deze gedeelde inhoud
                                offline te halen. De Gebruiker of Organisator
                                die deze inhoud deelt, moet ervoor zorgen dat er
                                een duidelijke verwijzing is naar Equento.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 16: Persoonsgegevens</h2>
                        <ul>
                            <li>
                                <strong>16.1</strong> Equento leeft te allen
                                tijde de Belgische Privacywet van 30 juli 2018
                                betreffende de bescherming van natuurlijke
                                personen met betrekking tot de verwerking van
                                persoonsgegevens en de EU Verordening 2016/679
                                van 27 april 2016 betreffende de bescherming van
                                natuurlijke personen in verband met de
                                verwerking van persoonsgegevens en betreffende
                                het vrije verkeer van die gegevens en tot
                                intrekking van Richtlijn 95/46/EG (‘GDPR’) na.
                            </li>
                            <li>
                                <strong>16.2</strong> De door de Gebruiker en
                                Organisator verstrekte gegevens worden verwerkt
                                in overeenstemming met de Privacyverklaring van
                                Equento.
                            </li>
                            <li>
                                <strong>16.3</strong> Equento gebruikt de
                                gegevens enkel voor de goede werking van de
                                Website.
                            </li>
                            <li>
                                <strong>16.4</strong> Equento treft alle nodige
                                maatregelen om de gegevens te beschermen en zal
                                deze niet doorgegeven aan derden.
                                Niettegenstaande het voorgaande, aanvaardt en
                                erkent de Gebruiker en de Organisator dat Equent
                                sommige gegevens aan derden (zoals, maar niet
                                gelimiteerd tot betalingspartners) mag overmaken
                                met oog op de correcte uitvoering van de
                                overeenkomst.
                            </li>
                            <li>
                                <strong>16.5</strong> De Gebruiker en de
                                Organisator kan steeds om inzage, verbetering,
                                verwijdering, beperking, overdraagbaarheid van
                                zijn gegevens vragen per e=mail op
                                info@equento.be.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 17: Geschillen</h2>
                        <ul>
                            <li>
                                <strong>17.1</strong> Het Belgische recht is van
                                toepassing.
                            </li>
                            <li>
                                <strong>17.2</strong> Uitsluitend de rechtbanken
                                in het rechtsgebied waarin de maatschappelijke
                                zetel van Equento is gevestigd zijn bevoegd om
                                kennis te nemen van geschillen betreffende de
                                overeenkomst.
                            </li>
                        </ul>

                        <div className="spacer" />
                        <h2>Artikel 18: Partners</h2>
                        <ul>
                            <li>
                                <strong>Mollie</strong>{' '}
                                <a
                                    href="https://www.mollie.com/be/user-agreement"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://www.mollie.com/be/user-agreement
                                </a>
                            </li>
                            <li>
                                <strong>Google</strong>{' '}
                                <a
                                    href="https://policies.google.com/terms?hl=en-US"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    https://policies.google.com/terms?hl=en-US
                                </a>
                            </li>
                        </ul>
                    </Container>
                </TemplateMarketing>
            </Suspense>
        );
    }
}

TermsPage.propTypes = {
    // event handlers
    t: PropTypes.func.isRequired,
};

const TermsPageTranslated = withTranslation()(TermsPage);
export { TermsPageTranslated as TermsPage };
