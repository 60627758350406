export const CLUBS_LOADING = 'clubs_loading';
export const CLUBS_LOADING_SUCCESS = 'clubs_loading_success';
export const CLUBS_LOADING_FAILED = 'clubs_loading_failed';

export const CLUB_RESET_CHANGED = 'club_reset_changed';
export const CLUB_RESET_CREATED = 'club_reset_created';
export const CLUB_RESET_DELETED = 'club_reset_deleted';

export const CLUBS_ACTION_LOADING = 'clubs_action_loading';
export const CLUBS_ACTION_SUCCESS = 'clubs_action_success';
export const CLUBS_ACTION_FAILED = 'clubs_action_failed';
