import {
    HOME_PAGE_EVENTS_LOADING,
    HOME_PAGE_EVENTS_SUCCESS,
    HOME_PAGE_EVENTS_FAILED,
} from './actionTypes';

import moment from 'moment';

import { listPublicEvents } from 'services/EventService';

export const listEvents = () => {
    return (dispatch) => {
        dispatch({ type: HOME_PAGE_EVENTS_LOADING });

        listPublicEvents({
            page: 1,
            dateMin: moment().format('YYYY-MM-DD'),
        })
            .then((result) => {
                dispatch({
                    type: HOME_PAGE_EVENTS_SUCCESS,
                    payload: result.results,
                });
            })
            .catch((error) => {
                dispatch({
                    type: HOME_PAGE_EVENTS_FAILED,
                    payload: error,
                });
            });
    };
};
