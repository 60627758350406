import {
    ADMIN_DISCOUNT_DIALOG_APPLY_LOADING,
    ADMIN_DISCOUNT_DIALOG_APPLY_SUCCESS,
    ADMIN_DISCOUNT_DIALOG_APPLY_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    isDiscountAdded: false,
    amount: 0,
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_DISCOUNT_DIALOG_APPLY_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
                amount: action.payload,
            };
        case ADMIN_DISCOUNT_DIALOG_APPLY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDiscountAdded: true,
            };
        case ADMIN_DISCOUNT_DIALOG_APPLY_FAILED:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
