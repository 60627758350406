import { exportEvent as apiExportEvent } from 'services/EventService';
import { exportCourse as apiExportCourse } from 'services/CourseService';

export const exportEvent = ({ id, fileType, showUnpaid }) => {
    return (dispatch) => {
        return apiExportEvent({ id, fileType, showUnpaid });
    };
};

export const exportCourse = ({ id, fileType, showUnpaid }) => {
    return (dispatch) => {
        return apiExportCourse({ id, fileType, showUnpaid });
    };
};
