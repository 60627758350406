import React, { Component } from "react";

import PropTypes from 'prop-types';
import classNames from 'classnames';


class IconBorder extends Component {
    render() {
        return (
            <div className={
                classNames(
                    'iconBorder',
                    this.props.dark && 'iconBorderDark',
                    this.props.variant === 'large' && 'iconBorderLarge',
                    this.props.variant === 'medium' && 'iconBorderMedium',
                )
            }>
                {this.props.children}
            </div>
        );
    }
}

IconBorder.propTypes = {
    children: PropTypes.object.isRequired,
    dark: PropTypes.bool,
    variant: PropTypes.oneOf(['normal', 'medium', 'large']),
};

IconBorder.defaultProps = {
    variant: 'normal',
};

export { IconBorder };
