import _ from 'lodash';
import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Redirect, withRouter } from 'react-router-dom';
import { urls, getUrl } from 'urls';
import { ERROR_CODES } from '../../enums';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { AdminHeader, Toast, EmptyMessage, TemplateAdmin } from 'components';
import { scrollIntoView, AdminContext } from '../../utils';

import {
    getEvent,
    addEvent,
    editEvent,
    listClubs,
    resetEvent,
} from './actions';
import { EventForm } from './form';

const useStyles = (theme) => ({
    container: {
        backgroundColor: 'transparent',
        paddingBottom: theme.spacing(8),
    },
});

class AdminEventAddEditPage extends Component {
    static contextType = AdminContext; // eslint-disable-line

    constructor(props) {
        super(props);

        const eventId = this.props.match.params.eventId;

        // set state
        this.state = {
            redirect: null,
            eventId: eventId,
        };

        // reset
        this.props.resetEvent();

        // load event
        if (eventId) {
            this.props.getEvent(eventId);
        }

        // load clubs
        this.props.listClubs();

        // event handlers
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { t } = this.props;
        const { eventId } = this.state;

        this.context.setAdminTitle(
            eventId
                ? t('common:title.admin.event_edit')
                : t('common:title.admin.event_add')
        );
        this.context.setAdminBackAction(() =>
            this.props.history.push(getUrl(urls.ADMIN_EVENTS))
        );
    }

    componentDidUpdate(prevProps) {
        if (prevProps.newEvent !== this.props.newEvent && this.props.newEvent) {
            // success
            this.setState({
                redirect: true,
            });
        }

        if (
            prevProps.formErrors !== this.props.formErrors &&
            this.props.formErrors
        ) {
            scrollIntoView('#top-form');
        }
    }

    handleSubmit(data) {
        // convert data
        if (data['subscriptions_open_at'] instanceof Date) {
            data['subscriptions_open_at'] = data[
                'subscriptions_open_at'
            ].toISOString();
        }

        if (data['subscriptions_closed_at'] instanceof Date) {
            data['subscriptions_closed_at'] = data[
                'subscriptions_closed_at'
            ].toISOString();
        }

        if (typeof data['status'] === 'boolean') {
            data['status'] = data['status'] ? 'published' : 'draft';
        }

        data['days'] = _.reduce(
            data['days'],
            (result, value) => {
                let timeStart = null;
                let dateStart = null;
                if (value['date_start'] instanceof Date) {
                    timeStart = value['date_start'];
                    timeStart.setSeconds(0);
                    timeStart = timeStart.toLocaleTimeString();

                    dateStart =
                        value['date_start'].getFullYear() +
                        '-' +
                        ('0' + (value['date_start'].getMonth() + 1)).slice(-2) +
                        '-' +
                        ('0' + value['date_start'].getDate()).slice(-2);
                } else {
                    const splitted = value['date_start'].split(' ');
                    timeStart = splitted[1];
                    dateStart = splitted[0];
                }

                let timeEnd = null;
                let dateEnd = null;
                if (value['date_end'] instanceof Date) {
                    timeEnd = value['date_end'];
                    timeEnd.setSeconds(0);
                    timeEnd = timeEnd.toLocaleTimeString();

                    dateEnd =
                        value['date_end'].getFullYear() +
                        '-' +
                        ('0' + (value['date_end'].getMonth() + 1)).slice(-2) +
                        '-' +
                        ('0' + value['date_end'].getDate()).slice(-2);
                } else {
                    const splitted = value['date_end'].split(' ');
                    timeEnd = splitted[1];
                    dateEnd = splitted[0];
                }

                result.push({
                    date_start_at: dateStart,
                    date_end_at: dateEnd,
                    time_start_at: timeStart,
                    time_end_at: timeEnd,
                });
                return result;
            },
            []
        );

        if (this.props.event) {
            data.id = this.props.event.id;
            this.props.editEvent(data);
        } else {
            this.props.addEvent(data);
        }
    }

    renderContent() {
        const { t, error, errorClubs, newEvent, isEventInThePast } = this.props;
        const { redirect, eventId } = this.state;

        return (
            <React.Fragment>
                <TemplateAdmin header>
                    {error && (
                        <Toast
                            message={t('common:error.general.description')}
                            open
                        />
                    )}
                    {redirect && (
                        <React.Fragment>
                            {this.props.newEvent ? (
                                <Redirect to={urls.ADMIN_EVENTS} />
                            ) : (
                                <Redirect
                                    to={getUrl(urls.ADMIN_EVENT_DETAIL, {
                                        eventId: newEvent.id,
                                    })}
                                />
                            )}
                            <Toast
                                message={t(
                                    'common:success.general.description'
                                )}
                                open
                            />
                        </React.Fragment>
                    )}
                    <AdminHeader
                        title={
                            eventId
                                ? t('common:title.admin.event_edit')
                                : t('common:title.admin.event_add')
                        }
                        breadcrumbs={[
                            {
                                title: t('common:text.menu.events'),
                                url: getUrl(urls.ADMIN_EVENTS),
                            },
                            {
                                title: eventId
                                    ? t('common:title.admin.event_edit')
                                    : t('common:title.admin.event_add'),
                            },
                        ]}
                        removeMargin
                    />
                </TemplateAdmin>

                <TemplateAdmin>
                    <div id="top-form" />

                    {Boolean(errorClubs) && !isEventInThePast && (
                        <Toast message={errorClubs} open />
                    )}
                    {isEventInThePast && (
                        <Toast
                            message={t('common:error.event.edit_in_the_past')}
                            open
                        />
                    )}

                    <EventForm
                        onSubmit={this.handleSubmit}
                        errors={this.props.formErrors}
                        isLoading={this.props.isAdding || this.props.isEditing}
                        event={this.props.event}
                        clubs={this.props.clubs}
                        eventCreated={Boolean(this.props.newEvent)}
                    />
                </TemplateAdmin>
            </React.Fragment>
        );
    }

    renderLoader() {
        const { t } = this.props;

        return (
            <EmptyMessage
                title={t('common:title.load_event')}
                description={t('common:description.event.load_event')}
                showLoader
            />
        );
    }

    render() {
        const { isLoading, t } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <Helmet>
                    <title>
                        {this.state.eventId
                            ? t('common:title.admin.event_edit')
                            : t('common:title.admin.event_add')}{' '}
                        | {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line
                    />
                </Helmet>
                {isLoading ? this.renderLoader() : this.renderContent()}
            </Suspense>
        );
    }
}

AdminEventAddEditPage.propTypes = {
    // props
    newEvent: PropTypes.object,
    event: PropTypes.object,
    error: PropTypes.string,
    errorClubs: PropTypes.string,
    isAdding: PropTypes.bool,
    isEditing: PropTypes.bool,
    isEventInThePast: PropTypes.bool,
    club: PropTypes.object,
    clubs: PropTypes.array,
    isLoading: PropTypes.bool,
    formErrors: PropTypes.object,
    // handlers
    getEvent: PropTypes.func.isRequired,
    resetEvent: PropTypes.func.isRequired,
    listClubs: PropTypes.func.isRequired,
    editEvent: PropTypes.func.isRequired,
    addEvent: PropTypes.func.isRequired,
    // others
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const {
        isAdding,
        isEditing,
        isLoadingClubs,
        isLoading,
        newEvent,
        clubs,
        event,
        formErrors,
        error,
        errorClubs,
    } = state.pageAdminEventAddEdit;

    let isEventInThePast = false;
    if (error && error.code === ERROR_CODES.EVENT_IN_THE_PAST) {
        isEventInThePast = true;
    }

    return {
        isAdding,
        isEditing,
        isLoading: isLoading || isLoadingClubs,
        isEventInThePast,
        newEvent,
        clubs,
        event,
        formErrors,
        error,
        errorClubs,
    };
};

const AdminEventAddEditPageTranslated = withTranslation()(
    AdminEventAddEditPage
);
const AdminEventAddEditPageStyled = withStyles(useStyles)(
    AdminEventAddEditPageTranslated
);
const AdminEventAddEditPageState = connect(mapStateToProps, {
    addEvent,
    getEvent,
    editEvent,
    listClubs,
    resetEvent,
})(AdminEventAddEditPageStyled);
const AdminEventAddEditPageRouter = withRouter(AdminEventAddEditPageState);
export { AdminEventAddEditPageRouter as AdminEventAddEditPage };
