import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { urls, getUrl } from 'urls';

import { CardTicket } from 'components';

class CardTicketAdmin extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
        event.preventDefault();
        event.stopPropagation();

        this.props.history.push({
            pathname: getUrl(urls.ADMIN_EVENT_TICKET_DETAIL, {
                eventId: this.props.event.id,
                ticketId: this.props.obj.id,
            }),
            search: '',
        });
    }

    render() {
        const { obj, isLoading } = this.props;

        if (isLoading) {
            return <CardTicket admin isLoading />;
        }

        return (
            <CardTicket
                admin
                ticket={obj}
                onClick={this.handleClick}
                {...this.props}
            />
        );
    }
}

CardTicketAdmin.propTypes = {
    obj: PropTypes.object.isRequiredIf((props) => !props.isLoading),
    event: PropTypes.object.isRequiredIf((props) => !props.isLoading),
    isLoading: PropTypes.bool,
    history: PropTypes.object.isRequired,
};

const CardTicketAdminRouter = withRouter(CardTicketAdmin);

export { CardTicketAdminRouter as CardTicketAdmin };
