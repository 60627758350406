import {
    EVENT_ADD_LOADING,
    EVENT_ADD_SUCCESS,
    EVENT_ADD_FAILED,

    EVENT_LOADING,
    EVENT_LOADING_SUCCESS,
    EVENT_LOADING_FAILED,

    EVENT_EDIT_LOADING,
    EVENT_EDIT_SUCCESS,
    EVENT_EDIT_FAILED,

    EVENT_CLUBS_LOADING,
    EVENT_CLUBS_SUCCESS,
    EVENT_CLUBS_FAILED,

    EVENT_RESET,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    isLoadingClubs: false,
    isAdding: false,
    isEditing: false,
    formErrors: null,
    newEvent: null,
    clubs: [],
    event: null,
    error: null,
    errorClubs: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EVENT_RESET:
            return {
                ...state,
                ...INITIAL_STATE,
            };

        case EVENT_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case EVENT_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                event: action.payload,
            };
        case EVENT_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case EVENT_EDIT_LOADING:
            return {
                ...state,
                isEditing: true,
            };
        case EVENT_EDIT_SUCCESS:
            return {
                ...state,
                // isEditing: false,  // keep loading for redirect
                newEvent: action.payload,
            };
        case EVENT_EDIT_FAILED:
            return {
                ...state,
                isEditing: false,
                error: action.payload,
            };

        case EVENT_ADD_LOADING:
            return {
                ...state,
                isAdding: true,
            };
        case EVENT_ADD_SUCCESS:
            return {
                ...state,
                // isAdding: false,  // keep loading for redirect
                newEvent: action.payload,
            };
        case EVENT_ADD_FAILED:
            return {
                ...state,
                isAdding: false,
                formErrors: action.payload,
            };

        case EVENT_CLUBS_LOADING:
            return {
                ...state,
                isLoadingClubs: true,
            };
        case EVENT_CLUBS_SUCCESS:
            return {
                ...state,
                isLoadingClubs: false,
                clubs: action.payload.results,
            };
        case EVENT_CLUBS_FAILED:
            return {
                ...state,
                isLoadingClubs: false,
                errorClubs: action.payload,
            };

        default:
            return state;
    }
};
