import { resetPassword as apiResetPassword } from 'services/AuthenticationService';

import {
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_LOADING_SUCCESS,
    FORGOT_PASSWORD_LOADING_FAILED,
} from './actionTypes';

export const resetPassword = ({ email }) => {
    return (dispatch) => {
        dispatch({ type: FORGOT_PASSWORD_LOADING });

        apiResetPassword(email)
            .then((result) => {
                dispatch({
                    type: FORGOT_PASSWORD_LOADING_SUCCESS,
                    payload: result,
                });
            })
            .catch((data) => {
                dispatch({
                    type: FORGOT_PASSWORD_LOADING_FAILED,
                    payload: data,
                });
            });
    };
};
