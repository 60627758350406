import {
    RESET_PASSWORD_CONFIRM_LOADING,
    RESET_PASSWORD_CONFIRM_SUCCESS,
    RESET_PASSWORD_CONFIRM_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    isSuccess: false,
    errors: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_PASSWORD_CONFIRM_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case RESET_PASSWORD_CONFIRM_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
        case RESET_PASSWORD_CONFIRM_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
            };

        default:
            return state;
    }
};
