import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Menu } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

class PopupMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
        };

        // event handlers
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
    }

    handleMenuClick(event) {
        event.stopPropagation();
        event.preventDefault();
        // const { anchorEl } = this.state;
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose(event) {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    }

    render() {
        return (
            <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="more"
                    aria-controls="context-menu"
                    aria-haspopup="true"
                    onClick={this.handleMenuClick}
                >
                    {this.props.icon}
                </IconButton>
                <Menu
                    id="context-menu"
                    anchorEl={this.state.anchorEl}
                    // keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                    onBlur={this.handleMenuClose}
                >
                    {this.props.children}
                </Menu>
            </React.Fragment>
        );
    }
}

PopupMenu.defaultProps = {
    icon: <MoreVertIcon />,
};

PopupMenu.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
        .isRequired,
    icon: PropTypes.object.isRequired,
};

export { PopupMenu };
