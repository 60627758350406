import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { urls, getUrl } from 'urls';

import { CardCourse, PopupMenuAdminCourse } from 'components';

const useStyles = (theme) => ({
    // actions: {
    //     position: 'absolute',
    //     top: '16px',
    //     right: '16px',
    //     width: 'auto !important',
    //     [theme.breakpoints.up('md')]: {
    //         top: 'auto',
    //     },
    // },
});

class CardCourseAdmin extends Component {
    constructor(props) {
        super(props);

        // event handlers
        this.renderActions = this.renderActions.bind(this);
    }

    renderLoading() {
        return <CardCourse isLoading />;
    }

    renderActions() {
        const { course, event, isLoading, t, classes } = this.props;

        if (!course || !course.actions || course.actions.length <= 0) {
            return;
        }

        return (
            <div className={'actions'}>
                {course.statistics.subscriptions.all.total >=
                    course.subscriptions_allowed && (
                    <span className="label red">{t('forms:label.full')}</span>
                )}
                {course.subscriptions_closed && (
                    <span className="label red">{t('forms:label.closed')}</span>
                )}
                {course.status !== 'published' && (
                    <span className="label red">
                        {t('forms:label.concept')}
                    </span>
                )}
                <PopupMenuAdminCourse
                    course={course}
                    event={event}
                    isLoading={isLoading}
                />
            </div>
        );
    }

    renderCard() {
        const { course } = this.props;

        return (
            <CardCourse
                name={course.name}
                position={course.position}
                height={course.height}
                time={course.start_hour}
                courseType={course.type_description}
                subscriptionsTotal={course.statistics.subscriptions.all.total}
                subscriptionsMax={course.subscriptions_allowed}
                submissionPrice={
                    course.is_local
                        ? course.submission_price_locally
                        : course.submission_price
                }
                actionUrl={getUrl(urls.ADMIN_EVENT_COURSE_START_LIST, {
                    eventId: this.props.event.id,
                    courseId: course.id,
                })}
                closed={course.subscriptions_closed}
                actions={this.renderActions}
            />
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return <React.Fragment>{this.renderCard()}</React.Fragment>;
    }
}

CardCourseAdmin.propTypes = {
    classes: PropTypes.object.isRequired,
    course: PropTypes.object.isRequiredIf((props) => !props.isLoading),
    event: PropTypes.object.isRequiredIf((props) => !props.isLoading),
    isLoading: PropTypes.bool,
    history: PropTypes.object,
    t: PropTypes.object,
};

const CardCourseAdminTranslated = withTranslation()(CardCourseAdmin);
const CardCourseAdminStyled = withStyles(useStyles)(CardCourseAdminTranslated);
const CardCourseAdminRouter = withRouter(CardCourseAdminStyled);

export { CardCourseAdminRouter as CardCourseAdmin };
