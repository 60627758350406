import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Animated } from 'react-animated-css';

// import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Container, Grid } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

import DOMPurify from 'dompurify';

import logoSvg from 'assets/images/equento-logo.svg';
import './styles.scss';

import PropTypes from 'prop-types';

class TemplateLogin extends Component {
    componentDidMount() {
        document.body.classList.add('login-page'); // eslint-disable-line no-undef
    }

    componentWillUnmount() {
        document.body.classList.remove('login-page'); // eslint-disable-line no-undef
    }

    render() {
        return (
            <Container maxWidth={'lg'} className="template-login-container">
                <Grid container spacing={3} display="flex" alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Animated
                            animationIn="fadeIn"
                            animationOut="fadeOut"
                            isVisible
                        >
                            <Link to="/">
                                <img
                                    src={logoSvg}
                                    alt="Equento"
                                    className="logo"
                                />
                            </Link>
                            <h1
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        this.props.title
                                    ),
                                }}
                            />
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                        this.props.description
                                    ),
                                }}
                            />
                        </Animated>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Animated
                            animationIn="fadeInRight"
                            animationOut="fadeOut"
                            isVisible
                        >
                            <Card elevation={0} className="card">
                                <CardContent className="card-content">
                                    {this.props.children}
                                </CardContent>
                            </Card>
                        </Animated>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

TemplateLogin.propTypes = {
    children: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export { TemplateLogin };
