import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { getUrl, urls } from 'urls';
import { AdminContext } from '../../utils';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import {
    AdminHeader,
    CardList,
    CardOrderAdmin,
    TemplateAdmin,
} from 'components';

import { FilterForm } from './form';

import { listOrders } from './actions';

class AdminOrdersPage extends Component {
    static contextType = AdminContext; // eslint-disable-line react/sort-comp

    constructor(props) {
        super(props);

        // get page from url
        const params = new URLSearchParams(window.location.search);

        // load orders
        this.props.listOrders({ page: params.get('page', 1) });

        // event handlers
        this.handlePage = this.handlePage.bind(this);
        this.handlePaymentStatusChange = this.handlePaymentStatusChange.bind(
            this
        );
    }

    componentDidMount() {
        const { t } = this.props;

        this.context.setAdminTitle(t('common:title.orders'));
        this.context.setAdminBackAction(null);
    }

    handlePage(object, page) {
        this.props.listOrders({ page: page });
    }

    handlePaymentStatusChange(event, value) {
        this.props.listOrders({ page: 1, payment_status: value.props.value });
    }

    render() {
        const {
            t,
            isLoading,
            error,
            totalPages,
            currentPage,
            orders,
        } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <Helmet>
                    <title>
                        {t('common:title.admin.orders')} |{' '}
                        {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(window.location.pathname, {}, true)} // eslint-disable-line no-undef
                    />
                </Helmet>
                <TemplateAdmin header>
                    <AdminHeader
                        title={t('common:title.admin.orders')}
                        breadcrumbs={[
                            {
                                title: t('common:text.menu.orders'),
                                url: getUrl(urls.ADMIN_ORDERS),
                            },
                        ]}
                        removeMargin
                    />
                </TemplateAdmin>

                <TemplateAdmin filter>
                    <FilterForm
                        onPaymentStatusChange={this.handlePaymentStatusChange}
                    />
                </TemplateAdmin>

                <TemplateAdmin>
                    <CardList
                        isLoading={isLoading}
                        objects={orders}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        error={error}
                        card={CardOrderAdmin}
                        handlePage={this.handlePage}
                        useCardAsLoaders
                    />
                </TemplateAdmin>
            </Suspense>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoading,
        orders,
        error,
        currentPage,
        totalPages,
    } = state.pageAdminOrders;

    return {
        isLoading,
        orders,
        error,
        currentPage,
        totalPages,
    };
};

AdminOrdersPage.propTypes = {
    // props
    isLoading: PropTypes.bool,
    orders: PropTypes.object,
    error: PropTypes.string,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    // handlers
    listOrders: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const AdminOrdersPageTranslated = withTranslation()(AdminOrdersPage);
const AdminOrdersPageState = connect(mapStateToProps, {
    listOrders,
})(AdminOrdersPageTranslated);
const AdminOrdersPageRouter = withRouter(AdminOrdersPageState);
export { AdminOrdersPageRouter as AdminOrdersPage };
