import {
    getAdminTicket,
    downloadAdminTicket as apiDownloadTicket,
} from 'services/TicketService';

import { getAdminEvent } from 'services/EventService';

import {
    confirmCombinationAdmin as apiConfirmCombinationAdmin,
    unconfirmCombinationAdmin as apiUnconfirmCombinationAdmin,
} from 'services/CombinationService';

import { listAdminOrders as apiGetAdminOrders } from 'services/OrderService';

import {
    ADMIN_TICKET_LOADING,
    ADMIN_TICKET_SUCCESS,
    ADMIN_TICKET_FAILED,
    ADMIN_TICKET_ORDERS_LOADING,
    ADMIN_TICKET_ORDERS_SUCCESS,
    ADMIN_TICKET_ORDERS_FAILED,
    ADMIN_TICKET_EVENT_LOADING,
    ADMIN_TICKET_EVENT_SUCCESS,
    ADMIN_TICKET_EVENT_FAILED,
    ADMIN_TICKET_COMBINATION_CONFIRM_LOADING,
    ADMIN_TICKET_COMBINATION_CONFIRM_SUCCESS,
    ADMIN_TICKET_COMBINATION_CONFIRM_FAILED,
    ADMIN_TICKET_COMBINATION_UNCONFIRM_LOADING,
    ADMIN_TICKET_COMBINATION_UNCONFIRM_SUCCESS,
    ADMIN_TICKET_COMBINATION_UNCONFIRM_FAILED,
} from './actionTypes';

export const getTicket = (id) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_TICKET_LOADING });

        getAdminTicket(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_TICKET_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_TICKET_FAILED,
                    payload: error,
                });
            });
    };
};

export const listOrders = ({ ticketId, page = 1 }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_TICKET_ORDERS_LOADING });

        apiGetAdminOrders({ ticketId, page })
            .then((result) => {
                dispatch({
                    type: ADMIN_TICKET_ORDERS_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_TICKET_ORDERS_FAILED,
                    payload: error,
                });
            });
    };
};

export const getEvent = (id) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_TICKET_EVENT_LOADING });

        getAdminEvent(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_TICKET_EVENT_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_TICKET_EVENT_FAILED,
                    payload: error,
                });
            });
    };
};

export const confirmCombination = (id) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_TICKET_COMBINATION_CONFIRM_LOADING,
            payload: { id },
        });

        apiConfirmCombinationAdmin(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_TICKET_COMBINATION_CONFIRM_SUCCESS,
                    payload: { id, result },
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_TICKET_COMBINATION_CONFIRM_FAILED,
                    payload: { id, error },
                });
            });
    };
};

export const unconfirmCombination = (id) => {
    return (dispatch) => {
        dispatch({
            type: ADMIN_TICKET_COMBINATION_UNCONFIRM_LOADING,
            payload: { id },
        });

        apiUnconfirmCombinationAdmin(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_TICKET_COMBINATION_UNCONFIRM_SUCCESS,
                    payload: { id, result },
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_TICKET_COMBINATION_UNCONFIRM_FAILED,
                    payload: { id, error },
                });
            });
    };
};

export const downloadTicket = ({ ticketId }) => {
    return (dispatch) => {
        return apiDownloadTicket(ticketId);
    };
};
