import {
    ADMIN_COURSES_LOADING,
    ADMIN_COURSES_LOADING_SUCCESS,
    ADMIN_COURSES_LOADING_FAILED,
    ADMIN_COURSE_RESET_CHANGED,
    ADMIN_COURSE_RESET_CREATED,
    ADMIN_COURSE_RESET_DELETED,
} from "./actionTypes";

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    courses: [],
    total_courses: 0,
    total_pages: 0,
    current_page: 1,
    isCreated: false,
    isChanged: false,
    isDeleted: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_COURSE_RESET_CHANGED:
            return {
                ...state,
                isChanged: false,
            };
        case ADMIN_COURSE_RESET_CREATED:
            return {
                ...state,
                isCreated: false,
            };
        case ADMIN_COURSE_RESET_DELETED:
            return {
                ...state,
                isDeleted: false,
            };

        case ADMIN_COURSES_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ADMIN_COURSES_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                total_courses: action.payload.count,
                total_pages: action.payload.total_pages,
                courses: action.payload.results,
                current_page: action.payload.current_page,
            };
        case ADMIN_COURSES_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};
