import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { EmptyMessage, Toast } from 'components';
import { scrollIntoView } from 'utils';

const useStyles = (theme) => ({
    flex: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(5),
        marginTop: theme.spacing(3),
    },
    removeSpacing: {
        marginBottom: 0,
    },
});

class CardList extends Component {
    constructor(props) {
        super(props);

        this.handlePage = this.handlePage.bind(this);
    }

    handlePage(object, page) {
        const { handlePage, history, location } = this.props;

        if (handlePage) {
            handlePage(object, page);
        }

        scrollIntoView('#top');
        history.push(location.pathname + '?page=' + page);
    }

    renderLoader() {
        const { t, useCardAsLoaders } = this.props;

        if (useCardAsLoaders) {
            return this.renderLoaderCards();
        }

        return (
            <EmptyMessage
                title={t('common:loading.title')}
                description={t('common:loading.description')}
                showLoader
            />
        );
    }

    renderLoaderCards() {
        const { maxLoadingCards } = this.props;
        const Card = this.props.card;

        if (!Card) {
            return;
        }

        return [...new Array(maxLoadingCards || 5)].map((index) => (
            <Card key={index} isLoading />
        ));
    }

    renderPagination() {
        const { classes, totalPages, currentPage, removeSpacing } = this.props;

        if (totalPages <= 0) {
            return;
        }

        return (
            <Grid item xs={12}>
                <div
                    className={classNames(
                        classes.flex,
                        removeSpacing && classes.removeSpacing
                    )}
                >
                    <Pagination
                        count={totalPages}
                        shape="rounded"
                        page={currentPage}
                        onChange={this.handlePage}
                    />
                </div>
            </Grid>
        );
    }

    renderCard(obj, index) {
        const Card = this.props.card;

        if (!Card) {
            return;
        }

        return <Card obj={obj} key={index} />;
    }

    renderList() {
        const { isLoading, error, t, objects } = this.props;

        if (isLoading) {
            return (
                <EmptyMessage
                    showLoader
                    title={t('common:loading.title')}
                    description={t('common:loading.description')}
                />
            );
        }

        if (error) {
            return (
                <React.Fragment>
                    <Toast
                        message={t('common:error.general.description')}
                        opwn
                    />
                    <EmptyMessage
                        title={t('common:no_objects.title')}
                        description={t('common:no_objects.description')}
                    />
                </React.Fragment>
            );
        }

        if (objects.length === 0) {
            return (
                <React.Fragment>
                    <EmptyMessage
                        title={t('common:no_objects.title')}
                        description={t('common:no_objects.description')}
                        textSize={'small'}
                    />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div id="top" />
                <Grid container display="flex" justify="space-between">
                    {objects.map((obj, i) => (
                        <Grid item xs={12} key={i}>
                            {this.renderCard(obj, i)}
                        </Grid>
                    ))}

                    {this.renderPagination()}
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return this.renderLoader();
        }

        return this.renderList();
    }
}

CardList.propTypes = {
    isLoading: PropTypes.bool,
    useCardAsLoaders: PropTypes.bool,
    maxLoadingCards: PropTypes.number,
    error: PropTypes.object,
    objects: PropTypes.array,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    card: PropTypes.object.isRequired,
    handlePage: PropTypes.func.isRequired,
    removeSpacing: PropTypes.bool,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    classes: PropTypes.object,
    t: PropTypes.func.isRequired,
};

const CardListStyled = withStyles(useStyles)(CardList);
const CardListTranslated = withTranslation()(CardListStyled);
const CardListRouter = withRouter(CardListTranslated);

export { CardListRouter as CardList };
