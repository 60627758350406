import {
    USER_UPDATING_LOADING,
    USER_UPDATING_SUCCESS,
    USER_UPDATING_FAILED,
    USER_LOADING,
    USER_SUCCESS,
    USER_FAILED,
    USER_EMAIL_UPDATING_LOADING,
    USER_EMAIL_UPDATING_SUCCESS,
    USER_EMAIL_UPDATING_FAILED,
    USER_PASSWORD_UPDATING_LOADING,
    USER_PASSWORD_UPDATING_SUCCESS,
    USER_PASSWORD_UPDATING_FAILED,
} from './actionTypes';

import {
    getUser as apiGetUser,
    updateUser as apiUpdateUser,
    updateEmail as apiUpdateEmail,
    updatePassword as apiUpdatePassword,
} from 'services/UserService';

export const getUser = () => {
    return (dispatch) => {
        dispatch({ type: USER_LOADING });

        apiGetUser()
            .then((result) => {
                dispatch({
                    type: USER_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_FAILED,
                    payload: error,
                });
            });
    };
};

export const updateUser = (userId, { first_name, last_name }) => {
    return (dispatch) => {
        dispatch({ type: USER_UPDATING_LOADING });

        apiUpdateUser(userId, first_name, last_name)
            .then((result) => {
                dispatch({
                    type: USER_UPDATING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_UPDATING_FAILED,
                    payload: error,
                });
            });
    };
};

export const updateEmail = ({ email, password }) => {
    return (dispatch) => {
        dispatch({ type: USER_EMAIL_UPDATING_LOADING });

        apiUpdateEmail(email, password)
            .then((result) => {
                dispatch({
                    type: USER_EMAIL_UPDATING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_EMAIL_UPDATING_FAILED,
                    payload: error,
                });
            });
    };
};

export const updatePassword = ({
    current_password,
    new_password,
    re_new_password,
}) => {
    return (dispatch) => {
        dispatch({ type: USER_PASSWORD_UPDATING_LOADING });

        apiUpdatePassword(current_password, new_password, re_new_password)
            .then((result) => {
                dispatch({
                    type: USER_PASSWORD_UPDATING_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: USER_PASSWORD_UPDATING_FAILED,
                    payload: error,
                });
            });
    };
};
