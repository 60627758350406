import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import classNames from "classnames";

import { getObjectFromCache, zeroPad, getCountryImage } from "../../utils";

const useStyles = (theme) => ({
    item: {
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        paddingBottom: "16px",
        paddingTop: "16px",
        width: "100%",

        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
        },

        "&:first-child": {
            paddingTop: 0,
        },

        "&:last-child": {
            paddingBottom: 0,
            borderBottomWidth: 0,
        },

        "& div": {
            padding: "0 10px",

            [theme.breakpoints.down("sm")]: {
                padding: "0 0 10px",
            },

            "&:first-child": {
                paddingLeft: 0,
            },

            "&:last-child": {
                paddingRight: 0,
            },
        },
    },
    border: {
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "#e5e5e5",
        padding: 0,
    },
    country: {
        maxWidth: "70px",
        minWidth: "70px",
        width: "70px",

        "& img": {
            borderRadius: "3px",
            width: "50px",
        },
    },
    startNumber: {
        fontWeight: "bold",
        maxWidth: "50px",
        minWidth: "50px",
        width: "50px",
    },
    name: {
        flexBasis: "200%",

        [theme.breakpoints.down("md")]: {},
    },
    labels: {
        paddingBottom: theme.spacing(2) + "px !important",
        paddingTop: "0",
        paddingLeft: "60px !important",

        "& span": {
            marginRight: "10px",
        },
    },
});

class TicketSubscription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            courseName: null,
            course: null,
        };
    }

    componentDidMount() {
        if (this.props.subscription && !this.state.courseName) {
            getObjectFromCache({
                url: this.props.subscription.course,
                success: (result) => {
                    this.setState({
                        courseName: result["name"],
                        course: result,
                    });
                },
                error: () => {
                    // do something TODO
                },
            });
        }
    }

    renderLabels() {
        const { subscription, classes, t } = this.props;

        const entered = subscription.combinations[0].entered_at;
        const ridden = subscription.entered_at;
        const pony = subscription.combinations[0].pony;

        if (!entered && !ridden && !pony) {
            return;
        }

        return (
            <div className={classes.labels}>
                {entered === true && (
                    <span className="label primary">
                        {t("forms:label.entered")}
                    </span>
                )}
                {ridden === true && (
                    <span className="label green">
                        {t("forms:label.ridden")}
                    </span>
                )}
                {pony === true && (
                    <span className="label secondary">
                        {t("forms:label.pony")}
                    </span>
                )}
            </div>
        );
    }

    render() {
        const { subscription, classes } = this.props;
        let country_image = getCountryImage();
        if (subscription.combinations && subscription.combinations.length > 0) {
            country_image = getCountryImage(
                subscription.combinations[0].country
            );
        }

        return (
            <React.Fragment>
                <div className={classes.item}>
                    <div className={classes.startNumber}>
                        {zeroPad(subscription.start_number, 2)}.{" "}
                        <span>
                            {subscription && subscription.out_of_competition
                                ? "BW."
                                : ""}
                        </span>
                    </div>
                    <div className={classes.country}>
                        <img
                            src={country_image}
                            alt="Belgium"
                            aria-label="Flag of Belgium"
                        />
                    </div>
                    <div className={classes.name}>
                        <strong>{`${subscription.combinations[0].first_name} ${subscription.combinations[0].last_name}`}</strong>
                        <br />
                        <small>{subscription.combinations[0].horse_name}</small>
                    </div>
                </div>
                <div className={classNames(classes.item, classes.border)}>
                    {this.renderLabels()}
                </div>
            </React.Fragment>
        );
    }
}

TicketSubscription.propTypes = {
    event: PropTypes.string.isRequired,
    subscription: PropTypes.object.isRequired,
};

const TicketSubscriptionStyled = withStyles(useStyles)(TicketSubscription);
const TicketSubscriptionTranslated = withTranslation()(
    TicketSubscriptionStyled
);

export { TicketSubscriptionTranslated as TicketSubscription };
