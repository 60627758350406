import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, Grid, CircularProgress } from '@material-ui/core';
import { TextInput } from 'components/Forms';

function LoginForm(props) {
    const { register, handleSubmit } = useForm();
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextInput
                        label={t('forms:label.email_address')}
                        name="email"
                        required
                        email
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label={t('forms:label.password')}
                        name="password"
                        password
                        required
                        ref={register({ required: true })}
                    >
                        <Link onClick={props.onForgotPassword}>
                            {t('common:link.forgot_password')}
                        </Link>
                    </TextInput>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Button
                            disabled={props.isLoading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            disableElevation
                            color="secondary"
                        >
                            {!props.isLoggingIn && t('common:button.login')}
                            {props.isLoggingIn && (
                                <CircularProgress size={28} />
                            )}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}

LoginForm.propTypes = {
    // props
    isLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isLoggingIn: PropTypes.bool,
    // event handlers
    onSubmit: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
};

export { LoginForm };
