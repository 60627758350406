import React, { Component, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { getUrl, urls } from 'urls';
import { AdminContext } from '../../utils';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import {
    AdminHeader,
    Button,
    CardList,
    CardClub,
    Toast,
    TemplateAdmin,
} from 'components';

import {
    listClubs,
    resetChanged,
    resetCreated,
    resetDeleted,
    listClubListActions,
} from './actions';

class AdminClubsPage extends Component {
    static contextType = AdminContext; // eslint-disable-line react/sort-comp

    constructor(props) {
        super(props);

        // load clubs and actions
        this.props.listClubs(1);
        this.props.listClubListActions();

        // event handlers
        this.handlePage = this.handlePage.bind(this);
        this.reloadClubs = this.reloadClubs.bind(this);
    }

    componentDidMount() {
        const { t } = this.props;

        this.context.setAdminTitle(t('common:title.clubs'));
        this.context.setAdminBackAction(null);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isCreated !== this.props.isCreated &&
            this.props.isCreated
        ) {
            this.reloadClubs();
        }

        if (
            prevProps.isDeleted !== this.props.isDeleted &&
            this.props.isDeleted
        ) {
            this.reloadClubs();
        }

        if (
            prevProps.isChanged !== this.props.isChanged &&
            this.props.isChanged
        ) {
            this.reloadClubs();
        }
    }

    reloadClubs() {
        const { currentPage } = this.props;

        this.handlePage(null, currentPage);
    }

    handlePage(object, page) {
        this.props.listClubs(page);
    }

    renderActions() {
        const { t, isLoadingActions, errorActions, actions } = this.props;

        if (isLoadingActions) {
            return <CircularProgress size={20} />;
        }

        if (errorActions) {
            return (
                <Toast message={t('common:error.general.description')} open />
            );
        }

        if (actions && actions['create']) {
            // if the user can create stuff
            return (
                <Button
                    color={'secondary'}
                    onClick={() =>
                        this.props.history.push(getUrl(urls.ADMIN_CLUB_ADD))
                    }
                >
                    {t('common:title.admin.club_add')}
                </Button>
            );
        }

        return;
    }

    render() {
        const {
            t,
            isLoading,
            error,
            totalPages,
            currentPage,
            clubs,
            isCreated,
            isChanged,
            isDeleted,
            history,
        } = this.props;

        return (
            <Suspense fallback={<CircularProgress />}>
                <Helmet>
                    <title>
                        {t('common:title.admin.clubs')} |{' '}
                        {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(history.location.pathname, {}, true)}
                    />
                </Helmet>
                <Toast
                    message={t('common:success.general.created_club')}
                    open={isCreated}
                    type="success"
                    onClose={this.props.resetCreated}
                />
                <Toast
                    message={t('common:success.general.changed_club')}
                    open={isChanged}
                    type="success"
                    onClose={this.props.resetChanged}
                />
                <Toast
                    message={t('common:success.general.deleted_club')}
                    open={isDeleted}
                    type="success"
                    onClose={this.props.resetDeleted}
                />
                <TemplateAdmin header>
                    <AdminHeader
                        title={t('common:title.admin.clubs')}
                        actionContainer={this.renderActions()}
                        breadcrumbs={[
                            {
                                title: t('common:text.menu.clubs'),
                                url: getUrl(urls.ADMIN_CLUBS),
                            },
                        ]}
                        removeMargin
                    />
                </TemplateAdmin>

                <TemplateAdmin>
                    <CardList
                        isLoading={isLoading}
                        objects={clubs}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        error={error}
                        card={CardClub}
                        handlePage={this.handlePage}
                        useCardAsLoaders
                    />
                </TemplateAdmin>
            </Suspense>
        );
    }
}

const mapStateToProps = (state) => {
    const {
        isLoading,
        clubs,
        error,
        isChanged,
        isCreated,
        isDeleted,
        currentPage,
        isLoadingActions,
        errorActions,
        actions,
    } = state.pageAdminClubs;

    return {
        isLoading: isLoading || isLoadingActions,
        clubs,
        error,
        isChanged,
        isCreated,
        isDeleted,
        currentPage,
        isLoadingActions,
        errorActions,
        actions,
    };
};

AdminClubsPage.propTypes = {
    // props
    isCreated: PropTypes.bool,
    isDeleted: PropTypes.bool,
    isChanged: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingActions: PropTypes.bool,
    error: PropTypes.string,
    errorActions: PropTypes.string,
    actions: PropTypes.object,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    clubs: PropTypes.object,
    // events
    listClubs: PropTypes.func.isRequired,
    listClubListActions: PropTypes.func.isRequired,
    resetCreated: PropTypes.func.isRequired,
    resetChanged: PropTypes.func.isRequired,
    resetDeleted: PropTypes.func.isRequired,
    // other
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

const AdminClubsPageTranslated = withTranslation()(AdminClubsPage);
const AdminClubsPageState = connect(mapStateToProps, {
    listClubs,
    listClubListActions,
    resetChanged,
    resetCreated,
    resetDeleted,
})(AdminClubsPageTranslated);
const AdminClubsPageRouter = withRouter(AdminClubsPageState);
export { AdminClubsPageRouter as AdminClubsPage };
