import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getUrl, urls } from 'urls';
import EventService from 'lib/EventService';
import { setItem, getItem } from 'services/StorageService';
import classNames from 'classnames';
import { EVENT } from 'enums';

import { Drawer, Hidden, Tabs, Tab } from '@material-ui/core';
import { MenuOpenOutlined as MenuOpenIcon } from '@material-ui/icons';
import { TabPanel } from 'components';
import { AdminPages } from './pages';
import logoSvg from 'assets/images/equento-logo.svg';
import { routes } from './routes';

const useStyles = (theme) => ({
    tabs: {
        // borderRightWidth: '1px',
        // borderRightStyle: 'solid',
        // borderRightColor: theme.colors.bone,
        // margin: '120px 0 40px 0',
        margin: '8px 0',

        '@media (min-height: 400px)': {
            margin: '40px 0',
        },
    },
    tab: {
        fontWeight: 'bold',
        minHeight: '55px',
        padding: '0 25px',
        textTransform: 'none',
        textAlign: 'left',
        zIndex: 2,

        '& .MuiTab-wrapper': {
            alignItems: 'flex-start',
            flexDirection: 'row',
            justifyContent: 'flex-start',

            '& svg': {
                marginBottom: '0 !important',
                marginRight: '10px',
            },
        },
    },
    // container: {
    //     marginLeft: '125px',
    //
    //     [theme.breakpoints.down(1500)]: {
    //         marginLeft: '250px',
    //         width: 'auto',
    //     },
    //
    //     [theme.breakpoints.down('sm')]: {
    //         marginLeft: 0,
    //         marginRight: 0,
    //         width: '100%',
    //     }
    // },
    content: {
        marginLeft: '15px',
    },
    drawer: {
        justifyContent: 'space-between',
    },
    drawerOpen: {
        width: '205px',
        zIndex: 1250,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClosed: {
        width: '80px',
        zIndex: 1250,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuOpenIcon: {
        color: theme.colors.mikado,
        margin: '10px',
        opacity: 0.7,
        padding: '16px',

        '& svg': {
            color: theme.colors.mikado,
            fill: theme.colors.mikado,
        },
    },
    logo: {
        display: 'inline-block',
        // height: '30px',
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(4.5),
    },
    indicator: {
        borderRadius: '5px',
        left: '10px',
        right: '10px',
        width: 'calc(100% - 20px)',
        zIndex: 0,
    },
});

class AdminPage extends Component {
    constructor(props) {
        super(props);

        let selectedTab = getUrl(urls.ADMIN_DASHBOARD);
        let subpage = props.match.params.subpage;

        if (subpage) {
            selectedTab = getUrl(urls.ADMIN_SUBPAGE, { subpage: subpage });
        }

        // state
        this.state = {
            drawerOpen: getItem('adminDrawerOpen', true) === 'true',
            selectedTab: selectedTab,
        };

        // event handlers
        this.handleChange = this.handleChange.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
    }

    componentDidMount() {
        EventService.trigger(EVENT.ADMIN_DRAWER_UPDATED, {
            drawerOpen: this.state.drawerOpen,
        });
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.match.params.subpage !== this.props.match.params.subpage
        ) {
            this.setState({
                selectedTab: '/admin/' + this.props.match.params.subpage,
            });
        }
    }

    getProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    handleChange(event, value) {
        this.setState({
            selectedTab: value,
        });
        this.props.history.push(value);
    }

    handleMenuOpen(event) {
        event.preventDefault();
        event.stopPropagation();

        const newValue = !this.state.drawerOpen;

        this.setState({
            drawerOpen: newValue,
        });
        setItem('adminDrawerOpen', newValue);

        EventService.trigger(EVENT.ADMIN_DRAWER_UPDATED, {
            drawerOpen: newValue,
        });
    }

    render() {
        const { classes } = this.props;
        const { selectedTab } = this.state;

        return (
            <React.Fragment>
                <Hidden smDown>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(
                                classes.drawer,
                                this.state.drawerOpen === true
                                    ? classes.drawerOpen
                                    : classes.drawerClosed
                            ),
                        }}
                        className={classNames(
                            this.state.drawerOpen === true
                                ? classes.drawerOpen
                                : classes.drawerClosed
                        )}
                    >
                        <Link to="/" className={classes.logo}>
                            <img src={logoSvg} alt="Equento" className="logo" />
                        </Link>
                        <Tabs
                            orientation={'vertical'}
                            value={selectedTab}
                            onChange={this.handleChange}
                            variant="scrollable"
                            scrollButton="auto"
                            classes={{
                                root: classes.tabs,
                                indicator: classes.indicator,
                            }}
                        >
                            {routes.map((obj, index) => (
                                <Tab
                                    key={index}
                                    classes={{ root: classes.tab }}
                                    value={obj.path}
                                    label={this.state.drawerOpen && obj.name}
                                    icon={obj.icon}
                                    {...this.getProps(index)}
                                />
                            ))}
                        </Tabs>

                        <Link
                            onClick={this.handleMenuOpen}
                            className={classes.menuOpenIcon}
                        >
                            <MenuOpenIcon classes={classes.icon} />
                        </Link>
                    </Drawer>
                </Hidden>

                {routes.map((obj, index) => {
                    return (
                        <TabPanel value={selectedTab} index={obj.path}>
                            <AdminPages
                                isLoggedIn={this.props.isLoggedIn}
                                path={obj.path}
                            />
                        </TabPanel>
                    );
                })}
            </React.Fragment>
        );
    }
}

AdminPage.propTypes = {
    // props
    isLoggedIn: PropTypes.bool,
    children: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    const { isLoggedIn } = state.authentication;

    return {
        isLoggedIn,
    };
};

const AdminPageTranslated = withTranslation()(AdminPage);
const AdminPageRouter = withRouter(AdminPageTranslated);
const AdminPageStyled = withStyles(useStyles)(AdminPageRouter);
const AdminPageState = connect(mapStateToProps, {})(AdminPageStyled);

export { AdminPageState as AdminPage };
