import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { dynamicInjectReducer } from 'reducers/InjectReducer';
import { connect } from 'react-redux';
import { LaunchOutlined as LaunchOutlineIcon } from '@material-ui/icons';

import { urls, getUrl } from 'urls';
import PropTypes from 'prop-types';
import Reducer from './reducer';
import AlertService from 'lib/AlertService';

import { deleteEvent, copyEvent } from './actions';

import { DialogDelete, PopupMenu, PopupMenuItem } from 'components';

class PopupMenuAdminEvent extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            eventId: this.props.match.params.eventId,
            dialogDeleteOpen: false,
            snack: undefined,
        };

        // event handlers
        this.handleDialogDeleteOpen = this.handleDialogDeleteOpen.bind(this);
        this.handleDialogDeleteClose = this.handleDialogDeleteClose.bind(this);
        this.handleChangeEvent = this.handleChangeEvent.bind(this);
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
        this.handleViewEvent = this.handleViewEvent.bind(this);
        this.handleCopyEvent = this.handleCopyEvent.bind(this);
        this.handleViewPublicEvent = this.handleViewPublicEvent.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { t, history } = this.props;

        // deleting
        if (prevProps.isDeleted !== this.props.isDeleted) {
            if (this.props.isDeleted) {
                this.handleDialogDeleteClose();
                history.push(getUrl(urls.ADMIN_EVENTS));
            }
        }

        // copying
        if (prevProps.isEventCopied !== this.props.isEventCopied) {
            if (this.props.isEventCopied) {
                // show message and refresh the page
                AlertService.showSuccess({
                    description: t('common:success.general.copy_event'),
                });

                this.props.closeSnackbar(this.state.snack);

                history.push(getUrl(urls.ADMIN_EVENTS));
            }
        }
    }

    handleViewPublicEvent() {
        const { event } = this.props;

        window.open(getUrl(urls.EVENT_DETAIL, { eventId: event.id }), '_blank'); // eslint-disable-line
    }

    handleDeleteEvent(event) {
        event.preventDefault();
        event.stopPropagation();

        if (event) {
            this.props.deleteEvent({ id: this.props.event.id });
        }
    }

    handleViewEvent() {
        const { history, event } = this.props;

        history.push(getUrl(urls.ADMIN_EVENT_DETAIL, { eventId: event.id }));
    }

    handleChangeEvent(event) {
        event.preventDefault();
        event.stopPropagation();

        const { history } = this.props;

        history.push(
            getUrl(urls.ADMIN_EVENT_CHANGE, { eventId: this.props.event.id })
        );
    }

    handleCopyEvent(event) {
        event.preventDefault();
        event.stopPropagation();

        const snack = this.props.enqueueSnackbar(
            this.props.t('common:text.copying_event')
        );

        this.setState({
            snack: snack,
        });

        this.props.copyEvent({ id: this.props.event.id });
    }

    handleDialogDeleteOpen(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            dialogDeleteOpen: true,
        });
    }

    handleDialogDeleteClose(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            dialogDeleteOpen: false,
        });
    }

    renderDialogDelete() {
        const { isLoading, event } = this.props;

        if (isLoading || !event) {
            return;
        }

        return (
            <DialogDelete
                open={this.state.dialogDeleteOpen}
                onClose={this.handleDialogDeleteClose}
                action={this.handleDeleteEvent}
                isDeleting={this.props.isDeleting}
            />
        );
    }

    render() {
        const { t, event, isViewedOnDetailPage, isLoading } = this.props;

        if (isLoading) {
            return;
        }

        return (
            <React.Fragment>
                <PopupMenu>
                    {event?.actions['change'] && [
                        <PopupMenuItem
                            key={'edit'}
                            action={this.handleChangeEvent}
                            label={t('common:text.edit_event')}
                        />,
                        <PopupMenuItem
                            key={'copy'}
                            action={this.handleCopyEvent}
                            label={t('common:text.copy_event')}
                        />,
                    ]}
                    {!isViewedOnDetailPage && (
                        <PopupMenuItem
                            key={'view'}
                            action={this.handleViewEvent}
                            label={t('common:text.view_event')}
                        />
                    )}
                    {event?.actions['delete'] && (
                        <PopupMenuItem
                            key={'remove'}
                            action={this.handleDialogDeleteOpen}
                            label={t('common:text.remove_event')}
                            type={'destroy'}
                        />
                    )}

                    <PopupMenuItem
                        key={'view-public'}
                        action={this.handleViewPublicEvent}
                        label={t('common:text.view_event_public')}
                        icon={LaunchOutlineIcon}
                    />
                </PopupMenu>

                {this.renderDialogDelete()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.reducerKey || !state[ownProps.reducerKey]) {
        return {};
    }

    const {
        isDeleting,
        isDeleted,
        error,
        isCopyingEvent,
        isEventCopied,
        errorCopying,
    } = state[ownProps.reducerKey];

    return {
        isDeleting,
        isDeleted,
        error,
        isCopyingEvent,
        isEventCopied,
        errorCopying,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        deleteEvent: ({ id }) =>
            dispatch(deleteEvent({ id, key: ownProps.reducerKey })),
        copyEvent: ({ id }) =>
            dispatch(copyEvent({ id, key: ownProps.reducerKey })),
    };
};

PopupMenuAdminEvent.defaultProps = {
    isViewedOnDetailPage: false,
};

PopupMenuAdminEvent.propTypes = {
    // props
    isDeleted: PropTypes.bool,
    isEventCopied: PropTypes.bool,
    isViewedOnDetailPage: PropTypes.bool,
    isLoading: PropTypes.bool,
    isDeleting: PropTypes.bool,
    isCopyingEvent: PropTypes.bool,
    event: PropTypes.object.isRequiredIf((props) => !props.isLoading),
    // handlers
    deleteEvent: PropTypes.func.isRequired,
    copyEvent: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    // other
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
};

const PopupMenuAdminEventTranslated = withTranslation()(PopupMenuAdminEvent);
const PopupMenuAdminEventRouter = withRouter(PopupMenuAdminEventTranslated);
const PopupMenuAdminEventState = connect(
    mapStateToProps,
    mapDispatchToProps
)(PopupMenuAdminEventRouter);
const PopupMenuAdminEventReduced = dynamicInjectReducer({
    key: `popupMenuAdminEvent`,
    reducer: Reducer,
})(PopupMenuAdminEventState);
const PopupMenuAdminEventSnackbar = withSnackbar(PopupMenuAdminEventReduced);

export { PopupMenuAdminEventSnackbar as PopupMenuAdminEvent };
