import format from 'string-format';
import fetchApi from './api';

const endPoints = {
    list: '/v1/events/',
    futureList: '/v1/events/future/',
    detail: '/v1/events/{id}/',
};

const adminEndPoints = {
    list: '/v1/admin/events/',
    detail: '/v1/admin/events/{id}/',
    add: '/v1/admin/events/',
    edit: '/v1/admin/events/{id}/',
    delete: '/v1/admin/events/{id}/',
    export: '/v1/admin/events/{id}/export/',
    copy: '/v1/admin/events/{id}/copy/',
    statistics: '/v1/admin/events/{id}/statistics/',
};

export const listPublicEvents = ({
    page,
    dateMin = undefined,
    dateMax = undefined,
}) =>
    fetchApi({
        url: endPoints.list,
        data: {
            page,
            date_min: dateMin,
            date_max: dateMax,
        },
        method: 'GET',
        useAccessToken: false,
    });

export const listPublicFutureEvents = ({
    page,
    dateMin = undefined,
    dateMax = undefined,
}) =>
    fetchApi({
        url: endPoints.futureList,
        data: {
            page,
            date_min: dateMin,
            date_max: dateMax,
        },
        method: 'GET',
        useAccessToken: false,
    });

export const getPublicEvent = (id) =>
    fetchApi({
        url: format(endPoints.detail, { id }),
        method: 'GET',
        useAccessToken: false,
    });

export const listAdminEvents = ({ page, status = undefined }) =>
    fetchApi({
        url: adminEndPoints.list,
        data: {
            page,
            status,
        },
        method: 'GET',
    });

export const listAdminEventListActions = () =>
    fetchApi({
        url: adminEndPoints.list,
        method: 'OPTIONS',
    });

export const getAdminEvent = (id) =>
    fetchApi({
        url: format(adminEndPoints.detail, { id }),
        method: 'GET',
    });

export const addAdminEvent = (data) =>
    fetchApi({
        url: adminEndPoints.add,
        data: data,
        method: 'POST',
    });

export const editAdminEvent = (id, data) =>
    fetchApi({
        url: format(adminEndPoints.edit, { id }),
        data: data,
        method: 'PATCH',
    });

export const deleteAdminEvent = (id) =>
    fetchApi({
        url: format(adminEndPoints.delete, { id }),
        method: 'DELETE',
    });

export const exportEvent = ({ id, fileType, showUnpaid }) =>
    fetchApi({
        url: format(adminEndPoints.export, { id }),
        data: { type: fileType, show_unpaid: showUnpaid },
        method: 'POST',
        responseType: 'arraybuffer',
        exposeHeaders: true,
    });

export const exportToCSV = (id) =>
    fetchApi({
        url: format(adminEndPoints.export, { id }),
        data: { type: 'csv' },
        method: 'POST',
        responseType: 'arraybuffer',
        exposeHeaders: true,
    });

export const exportToExcel = (id) =>
    fetchApi({
        url: format(adminEndPoints.export, { id }),
        data: {
            type: 'excel',
            show_unpaid: true,
        },
        method: 'POST',
        responseType: 'arraybuffer',
        exposeHeaders: true,
    });

export const exportToReceipts = (id) =>
    fetchApi({
        url: format(adminEndPoints.export, { id }),
        data: {
            type: 'receipts',
            show_unpaid: true,
        },
        method: 'POST',
        responseType: 'arraybuffer',
        exposeHeaders: true,
    });

export const exportToStartLists = (id) =>
    fetchApi({
        url: format(adminEndPoints.export, { id }),
        data: {
            type: 'start-list',
            show_unpaid: true,
        },
        method: 'POST',
        responseType: 'arraybuffer',
        exposeHeaders: true,
    });

export const getEventStatistics = (id) =>
    fetchApi({
        url: format(adminEndPoints.statistics, { id }),
        method: 'GET',
        useAccessToken: true,
    });

export const copyAdminEvent = ({ id }) =>
    fetchApi({
        url: format(adminEndPoints.copy, { id }),
        method: 'POST',
    });
