export const CLUB_ADD_LOADING = 'club_add_loading';
export const CLUB_ADD_SUCCESS = 'club_add_success';
export const CLUB_ADD_FAILED = 'club_add_failed';

export const CLUB_LOADING = 'club_loading';
export const CLUB_LOADING_SUCCESS = 'club_loading_success';
export const CLUB_LOADING_FAILED = 'club_loading_failed';

export const CLUB_EDIT_LOADING = 'club_edit_loading';
export const CLUB_EDIT_SUCCESS = 'club_edit_success';
export const CLUB_EDIT_FAILED = 'club_edit_failed';

export const CLUB_RESET = 'club_reset';
