import { registerUser as apiRegisterUser } from 'services/AuthenticationService';
import moment from 'moment';

import {
    REGISTER_USER_LOADING,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
} from './actionTypes';

export const registerUser = ({
    first_name,
    last_name,
    email,
    password,
    agreed_to_terms,
    date_of_birth,
    gender,
    vat_number,
    company_name,
    address,
    country,
}) => {
    return (dispatch) => {
        dispatch({ type: REGISTER_USER_LOADING });

        let convertedDate = '';
        if (date_of_birth) {
            convertedDate = moment(date_of_birth, 'DD-MM-YYYY').format(
                'YYYY-MM-DD'
            );
        }

        apiRegisterUser({
            first_name,
            last_name,
            email,
            password,
            agreed_to_terms,
            date_of_birth: convertedDate,
            gender,
            vat_number,
            company_name,
            address,
            country: country,
        })
            .then((result) => {
                dispatch({
                    type: REGISTER_USER_SUCCESS,
                    payload: result,
                });
            })
            .catch((data) => {
                dispatch({
                    type: REGISTER_USER_FAILED,
                    payload: data,
                });
            });
    };
};
