import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Grid, MenuItem } from '@material-ui/core';

import { Select } from 'components';

const useStyles = makeStyles((theme) => ({
    noMargin: {
        marginBottom: '0 !important',
    },
}));

function FilterForm(props) {
    const { control, setValue, register } = useForm({
        defaultValues: { status: 'all' },
    });
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <form>
            <Grid container>
                <Grid item xs={12}>
                    <Select
                        label={t('common:labels.status')}
                        name="status"
                        control={control}
                        setValue={setValue}
                        className={classes.noMargin}
                        onChange={props.onStatusChange}
                        ref={register}
                    >
                        <MenuItem value={'all'}>
                            {t('forms:select.status.visibility.all')}
                        </MenuItem>
                        <MenuItem value={'draft'}>
                            {t('forms:select.status.visibility.draft')}
                        </MenuItem>
                        <MenuItem value={'published'}>
                            {t('forms:select.status.visibility.published')}
                        </MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </form>
    );
}

FilterForm.propTypes = {
    onStatusChange: PropTypes.func.isRequired,
};

export { FilterForm };
