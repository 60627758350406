import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import moment from 'moment';
import { urls, getUrl } from 'urls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';
import { groupSubscriptionsByCourse } from 'utils';

import {
    CropFreeOutlined as CropFreeOutlinedIcon,
    CloudDownloadOutlined as CloudDownloadOutlinedIcon,
} from '@material-ui/icons';

import { OrderRow } from './components/OrderRow';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CancelOutlined as CancelOutlinedIcon } from '@material-ui/icons';
import {
    AdminHeader,
    Button,
    CardList,
    Checkbox,
    Chip,
    DialogQR,
    DownloadFile,
    EmptyMessage,
    PopupMenu,
    PopupMenuItem,
    ShoppingCartItem,
    TemplateAdmin,
    Toast,
} from 'components';
import { AdminContext, getCountryImage } from '../../utils';
import { ActionForm } from './forms';

import {
    downloadTicket,
    getTicket,
    getEvent,
    listOrders,
    confirmCombination,
    unconfirmCombination,
} from './actions';

const useStyles = (theme) => ({
    border: {
        borderBottomColor: theme.colors.bone,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        marginBottom: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    },
    avatar: {
        height: theme.spacing(8),
        width: theme.spacing(8),
        marginBottom: theme.spacing(3),
    },
    userContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        paddingTop: theme.spacing(3),
    },
    userDetails: {
        width: '100%',

        '& strong': {
            display: 'block',
        },
    },
    skeleton: {
        display: 'inline-block',
        transform: 'none',
        marginBottom: '12px',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    skeletonAvatar: {
        marginBottom: '32px',
    },
    subscription: {
        borderBottomColor: theme.colors.mercury,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        padding: '16px 0',
    },
    first: {
        paddingLeft: theme.spacing(4),
    },
    last: {
        paddingRight: theme.spacing(4),
    },
    amount: {
        textAlign: 'right',
    },
    name: {
        '& strong': {
            display: 'block',
        },
    },
    textRight: {
        textAlign: 'right',
    },
    spacer: {
        marginTop: theme.spacing(3),
    },
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    country: {
        borderRadius: '5px',
        width: '48px',
    },
    lines: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        borderBottomColor: theme.colors.bone,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',

        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
    },
    checkedRow: {
        background: theme.colors.springWood,
    },
    groupActions: {
        borderTopColor: theme.colors.bone,
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        marginTop: theme.spacing(2),
        paddingLeft: 0,
        paddingRight: 0,

        '& div.MuiCardContent-root': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    rider: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',

        '& > div': {
            marginLeft: theme.spacing(2),
        },
    },
    labels: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(3),
        textAlign: 'left',

        [theme.breakpoints.up('sm')]: {
            paddingBottom: 0,
            paddingTop: 0,
            textAlign: 'right',
        },
    },
    link: {
        color: theme.colors.mikado + ' !important',

        '&:hover': {
            textDecoration: 'none',
        },
    },
});

class AdminTicketPage extends Component {
    static contextType = AdminContext; // eslint-disable-line

    constructor(props) {
        super(props);

        // state
        this.state = {
            ticketId: this.props.match.params.ticketId,
            eventId: this.props.match.params.eventId,
            dialogQROpen: false,
            combinationsChecked: {},
            showGlobalActions: false,
            error: false,
            errorMessage: undefined,
            isSuccess: false,
            isDownloadingTicket: false,
            resetSelectAll: false,
        };

        // event handlers
        this.handleQRcode = this.handleQRcode.bind(this);
        this.handleQRcodeClose = this.handleQRcodeClose.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleCombinationSelect = this.handleCombinationSelect.bind(this);
        this.handleGroupAction = this.handleGroupAction.bind(this);
        this.handleToastClose = this.handleToastClose.bind(this);
        this.handleChangeCombinations = this.handleChangeCombinations.bind(
            this
        );
        this.handleResetSelectAll = this.handleResetSelectAll.bind(this);
        this.handleOrderPage = this.handleOrderPage.bind(this);
        this.handleDownloadTicket = this.handleDownloadTicket.bind(this);
    }

    componentDidMount() {
        const { t } = this.props;

        this.context.setAdminTitle(t('common:title.ticket_detail'));
        this.context.setAdminBackAction(() =>
            this.props.history.push(
                getUrl(urls.ADMIN_EVENT_DETAIL, { eventId: this.state.eventId })
            )
        );

        // load objects
        this.props.getTicket(this.state.ticketId);
        this.props.getEvent(this.state.eventId);
        this.props.listOrders({ ticketId: this.state.ticketId });
    }

    componentDidUpdate(prevProps) {
        this.handleChangeCombinations(
            prevProps.unconfirmCombinations,
            this.props.unconfirmCombinations
        );
        this.handleChangeCombinations(
            prevProps.confirmCombinations,
            this.props.confirmCombinations
        );
    }

    handleDownloadTicket() {
        this.setState({ isDownloadingTicket: true });
    }

    handleOrderPage(object, page) {
        this.props.listOrders({ ticketId: this.state.ticketId, page });
    }

    handleResetSelectAll() {
        this.setState({
            resetSelectAll: false,
        });
    }

    handleChangeCombinations(oldCombinations, currentCombinations) {
        if (oldCombinations !== currentCombinations) {
            let error = false;
            let success = false;
            let msg;

            Object.keys(currentCombinations).map((key, index) => {
                if (currentCombinations[key].error) {
                    error = true;
                    msg = currentCombinations[key].error;
                }

                if (currentCombinations[key].isSuccess) {
                    success = true;
                }

                return undefined;
            });

            if (error) {
                this.setState({
                    error: error,
                    errorMessage: msg,
                });
            }

            if (success) {
                this.setState({
                    isSuccess: true,
                    resetSelectAll: true,
                    showGlobalActions: false,
                });

                // deselect all
                this.handleSelectAll(false);
            }
        }
    }

    handleToastClose() {
        this.setState({
            error: false,
            errorMessage: undefined,
        });
    }

    handleQRcode(event) {
        event.stopPropagation();
        event.preventDefault();

        this.setState({
            dialogQROpen: true,
        });
    }

    handleQRcodeClose() {
        this.setState({
            dialogQROpen: false,
        });
    }

    handleGroupAction(event) {
        const action = event.target.value;

        if (action === 'confirm') {
            Object.entries(this.state.combinationsChecked).map(
                ([key, value]) => {
                    if (value) {
                        this.props.confirmCombination(key);
                    }
                    return undefined;
                }
            );
        } else if (action === 'unconfirm') {
            Object.entries(this.state.combinationsChecked).map(
                ([key, value]) => {
                    if (value) {
                        this.props.unconfirmCombination(key);
                    }
                    return undefined;
                }
            );
        }
    }

    handleSelectAll(checked) {
        const { ticket } = this.props;
        const combinations = {};

        Object.entries(ticket.subscriptions).map(([key, value]) => {
            combinations[value.combinations[0].id] = checked;
            return undefined;
        });

        this.setState({
            combinationsChecked: Object.assign({}, combinations),
            showGlobalActions: checked,
        });
    }

    handleCombinationSelect(event) {
        const name = event.target.name.split('-')[1];
        const checked = event.target.checked;
        const combinationsChecked = this.state.combinationsChecked;
        combinationsChecked[name] = checked;

        this.setState({
            combinationsChecked: Object.assign({}, combinationsChecked),
            showGlobalActions:
                Object.values(this.state.combinationsChecked).indexOf(true) >
                -1,
        });
    }

    renderFileDownload() {
        return (
            <DownloadFile
                onStart={() => this.setState({ isDownloadingTicket: true })}
                onFinish={() => this.setState({ isDownloadingTicket: false })}
                onError={() => {
                    AlertService.showError({
                        description: TranslationUtil.getTranslations().t(
                            'common:error.download_ticket'
                        ),
                    });
                }}
                onSuccess={() => {
                    AlertService.showSuccess({
                        description: TranslationUtil.getTranslations().t(
                            'common:success.download_ticket'
                        ),
                    });
                }}
                action={async () =>
                    this.props.downloadTicket({
                        ticketId: this.props.ticket.id,
                    })
                }
                start={this.state.isDownloadingTicket}
            />
        );
    }

    renderActions() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <PopupMenu>
                    <PopupMenuItem
                        action={this.handleQRcode}
                        label={t('forms:label.show_qr_code')}
                        icon={CropFreeOutlinedIcon}
                    />
                    <PopupMenuItem
                        action={this.handleDownloadTicket}
                        label={t('common:button.download_ticket')}
                        icon={CloudDownloadOutlinedIcon}
                        isLoading={this.state.isDownloadingTicket}
                    />
                </PopupMenu>
                {this.renderFileDownload()}
            </React.Fragment>
        );
    }

    renderHeader() {
        const {
            ticket,
            isLoading,
            t,
            isLoadingEvent,
            event,
            errorEvent,
        } = this.props;

        if (isLoading) {
            return (
                <AdminHeader
                    withTitle
                    withDescription
                    withBreadcrumbs
                    isLoading
                    variant={'small'}
                />
            );
        }

        return (
            <AdminHeader
                breadcrumbs={[
                    {
                        title: t('common:text.menu.events'),
                        url: urls.ADMIN_EVENTS,
                    },
                    {
                        title:
                            isLoadingEvent || errorEvent
                                ? t('common:text.menu.event')
                                : event.name,
                        url: getUrl(urls.ADMIN_EVENT_DETAIL, {
                            eventId: this.state.eventId,
                        }),
                    },
                    {
                        title: `${t('common:text.menu.ticket')} #${ticket.id}`,
                        url: getUrl(urls.ADMIN_EVENT_TICKET_DETAIL, {
                            eventId: this.state.eventId,
                            tiketId: this.state.ticketId,
                        }),
                    },
                ]}
                title={`${t('common:text.menu.ticket')} #${ticket.id}`}
                variant={'small'}
                removeMargin
                actionContainer={this.renderActions()}
            />
        );
    }

    renderNotFound() {
        const { t } = this.props;

        return (
            <EmptyMessage
                icon={<CancelOutlinedIcon />}
                title={t('common:title.not_found')}
                description={t('common:description.ticket.not_found')}
            />
        );
    }

    renderUser() {
        const { ticket, isLoading, classes } = this.props;

        if (isLoading) {
            return (
                <div className={classes.userContainer}>
                    <Skeleton
                        height={64}
                        width={64}
                        className={classNames(
                            classes.skeleton,
                            classes.skeletonAvatar
                        )}
                    />

                    <div className={classes.userDetails}>
                        <Skeleton
                            height={24}
                            width={'80%'}
                            className={classes.skeleton}
                        />
                        <Skeleton
                            height={18}
                            width={'60%'}
                            className={classes.skeleton}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.userContainer}>
                <Avatar variant={'rounded'} className={classes.avatar}>
                    {ticket.user.first_name[0]}
                    {ticket.user.last_name[0]}
                </Avatar>

                <div className={classes.userDetails}>
                    <strong>
                        {ticket.user.first_name} {ticket.user.last_name}
                    </strong>
                    <small>{ticket.user.email}</small>
                </div>
            </div>
        );
    }

    renderSubscriptions() {
        const {
            isLoading,
            subscriptions,
            classes,
            t,
            confirmCombinations,
            unconfirmCombinations,
        } = this.props;

        if (isLoading) {
            return (
                <React.Fragment>
                    <CardContent>
                        <div className={classes.subscriptionsContainer}>
                            {[...new Array(3)].map((key) => (
                                <ShoppingCartItem
                                    key={key}
                                    isLoading
                                    hideActions
                                />
                            ))}
                        </div>
                    </CardContent>
                </React.Fragment>
            );
        }

        const groupedSubscriptions = groupSubscriptionsByCourse(subscriptions);

        return (
            <React.Fragment>
                <Grid
                    container
                    className={classNames(classes.lines, classes.groupActions)}
                >
                    <ActionForm
                        onAction={this.handleGroupAction}
                        onSelectAll={this.handleSelectAll}
                        showGlobalActions={this.state.showGlobalActions}
                        onResetted={this.handleResetSelectAll}
                        reset={this.state.resetSelectAll}
                    />
                </Grid>
                <div className={classes.subscriptionsContainer}>
                    {Object.entries(groupedSubscriptions).map(
                        ([key, value]) => {
                            return (
                                <React.Fragment>
                                    <Grid
                                        container
                                        display={'flex'}
                                        alignItems={'center'}
                                        className={classes.lines}
                                    >
                                        <Grid item xs={12}>
                                            <strong>
                                                {t('common:title.course') +
                                                    ' ' +
                                                    value.course_object
                                                        ?.position +
                                                    '. ' +
                                                    value.course_object?.name +
                                                    ' - ' +
                                                    value.course_object
                                                        ?.height +
                                                    'cm.'}
                                            </strong>
                                        </Grid>
                                    </Grid>
                                    {value.subscriptions.map((obj, i) => {
                                        const countryImage = getCountryImage(
                                            obj.combinations[0].country
                                        );
                                        const combinationId =
                                            obj.combinations[0].id;

                                        return (
                                            <Grid
                                                container
                                                display={'flex'}
                                                alignItems={'center'}
                                                className={classNames(
                                                    classes.lines,
                                                    this.state
                                                        .combinationsChecked[
                                                        obj.combinations[0].id
                                                    ] && classes.checkedRow
                                                )}
                                            >
                                                <Grid item sm={1} xs={2}>
                                                    <Checkbox
                                                        name={`combination-${combinationId}`}
                                                        removeSpacing
                                                        onChange={
                                                            this
                                                                .handleCombinationSelect
                                                        }
                                                        checked={
                                                            this.state
                                                                .combinationsChecked[
                                                                combinationId
                                                            ] ?? false
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item sm={1} xs={10}>
                                                    {obj.start_number}{' '}
                                                    {obj.out_of_competition &&
                                                        t(
                                                            'forms:label.out_of_competition_short'
                                                        )}
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={7}
                                                    xs={12}
                                                    className={classes.rider}
                                                >
                                                    <img
                                                        src={countryImage}
                                                        alt={''}
                                                        className={
                                                            classes.country
                                                        }
                                                    />
                                                    <div>
                                                        <strong>
                                                            {
                                                                obj
                                                                    .combinations[0]
                                                                    .first_name
                                                            }{' '}
                                                            {
                                                                obj
                                                                    .combinations[0]
                                                                    .last_name
                                                            }
                                                        </strong>
                                                        <br />
                                                        <small>
                                                            {
                                                                obj
                                                                    .combinations[0]
                                                                    .horse_name
                                                            }
                                                        </small>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={3}
                                                    xs={12}
                                                    className={classes.labels}
                                                >
                                                    {(unconfirmCombinations[
                                                        combinationId
                                                    ] &&
                                                        unconfirmCombinations[
                                                            combinationId
                                                        ].isLoading) ||
                                                    (confirmCombinations[
                                                        combinationId
                                                    ] &&
                                                        confirmCombinations[
                                                            combinationId
                                                        ].isLoading) ? (
                                                        <CircularProgress
                                                            size={24}
                                                        />
                                                    ) : (
                                                        !obj.combinations[0]
                                                            .confirmed_by && (
                                                            <Chip label="Not confirmed" />
                                                        )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        }
                    )}
                </div>
            </React.Fragment>
        );
    }

    renderOrders() {
        const {
            isLoadingOrders,
            orders,
            errorOrders,
            classes,
            totalPagesOrders,
            // totalOrders,
            currentPageOrders,
        } = this.props;

        if (isLoadingOrders) {
            return (
                <React.Fragment>
                    <CardContent>
                        <div className={classes.subscriptionsContainer}>
                            {[...new Array(3)].map((key) => (
                                <OrderRow key={key} isLoading />
                            ))}
                        </div>
                    </CardContent>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Grid container className={classNames(classes.groupActions)}>
                    <CardList
                        isLoading={isLoadingOrders}
                        objects={orders}
                        totalPages={totalPagesOrders}
                        currentPage={currentPageOrders}
                        error={errorOrders}
                        card={OrderRow}
                        handlePage={this.handleOrderPage}
                        useCardAsLoaders
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderDialogQR() {
        const { ticket } = this.props;

        if (!ticket) {
            return;
        }

        return (
            <DialogQR
                ticket={ticket}
                open={this.state.dialogQROpen}
                onClose={this.handleQRcodeClose}
            />
        );
    }

    renderDetails() {
        const {
            ticket,
            isLoading,
            t,
            isLoadingEvent,
            isDownloadingTicket,
            errorEvent,
            event,
        } = this.props;

        return (
            <React.Fragment>
                <TableContainer className={'two-column-table nowrap'}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell variant={'head'} component={'th'}>
                                    <strong>{t('forms:label.id')}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    {isLoading ? (
                                        <Skeleton height={24} width={'100%'} />
                                    ) : (
                                        `#${ticket.id}`
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={'head'} component={'th'}>
                                    <strong>
                                        {t('forms:label.unique_id')}
                                    </strong>
                                </TableCell>
                                <TableCell align="right">
                                    {isLoading ? (
                                        <Skeleton height={24} width={'100%'} />
                                    ) : (
                                        ticket.unique_id
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={'head'} component={'th'}>
                                    <strong>{t('forms:label.created')}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    {isLoading ? (
                                        <Skeleton height={24} width={'100%'} />
                                    ) : (
                                        moment(ticket.created_at).format('LLL')
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={'head'} component={'th'}>
                                    <strong>{t('forms:label.scanned')}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    {
                                        /* eslint-disable */
                                        isLoading === true ? (
                                            <Skeleton
                                                height={24}
                                                width={'100%'}
                                            />
                                        ) : ticket.scanned_on ? (
                                            moment(ticket.scanned_on).format(
                                                'LLL'
                                            )
                                        ) : (
                                            '-'
                                        )
                                        /* eslint-enable */
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant={'head'} component={'th'}>
                                    <strong>{t('forms:label.event')}</strong>
                                </TableCell>
                                <TableCell align="right">
                                    {isLoadingEvent || errorEvent || !event ? (
                                        '-'
                                    ) : (
                                        <Link
                                            to={getUrl(
                                                urls.ADMIN_EVENT_DETAIL,
                                                { eventId: event.id }
                                            )}
                                        >
                                            {event.name}
                                        </Link>
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">
                                    <Button
                                        fullWidth
                                        variant={'text'}
                                        onClick={this.handleQRcode}
                                    >
                                        {t('forms:label.show_qr_code')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">
                                    <Button
                                        fullWidth
                                        color={'secondary'}
                                        variant={'contained'}
                                        onClick={this.handleDownloadTicket}
                                        isLoading={isDownloadingTicket}
                                    >
                                        {t('common:button.download_ticket')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </React.Fragment>
        );
    }

    render() {
        const { error, isLoading, classes, t } = this.props;

        if (!isLoading && error) {
            return this.renderNotFound();
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>
                        {t('common:title.ticket_detail')} |{' '}
                        {t('common:title.administration')} | Equento
                    </title>
                    <link
                        rel="canonical"
                        href={getUrl(this.props.location.pathname, {}, true)}
                    />
                </Helmet>

                <Toast
                    message={this.state.errorMessage}
                    open={this.state.error}
                    onClose={this.handleToastClose}
                />
                <Toast
                    message={t('common:success.general.action')}
                    open={this.state.isSuccess}
                    onClose={this.handleToastClose}
                    type={'success'}
                />

                <TemplateAdmin header>{this.renderHeader()}</TemplateAdmin>

                <TemplateAdmin>
                    <Grid container spacing={3}>
                        <Grid item md={8} xs={12}>
                            <Card>
                                <CardHeader
                                    title={t('common:title.subscriptions')}
                                    subheader={t(
                                        'common:description.ticket.subscriptions'
                                    )}
                                />
                                {this.renderSubscriptions()}
                            </Card>

                            <Card className={classes.spacer}>
                                <CardHeader
                                    title={t('common:title.orders')}
                                    subheader={t(
                                        'common:description.ticket.orders'
                                    )}
                                />
                                {this.renderOrders()}
                            </Card>
                        </Grid>

                        <Grid item md={4} xs={12}>
                            <Card>
                                <CardHeader
                                    title={t('common:title.customer')}
                                    subheader={t(
                                        'common:description.ticket.customer'
                                    )}
                                />
                                <CardContent>{this.renderUser()}</CardContent>
                            </Card>

                            <Card className={classes.spacer}>
                                <CardHeader
                                    title={t('common:title.details')}
                                    subheader={t(
                                        'common:description.ticket.details'
                                    )}
                                />
                                {this.renderDetails()}
                            </Card>
                        </Grid>
                    </Grid>
                </TemplateAdmin>
                {this.renderDialogQR()}
            </React.Fragment>
        );
    }
}

AdminTicketPage.propTypes = {
    // props
    isLoading: PropTypes.bool,
    isLoadingEvent: PropTypes.bool,
    isLoadingOrders: PropTypes.bool,
    isDownloadingTicket: PropTypes.bool,
    event: PropTypes.object,
    errorEvent: PropTypes.string,
    errorOrders: PropTypes.string,
    orders: PropTypes.object,
    totalPagesOrders: PropTypes.number,
    currentPageOrders: PropTypes.number,
    error: PropTypes.string,
    file: PropTypes.object,
    subscriptions: PropTypes.object,
    ticket: PropTypes.object,
    // event handlers
    getTicket: PropTypes.func.isRequired,
    getEvent: PropTypes.func.isRequired,
    listOrders: PropTypes.func.isRequired,
    unconfirmCombination: PropTypes.func.isRequired,
    unconfirmCombinations: PropTypes.func.isRequired,
    confirmCombination: PropTypes.func.isRequired,
    confirmCombinations: PropTypes.func.isRequired,
    downloadTicket: PropTypes.func.isRequired,
    // others
    match: PropTypes.object,
    t: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    classes: PropTypes.object,
};

const mapStateToProps = (state) => {
    const {
        isLoading,
        ticket,
        subscriptions,
        error,

        isLoadingEvent,
        event,
        errorEvent,

        isLoadingOrders,
        orders,
        errorOrders,
        totalPagesOrders,
        totalOrders,
        currentPageOrders,

        unconfirmCombinations,
        confirmCombinations,
    } = state.pageAdminTicket;

    return {
        isLoading,
        ticket,
        subscriptions,
        error,

        isLoadingEvent,
        event,
        errorEvent,

        isLoadingOrders,
        orders,
        errorOrders,
        totalPagesOrders,
        totalOrders,
        currentPageOrders,

        unconfirmCombinations,
        confirmCombinations,
    };
};

const AdminTicketPageTranslated = withTranslation()(AdminTicketPage);
const AdminTicketPageStyled = withStyles(useStyles)(AdminTicketPageTranslated);
const AdminTicketPageState = connect(mapStateToProps, {
    getTicket,
    getEvent,
    listOrders,
    confirmCombination,
    unconfirmCombination,
    downloadTicket,
})(AdminTicketPageStyled);
const AdminTicketPageRouter = withRouter(AdminTicketPageState);
export { AdminTicketPageRouter as AdminTicketPage };
