import React from 'react';
import { Translation } from 'react-i18next';
import { getUrl, urls } from 'urls';

import {
    HomePage,
    LoginPage,
    OrganisersPage,
    PublicEventsPage,
} from '../pages';

/*
    The routes.
*/
const topbarPublicMenuRoutes = [
    {
        path: getUrl(urls.HOME),
        name: <Translation>{(t) => t('common:text.menu.home')}</Translation>,
        exact: true,
        component: HomePage,
        hidden: true,
    },
    {
        path: getUrl(urls.EVENTS),
        name: <Translation>{(t) => t('common:text.menu.events')}</Translation>,
        isPrivate: false,
        exact: true,
        component: PublicEventsPage,
    },
    {
        path: getUrl(urls.ORGANISERS),
        name: (
            <Translation>{(t) => t('common:text.menu.organisers')}</Translation>
        ),
        isPrivate: false,
        exact: true,
        component: OrganisersPage,
    },
    {
        path: getUrl(urls.HELP),
        name: <Translation>{(t) => t('common:text.menu.help')}</Translation>,
        isPrivate: false,
        exact: true,
        component: HomePage,
    },
    {
        path: getUrl(urls.LOGIN),
        name: <Translation>{(t) => t('common:text.menu.login')}</Translation>,
        exact: false,
        component: LoginPage,
        hideWhenAutenticated: true,
    },
];

export { topbarPublicMenuRoutes };
