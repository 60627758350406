import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getUrl, urls } from 'urls';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Collapse,
    Divider,
    IconButton,
    ListItemIcon,
    MenuList,
    MenuItem,
    Popover,
    Typography,
} from '@material-ui/core';
import {
    AccountCircleOutlined as AccountCircleIcon,
    PowerSettingsNewOutlined as PowerSettingsNewIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';

const useStyles = (theme) => ({
    menu: {
        minWidth: '200px',

        '& .MuiListItemIcon-root': {
            minWidth: theme.spacing(5),
        },
    },
    divider: {
        margin: '4px 0',
    },
    username: {
        color: theme.colors.mikado,
        display: 'inline-block',
        fontSize: '1rem',
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),

        [theme.breakpoints.up('sm')]: {
            display: 'none !important',
        },
    },
    menuOpen: {
        '& svg': {
            '&:nth-child(2)': {
                transform: 'rotate(180deg)',
            },
        },
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        '& span': {
            display: 'flex',
            alignItems: 'center',
        },

        '& svg': {
            transition: 'transform .2s ease',
            verticalAlign: 'middle',
        },

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },
    collapseMenu: {
        width: 'calc(100% + 32px)',
        margin: '0 -16px',

        '& li': {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    },
});

class UserMenu extends Component {
    constructor(props) {
        super(props);

        // state
        this.state = {
            anchorEl: null,
            menuOpen: false,
            redirectToUrl: null,
        };

        // event handlers
        this.handleUserMenu = this.handleUserMenu.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
    }

    handleRedirect(url) {
        this.setState({
            redirectToUrl: url,
        });
        this.handleClose();
    }

    handleClose() {
        this.setState({
            menuOpen: false,
            anchorEl: null,
        });
    }

    handleUserMenu(event) {
        this.setState({
            menuOpen: !this.state.menuOpen,
            anchorEl: event.currentTarget,
        });
    }

    render() {
        const { classes, t, user, mobile } = this.props;
        const { redirectToUrl } = this.state;

        // <MenuItem
        //     onClick={() => this.handleRedirect("/user/notifications")}
        // >
        //     {t('common:text.menu.notifications')}
        // </MenuItem>

        const Content = () => {
            return (
                <MenuList
                    classes={{
                        root: classes.menu,
                    }}
                >
                    <MenuItem
                        onClick={() =>
                            this.handleRedirect(getUrl(urls.USER_PROFILE))
                        }
                    >
                        <Typography variant="inherit">
                            {t('common:text.menu.profile')}
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            this.handleRedirect(getUrl(urls.USER_ORDERS))
                        }
                    >
                        <Typography variant="inherit">
                            {t('common:text.menu.orders')}
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            this.handleRedirect(getUrl(urls.USER_TICKETS))
                        }
                    >
                        <Typography variant="inherit">
                            {t('common:text.menu.tickets')}
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            this.handleRedirect(getUrl(urls.USER_NOTIFICATIONS))
                        }
                    >
                        <Typography variant="inherit">
                            {t('common:text.menu.notifications')}
                        </Typography>
                    </MenuItem>
                    <Divider className={classes.divider} />
                    <MenuItem
                        onClick={() => this.handleRedirect(getUrl(urls.ADMIN))}
                    >
                        <Typography variant="inherit">
                            {t('common:text.menu.to_admin')}
                        </Typography>
                    </MenuItem>
                    <Divider className={classes.divider} />
                    <MenuItem
                        onClick={() => this.handleRedirect(getUrl(urls.LOGOUT))}
                    >
                        <ListItemIcon>
                            <PowerSettingsNewIcon fontSize={'small'} />
                        </ListItemIcon>
                        <Typography variant="inherit">
                            {t('common:text.menu.log_out')}
                        </Typography>
                    </MenuItem>
                </MenuList>
            );
        };

        const PopoverMenu = () => {
            return (
                <Popover
                    id="user-menu"
                    open={this.state.menuOpen}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Content />
                </Popover>
            );
        };

        const CollapsedMenu = () => {
            return (
                <Collapse
                    id="user-menu"
                    in={this.state.menuOpen}
                    onClose={this.handleClose}
                    anchorEl={this.state.anchorEl}
                    className={classes.collapseMenu}
                >
                    <Content />
                </Collapse>
            );
        };

        return (
            <React.Fragment>
                {Boolean(redirectToUrl) && <Redirect to={redirectToUrl} />}

                <IconButton
                    aria-describedby="user-menu"
                    onClick={this.handleUserMenu}
                    className={classNames(
                        classes.button,
                        this.state.menuOpen && classes.menuOpen
                    )}
                >
                    <span>
                        <AccountCircleIcon />

                        {user && (
                            <span className={classes.username}>
                                {user.first_name} {user.last_name}
                            </span>
                        )}
                    </span>
                    {mobile && <KeyboardArrowDownIcon />}
                </IconButton>

                {mobile ? <CollapsedMenu /> : <PopoverMenu />}
            </React.Fragment>
        );
    }
}

UserMenu.propTypes = {
    // props
    mobile: PropTypes.bool,
    user: PropTypes.object,
    classes: PropTypes.object,
    // handlers
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { user } = state.user;

    return {
        user,
    };
};

const UserMenuStyled = withStyles(useStyles)(UserMenu);
const UserMenuTranslated = withTranslation()(UserMenuStyled);
const UserMenuState = connect(mapStateToProps)(UserMenuTranslated);

export { UserMenuState as UserMenu };
