import { getAdminOrder } from 'services/OrderService';

import { listAdminOrderPayments } from 'services/PaymentService';

import { payAdminOrder as apiPayAdminOrder } from 'services/BasketService';

import AlertService from 'lib/AlertService';
import TranslationUtil from 'lib/TranslationUtil';

import {
    ADMIN_ORDER_LOADING,
    ADMIN_ORDER_SUCCESS,
    ADMIN_ORDER_FAILED,
    ADMIN_ORDER_PAYMENTS_LOADING,
    ADMIN_ORDER_PAYMENTS_SUCCESS,
    ADMIN_ORDER_PAYMENTS_FAILED,
    ADMIN_ORDER_PAY_LOADING,
    ADMIN_ORDER_PAY_SUCCESS,
    ADMIN_ORDER_PAY_FAILED,
} from './actionTypes';

export const getOrder = (id) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_ORDER_LOADING });

        getAdminOrder(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_ORDER_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                AlertService.showError({
                    title: TranslationUtil.getTranslations().t(
                        'common:error.general.title'
                    ),
                    description: TranslationUtil.getTranslations().t(
                        'common:error.general.description'
                    ),
                });

                dispatch({
                    type: ADMIN_ORDER_FAILED,
                    payload: error,
                });
            });
    };
};

export const listOrderPayments = (page, orderId) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_ORDER_PAYMENTS_LOADING });

        listAdminOrderPayments(page, orderId)
            .then((result) => {
                dispatch({
                    type: ADMIN_ORDER_PAYMENTS_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_ORDER_PAYMENTS_FAILED,
                    payload: error,
                });
            });
    };
};

export const payAdminOrder = ({ id, method, amount }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_ORDER_PAY_LOADING });

        apiPayAdminOrder(id, method, amount)
            .then((result) => {
                dispatch({
                    type: ADMIN_ORDER_PAY_SUCCESS,
                    payload: result,
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_ORDER_PAY_FAILED,
                    payload: error,
                });
            });
    };
};
