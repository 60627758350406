import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { getUrl, urls } from 'urls';

import { AppBar, Slide, useScrollTrigger } from '@material-ui/core';

import { ToolbarPublic, ToolbarAdmin } from './components';

const useStyles = (theme) => ({
    topbar: {
        background: theme.colors.springWood,
        transition: 'all .2s ease',
        zIndex: theme.zIndex.drawer + 1,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),

        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(5),
            paddingBottom: theme.spacing(5),
        },
    },
    topbarAdmin: {
        background: theme.colors.white,
        color: theme.colors.mikado,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    sticky: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
});

/*
    Static variables.
*/

function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
    });
}

function HideOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <ElevationScroll {...props}>
            <Slide appear={false} direction="down" in={!trigger}>
                {children}
            </Slide>
        </ElevationScroll>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.object,
};

class TopNavigation extends Component {
    render() {
        const { ref, trigger, classes, history, isLoggedIn } = this.props;
        const isAdmin = history.location.pathname.startsWith(
            getUrl(urls.ADMIN)
        );

        if (isAdmin) {
            return (
                <AppBar
                    ref={ref}
                    elevation={1}
                    className={classNames(
                        classes.topbar,
                        classes.topbarAdmin,
                        trigger && classes.sticky,
                        'main-admin'
                    )}
                >
                    <ToolbarAdmin
                        isLoggedIn={isLoggedIn}
                        adminBackAction={this.props.adminBackAction}
                        adminTitle={this.props.adminTitle}
                    />
                </AppBar>
            );
        }

        return (
            <HideOnScroll {...this.props}>
                <AppBar
                    ref={ref}
                    elevation={trigger ? 2 : 0}
                    color={'transparent'}
                    className={classNames(
                        classes.topbar,
                        trigger && classes.sticky,
                        'main'
                    )}
                >
                    <ToolbarPublic isLoggedIn={isLoggedIn} />
                </AppBar>
            </HideOnScroll>
        );
    }
}

TopNavigation.propTypes = {
    isLoggedIn: PropTypes.bool,
    trigger: PropTypes.bool,
    classes: PropTypes.object,
    ref: PropTypes.object,
    history: PropTypes.object,
    adminBackAction: PropTypes.func,
    adminTitle: PropTypes.string,
};

const TopNavigationRef = React.forwardRef((props, ref) => (
    <TopNavigation {...props} ref={ref} />
));
const TopNavigationStyled = withStyles(useStyles)(TopNavigationRef);
const TopNavigationHistory = withRouter(TopNavigationStyled);

export { TopNavigationHistory as TopNavigation };
