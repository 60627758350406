import { LOGIN_PAGE_LOGIN_LOADING_SUCCESS } from '../pages/Login/actionTypes';
import { LOGOUT_PAGE_LOADING_SUCCESS } from '../pages/Logout/actionTypes';

const INITIAL_STATE = {
    isLoggedIn: false,
    token: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'authentication_checking_success': // TODO
        case LOGIN_PAGE_LOGIN_LOADING_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
                token: action.payload.auth_token,
                isLoggedIn: true,
            };

        case LOGOUT_PAGE_LOADING_SUCCESS:
            return {
                ...state,
                ...INITIAL_STATE,
            };

        default:
            return state;
    }
};
