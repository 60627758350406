import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { urls, getUrl } from 'urls';

import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
    Typography,
} from '@material-ui/core';
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import {
    EventOutlined as EventIcon,
    ScheduleOutlined as ScheduleIcon,
} from '@material-ui/icons';
import { IconBorder } from 'components';
import { getObjectFromCache, randomEventImage } from 'utils';

const useStyles = (theme) => ({
    meta: {
        color: theme.colors.mikado,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        justifyContent: 'space-between',
        marginTop: '24px',

        '&> div': {
            marginBottom: theme.spacing(1),
        },

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },

        '& .iconBorder': {
            marginRight: '10px',
        },
    },
    cardContent: {
        padding: '32px 24px',
    },
    name: {
        // fontFamily: 'Nunito',
        // fontWeight: '800',
        fontSize: '1.25rem',
        // lineHeight: '150%',
        marginBottom: '0px',

        '& a': {
            color: theme.colors.mikado,
        },
    },
    root: {
        boxShadow: '0 0 10px rgba(0, 0, 0, .1)',
        borderRadius: '20px',
        // margin: '20px 20px 0 0',
        marginBottom: '5%',
        // maxWidth: '95%',
        textAlign: 'left',

        '&:hover': {
            textDecoration: 'none',
            '&> a': {
                textDecoration: 'none',
            },
        },

        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        },
    },
    club: {
        color: theme.colors.mikado,
        fontSize: '0.875rem',
        fontWeight: 'normal',
    },
    footnote: {
        color: theme.colors.warmGrey,
    },
    media: {
        height: '200px',
        backgroundColor: theme.colors.springWood,
        padding: '24px',
        position: 'relative',
    },
});

class CardEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clubName: null,
        };
    }

    componentDidMount() {
        if (this.props.club && !this.state.clubName) {
            getObjectFromCache({
                url: this.props.club,
                success: (result) => {
                    this.setState({
                        clubName: result['name'],
                    });
                },
                error: () => {
                    // do something TODO
                },
            });
        }
    }

    renderDays() {
        const { days, classes, isLoading } = this.props;

        Moment.locale(i18n.language);

        if (isLoading) {
            return (
                <div className={classes.meta}>
                    <div>
                        <IconBorder>
                            <EventIcon />
                        </IconBorder>
                        <Skeleton
                            variant="rect"
                            width={100}
                            height="17"
                            style={{ display: 'inline-block' }}
                        />
                    </div>
                    <div>
                        <IconBorder>
                            <ScheduleIcon className="icon" />
                        </IconBorder>
                        <Skeleton
                            variant="rect"
                            width={75}
                            height="17"
                            style={{ display: 'inline-block' }}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.meta}>
                <div>
                    <IconBorder>
                        <EventIcon />
                    </IconBorder>
                    {new Moment(days[0].date_start_at).format('LL')}
                </div>
                <div>
                    <IconBorder>
                        <ScheduleIcon className="icon" />
                    </IconBorder>
                    {new Moment(days[0].time_start_at, 'H:m:s').format('LT')}
                </div>
            </div>
        );
    }

    renderLoading() {
        const { classes } = this.props;

        return (
            <Card className={classes.root} elevation={0}>
                <CardMedia className={classes.media}>
                    <div className="card-overlay" />
                </CardMedia>
                <CardContent className={classes.cardContent}>
                    <Skeleton
                        variant="rect"
                        width={'100%'}
                        height="17"
                        style={{ marginBottom: '8px' }}
                    />
                    <Skeleton variant="rect" width={100} height="17" />

                    {this.renderDays()}

                    <div className={classes.meta}>
                        <AvatarGroup spacing="small">
                            <Skeleton variant="rect" width={80} height={30} />
                        </AvatarGroup>
                        <small className={classes.footnote}>
                            <Skeleton variant="rect" width={50} height="12" />
                        </small>
                    </div>
                </CardContent>
            </Card>
        );
    }

    render() {
        const { name, image, id, closed, isLoading, classes, t } = this.props;
        const clubName = this.state.clubName;
        let headerImg = image;

        if (!headerImg) {
            headerImg = randomEventImage()['@2x'];
        }

        if (isLoading) {
            return this.renderLoading();
        }

        return (
            <Card className={classes.root} elevation={0}>
                <CardActionArea
                    href={getUrl(urls.EVENT_DETAIL, { eventId: id })}
                >
                    <CardMedia
                        className={classes.media}
                        image={headerImg}
                        title={name}
                    >
                        <div className="card-overlay" />
                        {closed ? (
                            <span className="label red">
                                {t('common:labels.closed')}
                            </span>
                        ) : (
                            <span className="label green">
                                {t('common:labels.open')}
                            </span>
                        )}
                    </CardMedia>
                    <CardContent className={classes.cardContent}>
                        <Typography
                            variant="h3"
                            color="primary"
                            className={classes.name}
                        >
                            {name}
                        </Typography>
                        {clubName ? (
                            <small className={classes.club}>{clubName}</small>
                        ) : (
                            <Skeleton variant="rect" width={100} height="17" />
                        )}

                        {this.renderDays()}

                        {/*
                        <div className={classes.meta}>
                            <AvatarGroup spacing="small">
                                <Avatar variant="rounded" src="https://picsum.photos/30/30" />
                                <Avatar variant="rounded" src="https://picsum.photos/30/30" />
                                <Avatar variant="rounded" src="https://picsum.photos/30/30" />
                                <Avatar variant="rounded" src="https://picsum.photos/30/30" />
                            </AvatarGroup>
                            <small className={classes.footnote}>+42 {t('common:text.other_going')}</small>
                        </div>
                        */}
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
}

CardEvent.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequiredIf((props) => !props.isLoading),
    club: PropTypes.string.isRequiredIf((props) => !props.isLoading),
    id: PropTypes.number.isRequiredIf((props) => !props.isLoading),
    image: PropTypes.string,
    days: PropTypes.array.isRequiredIf((props) => !props.isLoading),
    closed: PropTypes.bool,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    t: PropTypes.object.isRequired,
};

const CardEventTranslated = withTranslation()(CardEvent);
const CardEventStyled = withStyles(useStyles)(CardEventTranslated);

export { CardEventStyled as CardEvent };
