import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Tabs as MuiTabs, Tab as MuiTab } from '@material-ui/core';

const useStyles = (theme) => ({
    tabs: {
        margin: '40px 0 0',
    },
    tab: {
        textTransform: 'none',
        fontWeight: 'bold',
    },
});

class Tabs extends Component {
    getProps(index) {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    render() {
        const { classes, selectedTab, onTabChange, tabs } = this.props;

        if (!tabs) {
            return null;
        }

        // alert('value: ' + value + ' index: ' + index)

        return (
            <MuiTabs
                value={selectedTab}
                onChange={onTabChange}
                variant="scrollable"
                scrollButton="auto"
                classes={{
                    root: classes.tabs,
                }}
            >
                {tabs?.map((obj, index) => (
                    <MuiTab
                        classes={{ root: classes.tab }}
                        value={obj.value}
                        label={obj.label}
                        {...this.getProps(index)}
                    />
                ))}
            </MuiTabs>
        );
    }
}

Tabs.propTypes = {
    // props
    selectedTab: PropTypes.number.isRequired,
    tabs: PropTypes.object.isRequired,
    // events
    onTabChange: PropTypes.func.isRequired,
    // other
    classes: PropTypes.object.isRequired,
};

const TabsStyled = withStyles(useStyles)(Tabs);

export { TabsStyled as Tabs };
