import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { getUrl, urls } from 'urls';

import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import { Button, CountrySelect, TextInput, IntroText } from 'components';

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: '24px',
    },
    cancel: {
        marginLeft: '15px',
    },
}));

function ClubForm(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const club = props.club;
    let defaultValues = {};

    if (club) {
        // render default values
        defaultValues = club;
        defaultValues.id = club.id;
    }

    const { handleSubmit, register, control, setValue } = useForm({
        defaultValues: defaultValues,
    });

    const cancel = () => {
        history.push(getUrl(urls.ADMIN_CLUBS));
    };

    const renderDetails = () => {
        return (
            <React.Fragment>
                <Grid item md={4} xs={12}>
                    <IntroText
                        title={t('common:title.details')}
                        description={t('common:description.club.details')}
                    />
                </Grid>

                <Grid item md={8} xs={12}>
                    <Card>
                        <CardContent
                            classes={{
                                root: classes.content,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextInput
                                        label={t('forms:label.name')}
                                        name="name"
                                        required
                                        error={
                                            props.errors && props.errors['name']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput
                                        label={t('forms:label.email_address')}
                                        name="email"
                                        email
                                        required
                                        error={
                                            props.errors &&
                                            props.errors['email']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextInput
                                        label={t('forms:label.phone')}
                                        name="phone_number"
                                        error={
                                            props.errors &&
                                            props.errors['phone_number']
                                        }
                                        ref={register({})}
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextInput
                                        label={t('forms:label.website')}
                                        name="website"
                                        error={
                                            props.errors &&
                                            props.errors['website']
                                        }
                                        ref={register({})}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextInput
                                        label={t('forms:label.vat_number')}
                                        name="vat_number"
                                        error={
                                            props.errors &&
                                            props.errors['vat_number']
                                        }
                                        ref={register({})}
                                        value={defaultValues['vat_number']}
                                        mask={'BE 9999.999.999'}
                                        maskPlaceholder={'BE ____.___.___'}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </React.Fragment>
        );
    };

    const renderAddress = () => {
        return (
            <React.Fragment>
                <Grid item md={4} xs={12}>
                    <IntroText
                        title={t('common:title.address')}
                        description={t('common:description.club.address')}
                    />
                </Grid>

                <Grid item md={8} xs={12}>
                    <Card>
                        <CardContent
                            classes={{
                                root: classes.content,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextInput
                                        label={t('forms:label.street')}
                                        name="address.street"
                                        required
                                        error={
                                            props.errors &&
                                            props.errors['address.street']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <TextInput
                                        label={t('forms:label.number')}
                                        name="address.number"
                                        required
                                        number
                                        error={
                                            props.errors &&
                                            props.errors['address.number']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item md={3} xs={12}>
                                    <TextInput
                                        label={t('forms:label.box')}
                                        name="address.box"
                                        error={
                                            props.errors &&
                                            props.errors['address.box']
                                        }
                                        ref={register()}
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextInput
                                        label={t('forms:label.postal_code')}
                                        name="address.postal_code"
                                        required
                                        error={
                                            props.errors &&
                                            props.errors['address.postal_code']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <TextInput
                                        label={t('forms:label.city')}
                                        name="address.city"
                                        required
                                        error={
                                            props.errors &&
                                            props.errors['address.city']
                                        }
                                        ref={register({ required: true })}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <CountrySelect
                                        label={t('forms:label.country')}
                                        name="address.country"
                                        required
                                        control={control}
                                        setValue={setValue}
                                        ref={register({ required: true })}
                                        error={
                                            props.errors &&
                                            props.errors['address.country']
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </React.Fragment>
        );
    };

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Grid
                container
                spacing={3}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
            >
                {renderDetails()}

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                {renderAddress()}

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        color={'secondary'}
                        type={'submit'}
                        isLoading={props.isLoading}
                    >
                        {club
                            ? t('forms:button.edit_club')
                            : t('forms:button.add_club')}
                    </Button>
                    <Button
                        variant={'text'}
                        className={classes.cancel}
                        onClick={cancel}
                    >
                        {t('forms:button.cancel')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

ClubForm.propTypes = {
    // props
    club: PropTypes.object,
    errors: PropTypes.array,
    isLoading: PropTypes.bool,
    // handlers
    onSubmit: PropTypes.func.isRequired,
};

export { ClubForm };
