import format from 'string-format';
import fetchApi from './api';

const adminEndPoints = {
    confirmCombination: '/v1/admin/combinations/{id}/confirm/',
    unconfirmCombination: '/v1/admin/combinations/{id}/unconfirm/',
};

export const confirmCombinationAdmin = (id) => fetchApi({
    url: format(adminEndPoints.confirmCombination, { id }),
    method: 'POST',
});

export const unconfirmCombinationAdmin = (id) => fetchApi({
    url: format(adminEndPoints.unconfirmCombination, { id }),
    method: 'POST',
});
