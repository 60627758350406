import moment from 'moment';
import React, { Component, Suspense, Spinner } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { urls, getUrl } from 'urls';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { Container, Grid, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    RoomOutlined as MapMarkerIcon,
    PublicOutlined as WebsiteIcon,
    EventOutlined as EventIcon,
} from '@material-ui/icons';

import { TemplateMarketing } from '../TemplateMarketing';
import {
    CardCourse,
    CardEventHeader,
    EmptyMessage,
    Toast,
    MetaStat,
} from 'components';
import { getEvent } from './actions';
import { getObjectFromCache, getEventDays } from '../../utils';

const useStyles = (theme) => ({});

class PublicEventDetailPage extends Component {
    constructor(props) {
        super(props);

        const eventId = this.props.match.params.eventId;
        // save to state
        this.state = {
            eventId: eventId,
            club_name: null,
            club: null,
        };
        // get the event
        this.props.getEvent(eventId);

        // event handlers
        this.reloadEvent = this.reloadEvent.bind(this);
    }

    componentDidUpdate(prevProps) {
        // get the club object
        if (this.props.event && !this.state.club_name) {
            getObjectFromCache({
                url: this.props.event.club,
                success: (result) => {
                    this.setState({
                        club_name: result['name'],
                        club: result,
                    });
                },
                error: () => {
                    // do something TODO
                },
            });
        }
    }

    reloadEvent() {
        this.props.getEvent(this.state.eventId);
    }

    renderDescription() {
        const { t, isLoading, event } = this.props;

        return (
            <React.Fragment>
                {isLoading || event.description ? (
                    <Typography variant="h2" className="title-h2">
                        {t('common:title.description')}
                    </Typography>
                ) : (
                    ''
                )}

                {isLoading ? (
                    <React.Fragment>
                        <Skeleton variant="rect" width={'100%'} height={21} />
                        <Skeleton
                            variant="rect"
                            width={'100%'}
                            height={21}
                            style={{ marginTop: '5px' }}
                        />
                        <Skeleton
                            variant="rect"
                            width={'80%'}
                            height={21}
                            style={{ marginTop: '5px' }}
                        />
                    </React.Fragment>
                ) : (
                    <Typography
                        variant="body1"
                        style={{ 'white-space': 'pre-line' }}
                    >
                        {event.description}
                    </Typography>
                )}
            </React.Fragment>
        );
    }

    renderMeta() {
        const { event, t, isLoading } = this.props;

        let eventDays = null;
        if (event) {
            eventDays = getEventDays(event.days);
        }

        return (
            <React.Fragment>
                <Typography variant="h2" className="title-h2">
                    {t('common:title.details')}
                </Typography>

                {
                    <React.Fragment>
                        <MetaStat
                            icon={<MapMarkerIcon />}
                            isLoading={isLoading}
                            value={
                                !isLoading &&
                                this.state.club &&
                                this.state.club.name
                            }
                            text={
                                !isLoading &&
                                this.state.club &&
                                this.state.club.address.street +
                                    ' ' +
                                    this.state.club.address.number +
                                    (this.state.club.address.box
                                        ? ' / ' + this.state.club.address.box
                                        : '') +
                                    ', ' +
                                    this.state.club.address.postal_code +
                                    ' ' +
                                    this.state.club.address.country_description
                            }
                        />
                        {this.state.club && (
                            <MetaStat
                                icon={<WebsiteIcon />}
                                value={
                                    <a
                                        href={this.state.club?.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {this.state.club?.website}
                                    </a>
                                }
                            />
                        )}
                        {isLoading && (
                            <MetaStat
                                id={'days'}
                                icon={<EventIcon />}
                                isLoading
                            />
                        )}
                        {!isLoading &&
                            eventDays &&
                            eventDays.days.map((obj, index) => (
                                <MetaStat
                                    id={'days'}
                                    icon={<EventIcon />}
                                    value={obj.day.format('LL')}
                                    text={`${obj.time_from} - ${obj.time_to}`}
                                />
                            ))}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }

    render() {
        const { isLoading, t, error, event } = this.props;
        const from = event?.subscriptions_open_at; // TODO
        const to = event?.subscriptions_closed_at;

        if (error) {
            return (
                <React.Fragment>
                    <Toast
                        message={t('common:error.general.description')}
                        open
                    />
                    <EmptyMessage
                        title={t('common:error.general.title')}
                        description={t('common:error.general.description_long')}
                        action={this.reloadEvent}
                    />
                </React.Fragment>
            );
        }

        return (
            <Suspense fallback={<Spinner />}>
                <TemplateMarketing>
                    <Helmet>
                        <title>
                            {event
                                ? event.name
                                : t('common:title.event_detail')}{' '}
                            | Equento
                        </title>
                        {event && (
                            <link
                                rel="canonical"
                                href={getUrl(
                                    urls.EVENT_DETAIL,
                                    { eventId: event.id },
                                    true
                                )}
                            />
                        )}
                    </Helmet>
                    <Container>
                        <CardEventHeader
                            name={event ? event.name : ''}
                            club={event ? event.club : ''}
                            eventId={event?.id}
                            subscriptionsFrom={
                                from && moment(from).format('DD-MM-YYYY HH:mm')
                            }
                            subscriptionsTo={
                                to && moment(to).format('DD-MM-YYYY HH:mm')
                            }
                            days={event ? event.days : null}
                            isLoading={isLoading}
                            closed={event ? event.subscriptions_closed : false}
                        />

                        <Grid container spacing={3}>
                            <Grid item lg={9} md={12} xs={12}>
                                {this.renderDescription()}

                                <Typography
                                    variant="h2"
                                    className="title-h2"
                                    id={'courses'}
                                >
                                    {t('common:title.courses')}
                                </Typography>

                                {!isLoading &&
                                    event &&
                                    event.courses.length === 0 && (
                                        <small>
                                            {t(
                                                'common:text.no_courses.description'
                                            )}
                                        </small>
                                    )}

                                {isLoading
                                    ? [...new Array(6)].map(() => (
                                          <CardCourse isLoading={isLoading} />
                                      ))
                                    : event.courses.map((obj, i) => (
                                          <CardCourse
                                              name={obj.name}
                                              key={i}
                                              position={obj.position}
                                              height={obj.height}
                                              time={obj.start_hour}
                                              courseType={obj.type_description}
                                              subscriptionsTotal={
                                                  obj.subscriptions_total
                                              }
                                              subscriptionsMax={
                                                  obj.subscriptions_allowed
                                              }
                                              submissionPrice={
                                                  obj.is_local
                                                      ? obj.submission_price_locally
                                                      : obj.submission_price
                                              }
                                              // team={true}
                                              actionUrl={getUrl(
                                                  urls.EVENT_COURSE_START_LIST,
                                                  {
                                                      eventId: this.state
                                                          .eventId,
                                                      courseId: obj.id,
                                                  }
                                              )}
                                              closed={
                                                  event
                                                      ? event.subscriptions_closed
                                                      : false
                                              }
                                          />
                                      ))}
                            </Grid>

                            <Grid item lg={3} md={12} xs={12}>
                                {this.renderMeta()}
                            </Grid>
                        </Grid>
                    </Container>
                </TemplateMarketing>
            </Suspense>
        );
    }
}

PublicEventDetailPage.propTypes = {
    // props
    event: PropTypes.object,
    match: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    // handlers
    getEvent: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    const { isLoading, event, error } = state.pagePublicEventDetail;

    return {
        isLoading,
        event,
        error,
    };
};

const PublicEventDetailPageState = connect(mapStateToProps, {
    getEvent,
})(PublicEventDetailPage);

const PublicEventDetailPageTranslated = withTranslation()(
    PublicEventDetailPageState
);
const PublicEventDetailPageStyled = withStyles(useStyles)(
    PublicEventDetailPageTranslated
);
export { PublicEventDetailPageStyled as PublicEventDetailPage };
