import _ from 'lodash';
import {
    ADMIN_COMBINATION_CONFIRM_LOADING,
    ADMIN_COMBINATION_CONFIRM_SUCCESS,
    ADMIN_COMBINATION_CONFIRM_FAILED,
    ADMIN_COMBINATION_UNCONFIRM_LOADING,
    ADMIN_COMBINATION_UNCONFIRM_SUCCESS,
    ADMIN_COMBINATION_UNCONFIRM_FAILED,
    SUBSCRIPTION_SET_TO_STATE,

    // ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_LOADING,
    ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_SUCCESS,
    // ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_FAILED,
    //
    // ADMIN_SUBSCRIPTION_MARK_ENTERED_LOADING,
    ADMIN_SUBSCRIPTION_MARK_ENTERED_SUCCESS,
    // ADMIN_SUBSCRIPTION_MARK_ENTERED_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    combinations: null,
    subscription: null,
};

const INITIAL_STATE_COMBINATION = {
    isConfirmed: false,
    isConfirming: false,
    errorConfirm: null,

    isUnconfirmed: false,
    isUnconfirming: false,
    errorUnconfirm: null,
};

export default function (name = '') {
    return (state = INITIAL_STATE, action) => {
        if (!action.meta || action.meta.key !== name) {
            return state;
        }

        switch (action.type) {
            case SUBSCRIPTION_SET_TO_STATE:
                return {
                    ...state,
                    subscription: action.payload.subscription,
                };
            case ADMIN_SUBSCRIPTION_MARK_NOT_ENTERED_SUCCESS:
            case ADMIN_SUBSCRIPTION_MARK_ENTERED_SUCCESS:
                return {
                    ...state,
                    subscription: action.payload.result,
                };

            case ADMIN_COMBINATION_CONFIRM_LOADING: {
                const newCombinations = Object.assign({}, state.combinations);
                newCombinations[action.payload.id] = {
                    ...INITIAL_STATE_COMBINATION,
                    isConfirming: true,
                };

                return {
                    ...state,
                    combinations: newCombinations,
                };
            }
            case ADMIN_COMBINATION_CONFIRM_SUCCESS: {
                const newCombinations = Object.assign({}, state.combinations);
                newCombinations[action.payload.id] = {
                    ...INITIAL_STATE_COMBINATION,
                    isConfirmed: true,
                };

                let subscription = JSON.parse(
                    JSON.stringify(state.subscription)
                ); // deep copy
                const index = _.findIndex(subscription?.combinations, {
                    id: action.payload.result.id,
                });
                if (index >= 0) {
                    subscription.combinations[index] = action.payload.result;
                }

                return {
                    ...state,
                    subscription: subscription,
                    combinations: { ...newCombinations },
                };
            }
            case ADMIN_COMBINATION_CONFIRM_FAILED: {
                const newCombinations = Object.assign({}, state.combinations);
                newCombinations[action.payload.id] = {
                    ...INITIAL_STATE_COMBINATION,
                    errorConfirm: action.payload.error,
                };

                return {
                    ...state,
                    combinations: newCombinations,
                };
            }

            case ADMIN_COMBINATION_UNCONFIRM_LOADING: {
                const newCombinations = Object.assign({}, state.combinations);
                newCombinations[action.payload.id] = {
                    ...INITIAL_STATE_COMBINATION,
                    isUnconfirming: true,
                };

                return {
                    ...state,
                    combinations: newCombinations,
                };
            }
            case ADMIN_COMBINATION_UNCONFIRM_SUCCESS: {
                const newCombinations = Object.assign({}, state.combinations);
                newCombinations[action.payload.id] = {
                    ...INITIAL_STATE_COMBINATION,
                    isUnconfirmed: true,
                };

                let subscription = JSON.parse(
                    JSON.stringify(state.subscription)
                ); // deep copy
                const index = _.findIndex(subscription?.combinations, {
                    id: action.payload.result.id,
                });
                if (index >= 0) {
                    subscription.combinations[index] = action.payload.result;
                }

                return {
                    ...state,
                    subscription: subscription,
                    combinations: newCombinations,
                };
            }
            case ADMIN_COMBINATION_UNCONFIRM_FAILED: {
                const newCombinations = Object.assign({}, state.combinations);
                newCombinations[action.payload.id] = {
                    ...INITIAL_STATE_COMBINATION,
                    errorUnconfirm: action.payload.error,
                };

                return {
                    ...state,
                    combinations: newCombinations,
                };
            }

            default:
                return state;
        }
    };
}
