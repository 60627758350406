import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

export default class StoreUtil {
    static _store;

    static initialize(reducers) {
        // promise to wait until the app has finished loading
        this._store = createStore(reducers, applyMiddleware(thunk));
    }

    static getStore() {
        return this._store;
    }
}
