import {
    ADMIN_ADMINISTRATION_STATISTICS_LOADING,
    ADMIN_ADMINISTRATION_STATISTICS_SUCCESS,
    ADMIN_ADMINISTRATION_STATISTICS_FAILED,
} from "./actionTypes";

const INITIAL_STATE = {
    isLoading: false,
    statistics: [],
    error: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_ADMINISTRATION_STATISTICS_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case ADMIN_ADMINISTRATION_STATISTICS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                statistics: action.payload,
            };
        case ADMIN_ADMINISTRATION_STATISTICS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
