import { deleteAdminEvent, copyAdminEvent } from 'services/EventService';

import {
    ADMIN_EVENT_DELETE_LOADING,
    ADMIN_EVENT_DELETE_SUCCESS,
    ADMIN_EVENT_DELETE_FAILED,
    ADMIN_EVENT_COPY_LOADING,
    ADMIN_EVENT_COPY_SUCCESS,
    ADMIN_EVENT_COPY_FAILED,
} from './actionTypes';

export const deleteEvent = ({ id, key }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_EVENT_DELETE_LOADING });

        deleteAdminEvent(id)
            .then((result) => {
                dispatch({
                    type: ADMIN_EVENT_DELETE_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_EVENT_DELETE_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};

export const copyEvent = ({ id, key }) => {
    return (dispatch) => {
        dispatch({ type: ADMIN_EVENT_COPY_LOADING });

        copyAdminEvent({ id })
            .then((result) => {
                dispatch({
                    type: ADMIN_EVENT_COPY_SUCCESS,
                    payload: result,
                    meta: { key: key },
                });
            })
            .catch((error) => {
                dispatch({
                    type: ADMIN_EVENT_COPY_FAILED,
                    payload: error,
                    meta: { key: key },
                });
            });
    };
};
