import fetchApi from './api';

const endPoints = {
    list: '/v1/authorizations/',
    accept: '/v1/authorizations/accept/',
    decline: '/v1/authorizations/decline/',
};

export const getAuthorizations = (args) =>
    fetchApi({
        url: endPoints.list,
        data: args,
        method: 'GET',
    });

export const getOpenAuthorizations = (args) =>
    fetchApi({
        url: endPoints.list,
        data: { ...args, is_declined: false, is_approved: false },
        method: 'GET',
    });

export const getApprovedAuthorizations = (args) =>
    fetchApi({
        url: endPoints.list,
        data: { ...args, is_approved: true },
        method: 'GET',
    });

export const getDeclinedAuthorizations = (args) =>
    fetchApi({
        url: endPoints.list,
        data: { ...args, is_declined: true },
        method: 'GET',
    });

export const acceptAuthorization = (args) =>
    fetchApi({
        url: endPoints.accept,
        data: args,
        method: 'POST',
    });

export const declineAuthorization = (args) =>
    fetchApi({
        url: endPoints.decline,
        data: args,
        method: 'POST',
    });
