import {
    HOME_PAGE_EVENTS_LOADING,
    HOME_PAGE_EVENTS_SUCCESS,
    HOME_PAGE_EVENTS_FAILED,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: true,
    error: null,
    events: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HOME_PAGE_EVENTS_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case HOME_PAGE_EVENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                events: action.payload.slice(0, 2),
            };
        case HOME_PAGE_EVENTS_FAILED:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
