import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { dynamicInjectReducer } from 'reducers/InjectReducer';
import Reducer from './reducer';
import { urls, getUrl } from 'urls';

// import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardActionArea,
    CardContent,
    IconButton,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
    EditOutlined as EditOutlineIcon,
    Close as CloseIcon,
    DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import {
    Bullet,
    DialogSubscription,
    DialogSubscriptionDelete,
    PopupMenu,
    PopupMenuItem,
    Toast,
} from 'components';
import { zeroPad, getIdFromUrl, getCountryImage } from 'utils';
import {
    confirmCombinationAdmin,
    unconfirmCombinationAdmin,
    markNotEnteredSubscriptionAdmin,
    markEnteredSubscriptionAdmin,
    setSubscriptionToSate,
} from './actions';

// const useStyles = (theme) => ({
//
// });

class CardSubscription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogSubscriptionOpen: false,
            dialogDeleteOpen: false,
            snackConfirmOpen: {},
            snackUnconfirmOpen: {},
            order: null,
        };
        this.handleSubscriptionOpen = this.handleSubscriptionOpen.bind(this);
        this.handleSubscriptionClose = this.handleSubscriptionClose.bind(this);
        this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
        this.handleDeleteClose = this.handleDeleteClose.bind(this);
        this.handleViewTicket = this.handleViewTicket.bind(this);
        this.handleViewOrder = this.handleViewOrder.bind(this);
        this.handleConfirmCombination = this.handleConfirmCombination.bind(
            this
        );
        this.handleUnconfirmCombination = this.handleUnconfirmCombination.bind(
            this
        );
        this.handleMarkEntered = this.handleMarkEntered.bind(this);
        this.handleMarkNotEntered = this.handleMarkNotEntered.bind(this);
    }

    componentDidUpdate(prevProps) {
        // if subscription is not set, set it
        if (
            (!this.props.stateSubscription && this.props.subscription) ||
            prevProps.subscription !== this.props.subscription ||
            this.props.stateSubscription !== this.props.subscription
        ) {
            this.props.setSubscriptionToSate({
                subscription: this.props.subscription,
            });
        }

        // (un)confirm
        if (
            prevProps.combinations !== this.props.combinations &&
            this.props.combinations &&
            Object.keys(this.props.combinations).length > 0
        ) {
            Object.keys(this.props.combinations).map((key, index) => {
                const comb = this.props.combinations[key];

                if (comb.isConfirming && !this.state.snackConfirmOpen[key]) {
                    const confirmSnack = this.props.enqueueSnackbar(
                        this.props.t('common:text.confirming_combination'),
                        {
                            autoHideDuraction: 5000,
                            onClose: () => {
                                this.setState({
                                    snackConfirmOpen: Object.assign(
                                        {},
                                        this.state.snackConfirmOpen,
                                        { [key]: false }
                                    ),
                                });
                            },
                            action: () => (
                                <IconButton>
                                    <CloseIcon style={{ color: 'white' }} />
                                </IconButton>
                            ),
                        }
                    );

                    this.setState({
                        snackConfirmOpen: Object.assign(
                            {},
                            this.state.snackConfirmOpen,
                            { [key]: confirmSnack }
                        ),
                    });
                }

                if (
                    comb.isUnconfirming &&
                    !this.state.snackUnconfirmOpen[key]
                ) {
                    const unconfirmSnack = this.props.enqueueSnackbar(
                        this.props.t('common:text.unconfirming_combination'),
                        {
                            autoHideDuraction: 5000,
                            onClose: () => {
                                this.setState({
                                    snackUnconfirmOpen: Object.assign(
                                        {},
                                        this.state.snackUnconfirmOpen,
                                        { [key]: false }
                                    ),
                                });
                            },
                            action: () => (
                                <IconButton>
                                    <CloseIcon style={{ color: 'white' }} />
                                </IconButton>
                            ),
                        }
                    );

                    this.setState({
                        snackUnconfirmOpen: Object.assign(
                            {},
                            this.state.snackUnconfirmOpen,
                            { [key]: unconfirmSnack }
                        ),
                    });
                }

                return null;
            });
        }
    }

    handleConfirmCombination(event, combination) {
        event.preventDefault();
        event.stopPropagation();

        this.props.confirmCombinationAdmin({
            id: combination.id,
        });
    }

    handleUnconfirmCombination(event, combination) {
        event.preventDefault();
        event.stopPropagation();

        this.props.unconfirmCombinationAdmin({
            id: combination.id,
        });
    }

    handleMarkEntered(event, subscription) {
        event.preventDefault();
        event.stopPropagation();

        this.props.markEnteredSubscriptionAdmin({
            id: subscription.id,
        });
    }

    handleMarkNotEntered(event, subscription) {
        event.preventDefault();
        event.stopPropagation();

        this.props.markNotEnteredSubscriptionAdmin({
            id: subscription.id,
        });
    }

    handleSubscriptionClose(newSubscription) {
        this.setState({
            dialogSubscriptionOpen: false,
        });
    }

    handleSubscriptionOpen(event) {
        event.preventDefault();
        event.stopPropagation();

        // check if the user is logged in -> otherwise sent to login page
        const { isLoggedIn } = this.props;

        if (!isLoggedIn) {
            // show the login page
            /* eslint-disable no-undef */
            window.location.href =
                getUrl(urls.LOGIN) + '?next=' + window.location.pathname;
            /* eslint-enable no-undef */
        } else {
            this.setState({
                dialogSubscriptionOpen: true,
            });
        }
    }

    handleDeleteClose() {
        this.setState({
            dialogDeleteOpen: false,
        });
    }

    handleDeleteOpen(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            dialogDeleteOpen: true,
        });
    }

    handleViewTicket(event) {
        const { subscription } = this.props;

        event.preventDefault();
        event.stopPropagation();

        // get the ids from the url
        const eventId = getIdFromUrl(subscription.event);
        const ticketId = getIdFromUrl(subscription.ticket);

        this.props.history.push({
            pathname: getUrl(urls.ADMIN_EVENT_TICKET_DETAIL, {
                eventId: eventId,
                ticketId: ticketId,
            }),
            search: '',
        });
    }

    handleViewOrder(event) {
        const { subscription } = this.props;

        event.preventDefault();
        event.stopPropagation();

        // get the ids from the url
        const orderId = getIdFromUrl(subscription.order);

        this.props.history.push({
            pathname: getUrl(urls.ADMIN_ORDER_DETAIL, { orderId: orderId }),
            search: '',
        });
    }

    renderAdminLabels(combination) {
        const { admin, t } = this.props;

        if (!admin) {
            return;
        }

        const confirmed = combination.confirmed_at;
        // TODO not paid

        return (
            <React.Fragment>
                {!confirmed && (
                    <span className="label red">
                        {t('forms:label.not_confirmed')}
                    </span>
                )}
            </React.Fragment>
        );
    }

    renderLabels(combination) {
        const { subscription, t } = this.props;

        const entered = false; // subscription.entered_at;
        const ridden = subscription.entered_at; // TODO
        const pony = combination.pony;

        return (
            <React.Fragment>
                {this.renderAdminLabels(combination)}
                {pony && (
                    <span className="label secondary">
                        {t('forms:label.pony')}
                    </span>
                )}
                {entered && (
                    <span className="label primary">
                        {t('forms:label.entered')}
                    </span>
                )}
                {ridden && (
                    <span className="label green">
                        {t('forms:label.ridden')}
                    </span>
                )}
            </React.Fragment>
        );
    }

    renderRiders() {
        const {
            subscription,
            startNumber,
            isLoading,
            editable,
            t,
        } = this.props;

        if (isLoading) {
            return (
                <CardContent>
                    <div className="startNumber">
                        <Skeleton variant="rect" height={17} width={20} />
                    </div>
                    <div className="country">
                        <Skeleton variant="rect" height={30} width={45} />
                    </div>
                    <div className="name">
                        <Skeleton variant="rect" height={19} width={'50%'} />
                        <Skeleton
                            variant="rect"
                            height={16}
                            width={'40%'}
                            style={{ marginTop: '5px' }}
                        />
                    </div>
                    <div className="actions" />
                </CardContent>
            );
        }

        const Content = () => {
            return subscription.combinations.map((obj, index) => {
                const countryImage = getCountryImage(obj?.country);

                return (
                    <CardContent key={index}>
                        <div className="startNumber">
                            {zeroPad(startNumber, 2)}.{' '}
                            <span>
                                {subscription.out_of_competition
                                    ? t('forms:label.out_of_competition_short')
                                    : ''}
                            </span>
                        </div>
                        <div className="country">
                            <img
                                src={countryImage}
                                alt="Belgium"
                                aria-label="Flag of Belgium"
                            />
                        </div>
                        <div className="name">
                            <strong>
                                {`${obj.first_name} ${obj.last_name}`}{' '}
                                {!subscription?.is_paid && this.props.admin && (
                                    <Bullet />
                                )}
                            </strong>
                            <br />
                            <small>{obj.horse_name}</small>
                        </div>
                        <div className="actions">
                            {this.renderLabels(obj)}
                            <img
                                src={countryImage}
                                alt="Belgium"
                                className="country"
                                aria-label="Flag of Belgium"
                            />
                            {this.renderRiderActions(obj)}
                        </div>
                    </CardContent>
                );
            });
        };

        if (!editable) {
            return <Content />;
        }

        return (
            <CardActionArea onClick={this.handleSubscriptionOpen}>
                <Content />
            </CardActionArea>
        );
    }

    renderRiderAdminActions(combination) {
        const { t, subscription } = this.props;

        const menuItems = [];

        if (combination && combination.actions) {
            const actions = combination.actions;

            if ('confirm' in actions) {
                menuItems.push({
                    action: (event) =>
                        this.handleConfirmCombination(event, combination),
                    label: t('common:text.confirm_combination'),
                });
            }

            if ('unconfirm' in actions) {
                menuItems.push({
                    action: (event) =>
                        this.handleUnconfirmCombination(event, combination),
                    label: t('common:text.unconfirm_combination'),
                });
            }
        }

        if (subscription && subscription.actions) {
            const actions = subscription.actions;

            if ('ticket' in actions) {
                menuItems.push({
                    action: this.handleViewTicket,
                    label: t('common:text.view_ticket'),
                });
            }

            if ('order' in actions) {
                menuItems.push({
                    action: this.handleViewOrder,
                    label: t('common:text.view_order'),
                });
            }

            if ('mark_entered' in actions) {
                menuItems.push({
                    action: (event) =>
                        this.handleMarkEntered(event, subscription),
                    label: t('common:text.mark_entered'),
                });
            }

            if ('mark_not_entered' in actions) {
                menuItems.push({
                    action: (event) =>
                        this.handleMarkNotEntered(event, subscription),
                    label: t('common:text.mark_not_entered'),
                });
            }

            if ('change' in actions) {
                menuItems.push({
                    action: this.handleSubscriptionOpen,
                    label: t('common:text.edit_subscription'),
                    icon: EditOutlineIcon,
                });
            }

            if ('delete' in actions) {
                menuItems.push({
                    action: this.handleDeleteOpen,
                    label: t('common:text.delete_subscription'),
                    type: 'destroy',
                    icon: DeleteOutlineIcon,
                });
            }
        }

        return menuItems.map((obj, index) => {
            return (
                <PopupMenuItem
                    action={obj.action}
                    key={index}
                    label={obj.label}
                    icon={obj.icon}
                    type={obj.type}
                />
            );
        });
    }

    renderRiderActions(combination) {
        const { editable, admin, t, subscription } = this.props;

        if (editable || subscription?.actions) {
            const menuItems = [];

            if (subscription && subscription.actions) {
                const actions = subscription.actions;

                if ('change' in actions) {
                    menuItems.push({
                        action: this.handleSubscriptionOpen,
                        label: t('common:text.edit_subscription'),
                    });
                }

                if ('delete' in actions) {
                    menuItems.push({
                        action: this.handleDeleteOpen,
                        label: t('common:text.delete_subscription'),
                    });
                }
            }

            return (
                <PopupMenu>
                    {admin
                        ? this.renderRiderAdminActions(combination)
                        : menuItems.map((obj, index) => (
                              <PopupMenuItem
                                  action={obj.action}
                                  key={index}
                                  label={obj.label}
                              />
                          ))}
                </PopupMenu>
            );
        }
    }

    renderFreePlace() {
        const { startNumber, reserved, t, isLoading, editable } = this.props;

        const ReservedContent = () => {
            return (
                <CardContent>
                    <div className="startNumber">
                        {isLoading ? (
                            <Skeleton variant="rect" height={17} width={20} />
                        ) : (
                            zeroPad(startNumber, 2) + '.'
                        )}
                    </div>
                    <div className="free">
                        {isLoading ? (
                            <Skeleton variant="rect" height={20} width={100} />
                        ) : (
                            <em>{t('common:text.reserved_place')}</em>
                        )}
                    </div>
                    <div className="actions">{this.renderEmptyActions()}</div>
                </CardContent>
            );
        };

        if (reserved) {
            if (!editable) {
                return <ReservedContent />;
            }

            return (
                <CardActionArea onClick={this.handleSubscriptionOpen}>
                    <ReservedContent />
                </CardActionArea>
            );
        }

        const Content = () => {
            return (
                <CardContent>
                    <div className="startNumber">
                        {isLoading ? (
                            <Skeleton variant="rect" height={17} width={20} />
                        ) : (
                            zeroPad(startNumber, 2) + '.'
                        )}
                    </div>
                    <div className="free">
                        {isLoading ? (
                            <Skeleton variant="rect" height={20} width={100} />
                        ) : (
                            <em>{t('common:text.free_place')}</em>
                        )}
                    </div>
                    <div className="actions">{this.renderEmptyActions()}</div>
                </CardContent>
            );
        };

        if (!editable) {
            return <Content />;
        }

        return (
            <CardActionArea onClick={this.handleSubscriptionOpen}>
                <Content />
            </CardActionArea>
        );
    }

    renderEmptyActions() {
        const { empty, t, editable } = this.props;

        if (!empty || !editable) {
            return;
        }

        return (
            <PopupMenu>
                <PopupMenuItem
                    action={this.handleSubscriptionOpen}
                    label={t('common:text.subscribe')}
                />
            </PopupMenu>
        );
    }

    renderNotifications() {
        const { error, combinations, t } = this.props;

        return (
            <React.Fragment>
                <Toast
                    message={
                        typeof error === 'string'
                            ? error
                            : t('common:error.general.title')
                    }
                    open={Boolean(error)}
                    onClose={this.handleCloseError}
                />
                {combinations &&
                    Object.keys(this.props.combinations).map((key, index) => {
                        const obj = this.props.combinations[key];

                        // confirm
                        if (obj.errorConfirm) {
                            // close all snacks
                            this.props.closeSnackbar();

                            return (
                                <Toast
                                    message={
                                        obj.errorConfirm['message'] ||
                                        t('common:error.combination.confirm')
                                    }
                                    open={Boolean(obj.errorConfirm)}
                                    onClose={this.handleCloseErrorConfirm}
                                />
                            );
                        }

                        if (obj.isConfirmed) {
                            // close all snacks
                            this.props.closeSnackbar();

                            return (
                                <Toast
                                    type={'success'}
                                    message={t(
                                        'common:success.combination.confirm'
                                    )}
                                    open={Boolean(obj.isConfirmed)}
                                    onClose={this.handleCloseConfirmed}
                                />
                            );
                        }

                        // unconfirm
                        if (obj.errorUnconfirm) {
                            // close all snacks
                            this.props.closeSnackbar();

                            return (
                                <Toast
                                    message={
                                        obj.errorUnconfirm['message'] ||
                                        t('common:error.combination.unconfirm')
                                    }
                                    open={Boolean(obj.errorUnconfirm)}
                                    onClose={this.handleCloseErrorUnconfirm}
                                />
                            );
                        }

                        if (obj.isUnconfirmed) {
                            // close all snacks
                            this.props.closeSnackbar();

                            return (
                                <Toast
                                    type={'success'}
                                    message={t(
                                        'common:success.combination.unconfirm'
                                    )}
                                    open={Boolean(obj.isUnconfirmed)}
                                    onClose={this.handleCloseUnconfirmed}
                                />
                            );
                        }

                        return <React.Fragment />;
                    })}
            </React.Fragment>
        );
    }

    render() {
        const {
            empty,
            reserved,
            subscription,
            course,
            startNumber,
            isLoading,
            admin,
        } = this.props;

        return (
            <React.Fragment>
                {this.renderNotifications()}

                <Card className="combination-card" elevation={0}>
                    {empty || reserved || !subscription
                        ? this.renderFreePlace()
                        : this.renderRiders()}
                </Card>

                {!isLoading && course && (
                    <DialogSubscription
                        open={this.state.dialogSubscriptionOpen}
                        onClose={this.handleSubscriptionClose}
                        course={course}
                        startNumber={startNumber}
                        subscription={subscription}
                        admin={admin}
                    />
                )}

                {!isLoading && subscription && (
                    <DialogSubscriptionDelete
                        open={this.state.dialogDeleteOpen}
                        onClose={this.handleDeleteClose}
                        subscription={subscription}
                        admin={admin}
                    />
                )}
            </React.Fragment>
        );
    }
}

CardSubscription.propTypes = {
    // props
    course: PropTypes.object.isRequired,
    startNumber: PropTypes.string.isRequired,
    stateSubscription: PropTypes.object,
    subscription: PropTypes.object,
    free: PropTypes.bool,
    reserved: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    editable: PropTypes.bool,
    admin: PropTypes.bool,
    empty: PropTypes.bool,
    isDeleted: PropTypes.bool,
    combinations: PropTypes.object,
    error: PropTypes.object,
    history: PropTypes.object,
    shoppingBasketSubscriptions: PropTypes.object,
    // functions
    t: PropTypes.func.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    confirmCombinationAdmin: PropTypes.func.isRequired,
    unconfirmCombinationAdmin: PropTypes.func.isRequired,
    deleteSubscriptionAdmin: PropTypes.func.isRequired,
    deleteSubscriptionBasketAdmin: PropTypes.func.isRequired,
    deleteSubscriptionBasket: PropTypes.func.isRequired,
    markEnteredSubscriptionAdmin: PropTypes.func.isRequired,
    markNotEnteredSubscriptionAdmin: PropTypes.func.isRequired,
    setSubscriptionToSate: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.reducerKey || !state[ownProps.reducerKey]) {
        return {};
    }

    const { error, combinations, subscription } = state[
        ownProps.reducerKey
    ];
    const { isLoggedIn } = state.authentication;
    const { subscriptions } = state.shoppingCart;

    return {
        isLoggedIn,
        error,
        combinations,
        subscription: subscription || ownProps.subscription,
        stateSubscription: subscription,
        shoppingBasketSubscriptions: subscriptions,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        confirmCombinationAdmin: ({ id }) =>
            dispatch(
                confirmCombinationAdmin({
                    id,
                    key: ownProps.reducerKey,
                })
            ),
        unconfirmCombinationAdmin: ({ id }) =>
            dispatch(
                unconfirmCombinationAdmin({
                    id,
                    key: ownProps.reducerKey,
                })
            ),
        markNotEnteredSubscriptionAdmin: ({ id }) =>
            dispatch(
                markNotEnteredSubscriptionAdmin({
                    id,
                    key: ownProps.reducerKey,
                })
            ),
        markEnteredSubscriptionAdmin: ({ id }) =>
            dispatch(
                markEnteredSubscriptionAdmin({
                    id,
                    key: ownProps.reducerKey,
                })
            ),
        setSubscriptionToSate: ({ subscription }) =>
            dispatch(
                setSubscriptionToSate({
                    subscription,
                    key: ownProps.reducerKey,
                })
            ),
    };
};

const CardSubscriptionState = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardSubscription);
const CardSubscriptionReduced = dynamicInjectReducer({
    key: `componentCardSubscription`,
    reducer: Reducer,
})(CardSubscriptionState);
const CardSubscriptionTranslated = withTranslation()(CardSubscriptionReduced);
const CardSubscriptionRouter = withRouter(CardSubscriptionTranslated);
const CardSubscriptionSnackbar = withSnackbar(CardSubscriptionRouter);

export { CardSubscriptionSnackbar as CardSubscription };
