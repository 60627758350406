import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Hidden,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = (theme) => ({
    // position: {
    //     display: 'block',
    //     marginBottom: theme.spacing(1),
    //
    //     [theme.breakpoints.up('sm')]: {
    //         display: 'inline-block',
    //         marginBottom: 0,
    //     }
    // },
    title: {
        display: 'block',
        marginBottom: theme.spacing(1),

        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
        },
    },
});

class CardCourse extends Component {
    renderText(plural = false) {
        const { team, t } = this.props;

        if (team) {
            return plural
                ? t('common:text.team_plural')
                : t('common:text.team');
        }

        return plural
            ? t('common:text.combination_plural')
            : t('common:text.combination');
    }

    renderLoading() {
        return (
            <Card className="course-card" elevation={0}>
                <CardContent>
                    <div>
                        <Hidden mdUp>
                            <Skeleton
                                variant="rect"
                                height={20}
                                width={'15%'}
                                style={{ display: 'block' }}
                            />
                        </Hidden>
                        <Skeleton
                            variant="rect"
                            height={20}
                            width={'80%'}
                            style={{ marginTop: '5px' }}
                        />
                        <small>
                            <Skeleton
                                variant="rect"
                                height={20}
                                width={'60%'}
                                style={{ marginTop: '5px' }}
                            />
                        </small>
                    </div>
                    <Hidden smDown>
                        <div>
                            <Skeleton
                                variant="rect"
                                height={20}
                                width={'60%'}
                            />
                        </div>
                    </Hidden>
                    <div className="actions">
                        <Hidden mdUp>
                            <Skeleton
                                variant="rect"
                                height={20}
                                width={'60%'}
                            />
                        </Hidden>
                        <Hidden smDown>
                            <Skeleton
                                variant="rect"
                                height={20}
                                width={'80%'}
                                style={{ marginTop: '2px' }}
                            />
                            <small>
                                <Skeleton
                                    variant="rect"
                                    height={16}
                                    width={'40%'}
                                    style={{
                                        marginTop: '4px',
                                        marginLeft: '20%',
                                    }}
                                />
                            </small>
                        </Hidden>
                    </div>
                </CardContent>
            </Card>
        );
    }

    renderActions() {
        const { actions, closed, submissionPrice, t } = this.props;

        if (actions) {
            return actions();
        }

        if (closed) {
            return (
                <div className="actions">
                    <Button disableElevation variant="contained">
                        {t('common:text.view_course')}
                    </Button>
                    <Hidden mdUp>
                        <small>
                            <strong>&euro;&nbsp;{submissionPrice}</strong>{' '}
                            {t('common:text.foreach')} {this.renderText()}
                        </small>
                    </Hidden>
                </div>
            );
        }

        return (
            <div className="actions">
                <Button disableElevation variant="contained" color="secondary">
                    {t('common:text.subscribe_now')}
                </Button>
                <Hidden mdUp>
                    <small>
                        <strong>&euro;&nbsp;{submissionPrice}</strong>{' '}
                        {t('common:text.foreach')} {this.renderText()}
                    </small>
                </Hidden>
            </div>
        );
    }

    render() {
        const {
            name,
            position,
            height,
            time,
            courseType,
            subscriptionsMax,
            subscriptionsTotal,
            submissionPrice,
            actionUrl,
            t,
            isLoading,
            classes,
        } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return (
            <Card className="course-card" elevation={0}>
                <CardActionArea href={actionUrl}>
                    <CardContent>
                        <div>
                            <Hidden mdUp>
                                {time && (
                                    <time>
                                        {moment(time, 'HH:mm:ss').format(
                                            'HH:mm'
                                        )}
                                        {t('common:text.time_unit_hours_short')}
                                    </time>
                                )}
                            </Hidden>
                            <strong className={classes.title}>
                                {position}. {name} - {height} cm
                            </strong>
                            <small>
                                {time ? (
                                    <Hidden smDown>
                                        <time>
                                            {moment(time, 'HH:mm:ss').format(
                                                'HH:mm'
                                            )}
                                            {t(
                                                'common:text.time_unit_hours_short'
                                            )}
                                        </time>
                                    </Hidden>
                                ) : (
                                    ''
                                )}
                                {courseType} - {subscriptionsTotal} /{' '}
                                {subscriptionsMax} {this.renderText(true)}
                            </small>
                        </div>
                        <Hidden smDown>
                            <div>
                                <strong>&euro;&nbsp;{submissionPrice}</strong>{' '}
                                {t('common:text.foreach')} {this.renderText()}
                            </div>
                        </Hidden>
                        {this.renderActions()}
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
}

CardCourse.propTypes = {
    // props
    actions: PropTypes.func,
    actionUrl: PropTypes.string.isRequired,
    closed: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    courseType: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    subscriptionsTotal: PropTypes.number.isRequired,
    subscriptionsMax: PropTypes.number.isRequired,
    submissionPrice: PropTypes.number.isRequired,
    team: PropTypes.bool,
    time: PropTypes.string,
    // handlers
    t: PropTypes.func.isRequired,
};

const CardCourseTranslated = withTranslation()(CardCourse);
const CardCourseStyled = withStyles(useStyles)(CardCourseTranslated);

export { CardCourseStyled as CardCourse };
