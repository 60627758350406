import { clear } from 'services/StorageService';

import {
    LOGOUT_PAGE_LOADING,
    LOGOUT_PAGE_LOADING_SUCCESS,
    LOGOUT_PAGE_LOADING_FAILED,
} from './actionTypes';

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT_PAGE_LOADING });

        try {
            clear();

            dispatch({
                type: LOGOUT_PAGE_LOADING_SUCCESS,
            });
        } catch (err) {
            dispatch({
                type: LOGOUT_PAGE_LOADING_FAILED,
            });
        }
    };
};
