import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { dynamicInjectReducer } from 'reducers/InjectReducer';
import { urls, getUrl } from 'urls';

import { Card, CardActionArea, CardContent, MenuItem } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { DialogDelete, PopupMenu } from 'components';
import { deleteClub } from './actions';
import Reducer from './reducer';

const useStyles = (theme) => ({});

class CardClub extends Component {
    constructor(props) {
        super(props);

        // set state
        this.state = {
            dialogDeleteOpen: false,
        };

        // event handlers
        this.handleDialogDeleteOpen = this.handleDialogDeleteOpen.bind(this);
        this.handleDialogDeleteClose = this.handleDialogDeleteClose.bind(this);
        this.handleChangeClub = this.handleChangeClub.bind(this);
        this.handleDeleteClub = this.handleDeleteClub.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDeleted !== this.props.isDeleted) {
            if (this.props.isDeleted) {
                this.handleDialogDeleteClose();
            }
        }
    }

    handleDeleteClub() {
        const { obj } = this.props;

        if (obj) {
            this.props.deleteClub({ id: obj.id });
        }
    }

    handleChangeClub(event) {
        event.preventDefault();
        event.stopPropagation();

        const { history, obj } = this.props;

        history.push(getUrl(urls.ADMIN_CLUB_CHANGE, { clubId: obj.id }));
    }

    handleDialogDeleteOpen(event) {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            dialogDeleteOpen: true,
        });
    }

    handleDialogDeleteClose() {
        this.setState({
            dialogDeleteOpen: false,
        });
    }

    renderActions() {
        const { obj, t } = this.props;

        if (!obj || Object.keys(obj.actions).length <= 0) {
            return;
        }

        return (
            <PopupMenu>
                {obj.actions && obj.actions['change'] && (
                    <MenuItem onClick={this.handleChangeClub}>
                        {t('common:text.edit_club')}
                    </MenuItem>
                )}
                {obj.actions && obj.actions['delete'] && (
                    <MenuItem onClick={this.handleDialogDeleteOpen}>
                        {t('common:text.remove_club')}
                    </MenuItem>
                )}
            </PopupMenu>
        );
    }

    renderLoading() {
        return (
            <Card className="club-card" elevation={0}>
                <CardContent>
                    <div className="clubId">
                        <Skeleton variant="rect" height={20} width={50} />
                    </div>
                    <div className="name">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="email">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="website">
                        <Skeleton variant="rect" height={20} width={'60%'} />
                    </div>
                    <div className="actions" />
                </CardContent>
            </Card>
        );
    }

    renderContent() {
        const { obj } = this.props;

        return (
            <CardContent>
                <div className="clubId">#{obj.id}</div>
                <div className="name">
                    <strong>{obj.name}</strong>
                </div>
                <div className="email">{obj.email}</div>
                <div className="website">{obj.website}</div>
                <div className="actions">{this.renderActions()}</div>
            </CardContent>
        );
    }

    renderCard() {
        const { obj } = this.props;
        let hasDefaultAction = false;

        if (obj && Object.keys(obj.actions).length > 0) {
            hasDefaultAction = Object.keys(obj.actions).indexOf('change') >= 0;
        }

        return (
            <Card className="club-card" elevation={0}>
                {hasDefaultAction ? (
                    <CardActionArea onClick={this.handleChangeClub}>
                        {this.renderContent()}
                    </CardActionArea>
                ) : (
                    this.renderContent()
                )}
            </Card>
        );
    }

    render() {
        const { isLoading, obj, isDeleting } = this.props;

        if (isLoading) {
            return this.renderLoading();
        }

        return (
            <React.Fragment>
                {this.renderCard()}

                {!isLoading && obj && (
                    <DialogDelete
                        open={this.state.dialogDeleteOpen}
                        onClose={this.handleDialogDeleteClose}
                        action={this.handleDeleteClub}
                        isDeleting={isDeleting}
                    />
                )}
            </React.Fragment>
        );
    }
}

CardClub.propTypes = {
    isLoading: PropTypes.bool,
    isDeleted: PropTypes.bool,
    isDeleting: PropTypes.bool,
    obj: PropTypes.object,
    deleteClub: PropTypes.func.isRequired,
    t: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    if (!ownProps.reducerKey || !state[ownProps.reducerKey]) {
        return {};
    }

    const { isDeleting, isDeleted, error } = state[ownProps.reducerKey];

    return {
        isDeleting,
        isDeleted,
        error,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        deleteClub: ({ id }) =>
            dispatch(deleteClub({ id, key: ownProps.reducerKey })),
    };
};

const CardClubTranslated = withTranslation()(CardClub);
const CardClubStyled = withStyles(useStyles)(CardClubTranslated);
const CardClubRouter = withRouter(CardClubStyled);
const CardClubState = connect(
    mapStateToProps,
    mapDispatchToProps
)(CardClubRouter);
const CardClubReduced = dynamicInjectReducer({
    key: `componentCardClub`,
    reducer: Reducer,
})(CardClubState);

export { CardClubReduced as CardClub };
