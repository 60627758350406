import {
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_LOADING_FAILED,
    FORGOT_PASSWORD_LOADING_SUCCESS,
} from './actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    emailSent: false,
    errors: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_LOADING:
            return {
                ...state,
                ...INITIAL_STATE,
                isLoading: true,
            };
        case FORGOT_PASSWORD_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
        case FORGOT_PASSWORD_LOADING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                emailSent: true,
            };

        default:
            return state;
    }
};
