import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Dialog } from '..';
import { TertiaryButton } from '../..';
import { LoginForm } from './form';
import { loginUser } from '../../../pages/Login/actions';

const useStyles = (theme) => ({
    loader: {
        display: 'block',
        textAlign: 'center',
        marginTop: '16px',
    },
    contentText: {
        color: theme.colors.mikado,
        marginTop: '15px',
        textAlign: 'center',
    },
    alert: {
        marginBottom: theme.spacing(3),
        width: '100%',
    },
    bottom: {
        textAlign: 'center',
        marginBottom: theme.spacing(3),
    },
});

class DialogLogin extends Component {
    render() {
        const { onClose, open, t, classes } = this.props;

        return (
            <Dialog
                open={open}
                onClose={onClose}
                title={t('common:title.login_dialog')}
                showLine
            >
                <Grid container>
                    {this.props.formErrors &&
                        Object.keys(this.props.formErrors).length > 0 && (
                            <Alert severity={'error'} className={classes.alert}>
                                {this.props.formErrors['non_field_errors'] ||
                                    t('common:error.general.title')}
                            </Alert>
                        )}

                    <LoginForm
                        action={this.props.loginUser}
                        isLoading={this.props.isLoggingIn}
                        errors={this.props.formErrors}
                    />
                </Grid>

                <Grid item xs={12}>
                    <div className="dashed-line" />
                </Grid>

                <Grid item xs={12} className={classes.bottom}>
                    <Typography variant={'h3'}>
                        {t('common:title.login_no_account')}
                    </Typography>
                    <p>{t('common:description.login_no_account')}</p>
                    <TertiaryButton href="/register">
                        {t('common:title.login_create_account')}
                    </TertiaryButton>
                </Grid>
            </Dialog>
        );
    }
}

DialogLogin.propTypes = {
    // props
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool,
    isLoggingIn: PropTypes.bool,
    formErrors: PropTypes.object,
    // handlers
    action: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
};

DialogLogin.defaultProps = {
    open: false,
};

const mapStateToProps = (state) => {
    const { isLoggingIn, formErrors } = state.pageLogin;
    const { isLoggedIn } = state.authentication;

    return {
        isLoggingIn,
        formErrors,
        isLoggedIn,
    };
};

const DialogLoginStyled = withStyles(useStyles)(DialogLogin);
const DialogLoginTranslated = withTranslation()(DialogLoginStyled);
const DialogLoginState = connect(mapStateToProps, {
    loginUser,
})(DialogLoginTranslated);

export { DialogLoginState as DialogLogin };
