import React from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/Forms';
import { Button, Grid, CircularProgress } from '@material-ui/core';

import PropTypes from 'prop-types';


function ForgotPasswordForm(props) {
    const { register, handleSubmit } = useForm();
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSubmit(props.onSubmit)}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextInput
                        label={t('forms:label.email_address')}
                        name="email"
                        required
                        email
                        ref={register({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        disabled={props.isLoading}
                        type="submit"
                        fullWidth
                        variant="contained"
                        disableElevation
                        color="secondary"
                    >
                        {!props.isLoading &&
                            t('common:button.send_reset_password_link')}
                        {props.isLoading && <CircularProgress size={28} />}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

ForgotPasswordForm.propTypes = {
    // props
    isLoading: PropTypes.bool,
    // events
    onSubmit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

export { ForgotPasswordForm };
