import format from 'string-format';
import fetchApi from './api';

const publicEndPoints = {
    list: '/v1/tickets/',
    detail: '/v1/tickets/{id}/',
};

const adminEndPoints = {
    list: '/v1/admin/tickets/',
    detail: '/v1/admin/tickets/{id}/',
    download: '/v1/admin/tickets/{id}/download/',
};

export const listTickets = (page) => fetchApi({
    url: publicEndPoints.list,
    data: {
        page: page,
        ordering: '-created_at'
    },
    method: 'GET',
});

export const listAdminTickets = (eventId = null, page) => fetchApi({
    url: adminEndPoints.list,
    data: {
        page: page,
        event: eventId,
        ordering: '-created_at'
    },
    method: 'GET',
});

export const getTicket = (id) => fetchApi({
    url: format(publicEndPoints.detail, { id }),
    method: 'GET',
});

export const getAdminTicket = (id) => fetchApi({
    url: format(adminEndPoints.detail, { id }),
    method: 'GET',
});

export const listAdminTicketListActions = (page) => fetchApi({
    url: adminEndPoints.list,
    method: 'OPTIONS',
});

export const downloadAdminTicket = (ticketId) => fetchApi({
    url: format(adminEndPoints.download, { id: ticketId }),
    method: 'GET',
    responseType: 'arraybuffer',
    exposeHeaders: true,
});
